export const sumGroupValue = (data, property = "amount") => data.reduce((total, currentValue) => total + Number(currentValue[property]), 0);

export const numberFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "AED",
  currencySign: "accounting",
});

export const getColumnWidth = (ratio, totalWidth) => ratio * totalWidth;

export const estimatedColumnWidth = (name) => {
  if ((/type|status|enum|category|method/i).test(name)) {
    return 180;
  }

  if ((/number|country|phone|date/i).test(name)) {
    return 150;
  }

  if ((/amount/i).test(name)) {
    return 160;
  }

  if ((/code/i).test(name)) {
    return 180;
  }

  if ((/name|description|comments|text|memo/i).test(name)) {
    return 350;
  }

  return 200;
};

function months() {
  return new Array(12).fill(0);
}

const sumGroup = (group = []) => (monthIndex) => group.reduce(
  (acc, { values }) => acc + (Number.isNaN(values[monthIndex]) ? 0 : values[monthIndex]),
  0
);

const calcMonthlyTotals = (group, columnCountArr) => columnCountArr.map((_, idx) => sumGroup(group)(idx));

export function calculateOutputVariables(inputVariables, columnCountArr = months()) {
  const {
    cashInflow,
    cashOutflow,
    openingBalance
    // creditLine
  } = inputVariables;
  const monthlyInflowTotals = calcMonthlyTotals(cashInflow, columnCountArr);
  const monthlyOutflowTotals = calcMonthlyTotals(cashOutflow, columnCountArr);

  const cashboxBank = columnCountArr.map(
    // eslint-disable-next-line max-len, no-return-assign
    (_, idx, array) => (array[idx + 1] = idx === 0 ? openingBalance : array[idx] + monthlyInflowTotals[idx - 1] - monthlyOutflowTotals[idx - 1])
  );

  const yearlyInflowTotal = monthlyInflowTotals.reduce((a, b) => a + b);
  const yearlyOutflowTotal = monthlyOutflowTotals.reduce((a, b) => a + b);

  const yearlyInflowOuflowDiff = yearlyInflowTotal - yearlyOutflowTotal;

  const monthlyInflowOuflowDiffs = columnCountArr.map(
    (_, idx) => monthlyInflowTotals[idx] - monthlyOutflowTotals[idx]
  );

  return {
    yearlyInflowTotal,
    yearlyOutflowTotal,
    monthlyInflowTotals,
    monthlyOutflowTotals,
    cashboxBank,
    yearlyInflowOuflowDiff,
    monthlyInflowOuflowDiffs
  };
}

export function mergeDataByMonth(dataArray) {
  const monthnames = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sept", "oct", "nov", "dec"];

  const mergedData = {};

  dataArray?.forEach((item) => {
    const {
      componentId, number, externalNumber, name,
      buildingId, buildingNumber, buildingName, month, actualAmount
    } = item;

    if (!mergedData[componentId]) {
      mergedData[componentId] = {
        componentId,
        number,
        externalNumber,
        name,
        buildingId,
        buildingNumber,
        buildingName
      };
    }

    mergedData[componentId][monthnames[month - 1]] = actualAmount;
  });
  return Object.values(mergedData);
}
export const perPageOptions = [
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "150", value: 150 },
];
