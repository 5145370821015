import qs from "qs";
import { isEmpty } from "lodash";

export const handleModal = (modalKey, params, navigate, isClose) => {
  const queryParams = qs.parse(window.location.search?.split("?")[1]);
  const modals = JSON.parse(queryParams?.modals || null) || [];
  if (isClose) modals.pop();
  else modals.push(modalKey);

  let searchQuery = `?modals=${JSON.stringify(modals)}`;
  for (const [key, val] of Object.entries(queryParams)) {
    if (key !== "modals") {
      searchQuery = `${searchQuery}&${key}=${val}`;
    }
  }
  searchQuery = params ? `${searchQuery}&${params}` : searchQuery;
  navigate(searchQuery);
};

export const handleDrawer = (value, navigate, isClose, key = "drawers", params = {}) => {
  const queryParams = qs.parse(window.location.search?.split("?")[1]);
  const drawers = JSON.parse(queryParams[key] || null) || [];

  if (isClose) {
    drawers.pop();
  } else {
    drawers.push(value);
  }

  let searchQuery = "";

  if (drawers.length) {
    searchQuery += `?${key}=${JSON.stringify(drawers)}`;
  }

  const searchParams = {};
  const filteredKeys = Object.keys(queryParams).filter((k) => k !== key);
  filteredKeys.forEach((k) => {
    searchParams[k] = queryParams[k];
  });
  if (!isEmpty(searchParams)) {
    const strSearchParams = qs.stringify(searchParams);
    searchQuery += `&${strSearchParams}`;
  }

  if (!isEmpty(params)) {
    const strParams = qs.stringify(params);
    if (drawers.length) {
      searchQuery += `&${strParams}`;
    }
  }

  if (navigate) {
    navigate(searchQuery);
  } else {
    return searchQuery;
  }
};

export const handleNestedModals = (params, isClose) => {
  const modals = JSON.parse(localStorage.getItem("modals")) || [];
  if (isClose) {
    const lastModal = modals.pop();
    localStorage.setItem("modals", JSON.stringify(modals));
    return lastModal || "";
  }
  const prevModalUrlParams = window.location.search;
  modals.push(prevModalUrlParams);
  localStorage.setItem("modals", JSON.stringify(modals));
  return params;
};
