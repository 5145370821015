import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Input, Loader } from "@hydra/atom/components";
import { kebabCase } from "lodash";
import { Header, NoDataFound, HeaderLeftContent } from "@/components/common";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { PlanBoardListTable } from "@/components/facility/planboard";
import { getDynamicObjectByName } from "@/api/dynamic/dynamicObjectSchemaApi";

function ListView() {
  // getting statuses for work orders
  const { data: objectSchema, isInitialLoading } = useQuery(
    [
      "dynamic-object-schema",
      kebabCase(dynamicObjectMap.get("WorkOrderObjectName")),
    ],
    () => getDynamicObjectByName(dynamicObjectMap.get("WorkOrderObjectName"))
  );

  // set status of workorders
  const statusOptions = useMemo(() => {
    if (objectSchema) {
      return (
        [
          ...objectSchema.document.find((f) => f.camelizedName === "status")
            .options,
        ] || []
      );
    }
    return [];
  }, [objectSchema]);

  if (isInitialLoading) {
    return <Loader />;
  }

  if (!statusOptions || !statusOptions?.length) {
    return <NoDataFound title="No job has been found" />;
  }

  const renderTable = (value) => (
    // eslint-disable-next-line max-len
    <PlanBoardListTable statusFilter={value} />
  );
  return (
    <>
      <div>
        <Header
          className="mt-15"
          leftContent={(
            <div>
              <HeaderLeftContent
                title="List of Workorders"
                subtitle="Supercharge your workflow and connect the tool you use every day."
              />
            </div>
          )}
          rightContent={(
            <div>
              <Input
                className="input-height-fix"
                value=""
                placeholder="Search by name"
              />
            </div>
          )}
        />
      </div>
      <div className="plan-board-accordion">
        {statusOptions && statusOptions.map((item) => renderTable(item.value))}
      </div>
    </>
  );
}

export default ListView;
