import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ReportBankAccountStatement
} from "@/components/finance/reporting";
import { BoxedContent, Header, SvgIcon, IconButton, HeaderLeftContent } from "@/components/common";
import appSettings from "@/settings";
import { selectActiveCompany } from "@/store/appSlice";

function ReportBankAccountStatementWrapper() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id").substring(1);
  const activeCompany = useSelector(selectActiveCompany);

  const exportExcel = () => window.open(`${appSettings.baseUrl}/reports/export-query-report/bank-account-statement?CompanyId=#)}`, "_blank", "noopener,noreferrer");

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<HeaderLeftContent title="Bank Account Statement" subtitle="" icon="file-check-stroke-icon" />}
        rightContent={(
          <div>
            <IconButton
              className="icon-button-with-text"
              onClick={() => exportExcel()}
            >
              <SvgIcon icon="excel" />
              <span>Download Excel</span>
            </IconButton>
          </div>
)}
      />
      <ReportBankAccountStatement id={id} activeCompany={activeCompany} />
    </BoxedContent>
  );
}

export default ReportBankAccountStatementWrapper;
