import { useState } from "react";
import PropTypes from "prop-types";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import getPlaceDetails from "@/api/vendor/google/addressApi";
import appSettings from "@/settings";

const fillInAddress = (place) => {
  let streetAddress = "";
  let postcode = "";
  let locality = "";
  let state = "";
  let country = "";

  for (const component of place.address_components) {
    const componentType = component.types[0];
    switch (componentType) {
      case "street_number": {
        streetAddress = `${component.long_name} ${streetAddress}`;
        break;
      }

      case "route": {
        streetAddress += component.short_name;
        break;
      }

      case "postal_code": {
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      case "postal_code_suffix": {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }

      case "locality":
        locality = component.long_name;
        break;

      case "administrative_area_level_1": {
        state = component.long_name;
        break;
      }

      case "country":
        country = component.long_name;
        break;

      default:
        break;
    }
  }
  return {
    streetAddress,
    zipCode: postcode,
    city: locality,
    state,
    country,
  };
};

function CityAutoComplete({
  value, onChange, onAddressDetailsChange, countryInitials, testId
}) {
  const [optionsMessage, setOptionsMessage] = useState("Type to load places");

  const formatOption = (option) => {
    const street = option.value.description;
    return street;
  };

  const handleChange = async (option) => {
    const newValue = {
      ...option,
      label: formatOption(option),
    };

    onChange(newValue);

    const address = option?.value?.description;

    try {
      const place = await getPlaceDetails(address);
      if (place) {
        const addressDetails = fillInAddress(place.data.results[0]);
        const {
          city, state, zipCode
        } = addressDetails;
        onAddressDetailsChange({
          city,
          state,
          zipCode,
          placeId: place.data.results[0].place_id,
          latitude: place.data.results[0].geometry.location.lat,
          longitude: place.data.results[0].geometry.location.lng
        });
      }
    } catch (error) {
      // TODO: add error handling
    }
  };

  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={appSettings.googleMapsApiKey}
        apiOptions={{ language: "en" }}
        autocompletionRequest={{
          componentRestrictions: {
            country: [countryInitials],
          },
        }}
        selectProps={{
          value,
          onChange: handleChange,
          className: "hydra-form-field hydra-react-select-wrapper",
          classNamePrefix: "hydra-react-select",
          components: { IndicatorSeparator: () => null },
          placeholder: "Street address",
          onInputChange(newValue) {
            if (newValue.length > 0) {
              setOptionsMessage("No places found");
            } else {
              setOptionsMessage("Type to load places");
            }
          },
          noOptionsMessage: () => optionsMessage,
          instanceId: testId,
          inputId: `${testId}Input`
        }}
      />
    </div>
  );
}

CityAutoComplete.propTypes = {
  countryInitials: PropTypes.string,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddressDetailsChange: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

CityAutoComplete.defaultProps = {
  countryInitials: "nl",
  testId: ""
};

export default CityAutoComplete;
