import PropTypes from "prop-types";
import { SvgIcon } from "@/components/common";

export default function Pill({ text, icon, variant }) {
  return (
    <div className={`pill pill-${variant}`}>
      {
        icon ?
          <SvgIcon icon={icon} /> :
          null
      }
      <span className="pill-text">
        {text}
      </span>
    </div>
  );
}

Pill.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

Pill.defaultProps = {
  icon: ""
};
