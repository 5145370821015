import { useEffect } from "react";
import ReactFlow, {
  Background,
  MarkerType,
  MiniMap,
  useEdgesState,
  useNodesState,
} from "reactflow";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { startCase } from "lodash";
import {
  BoxedContent, Header, IconButton, SvgIcon
} from "@/components/common";
import {
  getWorkflowSteps,
  getTaskById,
  getWorkflowSchema,
} from "@/api/workflow/workflowApi";
import { TASK_STATUSES } from "@/components/workflow/constants";

function TaskFlowChart() {
  const [flowChartNodes, setFlowChartNodes, onNodesChange] = useNodesState();
  const [flowChartEdges, setFlowChartEdges, onEdgesChange] = useEdgesState();
  const { taskId: id } = useParams();
  const navigate = useNavigate();

  const { data: task, isFetched: isTaskFetched } = useQuery(["task-data", id], () =>
    getTaskById(id)
  );

  const { data: workflowSteps, isInitialLoading: isLoadingSteps } = useQuery(
    ["workflow-steps-data", task?.workflowRunId],
    () =>
      getWorkflowSteps({
        objectName: task?.recordId ? task?.objectName : task?.preObjectName,
        workflowId: task?.workflowRunId,
      }),
    {
      enabled: isTaskFetched,
    }
  );

  const { data: workflowSchema } = useQuery(
    ["workflow-schema", task?.workflowName],
    () => getWorkflowSchema(task?.workflowName),
    {
      enabled: isTaskFetched && isLoadingSteps,
    }
  );

  useEffect(() => {
    if (!workflowSchema || !workflowSteps) return;
    // Initialize arrays to hold nodes and edges
    const workflowData = workflowSchema.document.workflowSteps;
    const nodes = [];
    const edges = [];
    // Counter for y position
    let yPos = 10;
    // Iterate over each workflow object
    workflowData.forEach((item, i) => {
      let type;

      if (workflowData.length - 1 === i) {
        type = "output";
      } else if (i === 0) {
        type = "input";
      } else {
        type = "default";
      }
      const node = {
        type,
        id: item.name, // Use name as node id
        position: { x: 220, y: yPos },
        style: {
          background: "#63B3ED",
          color: "white",
        },
        data: { label: item.name }, // Use name as label
      };
      const step = workflowSteps.data.find(
        (tempStep) => tempStep.step === item.name
      );
      if (step) {
        const { status } = step;

        if (
          status === TASK_STATUSES.CREATED ||
          status === TASK_STATUSES.COMPLETED ||
          status === TASK_STATUSES.APPROVED ||
          status === TASK_STATUSES.VALID ||
          status === TASK_STATUSES.SUBMITTED ||
          status === TASK_STATUSES.UPDATED
        ) {
          node.style.background = "green";
        } else if (
          status === TASK_STATUSES.REJECTED ||
          status === TASK_STATUSES.INVALID ||
          status === TASK_STATUSES.STOPPED ||
          status === TASK_STATUSES.SKIPPED
        ) {
          node.style.background = "red";
        } else if (status === TASK_STATUSES.INPROGRESS) {
          node.style.background = "purple";
        } else {
          node.style.background = "grey";
        }
      } else {
        node.style.background = "grey"; // Default color for nodes without status
      }
      nodes.push(node);
      // Increment y position
      yPos += 100;
      // Check activityList to determine edges
      item.workflowElements.forEach((element) => {
        if (element.activityList && element.activityList.length > 0) {

          const PreviousLabel = element.activityList.find(
            (activity) => activity.activityType === "Assign" || activity.activityType === "Next" || activity.activityType === "Edit"
          );
          if (PreviousLabel) {
            if (edges.length > 0) {
              const tempEdge = edges[edges.length - 1];
              if (tempEdge) {
                tempEdge.label = `Assign to ${step.role ? `${step.role} ${step.indidual ? (`${(step.indidual)}`) : ""}` : `${PreviousLabel.activity.replace(/_/g, " ")}`}`;
              }
            }
          }

          const nextActivity = element.activityList.find(
            (activity) => activity.activityType === "Next"
          );
          if (nextActivity) {
            const targetNode = nextActivity.activity; // Get the next activity as the target node id
            const edge = {
              id: `${item.name}-${targetNode}`, // Edge id
              source: item.name, // Source node id
              target: targetNode, // Target node id
              animated: true,
              type: "straight",
              markerEnd: {
                type: MarkerType.ArrowClosed,
              },
            };
            const assignItem = element.activityList.find(
              (activity) => activity.activityType === "Assign"
            );
            if (assignItem) {
              edge.label = assignItem.activity;
            }
            edges.push(edge);
          }
        }
      });
    });
    setFlowChartEdges(edges);
    setFlowChartNodes(nodes);
  }, [workflowSchema, workflowSteps]);

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<h1>{startCase(task?.workflowName)}</h1>}
        rightContent={(
          <IconButton
            className="icon-button-with-text mx-2"
            onClick={() => navigate(-1)}
          >
            <SvgIcon icon="back-arrow" />
            <span>Go back</span>
          </IconButton>
        )}
      />
      <div style={{ width: "100%", height: "70vh", direction: "ltr" }}>
        {flowChartNodes && flowChartEdges && (
          <ReactFlow
            nodesDraggable={false}
            nodes={flowChartNodes}
            edges={flowChartEdges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onlyRenderVisibleElements
            fitView
          >
            <MiniMap />
            <Background variant="dots" gap={12} size={1} />
          </ReactFlow>
        )}
      </div>
    </BoxedContent>
  );
}

export default TaskFlowChart;
