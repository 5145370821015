import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Pill } from "@/components/dashboard";
import { Avatar } from "@/components/common";

function ActivityItem({ activity }) {
  const { imageUrl, name, date, time, type, link, title, description, tags } =
    activity;

  const getActivityText = () => {
    switch (type) {
      case "rating":
        return "Rated ";

      case "maintenance":
        return "Opened a Maintenance ";

      default:
        return "";
    }
  };

  return (
    <div className="activity-item">
      <Avatar imageUrl={imageUrl} name={name} size="medium" />

      <div>
        <div className="title-container">
          {name && <h3>{name}</h3>}
          {date && time && <span>{`${date} at ${time}`}</span>}
        </div>

        <p className="activity-text">
          {getActivityText()}
          {link ? <Link to={link}>{title}</Link> : <span>{title}</span>}
        </p>

        {description ? <p className="description">{description}</p> : null}

        {tags ? (
          <div className="tags">
            {tags.map(({ text, variant }, idx) => (
              <div key={`pill-${idx}`}>
                <Pill text={text} variant={variant} />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}

ActivityItem.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default ActivityItem;
