export default function prepareInitialState(data, key) {
  switch (key) {
    case "RequestForQuotation":
      return {
        dueDate: null,
        materialRequest: { label: data?.number, value: data?.id },
        item: data?.itemDetail?.map((item) => ({
          ...item,
          item: { ...item.item, value: item.item.id },
        })),
        status: {
          label: "Open",
          value: "Open"
        },
        itemType: {
          label: data?.itemType,
          value: data?.itemType
        }
      };
    case "StockTransfer":
      return {
        stockItem: data?.itemDetail?.map((item) => ({
          ...item,
          price: item.sellingRate || 10,
          amount: item.sellingRate ? item.sellingRate * item.quantity : 10 * item.quantity,
          billableExpense: true,
          item: {
            ...item.item,
            key: item.item.id,
            value: item.item.id,
            label: item.item.itemName,
          },
        })),
        source: data?.itemDetail?.map((item) => ({
          warehouse: { label: "", value: "" },
          quantityAvailable: 0,
          quantityRequired: item.quantity,
          item: {
            ...item.item,
            key: item.item.id,
            value: item.item.id,
            label: item.item.itemName,
          },
        })),
        requiredFor: data?.requiredFor,
      };
    case "Quotation":
      return {
        quotations: data.map((item) => ({
          ...item,
          number: item.number,
          isApproved: false
        })),
      };
    default:
      return data;
  }
}

export const formatQuotationItems = (items) => items.map((itemData) => ({
  ...itemData,
  itemName: itemData.item.itemName,
  unit: itemData.item.uom,
  quantity: itemData.quantity,
  price: itemData.amount
}));
