import { Header } from "@/components/common";
import { setFormValue } from "@/reducers/admin/companySettingsReducer.js";

function HeaderSteps({ state, dispatch }) {
  const leftContent = (title, subtitle) => (
    <div className="container-header-left-content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );

  return (
    <div className="company-step">
      <div
        className={`company-step-item ${state.completedStep + 1 === 1 ? "company-step-primary" : ""
        } ${state.completedStep >= 1 ? "company-step-success" : ""}`}
        role="presentation"
        onClick={
          state.completedStep + 1 >= 1 ?
            () => dispatch(setFormValue("activeStep", 1)) :
            null
        }
      >
        <Header
          leftContent={leftContent(
            "General Information",
            ""
          )}
        />
      </div>
      <div
        className={`company-step-item ${state.completedStep + 1 === 2 ? "company-step-primary" : ""
        } ${state.completedStep >= 2 ? "company-step-success" : ""}`}
        role="presentation"
        onClick={
          state.completedStep + 1 >= 2 ?
            () => dispatch(setFormValue("activeStep", 2)) :
            null
        }
      >
        <Header
          leftContent={leftContent(
            "Finance Information",
            ""
          )}
        />
      </div>
      <div
        className={`company-step-item ${state.completedStep + 1 === 3 ? "company-step-primary" : ""
        } ${state.completedStep >= 3 ? "company-step-success" : ""}`}
        role="presentation"
        onClick={
          state.completedStep + 1 >= 3 ?
            () => dispatch(setFormValue("activeStep", 3)) :
            null
        }
      >
        <Header
          leftContent={leftContent("Upload Documents", "")}
        />
      </div>
      <div
        className={`company-step-item ${state.completedStep + 1 === 4 ? "company-step-primary" : ""
        } ${state.completedStep >= 4 ? "company-step-success" : ""}`}
        role="presentation"
        onClick={
          state.completedStep + 1 >= 4 ?
            () => dispatch(setFormValue("activeStep", 4)) :
            null
        }
      >
        <Header
          leftContent={leftContent("Alerts", "")}
        />
      </div>
    </div>
  );
}

export default HeaderSteps;
