import { getBuildingRevenueRows, getBuildingRevenueColumns } from "./building-revenue-statement";
import { getBuildingRevenue } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportBuildingRevenue({ activeCompany }) {
  return (
    <DynamicReport
      reportName="building-revenue"
      activeCompany={activeCompany}
      columnsDataProvider={getBuildingRevenueColumns}
      rowsDataProvider={getBuildingRevenueRows}
      fetchData={getBuildingRevenue}
      exportUrl="reports/export-query-report/building-revenue"
      filtersInitialState={{}}
      isPaginated={false}
      orderBy={null}
    />
  );
}
