import { actionCreator } from "@/utils/helpers";

export const SET_FORM_VALUE = "SET_FORM_VALUE";
export const SET_COMMENTS_VALUE = "SET_COMMENTS_VALUE";
export const SET_REMINDERS_VALUE = "SET_REMINDERS_VALUE";
export const SET_ACTIVITY_VALUE = "SET_ACTIVITY_VALUE";
export const SET_STEP = "SET_STEP";
export const COMPLETE_STEP = "COMPLETE_STEP";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const RESET_FORM = "RESET_FORM";
export const SYNC_STATE_WITH_PARAMS = "SYNC_STATE_WITH_PARAMS";

const widgetSteps = [
  {
    title: "Add Building",
    isActive: true,
    isCompleted: false
  },
  {
    title: "Add Components",
    isActive: false,
    isCompleted: false
  },
  {
    title: "Add Floors",
    isActive: false,
    isCompleted: false
  },
  {
    title: "Add Amenities",
    isActive: false,
    isCompleted: false
  },
  {
    title: "Add Facilities",
    isActive: false,
    isCompleted: false
  }
];

export const initialState = {
  steps: widgetSteps,
  loading: false,
  hasBlocks: false,
  floors: "",
  resetState: false,
};

export const blockInitialState = {
  floors: "",
  resetState: false
};

export const floorInitialState = {
  resetState: false
};

export const amenityInitialState = {
  resetState: false
};

export const facilityInitialState = {
  resetState: false
};

export const buildingFormReducer = (state, action) => {
  switch (action.type) {
    case SET_FORM_VALUE:
      return ({
        ...state,
        [action.payload.key]: action.payload.value
      });

    case SET_STEP: {
      const { steps } = state;
      const { index } = action.payload;

      return ({
        ...state,
        steps: steps.map((step, i) => ({
          ...step,
          isActive: i === index
        }))
      });
    }

    case COMPLETE_STEP: {
      const { steps } = state;
      const activeStepIndex = steps.findIndex((step) => step.isActive);
      return {
        ...state,
        steps: steps.map((step, i) => {
          if (i === activeStepIndex) {
            return {
              ...step,
              isCompleted: true
            };
          }

          return step;
        })
      };
    }

    case RESET_FORM: {
      return {
        ...state,
        ...action.payload
      };
    }

    case SET_INITIAL_STATE: {
      const { steps } = state;
      return {
        steps: steps.map((step) => ({
          ...step,
          isCompleted: true
        })),
        ...action.payload
      };
    }

    case SYNC_STATE_WITH_PARAMS: {
      const { steps } = state;
      const { activeStepIndex, hasBlocks, buildingId } = action.payload;

      return {
        ...state,
        buildingId,
        hasBlocks,
        steps: steps.map((step, index) => ({
          ...step,
          isSkipped: !hasBlocks && index === 1,
          isCompleted: activeStepIndex > index,
          isActive: activeStepIndex === index
        }))
      };
    }

    default:
      throw new Error(`${action.type} not found`);
  }
};

export const setFormValue = (key, value) => actionCreator(SET_FORM_VALUE, { key, value });

export const setStep = (index) => actionCreator(SET_STEP, { index });

export const completeStep = (index) => actionCreator(COMPLETE_STEP, { index });

export const setInitialState = (value) => actionCreator(SET_INITIAL_STATE, value);

export const resetForm = (value) => actionCreator(RESET_FORM, value);

export const syncStateWithParams = (value) => actionCreator(SYNC_STATE_WITH_PARAMS, value);

// Selector functions

export const selectActiveStepIndex = (state) => state.steps.findIndex((step) => step.isActive);
