import { useState, forwardRef } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { Form } from "@hydra/atom/components";
import { camelize } from "humps";
import { BoxedContent } from "@/components/common";
import { getDynamicObjectByName } from "@/api/dynamic/dynamicObjectSchemaApi";
import { renderField } from "@/utils/dynamic/helpers";
import { uploadAttachmentWithDynamicObjectRecord } from "@/api/dynamic/dynamicObjectNameApi";

function UploadImageForm({ data, objectName, onSuccess, parentId }, ref) {
  const [imgState, setImgState] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const queryClient = useQueryClient();

  const { data: objectSchema } = useQuery(["dynamic-object-schema", kebabCase(objectName)], () =>
    getDynamicObjectByName(objectName)
  );

  const handleDispatch = (key, value) => {
    setErrorMessage();
    setImgState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    let success = true;
    data.map(async (item) => {
      if ((!imgState[item.name] || imgState[item.name]?.length < 1) && item.required) {
        success = false;
        setErrorMessage(`Upload a signed copy of the ${item.name} before submitting the form.`);
      } else if (imgState[item.name] && imgState[item.name].length) {
        await uploadAttachmentWithDynamicObjectRecord({
          recordId: parentId,
          attachmentFieldName: item.name,
          files: imgState[item.name],
          objectName,
        });
        queryClient.invalidateQueries({
          queryKey: [kebabCase(objectName)],
        });
      }
    });

    if (success && onSuccess) {
      onSuccess(parentId);
    }
  };

  return (
    <BoxedContent>
      <Form
        className="dynamic-object-form dynamic-object-field-form"
        onSubmit={handleSubmit}
        key="dynamic-object-field-form"
      >
        {data.map(
          (item) =>
            objectSchema &&
            renderField({
              field: {
                ...objectSchema.document.find((f) => f.camelizedName === camelize(item.name)),
                required: item.required,
              },
              parentObjectName: objectName,
              state: imgState,
              onChange: handleDispatch,
              isDisabled: false,
            })
        )}
        {errorMessage && <p className="error-text">{errorMessage}</p>}
        <button
          ref={ref}
          type="submit"
          tabIndex={-1}
          className="dynamic-object-field-form-submit-btn"
          style={{ display: "none" }}
        >
          Save
        </button>
      </Form>
    </BoxedContent>
  );
}

export default forwardRef(UploadImageForm);
