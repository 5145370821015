import { getTaxLiabilityRows, getTaxLiabilityColumns } from "./tax-liability-statement";
import { getTaxLiability } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportTaxLiability({ activeCompany }) {
  return (
    <DynamicReport
      reportName="tax-liability"
      activeCompany={activeCompany}
      columnsDataProvider={getTaxLiabilityColumns}
      rowsDataProvider={getTaxLiabilityRows}
      fetchData={getTaxLiability}
      exportUrl="reports/export-query-report/tax-liability"
      filtersInitialState={{}}
      orderBy=""
      showDateRangeFilter={false}
      isPaginated={false}
    />
  );
}
