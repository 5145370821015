import { getOutOfScopeColumns, getOutOfScopeRows } from "./out-of-scope-statement";
import { getOutOfScope } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportOutOfScope({ activeCompany }) {
  return (
    <DynamicReport
      reportName="out-of-scope"
      activeCompany={activeCompany}
      columnsDataProvider={getOutOfScopeColumns}
      rowsDataProvider={getOutOfScopeRows}
      fetchData={getOutOfScope}
      exportUrl="reports/export-query-report/out-of-scope"
      filtersInitialState={{}}
      orderBy="InvoiceNumber"
    />
  );
}
