import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import Rating from "react-rating";
import { isNull, kebabCase } from "lodash";
import { Button } from "@hydra/atom/components";
import { useSelector } from "react-redux";
import { selectActiveApp } from "@/store/appSlice";
import {
  SvgIcon, Status, IconButton, StatusCircle
} from "@/components/common";
import { statusColorMap } from "@/utils/maps/statusColorMap";
import { formatDate } from "@/utils/helpers";

function StatusCell({ value }) {
  return (
    <Status
      baseClassName="status-cell"
      status={value?.value}
      bgColor={statusColorMap.get(value?.value?.toLowerCase())}
    >
      <StatusCircle color={statusColorMap.get(value?.value?.toLowerCase())} />
    </Status>
  );
}

StatusCell.propTypes = {
  value: PropTypes.string.isRequired,
};
function ImgCell({ value }) {
  return (
    <div className="table-img-container">
      {value?.img ? <img loading="lazy" src={value?.img} alt="img" /> : null}
      {value?.label}
    </div>
  );
}

ImgCell.propTypes = {
  value: PropTypes.object.isRequired,
};

function IconCell({ value }) {
  return (
    <div className="table-img-container">
      <SvgIcon icon={value?.icon} />
      {value.name}
    </div>
  );
}

function PercentageCell({ value }) {
  return (
    <div
      className={`table-percentage-container ${
        value?.positive ? "positive-value" : "negative-value"
      }`}
    >
      <div>{value?.value}</div>
      <div className="percentage-container">
        <div className="table-percentage-icon">
          <div className="percentage-icon-size">
            <SvgIcon icon={value?.positive ? "up" : "down"} />
          </div>
          <div className="table-card-percentage">{`${value.percentage}%`}</div>
        </div>
      </div>
    </div>
  );
}

PercentageCell.propTypes = {
  value: PropTypes.object.isRequired,
};

function LinkCell({ row }) {
  return (
    <div className="link-cell">
      <Link to={`details/${row.original.key}`}>{row.original.title}</Link>
    </div>
  );
}

LinkCell.propTypes = {
  row: PropTypes.object.isRequired,
};

function SelectCell({ value }) {
  return (
    <div className="table-img-container">
      {value?.label}
    </div>
  );
}

SelectCell.propTypes = {
  value: PropTypes.object.isRequired,
  label: PropTypes.object.isRequired,
};

function SliderCell({ value }) {
  return (
    <div className="slider-container">
      <div className="slider-container">
        <div
          className={value >= 50 ? "purple-slider" : "red-slider"}
          style={{ width: `${value}%` }}
        />
      </div>
      {value}
      %
    </div>
  );
}

SliderCell.propTypes = {
  value: PropTypes.number.isRequired,
};

function RatingCell({ value }) {
  return (
    <div className="star-table-icon">
      {!isNull(value) ? (
        <Rating
          emptySymbol="fa fa-star-o fa-2x"
          fullSymbol="fa fa-star fa-2x"
          fractions={2}
          readonly
          initialRating={value}
        />
      ) : null}
    </div>
  );
}

RatingCell.propTypes = {
  value: PropTypes.number.isRequired,
};

function ActionCell(data, objectName) {
  const { row } = data;
  const navigate = useNavigate();
  const activeApp = useSelector(selectActiveApp);
  return (
    <div className="action-cell">
      <IconButton
        onClick={() => {
          if (objectName?.toLowerCase() === "work-order-job") {
            navigate(`/${kebabCase(activeApp?.value)}/work-order/${row.original.workOrderId}?jobId=${row.original.jobId}`);
          } else {
            navigate(`/${kebabCase(activeApp?.value)}/${kebabCase(objectName)}/details/${row.original.key}`);
          }
        }}
      >
        <span className="material-icons-outlined">visibility</span>
      </IconButton>
    </div>
  );
}

export function ActionViewCell({ row }) {
  const status = row.values.status.value;
  return (
    <div className="action-cell">
      <Link
        to={status === "Approved" ? "/finance/petty-cash-request/approved" : "/finance/petty-cash-request/rejected"}
      >
        <Button small>
          View
        </Button>
      </Link>
    </div>
  );
}

export function ReportActionViewCell({ row }) {
  const navigate = useNavigate();
  return (
    <div className="action-cell">
      <IconButton
        onClick={() => {
          navigate(`${row.original?.to}`);
        }}
      >
        <span className="material-icons-outlined">visibility</span>
      </IconButton>
    </div>
  );
}

function CreatedAtDateCell({ value }) {
  if (value) {
    return (
      <div className="date-cell">{formatDate(new Date(value))}</div>
    );
  }
}

CreatedAtDateCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const getOverviewTableData = (data) =>
  data.map((workOrder) => ({
    key: workOrder.id,
    title: workOrder.title,
    type: {
      value:
        workOrder?.type?.toLowerCase() === "reactive" ?
          "Corrective" :
          "Preventive",
      color:
        workOrder?.type?.toLowerCase() === "reactive" ? "#7c4dff" : "#FF7600",
    },
    status: {
      value: workOrder?.workOrderStatus?.label,
      color: workOrder?.workOrderStatus?.color,
    },
    createdAt: workOrder?.createdAt,
    action: "",
  }));

export const getReportOverviewTableColumns = () => [
  {
    Header: "Reports",
    accessor: "reportName",
    Cell: IconCell,
  },
  {
    Header: "Category",
    accessor: "category",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ReportActionViewCell,
    collapse: true,
  },
];

export const getWorkOrdersTableColumns = (objectName) => [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Tenant",
    accessor: "tenant",
    Cell: ImgCell,
  },
  {
    Header: "Supervisor",
    accessor: "supervisor",
    Cell: ImgCell,
  },
  {
    Header: "Unit",
    accessor: "unit",
    Cell: SelectCell
  },
  {
    Header: "Category",
    accessor: "category",
    Cell: SelectCell
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusCell,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (data) => ActionCell(data, objectName),
    collapse: true,
  },
];

export const getTechnicianTableColumns = (objectName) => [
  {
    Header: "Number",
    accessor: "number",
  },
  {
    Header: "Technician Name",
    accessor: "name",
  },
  {
    Header: "Designation",
    accessor: "designation.name",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusCell,
  },
  {
    Header: "Total Jobs",
    accessor: "totalJob",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (data) => ActionCell(data, objectName),
    collapse: true,
  },
];

export const getTenantsTableColumns = () => [
  {
    Header: "Number",
    accessor: "number",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Mobile",
    accessor: "mobile",
  }
];

export const getUnitTableColumns = () => [
  {
    Header: "Sr#",
    accessor: "sr#",
  },
  {
    Header: "Unit ID",
    accessor: "unitId",
  },
  {
    Header: "Unit Name",
    accessor: "name",
  },
  {
    Header: "Rent",
    accessor: "rent",
    defaultCanSort: true,
  },
  {
    Header: "Unit Property",
    accessor: "unitProperty"
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionCell,
    collapse: true,
  },
];

export const getTechnicianFinanceTableColumns = () => [
  {
    Header: "Reciept No.",
    accessor: "receiptNo",
  },
  {
    Header: "Type",
    accessor: "type",
    Cell: StatusCell,
  },
  {
    Header: "Payment  Date",
    accessor: "paymentDate",
  },
  {
    Header: "Total Amount",
    accessor: "totalAmount",
  },
];
