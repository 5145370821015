import chroma from "chroma-js";
import {
  addDays, addHours, addMinutes, format, isWithinInterval
} from "date-fns";
import { isEmpty } from "lodash";
import { getFullName } from "@/utils/helpers";
import { statusColorMap } from "@/utils/maps/statusColorMap";

export const formatServiceRequestOption = (serviceRequest) => {
  const data = {
    ...serviceRequest,
    optionLabel: serviceRequest.name,
    label: serviceRequest.name,
    value: serviceRequest.id,
  };

  return data;
};

export const formatBuilding = (building) => ({
  ...building,
  label: building.name,
  value: building.id
});

export const formatServiceRequest = (serviceRequest) => {
  const {
    category, subCategory, unit, tenant, supervisor, building
  } = serviceRequest;

  const data = {
    category: {
      label: category?.name,
      value: category?.id,
    },
    subCategory: null,
    unit: {
      label: unit?.name,
      value: unit?.id,
    },
    building: {
      label: building?.name,
      value: building?.id
    },
    tenant: {
      label: getFullName(tenant),
      value: tenant?.id,
    },
    description: serviceRequest.description,
    optionLabel: serviceRequest.name,
    label: serviceRequest.name,
    value: serviceRequest.id,
  };

  if (serviceRequest?.priority) {
    data.priority = {
      label: serviceRequest?.priority,
      color: statusColorMap.get(serviceRequest?.priority?.toLowerCase()),
      value: serviceRequest?.priority
    };
  }

  if (!isEmpty(subCategory) && subCategory) {
    data.subCategory = {
      label: subCategory?.name,
      value: subCategory?.id,
    };
  }

  if (isEmpty(supervisor)) {
    data.supervisor = null;
  } else {
    data.supervisor = {
      ...supervisor,
      label: supervisor.name,
      value: supervisor.id
    };
  }
  return data;
};

export const makeInterval = (startingHour, endingHour) => {
  if (!startingHour && !endingHour) {
    return null;
  }

  let startTime;
  let endTime;

  if (startingHour) {
    const [startingHours, startingMinutes, _] = startingHour.split(":");
    startTime = new Date(2018, 11, 24, 0, 0);
    startTime = addHours(startTime, startingHours);
    startTime = addMinutes(startTime, startingMinutes);
  }

  if (endingHour) {
    const [endingHours, endingMinutes, _] = endingHour.split(":");
    endTime = new Date(2018, 11, 24, 0, 0);
    endTime = addHours(endTime, endingHours);
    endTime = addMinutes(endTime, endingMinutes);
  }

  return {
    startTime,
    endTime,
  };
};

export const formatTechnicianTime = (startingHour, endingHour) => {
  if (!startingHour && !endingHour) {
    return "";
  }

  const { startTime, endTime } = makeInterval(startingHour, endingHour);

  return `${format(startTime, "hh:mm a")} - ${format(endTime, "hh:mm a")}`;
};

const formatTechnicianDate = () => {
  const startDate = new Date();
  const endDate = addDays(startDate, 10);

  return `${format(startDate, "MMM d")} - ${format(endDate, "MMM d")}`;
};

export const formatTechnician = (technicianSkill) => {

  const workingHours = formatTechnicianTime(technicianSkill.technician.startingHour,
    technicianSkill.technician.endingHour);
  const date = formatTechnicianDate();

  return {
    ...technicianSkill,
    color: chroma.random().hex(),
    designation: technicianSkill.technician?.designation?.name,
    label: getFullName(technicianSkill.technician),
    optionLabel: getFullName(technicianSkill.technician),
    initials: `${technicianSkill.technician?.name[0]}${technicianSkill.technician?.name[1]}`?.toUpperCase(),
    value: technicianSkill.id,
    rate: technicianSkill.perHour,
    currency: "AED",
    startingHour: technicianSkill.technician.startingHour,
    endingHour: technicianSkill.technician.endingHour,
    time: workingHours,
    date,
  };
};

export const formatSupervisor = (supervisor) => ({
  ...supervisor,
  designation: supervisor?.designation?.name,
  label: getFullName(supervisor),
  optionLabel: getFullName(supervisor),
  initials: `${supervisor?.name[0]}${supervisor?.name[1]}`?.toUpperCase(),
  value: supervisor.id,
  rate: supervisor.sellingHourlyRate,
  currency: "AED",
  startingHour: supervisor.startingHour,
  endingHour: supervisor.endingHour,
});

export const formatUnit = (unit) => ({
  ...unit,
  optionLabel: unit.name,
  label: unit.name,
  value: unit.id,
});

export const formatCategory = (category) => ({
  optionLabel: category.name,
  label: category.name,
  value: category.id,
});

export const formatSubCategory = (subCategory) => ({
  optionLabel: subCategory.name,
  label: subCategory.name,
  value: subCategory.id,
  category: {
    label: subCategory.category?.name,
    value: subCategory.category?.id,
  },
});

export const formatItem = (item) => ({
  ...item,
  optionLabel: item.itemName,
  label: item.itemName,
  value: item.id,
  quantity: item?.quantity || 1,
  unit: item?.uom,
  rate: item.sellingRate,
  currency: "AED",
  rateText: `/${item?.uom}`,
});

export const formatVisualInspection = (data) => {

  if (data.jobFor.objectName === "Building") {
    return {
      title: `WorkOrder for ${data.number}`,
      description: data.defect
        ?.map((defect, index) => `${index + 1}. ${defect.description}`)
        .join("\n"),
      jobFor: {
        ...data?.jobFor,
        label: data?.jobFor?.name,
        value: data?.jobFor?.id,
        lookupObjectName: data?.jobFor?.objectName
      },
      building: { label: data.jobFor?.name, value: data.jobFor?.id },
      unit: null,
      visualInspection: {
        value: data?.id
      }
    };
  }
  return {
    title: `WorkOrder for ${data.number}`,
    description: data.defect
      ?.map((defect, index) => `${index + 1}. ${defect.description}`)
      .join("\n"),
    unit: {
      label: data.jobFor.name,
      value: data.jobFor.id,
    },
    building: {
      label: data.jobFor?.building?.name,
      value: data.jobFor?.building?.id
    },
    jobFor: null,
    visualInspection: {
      value: data?.id
    }
  };
};

export const formatIncidentReport = (data) => {
  if (data.jobFor.objectName === "Building") {
    return {
      title: `WorkOrder for ${data.number}`,
      description: data.incident
        ?.map((defect, index) => `${index + 1}. ${defect.description}`)
        .join("\n"),
      jobFor: {
        ...data?.jobFor,
        label: data?.jobFor?.name,
        value: data?.jobFor?.id,
        lookupObjectName: data?.jobFor?.objectName
      },
      building: { label: data.jobFor?.name, value: data.jobFor?.id },
      unit: null,
      incidentReport: {
        value: data?.id
      }
    };
  }
  return {
    title: `WorkOrder for ${data.number}`,
    description: data.incident
      ?.map((defect, index) => `${index + 1}. ${defect.description}`)
      .join("\n"),
    unit: {
      label: data.jobFor.name,
      value: data.jobFor.id,
    },
    building: {
      label: data.jobFor?.building?.name,
      value: data.jobFor?.building?.id
    },
    jobFor: null,
    incidentReport: {
      value: data?.id
    }
  };
};

export const checkOvertime = (job) => {
  const { isVendor } = job;
  if (isVendor) {
    return false;
  }

  let isOvertime = false;
  const { technician, timeRange } = job;

  const [jobStartingTime, jobEndingTime] = timeRange;
  const { startTime: jobStartTime, endTime: jobEndTime } = makeInterval(
    jobStartingTime,
    jobEndingTime
  );

  technician.forEach((t) => {
    const { startTime: technicianStartTime, endTime: technicianEndTime } = makeInterval(
      t.startingHour,
      t.endingHour
    );

    if (
      !isWithinInterval(jobStartTime, {
        start: technicianStartTime,
        end: technicianEndTime,
      })
    ) {
      isOvertime = true;
    }

    if (
      !isWithinInterval(jobEndTime, {
        start: technicianStartTime,
        end: technicianEndTime,
      })
    ) {
      isOvertime = true;
    }
  });

  return isOvertime;
};

export const formatBlanketAgreement = (blanketAgreement) => {
  const data = {
    blanketAgreement: null
  };

  if (blanketAgreement) {
    const { number, id } = blanketAgreement;
    data.blanketAgreement = {
      label: number,
      value: id,
    };

    data.category = {
      label: blanketAgreement?.category.name,
      value: blanketAgreement?.category.id
    };
  }

  return data;
};
