import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Input } from "@hydra/atom/components";
import { formatNumber, formatDecimalValues } from "@/utils/helpers";
import { selectActiveCompany } from "@/store/appSlice";

function CurrencyField({ value, onChange, name, error, ...rest }) {
  const [isEditing, setIsEditing] = useState(false);
  const activeCompany = useSelector(selectActiveCompany);

  const onBlur = (inputValue) => {
    setIsEditing(false);
    if (inputValue) {
      const formattedNumber = formatDecimalValues(inputValue);
      onChange(formattedNumber);
    }
  };

  return (
    <>
      {isEditing ? (
        <Input
          id={name}
          type="number"
          value={value}
          onChange={onChange}
          min="0"
          step="0.01"
          onWheel={(e) => e.target.blur()}
          {...rest}
          onBlur={() => onBlur(value)}
        >
          <Input.AppendIcon>
            <span>{activeCompany?.currencyCode}</span>
          </Input.AppendIcon>
        </Input>
      ) : (
        <Input
          id={name}
          type="text"
          value={value ? formatNumber(value) : ""}
          onFocus={() => setIsEditing(true)}
          readOnly
          {...rest}
        >
          <Input.AppendIcon>
            <span>{activeCompany?.currencyCode}</span>
          </Input.AppendIcon>
        </Input>
      )}
      {typeof error === "string" && <span className="error-text">{error}</span>}
    </>
  );
}

CurrencyField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

CurrencyField.defaultProps = {
  name: "currency-field",
  value: "",
  onChange: () => {},
  error: false,
};

CurrencyField.Type = "HydraValidatableComponent";

export default CurrencyField;
