import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { sortBy } from "lodash";
import { CustomActionDropdown } from "@/components/dynamic";
import { checkPermission } from "@/store/userSlice";

function ActionCell({ row }) {
  const navigate = useNavigate();
  const filteredActions = () => {
    const actions = [
      {
        title: "View",
        onClick: () => navigate(`${row.original.key}/fields`),
        icon: "eye"
      },
    ];

    return actions.filter((item) => checkPermission("Model", row.original.name, item.title));
  };

  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={filteredActions()}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId={`Dynamic-Object-Table-Data-${row.original.name}`}
      />
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
};

function LinkCell({ row }) {
  return (
    <div className="link-cell">
      <Link to={`${row.original.key}/fields`}>{row.original.name}</Link>
    </div>
  );
}

LinkCell.propTypes = {
  row: PropTypes.object.isRequired,
};

function StatusCell({ row }) {
  return (
    <div className={`status-cell ${row?.original?.status?.includes("Develop") ? "red" : "green"}`}>
      <div className="dot" />
      {row.original.status || "Deployed"}
    </div>
  );
}

StatusCell.propTypes = {
  row: PropTypes.object.isRequired,
};

export const getDynamicObjectColumns = () => [
  {
    Header: "Name",
    accessor: "name",
    Cell: LinkCell
  },
  {
    Header: "Label",
    accessor: "label",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusCell
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionCell,
    collapse: true
  }
];

export const getDynamicObjectData = (data) => {
  const sorted = sortBy(data, [(o) => o.objectName]);

  return sorted.map((dynamicObject) => ({
    key: dynamicObject.id,
    name: dynamicObject.objectName,
    label: dynamicObject.label,
    status: dynamicObject.deploymentStatus,
    action: "",
  }));
};
