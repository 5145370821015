import request from "@/utils/api/helpers";

const baseUrl = "/finance/currencies";

export const getCurrencies = () => request({
  url: baseUrl
});

export const getCurrencyById = (id) => request({
  url: `${baseUrl}/${id}`
});

export const createCurrency = (data) => request({
  url: baseUrl,
  method: "POST",
  data
});

export const updateCurrency = (id, data) => request({
  url: `${baseUrl}/${id}`,
  method: "PATCH",
  data
});

export const deleteCurrency = (id) => request({
  url: `${baseUrl}/${id}`,
  method: "DELETE",
});
