import PropTypes from "prop-types";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { getTestId } from "@/utils/helpers";
import { useModal } from "@/hooks";
import { SvgIcon } from "@/components/common";

function ActionDropdownItem({ action, onClick, testId }) {
  return (
    <Dropdown.Item
      className={`dropdown-menu-item action-menu-item ${action.title === "Delete" ? "action-menu-delete-item" : ""}`}
      onSelect={onClick}
      data-testid={getTestId(`${testId}-${action.title}-Button`)}
    >
      {action?.icon && <SvgIcon icon={action.icon} />}
      <a>{action.title}</a>
    </Dropdown.Item>
  );
}

ActionDropdownItem.propTypes = {
  action: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

ActionDropdownItem.defaultProps = {
  testId: "",
};

function ActionDropdownContent({ actions, testId }) {
  return (
    <Dropdown.Content className="dropdown-menu-content action-dropdown-menu" side="bottom" align="end">
      {actions.map((action, index) => (
        <ActionDropdownItem key={`action-${index}`} action={action} onClick={action.onClick} testId={testId} />
      ))}
    </Dropdown.Content>
  );
}

ActionDropdownContent.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  testId: PropTypes.string,
};

ActionDropdownContent.defaultProps = {
  testId: "",
};

export function CustomActionDropdown({ actions, trigger, testId }) {
  const {
    open: openDropdown,
  } = useModal(false);
  const order = ["View", "Edit", "Delete", "Print"];

  const sortedActions = actions.sort((a, b) => {
    const indexA = order.indexOf(a.title);
    const indexB = order.indexOf(b.title);
    return indexA - indexB;
  });

  return (
    actions?.length > 1 ? (
      <Dropdown.Root data-testid={getTestId(testId)}>
        <Dropdown.Trigger asChild data-testid={getTestId(`${testId}-Button`)} onClick={openDropdown}>
          {trigger}
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <ActionDropdownContent actions={sortedActions} testId={testId} />
        </Dropdown.Portal>
      </Dropdown.Root>
    ) : (
      <div className="single-action-icon" role="presentation" onClick={sortedActions[0].onClick} data-testid={getTestId(testId)}>
        <SvgIcon icon={sortedActions[0].icon} />
      </div>
    )
  );
}

CustomActionDropdown.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  trigger: PropTypes.node.isRequired,
};

export default CustomActionDropdown;
