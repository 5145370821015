import { getColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getTaxLiabilityColumns() {
  const windowWidth = window.innerWidth - 100;
  const totalColumns = 5;
  const colArr = [
    {
      label: "Tax Name",
      value: true,
      columnId: "taxName",
      resizable: true,
      width: getColumnWidth(1 / totalColumns, windowWidth),
      formatter: (value) => String(value),
    },
    {
      label: "Tax Rate",
      value: true,
      columnId: "taxrate",
      width: getColumnWidth(1 / totalColumns, windowWidth),
      resizable: true,
      formatter: (value) => String(`${value || 0}%`),
    },
    {
      label: "Taxable Amount",
      value: true,
      columnId: "taxableAmount",
      width: getColumnWidth(1 / totalColumns, windowWidth),
      formatter: (value) => formatCurrency(String(value || "0"))
    },
    {
      label: "Net Amount",
      value: true,
      columnId: "amount",
      width: getColumnWidth(1 / totalColumns, windowWidth),
      formatter: (value) => formatCurrency(String(value || "0"))
    },
  ];
  return colArr;
}
