import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";

import { Button } from "@hydra/atom/components";
import { BoxedContent, Header, NoDataFound } from "@/components/common";
import { FinanceMasterTable } from "@/components/finance";
import { SelectBudgetTypeModal } from "@/components/modals";
import {
  getBudgetListViewTableColumns,
  getBudgetListViewTableData,
} from "@/components/finance/budget/budgetTableData";
import { getBudgets, deleteBudget } from "@/api/finance/budgetApi";
import { skeletonLoadingRows } from "@/utils/helpers";
import { useModal } from "@/hooks";
import showToast from "@/utils/toast/helpers";

function Budget() {
  const { isOpen, openModal, closeModal } = useModal();

  const { data, isLoading } = useQuery(["budget"], getBudgets);

  const queryClient = useQueryClient();
  const deleteMutation = useMutation(
    deleteBudget,
    {
      onError: () => {
        showToast("Could not delete. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Deleted successfully", "success");
        queryClient.invalidateQueries({
          queryKey: ["budget"]
        });
      },
    }
  );

  const renderTable = () => {
    if (!isLoading && data && !data?.data) {
      return (
        <NoDataFound
          title="No budget has been added"
          buttonText="Add budget"
          onClick={() => openModal}
        />
      );
    }

    return (
      <FinanceMasterTable
        objectName="Budget"
        isLoading={isLoading}
        tableColumns={getBudgetListViewTableColumns(data?.data || [])}
        data={getBudgetListViewTableData(data?.data || skeletonLoadingRows(5))}
        handleDelete={(id) => deleteMutation.mutate(id)}
      />
    );
  };

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<h1>Budget</h1>}
        rightContent={(
          <Button small onClick={openModal}>
            Add budget
          </Button>
        )}
      />

      <SelectBudgetTypeModal isOpen={isOpen} onClose={closeModal} />
      {renderTable()}
    </BoxedContent>
  );
}

export default Budget;
