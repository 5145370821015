import { useEffect, useState } from "react";
import { Loader } from "@hydra/atom/components";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { ReportCard } from "@/components/property";
import { BoxedContent, Header, IconButton } from "@/components/common";
import { useUser } from "@/hooks";
import { getDashboardData } from "@/api/dynamic/dynamicDashboardApi";
import {
  BarChart, PieChart, LineChart, PieChartLegendItem
} from "@/components/charts";
import { DynamicObjectActivityDrawer } from "@/components/dynamic";

function DetailItem({ heading, children }) {
  return (
    <div className="row">
      <div className="col-12">
        <h6 className="headline">{heading}</h6>
        {children}
      </div>
    </div>
  );
}

DetailItem.propTypes = {
  heading: PropTypes.string.isRequired,
};

// Assuming months are in a specific order, you can create an array of month names
const allMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
function FacilityDashboard() {
  const { user } = useUser();
  const navigate = useNavigate();
  const [totalTask, setTotalTask] = useState();
  const [completedTask, setCompletedTask] = useState();
  const [inProgressTask, setInProgressTask] = useState();
  const [workOrdersStatus, setWorkOrdersStatus] = useState();
  const [workOrdersTrend, setWorkOrdersTrend] = useState();
  const [workOrdersTrendLine, setWorkOrdersTrendLine] = useState();
  const [openFiltersDrawer, setOpenActivityDrawer] = useState(false);
  const pieColor = ["#12B76A", "#9E77ED", "#EE46BC", "#363F72"];
  const pieProps = {
    width: 300,
    height: 250,
    margin: {
      top: 7,
      right: 0,
      bottom: 7,
      left: 0,
    },
    innerRadius: 0.5,
    padAngle: 0,
    cornerRadius: 0,
    activeOuterRadiusOffset: 4,
    colors: pieColor,
    colorBy: "index",
    borderWidth: 1,
    borderColor: {
      from: "color",
      modifiers: [["darker", 0.2]],
    },
    enableArcLabels: false,
    enableArcLinkLabels: false,
    legends: false,
    animate: true,
    motionConfig: "slow",
  };
  const { data, isLoading } = useQuery(
    ["facility-detail"],
    () => {
      const params = [
        {
          Name: "Year",
          Label: "@Year",
          Value: `${new Date().getFullYear()}`,
        },
      ];
      if (user?.roles.includes("supervisor")) {
        params.push({
          Name: "Supervisor",
          Label: "@Supervisor",
          Value: `${user.userKey}`,
        });
      }
      return getDashboardData({
        DashboardName: "facility-dashboard",
        Params: params,
      });
    },
    {
      enabled: Boolean(user?.userKey),
    }
  );

  const makeChartData = (items) => {
    const getItemData = (status, defaultLabel) => {
      const filteredItems = items.filter((item) => item.status === status);
      const value = filteredItems.length ? filteredItems[0].total : 0;
      return {
        id: status,
        label: filteredItems.length ? filteredItems[0].status : defaultLabel,
        value,
        color: `hsl(${status === "Closed" ? 9 : 346}, 70%, 50%)`,
      };
    };

    const completed = getItemData("Completed", "Completed");
    const closed = getItemData("Closed", "Closed");
    const inComplete = getItemData("Pending", "Pending");
    // inComplete.value = items
    //   .filter((item) => item.status !== "Escalated" && item.status !== "Completed")
    //   .reduce((accumulator, item) => accumulator + item.total, 0);

    return [completed, closed, inComplete];
  };

  useEffect(() => {
    if (!data) return;
    const setTaskData = (taskName, setData) => {
      const taskData = data.data.find((item) => item.name === taskName);
      setData(taskData);
    };

    setTaskData("TotalTask", setTotalTask);
    setTaskData("CompletedTask", setCompletedTask);
    setTaskData("InProgressTask", setInProgressTask);
    setWorkOrdersStatus(data.data.filter((item) => item.name === "WorkOrdersStatus")?.[0]?.data);
    const chartData = allMonths.map((month) => {
      const tempChartdata =
        data.data
          .filter((item) => item.name === "WorkOrdersTrend")?.[0]
          ?.data?.find((item) => item.creationMonth?.trim() === month)?.recordCount || 0;
      return {
        label: new Date(`${month} 1, 2000`).toLocaleString("en-US", { month: "short" }),
        Completed: tempChartdata,
        valueColor: "#9E77ED",
      };
    });

    setWorkOrdersTrend(chartData);
    const generateChartData = (yearOffset) =>
      allMonths.map((month) => {
        const tempChartdata =
          data.data
            .filter((item) => item.name === "WorkOrdersTrendLine")?.[0]
            ?.data.find(
              (item) =>
                item.creationMonth?.trim() === month &&
                item.creationYear === `${new Date().getFullYear() - yearOffset}`
            )?.recordCount || 0;

        return {
          x: new Date(`${month} 1, 2000`).toLocaleString("en-US", { month: "short" }),
          y: tempChartdata,
        };
      });

    const chartLineData = generateChartData(0); // Current year
    const chartLineDataPastYear = generateChartData(1); // Past year

    const tempData = [
      {
        id: "This year",
        color: "hsl(4, 70%, 50%)",
        data: chartLineData,
      },
      {
        id: "Past year",
        color: "hsl(4, 70%, 50%)",
        data: chartLineDataPastYear,
      },
    ];

    setWorkOrdersTrendLine(tempData);
  }, [data]);

  const rightContent = (title) => (
    <div className="container-right-content">
      <IconButton
        className="icon-button-with-text"
        onClick={() => {
          navigate("/facility-management/work-orders");
        }}
      >
        {title}
      </IconButton>
    </div>
  );

  const leftContent = (title, subtitle) => (
    <div className="container-left-content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );

  return (
    <BoxedContent className="dashboard">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header
            className="report-dashboard-header"
            showBreadcrumb
            leftContent={(
              <div>
                <h1>Facility Dashboard</h1>
              </div>
            )}
          />
          <div className="report-container">
            <div className="report-img-container dashboard-container card">
              <Header
                leftContent={leftContent(
                  "Monthly Completion of Work Orders",
                  "A Yearly Overview of Task Execution Efficiency"
                )}
                rightContent={rightContent("View Work orders")}
              />
              <div>
                <BarChart
                  data={workOrdersTrend || []}
                  keys={["Completed"]}
                  colors={["#12B76A"]}
                  legends
                />
              </div>
            </div>
            <div className="cards-container">
              <div className="card-container">
                <ReportCard
                  data={[
                    {
                      titleValue: totalTask?.data[0]?.total,
                      titleColor: "primary",
                      subtitle: "Total Jobs",
                      subtitleColor: "gray",
                    },
                    {
                      titleValue: completedTask?.data[0]?.total,
                      titleColor: "primary",
                      subtitle: "Completed  Jobs",
                      subtitleColor: "gray",
                    },
                    {
                      titleValue: inProgressTask?.data[0]?.total,
                      titleColor: "primary",
                      subtitle: "In Progress Jobs",
                      subtitleColor: "gray",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="work-order-charts-container">
            <div className="header-chart card">
              <Header
                leftContent={leftContent(
                  "Current Work Order Breakdown",
                  "Categorical Status Overview of Work Orders"
                )}
              />
              <div className="pie-chart-container">
                <PieChart
                  data={workOrdersStatus ? makeChartData(workOrdersStatus) : []}
                  config={pieProps}
                />
                <div className="pie-chart-legend-container">
                  <PieChartLegendItem
                    title="Pending"
                    subtitle={
                      workOrdersStatus?.filter((item) => item.status === "Pending")?.[0]?.total || 0
                    }
                    color={pieColor[2]}
                  />
                  <PieChartLegendItem
                    title="Active"
                    subtitle={
                      workOrdersStatus?.filter((item) => item.status === "Active")?.[0]?.total || 0
                    }
                    color={pieColor[1]}
                  />
                  <PieChartLegendItem
                    title="Completed"
                    subtitle={
                      workOrdersStatus?.filter((item) => item.status === "Completed")?.[0]?.total ||
                      0
                    }
                    color={pieColor[0]}
                  />
                  <PieChartLegendItem
                    title="Closed"
                    subtitle={
                      workOrdersStatus?.filter((item) => item.status === "Closed")?.[0]?.total || 0
                    }
                    color={pieColor[3]}
                  />
                </div>
              </div>
            </div>
            <div className="header-chart card">
              <Header
                leftContent={leftContent(
                  "Annual Dynamics of Work Order Volumes",
                  "Monthly Fluctuations and Workload Analysis"
                )}
              />
              <LineChart data={workOrdersTrendLine} />
            </div>
          </div>
        </>
      )}
      <DynamicObjectActivityDrawer isOpen={openFiltersDrawer} onClose={setOpenActivityDrawer} />
    </BoxedContent>
  );
}

FacilityDashboard.propTypes = {};

export default FacilityDashboard;
