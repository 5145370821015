import { actionCreator } from "@/utils/helpers";

export const SET_FORM_VALUE = "SET_FORM_VALUE";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";

export const intervalOptions = [
  {
    label: "Monthly",
    value: "Monthly"
  },
  {
    label: "Quarterly",
    value: "Quarterly"
  },
  {
    label: "Yearly",
    value: "Yearly"
  },
];

export const initialState = {
  name: "",
  code: "",
  accountPeriod: null,
  interval: intervalOptions[0],
  subDividedBy: "DontSubdivide",
  subDividedFor: "",
};

export const budgetFormReducer = (state, action) => {
  switch (action.type) {
    case SET_FORM_VALUE:
      return ({
        ...state,
        [action.payload.key]: action.payload.value
      });

    case SET_INITIAL_STATE:
      return {
        ...action.payload
      };

    default:
      throw new Error(`${action.type} not found`);
  }
};

export const setFormValue = (key, value) => actionCreator(SET_FORM_VALUE, { key, value });

export const setInitialState = (value) => actionCreator(SET_INITIAL_STATE, value);
