const enTranslations = {
  login: {
    title: "Login into Your Existing Account",
    subtitle: "Enter your existing account details",
    email: {
      label: "Email",
      placeholder: "john.doe@tuli.com",
      error: "This field is required",
      emailError: "Please enter a valid email address",
    },
    password: {
      label: "Password",
      error: "This field is required",
      minError: "Password is too short",
    },
    link: {
      forgotPassword: "Forgot password?"
    },
    rememberMe: {
      label: "Remember me"
    },
    submitButtonText: "Login",
  },
  forgotPassword: {
    title: "Forgot Password",
    subtitle: "Please enter your email address. You will receive a link to create a new password via email.",
    email: {
      label: "Email",
      placeholder: "john.doe@tuli.com",
      error: "This field is required",
      emailError: "Please enter a valid email address",
    },
    sendAgainButton: "Didn't receive the code?",
    cancelButton: "Cancel",
    submitButtonText: "Send code",
  },
  resetPassword: {
    title: "Reset Password",
    subtitle: "Create a new password for your account",
    password: {
      label: "Password",
      placeholder: "Password",
      error: "This field is required",
      minError: "Password should be at least 8 characters long",
    },
    confirmPassword: {
      label: "Confirm Password",
      placeholder: "Confirm Password",
      error: "This field is required",
      inError: "Passwords do not match",
    },
    submitButtonText: "Reset password"
  }
};

export default enTranslations;
