import {
  useState, useRef, useMemo
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import { Input, Button, Pagination } from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { SvgIcon, CustomDropdown, IconButton } from "@/components/common";
import { getOccupancyReportRows, getOccupancyReportColumns } from "./occupancy-statement";
import { getOccupancyReport } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";
import appSettings from "@/settings";
import { ReportFilterDrawer } from "@/components/drawers";

const perPageOptions = [
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "150", value: 150 },
];

function ReportOccupancy({ activeCompany }) {
  const ref = useRef();
  // eslint-disable-next-line no-unused-vars
  const [columnsData, setColumnsData] = useState(
    getOccupancyReportColumns(ref?.current?.clientWidth)
  );
  const [filterText, setFilterText] = useState("");
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    UnitStatus: "*",
    ComponentName: "*",
    UnitType: "*",
    Emirate: "*",
  });
  const [isOpen, setIsOpen] = useState(false);

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };

  const formatFilters = (data) => {
    const filterData = {
      UnitStatus: "*",
      ComponentName: "*",
      Emirate: "*",
      UnitType: "*",
    };

    if (!data) { return filterData; }

    if (data?.unitType) {
      filterData.UnitType = data.unitType.value;
    }

    if (data?.unitStatus) {
      filterData.UnitStatus = data.unitStatus.value;
    }

    if (data?.componentName) {
      filterData.ComponentName = data?.ComponentName;
    }

    if (data?.emirate) {
      filterData.Emirate = data?.emirate;
    }

    return filterData;
  };

  const onClose = (value) => {
    setIsOpen(value);
  };

  const exportExcel = () => window.open(`${appSettings.baseUrl}/reports/export-query-report/occupancy?CompanyId=${activeCompany.id}&${qs.stringify(formatFilters(filters))}`, "_blank", "noopener,noreferrer");

  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);

  const { data: occupancyData, isLoading } = useQuery(["occupancy", currentPage, perPage.value, filters], () => getOccupancyReport({
    pageNumber: currentPage,
    pageSize: perPage.value,
    orderBy: "ComponentName",
    ...formatFilters(filters)
  }));

  const totalCount = useMemo(() => occupancyData?.totalCount || 0, [occupancyData]);

  const formatReport = (transaction) => {
    if (filterText) {
      return transaction.filter((obj) => obj?.unitName.toLowerCase().includes(filterText.toLowerCase()));
    }
    return transaction;
  };

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const rows = useMemo(
    () =>
      getOccupancyReportRows({
        data: {
          data: formatReport(occupancyData?.data) || [],
        },
        columns,
      }),
    [occupancyData, columns, filterText]
  );

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-6">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search By Unit Name"
          />
        </div>
        <div className="col-md-6 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
          <Button
            onClick={() => setIsOpen(true)}
            small
            bordered
          >
            <Button.Prepend>
              <SvgIcon icon="filter-lines" />
            </Button.Prepend>
          </Button>
          <ReportFilterDrawer filters={filters} setFilters={setFilters} type="occupancy" size={400} isOpen={isOpen} onClose={onClose} />
        </div>
      </div>
      <div className="react-grid-container">
        { !isLoading ? (
          <ReactGrid
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        ) : <TableSkeleton /> }
        {!isLoading && (
        <Pagination
          className="dashboard-pagination"
          options={perPageOptions}
          perPage={perPage}
          onSelectChange={(val) => handlePagination(currentPage, val)}
          pageRangeDisplayed={3}
          pageNo={currentPage}
          handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
          showResults
          offset={0}
          totalItems={totalCount}
          pageCount={Math.ceil(totalCount / perPage.value)}
          reactPaginateProps={{
            previousLabel: <span className="material-icons">&#xe5cb;</span>,
            nextLabel: <span className="material-icons">&#xe5cc;</span>,
            forcePage: currentPage - 1,
          }}
        />
        )}
      </div>
    </div>
  );
}

export default ReportOccupancy;
