import { useMemo, useState } from "react";
import { Loader } from "@hydra/atom/components";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { kebabCase } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { pascalize } from "humps";

import { SimpleSlider, ReportCard, TabWithBadge } from "@/components/property";
import { DynamicObjectActivityDrawer, ActionDropdown } from "@/components/dynamic";
import {
  BoxedContent, Header, Accordion, TwoColumnList, NoDataFound
} from "@/components/common";
import building from "@/assets/images/building.png";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import {
  getDynamicObjectRecordById,
  getDynamicObjectRecords,
} from "@/api/dynamic/dynamicObjectNameApi";
import DynamicObjectOverview from "@/pages/dynamic/list-views/DynamicObjectOverview";
import { getAttachmentUrl } from "@/utils/helpers";
import DynamicObjectDetails from "@/pages/dynamic/detail-views/DynamicObjectDetails";
import { getDynamicObjectByNameWithCamelizedFieldNames } from "@/api/dynamic/dynamicObjectSchemaApi";

function HeaderRightContent({
  objectName, selectedId, recordValues, data
}) {
  return (
    <div className="buttons-at-end">
      <ActionDropdown
        objectName={pascalize(objectName)}
        id={selectedId}
        data={recordValues}
        actions={data?.actions || []}
        showTableActions
        removeActions={["View"]}
        testId="Action-Dropdown"
        trigger={(
          <div className="action-dropdown-trigger">
            <span className="text">Actions</span>
            <span className="material-icons-outlined">expand_more</span>
          </div>
        )}
      />
    </div>
  );
}

function CustomSlide(img) {
  return (
    <div className="custom-slide-container">
      <img loading="lazy" height="450px" src={img} alt="building" style={{ width: "100%" }} />
    </div>
  );
}

const renderList = (items, className = "col-md-3") => (
  <div className="card">
    <div className="list-container">
      {items &&
        items.map((item, index) => (
          <div key={`item-${index}`} className={className}>
            {item}
          </div>
        ))}
    </div>
  </div>
);

function DetailItem({ heading, children }) {
  return (
    <div className="row">
      <div className="col-12">
        <h6 className="headline">{heading}</h6>
        {children}
      </div>
    </div>
  );
}

DetailItem.propTypes = {
  heading: PropTypes.string.isRequired,
};

// TODO: refactor when api is available.
const isLoading = false;

function UnitDetail() {
  const [openFiltersDrawer, setOpenActivityDrawer] = useState(false);
  const [activeTab, setStatusTab] = useState("Overview");

  const { unitId } = useParams();

  const { data: objectSchema } = useQuery(
    ["dynamic-object-camelized-schema", dynamicObjectMap.get("UnitObjectName")],
    () => getDynamicObjectByNameWithCamelizedFieldNames(dynamicObjectMap.get("UnitObjectName")),
    {
      enabled: Boolean(unitId),
    }
  );

  const { data: unitData, isInitialLoading } = useQuery(
    [kebabCase(dynamicObjectMap.get("UnitObjectName")), unitId],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("UnitObjectName"), unitId),
    {
      enabled: Boolean(unitId),
    }
  );

  const barCode = useMemo(() => unitData?.barCode, [unitData]);

  const { data: contractData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ContractObjectName")), "Contract", unitId],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("ContractObjectName"), {
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
        unit: unitId,
      })
  );

  const { data: tenantData } = useQuery(
    [kebabCase(dynamicObjectMap.get("TenantObjectName")), "Tenant", unitId],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("TenantObjectName"), {
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
        unit: unitId,
      })
  );

  const { data: serviceRequestData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ServiceRequestObjectName")), "ServiceRequest", unitId],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("ServiceRequestObjectName"), {
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
        unit: unitId,
      })
  );

  const activeContracts =
    contractData?.data.filter(({ status }) => status === "Active").length || 0;
  const contractHistory = contractData?.totalCount || 0;
  const tenantHistory = tenantData?.totalCount || 0;
  const maintenanceRequestHistory = serviceRequestData?.totalCount || 0;

  const tabs = [
    { label: "Overview" },
    { label: "Keys" },
    { label: "Service Requests" },
    { label: "Tenant History" },
    { label: "Contract History" },
  ];

  if (isInitialLoading) {
    return <Loader />;
  }

  const handleStatusTabChange = (index) => {
    setStatusTab(tabs[index].label);
  };

  // Note: Use Tab component for this. Make TabWithBadge accept children.
  const renderView = () => {
    switch (activeTab) {
      case "Overview":
        return (
          <div className="accordion-container">
            <Accordion title="Unit Details">
              <DynamicObjectDetails objectName="Unit" id={unitId} showHeader={false} />
            </Accordion>
          </div>
        );
      case "Tenant History":
        return (
          <DynamicObjectOverview
            objectName={dynamicObjectMap.get("TenantObjectName")}
            showActionCell={false}
            showBreadcrumb={false}
            showActionButton={false}
            filters={{
              unit: unitId,
            }}
          />
        );
      case "Keys":
        return unitData.keys.length > 0 ? (
          <div className="accordion-container">
            {unitData.keys.length > 0 && (
              <Accordion title="Keys">
                <DetailItem>
                  {renderList(
                    [
                      <div className="d-flex align-items-center justify-content-between">
                        {unitData.keys.map((key) => (
                          <TwoColumnList
                            className="gap-20"
                            data={[
                              {
                                label: key.item,
                                value: key.quantity,
                              },
                            ]}
                          />
                        ))}
                      </div>,
                    ],
                    "col-md-6"
                  )}
                </DetailItem>
              </Accordion>
            )}
          </div>
        ) : (
          <NoDataFound title="No Keys Available" objectName="UnitKey" />
        );
      case "Service Requests":
        return (
          <DynamicObjectOverview
            objectName={dynamicObjectMap.get("ServiceRequestObjectName")}
            showActionCell={false}
            showBreadcrumb={false}
            showActionButton={false}
            filters={{
              unit: unitId,
            }}
          />
        );
      case "Contract History":
        return (
          <DynamicObjectOverview
            objectName={dynamicObjectMap.get("ContractObjectName")}
            showActionCell={false}
            showBreadcrumb={false}
            showActionButton={false}
            filters={{
              unit: unitId,
            }}
          />
        );
      default:
        return null;
    }
  };
  return (
    <BoxedContent className="dashboard">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header
            className="report-dashboard-header"
            showBreadcrumb
            leftContent={(
              <div>
                <h1>{`${unitData.name} (${unitData.number})`}</h1>
              </div>
            )}
            rightContent={(
              <HeaderRightContent
                objectName={dynamicObjectMap.get("UnitObjectName")}
                selectedId={unitId}
                recordValues={unitData}
                data={objectSchema}
              />
            )}
          />
          <div className="report-container justify-content-between">
            <div className="report-img-container">
              <SimpleSlider
                items={
                  unitData?.photos?.length > 0 ?
                    [CustomSlide(getAttachmentUrl(unitData.photos[0]))] :
                    [CustomSlide(building), CustomSlide(building), CustomSlide(building)]
                }
              />
            </div>
            <div className="right-container">
              {barCode && <img src={`data:image/png;base64,${barCode}`} alt="qrcode" />}
              <div className="cards-container">
                <div className="card-container">
                  <ReportCard
                    data={[
                      {
                        titleValue: "0",
                        titleColor: "success",
                        subtitle: "Total Income (AED)",
                        subtitleColor: "primary",
                      },
                      {
                        titleValue: "0",
                        titleColor: "error",
                        subtitle: "Total Expense (AED)",
                        subtitleColor: "primary",
                        positive: true,
                      },
                      {
                        titleValue: `${activeContracts}`,
                        titleColor: "primary",
                        subtitle: "Active Contracts",
                        subtitleColor: "primary",
                        positive: false,
                      },
                    ]}
                  />
                </div>
                <div className="card-container">
                  <ReportCard
                    data={[
                      {
                        titleValue: `${maintenanceRequestHistory}`,
                        titleColor: "primary",
                        subtitle: "Maintenance Request",
                        subtitleColor: "primary",
                      },
                      {
                        titleValue: `${tenantHistory}`,
                        titleColor: "primary",
                        subtitle: "Tenant History",
                        subtitleColor: "primary",
                        positive: true,
                      },
                      {
                        titleValue: `${contractHistory}`,
                        titleColor: "primary",
                        subtitle: "Contract History",
                        subtitleColor: "primary",
                        positive: false,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
          <TabWithBadge
            tabs={tabs}
            className="primary-tabs custom-primary-tabs"
            onClick={handleStatusTabChange}
          />
          {renderView()}
        </>
      )}
      <DynamicObjectActivityDrawer isOpen={openFiltersDrawer} onClose={setOpenActivityDrawer} />
    </BoxedContent>
  );
}

UnitDetail.propTypes = {};

export default UnitDetail;
