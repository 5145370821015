import { useEffect, useState } from "react";
import { Button } from "@hydra/atom/components";
import { Header, BoxedContent, SvgIcon, HeaderLeftContent } from "@/components/common";
import { TabWithBadge } from "@/components/property";
import {
  ListView, UpcomingView, BoardView, CalendarView
} from "@/components/facility/planboard";
import { SelectWorkOrderTypeModal } from "@/components/modals";
import { useUser } from "@/hooks";

const agendaSubViewOptions = [
  {
    value: "day",
    label: "Day",
  },
  {
    value: "week",
    label: "Week",
  },
  {
    value: "month",
    label: "Month",
  },
  {
    value: "year",
    label: "Year",
  },
];

// TODO: refactor state management and effects
function PlanBoard() {
  const [subViewOptions] = useState(agendaSubViewOptions);
  const [, setCurrentView] = useState(agendaSubViewOptions[1].value);
  const [subViewOption] = useState(subViewOptions[1]);
  const [activeTab, setStatusTab] = useState("Upcoming");
  const [openSelectWorkOrderTypeModal, setOpenSelectWorkOrderTypeModal] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    setCurrentView(subViewOption.value);
  }, [subViewOption]);

  const tabs = [
    { label: "Upcoming" },
    { label: "List" },
    { label: "Board" },
    { label: "Calender" },
  ];

  const handleStatusTabChange = (index) => {
    setStatusTab(tabs[index].label);
  };

  const renderView = () => {
    switch (activeTab) {
      case "Upcoming":
        return <UpcomingView />;
      case "List":
        return <ListView />;
      case "Board":
        return <BoardView />;
      case "Calender":
        return <CalendarView />;
      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
  };

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={(
          <HeaderLeftContent
            title={`${user.name}'s Plan Board`}
            subtitle="Supercharge your workflow and connect the tool you use every day."
          />
        )}
        rightContent={(
          <div className="buttons-at-end">
            <Button
              small
              onClick={() => setOpenSelectWorkOrderTypeModal(true)}
              className="btn-with-icon"
            >
              <Button.Prepend>
                <SvgIcon icon="plus-icon" />
              </Button.Prepend>
              Work Order
            </Button>
          </div>
        )}
      />
      <TabWithBadge tabs={tabs} className="primary-tabs" onClick={handleStatusTabChange} />
      {renderView()}
      <SelectWorkOrderTypeModal
        isOpen={openSelectWorkOrderTypeModal}
        onClose={setOpenSelectWorkOrderTypeModal}
      />
    </BoxedContent>
  );
}

export default PlanBoard;
