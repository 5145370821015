import { getColumnWidth } from "../helpers";
import { formatDate } from "@/utils/helpers";

export default function getFarColumns() {
  const windowWidth = window.innerWidth - 300;
  const totalColumns = 28;

  const colArr = [
    {
      label: "Building", value: true, columnId: "building", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Component", value: true, columnId: "component", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit No", value: true, columnId: "unitNr", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Assets Category", value: true, columnId: "assetCategory", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Asset Number", value: true, columnId: "assetNumber", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Assets Description", value: true, columnId: "assetDescription", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Assets Status (Active/ Non Active)", value: true, columnId: "assetStatus", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Model", value: true, columnId: "model", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Serial Number", value: true, columnId: "serialNr", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Purchased Date", value: true, columnId: "purchaseDate", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Sold Date", value: true, columnId: "soldDate", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Transfer Date", value: true, columnId: "transferDate", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Life", value: true, columnId: "life", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "GL Code GST", value: true, columnId: "gLCodeCost", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? String(value) : "")
    },
    {
      label: "GL Code Cum Dept", value: true, columnId: "glCodeCumDep", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? String(value) : "")
    },
    {
      label: "GL Code Dep", value: true, columnId: "glCodeDep", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? String(value) : "")
    },
    {
      label: "Intial Cost", value: true, columnId: "initialCost", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Additional Cost", value: true, columnId: "additionalCost", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Revaluation [latest]", value: true, columnId: "Revaluation", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Total Cost", value: true, columnId: "totalCosts", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Dep For Month", value: true, columnId: "depreciationMonth", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "YTD Depreciation", value: true, columnId: "depreciationYearToDate", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "LTD Depreciation", value: true, columnId: "lifeTimeToDateDepreciation", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Net Book Val", value: true, columnId: "netBookVal", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Disposal Proceeds [asset received amt]", value: true, columnId: "disposalsProceeds", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Gain/Loss on Disposal", value: true, columnId: "gainLossDisposal", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "GL Code Gain/Loss on Disposal", value: true, columnId: "glCodeGainLossDisposal", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "GL Code Revaluation", value: true, columnId: "glCodeRevluation", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Supplier Code", value: true, columnId: "supplierCode", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Supplier Inv No", value: true, columnId: "supplierInvNo", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
  ];

  return colArr;
}
