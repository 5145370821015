import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatNumber } from "@/utils/helpers";

export default function getOccupancyReportColumns() {

  const colArr = [
    {
      label: "Component Name", value: true, columnId: "componentName", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Component Number", value: true, columnId: "componentNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Emirate", value: true, columnId: "emirate", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Number", value: true, columnId: "unitNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Name", value: true, columnId: "unitName", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Mark", value: true, columnId: "unitMark", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Status", value: true, columnId: "unitStatus", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Type", value: true, columnId: "unitType", width: estimatedColumnWidth("type"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Usage", value: true, columnId: "usage", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "0")
    },
    {
      label: "Total Contract Amount", value: true, columnId: "totalContractAmount", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => formatCurrency(String(value || ""))
    },
    {
      label: "Tenant Name", value: true, columnId: "tenantName", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Type", value: true, columnId: "tenantType", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Company Id", value: true, columnId: "companyId", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Current Contract Amount", value: true, columnId: "currentContractAmount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || ""))
    },
    {
      label: "Previous Contract Amount", value: true, columnId: "previousContractAmount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || ""))
    },
    {
      label: "Variance %", value: true, columnId: "variance%", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => `${formatNumber(String(value || ""))}%`
    },
    {
      label: "Revenue Variance", value: true, columnId: "revenueVariance", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => formatNumber(String(value || ""))
    },
  ];

  return colArr;
}
