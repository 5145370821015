import { getBlanketAgreementDetailRows, getBlanketAgreementDetailColumns } from "./blanket-agreement-detail-statement";
import { getBlanketAgreementDetail } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const blanketAgreementFilters = {
  SupplierKey: "*",
  Status: "*",
  AgreementMethod: "*",
};

const blanketAgreementFilterOptions = [
  { key: "supplierKey", apiKey: "SupplierKey" },
  { key: "status", apiKey: "Status", keyType: "label" },
  { key: "agreementMethod", apiKey: "AgreementMethod", keyType: "label" },
];

export default function ReportBlanketAgreementDetail({ activeCompany }) {
  return (
    <DynamicReport
      reportName="blanket-agreement"
      activeCompany={activeCompany}
      columnsDataProvider={getBlanketAgreementDetailColumns}
      rowsDataProvider={getBlanketAgreementDetailRows}
      fetchData={getBlanketAgreementDetail}
      exportUrl="reports/export-query-report/blanket-agreement"
      filtersInitialState={blanketAgreementFilters}
      filterOptions={blanketAgreementFilterOptions}
      orderBy="SupplierName"
    />
  );
}
