import {
  useRef, useEffect, useState, useMemo
} from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Tab } from "@hydra/atom/components";
import { format, isToday, isYesterday } from "date-fns";

import {
  Header, CrossButton, Drawer, ActivityTimeline
} from "@/components/common";
import getActivityLogs from "@/api/dynamic/activityLogApi";
import { handleDrawer } from "@/utils/modal/helpers";
import { formatDate } from "@/utils/helpers";

const getDateText = (eventDate) => {
  const date = new Date(eventDate);

  if (isToday(date)) {
    return "Today";
  }

  if (isYesterday(date)) {
    return "Yesterday";
  }

  return formatDate(date);
};

const formatActivityLog = (activityLog) => ({
  imageUrl: activityLog?.userDetails?.profilePicture,
  name: activityLog?.userDetails?.name,
  type: activityLog.severity,
  title: activityLog.subject,
  link: handleDrawer(activityLog.recordId, null, false, "dynamicObjectDrawers"),
  description: activityLog.text,
  date: getDateText(`${activityLog.eventTime}Z`),
  time: format(new Date(`${activityLog.eventTime}Z`), "hh:mm a"),
});

export default function DynamicObjectActivityDrawer({ title, tabs }) {
  const ref = useRef(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const drawers = JSON.parse(searchParams.get("objectActivityDrawers") || null) || [];
  const scope = tabs.length ? tabs[activeTabIndex].scope : drawers.at(-1);

  const activityTabs = useMemo(() => {
    if (tabs.length) {
      return tabs;
    }

    return [
      {
        label: "All",
        scope,
      },
    ];
  }, [tabs]);

  const filters = useMemo(() => {
    if (activityTabs[activeTabIndex].filters) {
      return activityTabs[activeTabIndex].filters;
    }

    return {};
  }, [activeTabIndex]);

  const isOpen = Boolean(drawers.at(-1));

  const handleClose = () => {
    handleDrawer(null, setSearchParams, true, "objectActivityDrawers");
  };

  const {
    isInitialLoading,
    data: activity,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["activity-logs", scope, activityTabs[activeTabIndex]],
    ({ pageParam }) => getActivityLogs({ scope, page: pageParam, ...filters }),
    {
      getNextPageParam: (lastPage) => {
        const { page: currentPage, pageSize, totalCount } = lastPage;

        if (!currentPage || !pageSize || !totalCount) {
          return undefined;
        }

        const totalPages = Math.ceil(totalCount / pageSize);

        if (currentPage < totalPages) {
          return currentPage + 1;
        }

        return undefined;
      },
      enabled: isOpen,
    }
  );

  const observerCallback = (entries) => {
    const [entry] = entries;

    if (entry.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  };

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, options);
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  const activityLogs = activity?.pages ?
    activity?.pages?.flatMap((p) => p?.data?.flatMap((d) => formatActivityLog(d))) :
    [];

  return (
    <Drawer
      isOpen={isOpen}
      overlay
      size={400}
      className="drawer-border drawer-border-activity z-1000"
      onClose={handleClose}
    >
      <div className="activity-drawer mobile-responsive-drawer">
        <Header
          leftContent={<h6>{title}</h6>}
          rightContent={<CrossButton onClick={handleClose} />}
        />
        {tabs.length ? (
          <Tab
            className="activity-tabs-container"
            tabs={activityTabs.map((t) => t.label)}
            onClick={setActiveTabIndex}
          />
        ) : null}
        <ActivityTimeline
          ref={ref}
          activities={activityLogs}
          className="dynamic-object-timeline-container"
          isLoading={isInitialLoading || isFetchingNextPage}
        />
      </div>
    </Drawer>
  );
}

DynamicObjectActivityDrawer.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.array,
};

DynamicObjectActivityDrawer.defaultProps = {
  title: "Activity Log",
  tabs: [],
};
