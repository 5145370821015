import { getBuildingCostRows, getBuildingCostColumns } from "./building-cost-statement";
import { getBuildingCost } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportBuildingCost({ activeCompany }) {
  return (
    <DynamicReport
      reportName="building-cost"
      activeCompany={activeCompany}
      columnsDataProvider={getBuildingCostColumns}
      rowsDataProvider={getBuildingCostRows}
      fetchData={getBuildingCost}
      exportUrl="reports/export-query-report/building-cost"
      filtersInitialState={{}}
      orderBy="Number"
    />
  );
}
