import { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import useCollapse from "react-collapsed";

import { SearchInput } from "@/components/dashboard";
import { Drawer, SvgIcon, IconButton } from "@/components/common";
import BackArrowIcon from "@/assets/images/icons/back-arrow.svg";

const renderItems = (navItems, setSubmenu) =>
  navItems?.map((item, idx) => {
    if (item.type === "link") {
      return (
        <SideBarNavItem
          key={item.text}
          href={item.href}
          text={item.text}
          icon={item.icon}
          showComingSoonLabel={item.showComingSoonLabel}
          renderAsExternalLink={item.renderAsExternalLink}
          setSubmenu={setSubmenu}
        />
      );
    }

    return (
      <CollapsibleMenu
        key={idx}
        navItems={item.items}
        title={item.text}
        icon={item.icon}
        isExpanded={item.isExpanded}
        setSubmenu={setSubmenu}
      />
    );
  });

function CollapsibleMenu({ navItems, title, icon, isExpanded: isOpen, setSubmenu }) {
  const [isExpanded, setExpanded] = useState(isOpen || false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <>
      <button
        className="collapsible-menu-button"
        type="button"
        {...getToggleProps({
          onClick: () => setExpanded((prevExpanded) => !prevExpanded),
        })}
      >
        <div className={`dropdown-link ${isExpanded ? "active" : ""}`}>
          {icon && <SvgIcon icon={icon} />}
          <span className="nav-item-text">{title}</span>
        </div>
        {isExpanded ? (
          <span className="material-icons">expand_less</span>
        ) : (
          <span className="material-icons">expand_more</span>
        )}
      </button>
      <section {...getCollapseProps()}>
        <ul className="submenu-item-tab">{renderItems(navItems, setSubmenu)}</ul>
      </section>
    </>
  );
}

CollapsibleMenu.propTypes = {
  title: PropTypes.string.isRequired,
  navItems: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool,
  setSubmenu: PropTypes.func.isRequired,
};

CollapsibleMenu.defaultProps = {
  isExpanded: false,
};

function SideBarNavItem({ href, text, renderAsExternalLink, setSubmenu }) {
  if (renderAsExternalLink) {
    return (
      <a
        href={href}
        onClick={() => {
          setSubmenu(null);
        }}
      >
        <span className="nav-item-text">{text}</span>
      </a>
    );
  }

  return (
    <NavLink
      className={({ isActive }) => (isActive ? "active" : "")}
      to={href}
      onClick={() => {
        setSubmenu(null);
      }}
    >
      <span className="nav-item-text">{text}</span>
    </NavLink>
  );
}

SideBarNavItem.propTypes = {
  setSubmenu: PropTypes.func.isRequired,
  href: PropTypes.string,
  text: PropTypes.string,
  renderAsExternalLink: PropTypes.bool,
};

SideBarNavItem.defaultProps = {
  href: null,
  text: null,
  renderAsExternalLink: false,
};

function SideBarNavGroup({ title, navItems, className, setSubmenu }) {
  const navItemsMarkup = navItems.map((item, idx) => (
    <li key={idx}>
      <SideBarNavItem
        key={item.text}
        href={item.href}
        text={item.text}
        icon={item.icon}
        showComingSoonLabel={item.showComingSoonLabel}
        renderAsExternalLink={item.renderAsExternalLink}
        setSubmenu={setSubmenu}
      />
    </li>
  ));

  return (
    <div className={`sidebar-nav-group ${className}`}>
      <h6>{title}</h6>
      <ul>{navItemsMarkup}</ul>
    </div>
  );
}

SideBarNavGroup.propTypes = {
  setSubmenu: PropTypes.func.isRequired,
  title: PropTypes.string,
  navItems: PropTypes.array,
  className: PropTypes.string,
};

SideBarNavGroup.defaultProps = {
  title: null,
  className: "",
  navItems: [],
};

const flattenMenuItems = (items, arr = []) => {
  if (!items || items.length === 0) return [];
  // eslint-disable-next-line consistent-return
  items.forEach((item) => {
    if (item.type === "link") {
      arr.push(item);
    }

    if (item.type === "menu") {
      return flattenMenuItems(item.items, arr);
    }
  });

  return arr;
};

const filterMenuItems = (items, query) =>
  flattenMenuItems(items)?.filter((link) => link.text.toLowerCase().includes(query));

export default function SideBarDrawer({ submenu, setSubmenu }) {
  const [query, setQuery] = useState("");
  const filteredItems = filterMenuItems(submenu?.items, query.toLocaleLowerCase());

  return (
    <Drawer
      direction="left"
      isOpen={Boolean(submenu)}
      onClose={() => setSubmenu(null)}
      size={250}
      overlay={false}
      className={null}
      zIndex={4}
    >
      <div className="dashboard-sidebar-drawer mobile-responsive-drawer">
        <nav className="dashboard-sidebar-nav">
          <div>
            <div className="title-container">
              <p className="label">{submenu?.text}</p>
              <button className="back-button" type="button" onClick={() => setSubmenu(null)}>
                <img src={BackArrowIcon} alt="Back arrow icon" className="back-arrow-icon" />
              </button>
            </div>
            <div className="search-with-close-icon">
              <SearchInput
                className="sidebar-search-input"
                value={query}
                onChange={setQuery}
              />
              {query && (
                <IconButton
                  onClick={() => setQuery("")}
                >
                  <SvgIcon icon="icon-close" />
                </IconButton>
              )}
            </div>

            {query.length ? (
              <SideBarNavGroup
                navItems={filteredItems}
                title={filteredItems.length ? "Filtered Items" : "No Results Found"}
                setSubmenu={setSubmenu}
              />
            ) : (
              <div className="sidebar-nav-group">{renderItems(submenu?.items, setSubmenu)}</div>
            )}
          </div>
        </nav>
      </div>
    </Drawer>
  );
}

SideBarDrawer.propTypes = {
  submenu: PropTypes.object,
  setSubmenu: PropTypes.func.isRequired,
};

SideBarDrawer.defaultProps = {
  submenu: null,
};
