import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getUtilityContractColumns() {
  const colArr = [
    {
      label: "Utility Contract Number",
      value: true,
      columnId: "contractNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Collection Number",
      value: true,
      columnId: "paymentNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Start Date", value: true, columnId: "startDate", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))

    },
    {
      label: "End Date", value: true, columnId: "endDate", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))
    },
    {
      label: "Tenant Name", value: true, columnId: "customerName", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Utility Type",
      value: true,
      columnId: "utilityType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Account Name",
      value: true,
      columnId: "accountName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Amount", value: true, columnId: "amount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Paid Amount", value: true, columnId: "paidAmount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || 0))
    }
  ];

  return colArr;
}
