import PropTypes from "prop-types"; // Import PropTypes

function GutterHeader({ title, subtitle }) {

  return (
    <div className="time-gutter">
      <h6>{title}</h6>
      <h6>{subtitle}</h6>
    </div>
  );
}

GutterHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default GutterHeader;
