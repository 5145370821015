import PropTypes from "prop-types";

import { Button } from "@hydra/atom/components";
import { Modal, SvgIcon } from "@/components/common";
import { ModalHeader, ModalFooter } from "@/components/modals";

export default function AlertModal({
  title, subtitle, isOpen, onClose, onConfirm, size, children, isLoading, icon, iconClass
}) {
  if (!isOpen) return null;

  return (
    <Modal
      className={`alert-modal alert-modal-${size}`}
      rootClassName="center-vertically"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="core-modal-content">
        <div className={`icon-container ${iconClass}`}>
          <SvgIcon icon={icon} />
        </div>
        <ModalHeader heading={title} subHeading={subtitle} />
        {children}
      </div>
      <ModalFooter>
        <Button small bordered onClick={onClose}>
          Cancel
        </Button>
        <Button
          small
          loading={isLoading}
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
}

AlertModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  children: PropTypes.node,
  size: PropTypes.oneOf(["small", "large", "x-large"]),
  icon: PropTypes.string,
  iconClass: PropTypes.oneOf(["error", "success"]),
};

AlertModal.defaultProps = {
  title: "Are you sure?",
  subtitle: "If you delete this you’ll not be able to recover the previous data.",
  isOpen: false,
  onClose: () => {},
  onConfirm: () => {},
  children: null,
  isLoading: false,
  size: "small",
  icon: "delete-stroke-icon",
  iconClass: "error"
};
