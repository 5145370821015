import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Pill from "@/components/dashboard/Pill";
import { SvgIcon } from "@/components/common";
import { convertTo12HourFormat } from "@/utils/facility/helpers";

function LinkCell({ value }) {
  return (
    <Link
      to={`/facility-management/work-order/${value.workOrderId}?jobId=${value.jobId}`}
    >
      <SvgIcon className="icon end" icon="edit-icon" />
    </Link>
  );
}

LinkCell.propTypes = {
  value: PropTypes.object.isRequired,
};

function JobBoardTask({ task }) {
  const navigate = useNavigate();

  const avatars = [
    "https://i.pinimg.com/originals/7d/34/d9/7d34d9d53640af5cfd2614c57dfa7f13.png",
    "https://i.pinimg.com/originals/7d/34/d9/7d34d9d53640af5cfd2614c57dfa7f13.png",
    "https://i.pinimg.com/originals/7d/34/d9/7d34d9d53640af5cfd2614c57dfa7f13.png",
    "https://i.pinimg.com/originals/7d/34/d9/7d34d9d53640af5cfd2614c57dfa7f13.png",
    "https://i.pinimg.com/originals/7d/34/d9/7d34d9d53640af5cfd2614c57dfa7f13.png",
  ];

  const pillPriorityColor = (priority) => {
    switch (priority?.toLowerCase()) {
      case "medium":
        return "primary";
      case "critical":
        return "error";
      case "low":
        return "error";
      case "escalated":
        return "badge-pink";
      case "high":
        return "badge-green";
      case "closed":
        return "blue";
      case "completed":
        return "success";
      default:
        return "primary";
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() =>
        navigate(
          `/facility-management/work-order/${task.workOrder.id}?jobId=${task.id}`
        )}
      className={`work-order-card work-order-card-${task?.jobType}`}
    >
      <div className="work-order-body">
        <div className="work-order-badge-list">
          <div className="badge-container">
            <Pill text={task?.workOrder?.number || ""} variant="badge-green" />
            {task?.workOrder?.serviceRequest?.unit?.number && (
            <Pill
              text={task?.workOrder?.serviceRequest?.unit?.number || ""}
              variant="badge-pink"
            />
            )}
          </div>
          <SvgIcon className="icon end" icon="edit-icon" />
        </div>
        <p className={`headline ${task?.jobType}`}>
          {`Workorder ${task?.jobType}`}
        </p>
        <p className="sub-title">{task?.category?.name}</p>
        <div className="badge-list-container">
          <div className="work-order-badge-list">
            <Pill
              text={task?.priority || ""}
              variant={`${pillPriorityColor(task?.priority)}`}
            />
          </div>
        </div>
      </div>

      <div className="work-order-footer">
        <div>
          <hr className="hr-line" />
        </div>
        <div className="avatar-group-row">
          {task.endDate ? (
            <div className="left-content">
              <SvgIcon icon="calendar-icon-4" />
              <span className="sub-title">
                {format(new Date(task?.startDate), "dd LLLL")}
                {" "}
                -
                {" "}
                {format(new Date(task?.endDate), "dd LLLL")}
              </span>
            </div>
          ) : (
            <div className="left-content">
              <SvgIcon icon="calendar-icon-4" />
              <span className="sub-title">
                -
              </span>
            </div>
          )}
        </div>
        <div className="left-content">
          <SvgIcon icon="time-square" />
          {task?.endTime ? (
            <span className="sub-title">
              {convertTo12HourFormat(task?.startTime)}
              {" "}
              -
              {" "}
              {convertTo12HourFormat(task?.endTime) }
            </span>
          ) : (
            <span className="sub-title">
              -
            </span>
          ) }
        </div>
        <div className="left-content">
          {task.team && task?.team.map((avatar, index) =>
            (index < 2 ? (
              <div className="technician-avatar-container" key={index}>
                <img
                  key={index}
                  src={avatars[0]}
                  alt={`Avatar ${index}`}
                  className={`avatar avatar-${index + 1}`}
                />
                <div className="avatar-name">{avatar.name}</div>
              </div>
            ) : null)
          )}
          {task?.team.length > 2 && (
            <div className="avatar avatar-plus">
              +
              {avatars.length - 2}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default JobBoardTask;
