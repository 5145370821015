import { estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getVATSummaryColumns() {
  const colArr = [
    {
      label: "VAT Type",
      value: true,
      columnId: "vATType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Emirates",
      value: true,
      columnId: "emirates",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "VAT Amount (GL)",
      value: true,
      columnId: "vATAmountGL",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Service Value",
      value: true,
      columnId: "serviceValue",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Vat Recovery",
      value: true,
      columnId: "vATRecovery",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    }
  ];

  return colArr;
}
