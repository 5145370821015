/* eslint-disable no-nested-ternary */
import chroma from "chroma-js";
import { statusColorMap } from "@/utils/maps/statusColorMap";

export const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 8,
    content: "\" \"",
    display: "block",
    marginRight: 8,
    height: 8,
    width: 8,
  },
});

export const toolDot = (color = "#96A9C2") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 8,
    content: "\" \"",
    display: "block",
    marginRight: 28,
    height: 8,
    width: 8,
  },
});

const initialsCircle = (data) => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: data.color,
    borderRadius: "50%",
    color: chroma.contrast("#fff", data.color) > 0.5 ? "white" : "black",
    content: `"${data.initials}"`,
    display: "grid",
    placeItems: "center",
    marginRight: 5,
    height: 32,
    width: 32,
  },
});

export const colorStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data }) => ({
    ...styles,
    ...dot(data.color)
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...dot(data.color ? data.color :
      statusColorMap.get(data.value?.toLowerCase()))
  }),
};

export const multiSelectStyles = {
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
};

export const multiSelectUserStyles = {
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: data.color,
    width: "30px",
    height: "30px",
    display: "grid",
    placeItems: "center",
    borderRadius: "50%",
    fontSize: "1.2rem",
    fontWeight: 600
  }),
  multiValueLabel: (styles, { }) => ({
    ...styles,
    color: "#fff",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: "uppercase"
  }),
  multiValueRemove: (styles, { }) => ({
    ...styles,
    display: "none"
  }),
  placeholder: (base) => ({
    ...base,
    display: "flex",
    alignItems: "center",
    gap: "8px"
  }),
  option: (styles, { data }) => ({
    ...styles,
    ...initialsCircle(data)
  })
};

export const placeholderStyles = {
  placeholder: (styles) => ({ ...styles, ...toolDot() }),
};

export const nestedOptionStyles = {
  option: (styles, { data }) => ({
    ...styles,
    paddingLeft: `${data.depth}rem`
  })
};

export const spreadSheetSelectStyles = {
  control: (styles) => ({
    ...styles,
    padding: 0,
    minHeight: "22px !important",
    borderRadius: "0",
    border: "none",
    boxShadow: "none"
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: 0
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none"
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: 0,
    width: "16px",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: 0,
    width: "16px",
  }),
  loadingIndicator: (styles) => ({
    ...styles,
    padding: 0,
    width: "16px",
  }),
};

export const menuStyles = {
  menu: (styles) => ({
    ...styles,
    minWidth: "300px"
  })
};
