import { useState, useEffect, useRef } from "react";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { useUser } from "@/hooks";

function SupervisorPettyCashRequestForm() {
  const ref = useRef(null);
  const { user } = useUser();
  const [initialState, setInitialState] = useState({});

  useEffect(() => {
    const userRole = user?.roles;
    if (userRole && (userRole.includes("supervisor") || userRole.includes("cash_officer"))) {
      const supervisor = {
        label: user?.name,
        value: user?.userKey
      };

      setInitialState({
        supervisor,
        isSupervisorDisabled: true
      });
    }
  }, [user]);

  const onStateChange = (key, value) => {
    if (key === "workOrderJob") {
      if (value) {
        const { building, unit } = value;

        const data = {};

        if (building) {
          data.building = {
            label: building.name,
            value: building.id
          };
        }

        if (unit) {
          data.unit = {
            label: unit.name,
            value: unit.id
          };

          const { component } = unit;

          if (component) {
            data.component = {
              label: component.name,
              value: component.id
            };
          }
        }

        ref.current.setFormState(data);
      } else {
        ref.current.setFormState({
          building: null,
          component: null,
          unit: null
        });
      }
    }
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        initialData={initialState}
        objectName={dynamicObjectMap.get("SupervisorPettyCashRequestObjectName")}
        showHeader
        showLinkedViews
        readOnlyFields={initialState.isSupervisorDisabled ? ["supervisor"] : []}
        onStateChange={onStateChange}
      />
    </BoxedContent>
  );
}

export default SupervisorPettyCashRequestForm;
