import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { CustomActionDropdown } from "@/components/dynamic";

export default function ActionCell({
  row
}) {
  const navigate = useNavigate();

  const actions = [
    {
      title: "Edit",
      onClick: () => {
        navigate(`${row.original.id}`);
      },
      icon: "edit-icon"
    }
  ];
  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={actions}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId="Edit-Cancel-Button"
      />
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
};

function DateCell({ row }) {
  const { companySettings } = row.original;
  if (companySettings.fiscalCalendarYearStart && companySettings.fiscalCalendarYearEnd) {
    return (
      <div className="date-cell">
        {companySettings.fiscalCalendarYearStart}
        {" "}
        -
        {" "}
        {companySettings.fiscalCalendarYearEnd}
      </div>
    );
  }
}

export const getCompanySettingTableColumns = () => [
  {
    Header: "Company Name",
    accessor: "name",
    defaultCanSort: true,
    filter: "text"
  },
  {
    Header: "TRN",
    accessor: "companySettings.taxRegistrationNumber",
    defaultCanSort: true,
    filter: "text"
  },
  {
    Header: "Fiscal Calendar Year",
    Cell: DateCell,
    defaultCanSort: true,
    collapse: true
  },
  {
    Header: "System Currency",
    accessor: "companySettings.systemCurrencyCode",
    defaultCanSort: true,
    filter: "text"
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionCell,
    collapse: true,
  },
];

export const getCompanySettingTableData = (data) => data;
