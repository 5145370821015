import { useState, useEffect } from "react";

import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import makeConnection from "@/utils/sockets/helpers";
import { useUser } from "@/hooks";
import appSettings from "@/settings";
import {
  setUnreadNotificationCount,
  selectUnreadNotificationCount,
} from "@/store/notificationSlice";
import { setAutoNumber } from "@/store/autoNumberSlice";
import { getNotifications, setNotificationStatusBulk } from "@/api/user/notificationApi";

import NotificationIcon from "@/assets/images/icons/notification.svg";
import { NotificationItem, formatNotification } from "@/components/dashboard/NotificationItem";
import NoDataFound from "@/components/common/layout/NoDataFound";

export default function NotificationsDropDown() {
  const [currentConnection, setCurrentConnection] = useState(null);
  const [notificationsData, setNotificationsData] = useState([]);
  const dispatch = useDispatch();
  const unreadNotificationsCount = useSelector(selectUnreadNotificationCount);

  const [notificationIsOpen, setNotificationIsOpen] = useState(false);
  const { user } = useUser();
  const isLoggedIn = Boolean(user?.userId);
  const navigate = useNavigate();

  const { data: notifications } = useQuery(
    ["notification-drop-down"],
    () => getNotifications({ pageSize: 5 }),
    {
      enabled: isLoggedIn,
    }
  );

  const setNotificationReadStatus = async () => {
    setNotificationIsOpen(!notificationIsOpen);
    const isReadNotifications = notificationsData.filter((item) => !item.isRead);
    if (notificationsData.length === 0 || !notificationsData || !notificationIsOpen) {
      dispatch(setUnreadNotificationCount(unreadNotificationsCount - isReadNotifications.length));
      return;
    }

    const isReadNotificationsIds = isReadNotifications.map(
      (notificationItem) => notificationItem?.id
    );
    if (isReadNotifications.length > 0) {
      await setNotificationStatusBulk({
        AppNotificationIds: isReadNotificationsIds,
        Status: true,
      });
      setNotificationsData((prev) =>
        prev.map((item) => {
          if (isReadNotificationsIds.includes(item.id)) {
            return { ...item, isRead: true };
          }
          return item;
        })
      );
    }
  };

  useEffect(() => {
    if (!notifications) return;
    setNotificationsData(
      notifications?.data ? notifications?.data?.map((p) => formatNotification(p)) : []
    );
  }, [notifications]);

  const signalRConnecting = (connection) => {
    connection.on("Message", (notification) => {
      const newNotification = formatNotification(notification);
      setNotificationsData((prevData) => {
        const newData = [newNotification, ...prevData.slice(0, 4)];
        return newData;
      });
      dispatch(setUnreadNotificationCount(unreadNotificationsCount + 1));
    });

    connection.on("AutoNumberUpdates", (notification) => {
      const { objectName, newNumber, notificationType } = notification;
      if (notificationType === "AutoNumberNotification") {
        const payload = {
          key: objectName,
          value: newNumber
        };
        dispatch(setAutoNumber(payload));
      }
    });
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (!currentConnection) {
        makeConnection(`${appSettings.apiBaseUrl}/notifications/hub`, setCurrentConnection);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (currentConnection) {
      signalRConnecting(currentConnection);
    }
  }, [currentConnection]);

  const onViewAllClick = () => {
    dispatch(setUnreadNotificationCount(0));
    navigate("../notifications");
  };

  return (
    <div>
      <Dropdown.Root onOpenChange={() => setNotificationReadStatus()}>
        <Dropdown.Trigger asChild>
          <button type="button" className="notification-container">
            <img src={NotificationIcon} alt="Notification icon" className="notification-icon" />
            {unreadNotificationsCount > 0 && (
              <div className="notification-badge">
                {unreadNotificationsCount > 20 ? "20+" : unreadNotificationsCount}
              </div>
            )}
          </button>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content className="notification-dropdown" side="bottom" align="end">
            <div className="notification-title">Notifications</div>
            {notificationsData.length > 0 ? (
              notificationsData?.map((item, index, array) => (
                // eslint-disable-next-line max-len
                <NotificationItem key={index} {...item} isLastItem={index === array.length - 1} />
              ))
            ) : (
              <NoDataFound title="You don't have any notifications" objectName="Notification" />
            )}
            <div
              role="banner"
              className="view-all-container d-flex justify-content-end"
              onClick={() => onViewAllClick()}
            >
              View All
              {unreadNotificationsCount > 0 && <div className="notification-unread-icon" />}
            </div>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
    </div>
  );
}
