import PropTypes from "prop-types";

export default function SvgIcon({ icon, label }) {

  const getStrokeIconClassName = () => {
    if (!icon) return "";

    if (icon.includes("stroke-icon")) return "stroke-icon";

    return "";
  };

  return (
    <svg className={`icon ${getStrokeIconClassName()} icon-${icon}`} aria-label={label}>
      <title>{label}</title>
      <use xlinkHref={`#${icon}`} style={{ pointerEvents: "none" }} />
    </svg>
  );
}

SvgIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string
};

SvgIcon.defaultProps = {
  label: null
};
