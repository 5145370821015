import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { useMutation } from "@tanstack/react-query";

import { usePostHog } from "posthog-js/react";

import toast from "react-hot-toast";
import showToast from "@/utils/toast/helpers";
import { SvgIcon, Avatar } from "@/components/common";
import { logoutUserConnect } from "@/store/userSlice";
import { resetApp } from "@/store/appSlice";
import { resetLocalStorage, logoutUser } from "@/api/user/authApi";
import { useUser } from "@/hooks";

export default function ProfileDropDown() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useUser();
  const posthog = usePostHog();

  const logoutMutation = useMutation(logoutUser);

  const onLogout = () => {
    const toastId = toast.loading("Logging out...");

    logoutMutation.mutate({}, {
      onSuccess: () => {
        posthog?.reset();
        resetLocalStorage();
        dispatch(logoutUserConnect());
        dispatch(resetApp());
        toast.dismiss(toastId);
        showToast("Logged out successfully", "success");
        navigate("/login");
      },
      onError: () => {
        toast.dismiss(toastId);
        showToast("Could not logout. Please try again.", "error");
      },
    });
  };

  const logoutMenu = {
    title: "Logout", icon: "logout-icon", link: "profile", className: "logout-btn", onClick: onLogout, disabled: logoutMutation.isLoading
  };

  const itemMenu = {
    title: "Profile", icon: "user", link: "user/profile", onClick: () => navigate("user/profile")
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <div className="profile-info white">
          <Avatar name={user?.name} imageUrl={user?.profilePicture} size="medium" />
          <div>
            <p className="title">{user?.name}</p>
            <p className="email">{user?.email}</p>
          </div>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content className="dropdown-menu-content profile-dropdown" side="right">
          <div className="profile-info">
            <Avatar name={user?.name} imageUrl={user?.profilePicture} size="medium" status="online" />
            <div className="profile-menu">
              <p className="title">{user?.name}</p>
              <p className="email">{user?.email}</p>
            </div>
          </div>
          <Dropdown.Separator className="separator" />
          <div className="profile-container">
            <ProfileItem item={itemMenu} />
            <Dropdown.Separator className="separator" />
            <ProfileItem item={logoutMenu} />
            <Dropdown.Separator className="separator" />
          </div>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
}

function ProfileItem({
  item: {
    title, icon, link, onClick, className = "", disabled = false
  }
}) {
  if (!icon && !link && !onClick) {
    return <Dropdown.Separator key={title} className="separator" />;
  }

  const element = (
    <div className="dropdown-menu-item profile-menu-item">
      <SvgIcon icon={icon} className="profile-menu-item-image" />
      <p>{title}</p>
    </div>
  );

  if (onClick) {
    return (
      <button type="button" className={className} onClick={() => onClick()} disabled={disabled}>
        {element}
      </button>
    );
  }

  return (
    <NavLink to={link}>
      {element}
    </NavLink>
  );
}
