/* eslint-disable no-plusplus */
import {
  emptyTextCell,
  nonEditable,
  textCell,
  monthHeaderCell,
  bottomLine,
  showZero,
  noSideBorders,
  numberCell
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT } from "../constants";

export const CASH_IN_CHANGE_ROW_ID = "cashInChange";

function years() {
  return new Array(15).fill(0);
}

function getHeaderRow() {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: [
      nonEditable(monthHeaderCell("Transaction no"), "justify-content-center"),
      nonEditable(monthHeaderCell("Type"), "justify-content-center"),
      nonEditable(monthHeaderCell("Date"), "justify-content-center"),
      nonEditable(monthHeaderCell("Num"), "justify-content-center"),
      nonEditable(monthHeaderCell("Name"), "justify-content-center"),
      nonEditable(monthHeaderCell("Building Name"), "justify-content-center"),
      nonEditable(monthHeaderCell("Unit"), "justify-content-center"),
      nonEditable(monthHeaderCell("Unit Type"), "justify-content-center"),
      nonEditable(monthHeaderCell("Department"), "justify-content-center"),
      nonEditable(monthHeaderCell("Class"), "justify-content-center"),
      nonEditable(monthHeaderCell("Memo/Description"), "justify-content-center"),
      nonEditable(monthHeaderCell("Account"), "justify-content-center"),
      nonEditable(monthHeaderCell("Tax Amount"), "justify-content-center"),
      nonEditable(monthHeaderCell("Amount"), "justify-content-center"),
      nonEditable(monthHeaderCell("Create Date"), "justify-content-center"),
      nonEditable(monthHeaderCell("Created By"), "justify-content-center"),
      nonEditable(monthHeaderCell("Last Modified"), "justify-content-center"),
      nonEditable(monthHeaderCell("Total"), "justify-content-center"),
      nonEditable(monthHeaderCell("Balance"), "justify-content-center"),
    ]
  };
}

function getGroupRows(
  title,
  groups
) {
  return [
    {
      rowId: `${title}Header`,
      height: ROW_HEIGHT,
      cells: [
        bottomLine(nonEditable(emptyTextCell()), "light-green-bg"),
        bottomLine(nonEditable(textCell({ text: title }), "font-bold light-green-bg ")),
        ...years().map(() => noSideBorders(bottomLine(nonEditable(emptyTextCell(), "light-green-bg")))
        ),
        bottomLine(nonEditable(emptyTextCell(), "light-green-bg")),
        bottomLine(nonEditable(emptyTextCell(), "light-green-bg"))
      ]
    },
    ...groups.map(({ title: subtitle }) => ({
      rowId: subtitle,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(showZero(textCell({ text: "13567" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "436" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "24.00" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "ALIREZA MIRALAMI Unit 1705 Paramount D - Tenant" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "Byshore Water" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "1234" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "Brokerage:Rent Brokerage" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "Security Deposit of Previous Tenant of Unit 25 Trixis" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "Accounts Receivable (A/R)" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "24.00" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "24.00" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "Byshore Water" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "" })), "justify-content-center"),
        nonEditable(showZero(numberCell({ value: 343 })), "justify-content-center"),
        nonEditable(showZero(numberCell({ value: 324456 })), "justify-content-center"),
      ]
    })),
    ...groups.map(({ title: subtitle }) => ({
      rowId: subtitle,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(showZero(textCell({ text: "13567" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "436" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "24.00" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "Byshore Water" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "Byshore Water" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "1234" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "Brokerage:Rent Brokerage" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "Security Deposit of Previous Tenant of Unit 25 Trixis" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "Accounts Receivable (A/R)" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "24.00" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "24.00" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "Byshore Water" })), "justify-content-center"),
        nonEditable(showZero(textCell({ text: "" })), "justify-content-center"),
        nonEditable(showZero(numberCell({ value: 0 })), "justify-content-center"),
        nonEditable(showZero(numberCell({ value: 324456 })), "justify-content-center"),
      ]
    })),
    {
      rowId: `${title}Footer`,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(textCell({ text: "Total" }), "font-bold justify-content-center total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(textCell({ text: "" }), "font-bold justify-content-center total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(showZero(textCell({ text: "" })), "total-row"),
        nonEditable(showZero(numberCell({ value: 23432 })), "justify-content-center total-row-debit"),
        nonEditable(showZero(numberCell({ value: 0 })), "justify-content-center total-row-credit"),
      ]
    }
  ];
}

export function getTransactionDetailRows({
  cashInflow,
  cashOutflow,
}) {
  return [
    getHeaderRow(),
    ...getGroupRows(
      "Ex Exempt",
      cashInflow
    ),
    ...getGroupRows(
      "SR Standard Rated",
      cashOutflow,
    ),
    ...getGroupRows(
      "SR Standard Rated (AUH)",
      cashOutflow,
    ),
  ];
}
