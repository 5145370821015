import { FormControl, Input } from "@hydra/atom/components";
import PropTypes from "prop-types";
import FieldLabel from "./FieldLabel";
import { getTestId } from "@/utils/helpers";

function GeolocationField({ field, value, onChange, error, showLabel, disabled, testId }) {
  return (
    <>
      <div className="row">
        <div className="col">
          <FormControl>
            {showLabel ? (
              <FieldLabel
                label={`${field.label} Lat`}
                labelFor={field.name}
                helperText={field.helperText}
                isRequired={field.required}
              />
            ) : null}
            <Input
              name={field.name}
              id={field.name}
              value={value?.lat || ""}
              onChange={(v) => onChange(`${field.name}.lat`, v)}
              disabled={field.disabled || disabled}
              testId={getTestId(`${testId}-Lat`)}
            />
          </FormControl>
        </div>
        <div className="col">
          <FormControl>
            {showLabel ? (
              <FieldLabel
                label={`${field.label} Lng`}
                labelFor={field.name}
                helperText={field.helperText}
                isRequired={field.required}
              />
            ) : null}
            <Input
              name={field.name}
              id={field.name}
              value={value?.lng || ""}
              onChange={(v) => onChange(`${field.name}.lng`, v)}
              disabled={field.disabled || disabled}
              testId={getTestId(`${testId}-Lng`)}
            />
          </FormControl>
        </div>
      </div>
      {typeof error === "string" && <span className="error-text">{error}</span>}
    </>
  );
}

GeolocationField.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

GeolocationField.defaultProps = {
  value: null,
  onChange: () => {},
  name: "tuli-geolocation-field",
  error: false,
  showLabel: true,
  disabled: false,
  testId: "",
};

GeolocationField.Type = "HydraValidatableComponent";

export default GeolocationField;
