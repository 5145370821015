import { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  capitalize, flatten, kebabCase, map
} from "lodash";
import { Button, Input, Loader } from "@hydra/atom/components";
import { pascalize } from "humps";
import { formatDate, getAttachmentUrl } from "@/utils/helpers";
import { useIsMobile } from "@/hooks";
import {
  BoxedContent,
  Header,
  TwoColumnList,
  StatusCircle,
  Status,
  SvgIcon,
  Accordion,
  Gallery,
  AttachedFile
} from "@/components/common";
import DynamicObjectOverview from "@/pages/dynamic/list-views/DynamicObjectOverview";
import { JobsTable } from "@/components/facility/planboard";
import { InfoTable } from "@/components/facility/maintenance";
import { DynamicObjectActivityDrawer, ActionDropdown } from "@/components/dynamic";
import {
  getEquipmentTableColumns,
  getEquipmentTableData,
  getMaterialTableColumns,
  getMaterialTableData,
  getTechnicianTableColumns,
  getTechnicianTableData,
} from "@/components/facility/maintenance/infoTableData";
import {
  getDynamicObjectRecordById,
  getDynamicObjectRecords,
} from "@/api/dynamic/dynamicObjectNameApi";
import { handleDrawer } from "@/utils/modal/helpers";
import { formatWorkOrder, getUniqueData, formatWorkOrderJob } from "@/utils/facility/helpers";
import { statusColorMap } from "@/utils/maps/statusColorMap";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

const renderNotes = (notes) => {
  if (!notes || !notes.length) return null;

  return notes.map((note, index) => (
    <p key={`note-${index}`} className="card note-text">
      {note}
    </p>
  ));
};

const renderStatus = (value) => (
  <Status
    baseClassName="status-cell"
    status={value}
    bgColor={statusColorMap.get(value?.toLowerCase())}
  >
    <StatusCircle color={statusColorMap.get(value?.toLowerCase())} />
  </Status>
);

const formatWorkOrderDetails = (maintenance) => {
  let data = [
    {
      label: "Type",
      value: capitalize(maintenance.type),
    },
    {
      label: "Title",
      value: maintenance.title,
    },
  ];

  if (maintenance.serviceRequest) {
    data = data.concat([
      {
        label: "Service Request",
        value: maintenance.serviceRequest?.label,
      },
      {
        label: "Building",
        value: maintenance.building?.label,
      },
      {
        label: "Unit",
        value: maintenance.unit?.label,
      },
    ]);
  }

  data = data.concat([
    {
      label: "Category",
      value: maintenance.category.label,
    },
  ]);

  if (maintenance.serviceRequest) {
    data = data.concat([
      {
        label: "Priority",
        value: maintenance.priority.label,
        render: renderStatus(maintenance.priority.label),
      },
    ]);
  }

  if (maintenance.visualInspection && maintenance.visualInspection.label) {
    data = data.concat([
      {
        label: "Visual Inspection",
        value: maintenance.visualInspection.label,
      },
    ]);
  }

  if (maintenance.incidentReport && maintenance.incidentReport.label) {
    data = data.concat([
      {
        label: "Incident Report",
        value: maintenance.incidentReport.label,
      },
    ]);
  }

  data = data.concat([
    {
      label: "Status",
      value: maintenance.workOrderStatus.label,
      render: renderStatus(maintenance.workOrderStatus.label),
    },
  ]);

  return data;
};

function RightContent({
  isWorkOrderCompleted, isWorkOrderReactive, objectName, id, data
}) {
  const navigate = useNavigate();
  const { workOrderId } = useParams();
  const actionsData =
    isWorkOrderCompleted && isWorkOrderReactive ?
      [
        {
          name: "Create Performa Invoice",
          icon: "plus-icon",
          actionType: "CreatePerformaInvoice",
        },
      ] :
      [];

  return (
    <div className="buttons-container buttons-at-end">
      <Button
        onClick={() => handleDrawer(workOrderId, navigate, false, "objectActivityDrawers")}
        className="btn-with-icon stroke-icon-btn"
        small
      >
        <Button.Prepend>
          <SvgIcon icon="pulse-icon" />
        </Button.Prepend>
        Activity Log
      </Button>
      <ActionDropdown
        objectName={pascalize(objectName)}
        id={id}
        data={data}
        actions={actionsData}
        showTableActions
        removeActions={["View"]}
        testId="Action-Dropdown"
        trigger={(
          <div className="action-dropdown-trigger">
            <span className="text">Actions</span>
            <span className="material-icons-outlined">expand_more</span>
          </div>
        )}
      />
    </div>
  );
}

function DetailItem({ heading, item }) {
  return (
    <div className="row">
      <div className="col-12">
        <h6 className="headline">{heading}</h6>
        {item}
      </div>
    </div>
  );
}

DetailItem.propTypes = {
  heading: PropTypes.string.isRequired,
  item: PropTypes.node.isRequired,
};

const formatWorkOrderDetail = (workOrder, jobs) => {
  if (!workOrder || !jobs) {
    return;
  }

  const workOrderData = formatWorkOrder(workOrder);
  const workOrderJobs = jobs.data.map((j) => formatWorkOrderJob(j));
  workOrderData.jobs = workOrderJobs;
  workOrderData.technicians = getUniqueData(workOrderData.jobs, "technician");
  workOrderData.materials = getUniqueData(workOrderData.jobs, "materials");
  workOrderData.equipment = getUniqueData(workOrderData.jobs, "equipment");
  workOrderData.notes = flatten(map(workOrderData.jobs, "notes"));
  workOrderData.photos = flatten(map(workOrderData.jobs, "photos"));
  workOrderData.audios = flatten(map(workOrderData.jobs, "technicianAudios"));
  workOrderData.videos = flatten(map(workOrderData.jobs, "technicianVideos"));

  return workOrderData;
};

function WorkOrderDetails() {
  const navigate = useNavigate();
  const { workOrderId } = useParams();
  const isMobileView = useIsMobile();
  const [filterText, setFilterText] = useState("");
  const { data, isLoading } = useQuery(
    [kebabCase(dynamicObjectMap.get("WorkOrderObjectName")), workOrderId],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("WorkOrderObjectName"), workOrderId)
  );

  const { data: jobs, isLoading: isLoadingJobs } = useQuery(
    [kebabCase(dynamicObjectMap.get("WorkOrderJobObjectName")), workOrderId],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("WorkOrderJobObjectName"), {
        workOrder: workOrderId,
        queryMode: "Deep",
        sortBy: "CreatedAt",
        sortType: "DESC",
      })
  );

  const workOrder = useMemo(() => formatWorkOrderDetail(data, jobs), [data, jobs]);

  const isWorkOrderCompleted = useCallback(() => {
    if (data) {
      return ["Completed", "Closed"].includes(data?.status);
    }

    return false;
  }, [data]);

  const isWorkOrderReactive = useCallback(() => {
    if (data) {
      return data?.type?.toLowerCase() === "reactive";
    }

    return false;
  }, [data]);

  if (isLoading || isLoadingJobs) {
    return <Loader />;
  }

  return (
    <BoxedContent className="details-page">
      {!isMobileView ? (
        <Header
          showBreadcrumb
          leftContent={<h1>{workOrder?.title}</h1>}
          rightContent={(
            <RightContent
              isWorkOrderReactive={isWorkOrderReactive()}
              isWorkOrderCompleted={isWorkOrderCompleted()}
              objectName="WorkOrder"
              id={workOrderId}
              data={data}
              actions={data?.actions || {}}
            />
          )}
        />
      ) : (
        <>
          <Input
            className="search-input input-height-fix mb-3"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          >
            <Input.PrependIcon>
              <SvgIcon icon="search" />
            </Input.PrependIcon>
          </Input>
          <div className="d-grid mb-3">
            <Button
              onClick={() => handleDrawer(workOrderId, navigate, false, "objectActivityDrawers")}
              className="btn-with-icon stroke-icon-btn"
            >
              <Button.Prepend>
                <SvgIcon icon="pulse-icon" />
              </Button.Prepend>
              Activity Log
            </Button>
          </div>
        </>
      )}
      <div className="row">
        <div className="col-md-5">
          <div className="card">
            <TwoColumnList data={formatWorkOrderDetails(workOrder)} />
          </div>
        </div>
      </div>

      <Accordion title="Jobs" count={workOrder.jobs.length}>
        {workOrder.jobs.length ? (
          <DetailItem item={<JobsTable data={workOrder.jobs} isReadOnly />} />
        ) : null}
      </Accordion>

      <Accordion title="Technicians" count={workOrder.technicians.length}>
        {workOrder.technicians?.length ? (
          <DetailItem
            item={(
              <InfoTable
                data={getTechnicianTableData(workOrder.technicians)}
                columns={getTechnicianTableColumns(isWorkOrderCompleted())}
              />
            )}
          />
        ) : null}
      </Accordion>

      <Accordion
        title="Materials & Equipment"
        count={workOrder.materials.length + workOrder.equipment.length}
      >
        <div className="row">
          {workOrder.materials.length ? (
            <div className="col-lg-6">
              <DetailItem
                heading="Materials"
                item={(
                  <InfoTable
                    data={getMaterialTableData(workOrder.materials, isWorkOrderCompleted())}
                    columns={getMaterialTableColumns(isWorkOrderCompleted())}
                  />
                )}
              />
            </div>
          ) : null}
          {workOrder.equipment.length ? (
            <div className="col-lg-6">
              <DetailItem
                heading="Equipment"
                item={(
                  <InfoTable
                    data={getEquipmentTableData(workOrder.equipment)}
                    columns={getEquipmentTableColumns(isWorkOrderCompleted())}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
      </Accordion>

      {workOrder?.notes?.length ? (
        <Accordion title="Notes">
          <DetailItem item={renderNotes(workOrder?.notes)} />
        </Accordion>
      ) : null}

      {workOrder.inspectionNotes ? (
        <Accordion title="Inspection Notes">
          <DetailItem item={renderNotes(workOrder.inspectionNotes)} />
        </Accordion>
      ) : null}

      <Accordion title="Proforma Invoices">
        <DetailItem
          item={(
            <DynamicObjectOverview
              objectName={dynamicObjectMap.get("ProformaInvoiceObjectName")}
              filters={{ workOrder: workOrder.id }}
              showTableOnly
            />
          )}
        />
      </Accordion>

      {workOrder?.videos?.length || workOrder?.photos?.length || workOrder?.audios?.length ? (
        <Accordion title="Gallery">
          <div className="row">
            {workOrder?.videos?.length || workOrder?.photos?.length ? (
              <div className="col-lg-8">
                <DetailItem
                  heading="Recent Uploads"
                  item={(
                    <div className="images-container">
                      <Gallery videos={workOrder?.videos} images={workOrder?.photos} />
                    </div>
                  )}
                />
              </div>
            ) : null}

            {workOrder?.audios?.length ? (
              <div className="col-lg-4">
                <DetailItem
                  heading="Recent Voice Notes"
                  item={workOrder?.audios?.map((src, index) => (
                    <AttachedFile
                      key={`audio-${index}`}
                      src={getAttachmentUrl(src)}
                      title={src.split("/").pop().toLowerCase()}
                      date={formatDate(new Date())}
                    />
                  ))}
                />
              </div>
            ) : null}
          </div>
        </Accordion>
      ) : null}
      <DynamicObjectActivityDrawer
        tabs={[
          { label: "All", scope: workOrderId },
          { label: "Status Changed", scope: workOrderId, filters: { subject: "Status changed" } },
        ]}
      />
    </BoxedContent>
  );
}

export default WorkOrderDetails;
