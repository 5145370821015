/* eslint-disable no-plusplus */
import {
  emptyTextCell,
  nonEditable,
  textCell,
  monthHeaderCell,
  noSideBorders,
  chevronCell
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT, HEADING_ROW_HEIGHT } from "../constants";
import { formatNumber } from "@/utils/helpers";

export const CASHINCHANGE_ROW_ID = "cashInChange";

function getHeaderRow() {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: [
      nonEditable(monthHeaderCell("Type"), "trial-balance-header trial-balance-row justify-content-start"),
      nonEditable(monthHeaderCell("Date"), "trial-balance-header trial-balance-row justify-content-start"),
      nonEditable(monthHeaderCell("Name"), "trial-balance-header trial-balance-row justify-content-start"),
      nonEditable(monthHeaderCell("Num"), "trial-balance-header trial-balance-row justify-content-start"),
      nonEditable(monthHeaderCell("Qty"), "trial-balance-header trial-balance-row justify-content-start"),
      nonEditable(monthHeaderCell("Cost"), "trial-balance-header trial-balance-row justify-content-start"),
      nonEditable(monthHeaderCell("On Hand"), "trial-balance-header trial-balance-row justify-content-start"),
      nonEditable(monthHeaderCell("U/M"), "trial-balance-header trial-balance-row justify-content-start"),
      nonEditable(monthHeaderCell("Avg.Cost"), "trial-balance-header trial-balance-row justify-content-start"),
      nonEditable(monthHeaderCell("Asset Value"), "trial-balance-header trial-balance-row justify-content-start"),
    ]
  };
}

function getTotalCashInChangeRow(title) {
  return {
    rowId: CASHINCHANGE_ROW_ID,
    height: HEADING_ROW_HEIGHT,
    cells: [
      nonEditable(noSideBorders(textCell({ text: title })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: "" })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: "" })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: "" })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
    ]
  };
}

function getCabinets(title) {
  return {
    rowId: 1,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: title, className: "font-bold trial-balance-row justify-content-start", hasChildren: true, isExpanded: true
      }),
      nonEditable(noSideBorders(textCell({ text: "" })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: "" })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: "" })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
    ]
  };
}

function getDoorFrame(title) {
  return {
    rowId: 6,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: title, className: "font-bold trial-balance-row justify-content-start", hasChildren: true, isExpanded: true
      }),
      nonEditable(noSideBorders(textCell({ text: `${formatNumber(Math.abs(26666))}` })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: `${formatNumber(Math.abs(26666))}` })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: `${formatNumber(Math.abs(26666))}` })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: `${formatNumber(Math.abs(26666))}` })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: `${formatNumber(Math.abs(26666))}` })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: `${formatNumber(Math.abs(26666))}` })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: `${formatNumber(Math.abs(26666))}` })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: `${formatNumber(Math.abs(26666))}` })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: `${formatNumber(Math.abs(26666))}` })), "font-bold trial-balance-row justify-content-start"),
    ]
  };
}

function getInventory() {
  return [{
    rowId: 2,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: "Invoice", parentId: 1, isExpanded: true
      }),
      nonEditable(textCell({ text: "29-09-2023" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
    ]
  },
  {
    rowId: 3,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: "Invoice", parentId: 1, isExpanded: true
      }),
      nonEditable(textCell({ text: "29-09-2023" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "e/a" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
    ]
  },
  {
    rowId: 4,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: "Total Cabinets", parentId: 1, isExpanded: true, className: "font-bold trial-balance-row justify-content-start total-row"
      }),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: "e/a" }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(10904))}` }), "trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(10904))}` }), "trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(10904))}` }), "trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(10904))}` }), "trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(10904))}` }), "trial-balance-row justify-content-start total-row"),
    ]
  },
  {
    rowId: 5,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: "", parentId: 1, isExpanded: true
      }),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
    ]
  }
  ];

}

function getDoorFrameData() {
  return [{
    rowId: 7,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: "Brass Hinges", parentId: 6, isExpanded: true
      }),
      nonEditable(textCell({ text: "29-09-2023" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
      nonEditable(emptyTextCell(), ""),
    ]
  },
  {
    rowId: 8,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: "Door Knobs", parentId: 6, isExpanded: true
      }),
      nonEditable(textCell({ text: "29-09-2023" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "e/a" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
    ]
  },
  {
    rowId: 9,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: "Total", parentId: 6, isExpanded: true, className: "font-bold trial-balance-row justify-content-start total-row"
      }),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: "e/a" }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(10904))}` }), "trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(10904))}` }), "trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(10904))}` }), "trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(10904))}` }), "trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(10904))}` }), "trial-balance-row justify-content-start total-row"),
    ]
  },
  {
    rowId: 10,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: "", parentId: 6, isExpanded: true
      }),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
    ]
  }
  ];

}

export function getInventoryValuationRows({
  cashboxBank,
}) {
  return [
    getHeaderRow(),
    getTotalCashInChangeRow("Hardware", cashboxBank),
    getCabinets("Cabinets", cashboxBank),
    ...getInventory(),
    getDoorFrame("Door Frame", cashboxBank),
    ...getDoorFrameData("Income", cashboxBank),
  ];
}
