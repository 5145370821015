import qs from "qs";

import request from "@/utils/api/helpers";

const baseUrl = "/activity-logs";

export default function getActivityLogs(params) {
  const apiParams = { ...params };

  if (!apiParams.page) {
    apiParams.page = 1;
  }

  if (!apiParams.pageSize) {
    apiParams.pageSize = 10;
  }

  const queryParams = qs.stringify(apiParams, { addQueryPrefix: true });

  return request({
    url: `${baseUrl}${queryParams}`,
  });
}
