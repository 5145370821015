import { useState } from "react";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import DynamicObjectOverview, { defaultComponents } from "@/pages/dynamic/list-views/DynamicObjectOverview";
import { SelectWorkOrderTypeModal } from "@/components/modals";

function HeaderRightContent(props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <defaultComponents.HeaderRightContent {...props} onClickNew={() => setIsOpen(true)}>
      <SelectWorkOrderTypeModal isOpen={isOpen} onClose={setIsOpen} />
    </defaultComponents.HeaderRightContent>
  );
}

function WorkOrderOverview() {
  return (
    <DynamicObjectOverview
      objectName={dynamicObjectMap.get("WorkOrderObjectName")}
      components={{
        HeaderRightContent
      }}
      showFilters
    />
  );
}

export default WorkOrderOverview;
