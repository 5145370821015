import { useState, useRef, useMemo } from "react";
import { ReactGrid } from "@silevis/reactgrid";
import { Input, Button } from "@hydra/atom/components";
import { SvgIcon, CustomDropdown } from "@/components/common";
import { getTransactionDetailRows, getTransactionDetailColumns } from "./transaction-detail-statement";
import { inflows as emptyInflows, outflows as emptyOutflows } from "./profit-and-loss-statement/data";
import { calculateOutputVariables } from "./helpers";
import { ReportFilterDrawer } from "@/components/drawers";

const applyChange = (change) => (groups) => groups.map((group) => (group.title === change.rowId ?
  {
    ...group,
    // eslint-disable-next-line max-len
    values: group.values.map((value, idx) => (change.columnId === idx + 1 ? change.newCell.value : value)
    )
  } :
  group)
);

function ReportTransactionDetail() {
  const ref = useRef();
  // eslint-disable-next-line no-unused-vars
  const [openingBalance, setOpeningBalance] = useState(10000);
  const [cashInflow, setCashInflow] = useState(() => [...emptyInflows]);
  const [cashOutflow, setCashOutflow] = useState(() => [
    ...emptyOutflows
  ]);
  const [filterText, setFilterText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [columnsData, setColumnsData] = useState(
    getTransactionDetailColumns(ref?.current?.clientWidth));

  const data = [
    { label: "Transaction no", value: true },
    { label: "Type", value: true },
    { label: "Date", value: true },
    { label: "Num", value: true },
    { label: "Name", value: true },
    { label: "Building Name", value: true },
    { label: "Unit", value: true },
    { label: "Unit Type", value: true },
    { label: "Department", value: true },
    { label: "Class", value: true },
    { label: "Memo/Description", value: true },
    { label: "Account", value: true },
    { label: "Tax Amount", value: true },
    { label: "Amount", value: true },
    { label: "Create Date", value: true },
    { label: "Created By", value: true },
    { label: "Last Modified", value: true },
    { label: "Total", value: true },
    { label: "Balance", value: true }
  ];
  const onClose = (value) => {
    setIsOpen(value);
  };
  const columns = useMemo(
    () => getTransactionDetailColumns(ref?.current?.clientWidth),
    [ref?.current?.clientWidth]
  );

  const inputVariables = {
    cashInflow,
    cashOutflow,
    openingBalance,
  };

  const years = new Array(7).fill(0);
  const outputVariables = calculateOutputVariables(inputVariables, years);
  const plannerData = {
    ...inputVariables,
    ...outputVariables
  };

  const rows = useMemo(() => getTransactionDetailRows(plannerData), []);

  const handleChanges = (changes) => {
    changes.forEach((change) => {
      setCashInflow((cash) => applyChange(change)(cash));
      setCashOutflow((cash) => applyChange(change)(cash));
    });
  };

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-3">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-9 btn-icon-container">
          <CustomDropdown data={data} setColumnData={setColumnsData} />
          <Button
            onClick={() => setIsOpen(true)}
            small
            bordered
          >
            <Button.Prepend>
              <SvgIcon icon="filter-lines" />
            </Button.Prepend>
          </Button>
          <ReportFilterDrawer
            filters={filters}
            setFilters={setFilters}
            size={400}
            isOpen={isOpen}
            onClose={onClose}
          />
        </div>
      </div>
      <div className="react-grid-container">
        <ReactGrid
          rows={rows}
          columns={columns}
          onCellsChanged={handleChanges}
          stickyTopRows={1}
          enableFillHandle
          enableRangeSelection
        />
      </div>
    </div>
  );
}

export default ReportTransactionDetail;
