import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { camelize, pascalize } from "humps";

import { getCompanyAccounts } from "@/api/admin/companyAccountApi";
import { formatGLAccountOption } from "@/utils/finance/helpers";

export default function useCompanyAccount(args = {}) {
  const {
    params = {},
    accountMap = null,
    isEnabled = true,
    format = "Normal"
  } = args;

  const [defaultAccounts, setDefaultAccounts] = useState(null);

  const { data: companyAccounts, isInitialLoading: isLoadingCompanyAccounts } = useQuery(
    ["company-account", params],
    () => getCompanyAccounts(params),
    {
      enabled: isEnabled,
    }
  );

  useEffect(() => {
    if (!isLoadingCompanyAccounts && companyAccounts && companyAccounts?.data) {
      const accountsData = {};

      if (format === "AccountMap") {
        companyAccounts?.data.forEach((account) => {
          const { company, accountType } = account;
          const camelCaseCompanyName = camelize(company.name);
          if (!accountMap) {
            throw new Error("Accounts Map is not provided to the useCompanyAccount hook");
          }

          const fieldName = accountMap[camelize(accountType)];

          if (!fieldName) {
            return;
          }

          const pascalCaseFieldName = pascalize(fieldName);
          const companyAccountName = `${camelCaseCompanyName}${pascalCaseFieldName}`;
          const companyAccount = {};
          companyAccount[companyAccountName] = {
            ...formatGLAccountOption(account.account),
            companyId: company.id,
            companyName: camelize(company.name),
          };

          if (accountsData[camelize(account.accountType)]) {
            accountsData[camelize(account.accountType)] = {
              ...accountsData[camelize(account.accountType)],
              ...companyAccount,
            };
          } else {
            accountsData[camelize(account.accountType)] = companyAccount;
          }
        });
      }

      if (format === "RecordState") {
        companyAccounts?.data.forEach((account) => {
          const camelCaseCompanyName = camelize(account.company.name);
          const pascalCaseAccountType = pascalize(account.accountType);
          const key = `${camelCaseCompanyName}${pascalCaseAccountType}`;
          accountsData[key] = {
            ...formatGLAccountOption(account.account),
            companyId: account.company.id,
            companyName: camelize(account.company.name),
            recordId: params?.recordId
          };
        });
      }

      if (format === "Normal") {
        companyAccounts?.data.forEach((account) => {
          accountsData[camelize(account.accountType)] = {
            ...formatGLAccountOption(account.account),
            companyId: account.company.id,
            companyName: camelize(account.company.name),
          };
        });
      }

      setDefaultAccounts(accountsData);
    }
  }, [companyAccounts]);

  return defaultAccounts;
}
