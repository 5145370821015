import { useState } from "react";
import { SvgIcon, IconButton } from "@/components/common";
import appSettings from "@/settings";

function ImageSlider({ images }) {
  const [current, setCurrent] = useState(0);
  const size = images?.length;

  const nextSlide = () => {
    setCurrent(current === size - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? size - 1 : current - 1);
  };

  if (!Array.isArray(images) || images.length <= 0) {
    return null;
  }

  return (
    <section className="image-slider">
      <IconButton onClick={prevSlide} className="image-slider-previous">
        <SvgIcon icon="arrow-up-icon" />
      </IconButton>
      {images.map((slide, index) => (
        <div
          className={index === current ? "image-slide active" : "image-slide"}
          key={index}
        >
          {index === current && (
          <img src={`${appSettings.minioBaseUrl}${slide}`} alt="" className="image-slider-src" />
          )}
        </div>
      ))}

      <IconButton onClick={nextSlide} className="image-slider-next">
        <SvgIcon icon="arrow-up-icon" />
      </IconButton>
    </section>
  );
}

export default ImageSlider;
