import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { BoxedContent, Header } from "@/components/common";
import { CardLayout } from "@/components/dashboard";
import {
  getDynamicDashboardView,
} from "@/api/dynamic/dynamicDashboardViewApi";
import { DashboardComponentLoader } from "@/components/dynamic";

function Dashboard() {
  const { schemaId } = useParams();

  const {
    data: dashboardComponents
  } = useQuery(
    ["dynamic-dashboard-schema", schemaId],
    () => getDynamicDashboardView(schemaId),
    {
      enabled: schemaId !== undefined,
    }
  );

  const getDashboardComponentContainerClassName = (componentConfig) => {
    switch (componentConfig.dashboardType.toLowerCase()) {
      case "tabular":
        return "col-12";

      case "scorecard":
        return "col-md-3";

      case "bar":
        return "col-md-6";

      default:
        return "col-md-4";
    }
  };

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        className="page-header"
        leftContent={<h1>Dashboard</h1>}
      />

      <div className="row cards-row">
        {dashboardComponents?.data.map((dashboardComponent, index) => (
          <div
            key={`${dashboardComponent.name}-${index}`}
            className={getDashboardComponentContainerClassName(
              dashboardComponent
            )}
          >
            <CardLayout title={dashboardComponent.label}>
              <DashboardComponentLoader config={dashboardComponent} />
            </CardLayout>
          </div>
        ))}
      </div>
    </BoxedContent>
  );
}

export default Dashboard;
