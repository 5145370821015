export default function getBalanceSheetColumns(period) {
  const windowWidth = window.innerWidth - 300;

  const colArr = [
    {
      label: "Accounts",
      value: true,
      columnId: "name",
      resizable: true,
      width: windowWidth - 400,
      formatter: (value) => String(value),
    },
    {
      label: `${period?.label}`,
      value: true,
      columnId: "amount",
      width: 400,
      resizable: true,
      formatter: (value) => {
        if (!value) {
          return "";
        }
        return value;
      },
    }
  ];
  return colArr;
}
