import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";
import Skeleton from "react-loading-skeleton";

import { AlertModal } from "@/components/modals";
import { useModal, useIsMobile } from "@/hooks";

import { getJobColumns, getJobData, getJobMobileColumns } from "./jobsTableData";
import { setJobData, deleteJob } from "@/reducers/facility/workOrderFormReducer";
import { deleteDynamicObjectRecord } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import showToast from "@/utils/toast/helpers";

export default function JobsTable({
  data,
  dispatch,
  onRowClick,
  isReadOnly,
  isEditing,
  isLoading,
  isVendor,
}) {
  const tableData = useMemo(() => getJobData(data), [data]);
  const [selectedId, setSelectedId] = useState(null);
  const { isOpen, closeModal, openModal } = useModal(false);
  const isMobileView = useIsMobile(992);

  const columns = useMemo(() => {
    if (isLoading) {
      if (isMobileView) {
        return getJobMobileColumns(isReadOnly, isVendor).map((column) => ({
          ...column,
          Cell: Skeleton,
        }));
      }
      return getJobColumns(isReadOnly, isVendor).map((column) => ({
        ...column,
        Cell: Skeleton,
      }));
    } if (isMobileView) {
      return getJobMobileColumns(isReadOnly, isVendor);
    }
    return getJobColumns(isReadOnly, isVendor);
  }, [isLoading, isReadOnly, isMobileView, isVendor]);

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } =
    useTable({
      columns,
      data: tableData,
      toggleAlertDialogue: (id) => {
        openModal();
        setSelectedId(id);
      },
      editJob: (id) => dispatch(setJobData(id)),
    });

  const handleDelete = async () => {
    if (isEditing) {
      try {
        await deleteDynamicObjectRecord(
          dynamicObjectMap.get("WorkOrderJobObjectName"),
          selectedId
        );
      } catch (error) {
        showToast("Could not remove job", "error");
      }
    }

    dispatch(deleteJob(selectedId));
    setSelectedId(null);
    closeModal();
  };

  return (
    <div className="table-wrapper jobs-table">
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="table-header-row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className={`table-header-cell ${column.collapse ? "collapse" : ""}`}
                  {...column.getHeaderProps()}
                >
                  <div className="table-header-cell-content">
                    <span>{column.render("Header")}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                className="table-row"
                {...row.getRowProps()}
                onClick={() => onRowClick(row.original.key)}
              >
                {row.cells.map((cell) => (
                  <td
                    className={`table-body-cell ${cell.column.collapse ? "collapse" : ""}`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <AlertModal
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={handleDelete}
      />
    </div>
  );
}

JobsTable.propTypes = {
  data: PropTypes.array.isRequired,
  dispatch: PropTypes.func,
  onRowClick: PropTypes.func,
  isReadOnly: PropTypes.bool,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
};

JobsTable.defaultProps = {
  onRowClick: () => {},
  dispatch: () => {},
  isReadOnly: false,
  isEditing: false,
  isLoading: false,
};
