import PropTypes from "prop-types";
import TimePicker from "react-time-picker";
import { getTestId } from "@/utils/helpers";

export default function CustomizedTimePicker({
  value, onChange, disabled, name, error, format, testId, ...rest
}) {
  return (
    <>
      <TimePicker
        name={name}
        className="custom-time-picker"
        clockIcon={null}
        clearIcon={null}
        disableClock
        format={format}
        onChange={onChange}
        value={value}
        disabled={disabled}
        data-testid={getTestId(testId)}
        {...rest}
      />
      {typeof error === "string" && <span className="error-text">{error}</span>}
    </>
  );
}

CustomizedTimePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  format: PropTypes.string,
  testId: PropTypes.string,
};

CustomizedTimePicker.defaultProps = {
  disabled: false,
  name: "timePicker",
  error: false,
  value: null,
  format: "hh:mm a",
  testId: ""
};

CustomizedTimePicker.Type = "HydraValidatableComponent";
