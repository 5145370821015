import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";

import { Pagination } from "@hydra/atom/components";
import { AlertModal } from "@/components/modals";
import { useModal } from "@/hooks";
import Accordion from "../../common/Accordion";
import {
  getOverviewTableColumns,
  getOverviewTableData,
} from "./WorkOrderTableData";
import { deleteDynamicObjectRecord, getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { skeletonLoadingRows } from "@/utils/helpers";
import { formatWorkOrder } from "@/utils/facility/helpers";
import showToast from "@/utils/toast/helpers";

const perPageOptions = [
  { label: "12", value: 12 },
  { label: "24", value: 24 },
  { label: "36", value: 36 },
];

export default function OverviewTable({
  statusFilter
}) {
  const [selectedId, setSelectedId] = useState(null);
  const { isOpen, closeModal, openModal } = useModal(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageOptions[0]);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };

  const deleteMutation = useMutation(
    (recordId) => deleteDynamicObjectRecord("work-order", recordId),
    {
      onError: () => {
        showToast("Could not delete work order. Try again!", "error");
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["work-order"]
        });
        showToast("Work Order Deleted", "success");
        setSelectedId(null);
        closeModal();
      },
    }
  );

  const { data: workOrders, isLoading: loadingWorkOrders } = useQuery(
    ["getDynamicObjectRecords", "work-order", currentPage, perPage.value, statusFilter],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("WorkOrderObjectName"), {
        takePage: currentPage,
        limitPage: perPage.value,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
        Status: statusFilter
      })
  );

  const totalCount = useMemo(() => workOrders?.totalCount || 0, [workOrders]);
  const totalPages = useMemo(() => workOrders?.totalPages, [workOrders]);
  // eslint-disable-next-line max-len
  const formattedWorkOrders = useMemo(() => workOrders?.data.map((w) => formatWorkOrder(w)) || skeletonLoadingRows(), [workOrders]);
  const tableData = useMemo(() => getOverviewTableData(formattedWorkOrders), [formattedWorkOrders]);
  const columns = useMemo(
    () => (loadingWorkOrders ?
      getOverviewTableColumns().map((column) => ({
        ...column,
        Cell: Skeleton,
      })) :
      getOverviewTableColumns()),
    [loadingWorkOrders]
  );
  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } =
    useTable({
      columns,
      data: tableData,
      toggleAlertDialogue: (id) => {
        openModal();
        setSelectedId(id);
      },
      editWorkOrder: (id) => {
        navigate(`/facility-management/work-order/${id}`);
      },
    });

  const handleDelete = async () => {
    // TODO: check if jobs and material also get deleted
    deleteMutation.mutate(selectedId);
  };

  if (workOrders?.data?.length === 0) return;

  return (
    <div className={`plan-board-accordion-${statusFilter.toLowerCase()}`}>
      <Accordion
        key={statusFilter}
        title={statusFilter}
        count={workOrders?.data?.length}
      >
        <div className="table-wrapper">

          <div className="overflow">
            <table className="table" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="table-header-row"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        className={`table-header-cell ${
                          column.collapse ? "collapse" : ""
                        }`}
                        {...column.getHeaderProps()}
                      >
                        <div className="table-header-cell-content">
                          <span>{column.render("Header")}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr className="table-row" {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          className={`table-body-cell ${
                            cell.column.collapse ? "collapse" : ""
                          }`}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {!loadingWorkOrders && (
          <Pagination
            className="dashboard-pagination"
            options={perPageOptions}
            perPage={perPage}
            onSelectChange={(val) => handlePagination(currentPage, val)}
            pageRangeDisplayed={3}
            handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
            showResults
            offset={0}
            totalItems={totalCount}
            pageCount={totalPages || Math.ceil(totalCount / perPage.value)}
            reactPaginateProps={{
              previousLabel: <span className="material-icons">&#xe5cb;</span>,
              nextLabel: <span className="material-icons">&#xe5cc;</span>,
              forcePage: currentPage - 1,
            }}
          />
          )}

          <AlertModal
            onClose={closeModal}
            isOpen={isOpen}
            onConfirm={handleDelete}
          />
        </div>
      </Accordion>
    </div>
  );
}

OverviewTable.propTypes = {
  data: PropTypes.array.isRequired,
  perPage: PropTypes.object.isRequired,
  perPageOptions: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number,
  handlePagination: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

OverviewTable.defaultProps = {
  isLoading: false,
  totalPages: 1,
};
