import { getBounceChequeRows, getBounceChequeColumns } from "./bounce-cheque-statement";
import { getBounceCheque } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const bounceChequeFilters = {
  TenantName: "*",
  TenantKey: "*",
  ComponentName: "*",
  ComponentNumber: "*",
  PaymentReceiptNumber: null,
  ChequeDueDate: "*",
  ChequeAmount: "*"
};

const bounceFilterOptions = [
  { key: "tenantName", apiKey: "TenantName", keyType: "label" },
  { key: "tenantKey", apiKey: "TenantKey", keyType: "string" },
  { key: "componentName", apiKey: "ComponentName", keyType: "label" },
  { key: "componentNumber", apiKey: "ComponentNumber", keyType: "number" },
  { key: "paymentReceiptNumber", apiKey: "PaymentReceiptNumber", keyType: "string" },
  { key: "chequeDueDate", apiKey: "ChequeDueDate", keyType: "date" },
  { key: "chequeAmount", apiKey: "ChequeAmount", keyType: "string" }
];

export default function ReportBounceCheque({ activeCompany }) {
  return (
    <DynamicReport
      reportName="bounce-cheque"
      activeCompany={activeCompany}
      columnsDataProvider={getBounceChequeColumns}
      rowsDataProvider={getBounceChequeRows}
      fetchData={getBounceCheque}
      exportUrl="reports/export-query-report/bounce-cheque"
      filtersInitialState={bounceChequeFilters}
      filterOptions={bounceFilterOptions}
      isPaginated={false}
    />
  );
}
