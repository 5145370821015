import { useRef, useReducer, useEffect, useMemo, useCallback, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { groupBy, isEmpty, isEqual, startCase } from "lodash";
import { toast } from "react-hot-toast";
import { getYear } from "date-fns";
import { pascalize } from "humps";

import {
  Button,
  Form,
  FormControl,
  Input,
  Label,
  Loader,
  ReactSelect,
} from "@hydra/atom/components";
import { BoxedContent, Header, Select, SvgIcon } from "@/components/common";
import {
  budgetFormReducer,
  initialState,
  setFormValue,
  setInitialState,
  intervalOptions,
} from "@/reducers/finance/budgetFormReducer";
import {
  createBudget,
  updateBudget,
  getBudgetById,
  getBudgetPeriods,
  updatePeriodicBudget,
  importBudgetData,
} from "@/api/finance/budgetApi";
import { selectActiveCompany } from "@/store/appSlice";
import { getAccountingPeriods } from "@/api/finance/accountingPeriodApi";
import BudgetTable from "@/components/finance/budget/BudgetTable";
import { getBudgetTableColumns, getBudgetTableData } from "@/components/finance/budget/budgetTableData";
import TableSkeleton from "@/components/common/TableSkeleton";
import showToast from "@/utils/toast/helpers";

const validationMessages = {
  required: {
    required: "This field is required",
  },
};

const prepareData = ({ state, budgetData, periods, isEditing }) => {
  const { name, accountPeriod, interval, company, code, fiscalYear, businessPlan } = state;
  const data = {
    name,
    accountPeriodId: accountPeriod?.value,
    interval: interval?.value,
    companyId: company?.value,
    subDividedBy: "DontSubdivide",
    subDividedFor: "",
  };

  if (!isEditing) {
    data.lineItems = [];

    budgetData.filter((r) => r.account).forEach((budgetRow) => {
      const lineItem = {};
      const { account, budgetCategory, costCenter } = budgetRow;

      lineItem.account = account?.value;
      lineItem.category = budgetCategory?.code;
      lineItem.amounts = [];

      // Add periodic buckets for each category
      periods.forEach((period) => {
        const { number } = period;
        const budgetAmount = Number(budgetRow[`p${number}`]?.value);
        lineItem.amounts.push(budgetAmount || 0);
      });

      // Add cost centers
      if (!isEmpty(costCenter)) {
        const values = Object.values(costCenter);
        const costCenterKeys = values.filter((v) => v).map((v) => v.value);
        lineItem.costCenters = costCenterKeys;
      }

      data.lineItems.push(lineItem);
    });
  }

  if (isEditing) {
    data.code = code || `${fiscalYear}`;
    data.businessPlan = businessPlan;
    data.fiscalYear = fiscalYear;
  }

  return data;
};

const calculateRowTotal = ({ row, interval }) => {
  let total = 0;
  let totalPeriods = 1;

  switch (interval) {
    case "Monthly":
      totalPeriods = 12;
      for (let i = 0; i < totalPeriods; i += 1) {
        const monthAmount = row[`p${i + 1}`]?.value;
        if (monthAmount) {
          total += Number(monthAmount);
        }
      }

      break;

    case "Quarterly":
      totalPeriods = 4;
      for (let i = 0; i < totalPeriods; i += 1) {
        const quarterAmount = row[`p${i + 1}`]?.value;

        if (quarterAmount) {
          total += Number(quarterAmount);
        }
      }

      break;

    case "Yearly":
      total = row.p1?.value;
      break;

    default:
      break;
  }

  return total;
};

const getUniquePrefix = (budgetRow) => {
  const { account, budgetCategory } = budgetRow;
  let uniquePrefix = "";

  if (account) {
    uniquePrefix += account.value;
  }

  if (budgetCategory) {
    uniquePrefix += budgetCategory.value;
  }

  return uniquePrefix;
};

const prepareTableData = ({ periodicBudgets, interval }) => {
  const data = [];
  const uniqueLineItemIds = [];

  const budgetBuckets = periodicBudgets.map((periodicBudget) => {
    let uniqueId = "";
    const { ledgerAccount, category, costCenters } = periodicBudget;
    if (ledgerAccount) {
      uniqueId += ledgerAccount.id;
    }

    if (category) {
      uniqueId += category.id;
    }

    if (costCenters.length) {
      const costCenterIds = [];
      costCenters.forEach((costCenter) => {
        const costCenterUniqueId = uniqueId + costCenter.id;
        uniqueLineItemIds.push(costCenterUniqueId);
        costCenterIds.push(costCenter.id);
      });

      uniqueId += costCenterIds.join("");
    }

    periodicBudget.uniqueId = uniqueId;

    return periodicBudget;
  });

  const groupedBudgets = groupBy(budgetBuckets, "uniqueId");

  Object.keys(groupedBudgets).forEach((groupBudgetKey, index) => {
    const groupBudget = groupedBudgets[groupBudgetKey];
    const { uniqueId, ledgerAccount, category, costCenters } = groupBudget[0];

    const tableRow = {
      id: uniqueId,
      index: index + 1,
      account: null,
      budgetCategory: null,
      costCenter: {},
    };

    if (ledgerAccount) {
      tableRow.account = {
        label: ledgerAccount.code,
        code: ledgerAccount.code,
        value: ledgerAccount.id,
      };
    }

    if (category) {
      tableRow.budgetCategory = {
        label: category.code,
        code: category.code,
        value: category.id,
      };
    }

    costCenters.forEach((costCenter) => {
      const { id: costCenterId, type, name: costCenterName } = costCenter;
      tableRow.costCenter[type.trim()] = {
        label: costCenterName,
        value: costCenterId,
        objectName: pascalize(type),
      };
    });

    groupBudget.forEach((b) => {
      tableRow[`p${b.number}`] = {
        id: b.id,
        value: b.amountInCurrency / 100,
        period: b.number,
      };
    });

    const total = calculateRowTotal({
      row: tableRow,
      interval,
    });

    tableRow.total = total;
    data.push(tableRow);
  });

  return {
    tableData: data,
    uniqueLineItemIds,
  };
};

const prepareState = (data) => {
  const {
    name,
    accountingPeriod,
    interval,
    businessPlan,
    fiscalYear,
    subDividedBy,
    subDividedFor,
    code,
    periodicBudgets,
  } = data;

  const state = {
    name,
    code,
    businessPlan,
    fiscalYear,
    subDividedBy,
    subDividedFor,
  };

  if (accountingPeriod) {
    state.accountPeriod = {
      ...accountingPeriod,
      label: accountingPeriod.name,
      value: accountingPeriod.id,
    };
  }

  state.interval = {
    label: interval,
    value: interval,
  };

  const { tableData, uniqueLineItemIds } = prepareTableData({
    periodicBudgets,
    interval,
  });

  return {
    state,
    tableData,
    uniqueLineItemIds,
  };
};

export default function BudgetForm() {
  const activeCompany = useSelector(selectActiveCompany);
  const [state, dispatch] = useReducer(budgetFormReducer, initialState);
  const [searchParams] = useSearchParams();
  const [budgetTableData, setBudgetTableData] = useState([]);
  const [uniqueLineItemIds, setUniqueLineItemIds] = useState([]);
  const fileInputRef = useRef(null);

  const typeParam = searchParams.get("type");

  const submitButtonRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const budgetInputPlaceholder = useMemo(() => {
    const currentYear = getYear(new Date());

    return `Budget for ${currentYear}-${currentYear + 1}`;
  }, []);

  const enableBudgetPeriod = useCallback(() => {
    const { accountPeriod, interval } = state;

    if (!accountPeriod || !interval || !activeCompany) {
      return false;
    }

    return true;
  }, [state.accountPeriod, state.interval, activeCompany]);

  const { data: periods, isInitialLoading: isLoadingPeriods } = useQuery(
    ["budget", "budget-period", state.accountPeriod, state.interval],
    () =>
      getBudgetPeriods({
        companyId: activeCompany.id,
        name: state.name,
        accountPeriodId: state.accountPeriod?.id,
        interval: state.interval?.value,
      }),
    {
      enabled: enableBudgetPeriod(),
    }
  );

  const { data: budget, isInitialLoading: isLoadingBudget } = useQuery(
    ["budget", id],
    () => getBudgetById(id),
    {
      enabled: id !== undefined,
    }
  );

  const columns = useMemo(
    () =>
      getBudgetTableColumns({
        interval: state.interval,
        periods,
      }),
    [periods, state.interval]
  );

  useEffect(() => {
    if (id && budget?.data) {
      const {
        state: stateData,
        tableData,
        uniqueLineItemIds: uniqueIds,
      } = prepareState(budget?.data);
      stateData.company = {
        label: activeCompany.name,
        value: activeCompany.id,
      };
      dispatch(setInitialState(stateData));
      setBudgetTableData(tableData);
      setUniqueLineItemIds(uniqueIds);
    }
  }, [budget]);

  useEffect(() => {
    if (!id && typeParam) {
      const tableData = getBudgetTableData();
      setBudgetTableData(tableData);
      setUniqueLineItemIds([]);
    }
  }, [state.interval]);

  const saveMutation = useMutation(createBudget, {
    onError: () => {
      showToast("Could not create. Try again!", "error");
    },
    onSuccess: () => {
      showToast("Created successfully", "success");
      navigate(-1);
    },
  });

  const updateBudgetMutation = useMutation(
    ({ id: recordId, data: recordData }) => updateBudget(recordId, recordData),
    {
      onError: () => {
        showToast("Could not update. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Updated successfully", "success");
        navigate(-1);
      },
    }
  );

  const updatePeriodicBudgetMutation = useMutation(
    ({ id: recordId, data: recordData }) => updatePeriodicBudget(recordId, recordData),
    {
      onError: () => {
        showToast("Could not update. Try again!", "error");
      },
    }
  );

  const importMutation = useMutation(({ file }) =>
    importBudgetData({
      file,
      accountPeriodId: state.accountPeriod?.id,
      interval: state.interval?.value,
    })
  );

  const handleCancel = () => {
    dispatch(setInitialState(initialState));
    navigate(-1);
  };

  const handleSave = () => {
    submitButtonRef.current.click();
  };

  const handleSubmit = async () => {
    if (!periods?.data) {
      return;
    }

    if (id) {
      showToast("Budget updated successfully!", "success");
      navigate(-1);
      return;
    }

    const recordData = prepareData({
      state: {
        ...state,
        company: {
          label: activeCompany.name,
          value: activeCompany.id,
        },
      },
      budgetData: budgetTableData,
      periods: periods?.data,
      isEditing: Boolean(id),
    });

    saveMutation.mutate(recordData);
  };

  const setData = async (rowIndex, columnId, value) => {
    setBudgetTableData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          if (columnId === "costCenter") {
            const oldValue = old[rowIndex][columnId];
            const newValue = value;
            let changedCostCenter = null;

            if (!isEqual(oldValue, newValue)) {
              const oldValueKeys = Object.keys(oldValue);
              const newValueKeys = Object.keys(newValue);

              for (let i = 0; i < newValueKeys.length; i += 1) {
                const newValueKey = newValueKeys[i];
                if (oldValueKeys.includes(newValueKey)) {
                  if (newValue[newValueKey] !== oldValue[newValueKey]) {
                    changedCostCenter = newValue[newValueKey];
                    break;
                  }
                } else {
                  changedCostCenter = newValue[newValueKey];
                  break;
                }
              }
            }

            if (changedCostCenter) {
              const uniquePrefix = getUniquePrefix(old[rowIndex]);
              const uniqueCostCenterId = uniquePrefix + changedCostCenter.value;

              if (uniqueLineItemIds.includes(uniqueCostCenterId)) {
                showToast(
                  "This cost center is already selected in another line item with same account and category",
                  "info"
                );
                return old[rowIndex];
              }

              setUniqueLineItemIds((prevState) => [...prevState, uniqueCostCenterId]);
            }

            const updatedRow = {
              ...old[rowIndex],
              [columnId]: value,
            };

            return updatedRow;
          }

          if (columnId === "account" || columnId === "budgetCategory") {
            const updatedRow = {
              ...old[rowIndex],
              [columnId]: value,
            };

            const uniquePrefix = getUniquePrefix(updatedRow);
            const costCenterValues = Object.values(updatedRow.costCenter);
            const costCenterValue = costCenterValues.filter((v) => v);

            if (costCenterValue.length) {
              const costCenterId = costCenterValue[0]?.value;
              const uniqueId = uniquePrefix + costCenterId;

              if (uniqueLineItemIds.includes(uniqueId)) {
                showToast(
                  `This ${startCase(columnId)} is already selected in another line item`,
                  "info"
                );

                return old[rowIndex];
              }

              const oldPrefix = getUniquePrefix(old[rowIndex]);
              const oldUniqueId = oldPrefix + costCenterId;
              setUniqueLineItemIds((prevIds) => prevIds.filter((prevId) => prevId !== oldUniqueId));
              setUniqueLineItemIds((prevIds) => [...prevIds, uniqueId]);
            }

            return updatedRow;
          }

          let updatedColumn = {};

          if (old[rowIndex][columnId]) {
            updatedColumn = {
              ...old[rowIndex][columnId],
              value,
            };
          } else {
            updatedColumn = {
              value,
            };
          }

          const updatedRow = {
            ...old[rowIndex],
            [columnId]: updatedColumn,
          };

          const total = calculateRowTotal({
            row: updatedRow,
            interval: state.interval?.value,
          });

          updatedRow.total = total;

          return updatedRow;
        }
        return row;
      })
    );

    if (id && !["account", "budgetCategory", "costCenter"].includes(columnId)) {
      const columnData = budgetTableData[rowIndex][columnId];
      await updatePeriodicBudgetMutation.mutateAsync({
        id: columnData.id,
        data: {
          amount: Number(value),
        },
      });
    }
  };

  const onUploadFile = (event) => {
    const file = event.target.files[0];

    const toastId = toast.loading("Importing Budget data...");

    importMutation.mutate(
      { file },
      {
        onError: () => {
          toast.error("Could not import the budget data. Try again!", {
            id: toastId,
          });
        },
        onSuccess: (response) => {
          const { tableData, uniqueLineItemIds: uniqueIds } = prepareTableData({
            periodicBudgets: response.data,
            interval: state.interval?.value,
          });
          dispatch(setFormValue("isImported", true));
          setBudgetTableData(tableData);
          setUniqueLineItemIds(uniqueIds);
          toast.success("Budget data imported successfully!", {
            id: toastId,
          });
        },
      }
    );
  };

  const reset = () => {
    const tableData = getBudgetTableData();
    setBudgetTableData(tableData);
    dispatch(setFormValue("isImported", false));
  };

  const addRow = () => {
    const newRow = {
      account: null,
      budgetCategory: null,
      costCenter: {}
    };

    setBudgetTableData((tableData) => ([
      ...tableData,
      newRow
    ]));
  };

  if (id && isLoadingBudget) {
    return <Loader />;
  }

  const renderBudgetTable = () => {
    if (isLoadingPeriods || isLoadingBudget) {
      return <TableSkeleton />;
    }

    if (!periods?.data) return null;

    return (
      <BudgetTable
        data={budgetTableData}
        columns={columns}
        setData={setData}
        interval={state.interval}
        isEditing={Boolean(id)}
      />
    );
  };

  return (
    <BoxedContent className="gl-account-form">
      <Header
        showBreadcrumb
        leftContent={<h1>{`${id ? "Edit" : "Create"} Budget`}</h1>}
        rightContent={
          !id &&
          periods?.data && (
            <div className="buttons-container buttons-at-end">
              <input
                type="file"
                ref={fileInputRef}
                onChange={onUploadFile}
                onClick={(event) => {
                  event.target.value = null;
                }}
                style={{ display: "none" }}
                accept=".xlsx"
              />
              {state.isImported && (
                <Button
                  small
                  onClick={reset}
                  className="btn-with-icon"
                  bordered
                  testId="Budget-Reset-Button"
                >
                  Reset
                </Button>
              )}
              <Button
                small
                onClick={() => fileInputRef.current.click()}
                className="btn-with-icon"
                bordered
                testId="Budget-Import-Button"
              >
                <Button.Prepend>
                  <SvgIcon icon="upload-cloud-stroke-icon" />
                </Button.Prepend>
                Import
              </Button>
            </div>
          )
        }
      />
      <Form
        onSubmit={handleSubmit}
        key="company-form"
        className="dynamic-object-form"
        shouldScrollOnError
      >
        <div className="row">
          <div className="col-md-3">
            <FormControl>
              <Label htmlFor="name" label="Name*" />
              <Input
                name="name"
                placeholder={budgetInputPlaceholder}
                value={state.name}
                onChange={(value) => dispatch(setFormValue("name", value))}
                rules="required"
                messages={validationMessages.required}
                disabled={Boolean(id)}
                testId="Budget-Name-Input"
              />
            </FormControl>
          </div>
          <div className="col-md-3">
            <FormControl>
              <Label htmlFor="accountPeriod" label="Accounting Period*" />
              <Select
                id="accountPeriod"
                name="accountPeriod"
                queryKey="accounting-period"
                value={state.accountPeriod}
                onChange={(value) => dispatch(setFormValue("accountPeriod", value))}
                optionsLoader={getAccountingPeriods}
                dataAccessKey="data"
                optionLabelKey="name"
                optionValueKey="id"
                isClearable
                backspaceRemovesValue
                rules="required"
                messages={validationMessages.required}
                isDisabled={Boolean(id)}
                testId="Budget-AccountPeriod-Select"
              />
            </FormControl>
          </div>
          <div className="col-md-3">
            <FormControl>
              <Label htmlFor="interval" label="Interval*" />
              <ReactSelect
                name="interval"
                placeholder="Monthly"
                options={intervalOptions}
                value={state.interval}
                onChange={(value) => dispatch(setFormValue("interval", value))}
                isClearable
                backspaceRemovesValue
                rules="required"
                messages={validationMessages.required}
                isDisabled={Boolean(id)}
                testId="Budget-Interval-Select"
              />
            </FormControl>
          </div>
          <div className="col-md-3 d-flex justify-content-end align-items-center">
            {!periods?.data && (
              <Button
                primary
                small
                onClick={() => submitButtonRef.current.click()}
                testId="Budget-Create-Button"
              >
                Create
              </Button>
            )}
            {periods?.data && !id && (
              <Button primary small onClick={addRow} testId="Budget-Add-Row-Button">
                Add row
              </Button>
            )}
          </div>
        </div>

        <button type="submit" style={{ display: "none" }} ref={submitButtonRef}>
          Save
        </button>
      </Form>

      {renderBudgetTable()}

      {Boolean(periods?.data) && (
        <div className="buttons-at-end mt-4">
          <Button bordered small onClick={handleCancel} testId="Budget-Cancel-Button">
            Cancel
          </Button>
          <Button
            small
            className="save-btn"
            loading={saveMutation.isLoading || updateBudgetMutation.isLoading}
            onClick={handleSave}
            testId="Budget-Submit-Button"
          >
            Save
          </Button>
        </div>
      )}
    </BoxedContent>
  );
}
