import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatDistance } from "date-fns";
import { kebabCase, startCase } from "lodash";

import { formatDate } from "@/utils/helpers";
import { StatusCircle, Status, Tooltip } from "@/components/common";
import { statusColorMap } from "@/utils/maps/statusColorMap";
import { selectActiveApp } from "@/store/appSlice";
import { CustomActionDropdown } from "@/components/dynamic";
import { handleDrawer } from "@/utils/modal/helpers";

export default function ActionCell({ row }) {
  const navigate = useNavigate();
  const activeApp = useSelector(selectActiveApp);
  const actions = [
    {
      title: "View",
      onClick: () => {
        navigate(
          `/${kebabCase(activeApp?.value)}/tasks/${row.original.id}?workflowId=${
            row.original.workflowRunId
          }`
        );
      },
      icon: "eye",
    },
  ];
  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={actions}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId="Task-View-Button"
      />
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
};

function StatusCell({ value }) {
  return (
    <Status
      baseClassName="status-cell"
      status={startCase(value)}
      bgColor={statusColorMap.get(value?.toLowerCase())}
    >
      <StatusCircle color={statusColorMap.get(value?.toLowerCase())} />
    </Status>
  );
}

StatusCell.propTypes = {
  value: PropTypes.string.isRequired,
};

function DateCell({ value }) {
  const dateText = formatDistance(new Date(`${value}Z`), new Date());
  const formattedDate = formatDate(new Date(value));

  return (
    <div className="date-cell">
      <Tooltip activator={<span>{`${dateText} ago`}</span>} side="right">
        {formattedDate}
      </Tooltip>
    </div>
  );
}

DateCell.propTypes = {
  value: PropTypes.string.isRequired,
};

function WorkflowNameCell({ value }) {
  return <div className="text-cell">{startCase(value)}</div>;
}

WorkflowNameCell.propTypes = {
  value: PropTypes.string.isRequired,
};

function RecordCell({ row }) {
  const recordId = row.original.recordId || row.original.preRecordId;
  const objectName = row.original.objectName || row.original.preObjectName;
  const recordText =
    row.original.recordNumber || row.original.preRecordNumber || startCase(objectName);

  return (
    <Link to={handleDrawer(recordId, null, false, "dynamicObjectDrawers")}>
      <div className="truncated-text-cell">
        <a>{recordText}</a>
      </div>
    </Link>
  );
}

function AdditionalFieldCell({ row }) {
  if (row.original?.document === null) {
    return null;
  }
  const additionalFields = Object.values(row?.original?.document);

  return (
    additionalFields &&
    additionalFields?.map((doc, index) => (
      <Link to={handleDrawer(doc.id, null, false, "dynamicObjectDrawers")} key={doc.id}>
        <div className="truncated-text-cell">
          <a>{doc.name}</a>
          {index < additionalFields.length - 1 && ", "}
        </div>
      </Link>
    ))
  );
}

function InitiatedRecordCell({ row }) {
  const recordId = row.original.initiatedRecordId;
  const objectName = row.original.initiatedObjectName;
  const recordText = row.original.initiatedRecordNumber || startCase(objectName);

  return (
    <Link to={handleDrawer(recordId, null, false, "dynamicObjectDrawers")}>
      <div className="truncated-text-cell">
        <a>{recordText}</a>
      </div>
    </Link>
  );
}

function TaskNameCell({ row, value }) {
  const { taskType } = row.original;
  const taskText = value.startsWith(taskType) ? value : `${taskType} ${value}`;

  return (
    <div className="text-cell">{taskText}</div>
  );
}

export const getTaskTableColumns = () => [
  {
    Header: "Task Name",
    accessor: "currentStep",
    defaultCanSort: true,
    filter: "text",
    collapse: true,
    Cell: TaskNameCell,
  },
  // {
  //   Header: "Type",
  //   accessor: "taskType",
  // },
  // {
  //   Header: "Assigned To",
  //   accessor: "role",
  // },
  {
    Header: "Additional Fields",
    accessor: "additional Fields",
    Cell: AdditionalFieldCell,
  },
  // {
  //   Header: "Status",
  //   accessor: "status",
  //   Cell: StatusCell,
  // },
  {
    Header: "Workflow",
    accessor: "workflowName",
    Cell: WorkflowNameCell,
  },
  {
    Header: "Task for",
    accessor: "recordId",
    Cell: RecordCell,
  },
  {
    Header: "Started for",
    accessor: "initiatedRecordId",
    Cell: InitiatedRecordCell,
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    Cell: DateCell,
    defaultCanSort: true,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionCell,
    collapse: true,
  },
];

export const getTaskTableData = (data) => data;
