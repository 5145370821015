import qs from "qs";
import humps from "humps";
import request from "@/utils/api/helpers";

const baseUrl = "/finance/journal-entries";

export const uploadGLEntryExcelFile = async (data) => {
  const { file, companyId } = data;

  const formData = new FormData();
  formData.append("File", file, file.name);
  formData.append("CompanyId", companyId);

  return request({
    url: `${baseUrl}/project`,
    method: "POST",
    headers: {
      "Content-type": "multipart/form-data",
    },
    data: formData,
  });
};

export const getJournalEntryNumber = () => request({
  url: `${baseUrl}/last-id`
});

export const getJournalEntryLists = (params = {}) => {
  const queryParams = qs.stringify(humps.camelizeKeys(params));

  return request({
    url: `${baseUrl}/paginated?${queryParams}`
  });
};

export const JournalEntryById = (id) => request({
  url: `${baseUrl}/${id}/transfers`
});

export const getJournalEntryById = (id) => request({
  url: `${baseUrl}/${id}/transfers`
});

export const deleteJournalEntryDetailById = (id) => request({
  url: `${baseUrl}/${id}`,
  method: "DELETE"
});

export const getJournalEntryDetailById = (id) => request({
  url: `${baseUrl}/${id}`
});
