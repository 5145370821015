import { Tab } from "@hydra/atom/components";
import { statusColorMap } from "@/utils/maps/statusColorMap";

export default function TabWithStatus({
  tabs, onClick, className, activeTabIndex
}) {
  const addBorderToParent = (value, color) => {
    if (value) {
      const parent = document.querySelector(`.tab-status-${value.toLowerCase()}`).parentNode;
      parent.style.borderBottom = `1px solid ${color}`;
    }
  };

  return (
    <Tab
      className={className}
      tabs={tabs.map((tab, index) => (
        <div
          onMouseEnter={() => addBorderToParent(tab?.value?.toLowerCase(),
            statusColorMap.get(tab?.value?.toLowerCase()))}
          onMouseLeave={() => addBorderToParent(tab?.value?.toLowerCase(), "rgb(247 144 9 / 0%)")}
          key={`tab-${index}`}
          className={`tabs-badge-container tab-status-${tab.value?.toLowerCase()}`}
        >
          <div>{tab.label}</div>
        </div>
      ))}
      activeTabIndex={activeTabIndex}
      onClick={onClick}
    />
  );
}
