import { getVacancyAgingRows, getVacancyAgingColumns } from "./vacancy-aging";
import { getVacancyAging } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportAging({ activeCompany }) {
  return (
    <DynamicReport
      reportName="vacancy-aging"
      activeCompany={activeCompany}
      columnsDataProvider={getVacancyAgingColumns}
      rowsDataProvider={getVacancyAgingRows}
      fetchData={getVacancyAging}
      exportUrl="reports/export-query-report/vacancy-aging"
      filtersInitialState={{}}
      orderBy=""
      isPaginated={false}
      showDateRangeFilter={false}
    />
  );
}
