import { getUtilityContractRows, getUtilityContractColumns } from "./utility-contract";
import { getUtilityContractReport } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const utilityContractFilters = {
  TenantName: "*",
  ContractNumber: "*",
  PaymentType: "*"
};

const utilityContractFilterOptions = [
  { key: "tenantName", apiKey: "TenantName", keyType: "label" },
  { key: "paymentType", apiKey: "PaymentType" },
  { key: "paymentType", apiKey: "PaymentType" }
];

export default function ReportUtilityContract({ activeCompany }) {
  return (
    <DynamicReport
      reportName="utility-contract"
      activeCompany={activeCompany}
      columnsDataProvider={getUtilityContractColumns}
      rowsDataProvider={getUtilityContractRows}
      fetchData={getUtilityContractReport}
      exportUrl="reports/export-query-report/utility-contract"
      filtersInitialState={utilityContractFilters}
      filterOptions={utilityContractFilterOptions}
      orderBy=""
      isPaginated={false}
    />
  );
}
