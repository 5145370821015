import { useState, useEffect } from "react";

import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { useCompanyAccount } from "@/hooks";

function EmployeeForm() {
  const [initialState, setInitialState] = useState({});
  const defaultAccounts = useCompanyAccount({
    params: {
      accountType: "Employee Expense",
      includeCompanyId: false,
      isLinkedWithRecord: false,
    },
    accountMap: {
      employeeExpense: "expenseAccount"
    },
    format: "AccountMap"
  });

  useEffect(() => {
    if (defaultAccounts && !initialState.areAccountsSelected) {
      const { employeeExpense } = defaultAccounts;

      setInitialState((prevState) => ({
        ...prevState,
        areAccountsSelected: true,
        ...employeeExpense
      }));
    }
  }, [defaultAccounts]);

  return (
    <BoxedContent>
      <DynamicFormContainer
        initialData={initialState}
        objectName={dynamicObjectMap.get("EmployeeObjectName")}
        showHeader
        showLinkedViews
      />
    </BoxedContent>
  );
}

export default EmployeeForm;
