import { useEffect, useState, } from "react";
import { Loader } from "@hydra/atom/components";
import PropTypes from "prop-types";
import { useSearchParams, useParams } from "react-router-dom";
import { kebabCase } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { addDays } from "date-fns";
import { getAttachmentUrl, formatApiPayloadDate } from "@/utils/helpers";
import {
  SimpleSlider,
  ReportTable,
  ReportCard,
  TabWithBadge,
} from "@/components/property";
import { DynamicObjectActivityDrawer } from "@/components/dynamic";
import {
  BoxedContent,
  Header,
  Accordion,
  SvgIcon,
} from "@/components/common";
import building from "@/assets/images/building.png";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecordById, getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import DynamicObjectOverview from "@/pages/dynamic/list-views/DynamicObjectOverview";
import { getStatusCount } from "@/utils/leasing/helpers";
import DynamicObjectDetails from "@/pages/dynamic/detail-views/DynamicObjectDetails";

const contractTabs = [
  {
    label: "All",
    value: undefined,
    fieldName: "status",
  },
  {
    label: "Active Contracts",
    value: "Open",
    fieldName: "status",
  },
  {
    label: "Pending Notice",
    value: "PendingNotice",
    multiple: true,
    filters: [
      {
        fieldName: "agreementEndDate[gte]",
        value: formatApiPayloadDate(new Date()),
      },
      {
        fieldName: "agreementEndDate[lte]",
        value: formatApiPayloadDate(addDays(new Date(), 100)),
      },
      {
        fieldName: "status",
        value: "Open",
      },
    ],
  },
  {
    label: "Pending Renewal",
    value: "RenewalPending",
    fieldName: "status",
  },
  {
    label: "Terminated Contracts",
    value: "Expired",
    fieldName: "status",
  },
];

function HeaderLeftContent({ title, subtitle, icon }) {
  return (
    <div className="header-left-content">
      {icon && (
        <div className="icon-container">
          <SvgIcon icon={icon} />
        </div>
      )}
      <div>
        <h1 className="tenant-title">{title}</h1>
        {subtitle && <p className="sub-title">{subtitle}</p>}
      </div>
    </div>
  );
}

function CustomSlide(img) {
  return (
    <div>
      <img loading="lazy" src={img} alt="building" style={{ width: "100%" }} />
    </div>
  );
}

function DetailItem({ heading, children }) {
  return (
    <div className="row">
      <div className="col-12">
        <h6 className="headline">{heading}</h6>
        {children}
      </div>
    </div>
  );
}

DetailItem.propTypes = {
  heading: PropTypes.string,
};

DetailItem.defaultProps = {
  heading: "",
};

const perPageOptions = [
  { label: "12", value: 12 },
  { label: "24", value: 24 },
  { label: "36", value: 36 },
];

function retrieveUniqueTenants(objects) {
  const uniqueTenants = {};
  objects.forEach((obj) => {
    if (obj.tenant && obj.tenant.id) {
      uniqueTenants[obj.tenant.id] = obj.tenant;
    }
  });
  return Object.values(uniqueTenants);
}
function BuildingDetails() {
  const [currentPage, setCurrentPage] = useState(1);
  const [, setSearchParams] = useSearchParams();
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [openFiltersDrawer, setOpenActivityDrawer] = useState(false);
  const [activeTab, setStatusTab] = useState("Overview");
  const { buildingId } = useParams();
  const [tenantData, setTenantData] = useState([]);
  const { data: unitList, isLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("UnitObjectName")),
      "Unit",
      currentPage,
      perPage.value,
      buildingId,
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("UnitObjectName"), {
        takePage: currentPage,
        limitPage: perPage.value,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
        building: buildingId,
      })
  );

  const { data: contractData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ContractObjectName")), "Contract", buildingId],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("ContractObjectName"), {
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
        building: buildingId,
        status: "Active",
      })
  );

  const { data: contractItems } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("ContractObjectName")),
      "Contract",
      currentPage,
      perPage.value,
      buildingId,
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("ContractObjectName"), {
        takePage: currentPage,
        limitPage: perPage.value,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
        building: buildingId,
      })
  );

  useEffect(() => {
    if (contractItems?.data.length > 0) {
      const uniqueTenants = retrieveUniqueTenants(contractItems?.data);
      setTenantData(uniqueTenants);
    }
  }, [contractItems]);

  const tabs = [
    { label: "Overview" },
    { label: "Units", badgeContent: `${unitList?.totalCount || 0}` },
    { label: "Landlords" },
    { label: "Tenants", badgeContent: `${tenantData?.length || 0}` },
    { label: "Contracts" },
  ];

  const { data: buildingData, isInitialLoading } = useQuery(
    [kebabCase(dynamicObjectMap.get("BuildingObjectName")), buildingId],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("BuildingObjectName"), buildingId),
    {
      enabled: Boolean(buildingId),
    }
  );

  if (isInitialLoading) {
    return <Loader />;
  }

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
    setSearchParams(`?page=${pageNumber}&pageSize=${pageSize.label}`);
  };

  const handleStatusTabChange = (index) => {
    setStatusTab(tabs[index].label);
  };

  const { occupied: occupiedCount, vacant: vacantCount } = getStatusCount(unitList?.data || []);
  // Note: Use Tab component for this. Make TabWithBadge accept children.
  const renderView = () => {
    switch (activeTab) {
      case "Overview":
        return (
          <div className="accordion-container">
            <Accordion title="Building Details">
              <DynamicObjectDetails objectName="Building" id={buildingId} showHeader={false} />
            </Accordion>
          </div>
        );
      case "Landlords":
        return (
          <DynamicObjectOverview
            objectName={dynamicObjectMap.get("LandlordObjectName")}
            showActionCell={false}
            showBreadcrumb={false}
            showActionButton={false}
            filters={{
              building: buildingId,
            }}
          />
        );
      case "Tenants":
        return (
          <ReportTable
            data={tenantData.length > 0 ? tenantData : []}
            headerLeft={<HeaderLeftContent title="Tenants" />}
            perPage={perPage}
            perPageOptions={perPageOptions}
            currentPage={currentPage}
            handlePagination={handlePagination}
            totalCount={1}
            isLoading={isLoading}
            activeStatusTab={activeTab}
            objectName="tenant"
          />
        );
      case "Contracts":
        return (
          <DynamicObjectOverview
            objectName={dynamicObjectMap.get("ContractObjectName")}
            tabs={contractTabs}
            showActionCell={false}
            showBreadcrumb={false}
            showActionButton={false}
            filters={{
              building: buildingId,
            }}
          />
        );
      case "Units":
        return (
          <DynamicObjectOverview
            objectName={dynamicObjectMap.get("UnitObjectName")}
            showActionCell={false}
            showBreadcrumb={false}
            showActionButton={false}
            noDataHideButton
            filters={{
              building: buildingId,
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <BoxedContent className="dashboard">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header
            className="report-dashboard-header"
            showBreadcrumb
            leftContent={(
              <div>
                <h1>{`${buildingData.name} (${buildingData.number})`}</h1>
              </div>
            )}
          />
          <div className="report-container">
            <div className="report-img-container">
              <SimpleSlider
                items={buildingData?.photos?.length > 0 ? buildingData.photos.map(
                  (img) => CustomSlide(getAttachmentUrl(img))) :
                  [CustomSlide(building), CustomSlide(building), CustomSlide(building)]}
              />
            </div>
            <div className="cards-container">
              <div className="card-container">
                <ReportCard
                  data={[
                    {
                      titleValue: "0",
                      titleColor: "success",
                      subtitle: "Total Income (AED)",
                      subtitleColor: "primary",
                    },
                    {
                      titleValue: "0",
                      titleColor: "error",
                      subtitle: "Total Expense (AED)",
                      subtitleColor: "primary",
                      positive: true,
                    },
                    {
                      titleValue: `${contractData?.totalCount || 0}`,
                      titleColor: "primary",
                      subtitle: "Active Contracts",
                      subtitleColor: "primary",
                      positive: false,
                    },
                  ]}
                />
              </div>
              <div className="card-container">
                <ReportCard
                  data={[
                    {
                      titleValue: `${unitList?.totalCount || 0}`,
                      titleColor: "primary",
                      subtitle: "Total Units",
                      subtitleColor: "primary",
                    },
                    {
                      titleValue: `${occupiedCount}`,
                      titleColor: "primary",
                      subtitle: "Occupied Units",
                      subtitleColor: "primary",
                      positive: true,
                    },
                    {
                      titleValue: `${vacantCount}`,
                      titleColor: "primary",
                      subtitle: "Vacant Units",
                      subtitleColor: "primary",
                      positive: false,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <TabWithBadge tabs={tabs} className="primary-tabs custom-primary-tabs" onClick={handleStatusTabChange} />
          {renderView()}
        </>
      )}
      <DynamicObjectActivityDrawer isOpen={openFiltersDrawer} onClose={setOpenActivityDrawer} />
    </BoxedContent>
  );
}

BuildingDetails.propTypes = {};

export default BuildingDetails;
