import PropTypes from "prop-types";

export default function ModalFooter({ children, bordered }) {

  return (
    <footer className={`modal-footer ${bordered ? "bordered" : ""}`}>
      {children}
    </footer>
  );
}

ModalFooter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  bordered: PropTypes.bool
};

ModalFooter.defaultProps = {
  bordered: true
};
