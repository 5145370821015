import { Input } from "@hydra/atom/components";
import PropTypes from "prop-types";

function PrependInput({
  value,
  name,
  id,
  onChange,
  prependText,
  error,
  ...rest
}) {
  return (
    <div>
      <div className="input-prepend-item">
        <div className="input-prepend-text">
          <Input
            type="text"
            name={prependText}
            id={prependText}
            placeholder={prependText}
            disabled
          />
        </div>
        <div className="input-prepend-field">
          <Input
            value={value}
            onChange={onChange}
            name={id}
            id={id}
            placeholder={name}
            type="text"
            {...rest}
          />
        </div>
      </div>
      {typeof error === "string" && <span className="error-text">{error}</span>}
    </div>
  );
}

PrependInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

PrependInput.defaultProps = {
  value: "",
  onChange: () => {},
  name: "tuli-input-field",
  error: false,
};

PrependInput.Type = "HydraValidatableComponent";

export default PrependInput;
