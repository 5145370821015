import PropTypes from "prop-types";
import { camelize } from "humps";

import { FormControl, ReactSelect } from "@hydra/atom/components";

import FieldLabel from "@/components/dynamic/fields/FieldLabel";
import { colorStyles } from "@/utils/select/constants";
import { statusColorMap } from "@/utils/maps/statusColorMap";
import { statusFieldNames } from "@/utils/dynamic/constants";

const getParentOptionValues = (field, formState) => {
  let optionValues = [];

  if (!field) return optionValues;

  if (field?.selectFieldParent.length) {
    field.selectFieldParent.forEach(({ parentField, parentObjectField }) => {
      if (parentField && parentObjectField && formState) {
        const parentFieldValue = formState[camelize(parentField)];

        if (parentFieldValue) {
          const parentOptionValues = parentFieldValue[camelize(parentObjectField)];

          if (parentOptionValues && typeof parentOptionValues === "string") {
            try {
              const parsedValues = JSON.parse(parentOptionValues);
              if (Array.isArray(parsedValues)) {
                optionValues = parsedValues;
              }
            } catch (error) {
              optionValues.push(parentOptionValues);
            }
          } else if (typeof parentOptionValues === "object") {
            optionValues = parentOptionValues;
          }
        }
      }
    });
  }

  return optionValues;
};

// TODO: Filter options by role
// TODO: Filter next options while editing
const getFieldOptions = (field, formState, isEditing) => {
  const { stateMachine, options, camelizedName } = field;

  let fieldOptions = options;

  if (statusFieldNames.includes(camelizedName)) {
    fieldOptions = options.map((o) => ({
      ...o,
      color: statusColorMap.get(o.value.toLowerCase())
    }));
  }

  if (!isEditing && stateMachine && stateMachine?.startValue) {
    const startOptionValue = stateMachine.startValue;

    const startOption = fieldOptions.filter((o) => o.value === startOptionValue);

    return startOption;
  }

  if (field.selectFieldParent && field.selectFieldParent.length && formState) {
    const parentOptionValues = getParentOptionValues(field, formState);

    if (parentOptionValues && parentOptionValues.length) {
      const filterOptions = fieldOptions.filter((o) => parentOptionValues.includes(o.value));

      return filterOptions;
    }
  }

  return fieldOptions;
};

function SelectField({
  name,
  field,
  value,
  onChange,
  error,
  showLabel,
  isEditing,
  disabled,
  isChildField,
  relationalFieldState,
  formState,
  handleDispatch,
  ...rest
}) {
  const filterOption = (option) => {
    const selectedIds = relationalFieldState.map((item) => item[field.name]?.value);
    return !selectedIds.includes(option.value);
  };

  const getFilterProps = () => {
    if (isChildField && field.unique) {
      return {
        filterOption
      };
    }

    return {};
  };

  const getMenuStyles = () => {
    if (!field.name) {
      return {};
    }

    if (statusFieldNames.includes(camelize(field.name))) {
      return colorStyles;
    }

    return {};
  };

  return (
    <>
      <FormControl>
        {showLabel ? (
          <FieldLabel
            label={field.label}
            labelFor={field.name}
            helperText={field.helperText}
            isRequired={field.required}
          />
        ) : null}
        <ReactSelect
          id={name}
          value={value || null}
          onChange={onChange}
          placeholder={field.placeholder}
          options={getFieldOptions(field, formState, isEditing)}
          isSearchable
          isClearable={field.isClearable ?? true}
          backSpaceRemovesValue={field.isClearable ?? true}
          isMulti={field.allowMultiple ?? false}
          isDisabled={disabled}
          styles={getMenuStyles()}
          handleDispatch={handleDispatch}
          {...getFilterProps()}
          {...rest}
        />
      </FormControl>
      {typeof error === "string" && <span className="error-text">{error}</span>}
    </>
  );
}

SelectField.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.oneOf([null]),
  ]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showLabel: PropTypes.bool,
  isEditing: PropTypes.bool,
  isChildField: PropTypes.bool,
  relationalFieldState: PropTypes.array,
  disabled: PropTypes.bool,
  formState: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
};

SelectField.defaultProps = {
  value: null,
  onChange: () => {},
  name: "tuli-select-field",
  error: false,
  showLabel: true,
  isEditing: false,
  isChildField: false,
  disabled: false,
  relationalFieldState: [],
  formState: null,
};

SelectField.Type = "HydraValidatableComponent";

export default SelectField;
