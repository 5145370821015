import { estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getVacancyAgingColumns() {
  const colArr = [
    {
      label: "Property Name",
      value: true,
      columnId: "propertyName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Emirates",
      value: true,
      columnId: "emirates",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit Number",
      value: true,
      columnId: "unitNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit Category",
      value: true,
      columnId: "unitCategory",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit Type",
      value: true,
      columnId: "unitType",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit Status",
      value: true,
      columnId: "unitStatus",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "View",
      value: true,
      columnId: "view",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit Mark",
      value: true,
      columnId: "unitMark",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Area PerS quare Meter",
      value: true,
      columnId: "areaSqM",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Area Per Square Feet",
      value: true,
      columnId: "areaSqFt",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Bedrooms",
      value: true,
      columnId: "bedrooms",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "CMA Rate",
      value: true,
      columnId: "cMARate",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => String(formatCurrency(value) || "")
    },
    {
      label: "Contract Number",
      value: true,
      columnId: "contractNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Previous Contract Start Date",
      value: true,
      columnId: "previouStartDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Previous Contract End Date",
      value: true,
      columnId: "previousEndDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Previous Contract Amount",
      value: true,
      columnId: "previousContractAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => String(formatCurrency(value) || "")
    },
    {
      label: "Age (Days)",
      value: true,
      columnId: "ageDays",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Age (Years)",
      value: true,
      columnId: "ageYears",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Total Lost Amount",
      value: true,
      columnId: "totalLostAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => String(formatCurrency(value) || "")
    }
  ];

  return colArr;
}
