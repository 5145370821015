import { getStandardPurchaseColumns, getStandardPurchaseRows } from "./standard-purchase-statement";
import { getStandardRatedPurchase } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportStandardRatedPurchase({ activeCompany }) {
  return (
    <DynamicReport
      reportName="standard-rate-purchase"
      activeCompany={activeCompany}
      columnsDataProvider={getStandardPurchaseColumns}
      rowsDataProvider={getStandardPurchaseRows}
      fetchData={getStandardRatedPurchase}
      exportUrl="reports/export-query-report/standard-rate-purchase"
      filtersInitialState={{}}
      orderBy="InvoiceNumber"
    />
  );
}
