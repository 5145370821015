import PropTypes from "prop-types";

import { SvgIcon } from "@/components/common";
import { CustomActionDropdown } from "@/components/dynamic";

function FileCell({ value }) {
  return (
    <div className="">
      <div className="icon-file-container">
        <div className="icon-file-item">
          <SvgIcon icon="file-icon-2" />
        </div>
        <h1 className="icon-field-title">{value.value}</h1>
      </div>
    </div>
  );
}

function ActionCell({ remove, row }) {
  const actions = [
    {
      title: "View",
      onClick: () => {},
      icon: "eye"
    },
    {
      title: "Delete",
      onClick: () => remove(row.original),
      icon: "trash-icon"
    }
  ];

  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={actions}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
      )}
        testId="Jobs-Table-Action-Dropdown"
      />
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
};

export const getUploadDocumentsTablesTableColumns = () => [
  {
    Header: "Title",
    accessor: "title",
    Cell: FileCell,
  },
  {
    Header: "Document Type",
    accessor: "documentType"
  },
  {
    Header: "Entry Date",
    accessor: "entryDate",
  },
  {
    Header: "Action",
    Cell: ActionCell,
    collapse: true
  },
];

export const getUploadDocumentsTableData = (data = []) => data.map((item, index) => ({
  ...item,
  index,
  key: index,
}));
