import PropTypes from "prop-types";
import { getAttachmentUrl, getFileExtension } from "@/utils/helpers";
import PdfFileIcon from "@/assets/images/file-pdf-icon.png";

const renderAttachment = (attachment) => {
  const fileExtension = getFileExtension(attachment);

  if (fileExtension === "pdf") {
    return (
      <a href={getAttachmentUrl(attachment)} target="_blank">
        <img
          width={28}
          height={28}
          className="attachment"
          src={PdfFileIcon}
          alt="Attachment"
          loading="lazy"
        />
      </a>
    );
  }

  return (
    <a href={getAttachmentUrl(attachment)} target="_blank">
      <img
        width={50}
        height={50}
        className="attachment"
        src={getAttachmentUrl(attachment)}
        alt="Attachment"
        loading="lazy"
      />
    </a>
  );
};

function AttachmentsList({ attachments }) {
  if (!attachments) return <ul />;

  return (
    <ul className="attachments-list">
      {attachments.map((attachmentUrl) => (
        <li key={attachmentUrl}>
          <div className="attachment-container">
            {renderAttachment(attachmentUrl)}
          </div>
        </li>
      ))}
    </ul>
  );
}

AttachmentsList.propTypes = {
  attachments: PropTypes.array.isRequired,
};

export default AttachmentsList;
