import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "@hydra/atom/components";
import { isEmpty, kebabCase, sortBy } from "lodash";

import { camelize } from "humps";
import pluralize from "pluralize";
import {
  FormLeftHeader,
  CheckboxWithIcon,
} from "@/components/common";
import {
  getDynamicObjectRecords,
} from "@/api/dynamic/dynamicObjectNameApi";

const getAmenityHelperText = (amenity) => {
  const helperLabels = [];
  if (!isEmpty(amenity.block)) {
    helperLabels.push(amenity.block.name);
  }

  if (!isEmpty(amenity.floor)) {
    helperLabels.push(amenity.floor.name);
  }

  return helperLabels.join(" ");
};

const isAmenitySelected = (unitId, amenity) => {
  const amenityUnitIds = amenity.unit.map(({ id }) => id);

  return amenityUnitIds.includes(unitId);
};

function UnitAmenityForm({
  building, id, onChange, objectName
}) {
  const [amenities, setAmenities] = useState([]);

  const { data: buildingAmenities, isInitialLoading: isLoadingBuildingAmenities } = useQuery(
    [kebabCase(objectName), building?.value],
    () => getDynamicObjectRecords(objectName, {
      building: building?.value
    }),
    {
      enabled: Boolean(building?.value),
    }
  );

  useEffect(() => {
    if (buildingAmenities?.data) {
      const sortedAmenities = sortBy(buildingAmenities?.data, [(a) => a[camelize(objectName)]]);
      const formattedAmenities = sortedAmenities.map((amenity) => ({
        selected: id ? isAmenitySelected(id, amenity) : true,
        id: amenity.id,
        label: amenity[camelize(objectName)],
        helperText: getAmenityHelperText(amenity),
        unit: amenity.unit.map((unit) => unit.id)
      }));

      setAmenities(formattedAmenities);
      onChange(formattedAmenities);
    }

  }, [buildingAmenities]);

  const handleChange = (index, value) => {
    const changedAmenities = amenities.map((amenity, i) => {
      if (index === i) {
        let amenityUnitIds = amenity.unit;

        if (id && !value) {
          amenityUnitIds = amenityUnitIds.filter((amenityUnitId) => amenityUnitId !== id);
        }
        return {
          ...amenity,
          selected: value,
          unit: amenityUnitIds
        };
      }

      return amenity;
    });

    setAmenities(changedAmenities);
    onChange(changedAmenities);
  };

  if (isLoadingBuildingAmenities) {
    return <Loader />;
  }

  if (!building || !buildingAmenities || !buildingAmenities?.data?.length) return null;

  return (
    <div className="row">
      <div className="col-md-3">
        <FormLeftHeader title={`Building ${pluralize(objectName)}`} icon="disc-icon" />
      </div>

      <div className="col-md-9">
        <div className="row">
          {
            amenities.map((amenity, index) => (
              <div className="col-md-4" key={`amenity-${index}`}>
                <CheckboxWithIcon
                  id={kebabCase(amenity.label)}
                  name={kebabCase(amenity.label)}
                  label={amenity.label}
                  value={amenity.selected}
                  helperText={amenity.helperText}
                  onChange={() => handleChange(index, !amenity.selected)}
                />
              </div>
            ))
          }
        </div>
      </div>

      <hr className="full-hr" />
    </div>
  );
}

UnitAmenityForm.propTypes = {
  objectName: PropTypes.string.isRequired,
  building: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  id: PropTypes.string,
  onChange: PropTypes.func,
};

UnitAmenityForm.defaultProps = {
  id: "",
  onChange: () => {},
  building: null,
};

export default UnitAmenityForm;
