import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  StatusCircle,
  Status,
} from "@/components/common";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { statusColorMap } from "@/utils/maps/statusColorMap";
import { handleDrawer } from "@/utils/modal/helpers";
import { CustomActionDropdown } from "@/components/dynamic";
import { checkPermission } from "@/store/userSlice";
import {
  formatDate
} from "@/utils/helpers";

function StatusCell({ value }) {
  return (
    <Status
      baseClassName="status-cell"
      status={value.value}
      bgColor={statusColorMap.get(value.value.toLowerCase())}
    >
      <StatusCircle color={statusColorMap.get(value.value.toLowerCase())} />
    </Status>
  );
}

StatusCell.propTypes = {
  value: PropTypes.object.isRequired,
};

function LinkCell({ value }) {
  return (
    <div className="link-cell">
      <Link
        to={`/work-order/details/${value?.value}`}
      >
        {value?.label}
      </Link>
    </div>
  );
}

LinkCell.propTypes = {
  value: PropTypes.object.isRequired,
};

function ActionCell({ toggleAlertDialogue, editWorkOrder, row }) {
  const filteredActions = () => {
    const actions = [
      {
        title: "Delete",
        onClick: () => toggleAlertDialogue(row.original.key),
        icon: "trash-icon"
      },
      {
        title: "Edit",
        onClick: () => { editWorkOrder(row.original.key); },
        icon: "edit-icon"
      },
    ];
    return actions.filter((item) => checkPermission("Model", dynamicObjectMap.get("WorkOrderObjectName"), item.title));
  };

  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={filteredActions()}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId="Work-Order-Table-Action-Dropdown"
      />
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
  toggleAlertDialogue: PropTypes.func.isRequired,
  editWorkOrder: PropTypes.func.isRequired,
};

function CreatedAtDateCell({ value }) {
  return (
    <div className="date-cell">{formatDate(new Date(value))}</div>
  );
}

CreatedAtDateCell.propTypes = {
  value: PropTypes.string.isRequired,
};
function LookupCell({ value }) {
  if (!value) return <div />;

  return value?.value ? (
    <div className="link-cell">
      <Link
        to={handleDrawer(
          value.value,
          null,
          false,
          "dynamicObjectDrawers"
        )}
      >
        <TruncatedTextCell value={value.label} />
      </Link>
    </div>
  ) : (
    <TruncatedTextCell value={value.label} />
  );
}
LookupCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

LookupCell.defaultProps = {
  value: "",
};
export const getOverviewTableColumns = () => [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Tenant",
    accessor: "tenant",
    Cell: LookupCell,
  },
  {
    Header: "Unit",
    accessor: "unit",
    Cell: LookupCell,
  },
  {
    Header: "Main Maintenance",
    accessor: "category",
    Cell: LookupCell,
  },
  {
    Header: "subCategory",
    accessor: "subCategory",
    Cell: LookupCell,

  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusCell,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionCell,
    collapse: true,
  },
];
function TruncatedTextCell({ value }) {
  return (
    <div className="truncated-text-cell">
      <p>{value}</p>
    </div>
  );
}
TruncatedTextCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const getOverviewTableData = (data) => data?.map((workOrder) => ({
  key: workOrder.id,
  number: workOrder.number,
  title: workOrder.title,
  maintenanceDescription: workOrder.description,
  type: {
    value:
        workOrder?.type?.toLowerCase() === "reactive" ?
          "Corrective" :
          "Preventive",
    color:
        workOrder?.type?.toLowerCase() === "reactive" ? "#7c4dff" : "#FF7600",
  },
  status: {
    value: workOrder?.workOrderStatus?.label,
    color: workOrder?.workOrderStatus?.color,
  },
  subCategory: {
    value: workOrder?.subCategory?.value,
    label: workOrder?.subCategory?.label,
    objectName: "SubCategory"
  },
  category: {
    value: workOrder?.category?.value,
    label: workOrder?.category?.label,
    objectName: "Category"

  },
  unit: {
    value: workOrder?.unit?.value,
    label: workOrder?.unit?.label,
    objectName: "Unit"
  },
  tenant: {
    value: workOrder?.tenant?.value,
    label: workOrder?.tenant?.label,
    objectName: "Tenant"
  },
  // createdAt: workOrder?.createdAt,
  action: "",
}));
