import { toast } from "react-hot-toast";
import { CustomToast } from "@/components/common";
import { toastStyles } from "./constants";

const getToastStyles = (type) => {
  switch (type) {
    case "success":
      return toastStyles.success;

    case "info":
      return toastStyles.info;

    case "error":
      return toastStyles.error;

    default:
      return toastStyles.error;
  }
};

export default function showToast(message, type = "success" || "error" || "info") {
  toast((t) => (
    <CustomToast type={type} message={message} toastId={t.id} />
  ), {
    duration: 4000,
    style: getToastStyles(type)
  });
}
