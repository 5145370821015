import {
  Button, FormControl, Radio, ReactSelect, Label, Form
} from "@hydra/atom/components";
import { components } from "react-select";
import PropTypes from "prop-types";
import { useMutation } from "@tanstack/react-query";
import {
  Header, FormLeftHeader, CustomizedDatePicker, Select, CustomSwitch
} from "@/components/common";
import { PrependInput } from "@/components/admin";
import { setFormValue, setNextStep } from "@/reducers/admin/companySettingsReducer.js";
import { nestedOptionStyles } from "@/utils/select/constants";
import { getFormattedLedgerAccounts } from "@/api/finance/ledgerAccountApi";
import { formatGLAccountOption } from "@/utils/finance/helpers";
import { updateCompanySettingFinancial } from "@/api/admin/companySettingApi";
import { updateCompanyAccount } from "@/api/admin/companyAccountApi";
import {
  getAccountsData, prepareData,
} from "@/utils/admin/helpers";
import showToast from "@/utils/toast/helpers";
import { getTestId } from "@/utils/helpers";
import { currencies, validationMessages } from "@/utils/admin/constants";

function Option(props) {
  const { data, testId } = props;
  return (
    <div data-testid={getTestId(`${testId}-Option`)}>
      <components.Option {...props}>
        <div className="react-select-option">
          <p>{data.label}</p>
          <p>{data.subtitle}</p>
        </div>
      </components.Option>
    </div>
  );
}

Option.propTypes = {
  data: PropTypes.object.isRequired,
};

function FinancialInformation({ state, dispatch }) {
  const leftContent = (title, subtitle) => (
    <div className="container-header-left-content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );

  const updateMutation = useMutation(
    ({ id: recordId, data: recordData }) => updateCompanySettingFinancial(recordId, recordData),
    {
      onError: () => {
        showToast("Could not update. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Updated successfully", "success");
        dispatch(setNextStep(3));
      },
    }
  );

  const updateCompanyAccountMutation = useMutation(
    ({ recordId, dataObject }) => updateCompanyAccount(recordId, dataObject),
    {
      onError: () => {
        showToast("Could not update. Try again!", "error");
      },
    }
  );

  const handleSubmit = () => {
    const accounts = getAccountsData(state);
    const formattedData = prepareData(state);
    accounts.forEach((account) => {
      updateCompanyAccountMutation.mutate({
        recordId: account.id,
        dataObject: account,
      });
    });

    updateMutation.mutate({
      id: state.companyId,
      data: {
        ...formattedData,
        $Type: "finance",
      },
    });
  };

  return (
    <section className="financial-setting-body">
      <Form onSubmit={handleSubmit} shouldScrollOnError>
        <div className="setting-header">
          <Header
            leftContent={leftContent(
              "Finance Information",
              "Get emails to find out what’s going on when you’re not online. You can turn them off anytime."
            )}
          />
          <hr className="full-hr" />
        </div>
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Tax Registration Number"
              subtitle="This will be displayed on your Company profile."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <PrependInput
                  value={state.taxRegistrationNumber}
                  onChange={(value) => dispatch(setFormValue("taxRegistrationNumber", value))}
                  name="Tax Registration Number"
                  id="taxRegistrationNumber"
                  prependText="TXNUM"
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="System Currency"
              subtitle="This will be displayed on your Company profile."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <ReactSelect
                  name="currency"
                  placeholder="Select currency"
                  options={currencies}
                  value={state.systemCurrency}
                  onChange={(value) => dispatch(setFormValue("systemCurrency", value))}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Local Currency"
              subtitle="This will be displayed on your Company profile."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <ReactSelect
                  name="currency"
                  placeholder="Select currency"
                  options={currencies}
                  value={state?.localCurrency}
                  onChange={(value) => dispatch(setFormValue("localCurrency", value))}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Do you have Standard Chart of Accounts System"
              subtitle="Select to specify if segments of information should be added to the standard account codes."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4">
                <div
                  className={state.chartAccountSystem ? "form-radio-card-primary" : "card"}
                  role="presentation"
                  onClick={() => dispatch(setFormValue("chartAccountSystem", true))}
                >
                  <Radio
                    className="radio-text"
                    label="Yes"
                    itemValue
                    type="radio"
                    value={state.chartAccountSystem}
                  >
                    <Radio.CheckIcon>
                      <span className="material-icons">circle</span>
                    </Radio.CheckIcon>
                  </Radio>
                  <h1 className="form-field-radio-text">We use a standard system</h1>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className={!state.chartAccountSystem ? "form-radio-card-primary" : "card"}
                  role="presentation"
                  onClick={() => dispatch(setFormValue("chartAccountSystem", false))}
                >
                  <Radio
                    className="radio-text"
                    label="No"
                    itemValue
                    type="radio"
                    value={!state.chartAccountSystem}
                    onChange={() => {}}
                  >
                    <Radio.CheckIcon>
                      <span className="material-icons">circle</span>
                    </Radio.CheckIcon>
                  </Radio>
                  <h1 className="form-field-radio-text">We don’t use standard system</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Fiscal Calendar Year"
              subtitle="This will be displayed on your Company profile."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4">
                <CustomizedDatePicker
                  name="startFiscalCalendarYear"
                  value={state.startFiscalCalendarYear}
                  onChange={(value) => dispatch(setFormValue("startFiscalCalendarYear", value || null))}
                />
              </div>
              <div className="col-md-4">
                <CustomizedDatePicker
                  name="endFiscalCalendarYear"
                  value={state.endFiscalCalendarYear}
                  onChange={(value) => dispatch(setFormValue("endFiscalCalendarYear", value))}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Customer Activity Alert Options"
              subtitle="This will be displayed on your Company profile."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4">
                <CustomSwitch
                  value={state.creditLimit}
                  onChange={() => dispatch(setFormValue("creditLimit", !state.creditLimit))}
                  title="Credit Limit"
                  subtitle="Save my login details for next time."
                />
                <CustomSwitch
                  value={state.commitmentLimit}
                  onChange={() => dispatch(setFormValue("commitmentLimit", !state.commitmentLimit))}
                  title="Commitment Limit"
                  subtitle="Save my login details for next time."
                />
                <CustomSwitch
                  value={state.reservation}
                  onChange={() => dispatch(setFormValue("reservation", !state.reservation))}
                  title="Reservation"
                  subtitle="Save my login details for next time."
                />
                <CustomSwitch
                  value={state.arInvoice}
                  onChange={() => dispatch(setFormValue("arInvoice", !state.arInvoice))}
                  title="AR Invoice"
                  subtitle="Save my login details for next time."
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="Default Tenant/Lead Account Settings:" subtitle="" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="customer-receivable" label="Customer Receivable A/C" />
                  <Select
                    id="customerReceivable"
                    name="customerReceivable"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.customerReceivable}
                    onChange={(value) => dispatch(setFormValue("customerReceivable", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="customer-advance" label="Customer Advance A/C" />
                  <Select
                    id="customerAdvance"
                    name="customerAdvance"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.customerAdvance}
                    onChange={(value) => dispatch(setFormValue("customerAdvance", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="security-deposit" label="Security Deposit A/C" />
                  <Select
                    id="securityDeposit"
                    name="securityDeposit"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.securityDeposit}
                    onChange={(value) => dispatch(setFormValue("securityDeposit", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="bounce-cheque-penalty" label="Bounce Cheque Penalty A/C" />
                  <Select
                    id="bounceChequePenalty"
                    name="bounceChequePenalty"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.bounceChequePenalty}
                    onChange={(value) => dispatch(setFormValue("bounceChequePenalty", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="contract-break-penalty" label="Contract Break Penalty A/C" />
                  <Select
                    id="contract-break-penalty"
                    name="contractBreakPenalty"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.contractBreakPenalty}
                    onChange={(value) => dispatch(setFormValue("contractBreakPenalty", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="damage-break-penalty" label="Damage Break Penalty A/C" />
                  <Select
                    id="damage-break-penalty"
                    name="damageBreakPenalty"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.damageBreakPenalty}
                    onChange={(value) => dispatch(setFormValue("damageBreakPenalty", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="Default Unit Account Settings:" subtitle="" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Label
                    htmlFor="unrealised-rental-revenue"
                    label="Unrealised Rental Revenue A/C"
                  />
                  <Select
                    id="unrealised-rental-revenue"
                    name="unrealisedRentalRevenue"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.unrealisedRentalRevenue}
                    onChange={(value) => dispatch(setFormValue("unrealisedRentalRevenue", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="rental-revenue" label="Rental Revenue A/C" />
                  <Select
                    id="rentalRevenue"
                    name="rentalRevenue"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.rentalRevenue}
                    onChange={(value) => {
                      dispatch(setFormValue("rentalRevenue", value));
                    }}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label
                    htmlFor="unrealised-utility-revenue"
                    label="Unrealised Utility Revenue A/C"
                  />
                  <Select
                    id="unrealised-utility-revenue"
                    name="unrealisedUtilityRevenue"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.unrealisedUtilityRevenue}
                    onChange={(value) => dispatch(setFormValue("unrealisedUtilityRevenue", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="utility-revenue" label="Utility Revenue A/C" />
                  <Select
                    id="utilityRevenue"
                    name="utilityRevenue"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.utilityRevenue}
                    onChange={(value) => {
                      dispatch(setFormValue("utilityRevenue", value));
                    }}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="maintenance-revenue" label="Maintenance Revenue A/C" />
                  <Select
                    id="maintenanceRevenue"
                    name="maintenanceRevenue"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.maintenanceRevenue}
                    onChange={(value) => {
                      dispatch(setFormValue("maintenanceRevenue", value));
                    }}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="Default PDC Account Settings:" subtitle="" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="pdc-on-hand" label="PDC On Hand A/C" />
                  <Select
                    id="pdc-on-hand"
                    name="pdcOnHand"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.pdcOnHand}
                    onChange={(value) => dispatch(setFormValue("pdcOnHand", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="pdc-contra" label="PDC Contra A/C" />
                  <Select
                    id="pdc-contra"
                    name="pdcContra"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.pdcContra}
                    onChange={(value) => dispatch(setFormValue("pdcContra", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="Default Reservation Account Settings:" subtitle="" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="reservation-deposit" label="Reservation Deposit A/C" />
                  <Select
                    id="reservation-deposit"
                    name="reservation-deposit"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.reservationDeposit}
                    onChange={(value) => dispatch(setFormValue("reservationDeposit", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="reservation-fee-income" label="Reservation Fee Income A/C" />
                  <Select
                    id="reservation-fee-income"
                    name="reservation-fee-income"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.reservationFeeIncome}
                    onChange={(value) => dispatch(setFormValue("reservationFeeIncome", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="Default Bounce Cheque Account Settings:" subtitle="" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="expense-account" label="Expense A/C" />
                  <Select
                    id="expense"
                    name="expense"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.expense}
                    onChange={(value) => dispatch(setFormValue("expense", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="Default Credit Note Account Settings:" subtitle="" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="customer-refund" label="Customer Refund A/C" />
                  <Select
                    id="customer-refund"
                    name="customerRefund"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.customerRefund}
                    onChange={(value) => dispatch(setFormValue("customerRefund", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="Default Fixed Asset Account Settings:" subtitle="" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="fixed-asset-clearing-account" label="Fixed Asset Clearing A/C" />
                  <Select
                    id="fixed-asset-clearing-account"
                    name="fixedAssetClearing"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.fixedAssetClearing}
                    onChange={(value) => dispatch(setFormValue("fixedAssetClearing", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label
                    htmlFor="fixed-asset-sale-clearing-account"
                    label="Fixed Asset Sale Clearing A/C"
                  />
                  <Select
                    id="fixed-asset-sale-clearing-account"
                    name="fixedAssetSakeClearing"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.fixedAssetSaleClearing}
                    onChange={(value) => dispatch(setFormValue("fixedAssetSaleClearing", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label
                    htmlFor="fixed-asset-revaluation-account"
                    label="Fixed Asset Revaluation A/C"
                  />
                  <Select
                    id="fixed-asset-revaluation-account"
                    name="fixedAssetRevaluation"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.fixedAssetRevaluation}
                    onChange={(value) => dispatch(setFormValue("fixedAssetRevaluation", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label
                    htmlFor="fixed-asset-surplus-revaluation-account"
                    label="Fixed Asset Surplus Revaluation A/C"
                  />
                  <Select
                    id="fixed-asset-surplus-revaluation-account"
                    name="fixedAssetSurplusRevaluation"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.fixedAssetSurplusRevaluation}
                    onChange={(value) =>
                      dispatch(setFormValue("fixedAssetSurplusRevaluation", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label
                    htmlFor="fixed-asset-reclassification-account"
                    label="Fixed Asset Reclassification A/C"
                  />
                  <Select
                    id="fixed-asset-reclassification-account"
                    name="fixedAssetReclassification"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.fixedAssetReclassification}
                    onChange={(value) =>
                      dispatch(setFormValue("fixedAssetReclassification", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label
                    htmlFor="fixed-asset-inter-component-transfer-account"
                    label="Fixed Asset Inter Component Transfer A/C"
                  />
                  <Select
                    id="fixed-asset-inter-component-transfer-account"
                    name="fixedAssetInterComponentTransfer"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.fixedAssetInterComponentTransfer}
                    onChange={(value) =>
                      dispatch(setFormValue("fixedAssetInterComponentTransfer", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label
                    htmlFor="fixed-asset-control-account"
                    label="Fixed Asset Surplus Revaluation A/C"
                  />
                  <Select
                    id="fixed-asset-control-account"
                    name="fixedAssetControl"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.fixedAssetControl}
                    onChange={(value) => dispatch(setFormValue("fixedAssetControl", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="Default Service Contract Account Settings:" subtitle="" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Label
                    htmlFor="service-contract-clearing"
                    label="Service Contract Clearing A/C"
                  />
                  <Select
                    id="service-contract-clearing"
                    name="serviceContractClearing"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.serviceContractClearing}
                    onChange={(value) => dispatch(setFormValue("serviceContractClearing", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="Default Employee Account Settings:" subtitle="" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Label
                    htmlFor="employee-expense-account"
                    label="Employee Expense A/C"
                  />
                  <Select
                    id="employee-expense-account"
                    name="employeeExpense"
                    queryKey="ledger-account"
                    placeholder="Select account"
                    value={state.employeeExpense}
                    onChange={(value) => dispatch(setFormValue("employeeExpense", value))}
                    optionsLoader={() => getFormattedLedgerAccounts()}
                    formatOption={formatGLAccountOption}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    components={{ Option }}
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <footer className="setting-footer">
          <Button small className="save-btn" type="submit" loading={updateMutation.isLoading}>
            Save and Move Next
          </Button>
        </footer>
      </Form>
    </section>
  );
}

export default FinancialInformation;
