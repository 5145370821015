import { getVATDetailColumns, getVATDetailRows } from "./vat-detail-statement";
import { getVatDetails } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const vatDetailFilters = {
  LocationId: "*",
  Location: "*",
  CompanyIdParam: "*",
  Company: "*",
  CompanyName: "*",
};

const vatDetailFilterOptions = [
  { key: "locationId", apiKey: "LocationId" },
  { key: "location", apiKey: "Location" },
  { key: "companyIdParam", apiKey: "CompanyIdParam", },
  { key: "company", apiKey: "Company", keyType: "label" },
  { key: "companyName", apiKey: "CompanyName" }
];

export default function ReportVATDetail({ activeCompany }) {
  return (
    <DynamicReport
      reportName="vat-details"
      activeCompany={activeCompany}
      columnsDataProvider={getVATDetailColumns}
      rowsDataProvider={getVATDetailRows}
      fetchData={getVatDetails}
      exportUrl="reports/export-query-report/vat-details"
      filtersInitialState={vatDetailFilters}
      filterOptions={vatDetailFilterOptions}
      orderBy="Building"
      showDateRangeFilter={false}
    />
  );
}
