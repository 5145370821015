import Skeleton from "react-loading-skeleton";

function ActivityItemSkeleton() {
  return (
    <div className="d-flex" style={{ gap: "12px" }}>
      <Skeleton inline circle width={30} height={30} />

      <div className="flex-grow-1 me-2">
        <Skeleton inline width={100} height={10} />
        <Skeleton inline height={10} />
      </div>
    </div>
  );
}

export default ActivityItemSkeleton;
