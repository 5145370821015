import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";

import { Button } from "@hydra/atom/components";
import { BoxedContent, Header, NoDataFound } from "@/components/common";
import { FinanceMasterTable } from "@/components/finance";
import {
  getCurrencyTableColumns,
  getCurrencyTableData,
} from "@/components/finance/currency/currencyTableData";
import { getCurrencies, deleteCurrency } from "@/api/finance/currencyApi";
import { skeletonLoadingRows } from "@/utils/helpers";
import showToast from "@/utils/toast/helpers";

function Currency() {
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(["currency"], getCurrencies);

  const queryClient = useQueryClient();
  const deleteMutation = useMutation(
    deleteCurrency,
    {
      onError: () => {
        showToast("Could not delete. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Deleted successfully", "success");

        queryClient.invalidateQueries({
          queryKey: ["currency"]
        });
      },
    }
  );

  const renderTable = () => {
    if (!isLoading && data && !data?.data) {
      return (
        <NoDataFound
          title="No currency has been added"
          buttonText="Add currency"
          onClick={() => navigate("/finance/currency/new")}
        />
      );
    }
    return (
      <FinanceMasterTable
        objectName="Currency"
        isLoading={isLoading}
        tableColumns={getCurrencyTableColumns(data?.data || [])}
        data={getCurrencyTableData(data?.data || skeletonLoadingRows(5))}
        handleDelete={(id) => deleteMutation.mutate(id)}
      />
    );
  };

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<h1>Currency</h1>}
        rightContent={(
          <Button small onClick={() => navigate("/finance/currency/new")}>
            Add currency
          </Button>
        )}
      />

      {renderTable()}
    </BoxedContent>
  );
}

export default Currency;
