import {
  useState, useRef, useMemo
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import { CustomDropdown } from "@/components/common";
import { getRentRollRows, getRentRollColumns } from "./rent-roll-statement";
import { inflows as emptyInflows, outflows as emptyOutflows } from "./profit-and-loss-statement/data";
import { calculateOutputVariables } from "./helpers";
import getGeneralJournal from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";

function ReportAgentSummary() {
  const ref = useRef();
  const [cashInflow,] = useState(() => [...emptyInflows]);
  const [columnData, setColumnData] = useState([]);
  const [cashOutflow,] = useState(() => [
    ...emptyOutflows
  ]);
  const [filterText, setFilterText] = useState("");
  const data = [
    {
      label: "All",
      value: true
    },
    {
      label: "Transaction",
      value: true
    },
    {
      label: "Date",
      value: true
    },
    {
      label: "Num",
      value: true
    },
    {
      label: "Name",
      value: true
    },
    {
      label: "Building",
      value: true
    },
    {
      label: "Unit",
      value: true
    },
    {
      label: "Account",
      value: true
    }, {
      label: "Debit",
      value: true
    },
    {
      label: "Credit",
      value: true
    }
  ];
  const columns = useMemo(
    () => getRentRollColumns(ref?.current?.clientWidth, columnData),
    [ref?.current?.clientWidth, columnData]
  );

  const { data: getGeneralJournalData, isLoading } = useQuery(["general-journal"], getGeneralJournal);
  const rowsData = getGeneralJournalData?.data;
  const inputVariables = {
    cashInflow,
    cashOutflow,
    rowsData
  };
  const years = new Array(7).fill(0);
  const outputVariables = calculateOutputVariables(inputVariables, years);
  const plannerData = {
    ...inputVariables,
    ...outputVariables,
    rowsData
  };

  const rows = useMemo(() => (getRentRollRows(plannerData, columnData)), [rowsData]);

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-6">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-6 btn-icon-container">
          <CustomDropdown data={data} setColumnData={setColumnData} />
        </div>
      </div>
      <div className="react-grid-container">
        <ReactGrid
          rows={rows}
          columns={columns}
          enableFillHandle
          enableRangeSelection
        />
      </div>
    </div>
  );
}

export default ReportAgentSummary;
