import PropTypes from "prop-types";
import Modal from "react-responsive-modal";

import "react-responsive-modal/styles.css";

export default function CoreModal({
  rootClassName, className, isOpen, onClose, children, ...restProps
}) {
  return (
    <Modal
      {...restProps}
      open={isOpen}
      onClose={onClose}
      animationDuration={200}
      classNames={{
        root: `core-modal-root ${rootClassName}`,
        modalContainer: "core-modal-container",
        modal: `core-modal ${className}`,
      }}
    >
      {children}
    </Modal>
  );
}

CoreModal.propTypes = {
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};

CoreModal.defaultProps = {
  rootClassName: "",
  className: "",
  isOpen: false,
  onClose: () => {},
};
