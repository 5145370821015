import request from "@/utils/api/helpers";

const baseUrl = "/finance/tax-rates";

export const getTaxRates = () => request({
  url: baseUrl
});

export const getTaxRateById = (id) => request({
  url: `${baseUrl}/${id}`
});

export const createTaxRate = (data) => request({
  url: baseUrl,
  method: "POST",
  data
});

export const updateTaxRate = (id, data) => request({
  url: `${baseUrl}/${id}`,
  method: "PATCH",
  data
});

export const deleteTaxRate = (id) => request({
  url: `${baseUrl}/${id}`,
  method: "DELETE",
});
