import { checkDynamicObjectPermission } from "@/utils/dynamic/helpers";
import { getRoles } from "@/api/user/authApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

export const REPORT = "REPORT";

export const checkIfValueExists = (reportType, permissions) => {
  const userRoles = getRoles();
  const isAdmin = userRoles.includes("admin");

  const reportPermissions = isAdmin ? [] : permissions?.Report?.permissions;

  if (isAdmin) {
    return true;
  }

  if (reportType === "unit-availability") {
    return checkDynamicObjectPermission({
      permissions,
      scope: dynamicObjectMap.get("UnitObjectName"),
      action: "View",
    });
  }

  if (reportType === "incident-report") {
    return checkDynamicObjectPermission({
      permissions,
      scope: dynamicObjectMap.get("IncidentReportObjectName"),
      action: "View",
    });
  }

  if (reportType === "facility-contract-report") {
    return checkDynamicObjectPermission({
      permissions,
      scope: dynamicObjectMap.get("FacilityContractObjectName"),
      action: "View",
    });
  }

  if (reportType === "revenue-recognition-report") {
    return checkDynamicObjectPermission({
      permissions,
      scope: dynamicObjectMap.get("RevenueRecognitionRevenueRecognitionDetailObjectName"),
      action: "View",
    });
  }

  if (reportType === "work-order-report") {
    return checkDynamicObjectPermission({
      permissions,
      scope: dynamicObjectMap.get("WorkOrderObjectName"),
      action: "View",
    });
  }

  if (reportType === "tenant-report") {
    return checkDynamicObjectPermission({
      permissions,
      scope: dynamicObjectMap.get("TenantObjectName"),
      action: "View",
    });
  }

  if (reportType === "petty-cash-by-supervisor-report") {
    return checkDynamicObjectPermission({
      permissions,
      scope: dynamicObjectMap.get("PettyCashObjectName"),
      action: "View",
    });
  }

  if (reportType === "petty-cash-transaction-report") {
    return checkDynamicObjectPermission({
      permissions,
      scope: dynamicObjectMap.get("PettyCashTransactionObjectName"),
      action: "View",
    });
  }

  if (reportType === "legal-case-report") {
    return checkDynamicObjectPermission({
      permissions,
      scope: dynamicObjectMap.get("LegalCaseObjectName"),
      action: "View"
    });
  }
  if (reportType === "petty-cash-claim-categories") {
    return checkDynamicObjectPermission({
      permissions,
      scope: dynamicObjectMap.get("PettyCashClaimObjectName"),
      action: "View",
    });
  }

  if (reportType === "supplier-report") {
    return checkDynamicObjectPermission({
      permissions,
      scope: dynamicObjectMap.get("SupplierObjectName"),
      action: "View"
    });
  }

  if (reportType === "expense-report") {
    return checkDynamicObjectPermission({
      permissions,
      scope: dynamicObjectMap.get("ExpenseObjectName"),
      action: "View",
    });
  }

  if (reportType === "expense-detail-report") {
    return checkDynamicObjectPermission({
      permissions,
      scope: dynamicObjectMap.get("ExpenseDetailObjectName"),
      action: "View",
    });
  }

  if (reportType === "fitout-report") {
    return checkDynamicObjectPermission({
      permissions,
      scope: dynamicObjectMap.get("FitOutRequestObjectName"),
      action: "View"
    });
  }

  return reportPermissions?.some((i) => i.objectName === reportType);
};
