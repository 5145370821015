import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getZeroPurchaseColumns() {
  const colArr = [
    {
      label: "Tax Payer TRN",
      columnId: "taxPayerTRN",
      width: estimatedColumnWidth("number"),
      resizable: true,
      value: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Company Name",
      columnId: "companyName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      value: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tax Invoice/Tax credit note No.",
      columnId: "invoiceNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      value: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tax Invoice/Tax credit note Date",
      columnId: "invoiceDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      value: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Reporting Period From",
      columnId: "periodFrom",
      width: estimatedColumnWidth("date"),
      resizable: true,
      value: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Reporting Period To",
      columnId: "periodTo",
      width: estimatedColumnWidth("date"),
      resizable: true,
      value: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Tax Invoice/Tax credit note Amount",
      columnId: "amount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      value: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Supplier Name",
      columnId: "supplierName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      value: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Supplier TRN",
      columnId: "supplierTRN",
      width: estimatedColumnWidth("number"),
      resizable: true,
      value: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Clear description of the Supply",
      columnId: "description",
      width: estimatedColumnWidth("name"),
      resizable: true,
      value: true,
      formatter: (value) => String(value || "")
    }
  ];
  return colArr;
}
