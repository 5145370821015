import { useMemo } from "react";
import PropTypes from "prop-types";
import { camelize } from "humps";
import { startCase } from "lodash";
import { isValid } from "date-fns";

import { FormControl, Input } from "@hydra/atom/components";
import FieldLabel from "./FieldLabel";
import { getTestId, formatDate, } from "@/utils/helpers";

export const getDisplayFields = (name) => {
  const nameWithoutBrackets = name.slice(1, name.length - 1);
  const [lookupFieldName, ...displayFieldNames] = nameWithoutBrackets.split(".");
  const displayFields = displayFieldNames.map((d) => camelize(d));

  return {
    lookupFieldName: camelize(lookupFieldName),
    displayFields
  };
};

const getValue = (value, displayFields, state, lookupFieldName) => {
  if (!value) return "";

  // Shows lookup field
  if (displayFields.length > 1) {
    let displayValue = "";

    displayFields.forEach((displayField, index) => {
      if (index) {
        displayValue = displayValue[displayField];
      } else {
        displayValue = value[displayField];
      }
    });

    return displayValue;
  }

  const displayFieldName = displayFields[0];

  if (displayFieldName === "saleRate" || displayFieldName === "purchaseRate") {
    let taxRate = 0;

    if (value[displayFieldName]) {
      taxRate = value[displayFieldName];
    }

    let { amountOfTax, amountBeforeTax } = state;

    if (lookupFieldName === "utilityTax") {
      const { utilityAmountOfTax, utilityAmountBeforeTax } = state;
      amountOfTax = utilityAmountOfTax;
      amountBeforeTax = utilityAmountBeforeTax;
    }

    if (amountOfTax && amountBeforeTax) {
      switch (amountOfTax.value) {
        case "Exclusive":
          return `VAT @ ${taxRate}% on ${amountBeforeTax}`;

        case "Inclusive":
          return `Incl. VAT @ ${taxRate}% on ${amountBeforeTax}`;

        default:
          return "";
      }
    }

    return "";
  }

  // Shows date field
  if (value[displayFieldName] && typeof value[displayFieldName] === "string" && isValid(new Date(value[displayFieldName]))) {
    return formatDate(new Date(value[displayFieldName]));
  }

  if (value[displayFieldName] && typeof value[displayFieldName] === "object") {
    if (Object.prototype.hasOwnProperty.call(value[displayFieldName], "name")) {
      return value[displayFieldName].name;
    }

    if (Object.prototype.hasOwnProperty.call(value[displayFieldName], "number")) {
      return value[displayFieldName].number;
    }
  }

  if (value[displayFieldName] && typeof value[displayFieldName] !== "object") {
    return value[displayFieldName];
  }

  return "";
};

const getLabel = (displayFields) => {
  const displayFieldName = displayFields[0];

  if (displayFieldName === "saleRate" || displayFieldName === "purchaseRate") {
    return "Tax Rate";
  }

  return displayFieldName;
};

function DisplayField({
  field, state, name, showLabel, disabled, testId
}) {
  const fieldConfig = useMemo(() => getDisplayFields(field.name), [field.name]);
  const { lookupFieldName, displayFields } = fieldConfig;

  const value = getValue(state[lookupFieldName], displayFields, state, lookupFieldName);
  const label = getLabel(displayFields);

  return (
    <FormControl>
      {
        showLabel ?
          (
            <FieldLabel
              label={startCase(label)}
              labelFor={field.name}
              helperText={field.helperText}
              isRequired={field.required}
            />
          ) : null
      }
      <Input
        id={name}
        type="text"
        value={value ?? ""}
        onChange={() => {}}
        disabled={field.disabled || disabled}
        testId={getTestId(testId)}
      />
    </FormControl>
  );
}

DisplayField.propTypes = {
  field: PropTypes.object.isRequired,
  name: PropTypes.string,
  showLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

DisplayField.defaultProps = {
  name: "tuli-display-field",
  showLabel: true,
  disabled: false,
  testId: ""
};

export default DisplayField;
