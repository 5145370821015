import { useState } from "react";
import { getInputVATRows, getInputVATColumns } from "./input-vat-statement";
import { getInputVat } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";
import { formatNumber } from "@/utils/helpers";

const inputVATFilters = {
  RecordType: "*",
};

const inputVATFilterOptions = [
  { key: "recordType", apiKey: "RecordType", keyType: "label" },
];

export default function ReportInputVAT({ activeCompany }) {

  const [reportCardState, setReportCardState] = useState(null);

  const handleDataLoaded = (data) => {
    const sums = data.reduce((acc, item) => {
      acc.vATValue += item.vATValue;
      acc.vATValueCommercial += item.vATValueCommercial;
      acc.vATValueResidental += item.vATValueResidental;
      acc.vATValueAdmin += item.vATValueAdmin;
      return acc;
    }, {
      vATValue: 0,
      vATValueCommercial: 0,
      vATValueResidental: 0,
      vATValueAdmin: 0,
    });

    const reportCardData = {
      size: 2, // Number of ReportCard components
      dataChunks: 2, // Number of data items per ReportCard component
      data: [
        {
          titleValue: `${formatNumber(sums?.vATValue || 0)}`,
          titleColor: "success",
          subtitle: "VAT Value (AED)",
          subtitleColor: "primary",
        },
        {
          titleValue: `${formatNumber(sums?.vATValueCommercial || 0)}`,
          titleColor: "error",
          subtitle: "VAT Value Commercial (AED)",
          subtitleColor: "primary",
          positive: true,
        },
        {
          titleValue: `${formatNumber(sums?.vATValueResidental || 0)}`,
          titleColor: "primary",
          subtitle: "VAT Value Residental (AED)",
          subtitleColor: "primary",
          positive: false,
        },
        {
          titleValue: `${formatNumber(sums?.vATValueAdmin || 0)}`,
          titleColor: "primary",
          subtitle: "VAT Value Admin (AED)",
          subtitleColor: "primary",
        }
      ],
    };
    setReportCardState(reportCardData);
  };

  return (
    <DynamicReport
      reportName="vat-input"
      activeCompany={activeCompany}
      columnsDataProvider={getInputVATColumns}
      rowsDataProvider={getInputVATRows}
      fetchData={getInputVat}
      exportUrl="reports/export-query-report/vat-input"
      orderBy="CompanyName"
      filtersInitialState={inputVATFilters}
      filterOptions={inputVATFilterOptions}
      onDataLoaded={handleDataLoaded}
      reportCardData={reportCardState}
    />
  );
}
