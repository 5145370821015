import chroma from "chroma-js";
import {
  formatISO, getDate, getHours, getMinutes, getMonth, getYear
} from "date-fns";
import { map } from "lodash";
import { getFullName } from "@/utils/helpers";

import intermediateObjectFieldNamesMap from "@/utils/maps/intermediateObjectFieldNamesMap";
import { statusColorMap } from "@/utils/maps/statusColorMap";
import { allowedDesignationsForPettyCashRequest } from "./constants";

export const getISOFormat = (date) =>
  `${formatISO(new Date(date), { representation: "date" })}T00:00:10.000Z`;

export const checkStartAndEndTimeForSameDate = (timeRange) => {
  if (!timeRange[0] && !timeRange[1]) {
    return true;
  }
  const [startHours, startMinutes] = timeRange[0].split(":");
  const [endHours, endMinutes] = timeRange[1].split(":");

  if (startHours >= endHours) {
    if (startMinutes >= endMinutes) {
      return true;
    }
  }

  return false;
};

export const checkIfArrayValueIsNull = (arr) => {
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i] === null) {
      return true;
    }
  }

  return false;
};

export const formatWorkOrderJob = (job) => {
  const { jobStatus: status, jobType } = job;
  const workingJobHours = job.totalHours;

  const proposedTimeData = job.proposedTime.map((item) => ({
    ...item,
    externalSupervisor: job.externalSupervisor,
  }));

  const jobData = {
    workOrderId: job.workOrder.id,
    workOrder: {
      ...job.workOrder,
      label: job.workOrder.number,
      value: job.workOrder.id,
    },
    id: job.id,
    jobType: jobType?.toLowerCase(),
    title: job.name,
    isVendor: job.isVendor,
    description: job.description,
    externalSupervisor: job.externalSupervisor.id ?
      {
        label: getFullName(job.externalSupervisor),
        value: job.externalSupervisor.id,
        supplier: {
          label: job.externalSupervisor?.supplier?.name,
          value: job?.externalSupervisor?.supplier?.id,
        },
        selected: true,
      } :
      job.externalSupervisor,
    externalTeam: job.externalTeam.id ?
      {
        label: getFullName(job.externalTeam),
        value: job.externalTeam.id,
      } :
      null,
    externalNote: job.externalNote,
    proposedTime: proposedTimeData,
    technician: Array.isArray(job.technicianSkill) ?
      job.technicianSkill.map((t) => {
        const { technician } = t;
        return {
          label: getFullName(technician),
          value: t.id,
          technicianId: technician.id,
          initials: `${technician.name[0]}${technician.name[1]}`,
          color: chroma.random().hex(),
          designation: technician.designation?.name,
          rate: t.perHour || 0,
          currency: "AED",
          startingHour: technician.startingHour,
          endingHour: technician.endingHour,
          hours: workingJobHours,
          rateText: "/hr",
          selected: true,
        };
      }) :
      [],
    status: {
      label: status,
      value: status,
      color: statusColorMap.get(status.toLowerCase()),
    },
    materials: Array.isArray(job.materials) ?
      job.materials.map((m) => ({
        item: {
          label: m?.item?.itemName,
          value: m?.item?.id,
        },
        label: m?.item?.itemName,
        value: m?.item?.id,
        id: m.id,
        [intermediateObjectFieldNamesMap.get("DetailIdFieldName")]:
            m[[intermediateObjectFieldNamesMap.get("DetailIdFieldName")]],
        quantity: m.quantity,
        price: m.price,
        unit: m?.item?.uom,
        rate: m.price || 0,
        currency: "AED",
        rateText: `/${m?.item?.uom}`,
      })) :
      [],
    dateRange: [
      job.startDate ? new Date(job.startDate) : null,
      job.endDate ? new Date(job.endDate) : null,
    ],
    timeRange: [job.startTime, job.endTime],
    equipment: Array.isArray(job.equipment) ?
      job.equipment.map((e) => ({
        label: e?.itemName,
        value: e.id,
        assetId: e.number,
        rate: e?.sellingRate || 0,
        currency: "AED",
        hours: workingJobHours,
        rateText: "/hr",
      })) :
      [],
    jobUnit: {
      label: job?.unit?.name,
      value: job?.unit?.id,
    },
    notes: map(job?.notes, "note"),
    attachments: job.photos || [],
    photos: job.photos || [],
    technicianAudios: job.technicianAudios || [],
    technicianVideos: job.technicianVideos || [],
    createdAt: job.createdAt,
    number: job.number,
  };
  if (job.workOrder.type?.toLowerCase() === "scheduled") {
    jobData.jobFor = {
      lookupObjectName: job.jobFor.objectName,
      value: job.jobFor.id,
      label: job.jobFor.name,
    };
  }

  return jobData;
};

export const formatWorkOrder = (workOrder) => {
  const {
    status, priority, type, incidentType
  } = workOrder;
  const workOrderData = {
    id: workOrder.id,
    type,
    title: workOrder.name,
    number: workOrder.number,
    description: workOrder.description,
    unit: null,
    serviceRequest: null,
    incidentType: "",
    priority: {
      label: priority,
      value: priority,
    },
    supervisor: {
      label: getFullName(workOrder?.supervisor),
      value: workOrder?.supervisor?.id,
    },
    subCategory: {
      label: workOrder?.subCategory?.name || "",
      value: workOrder?.subCategory?.id || "",
    },
    category: {
      label: workOrder?.category?.name,
      value: workOrder?.category?.id,
    },
    workOrderStatus: {
      label: status,
      value: status,
      color: statusColorMap.get(status.toLowerCase()),
    },
    building: {
      label: workOrder?.building?.name,
      value: workOrder?.building?.id,
    },
    visualInspection: {
      label: workOrder?.visualInspection?.number,
      value: workOrder?.visualInspection?.id,
    },
    incidentReport: {
      label: workOrder?.incidentReport?.number,
      value: workOrder?.incidentReport?.id,
    },
    createdAt: workOrder.createdAt,
  };

  if (type.toLowerCase() === "reactive") {
    workOrderData.unit = {
      label: workOrder?.unit?.name,
      value: workOrder?.unit?.id,
    };

    workOrderData.incidentType = incidentType;

    if (incidentType.toLowerCase() === "servicerequest" && workOrder.serviceRequest?.id) {
      workOrderData.serviceRequest = {
        label: workOrder?.serviceRequest?.name,
        value: workOrder?.serviceRequest?.id,
      };

      const tenant = workOrder?.serviceRequest?.tenant;

      workOrderData.tenant = {
        label: getFullName(tenant),
        value: tenant?.id,
      };
    }
  } else {
    workOrderData.blanketAgreement = {
      value: workOrder.blanketAgreement.id,
      label: workOrder.blanketAgreement.number,
    };
  }

  return workOrderData;
};

export const getUniqueData = (jobs, dataKey) => {
  const data = [];
  const keys = [];

  jobs.forEach((job) => {
    job[dataKey].forEach((item) => {
      if (!keys.includes(item.value)) {
        keys.push(item.value);
        data.push({
          ...item,
          quantity: item.quantity || item.hours,
          description: job.description,
        });
      } else {
        const selectedItem = data.find((i) => i.value === item.value);
        if (dataKey === "material") {
          selectedItem.quantity = Number(selectedItem.quantity) + Number(item.quantity);
        } else {
          selectedItem.quantity =
            Number(selectedItem.quantity) + Number(item.quantity || item.hours);
          selectedItem.hours = Number(selectedItem.hours) + Number(item.hours);
        }
      }
    });
  });

  return data;
};

// TODO: refactor with addHours, addMinutes helpers
export const formatDateTime = ({ date, time }) => {
  const [datePart] = date.split("T");

  const combinedStr = `${datePart}T${time}`;
  const dateTime = new Date(combinedStr);
  const year = getYear(dateTime);
  const month = getMonth(dateTime);
  const day = getDate(dateTime);
  const hours = getHours(dateTime);
  const minutes = getMinutes(dateTime);
  const formattedDate = new Date(year, month, day, hours, minutes);
  return formattedDate;
};

export const filterWorkOrderJob = (job) => ({
  id: job.number,
  content: {
    title: job.jobType === "job" ? "Workorder Job" : "Workorder Inspection",
    priority: job.priority,
    type: job.jobType,
    status: job.jobStatus,
  },
  start: formatDateTime({ date: job.startDate, time: job.startTime }),
  end: formatDateTime({ date: job.endDate, time: job.endTime }),
  job: { ...job },
});

export const convertTo12HourFormat = (militaryTime) => {
  if (militaryTime) {
    const militaryTimeParts = militaryTime?.split(":");
    let hours = parseInt(militaryTimeParts[0], 10);
    const minutes = militaryTimeParts[1];
    let period = "AM";

    if (hours >= 12) {
      period = "PM";
      if (hours > 12) {
        hours -= 12;
      }
    }

    return `${hours}:${minutes} ${period}`;
  }
};

export const filterSupervisorPettyCashRequestEmployee = (option, inputValue) => {
  if (inputValue && !option.data.name?.toLowerCase()?.includes(inputValue?.toLowerCase())) {
    return false;
  }

  if (
    option.data?.designation?.name &&
    allowedDesignationsForPettyCashRequest.includes(option.data.designation.name)
  ) {
    return true;
  }

  return false;
};
