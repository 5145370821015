import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { Loader } from "@hydra/atom/components";
import { JobBoard } from "@/components/facility/board";
import {
  Header,
  CustomizedDateRangePicker,
  SvgIcon,
  IconButton,
  NoDataFound,
  HeaderLeftContent
} from "@/components/common";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

function WorkOrderBoardView() {
  const { data: jobs, isInitialLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("WorkOrderJobObjectName")),
      {
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
      },
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("WorkOrderJobObjectName"), {
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
      })
  );

  if (isInitialLoading) return <Loader />;

  if (!jobs || !jobs?.data?.length) {
    return <NoDataFound title="No job has been found" />;
  }

  return (
    <div className="job-board-container">
      <Header
        className="mt-15"
        leftContent={(
          <div className="board-left-content">
            <HeaderLeftContent
              title="Work orders Board"
              subtitle="Supercharge your workflow and connect the tool you use every day."
            />
          </div>
        )}
        rightContent={(
          <div className="work-order-right-content ">
            <CustomizedDateRangePicker name="dateRange" required />
            <IconButton onClick={() => {}}>
              <SvgIcon icon="more" />
            </IconButton>
          </div>
        )}
      />
      <JobBoard jobs={jobs} />
    </div>
  );
}

export default WorkOrderBoardView;
