import PropTypes from "prop-types";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { DynamicFormContainer } from "@/components/dynamic";

const formatFitOutRequest = (data) => ({
  approvedStartDate: new Date(data?.startDate),
  approvedEndDate: new Date(data?.endDate),
  approvedStartTime: data?.approvedStartTime || data?.startTime,
  approvedEndTime: data?.approvedEndTime || data?.endTime
});

function FitOutWorkflowFormContainer({ task, recordData, setIsLoading }) {
  const renderForm = () => {
    if (
      task?.objectName === dynamicObjectMap.get("FitOutRequestObjectName") &&
      task?.currentStep === "Approve Fit Out Request"
    ) {
      return (
        <DynamicFormContainer
          initialData={formatFitOutRequest(recordData)}
          objectName={task.objectName}
          selectedId={recordData?.id}
          setIsLoading={setIsLoading}
          navigate={false}
          showButtons={false}
          layout="UpdateFormModel"
        />
      );
    }

    return null;
  };

  return <div>{renderForm()}</div>;
}

FitOutWorkflowFormContainer.propTypes = {
  task: PropTypes.object.isRequired,
  recordData: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default FitOutWorkflowFormContainer;
