import PropTypes from "prop-types";

function PieChartLegendItem({ title, subtitle, color }) {
  return (
    <div className="pie-chart-legend-item">
      <h1 className="legend-title">
        <div className="legend" style={{ backgroundColor: color }} />
        {" "}
        {title}
      </h1>
      <p className="legend-subtitle">{subtitle}</p>
    </div>
  );
}

PieChartLegendItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default PieChartLegendItem;
