import { getUnitReadyReportRows, getUnitReadyReportColumns } from "./unit-ready-report";
import { getUnitReady } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const unitReadyFilters = {
  CompanyName: "*",
  SupervisorKey: "*",
  BuildingKey: "*",
  UnitKey: "*",
};

const unitReadyFilterOptions = [
  { key: "companyName", apiKey: "CompanyName" },
  { key: "supervisorKey", apiKey: "SupervisorKey" },
  { key: "buildingKey", apiKey: "BuildingKey" },
  { key: "unitKey", apiKey: "UnitKey" }
];

export default function ReportUnitReady({ activeCompany }) {
  return (
    <DynamicReport
      reportName="unit-ready"
      activeCompany={activeCompany}
      columnsDataProvider={getUnitReadyReportColumns}
      rowsDataProvider={getUnitReadyReportRows}
      fetchData={getUnitReady}
      exportUrl="reports/export-query-report/unit-ready"
      filtersInitialState={unitReadyFilters}
      filterOptions={unitReadyFilterOptions}
      orderBy="WorkOrderNumber"
      showDateRangeFilter={false}
    />
  );
}
