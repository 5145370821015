import PropTypes from "prop-types";

export default function BoxedContent({ className, children }) {
  return (
    <div className={`${className} dashboard-page with-padding main-content`}>
      <div className="container-fluid">{children}</div>
    </div>
  );
}

BoxedContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

BoxedContent.defaultProps = {
  className: ""
};
