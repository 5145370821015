/* eslint-disable no-plusplus */
import {
  nonEditable,
  textCell,
  headerCell
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT } from "../constants";
import { formatCurrency } from "@/utils/helpers";

export const CASH_IN_CHANGE_ROW_ID = "cashInChange";

function getHeaderRow(columns) {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: columns.map((column) => headerCell(column.label)),
  };
}

function getGroupRows(title, groups, totalDebit, totalCredit, columns) {
  if (groups?.length) {
    return [
      ...groups.map((item, index) => ({
        rowId: item.id,
        height: ROW_HEIGHT,
        cells: columns.map((column) => {
          const textCellArgs = {};

          if (column.formatter) {
            textCellArgs.text = column.formatter(item[column.columnId], index);
          } else {
            textCellArgs.text = item[column.columnId] ?? "";
          }

          return nonEditable(textCell(textCellArgs), column.className || "");
        }),
      }))
    ];
  }
  return [];
}

export function getOutOfScopeRows({
  data,
  columns
}) {

  if (!data) {
    return [];
  }
  return [getHeaderRow(columns), ...getGroupRows("Total", data.data, formatCurrency(data?.totalDebit, "AED"), formatCurrency(data?.totalCredit, "AED"), columns)];
}
