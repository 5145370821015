import { useReducer, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Loader } from "@hydra/atom/components";
import { kebabCase } from "lodash";
import { useSelector } from "react-redux";
import { BoxedContent, Header } from "@/components/common";
import {
  userManagementReducer,
  initialState,
  setInitialState
} from "@/reducers/admin/userManagementReducer";
import { useScrollToTop } from "@/hooks";
import { getAllRoles } from "@/api/user/roleApi";
import UserManagementForm from "@/components/admin/user-management/UserManagementForm";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import { getUserManagementById } from "@/api/admin/userManagementApi";
import { selectApps } from "@/store/appSlice";
import { userType } from "@/utils/admin/constants";

function UserManagement() {
  const [state, dispatch] = useReducer(userManagementReducer, initialState);
  const { scrollToTopRef } = useScrollToTop(state.activeStep);
  const params = useParams();
  const employeeId = params?.id;

  const { data: allRolesData, isInitialLoading } = useQuery(
    ["all-roles-data"],
    () => getAllRoles(),
    {}
  );
  const apps = useSelector(selectApps);

  const { data: userManagementData, isInitialLoading: userManagementDataLoading } = useQuery(
    ["user-management-data", employeeId],
    () => getUserManagementById(employeeId),
    {
      enabled: employeeId !== undefined,

    }
  );

  const { data: employeeList, isInitialLoading: isLoading } = useQuery(
    [kebabCase(dynamicObjectMap.get("EmployeeObjectName")), employeeId],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("EmployeeObjectName"), employeeId),
    {
      enabled: Boolean(employeeId),
    }
  );

  useEffect(() => {
    if (employeeList && allRolesData?.data && userManagementData) {
      const modules = [...apps];
      const selectedEmployee = employeeList;
      const formattedRoles = allRolesData.data.map((roleItem) => ({
        label: roleItem.name,
        value: roleItem.id
      }));
      const selectedRole = formattedRoles?.filter((item) =>
        !!userManagementData.roles?.includes(item.label));
      const selectedModule = modules?.filter((item) =>
        !!userManagementData.defaultModule?.includes(kebabCase(item.value)));
      const selectedUserType = userType?.filter((item) =>
        !!userManagementData.userAccessTypes?.includes(kebabCase(item.value)));

      dispatch(setInitialState({
        roleOptions: formattedRoles,
        employeeId: { label: selectedEmployee.name, value: selectedEmployee.id },
        roles: selectedRole,
        moduleOptions: modules,
        defaultModule: selectedModule,
        userAccessTypes: selectedUserType,
        isUserEnabled: userManagementData?.isUserEnabled
      }));
    }
  }, [isLoading, isInitialLoading, userManagementDataLoading]);

  const leftContent = (title, subtitle) => (
    <div className="container-header-left-content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );

  if (isInitialLoading || userManagementDataLoading) {
    return <BoxedContent><Loader /></BoxedContent>;
  }

  return (
    <BoxedContent>
      <div ref={scrollToTopRef}>
        <Header
          showBreadcrumb
          leftContent={leftContent(
            "User Management",
            "You can see your user settings here"
          )}
        />
        <UserManagementForm
          state={state}
          dispatch={dispatch}
        />
      </div>
    </BoxedContent>
  );
}

export default UserManagement;
