import { useMemo } from "react";

import { Button } from "@hydra/atom/components";
import {
  Header, FormLeftHeader, CrossButton, Drawer
} from "@/components/common";
import { renderField } from "@/utils/dynamic/helpers";

export default function DynamicFiltersDrawer({
  isOpen,
  onClose,
  fields,
  filters,
  setFilters,
  onSearch,
}) {
  const searchFields = useMemo(() => fields.filter((f) => f.searchFilter).sort((a, b) => {
    if (a.filterOrder === 0) return Infinity;
    if (b.filterOrder === 0) return -Infinity;
    return Number(a.filterOrder) - Number(b.filterOrder);
  }), [fields]);

  const setFieldValue = (key, value) =>
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  const leftContent = (
    <div className="left-content">
      <h3>Filters</h3>
    </div>
  );

  return (
    <Drawer isOpen={isOpen} overlay={false} size={400} onClose={() => onClose(false)}>
      <div
        className="filters-drawer-header"
      >
        <Header
          leftContent={leftContent}
          rightContent={(
            <div className="filters-drawer-header-button">
              <Button onClick={onSearch} className="btn-with-icon">
                Search
              </Button>
              <CrossButton onClick={() => onClose(false)} />
            </div>
    )}
        />
      </div>
      <div className="filters-drawer">
        {isOpen && (
          <div className="filters-drawer-container">
            {searchFields
              .map((field, index) => (
                <div key={`filter-${index}`} className="filter-item">
                  <FormLeftHeader title={field.label} />
                  {renderField({
                    index,
                    field: {
                      ...field,
                      drawerField: true,
                      dynamicFilterDrawerField: true,
                      required: false
                    },
                    state: filters,
                    onChange: (key, value) => setFieldValue(key, value),
                    showLabel: false,
                  })}
                </div>
              ))}
          </div>
        )}
      </div>
    </Drawer>
  );
}
