import { configureStore } from "@reduxjs/toolkit";

import { userSlice } from "./userSlice";
import { appSlice } from "./appSlice";
import { notificationSlice } from "./notificationSlice";
import { autoNumberSlice } from "./autoNumberSlice";

const store = configureStore({
  reducer: {
    [userSlice.name]: userSlice.reducer,
    [appSlice.name]: appSlice.reducer,
    [notificationSlice.name]: notificationSlice.reducer,
    [autoNumberSlice.name]: autoNumberSlice.reducer,
  },
  devTools: true,
});

export default store;
