import { useState } from "react";
import PropTypes from "prop-types";

import { Button, ReactSelect } from "@hydra/atom/components";
import { SelectWorkOrderTypeModal } from "@/components/modals";
import { Tooltip, StatusCircle, RequirePermission } from "@/components/common";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

const colorCodes = [
  {
    label: "Inspection",
    color: "#029a82",
  },
  {
    label: "Corrective Job",
    color: "#536dfe",
  },
  {
    label: "PPE Job",
    color: "#FF7600",
  },
  {
    label: "Low Priority",
    color: "#00E676",
  },
  {
    label: "Medium Priority",
    color: "#EAC72A",
  },
  {
    label: "High Priority",
    color: "#FF9700",
  },
  {
    label: "Critical Priority",
    color: "#ff0000",
  },
];

function Header({
  timelineView,
  setTimelineView,
  subViewOption,
  subViewOptions,
  setSubViewOption,
}) {
  const [openSelectWorkOrderTypeModal, setOpenSelectWorkOrderTypeModal] = useState(false);

  return (
    <header className="plan-board-header">
      <SelectWorkOrderTypeModal
        isOpen={openSelectWorkOrderTypeModal}
        onClose={setOpenSelectWorkOrderTypeModal}
      />
      <div className="view-changer-container">
        <button
          type="button"
          className={!timelineView ? "active" : ""}
          onClick={() => setTimelineView(false)}
        >
          Agenda
        </button>
        <button
          type="button"
          className={timelineView ? "active" : ""}
          onClick={() => setTimelineView(true)}
        >
          Team
        </button>
      </div>
      <div className="right-container ml-auto">
        <div className="sort-dropdown-container">
          <ReactSelect
            options={subViewOptions}
            value={subViewOption}
            onChange={(option) => setSubViewOption(option)}
          />
        </div>
        <RequirePermission parent="Model" scope={dynamicObjectMap.get("WorkOrderObjectName")} action="Insert">
          <Button small onClick={() => setOpenSelectWorkOrderTypeModal(true)}>
            Add WorkOrder
          </Button>
        </RequirePermission>
        <Tooltip
          activator={<span className="material-icons-outlined">info</span>}
        >
          <ul className="color-coded-list">
            {colorCodes.map((colorCode) => (
              <li key={colorCode.color}>
                <span>{colorCode.label}</span>
                <StatusCircle color={colorCode.color} size="large" />
              </li>
            ))}
          </ul>
        </Tooltip>
      </div>
    </header>
  );
}

Header.propTypes = {
  timelineView: PropTypes.bool.isRequired,
  setTimelineView: PropTypes.func.isRequired,
  subViewOption: PropTypes.object.isRequired,
  subViewOptions: PropTypes.array.isRequired,
  setSubViewOption: PropTypes.func.isRequired,
};

export default Header;
