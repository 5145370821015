import { getAgingColumns, getAgingRows } from "./aging-statement";
import { getCustomerAging } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const agingFilters = {
  BuildingNumber: "*",
  TenantNumber: "*",
  TenantId: "*",
  ComponentNumber: "*",
};

const agingFilterOptions = [
  { key: "buildingNumber", apiKey: "BuildingNumber" },
  { key: "tenantNumber", apiKey: "TenantNumber" },
  { key: "tenantId", apiKey: "TenantId", keyType: "label" },
  { key: "componentNumber", apiKey: "ComponentNumber" }
];

export default function RentRollReport({ activeCompany }) {
  return (
    <DynamicReport
      reportName="customer-aging"
      activeCompany={activeCompany}
      columnsDataProvider={getAgingColumns}
      rowsDataProvider={getAgingRows}
      fetchData={getCustomerAging}
      exportUrl="reports/export-query-report/customer-aging"
      filtersInitialState={agingFilters}
      filterOptions={agingFilterOptions}
      orderBy="TenantName"
    />
  );
}
