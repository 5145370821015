import { kebabCase } from "lodash";
import { useSelector } from "react-redux";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { selectActiveApp } from "@/store/appSlice";
import DynamicObjectOverview, { defaultComponents } from "@/pages/dynamic/list-views/DynamicObjectOverview";

function HeaderLeftContent() {
  return (
    <defaultComponents.HeaderLeftContent title="Technician Pool" />
  );
}

function TechnicianOverview() {
  const activeApp = useSelector(selectActiveApp);

  return (
    <DynamicObjectOverview
      objectName={dynamicObjectMap.get("EmployeeObjectName")}
      components={{
        HeaderLeftContent
      }}
      navigationLink={`/${kebabCase(activeApp?.value)}/technician/details`}
      filters={{ Designation: "Technician" }}
      showFilters
    />
  );
}

export default TechnicianOverview;
