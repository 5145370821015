import { getTenantOutstandingColumns, getTenantOutstandingRows } from "./tenant-outstanding-statement";
import { getTenantOutstanding } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const tenantOutstandingFilters = {
  TenantName: "*",
  TenantNumber: "*",
  InvoiceNumber: "*",
};

const tenantOutstandingFilterOptions = [
  { key: "tenantName", apiKey: "TenantName", keyType: "label" },
  { key: "tenantNumber", apiKey: "TenantNumber", keyType: "string" },
  { key: "invoiceNumber", apiKey: "InvoiceNumber", keyType: "string" },
];

export default function ReportTenantOutstanding({ activeCompany }) {
  return (
    <DynamicReport
      reportName="tenant-outstanding"
      activeCompany={activeCompany}
      columnsDataProvider={getTenantOutstandingColumns}
      rowsDataProvider={getTenantOutstandingRows}
      fetchData={getTenantOutstanding}
      exportUrl="reports/export-query-report/tenant-outstanding"
      filtersInitialState={tenantOutstandingFilters}
      filterOptions={tenantOutstandingFilterOptions}
      orderBy="TenantNumber"
      isPaginated={false}
    />
  );
}
