import {
  useReducer, useEffect, useCallback, useRef, useMemo,
  useState
} from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Input } from "@hydra/atom/components";
import { kebabCase, range } from "lodash";
import pluralize from "pluralize";
import qs from "qs";
import { useModal, useScrollToTop, useSaveBulkMutation } from "@/hooks";
import { getCompany } from "@/api/user/authApi";
import showToast from "@/utils/toast/helpers";

import {
  BoxedContent, Header, FormLeftHeader, CheckboxWithIcon
} from "@/components/common";
import {
  buildingFormReducer,
  initialState,
  selectActiveStepIndex,
  setStep,
  setFormValue,
  completeStep,
  resetForm,
  blockInitialState,
  floorInitialState,
  amenityInitialState,
  facilityInitialState,
  syncStateWithParams,
} from "@/reducers/leasing/buildingFormReducer";
import { AlertModal } from "@/components/modals";
import DynamicObjectOverview from "@/pages/dynamic/list-views/DynamicObjectOverview";
import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";

import { WidgetSteps, WidgetDropdown } from "@/components/leasing";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { selectActiveApp } from "@/store/appSlice";
import { getFullName } from "@/utils/helpers";

const validationMessages = {
  required: "This field is required",
};

const steps = [0, 1, 2, 3, 4];

const getActiveStepObjectName = (state) => {
  switch (selectActiveStepIndex(state)) {
    case 0:
      return dynamicObjectMap.get("BuildingObjectName");

    case 1:
      return dynamicObjectMap.get("ComponentObjectName");

    case 2:
      return dynamicObjectMap.get("FloorObjectName");

    case 3:
      return dynamicObjectMap.get("AmenityObjectName");

    case 4:
      return dynamicObjectMap.get("FacilityObjectName");

    default:
      break;
  }
};

function isAddBuildingCompleted(formSteps, activeStepIndex) {
  return formSteps[0].isCompleted && activeStepIndex === 0;
}

const renderBuildingAdditionalFields = (state, dispatch, activeStepIndex) => (
  <div className="row">
    <div className="col-md-3">
      <FormLeftHeader
        title="Components"
        subtitle="Does building have components?"
        icon="check-box-icon"
      />
    </div>
    <div className="col-md-9">
      <div className="row">
        <div className="col-md-6">
          <CheckboxWithIcon
            id="hasBlocks"
            name="hasBlocks"
            disabled={isAddBuildingCompleted(state.steps, activeStepIndex)}
            className={
              isAddBuildingCompleted(state.steps, activeStepIndex) &&
              "checkbox-option-container-disabled"
            }
            label="Does building have components?"
            value={state.hasBlocks}
            onChange={() => {
              dispatch(setFormValue("hasBlocks", !state.hasBlocks));
              dispatch(setFormValue("floors", ""));
            }}
          />
        </div>
      </div>
    </div>
    <hr className="full-hr" />
  </div>
);

const renderFloorField = (state, dispatch, activeStepIndex) => (
  <div className="row">
    <div className="col-md-3">
      <FormLeftHeader title="Floors" icon="check-box-icon" />
    </div>
    <div className="col-md-9">
      <div className="row">
        <div className="col-md-2">
          <Input
            type="number"
            name="floor"
            id="floor"
            disabled={isAddBuildingCompleted(state.steps, activeStepIndex)}
            value={state.floors}
            onChange={(value) => dispatch(setFormValue("floors", value))}
            min="0"
            rules="required"
            messages={validationMessages.required}
          />
        </div>
      </div>
    </div>

    <hr className="full-hr" />
  </div>
);

function BuildingForm() {
  const formRef = useRef(null);
  const queryClient = useQueryClient();
  const [state, dispatch] = useReducer(buildingFormReducer, initialState);
  const { isOpen, closeModal, openModal } = useModal(false);
  const { scrollToTopRef } = useScrollToTop(state.activeStep);
  const { saveBulkMutation: saveFloorsMutation } = useSaveBulkMutation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeApp = useSelector(selectActiveApp);
  const resetStateRef = useRef({
    step0: false,
    step1: false,
    step2: false,
    step3: false,
    step4: false
  });
  const { data: buildingData } = useQuery(
    [kebabCase(dynamicObjectMap.get("BuildingObjectName")), state.buildingId],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("BuildingObjectName"), state.buildingId),
    {
      enabled: Boolean(state.buildingId),
    }
  );

  const activeStepIndex = selectActiveStepIndex(state);
  const objectName = getActiveStepObjectName(state);

  const leftContent = (title, subtitle) => (
    <div className="container-header-left-content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );

  useEffect(() => {
    const buildingIdParam = searchParams.get("buildingId");
    const activeStepIndexParam = searchParams.get("activeStepIndex");
    const hasBlocksParam = searchParams.get("hasBlocks");

    if (buildingIdParam) {
      dispatch(
        syncStateWithParams({
          buildingId: buildingIdParam,
          activeStepIndex: Number(activeStepIndexParam),
          hasBlocks: hasBlocksParam === "true",
        })
      );
    }
  }, [searchParams.size]);

  const serializeState = () => {
    const { buildingId, hasBlocks } = state;

    if (buildingId) {
      setSearchParams(
        qs.stringify({
          buildingId,
          hasBlocks,
          activeStepIndex,
        })
      );
    }
  };

  useEffect(() => {
    serializeState();
  }, [objectName]);

  const initialData = useMemo(() => {
    if (!buildingData || objectName === dynamicObjectMap.get("BuildingObjectName")) {
      return null;
    }

    return {
      building: {
        label: buildingData?.name,
        value: buildingData?.id,
      },
    };
  }, [objectName, buildingData, state.loading]);

  const handleSubmit = useCallback(() => {
    if (state.loading) return;

    const submitButton = document.getElementById(
      `dynamic-form-submit-button-${kebabCase(objectName)}`
    );

    if (submitButton) {
      submitButton.click();
    }
  }, [activeStepIndex]);

  const handleSaveAndCreateNew = () => {
    if (state.loading) return;

    resetStateRef.current[`step${selectActiveStepIndex(state)}`] = true;
    handleSubmit();
  };

  const handleCompleteSetup = (moveNext = false) => {
    if (!moveNext) {
      const formState = formRef.current.getState();
      if ((formState?.facility || formState?.quantity) && (objectName === dynamicObjectMap.get("FacilityObjectName"))) {
        openModal();
        return;
      }
    }
    showToast(`${dynamicObjectMap.get("BuildingObjectName")} created`, "success");
    navigate(
      `/${kebabCase(activeApp?.value)}/${pluralize(
        kebabCase(dynamicObjectMap.get("BuildingObjectName"))
      )}`
    );
  };

  const handleNextStep = (moveNext = false) => {
    if (!moveNext) {
      const formState = formRef.current.getState();
      if (formState?.name && (objectName === dynamicObjectMap.get("FloorObjectName") || objectName === dynamicObjectMap.get("ComponentObjectName"))) {
        openModal();
        return;
      }
      if ((formState?.amenity || formState?.quantity) && objectName === dynamicObjectMap.get("AmenityObjectName")) {
        openModal();
        return;
      }
    }

    switch (selectActiveStepIndex(state)) {
      case 0: {
        dispatch(completeStep());
        if (state.hasBlocks) {
          dispatch(setStep(1));
        } else {
          dispatch(setStep(2));
        }
        break;
      }

      case 1: {
        if (resetStateRef.current[`step${selectActiveStepIndex(state)}`]) {
          dispatch(resetForm(blockInitialState));
          resetStateRef.current[`step${selectActiveStepIndex(state)}`] = false;
        } else {
          dispatch(completeStep());
          dispatch(setStep(2));
        }
        break;
      }

      case 2: {
        if (resetStateRef.current[`step${selectActiveStepIndex(state)}`]) {
          dispatch(resetForm(floorInitialState));
          resetStateRef.current[`step${selectActiveStepIndex(state)}`] = false;
        } else {
          dispatch(completeStep());
          dispatch(setStep(3));
        }
        break;
      }

      case 3: {
        if (resetStateRef.current[`step${selectActiveStepIndex(state)}`]) {
          dispatch(resetForm(amenityInitialState));
          resetStateRef.current[`step${selectActiveStepIndex(state)}`] = false;
        } else {
          dispatch(completeStep());
          dispatch(setStep(4));
        }
        break;
      }

      case 4: {
        if (resetStateRef.current[`step${selectActiveStepIndex(state)}`]) {
          dispatch(resetForm(facilityInitialState));
          dispatch(completeStep());
        }
        break;
      }

      default:
        break;
    }
  };

  const saveFloors = (responseId) => {
    let floorBuildingId;
    let componentId;

    switch (activeStepIndex) {
      case 0:
        floorBuildingId = responseId;
        break;

      case 1:
        floorBuildingId = state.buildingId;
        componentId = responseId;
        break;

      default:
        break;
    }
    const activeCompanyId = getCompany();
    const dataObjects = range(0, state.floors).map((value, index) => ({
      name: index === 0 ? "Ground Floor" : `Floor ${value}`,
      building: floorBuildingId,
      component: componentId ?? null,
      note: null,
      company: activeCompanyId,
      inactive: false,
    }));

    return saveFloorsMutation.mutateAsync({
      objectName: dynamicObjectMap.get("FloorObjectName"),
      data: dataObjects,
    });
  };

  const getCompletedStepId = useCallback(() => {
    switch (activeStepIndex) {
      case 0:
        return state.buildingId;

      default:
        return "";
    }
  }, [activeStepIndex]);

  const getHiddenFields = useCallback(() => {
    switch (objectName) {
      case dynamicObjectMap.get("FloorObjectName"):
        if (state.hasBlocks) {
          return ["Unit"];
        }

        return ["Component", "Unit"];

      case dynamicObjectMap.get("AmenityObjectName"):
      case dynamicObjectMap.get("FacilityObjectName"):
        if (state.hasBlocks) {
          return ["Unit"];
        }

        return ["Component", "Unit"];

      default:
        return [];
    }
  }, [objectName]);

  const getReadOnlyFields = useCallback(() => {
    switch (objectName) {
      case dynamicObjectMap.get("ComponentObjectName"):
      case dynamicObjectMap.get("FloorObjectName"):
      case dynamicObjectMap.get("AmenityObjectName"):
      case dynamicObjectMap.get("FacilityObjectName"):
        return ["building"];

      default:
        return [];
    }
  }, [objectName]);

  const getDropdownItems = useCallback(() => {
    switch (objectName) {
      case dynamicObjectMap.get("BuildingObjectName"):
        return [
          {
            title: "Save and move next",
            icon: "plus-icon",
            onClick: handleSubmit,
          },
        ];

      case dynamicObjectMap.get("ComponentObjectName"):
      case dynamicObjectMap.get("FloorObjectName"):
      case dynamicObjectMap.get("AmenityObjectName"):
        return [
          {
            title: "Save and create new",
            icon: "plus-icon",
            onClick: handleSaveAndCreateNew,
          },
          {
            title: "Save and move next",
            icon: "plus-icon",
            onClick: handleSubmit,
          },
          {
            title: "Move next",
            icon: "plus-icon",
            onClick: handleNextStep,
          },
        ];

      case dynamicObjectMap.get("FacilityObjectName"):
        return [
          {
            title: "Save and create new",
            icon: "plus-icon",
            onClick: handleSaveAndCreateNew,
          },
          {
            title: "Complete Setup",
            icon: "plus-icon",
            onClick: handleCompleteSetup,
          },
        ];

      default:
        return [];
    }
  }, [objectName]);

  const onSuccess = async (responseId) => {
    if (activeStepIndex) {
      queryClient.invalidateQueries({
        queryKey: [kebabCase(objectName)],
      });
    }

    if (objectName === dynamicObjectMap.get("BuildingObjectName")) {
      dispatch(setFormValue("buildingId", responseId));
    }

    try {
      if (state.floors && (!isAddBuildingCompleted(state.steps, activeStepIndex))) {
        if (activeStepIndex < 1) {
          const response = await saveFloors(responseId);
          if (response.status === 200) {
            handleNextStep(true);
          }
        } else {
          handleNextStep(true);
        }
      } else {
        handleNextStep(true);
      }
    } catch (error) {
      showToast("Could not save floors. Try again.", "error");
    } finally {
      dispatch(setFormValue("loading", false));
    }
  };

  const onError = () => {
    dispatch(setFormValue("loading", false));
  };

  const onClickStep = (index) => {
    if (state.buildingId) {
      if (index === 1 && !state.hasBlocks) {
        return;
      }

      dispatch(setStep(index));
    }
  };

  const onStateChange = (key, value) => {
    if (objectName === dynamicObjectMap.get("BuildingObjectName")) {
      if (key === "insuranceNo") {
        if (value) {
          const { supplier } = value;

          formRef.current.setFormValue("insuranceSupplier", getFullName(supplier));
        } else {
          formRef.current.setFormValue("insuranceSupplier", "");
        }
      }
      if (key === "isUniqueText") {
        dispatch(setFormValue("isUniqueText", value));
      }
    }
  };

  return (
    <BoxedContent>
      <AlertModal
        onClose={closeModal}
        isOpen={isOpen}
        icon="file-check-stroke-icon"
        title="Are you sure ?"
        subtitle="Do you want to move next without saving current entry?"
        onConfirm={() => {
          if (objectName === dynamicObjectMap.get("FacilityObjectName")) {
            handleCompleteSetup(true);
          } else handleNextStep(true);
          closeModal();
        }}
      />
      <div ref={scrollToTopRef}>
        <Header
          showBreadcrumb
          leftContent={leftContent(
            "Add Building",
            "Add blocks, floors and amenities for a building"
          )}
        />
        <WidgetSteps steps={state.steps} onClickStep={onClickStep} testId="Building-Tab" />

        {steps.map((step) =>
          (step === activeStepIndex ? (
            <DynamicFormContainer
              ref={formRef}
              objectName={objectName}
              initialData={initialData}
              setIsLoading={(loading) => dispatch(setFormValue("loading", loading))}
              onSuccess={onSuccess}
              onError={onError}
              selectedId={getCompletedStepId()}
              hiddenFields={getHiddenFields()}
              readOnlyFields={getReadOnlyFields()}
              showButtons={false}
              navigate={false}
              onStateChange={onStateChange}
            />
          ) : null)
        )}

        {objectName === dynamicObjectMap.get("BuildingObjectName") ?
          renderBuildingAdditionalFields(state, dispatch, activeStepIndex) :
          null}

        {objectName === dynamicObjectMap.get("BuildingObjectName") && !state.hasBlocks ?
          renderFloorField(state, dispatch, activeStepIndex) :
          null}

        <div className="buttons-container buttons-at-end">
          <WidgetDropdown
            label="Action"
            items={getDropdownItems()}
            isLoading={state.loading}
            isDisabled={
              Object.prototype.hasOwnProperty.call(state, "isUniqueText") && !state.isUniqueText
            }
            testId="Action-Dropdown"
          />
        </div>

        {objectName !== dynamicObjectMap.get("BuildingObjectName") && state.buildingId ? (
          <div className="col-md-12">
            <DynamicObjectOverview
              objectName={objectName}
              showTableOnly
              showActionCell={false}
              filters={{
                building: state.buildingId,
              }}
            />
            <hr className="full-hr" />
          </div>
        ) : null}
      </div>
    </BoxedContent>
  );
}

export default BuildingForm;
