import { estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getExpenseDetailsColumns() {

  const colArr = [
    {
      label: "Company",
      value: true,
      columnId: "company",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Expense",
      value: true,
      columnId: "expense",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Supplier",
      value: true,
      columnId: "supplier",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Supplier Name",
      value: true,
      columnId: "supplierName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Asset",
      value: true,
      columnId: "asset",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Expense Type",
      value: true,
      columnId: "expenseType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Account",
      value: true,
      columnId: "account",
      width: estimatedColumnWidth("account"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Rate",
      value: true,
      columnId: "rate",
      width: estimatedColumnWidth("account"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Quantity",
      value: true,
      columnId: "quantity",
      width: estimatedColumnWidth("account"),
      resizable: true,
      formatter: (value) => String(value || 0)
    },
    {
      label: "Amount",
      value: true,
      columnId: "amount",
      width: estimatedColumnWidth("account"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Tax",
      value: true,
      columnId: "tax",
      width: estimatedColumnWidth("account"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tax Amount",
      value: true,
      columnId: "taxAmount",
      width: estimatedColumnWidth("account"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Total Amount",
      value: true,
      columnId: "totalAmount",
      width: estimatedColumnWidth("account"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    }
  ];

  return colArr;
}
