import React from "react";
import { useLocation } from "react-router-dom";

// eslint-disable-next-line react/function-component-definition
const withRouter = (WrappedComponent) => (props) => {
  const location = useLocation();

  return (
    <WrappedComponent
      {...props}
      location={location}
    />
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location.pathname !== prevProps.location.pathname) {
      this.setState({
        hasError: false
      });
    }
  }

  render() {
    const { hasError } = this.state;
    const { fallback, children } = this.props;

    if (hasError) {
      return fallback;
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
