import { useRef, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { isBefore, isEqual } from "date-fns";
import { useSelector } from "react-redux";

import { kebabCase } from "lodash";
import { toast } from "react-hot-toast";
import { selectActiveCompany } from "@/store/appSlice";
import { BoxedContent } from "@/components/common";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { AlertModal } from "@/components/modals";
import { useModal } from "@/hooks";
import { getRevaluationAssetDetailTable } from "@/components/finance/account-receivables/tableWithCheckboxData";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords, getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import showToast from "@/utils/toast/helpers";

const filterAsset = (asset, date) => {
  const { purchaseDate, revaluationDate } = asset;

  const isPurchaseDateBeforeDate = isBefore(new Date(date), new Date(purchaseDate));
  const isPurchaseDateEqualToDate = isEqual(new Date(date), new Date(purchaseDate));
  if (isPurchaseDateBeforeDate || isPurchaseDateEqualToDate) {
    return false;
  }

  let isRevaluationDateBeforeDate = false;
  let isRevaluationDateEqualToDate = false;
  if (revaluationDate) {
    isRevaluationDateBeforeDate = isBefore(new Date(date), new Date(revaluationDate));
    isRevaluationDateEqualToDate = isEqual(new Date(date), new Date(revaluationDate));
  }

  if (isRevaluationDateBeforeDate || isRevaluationDateEqualToDate) {
    return false;
  }

  return true;
};

const formatAsset = (item, company, date = new Date(), transactionDate = new Date()) => ({
  company: {
    label: company.name,
    value: company.id
  },
  skip: false,
  asset: {
    label: item.itemName,
    value: item.id,
  },
  isSelected: true,
  key: item.id,
  previousValue: item.bookValue,
  revaluedValue: item.bookValue,
  description: `Revaluation for ${item.number}`,
  revaluationDate: new Date(date),
  transactionDate: new Date(transactionDate),
});

const formatAssets = (data, company, date, transactionDate = new Date()) => {
  const items = data
    .filter((i) => filterAsset(i, date))
    .map((item) => formatAsset(item, company, date, transactionDate));

  return {
    assetDetail: items,
  };
};

function RevaluationForm() {
  const activeCompany = useSelector(selectActiveCompany);
  const ref = useRef(null);
  const [searchParams] = useSearchParams();
  const [state, setState] = useState({});
  const [assetDetailData, setAssetDetailData] = useState({});
  const { isOpen, closeModal, openModal } = useModal(false);
  const item = searchParams.get("item");
  const [toastId, setToastId] = useState("");

  const { data: fixedAsset } = useQuery(
    [kebabCase(dynamicObjectMap.get("ItemObjectName")), item],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("ItemObjectName"), item),
    {
      enabled: Boolean(item),
    }
  );

  useEffect(() => {
    if (fixedAsset) {
      const assetDetail = [formatAsset(fixedAsset, activeCompany)];
      ref.current.setFormValue("assetDetail", assetDetail);
    }
  }, [fixedAsset]);

  const { data: assetData, isLoading } = useQuery(
    [kebabCase(dynamicObjectMap.get("ItemObjectName")), "Active"],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("ItemObjectName"), {
        sortBy: "CreatedAt",
        sortType: "DESC",
        status: "Active",
        itemType: "Asset",
        isDepreciated: true,
      }),
    {
      enabled: Boolean(!item),
    }
  );

  const setAssetDetail = async () => {
    const formState = ref.current.getState();
    const { date } = formState;

    const { assetDetail } = await formatAssets(assetData?.data, activeCompany, date);

    if (!assetDetail?.length) {
      toast.dismiss(toastId);
      showToast("No assets found for the given date range!", "info");
    } else {
      toast.dismiss(toastId);
      showToast("Assets loaded!", "success");
    }

    setAssetDetailData(assetDetail);
    openModal();

  };

  useEffect(() => {
    if (!state?.date) return;
    if (isLoading) {
      const tId = toast.loading("Loading assets...");
      setToastId(tId);
    }
    if (assetData && assetData.data) {
      setAssetDetail();
    }
  }, [state?.date, assetData?.data]);

  const onStateChange = (key, value) => {
    switch (key) {
      case "date":
      case "transactionDate": {
        setState((prevState) => ({
          ...prevState,
          [key]: value,
        }));
        break;
      }

      default:
        break;
    }
  };

  const handleConfirm = () => {
    if (assetDetailData.length) {
      const selectedAssets = assetDetailData.filter((q) => q.isSelected);

      if (selectedAssets) {
        const totalOpenAssetsAmount = selectedAssets
          .filter((i) => !i.skip)
          .reduce((prevValue, { bookValue }) => prevValue + bookValue, 0);
        ref.current.setFormState({
          totalOpenAssetsAmount,
          assetDetail: selectedAssets,
        });
      }
    }

    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Assets"
        subtitle="Selected date range has following active assets"
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        size="large"
      >
        {assetDetailData.length ? (
          <TableWithCheckbox
            data={assetDetailData}
            searchKey={["asset.label"]}
            columns={getRevaluationAssetDetailTable()}
            setData={setAssetDetailData}
            selectAll
            allowMultiple
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("RevaluationObjectName")}
        showHeader
        onStateChange={onStateChange}
      />
    </BoxedContent>
  );
}

export default RevaluationForm;
