import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";
import { useMutation } from "@tanstack/react-query";
import { camelize, pascalize } from "humps";
import { Form, Button } from "@hydra/atom/components";
import { startCase } from "lodash";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";

import { isValid } from "date-fns";
import { AlertModal } from "@/components/modals";
import { formatDate, getTestId } from "@/utils/helpers";
import { useModal } from "@/hooks";
import {
  FormLeftHeader, Accordion, Header, IconButton, SvgIcon, HeaderLeftContent
} from "@/components/common";
import { Pill } from "@/components/dashboard";

import { sectionTypeIcons } from "@/utils/dynamic/constants";

import {
  prepareFieldValue,
  renderField,
  getFieldColumnClassName,
  checkForEmptyFieldValues,
  prepareDynamicObjectInitialState,
  prepareDynamicObjectDefaultValues,
  validateFields,
  extractStringFromBracketsAndDots,
  checkRules,
} from "@/utils/dynamic/helpers";

import {
  createDynamicObjectRecord,
  updateDynamicObjectRecord,
  deleteDynamicObjectRecord,
  uploadAttachmentWithDynamicObjectRecord,
} from "@/api/dynamic/dynamicObjectNameApi";

import intermediateObjectFieldNamesMap from "@/utils/maps/intermediateObjectFieldNamesMap";
import { getRelationalFieldData, getRelationalFieldColumns } from "./relationalFieldData";
import showToast from "@/utils/toast/helpers";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { selectActiveCompany } from "@/store/appSlice";

function AddRecordRow({
  field,
  fieldsAccessKey,
  state,
  handleStateChange,
  parentState,
  handleSave,
  disabled,
  errors,
  relationalFieldState,
  parentId,
  testId,
}) {
  return (
    <tr className="table-row" key="add-row">
      {field[fieldsAccessKey]
        .filter((f) => {
          if (f.hidden) {
            return false;
          }

          if (!checkRules(f, state, true, parentState)) {
            return false;
          }

          if (f.name === "transferStatus") {
            return false;
          }

          return true;
        })
        .map((childField, index) => (
          <td key={`${childField.name}-${index}`} className="table-body-cell">
            {renderField({
              field: childField,
              parentObjectName: field.intermediateObjectName,
              parentFieldName: field.name,
              state,
              errors,
              onChange: handleStateChange,
              showLabel: false,
              parentState,
              isChildField: true,
              relationalFieldState,
              isDisabled: disabled,
              parentId,
            })}
          </td>
        ))}
      {pascalize(field.intermediateObjectName) === dynamicObjectMap.get("PettyCashClaimTransactionObjectName") ? <td aria-label="Attachments" /> : null}
      <td className="table-body-cell collapse">
        <button
          type="button"
          className="add-action-btn"
          onClick={handleSave}
          disabled={disabled}
          data-testid={getTestId(`${testId}-Save-Button`)}
        >
          <span className="material-icons-outlined">add</span>
        </button>
      </td>
    </tr>
  );
}

AddRecordRow.propTypes = {
  field: PropTypes.object.isRequired,
  fieldsAccessKey: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  relationalFieldState: PropTypes.array.isRequired,
  handleStateChange: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  parentId: PropTypes.string,
};

AddRecordRow.defaultProps = {
  parentId: "",
};

function ErrorMessages({ errors }) {
  if (!errors.length) {
    return null;
  }

  return (
    <div className="error-messages-container">
      {errors.map((e, index) => (
        <p key={`error-${index}`} className="error-text">
          {e}
        </p>
      ))}
    </div>
  );
}

ErrorMessages.propTypes = {
  errors: PropTypes.array.isRequired,
};

function RelationalFieldForm({
  field,
  fieldsAccessKey,
  state,
  handleStateChange,
  value,
  disabled,
  parentState,
  parentId,
  handleSave,
  isLoading,
  fieldLayout,
  resetState,
  showFormOnly,
  showFormInModal,
  testId,
}) {
  const renderElement = ({ element, row }, index) => {
    switch (element.dataType) {
      case "SectionElement": {
        const sectionFieldNames = [];
        element.elements.forEach((e) =>
          e.elements.forEach((item) => sectionFieldNames.push(camelize(item.name)))
        );
        const sectionFields = field[fieldsAccessKey].filter((item) =>
          sectionFieldNames.includes(item.name)
        );
        if (sectionFields.length) {
          const isSectionVisible = sectionFields.some((item) =>
            checkRules(item, state, true, parentState)
          );
          if (!isSectionVisible) {
            return null;
          }
        }

        return (
          <React.Fragment key={`${element.dataType}-${element.title}`}>
            <div className="row">
              <div className="col-md-3">
                <FormLeftHeader
                  title={element.title}
                  subtitle={element.subTitle}
                  icon={sectionTypeIcons(index)}
                />
              </div>
              <div className="col-md-9">
                {element.elements.map((child) => renderElement({ element: child }, index))}
              </div>
            </div>
            <hr className="full-hr" />
          </React.Fragment>
        );
      }

      case "RowElement":
        return (
          <div className="row">
            {element.elements.map((child) =>
              renderElement({ element: child, row: element }, index)
            )}
          </div>
        );

      case "FieldElement": {
        const childField = field[fieldsAccessKey].find((f) => f.name === camelize(element.name));

        if (!checkRules(childField, state, true, parentState)) {
          return null;
        }

        return (
          <div
            key={`${element.dataType}-${element.name}`}
            className={getFieldColumnClassName({
              field: childField,
              fieldLayout: element,
              row,
            })}
          >
            {renderField({
              field: childField,
              parentObjectName: field.intermediateObjectName,
              state,
              onChange: handleStateChange,
              isChildField: true,
              parentState,
              relationalFieldState: value,
              isDisabled: disabled,
              parentId,
              childParentId: state.id,
              isInsideModal: showFormInModal,
            })}
          </div>
        );
      }

      default:
        return <div key={`${element.dataType}-${element.name}`}>{element.title}</div>;
    }
  };

  return (
    <Form onSubmit={handleSave} className="relational-field-form">
      <Header
        leftContent={<HeaderLeftContent title={startCase(field.label)} />}
        rightContent={
          state.isEditing ? (
            <div className="buttons-at-end" style={{ display: showFormOnly ? "none" : "flex" }}>
              <Button
                small
                bordered
                onClick={resetState}
                testId={getTestId(`${testId}-Cancel-Button`)}
              >
                Cancel
              </Button>
              <Button
                className={`${field.name}-submit-button`}
                small
                type="submit"
                loading={isLoading}
              >
                Update
              </Button>
            </div>
          ) : (
            <div className="buttons-at-end" style={{ display: showFormOnly ? "none" : "flex" }}>
              <Button
                className={`${field.name}-submit-button`}
                small
                type="submit"
                loading={isLoading}
                testId={getTestId(`${testId}-Submit-Button`)}
              >
                Save
              </Button>
            </div>
          )
        }
      />

      {fieldLayout.elements.map((element, index) => renderElement({ element }, index))}
    </Form>
  );
}

function RelationalFieldTable({
  isFormLayout,
  field,
  fieldsAccessKey,
  state,
  handleStateChange,
  value,
  disabled,
  parentId,
  handleSave,
  parentState,
  handleEdit,
  setSelectedIndex,
  handleUpdateValue,
  handleUpdate,
  handleDelete,
  openModal,
  closeModal,
  isOpen,
  onChildFieldBlur,
  testId,
  errors,
  handleAdd,
  showFormInModal,
  childRowStatus,
}) {

  const headers = field?.fields?.map((item) => {
    let header = {};
    switch (item.objectFieldType.toLowerCase()) {
      case "attachment":
        break;

      case "lookup":
      case "polymorphic":
        header = {
          label: item.label || startCase(item.name),
          key: `${camelize(item.name)}.label`
        };
        break;
      default:
        header = {
          label: item.label || startCase(item.name),
          key: camelize(item.name)
        };
        break;
    }
    return header;
  });

  const tableData = useMemo(() => getRelationalFieldData(value, field), [value]);
  const tableColumns = useMemo(() => getRelationalFieldColumns(field, parentState, state),
    [parentState, state]);
  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } = useTable({
    columns: tableColumns,
    data: tableData,
    onDelete: (id) => {
      openModal();
      setSelectedIndex(id);
    },
    onEdit: handleEdit,
    onUpdate: handleUpdateValue,
    onBlur: onChildFieldBlur,
    onSave: handleUpdate,
    fieldName: field.name,
    relationalFieldState: value,
    disabled,
    parentId,
    testId,
    errors: childRowStatus ? errors : [],
  });

  const csvObjects = ["revenueRecognitionDetail", "assetDetail", "expenseRecognitionDetail", "paymentDetail", "transaction"];

  function formatDates(obj) {
    const newObj = {};

    Object.keys(obj).forEach((key) => {
      if (key.toLowerCase().includes("dateformat") || key.toLowerCase().includes("updatesequence")) {
        newObj[key] = obj[key];
        return;
      }
      if (key.toLowerCase().includes("date") && isValid(obj[key])) {
        newObj[key] = formatDate(new Date(obj[key]));
      } else {
        newObj[key] = obj[key];
      }
    });
    return newObj;
  }

  function formatDatesInArray(dataArray) {
    const newObjArr = dataArray.map((obj) => formatDates(obj));
    return newObjArr;
  }
  const renderAddRecordRow = () => {
    if (field.intermediateObjectName !== "PettyCashClaim_Transaction") {
      return (
        <AddRecordRow
          field={field}
          parentState={parentState}
          fieldsAccessKey={fieldsAccessKey}
          state={state}
          handleStateChange={handleStateChange}
          handleSave={handleSave}
          relationalFieldState={value}
          disabled={disabled}
          errors={childRowStatus ? [] : errors}
          parentId={parentId}
          testId={getTestId(testId)}
        />
      );
    }
    return "";
  };

  return (
    <div id={`${field.name}-container`} className="table-wrapper relational-field">
      <div className="filters-row table-view-filters-row">
        <div className="left-content">
          <p className="headline me-2">{startCase(field.label)}</p>
          {value && value.length > 0 && <Pill text={value.length} variant="blue" />}
        </div>
        {showFormInModal && (
          <div className="right-content">
            <Button
              small
              bordered
              onClick={handleAdd}
              testId={getTestId(`${pascalize(field.name)}-Add-Button`)}
            >
              Add
            </Button>
          </div>
        )}
        {csvObjects.includes(field.camelizedName) && (
          <CSVLink data={formatDatesInArray(value)} headers={headers}>
            <div className="btn-icon-container">
              <IconButton
                className="icon-button-with-text"
                onClick={() => {}}
              >
                <SvgIcon icon="excel" />
                <span>Download Excel</span>
              </IconButton>
            </div>
          </CSVLink>
        )}
      </div>
      <div className="overflow">
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr className="table-header-row" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={`table-header-cell ${column.collapse ? "collapse" : ""}`}
                    {...column.getHeaderProps()}
                  >
                    <div className="table-header-cell-content">
                      <span>{column.render("Header")}</span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!isFormLayout && (
              renderAddRecordRow()
            )}
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr className="table-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      className={`table-body-cell ${cell.column.collapse ? "collapse" : ""}`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <AlertModal onClose={closeModal} isOpen={isOpen} onConfirm={handleDelete} />
    </div>
  );
}

export default function RelationalField({
  value,
  onChange,
  field,
  parentId,
  parentState,
  disabled,
  showFormOnly,
  showTableOnly,
  fieldLayout,
  objectData,
  onChildStateChange,
  onChildFieldBlur,
  testId,
  showFormInModal,
  updateTableDataOnSubmit = false
}) {
  const activeCompany = useSelector(selectActiveCompany);
  const fieldsAccessKey = "fields";
  const initialState = useMemo(() => prepareDynamicObjectInitialState(field[fieldsAccessKey]));
  const defaultValues = useMemo(() => prepareDynamicObjectDefaultValues(
    field[fieldsAccessKey]));
  const [childRowStatus, setChildRowStatus] = useState(false);
  const stateKey = `${field.name}${field.objectFieldType}`;
  const state = parentState[stateKey] ?? initialState;

  const [errors, setErrors] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { isOpen, closeModal, openModal } = useModal(false);

  const resetState = () => {
    onChange(stateKey, {
      ...initialState,
      ...defaultValues,
    });
    setErrors([]);
  };

  // Note: passing un-used variables to use in onSuccess handler
  const saveMutation = useMutation(
    // eslint-disable-next-line no-unused-vars
    ({ dynamicObjectName, dataObject, stateValue }) =>
      createDynamicObjectRecord(dynamicObjectName, dataObject),
    {
      onSuccess: (response, variables) => {
        const { id } = response;
        const { stateValue } = variables;
        stateValue.id = id;
        const childAttachmentFields = field[fieldsAccessKey].filter(
          (f) => f.objectFieldType.toLowerCase() === "attachment"
        );

        if (childAttachmentFields.length) {
          childAttachmentFields.forEach((childAttachmentField) => {
            uploadAttachmentWithDynamicObjectRecord({
              recordId: id,
              attachmentFieldName: childAttachmentField.name,
              files: stateValue[childAttachmentField.name],
              objectName: field.intermediateObjectName,
            });
          });
        }
        onChange(field.name, [...value, stateValue]);
        resetState();
      },
    }
  );

  const updateMutation = useMutation(({ recordId, dataObject, objectName }) =>
    updateDynamicObjectRecord(objectName, recordId, dataObject)
  );

  const deleteMutation = useMutation(
    ({ key, objectName }) => deleteDynamicObjectRecord(objectName, key),
    {
      onError: () => {
        showToast("Could not delete dynamic object record. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Deleted successfully", "success");
      },
    }
  );

  const isEditing = Boolean(parentId);
  const isFormLayout = Boolean(fieldLayout);

  const handleStateChange = (key, val) => {
    onChildStateChange({
      key,
      value: val,
      parentField: field?.camelizedName,
      parentFieldType: field?.objectFieldType,
    });

    const newState = {
      ...state,
      [key]: val,
    };

    onChange(stateKey, newState);

    if (!isFormLayout && errors.length) {
      const validationErrors = validateFields(field[fieldsAccessKey], newState);
      setErrors(validationErrors);
    }
  };

  const saveRecord = () => {
    const rowValue = { ...state };

    const dataObject = {
      [intermediateObjectFieldNamesMap.get("DetailIdFieldName")]: parentId,
    };

    field.fields.forEach((f) => {
      dataObject[f.name] = prepareFieldValue(f, state);
    });

    dataObject.company = activeCompany.id;

    saveMutation.mutate({
      dynamicObjectName: field.intermediateObjectName,
      dataObject,
      stateValue: rowValue,
    });
  };

  const updateRecord = ({ index, key, newValue }) => {
    const values = [...value];
    values[index][key] = newValue;
    onChange(field.name, values);

    if (!isFormLayout) {
      const validationErrors = validateFields(field[fieldsAccessKey], values[index]);
      setErrors(validationErrors);
    }
    if (key === "isEditing") {
      setChildRowStatus(true);
    }
    if (key !== "isEditing") {
      setChildRowStatus(true);
    }
  };

  const deleteRecord = (deletedIndex) => {
    onChange(
      field.name,
      [...value].filter((_, index) => index !== deletedIndex)
    );
  };

  const handleUpdateValue = (index, fieldKey, fieldValue) => {
    onChildStateChange({
      index,
      key: fieldKey,
      value: fieldValue,
      parentField: field?.camelizedName,
      fieldType: field?.objectFieldType,
    });

    updateRecord({
      index,
      key: fieldKey,
      newValue: fieldValue,
    });
  };

  const handleUpdate = (index) => {
    let rowValue = {};
    if (!isFormLayout) {
      rowValue = { ...value[index] };
    } else {
      rowValue = { ...state };
    }

    if (!checkForEmptyFieldValues(field[fieldsAccessKey], rowValue)) {
      return;
    }

    if (!isFormLayout) {
      const validationErrors = validateFields(field[fieldsAccessKey], rowValue);

      if (validationErrors.length) {
        setChildRowStatus(true);
        setErrors(validationErrors);
        return;
      }

      setErrors([]);
    }

    if (isEditing) {
      const dataObject = {
        [intermediateObjectFieldNamesMap.get("DetailIdFieldName")]: parentId,
      };

      field[fieldsAccessKey].forEach((f) => {
        dataObject[f.name] = prepareFieldValue(f, rowValue, true);
      });

      if (updateTableDataOnSubmit) {
        const prevUpdatedData = { ...parentState.updatedTableData };
        const tableKey = camelize(`${field.intermediateObjectName}TableData`);
        const prevTableData = prevUpdatedData[tableKey] || [];

        const newData = {
          recordId: rowValue.id,
          objectName: field.intermediateObjectName,
          dataObject,
        };

        const existingIndex = prevTableData.findIndex((item) => item.recordId === rowValue.id);

        if (existingIndex >= 0) {
          prevTableData[existingIndex] = newData;
        } else {
          prevTableData.push(newData);
        }

        prevUpdatedData[tableKey] = prevTableData;

        onChange("updatedTableData", prevUpdatedData);
      } else {
        updateMutation.mutate({
          recordId: rowValue.id,
          objectName: field.intermediateObjectName,
          dataObject,
        });
      }
    }

    if (!isFormLayout) {
      updateRecord({
        index,
        key: "isEditing",
        newValue: false,
      });
    } else {
      const updatedValues = value.map((v, i) => {
        if (i === index) {
          return {
            ...state,
            isEditing: false,
          };
        }

        return v;
      });

      onChange(field.name, updatedValues);
      resetState();
    }
  };

  const handleSave = () => {
    if (!checkForEmptyFieldValues(field[fieldsAccessKey], state)) {
      return;
    }

    if (!isFormLayout) {
      const validationErrors = validateFields(field[fieldsAccessKey], state);

      if (validationErrors.length) {
        setChildRowStatus(false);
        setErrors(validationErrors);
        return;
      }

      setErrors([]);
    }
    if (isFormLayout && state.isEditing) {
      handleUpdate(state.index);
      return;
    }

    if (isEditing) {
      saveRecord();
      return;
    }
    onChange(field.name, [...value, { ...state }]);
    resetState();
  };

  const handleEdit = (index) => {
    if (field.actions && field.actions.length) {
      const editAction = field.actions.find((a) => a.name === "Edit");

      if (editAction) {
        const { actionName } = editAction;
        const { left, right } = extractStringFromBracketsAndDots(actionName);

        if (left === "Action") {
          const formAction = objectData?.actions?.find((a) => a.name === right);
          const modalName = extractStringFromBracketsAndDots(formAction.modal);
          const selectedRow = value[index];

          const layoutForm = objectData.layout.forms.find(
            (item) => item.name === selectedRow[camelize(modalName)]?.label
          );

          if (layoutForm) {
            onChange(stateKey, {
              ...selectedRow,
              index,
              isEditing: true,
            });
            onChange("dynamicPopupLayout", layoutForm);
            onChange("isDynamicPopupModalOpen", true);
            return;
          }
        }
      }
    }

    if (isFormLayout && showFormInModal) {
      const selectedRow = value[index];

      onChange(stateKey, {
        ...selectedRow,
        index,
        isEditing: true,
      });
      onChange("dynamicPopupLayout", { elements: [fieldLayout] });
      onChange("isDynamicPopupModalOpen", true);
      return;
    }

    if (!isFormLayout) {
      updateRecord({
        index,
        key: "isEditing",
        newValue: true,
      });
    } else {
      const selectedRow = value[index];

      onChange(stateKey, {
        ...selectedRow,
        index,
        isEditing: true,
      });
    }
  };

  const handleDelete = () => {
    if (isEditing) {
      deleteMutation.mutate({
        objectName: field.intermediateObjectName,
        key: value[selectedIndex].id,
      });
    }

    deleteRecord(selectedIndex);
    setSelectedIndex(null);
    closeModal();
  };

  const handleAdd = () => {
    onChange("dynamicPopupLayout", { elements: [fieldLayout] });
    onChange("isDynamicPopupModalOpen", true);

    // Note: Allow user to accept multiple payment methods for a single payment
    if (field.name === "outstandingTransaction") {
      const paymentWithOpenBalance = value.find((p) => p.openBalance && p.payment);
      let outstandingAmount = paymentWithOpenBalance.openBalance;
      let amountPaid = 0;

      if (paymentWithOpenBalance) {
        const allPayments = value.filter(
          (p) => p.paymentDetailId === paymentWithOpenBalance.paymentDetailId
        );

        if (allPayments.length) {
          const totalPaidAmount = allPayments.reduce(
            (prevValue, { payment }) => prevValue + Number(payment),
            0
          );

          outstandingAmount = paymentWithOpenBalance.originalAmount - totalPaidAmount;
          amountPaid = totalPaidAmount;

          if (!outstandingAmount) {
            return;
          }
        }

        const outstandingPayment = { ...paymentWithOpenBalance };
        outstandingPayment.openBalance = outstandingAmount;
        outstandingPayment.payment = outstandingPayment.openBalance;
        outstandingPayment.openBalance = 0;
        outstandingPayment.amountPaid = amountPaid;
        outstandingPayment.description = `Remaining payment of ${outstandingPayment.description}`;
        outstandingPayment.chequeNo = "";
        outstandingPayment.creditCardNo = "";
        outstandingPayment.creditCardExpiryDate = "";
        outstandingPayment.chequeNo = "";
        outstandingPayment.bank = null;
        onChange(stateKey, outstandingPayment);
      }
    }
  };

  if (showFormOnly) {
    return (
      <RelationalFieldForm
        field={field}
        fieldsAccessKey={fieldsAccessKey}
        state={state}
        handleStateChange={handleStateChange}
        value={value}
        parentState={parentState}
        disabled={disabled}
        parentId={parentId}
        handleSave={handleSave}
        isLoading={saveMutation.isLoading || updateMutation.isLoading}
        fieldLayout={fieldLayout}
        resetState={resetState}
        showFormOnly={showFormOnly}
        showFormInModal={showFormInModal}
        testId={getTestId(testId)}
      />
    );
  }

  if (field.name === "document" || showTableOnly || showFormInModal) {
    return (
      <form className="mb-4">
        <RelationalFieldTable
          isFormLayout
          field={field}
          parentState={parentState}
          fieldsAccessKey={fieldsAccessKey}
          state={state}
          handleStateChange={handleStateChange}
          onChildFieldBlur={onChildFieldBlur}
          value={value}
          disabled={disabled}
          parentId={parentId}
          handleSave={handleSave}
          handleEdit={handleEdit}
          setSelectedIndex={setSelectedIndex}
          handleUpdateValue={handleUpdateValue}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          openModal={openModal}
          closeModal={closeModal}
          isOpen={isOpen}
          testId={getTestId(testId)}
          handleAdd={handleAdd}
          showFormInModal={showFormInModal}
          childRowStatus={childRowStatus}
        />
      </form>
    );
  }

  if (isFormLayout) {
    return (
      <>
        <Accordion key={field.name} title={startCase(field.label)} count={value.length}>
          <RelationalFieldForm
            field={field}
            fieldsAccessKey={fieldsAccessKey}
            state={state}
            handleStateChange={handleStateChange}
            value={value}
            parentState={parentState}
            disabled={disabled}
            parentId={parentId}
            handleSave={handleSave}
            isLoading={saveMutation.isLoading || updateMutation.isLoading}
            fieldLayout={fieldLayout}
            resetState={resetState}
            showFormOnly={showFormOnly}
            testId={getTestId(testId)}
          />
          <RelationalFieldTable
            isFormLayout={isFormLayout}
            field={field}
            parentState={parentState}
            fieldsAccessKey={fieldsAccessKey}
            state={state}
            handleStateChange={handleStateChange}
            onChildFieldBlur={onChildFieldBlur}
            value={value}
            disabled={disabled}
            parentId={parentId}
            handleSave={handleSave}
            handleEdit={handleEdit}
            setSelectedIndex={setSelectedIndex}
            handleUpdateValue={handleUpdateValue}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            openModal={openModal}
            closeModal={closeModal}
            isOpen={isOpen}
            testId={getTestId(testId)}
            handleAdd={handleAdd}
            showFormInModal={showFormInModal}
            childRowStatus={childRowStatus}
          />
        </Accordion>
        <hr className="full-hr" />
      </>
    );
  }

  return (
    <form className="relational-field-form">
      <RelationalFieldTable
        isFormLayout={isFormLayout}
        field={field}
        fieldsAccessKey={fieldsAccessKey}
        state={state}
        parentState={parentState}
        handleStateChange={handleStateChange}
        onChildFieldBlur={onChildFieldBlur}
        value={value}
        disabled={disabled}
        parentId={parentId}
        handleSave={handleSave}
        handleEdit={handleEdit}
        setSelectedIndex={setSelectedIndex}
        handleUpdateValue={handleUpdateValue}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
        openModal={openModal}
        closeModal={closeModal}
        isOpen={isOpen}
        errors={errors}
        testId={getTestId(testId)}
        handleAdd={handleAdd}
        showFormInModal={showFormInModal}
        childRowStatus={childRowStatus}
      />
      <ErrorMessages errors={errors} />
    </form>
  );
}

RelationalField.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
  onChange: PropTypes.func,
  onChildStateChange: PropTypes.func,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  disabled: PropTypes.bool,
  showFormOnly: PropTypes.bool,
  showTableOnly: PropTypes.bool,
  fieldLayout: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  objectData: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  onChildFieldBlur: PropTypes.func,
  testId: PropTypes.string,
  showFormInModal: PropTypes.bool,
};

RelationalField.defaultProps = {
  value: [],
  parentId: null,
  onChange: () => {},
  onChildStateChange: () => {},
  disabled: false,
  showFormOnly: false,
  showTableOnly: false,
  fieldLayout: null,
  objectData: null,
  onChildFieldBlur: () => {},
  testId: "",
  showFormInModal: false,
};
