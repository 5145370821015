import parse from "date-fns/parse";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import ReactDOM from "react-dom";
import { Loader } from "@hydra/atom/components";

import startOfWeek from "date-fns/startOfWeek";
import { useEffect, useMemo, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { useNavigate } from "react-router-dom";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { NoDataFound, CustomizedDateRangePicker, Header, HeaderLeftContent } from "@/components/common";
import {
  VerticalEventCard,
  HorizontalEventCard,
  CalendarToolbar,
  GutterHeader,
  WeeklyEventCard,
  DayHeader,
  CalenderViewMoreSection,
} from "@/components/facility/planboard/calendar";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import CalendarDrawer from "@/components/facility/planboard/calendar/CalendarDrawer";
import { filterWorkOrderJob } from "@/utils/facility/helpers";

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: (date) => startOfWeek(date, { weekStartsOn: 1 }),
  getDay,
  locales: enUS,
});

const eventComponentHandler = (props) => {
  if ("isAllDay" in props) return <HorizontalEventCard {...props} />;
  return <VerticalEventCard {...props} />;
};

const weekEventComponentHandler = (props) => {
  const children =
    "isAllDay" in props ? <HorizontalEventCard {...props} /> : <WeeklyEventCard {...props} />;

  return children;
};

let counter = -1;

function CalendarView() {
  const [events, setEvents] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [drawerEvents, setDrawerEvents] = useState([]);
  const navigate = useNavigate();

  const { data: jobs, isInitialLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("WorkOrderJobObjectName")),
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("WorkOrderJobObjectName"), {
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
      })
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  function WeekHeader({ date }) {
    const { formattedDate, filterEvents } = useMemo(() => {
      const filterEventData = events.filter(
        (event) =>
          event.start.getDate() === date.getDate() && event.end.getDate() === date.getDate()
      );

      return {
        formattedDate: format(date, "EEEE, MMMM dd", { locale: enUS }),
        filterEvents: filterEventData,
      };
    }, [date]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const customHTML = `<div class="week-custom-header">
        <div class="label d-flex flex-column justify-content-center align-items-center"><h6 class="m-0 pe-5 ">All</h6><h6>Day Task</h6></div>
        <div>
        <div class="event-column p-2"></div>
        <div class="event-column p-2"></div>
        <div class="event-column p-2"></div>
        <div class="event-column p-2"></div>
        <div class="event-column p-2"></div>
        <div class="event-column p-2"></div>
        <div class="event-column p-2"></div>
        </div>
      </div>`;

      if (counter === -1) {
        const element = document.querySelector(".rbc-time-view .rbc-time-header");

        const elements = document.getElementsByClassName("rbc-row-segment");
        [...elements].forEach((ele) => {
          ele.setAttribute("onClick", "event.stopPropagation()");
        });

        if (element) {
          element.insertAdjacentHTML("afterend", "");
          element.insertAdjacentHTML("afterend", customHTML);
          counter += 1;
        }
      }

      if (filterEvents.length > 0) {
        const eventColumn = document.querySelectorAll(".event-column")[counter];
        if (eventColumn) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < filterEvents.length; i++) {
            if (i > 3) {
              const container = document.createElement("div");
              eventColumn.appendChild(container);
              ReactDOM.render(<CalenderViewMoreSection filterEvents={filterEvents} />, container);
              break;
            } else {
              const container = document.createElement("div");
              container.className = "mb-1";
              const cardElement = (
                <HorizontalEventCard event={filterEvents[i]} navigate={navigate} />
              );
              ReactDOM.render(cardElement, container);
              eventColumn.appendChild(container);
            }
          }
        }
      }
      counter += 1;

      return () => {
        counter = -1;
      };
    }, [formattedDate, filterEvents]);

    const newDate = formattedDate.split(" ");
    return (
      <div className="d-flex justify-content-end align-items-end">
        <h3>{newDate[2]}</h3>
        <h6 className="m-0 ms-2">{newDate[0].slice(0, -1)}</h6>
      </div>
    );
  }

  const handleShowMore = (props) => {
    setIsOpen(true);
    setDrawerEvents(props);
  };

  const calendarProps = {
    onShowMore: handleShowMore,
    popup: true,
    localizer,
    formats: {
      dateFormat: "dd MMMM",
    },
    startAccessor: "start",
    endAccessor: "end",
    dayLayoutAlgorithm: "no-overlap",
    step: 15,
    timeslots: 1,
    components: {
      toolbar: (props) => <CalendarToolbar {...props} />,
      event: eventComponentHandler,
      day: {
        header: (props) => <DayHeader {...props} />,
        event: eventComponentHandler,
        timeGutterHeader: () => <GutterHeader title="All" subtitle="Day Tasks" />,
      },
      week: {
        header: (props) => <WeekHeader {...props} />,
        event: weekEventComponentHandler,
        timeGutterHeader: () => <GutterHeader title="Multiple" subtitle="Days Task" />,
      },
    },
  };

  useEffect(() => {
    const arr = [];
    if (jobs?.data.length > 0) {
      jobs.data.forEach((job) => {
        if (job.startTime && job.endTime) {
          arr.push(filterWorkOrderJob(job));
        }
      });
      setEvents(arr);
    }
  }, [jobs]);

  if (isInitialLoading) {
    return <Loader />;
  }

  if (!jobs || !jobs?.data?.length) {
    return <NoDataFound title="No job has been found" />;
  }

  return (
    <div className="job-board-container">
      <Header
        className="mt-15"
        leftContent={(
          <div>
            <HeaderLeftContent
              title="Work Orders Calendar"
              subtitle="Supercharge your workflow and connect the tool you use every day."
            />
          </div>
        )}
        rightContent={(
          <div className="date-range-picker">
            <CustomizedDateRangePicker name="dateRange" />
          </div>
        )}
      />
      <div className="calendar-container">
        {isOpen && <CalendarDrawer isOpen={isOpen} onClose={setIsOpen} tasks={drawerEvents} />}
        {events.length > 0 && (
          <Calendar
            events={events}
            {...calendarProps}
            eventPropGetter={() => ({
              className: "weekly-rbc-event",
            })}
          />
        )}
      </div>
    </div>
  );
}

export default CalendarView;
