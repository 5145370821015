import { ResetPasswordForm } from "@hydra/saas/components";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

import { useI18n } from "@/context/i18nContext";
import passwordVisibleIcon from "@/assets/images/icons/active.svg";
import passwordHiddenIcon from "@/assets/images/icons/in-active.svg";
import showToast from "@/utils/toast/helpers";

function ResetPassword() {
  const i18n = useI18n();
  const { code } = useParams();

  const handleResetPassword = () => {};

  const onPasswordMismatchError = () => {
    showToast(i18n.t("resetPassword.passwordMismatchError"), "error");
  };

  return (
    <ResetPasswordForm
      i18n={i18n}
      onResetPassword={handleResetPassword}
      onPasswordMismatchError={onPasswordMismatchError}
      isHeadingLeftAlign
      isSubheading
      isLoading={false}
      passwordVisibleIcon={passwordVisibleIcon}
      passwordHiddenIcon={passwordHiddenIcon}
      code={code}
    />
  );
}

export default ResetPassword;
