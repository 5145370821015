import qs from "qs";
import humps from "humps";
import { kebabCase } from "lodash";
import pluralize, { singular } from "pluralize";
import request from "@/utils/api/helpers";

const baseUrl = "/object";

export const getDynamicObjectRecords = (pluralObjectName, params = {}) => {
  if (!params || !params.queryMode) {
    params.queryMode = "List";
  }

  const queryParams = qs.stringify(humps.camelizeKeys(params));

  let objectName = kebabCase(pluralize(pluralObjectName));

  if (objectName === "pdcs") {
    objectName = "pd-cs";
  }

  return request({
    url: `${baseUrl}/${objectName}?${queryParams}`
  });
};

export const getDynamicObjectRecordById = (singularObjectName, recordId) => {
  const objectName = kebabCase(singularObjectName);

  return request({
    url: `${baseUrl}/${objectName}/${recordId}`
  });
};

export const createDynamicObjectRecord = (pluralObjectName, data) => {
  let objectName = kebabCase(pluralize(pluralObjectName));

  // Handle edge case for model named PDC
  if (objectName === "pdcs") {
    objectName = "pd-cs";
  }

  return request({
    url: `${baseUrl}/${objectName}`,
    method: "POST",
    data
  });
};

export const bulkCreateDynamicObjectRecord = (pluralObjectName, data) => {
  const objectName = kebabCase(pluralize(pluralObjectName));

  return request({
    url: `${baseUrl}/${objectName}/bulk`,
    method: "POST",
    data
  });
};

export const updateDynamicObjectRecord = (singularObjectName, recordId, data) => {
  let objectName = kebabCase(singularObjectName);

  // Handle edge case for model named PDC
  if (objectName === "pdc") {
    objectName = "pd-c";
  }

  return request({
    url: `${baseUrl}/${objectName}/${recordId}`,
    method: "PATCH",
    data
  });
};

export const deleteDynamicObjectRecord = (singularObjectName, recordId) => {
  const objectName = kebabCase(singularObjectName);

  return request({
    url: `${baseUrl}/${objectName}/${recordId}`,
    method: "DELETE",
  });
};

export const uploadAttachmentWithDynamicObjectRecord = (data, progressCallback) => {
  const {
    recordId, files, objectName, attachmentFieldName
  } = data;
  const singularObjectName = kebabCase(singular(objectName));

  const formData = new FormData();

  files.forEach((file) => {
    formData.append("formFiles", file.file, file.file.name);
  });

  return request({
    url: `${baseUrl}/${singularObjectName}/${recordId}/attachment/${attachmentFieldName}`,
    method: "POST",
    headers: {
      "Content-type": "multipart/form-data",
    },
    data: formData,
    onUploadProgress: (event) => {
      const percentCompleted = Math.round((event.loaded * 100) / event.total);
      if (progressCallback) {
        progressCallback(percentCompleted);
      }
    }
  });
};

export const validationFieldDynamicObjectRecord = (data) => request({
  url: `${baseUrl}-e/data-exists`,
  method: "POST",
  data
});

export const getFieldDynamicObjectRecordName = (data) => request({
  url: `${baseUrl}-e/parse-full-name`,
  method: "POST",
  data
});

export const getFieldDynamicObjectArabicRecordName = (data) => request({
  url: `${baseUrl}-e/transliterate-full-name`,
  method: "POST",
  data
});

export const getDynamicAutoNumber = (params = {}) => {
  const queryParams = qs.stringify(humps.camelizeKeys(params));

  return request({
    url: `auto-number/object-next-number?${queryParams}`
  });
};
