import { getPlannedActualPpmExternalRows, getPlannedActualPpmExternalColumns } from "./planned-actual-ppm-external-statement";
import { getPlannedActualPpmExternal } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const plannedVsActualPpmExternalFilters = {
  WorkOrderNumber: "*",
  BuildingId: "*",
  SupervisorId: "*",
  CategoryId: "*"
};

const plannedVsActualPpmExternalFilterOptions = [
  { key: "workOrderNumber", apiKey: "WorkOrderNumber", keyType: "string" },
  { key: "buildingId", apiKey: "BuildingId" },
  { key: "supervisorId", apiKey: "SupervisorId" },
  { key: "categoryId", apiKey: "CategoryId" },
];

export default function ReportPlannedVsActualPpmExternal({ activeCompany }) {
  return (
    <DynamicReport
      reportName="ppm-report-internal"
      activeCompany={activeCompany}
      columnsDataProvider={getPlannedActualPpmExternalColumns}
      rowsDataProvider={getPlannedActualPpmExternalRows}
      fetchData={getPlannedActualPpmExternal}
      exportUrl="reports/export-query-report/ppm-report-external"
      filtersInitialState={plannedVsActualPpmExternalFilters}
      filterOptions={plannedVsActualPpmExternalFilterOptions}
      orderBy="BuildingName"
    />
  );
}
