import qs from "qs";
import request from "@/utils/api/helpers";

const baseUrl = "/configuration/company-accounts";

export const getCompanyAccounts = (params = {}) => {
  const headers = {
    includeCompanyId: true
  };

  const { companyId, includeCompanyId, ...rest } = params;

  if (typeof includeCompanyId === "boolean" && !includeCompanyId) {
    headers.includeCompanyId = false;
  }

  if (headers.includeCompanyId && companyId) {
    headers.Companyid = params.companyId;
  }

  const queryParams = qs.stringify(rest, {
    addQueryPrefix: true
  });

  return request({
    url: `${baseUrl}${queryParams}`,
    headers
  });
};

export const getCompanyAccountById = (id) => request({
  url: `${baseUrl}/${id}`
});

export const createCompanyAccount = (data) => request({
  url: baseUrl,
  method: "POST",
  data
});

export const bulkCreateCompanyAccounts = (data) => request({
  url: `${baseUrl}/bulk`,
  method: "POST",
  data
});

export const updateCompanyAccount = (id, data) => request({
  url: `${baseUrl}/${id}`,
  method: "PATCH",
  data
});

export const deleteCompanyAccount = (id) => request({
  url: `${baseUrl}/${id}`,
  method: "DELETE",
});
