import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";
import { Pagination } from "@hydra/atom/components";
import {
  getOverviewTableColumns,
  getOverviewTableData,
} from "./quotationTableData";

const perPageOptions = [
  { label: "12", value: 12 },
  { label: "24", value: 24 },
  { label: "36", value: 36 },
];

export default function QuotationListTable({
  data,
  setMaterialData,
  setAddedMaterialList
}) {

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageOptions[0]);

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };

  const handleValueChange = (row, value) => {
    const material = {
      id: row.original.key,
      rate: value
    };
    setMaterialData(material);
  };

  const addMaterial = (row) => {
    const item = {
      id: row.original.key,
      itemName: row.original.title,
      quantity: row.original.quantity,
      rate: row.original.rate,
      amount: row.original.amount,
      item: row.original.item
    };

    setAddedMaterialList(item);
  };

  const totalCount = useMemo(() => data?.length || 0, [data]);
  // eslint-disable-next-line max-len
  const tableData = useMemo(() => getOverviewTableData(data), [data]);
  const columns = useMemo(
    () => (
      getOverviewTableColumns()),
    []
  );
  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } =
    useTable({
      columns,
      data: tableData || [],
      handleValueChange,
      addMaterial,
    });

  if (data?.length === 0) return;

  return (
    <div className="plan-board-accordion-pending}">
      <div className="table-wrapper">

        <div className="overflow">
          <table className="table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="table-header-row"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      className={`table-header-cell ${
                        column.collapse ? "collapse" : ""
                      }`}
                      {...column.getHeaderProps()}
                    >
                      <div className="table-header-cell-content">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr className="table-row" {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        className={`table-body-cell ${
                          cell.column.collapse ? "collapse" : ""
                        }`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {!data && (
          <Pagination
            className="dashboard-pagination"
            options={perPageOptions}
            perPage={perPage}
            onSelectChange={(val) => handlePagination(currentPage, val)}
            pageRangeDisplayed={3}
            handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
            showResults
            offset={0}
            totalItems={totalCount}
            pageCount={totalCount || Math.ceil(totalCount / perPage.value)}
            reactPaginateProps={{
              previousLabel: <span className="material-icons">&#xe5cb;</span>,
              nextLabel: <span className="material-icons">&#xe5cc;</span>,
              forcePage: currentPage - 1,
            }}
          />
        )}
      </div>
    </div>
  );
}

QuotationListTable.propTypes = {
  data: PropTypes.array.isRequired,
  perPage: PropTypes.object.isRequired,
  perPageOptions: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number,
  handlePagination: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

QuotationListTable.defaultProps = {
  isLoading: false,
  totalPages: 1,
};
