import { useReducer } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@hydra/atom/components";
import { Modal, RequirePermission } from "@/components/common";
import { ModalHeader } from "@/components/modals";
import {
  workOrderFormReducer,
  initialState,
  resetState,
  setType,
} from "@/reducers/facility/workOrderFormReducer";
import { SelectStep } from "@/components/facility/planboard";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import reactiveImage from "@/assets/images/reactive.png";
import scheduledImage from "@/assets/images/scheduled.png";

const options = [
  {
    value: "reactive",
    title: "Corrective Maintenance",
    subtitle: "Corrective Maintenance",
    image: reactiveImage,
  },
  {
    value: "scheduled",
    title: "Preventive Maintenance",
    subtitle: "Preventive Maintenance",
    image: scheduledImage,
  },
];

export default function SelectWorkOrderTypeModal({ isOpen, onClose }) {
  const [state, dispatch] = useReducer(workOrderFormReducer, initialState);
  const navigate = useNavigate();

  const submitWorkOrderForm = () => {
    navigate(`/facility-management/work-order/new?type=${state.type}`);
  };

  const handleClose = () => {
    dispatch(resetState());
    onClose(false);
  };

  if (!isOpen) return null;

  return (
    <Modal
      rootClassName="center-vertically add-workorder-modal"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div className="core-modal-content select-work-order-type-modal">
        <ModalHeader
          heading="Select Maintenance"
          subHeading="Select maintenance type for work order"
        />
        <SelectStep
          options={options}
          value={state.type}
          onChange={(value) => dispatch(setType(value))}
        />
        <RequirePermission
          parent="Model"
          scope={dynamicObjectMap.get("WorkOrderObjectName")}
          action="Insert"
        >
          <div className="save-btn">
            <Button block onClick={submitWorkOrderForm}>
              Next
            </Button>
          </div>
        </RequirePermission>
      </div>
    </Modal>
  );
}
