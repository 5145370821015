import { isEmpty } from "lodash";
import { addDays, differenceInDays, endOfMonth, getDaysInMonth, getOverlappingDaysInIntervals, intervalToDuration, isAfter, isBefore, isEqual, isSameMonth } from "date-fns";
import { calculateTax, formatDecimalValues } from "@/utils/helpers";

import ProfileAvatar from "@/assets/images/avatar-arab.png";

export default function formatBuildingReport(data) {
  return data?.map((key, index) => ({
    index,
    key: key.id,
    id: index + 1,
    propertyName: key.name,
    landlord: {
      name: "Ali Ahmed",
      img: ProfileAvatar,
    },
    totalUnits: "89",
    occupiedUnits: "89",
    vacantUnits: "89",
    contracts: "05",
    occupancy: 60,
  }));
}

export function getStatusCount(data) {
  const counts = {
    blocked: 0,
    occupied: 0,
    vacant: 0,
  };
  data.map((item) => {
    if (item.currentStatus === "Inactive") {
      counts.blocked += 1;
      return "";
    }

    if (item.status === "Available") {
      counts.vacant += 1;
    }

    if (item.status === "Occupied") {
      counts.occupied += 1;
    }

    return "";
  });

  return counts;
}

export const calculateUtilityAnnualAmount = ({
  amount,
  agreementStartDate,
  agreementEndDate
}) => {
  let annualAmount = 0;

  const { years, months, days } = intervalToDuration({
    start: new Date(agreementStartDate),
    end: addDays(new Date(agreementEndDate), 1),
  });

  if (years && !months && !days) {
    annualAmount = formatDecimalValues(Number(amount / years));
  }

  if (!years && !days && months) {
    annualAmount = formatDecimalValues(Number((12 / months) * amount));
  }

  if (!years && !months && days) {
    annualAmount = formatDecimalValues(Number((365 / days) * amount));
  }

  return annualAmount;
};

export const calculateAmountForStayedPeriod = ({
  annualAmount,
  agreementStartDate,
  moveOutDate
}) => {
  let amountForStayedPeriod = 0;

  const { years, months, days } = intervalToDuration({
    start: new Date(agreementStartDate),
    end: addDays(new Date(moveOutDate), 1),
  });

  const monthlyAmount = formatDecimalValues(annualAmount / 12);

  if (years) {
    amountForStayedPeriod += Number(annualAmount * years);
  }

  if (months) {
    amountForStayedPeriod += Number(monthlyAmount * months);
  }

  // If duration is less than a month and dates are in different months,
  // calculate separately for each month
  if (days && !months && !years) {
    const areDatesInSameMonth = isSameMonth(new Date(agreementStartDate), new Date(moveOutDate));

    if (!areDatesInSameMonth) {
      const endDateOfFirstMonth = endOfMonth(new Date(agreementStartDate));
      const { days: firstMonthDays } = intervalToDuration({
        start: new Date(agreementStartDate),
        end: addDays(new Date(endDateOfFirstMonth), 1),
      });
      const daysInTheFirstMonth = getDaysInMonth(new Date(agreementStartDate));
      const firstMonthRatio = firstMonthDays / daysInTheFirstMonth;
      amountForStayedPeriod += Number(monthlyAmount * firstMonthRatio);

      const startDateOfSecondMonth = endOfMonth(new Date(agreementStartDate));
      const { days: secondMonthDays } = intervalToDuration({
        start: new Date(startDateOfSecondMonth),
        end: new Date(moveOutDate),
      });
      const daysInTheSecondMonth = getDaysInMonth(new Date(moveOutDate));
      const secondMonthRatio = secondMonthDays / daysInTheSecondMonth;
      amountForStayedPeriod += Number(monthlyAmount * secondMonthRatio);

      return amountForStayedPeriod;
    }
  }

  if (days) {
    const daysInTheMonth = getDaysInMonth(new Date(moveOutDate));
    const ratio = days / daysInTheMonth;
    amountForStayedPeriod += Number(monthlyAmount * ratio);
  }

  return amountForStayedPeriod;
};

export const calculateContractRefund = (contract, moveOutDate, totalCharge = 0) => {
  const {
    agreementStartDate,
    agreementEndDate,
    agreementEndDateWithoutGracePeriod,
    contractAmount,
    utilityCharge,
    paymentDetail,
    annualAmount,
    tax,
    amountOfTax,
    securityDeposit,
    utilityTax,
    utilityAmountOfTax,
    utilityTaxAmount,
    taxAmount,
  } = contract;

  const data = {
    rentForPeriod: contractAmount,
    utilityAmountForPeriod: utilityCharge,
    stayedPeriod: 0,
    rentForStayedPeriod: 0,
    utilityAmountForStayedPeriod: 0,
    moveOutDate,
    earlyTerminationPenalty: 0,
    securityDeposit,
    rentVatForStayedPeriod: 0,
    utilityVatAmountForStayedPeriod: 0,
    rentVatForPeriod: 0,
    utilityVatAmountForPeriod: 0,
    vatAmountForStayedPeriod: 0,
    totalCharge
  };

  const isSameAsAgreementEndDate = isEqual(new Date(moveOutDate), new Date(agreementEndDate));
  const isAfterAgreementEndDate = isAfter(new Date(moveOutDate), new Date(agreementEndDate));
  const isBeforeAgreementStartDate = isBefore(new Date(moveOutDate), new Date(agreementStartDate));

  const overlappingDays =
    getOverlappingDaysInIntervals(
      {
        start: new Date(agreementStartDate),
        end: new Date(agreementEndDateWithoutGracePeriod),
      },
      {
        start: new Date(agreementStartDate),
        end: isBeforeAgreementStartDate ? new Date(agreementStartDate) : new Date(moveOutDate),
      }
    ) + 1;

  data.stayedPeriod = overlappingDays === 1 ? 0 : overlappingDays;

  if (isAfterAgreementEndDate) {
    const extraDays = differenceInDays(new Date(moveOutDate), new Date(agreementEndDate));
    if (extraDays) {
      data.stayedPeriod += extraDays;
    }
  }

  let rentAmountForStayedPeriod = calculateAmountForStayedPeriod({
    annualAmount,
    agreementStartDate,
    moveOutDate
  });

  let rentTaxAmountForStayedPeriod = 0;

  if (tax && !isEmpty(tax) && amountOfTax) {
    const parameters = {
      amount: rentAmountForStayedPeriod,
      amountOfTax: {
        value: amountOfTax,
      },
      tax,
      taxAmount: 0,
    };

    const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax(parameters);

    rentAmountForStayedPeriod = principalAmount;
    rentTaxAmountForStayedPeriod = calculatedTaxAmount;
  }

  data.rentForStayedPeriod = formatDecimalValues(Number(rentAmountForStayedPeriod));
  data.rentVatForStayedPeriod = rentTaxAmountForStayedPeriod;

  const utilityAnnualAmount = calculateUtilityAnnualAmount({
    amount: utilityCharge,
    agreementStartDate,
    agreementEndDate
  });
  let utilityAmountForStayedPeriod = calculateAmountForStayedPeriod({
    annualAmount: utilityAnnualAmount,
    agreementStartDate,
    moveOutDate
  });

  let utilityTaxAmountForStayedPeriod = 0;

  if (utilityTax && !isEmpty(utilityTax) && utilityAmountOfTax) {
    const parameters = {
      amount: utilityAmountForStayedPeriod,
      amountOfTax: {
        value: utilityAmountOfTax,
      },
      tax: utilityTax,
      taxAmount: 0,
    };

    const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax(parameters);

    utilityAmountForStayedPeriod = principalAmount;
    utilityTaxAmountForStayedPeriod = calculatedTaxAmount;
  }

  data.utilityAmountForStayedPeriod = utilityAmountForStayedPeriod;
  data.utilityVatAmountForStayedPeriod = utilityTaxAmountForStayedPeriod;

  if (isSameAsAgreementEndDate) {
    data.rentForStayedPeriod = contractAmount;
    data.utilityAmountForPeriod = utilityCharge;
  }

  const collectedPayments = paymentDetail.filter((p) =>
    ["Collected", "Cleared"].includes(p.paymentStatus)
  );

  const partialPayments = paymentDetail.filter((p) => ["Partial"].includes(p.paymentStatus));

  const receivedPayments = collectedPayments.filter((p) => {
    if (
      p.paymentStatus === "Collected" &&
      p.paymentMethod === "Cheque"
    ) {
      return false;
    }

    return true;
  });

  const totalFullyReceivedRentAmount = receivedPayments
    .filter((p) => p.paymentType === "Rent")
    .reduce((prevValue, { amount }) => prevValue + amount, 0);
  const totalFullyReceivedUtilityAmount = receivedPayments
    .filter((p) => p.paymentType === "Utility")
    .reduce((prevValue, { amount }) => prevValue + amount, 0);
  const totalFullyReceivedRentVatAmount = receivedPayments
    .filter((p) => p.paymentType === "Rent")
    .reduce((prevValue, { taxAmount: taxAmountValue }) => prevValue + Number(taxAmountValue), 0);
  const totalFullyReceivedUtilityVatAmount = receivedPayments
    .filter((p) => p.paymentType === "Utility")
    .reduce((prevValue, { taxAmount: taxAmountValue }) => prevValue + Number(taxAmountValue), 0);

  const totalReceivedRentPartialPayments = partialPayments
    .filter((p) => p.paymentType === "Rent")
    .reduce(
      (prevValue, { totalAmount, openBalance }) =>
        prevValue + totalAmount - Number(openBalance || 0),
      0
    );

  const totalReceivedRentVatPartialAmount = partialPayments
    .filter((p) => p.paymentType === "Rent")
    .reduce((prevValue, { totalAmount, openBalance, taxAmount: paymentTaxAmount }) => {
      const receivedAmount = totalAmount - Number(openBalance || 0);
      const receivedTaxAmount = (receivedAmount / totalAmount) * paymentTaxAmount;

      return prevValue + Number(receivedTaxAmount || 0);
    }, 0);

  const totalReceivedRentPartialAmount =
    totalReceivedRentPartialPayments - totalReceivedRentVatPartialAmount;

  const totalReceivedUtilityPartialPayments = partialPayments
    .filter((p) => p.paymentType === "Utility")
    .reduce(
      (prevValue, { totalAmount, openBalance }) =>
        prevValue + totalAmount - Number(openBalance || 0),
      0
    );

  const totalReceivedUtilityVatPartialAmount = partialPayments
    .filter((p) => p.paymentType === "Utility")
    .reduce((prevValue, { totalAmount, openBalance, taxAmount: paymentTaxAmount }) => {
      const receivedAmount = totalAmount - Number(openBalance || 0);
      const receivedTaxAmount = (receivedAmount / totalAmount) * paymentTaxAmount;

      return prevValue + Number(receivedTaxAmount || 0);
    }, 0);

  const totalReceivedUtilityPartialAmount =
    totalReceivedUtilityPartialPayments - totalReceivedUtilityVatPartialAmount;

  const totalReceivedRentAmount = totalFullyReceivedRentAmount + totalReceivedRentPartialAmount;
  const totalReceivedRentVatAmount =
    totalFullyReceivedRentVatAmount + totalReceivedRentVatPartialAmount;
  const totalReceivedUtilityAmount =
    totalFullyReceivedUtilityAmount + totalReceivedUtilityPartialAmount;
  const totalReceivedUtilityVatAmount =
    totalFullyReceivedUtilityVatAmount + totalReceivedUtilityVatPartialAmount;

  const totalRefundRentAmount = totalReceivedRentAmount - data.rentForStayedPeriod;
  const totalRefundRentVatAmount = totalReceivedRentVatAmount - data.rentVatForStayedPeriod;
  const totalRefundUtilityAmount = totalReceivedUtilityAmount - data.utilityAmountForStayedPeriod;
  const totalRefundUtilityVatAmount =
    totalReceivedUtilityVatAmount - data.utilityVatAmountForStayedPeriod;

  data.rentVatForPeriod = taxAmount;
  data.utilityVatAmountForPeriod = utilityTaxAmount;
  data.totalRentReceived = totalReceivedRentAmount;
  data.totalRentVatReceived = totalReceivedRentVatAmount;
  data.totalUtilityVatAmountReceived = totalReceivedUtilityVatAmount;
  data.totalUtilityAmountReceived = totalReceivedUtilityAmount;
  data.rentRefund = totalRefundRentAmount;
  data.utilityAmountRefund = totalRefundUtilityAmount;
  data.rentVatRefund = totalRefundRentVatAmount;
  data.utilityVatAmountRefund = totalRefundUtilityVatAmount;
  data.vatAmountForStayedPeriod =
    Number(data.rentVatForStayedPeriod || 0) + Number(data.utilityVatAmountForStayedPeriod || 0);

  data.balanceAmount = data.rentRefund + data.utilityAmountRefund + Number(securityDeposit || 0);
  data.totalRefundAmount = data.balanceAmount - Number(data?.totalCharge || 0);

  data.totalVatReceived =
    Number(data.totalRentVatReceived || 0) + Number(data.totalUtilityVatReceived || 0);
  data.totalRefundVatAmount = totalRefundRentVatAmount + totalRefundUtilityVatAmount;

  return data;
};
