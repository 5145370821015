import { getVATExceptionRows, getVATExceptionColumns } from "./vat-exception-statement";
import { getVATExceptions } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportVATException({ activeCompany }) {
  return (
    <DynamicReport
      reportName="vat-exceptions"
      activeCompany={activeCompany}
      columnsDataProvider={getVATExceptionColumns}
      rowsDataProvider={getVATExceptionRows}
      fetchData={getVATExceptions}
      exportUrl="reports/export-query-report/vat-exceptions"
      filtersInitialState={{}}
      orderBy="Building"
      showDateRangeFilter={false}
    />
  );
}
