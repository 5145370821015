import PropTypes from "prop-types";
import { useTable } from "react-table";

function Table({ columns, data, setData, isEditing }) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
    setData,
    isEditing
  });

  return (
    <table className="table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr className="table-header-row" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className={`table-header-cell ${column.collapse ? "collapse" : ""}`}
              >
                <div className="table-header-cell-content">{column.render("Header")}</div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="table-row">
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className={`table-body-cell ${cell.column.collapse ? "collapse" : ""} ${
                    row.original.isParent ? "bg-gray-200" : ""
                  }`}
                >
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
};

function BudgetTable({ data, columns, setData, isEditing }) {
  return (
    <div className="spread-sheet">
      <div className="table-wrapper scrollable">
        <Table columns={columns} data={data} setData={setData} isEditing={isEditing} />
      </div>
    </div>
  );
}

export default BudgetTable;
