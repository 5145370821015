export const statusColorMap = new Map([
  // blue
  ["low", "#026AA2"],
  ["created", "#175CD3"],

  // yellow
  ["open", "#EAC72A"],
  ["partial", "#EAC72A"],
  ["inquiry", "#EAC72A"],
  ["in-process [sales]", "#EAC72A"],
  ["in-process [leasing]", "#EAC72A"],
  ["available", "#EAC72A"],

  // orange
  ["pending", "#F79009"],
  ["draft", "#F79009"],
  ["inprocess", "#F79009"],
  ["in process", "#F79009"],
  ["inprogress", "#F79009"],
  ["in progress", "#F79009"],
  ["medium", "#F79009"],
  ["renewal pending", "#F79009"],
  ["approval pending", "#F79009"],
  ["transfer pending", "#F79009"],
  ["reopened", "#F79009"],
  ["under review", "#F79009"],
  ["request to recall", "#F79009"],
  ["pending to recall", "#F79009"],
  ["pending claim", "#F79009"],
  ["scheduled viewing", "#F79009"],
  ["quoted [sales]", "#F79009"],
  ["quoted [leasing]", "#F79009"],
  ["scheduledviewing", "#F79009"],

  // purple
  ["active", "#6941C6"],
  ["started", "#6941C6"],
  ["submitted", "#6941C6"],
  ["collected", "#6941C6"],
  ["escalate", "#6941C6"],
  ["escalated", "#6941C6"],
  ["proposed", "#6941C6"],
  ["planned", "#6941C6"],
  ["property visited", "#6941C6"],
  ["merged", "#6941C6"],
  ["under maintenance", "#6941C6"],
  ["checkout", "#6941C6"],
  ["booked [sales]", "#6941C6"],
  ["booked [leasing]", "#6941C6"],
  ["propertyvisited", "#6941C6"],

  // green
  ["completed", "#027A48"],
  ["occupied", "#027A48"],
  ["approved", "#027A48"],
  ["deployed", "#027A48"],
  ["scheduled", "#027A48"],
  ["future", "#027A48"],
  ["updated", "#027A48"],
  ["deposited", "#027A48"],
  ["cleared", "#027A48"],
  ["paid", "#027A48"],
  ["issued", "#027A48"],
  ["claimed", "#027A48"],
  ["accepted", "#027A48"],
  ["adjusted", "#027A48"],
  ["sold", "#027A48"],
  ["approved for refund", "#027A48"],
  ["refunded", "#027A48"],

  // grey
  ["onhold", "#363F72"],
  ["on hold", "#363F72"],
  ["closed", "#363F72"],
  ["cancelled", "#363F72"],
  ["blocked", "#363F72"],
  ["recalled", "#363F72"],
  ["inactive", "#363F72"],
  ["expired", "#363F72"],
  ["ended", "#363F72"],
  ["retired", "#B42318"],
  ["hold [sales]", "#B42318"],
  ["hold [leasing]", "#B42318"],

  // red
  ["rejected", "#B42318"],
  ["vacant", "#B42318"],
  ["high", "#B42318"],
  ["critical", "#B42318"],
  ["bounced", "#B42318"],
  ["denied", "#B42318"],
  ["terminated", "#B42318"],
  ["suspended", "#B42318"],
  ["left", "#B42318"],
  ["deleted", "#B42318"],
  ["broken", "#B42318"],
  ["lost lead", "#B42318"],
  ["obsolete", "#B42318"],
  ["has legal case on it", "#B42318"],
  ["lostlead", "#B42318"],
]);

export const priorityColorMap = new Map([
  ["low", "#026AA2"],
  ["medium", "#B93815"],
  ["high", "#B42318"],
  ["critical", "#B42318"],
]);
