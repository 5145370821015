import PropTypes from "prop-types";
import { Button } from "@hydra/atom/components";

function CrossButton({ onClick }) {
  return (
    <Button small onClick={onClick}>
      <span className="material-icons">close</span>
    </Button>
  );
}

CrossButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CrossButton;
