import { useMemo, useEffect } from "react";
import PropTypes from "prop-types";

import { ReactSelect } from "@hydra/atom/components";
import { components } from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { SvgIcon } from "@/components/common";
import {
  selectActiveApp,
  setActiveApp,
  selectActiveCompany,
  setActiveCompany,
  setCompanies,
  selectApps,
} from "@/store/appSlice";
import { useUser } from "@/hooks";
import { VENDOR_USER_TYPE } from "@/utils/userTypes";
import { getCompanies } from "@/api/finance/companyApi";
import { getCompany } from "@/api/user/authApi";
import { getTestId } from "@/utils/helpers";

function DropdownIndicator() {
  return (
    <div className="dropdown-indicator">
      <SvgIcon icon="arrow-down-icon" />
    </div>
  );
}

function ValueContainer({ children, ...props }) {
  return (
    <components.ValueContainer {...props}>
      <img className="account-img" src={props.getValue()[0]?.image} alt="" />
      <div className="value-container-labels">
        {children}
        <div className="account-label">{props.getValue()[0]?.label}</div>
      </div>
    </components.ValueContainer>
  );
}

ValueContainer.propTypes = {
  getValue: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
};

function CustomOption(props) {
  const { data, label, testId } = props;
  return data.valueType === "company" && label ? (
    <SwitchCompany {...props} />
  ) : (
    <div data-testid={getTestId(`${testId}-Option`)}>
      <components.Option {...props}>
        <img className="account-img" src={data.image} alt="" />
        {label}
      </components.Option>
    </div>
  );
}

CustomOption.propTypes = {
  data: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

function Input(props) {
  const { isHidden, getClassNames, ...rest } = props;
  if (isHidden) return null;

  return <components.Input {...rest} />;
}

Input.propTypes = {
  isHidden: PropTypes.bool.isRequired,
};

function SwitchCompany({ data, label }) {
  return (
    <div className="switch-company">
      <p>{label}</p>
      {data.showSwitchAction && (
        <button aria-label="switch-button" className="switch" type="button" onClick={() => data.action()}>
          <SvgIcon icon="reset-icon" />
        </button>
      )}
    </div>
  );
}

export default function SwitchAccountDropdown() {
  const queryClient = useQueryClient();
  const apps = useSelector(selectApps);
  const activeApp = useSelector(selectActiveApp);
  const activeCompany = useSelector(selectActiveCompany);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, userType } = useUser();
  const { data: companiesData, isLoading } = useQuery(
    ["company", user?.userId],
    () => getCompanies(),
    { enabled: Boolean(user?.userId) }
  );

  const companies = useMemo(() => {
    if (!user || !companiesData || isLoading) {
      return [];
    }

    const userCompanyIds = user?.companies.map((c) => c.id);

    return companiesData?.data.filter((c) => userCompanyIds.includes(c.id));
  }, [companiesData, user]);

  useEffect(() => {
    if (user && companiesData) {
      const formattedCompanies = companiesData?.data.map((company) => ({
        value: company.id,
        label: company.name
      }));
      dispatch(setCompanies(formattedCompanies));
    }
  }, [companiesData, user]);

  useEffect(() => {
    if (companies.length) {
      const { defaultBookOfBusiness } = user;
      const activeCompanyId = getCompany();
      let selectedCompany = null;

      if (activeCompanyId) {
        selectedCompany = companies.find(
          (c) => c.id === activeCompanyId
        );
      }

      if (!selectedCompany && defaultBookOfBusiness) {
        selectedCompany = companies.find(
          (c) => c.name.toLowerCase() === defaultBookOfBusiness.toLowerCase()
        );
      }

      if (selectedCompany) {
        window.localStorage.setItem("date_format", selectedCompany?.companySettings?.dateFormat);
        dispatch(setActiveCompany(selectedCompany));
      } else {
        dispatch(setActiveCompany(companies[0]));
      }
    }
  }, [companies, user]);

  const handleSwitchCompany = async () => {
    if (companies.length) {
      const inActiveIndex = companies.findIndex((c) => c.id !== activeCompany.id);
      dispatch(setActiveCompany(companies[inActiveIndex]));
      const paths = location.pathname.split("/");
      const slicedPaths = paths.splice(1, 2);
      const pathName = `/${slicedPaths.join("/")}`;
      if (!pathName.includes("reports")) {
        navigate(pathName);
      }
      await queryClient.invalidateQueries();
    }
  };

  const handleChangeActiveApp = (option) => {
    dispatch(setActiveApp(option));

    switch (option.value) {
      case "Admin":
        navigate("/admin/tasks");
        break;

      case "FacilityManagement": {
        if (userType === VENDOR_USER_TYPE) {
          navigate("/facility-management/work-order-jobs");
        } else {
          navigate("/facility-management/tasks");
        }
        break;
      }

      case "Leasing":
        navigate("/leasing/tasks");
        break;

      case "Finance":
        navigate("/finance/tasks");
        break;

      case "Sales":
        navigate("/sales/tasks");
        break;

      case "Warehousing":
        navigate("/warehousing/tasks");
        break;

      default:
        break;
    }
  };

  const company = {
    label: activeCompany?.name,
    value: activeCompany?.id,
    valueType: "company",
    status: "Active",
    action: handleSwitchCompany,
    showSwitchAction: companies ? companies.length > 1 : false,
  };

  return (
    <ReactSelect
      className="switch-app-dropdown"
      components={{
        ValueContainer,
        IndicatorSeparator: null,
        DropdownIndicator,
        Option: CustomOption,
        Input,
      }}
      value={activeApp}
      onChange={handleChangeActiveApp}
      options={[company, ...apps]}
      isSearchable
    />
  );
}
