import request from "@/utils/api/helpers";

const baseUrl = "/identity/role";

// eslint-disable-next-line import/prefer-default-export
export function getAllRoles() {
  return request({
    url: `${baseUrl}/get-all-roles`
  });
}
