/* eslint-disable no-nested-ternary */
import { useCallback, useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { components } from "react-select";
import { kebabCase, startCase } from "lodash";
import pluralize from "pluralize";
import { useNavigate } from "react-router-dom";
import { pascalize } from "humps";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { ReactSelect } from "@hydra/atom/components";
import { Pill } from "@/components/dashboard";
import { getTestId } from "@/utils/helpers";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import getElasticSearchResults from "@/api/vendor/elastic-search/searchApi";
import { selectPermissions } from "@/store/userSlice";
import { useDebounce } from "@/hooks";

const getPillVariant = (moduleName) => {
  switch (moduleName) {
    case "Finance":
      return "success";

    case "FacilityManagement":
      return "orange";

    case "Leasing":
      return "blue";

    case "Sales":
      return "pink";

    case "Warehousing":
      return "error";

    case "Admin":
      return "gray";

    default:
      return "gray";
  }
};

function Option(props) {
  const { testId } = props;
  const userInput = props?.selectProps?.inputValue || "";

  return (
    <div data-testid={getTestId(`${testId}-Option`)}>
      <components.Option {...props}>
        <div className="react-select-option">
          <div className="d-flex justify-content-between align-items-center">
            <p>
              {props?.selectProps?.inputValue?.length ?
                props?.data?.label?.split(" ").length ?
                  props?.data?.label?.split(" ").map((word) =>
                    word.split("").map((w, letterIndex) => {
                      const isLastLetter = letterIndex === word.length - 1;

                      if (userInput.includes(w.toLowerCase())) {
                        return <b style={{ color: "#6941c6" }}>{isLastLetter ? `${w} ` : w}</b>;
                      }

                      return isLastLetter ? `${w} ` : w;
                    })
                  ) :
                  props?.data?.label :
                props?.data?.label}
            </p>
            <div className="d-flex align-items-center pills-container">
              <Pill
                text={props?.data?.moduleName}
                variant={getPillVariant(pascalize(props?.data?.moduleName))}
              />
              {props?.data?.objectName && <Pill text={props?.data?.objectName} variant="primary" />}
              {props?.data?.number && <Pill text={props?.data?.number} variant="pink" />}
            </div>
          </div>
        </div>
      </components.Option>
    </div>
  );
}

const prepareMenuItemsFromPermissions = (appPermissions) => {
  if (!appPermissions) return [];

  const menuItems = [];

  const modules = appPermissions.Module.permissions;
  const objects = appPermissions.Model.permissions;
  const reports = appPermissions?.Report?.permissions;

  modules.forEach((permission) => {
    const { objectName, permitted: modulePermissions } = permission;
    const viewPermission = modulePermissions.includes("Any") || modulePermissions.includes("View");

    if (viewPermission) {
      menuItems.push({
        label: `View ${startCase(kebabCase(objectName))} tasks`,
        value: `/${kebabCase(objectName)}/tasks`,
        moduleName: startCase(objectName),
      });
    }
  });

  objects.forEach((permission) => {
    const { objectName, moduleName } = permission;
    const objectPermissions = appPermissions.Model[objectName] ?? [];

    const nameInPascalCase = startCase(kebabCase(objectName));
    const insertPermission =
      objectPermissions.includes("Any") || objectPermissions.includes("Insert");
    const viewPermission = objectPermissions.includes("Any") || objectPermissions.includes("View");
    if (insertPermission) {
      let link = `/${kebabCase(moduleName)}/${kebabCase(objectName)}/new`;

      if (objectName === "Budget") {
        link += "?type=opex";
      }

      if (objectName === dynamicObjectMap.get("WorkOrderObjectName")) {
        link += "?type=reactive";
      }
      if ((objectName !== dynamicObjectMap.get("MoveOutRequestObjectName")) && (objectName !== dynamicObjectMap.get("MoveInRequestObjectName"))) {
        menuItems.push({
          label: `Create ${nameInPascalCase}`,
          value: link,
          moduleName: startCase(moduleName),
        });
      }
    }

    if (viewPermission) {
      let link = `/${kebabCase(moduleName)}/${kebabCase(pluralize(nameInPascalCase))}`;
      let label = `View ${pluralize(nameInPascalCase)}`;

      if (objectName === "LedgerAccount") {
        label = "View Chart of Accounts";
        link = `/${kebabCase(moduleName)}/chart-of-accounts`;
      }

      menuItems.push({
        label,
        value: link,
        moduleName: startCase(moduleName),
      });
    }
  });

  if (reports && reports.length) {
    menuItems.push({
      label: "View Reports",
      value: "/finance/reports",
      moduleName: "Finance",
    });

    reports.forEach((permission) => {
      const { objectName, permitted: reportPermissions } = permission;
      const viewPermission =
        reportPermissions.includes("Any") || reportPermissions.includes("View");

      const filteredReports = [
        "tenant-statement-invoices",
        "tenant-statement-payments",
        "supplier-aging-report",
        "supplier-open-transactions",
      ];
      const isFiltered = filteredReports.includes(objectName);

      if (viewPermission && !isFiltered) {
        menuItems.push({
          label: `View ${startCase(objectName)}`,
          value: `/finance/reports/${objectName}`,
          moduleName: "Finance",
        });
      }

      if (viewPermission && objectName === "tenant-statement-invoices") {
        menuItems.push({
          label: "View Tenant Statement",
          value: "/finance/reports/tenant-statement-list",
          moduleName: "Finance",
        });
      }

      if (viewPermission && objectName === "supplier-aging-report") {
        menuItems.push({
          label: "View Supplier Statement",
          value: "/finance/reports/supplier-aging-report",
          moduleName: "Finance",
        });
      }
    });
  }
  return menuItems;
};

const prepareMenuItemsFromSearch = (searchResults) => {
  const { hits } = searchResults;
  const searchOptions = hits.filter(({ objectName }) => !objectName.includes("_")).map((record) => {
    const { objectName, dataObject } = record;
    const label = dataObject?.itemName || dataObject?.name || dataObject?.number;
    const number = dataObject?.number || dataObject?.code || dataObject?.currencyCode;

    return {
      label,
      value: `/finance/${kebabCase(objectName)}/${dataObject?.id}`,
      moduleName: "Finance",
      number,
      objectName,
    };
  });

  return searchOptions;
};

const filterOptions = (search, options) =>
  options.filter((o) => o.label.toLowerCase().includes(search.toLowerCase()));

export default function SearchBar() {
  const queryClient = useQueryClient();
  const permissions = useSelector(selectPermissions);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const debouncedSearch = useDebounce(searchText, 300);

  const options = useMemo(() => prepareMenuItemsFromPermissions(permissions), [permissions]);
  const [searchOptions, setSearchOptions] = useState([]);

  const handleInputChange = useCallback((value) => {
    queryClient.cancelQueries({ queryKey: [kebabCase("ElasticSearch")] });
    setSearchText(value);
  });

  const onChange = (value) => {
    navigate(value.value);
  };

  const { data: elasticSearchOptions, isInitialLoading } = useQuery(
    ["elastic-search", searchText],
    () => getElasticSearchResults({
      searchText: debouncedSearch,
      pageNumber: 1,
      pageSize: 10
    }),
    {
      enabled: Boolean(debouncedSearch),
    }
  );

  useEffect(() => {
    setSearchOptions(options);
  }, [options]);

  useEffect(() => {
    if (!isInitialLoading && elasticSearchOptions && debouncedSearch) {
      const newOptions = prepareMenuItemsFromSearch(elasticSearchOptions);
      const filteredOptions = filterOptions(searchText, options);
      const menuOptions = filteredOptions.concat(newOptions);
      setSearchOptions(menuOptions);
    } else {
      setSearchOptions(options);
    }
  }, [elasticSearchOptions, isInitialLoading]);

  return (
    <ReactSelect
      id="search-bar"
      name="search"
      lookupObjectName="ElasticSearch"
      className="search-bar"
      value={null}
      onChange={onChange}
      onInputChange={handleInputChange}
      options={searchOptions}
      placeholder="Search..."
      components={{
        Option,
        IndicatorSeparator: null,
      }}
    />
  );
}
