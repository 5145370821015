import { Checkbox } from "@hydra/atom/components";
import { SvgIcon } from "@/components/common";
import { getTestId } from "@/utils/helpers";

function CheckboxWithIcon({
  id, name, label, helperText, value, onChange, testId, ...rest
}) {
  const LabelNode = (
    <div className="checkbox-label-container">
      <SvgIcon className="field-icon" icon="layers-icon" />
      <div className="checkbox-label">
        <span>{label}</span>
        {helperText ? <p>{helperText}</p> : null}
      </div>
    </div>
  );

  return (
    <label htmlFor={name} className={`checkbox-option-container ${value && "checkbox-container-active"}`}>
      { LabelNode }
      <Checkbox
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        testId={getTestId(testId)}
        {...rest}
      />
    </label>
  );
}

export default CheckboxWithIcon;
