import { getPettyCashByBuildingRows, getPettyCashByBuildingColumns } from "./petty-cash-building-statement";
import { getPettyCashByBuilding } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const pettyCashByBuildingFilters = {
  SupervisorId: "*",
  BuildingId: "*",
};

export default function ReportPettyCashByBuilding({ activeCompany }) {
  return (
    <DynamicReport
      reportName="petty-cash-claim-by-building"
      activeCompany={activeCompany}
      columnsDataProvider={getPettyCashByBuildingColumns}
      rowsDataProvider={getPettyCashByBuildingRows}
      fetchData={getPettyCashByBuilding}
      exportUrl="reports/export-query-report/petty-cash-claim-by-building"
      filtersInitialState={pettyCashByBuildingFilters}
      orderBy="BuildingName"
    />
  );
}
