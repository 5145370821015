import request from "@/utils/api/helpers";

const baseUrl = "/finance/accounting-periods";

export const getAccountingPeriods = () => request({
  url: baseUrl
});

export const getAccountingPeriodById = (id) => request({
  url: `${baseUrl}/${id}`
});

export const createAccountingPeriod = (data) => request({
  url: baseUrl,
  method: "POST",
  data
});

export const updateAccountingPeriod = (id, data) => request({
  url: `${baseUrl}/${id}`,
  method: "PATCH",
  data
});

export const closeAccountingPeriod = (id, number, moduleName) => {
  const data = {
    number,
    moduleName
  };

  return request({
    url: `${baseUrl}/${id}/close`,
    method: "POST",
    data
  });
};

export const reopenAccountingPeriod = (id, number, moduleName) => {
  const data = {
    number,
    moduleName
  };

  return request({
    url: `${baseUrl}/${id}/reopen`,
    method: "POST",
    data
  });
};

export const deleteAccountingPeriod = (id) => request({
  url: `${baseUrl}/${id}`,
  method: "DELETE",
});

export const checkDateInAccountingPeriod = (date) => request({
  url: `${baseUrl}/status/${date}`,
  method: "GET"
});
