import React from "react";
import {
  useTable, useFilters, useSortBy, useRowSelect
} from "react-table";

import { SortableArrows } from "@/components/common";

function Table({
  columns: userColumns, data, handleDelete, handleApprove
}) {
  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } =
    useTable(
      {
        columns: userColumns,
        data,
        remove: (item) => {
          handleDelete(item);
        },
        handleApprove,
      },
      useFilters,
      useSortBy,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => [
          ...columns,
        ]);
      }
    );

  return (
    <table className="table checkbox-row-table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr
            className="table-header-row"
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column) => (
              <th
                className={`table-header-cell ${column.collapse ? "collapse" : ""} ${column.noLeftPadding ? "no-left-padding" : ""}`}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <div className="table-header-cell-content">
                  {column.render("Header")}
                  {column.defaultCanSort ? (
                    <SortableArrows
                      isSorted={column.isSorted}
                      isSortedDesc={column.isSortedDesc}
                    />
                  ) : null}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr className="table-row" {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td
                  className={`table-body-cell ${cell.column.collapse ? "collapse" : ""} ${cell.column.noLeftPadding ? "no-left-padding" : ""}`}
                  {...cell.getCellProps()}
                >
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function UploadDocumentsTable({
  tableColumns, data, isLoading, handleDelete, handleApprove
}) {
  const columns = React.useMemo(() => (tableColumns), [isLoading, tableColumns]);
  const rows = React.useMemo(() => data, [data]);

  return (
    <div className="table-wrapper">
      <Table
        columns={columns}
        data={rows}
        handleDelete={handleDelete}
        handleApprove={handleApprove}
      />
    </div>
  );
}

export default UploadDocumentsTable;
