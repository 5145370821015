import { useEffect, useState, useRef } from "react";
import { useQuery } from "@tanstack/react-query";

import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { useCompanyAccount } from "@/hooks";
import { getTaxRules } from "@/api/finance/taxRuleApi";
import { formatDecimalValues } from "@/utils/helpers";
import { taxableTypes } from "@/utils/finance/constants";

function UnitForm() {
  const ref = useRef(null);
  const [initialState, setInitialState] = useState({});
  const defaultAccounts = useCompanyAccount({
    params: {
      includeCompanyId: true,
      isLinkedWithRecord: false
    }
  });

  const { data: taxRulesData } = useQuery(["tax-rule"], getTaxRules);

  useEffect(() => {
    if (defaultAccounts && Object.keys(initialState)?.length === 0) {
      const {
        unrealisedRentalRevenue, rentalRevenue, unrealisedUtilityRevenue, utilityRevenue
      } =
        defaultAccounts;

      setInitialState((prevState) => ({
        ...prevState,
        unrealizedRevenueAccount: unrealisedRentalRevenue,
        rentalRevenueAccount: rentalRevenue,
        unrealizedUtilityRevenueAccount: unrealisedUtilityRevenue,
        utilityRevenueAccount: utilityRevenue,
      }));
    }
  }, [defaultAccounts]);

  const setTax = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { unitProperty, salesLocation } = formState;

    if (!unitProperty || !salesLocation) return;

    let unitTaxItem = null;

    if (taxableTypes.includes(unitProperty?.value)) {
      const taxItem = taxRulesData?.data.find((i) => i.region === salesLocation?.value);

      if (taxItem) {
        unitTaxItem = {
          label: taxItem?.name,
          value: taxItem?.id
        };
      }
    } else {
      const taxItem = taxRulesData?.data.find((i) => i.name === "EX Exempt (Sales)");

      if (taxItem) {
        unitTaxItem = {
          label: taxItem?.name,
          value: taxItem?.id
        };
      }
    }

    ref.current.setFormValue("tax", unitTaxItem);
  };

  const setBuildingData = (key, value) => {
    const { emirate, name, landlord } = value;
    const data = {
      address: name,
      landlord: []
    };

    if (emirate?.name) {
      data.address += ` ${emirate?.name}`;

      data.salesLocation = {
        label: emirate?.name,
        value: emirate?.id,
      };
    }

    if (landlord?.length) {
      data.landlord = landlord?.map((l) => ({
        ...l,
        label: l.name,
        value: l.id
      }));
    }

    ref.current.setFormState(data);
    setTax("salesLocation", data.salesLocation);
  };

  const setStatus = (key, value) => {
    if (value.value === "Inactive") {
      ref.current.setFormValue("status", {
        label: "Blocked",
        value: "Blocked",
        default: false,
        tabOption: true,
        color: "#363F72"
      });
    } else if (value.value === "Active") {
      ref.current.setFormValue("status", {
        label: "Available",
        value: "Available",
        default: true,
        tabOption: true
      });
      ref.current.setFormValue("inactiveReason", null);
    }
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "building": {
        setBuildingData(key, value);
        break;
      }

      case "commonArea":
      case "builtUpArea": {
        const formState = ref.current.getState();
        const otherKey = key === "commonArea" ? "builtUpArea" : "commonArea";
        const total = parseFloat(value, 10) + parseFloat(formState[otherKey] || 0, 10);
        ref.current.setFormValue("totalUnitArea", formatDecimalValues(total));
        break;
      }

      case "unitProperty": {
        setTax(key, value);
        break;
      }

      case "currentStatus": {
        setStatus(key, value);
        break;
      }

      default:
        break;
    }
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        initialData={initialState}
        objectName={dynamicObjectMap.get("UnitObjectName")}
        showHeader
        onStateChange={onStateChange}
      />
    </BoxedContent>
  );
}

export default UnitForm;
