import PropTypes from "prop-types";

function HorizontalEventCard({ event, navigate }) {
  const { content, job } = event;

  const handleEventClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(
      `/facility-management/work-order/${job.workOrder.id}?jobId=${job.id}`
    );
  };

  return (
    <div
      className={`event-horizontal-container event-bg-${content.type.toLowerCase()}`}
      onClick={handleEventClick}
      role="presentation"
    >
      <div
        className={`event-text-${content.type.toLowerCase()}`}
        onClick={handleEventClick}
        role="presentation"
      >
        {" "}
        <span className="bullet" />
        {content.title}
      </div>
      <p
        className="event-status-text event-status-bg"
        onClick={handleEventClick}
      >
        {content.priority}
      </p>
    </div>
  );
}

HorizontalEventCard.propTypes = {
  event: PropTypes.shape({
    content: PropTypes.shape({
      status: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default HorizontalEventCard;
