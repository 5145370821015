import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import {
  addDays,
  areIntervalsOverlapping,
  differenceInDays,
  getDaysInMonth,
  getOverlappingDaysInIntervals,
  intervalToDuration,
  isAfter,
  isBefore,
} from "date-fns";
import { toast } from "react-hot-toast";
import { isEqual, kebabCase } from "lodash";
import { Button } from "@hydra/atom/components";

import { selectActiveCompany } from "@/store/appSlice";
import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { useModal } from "@/hooks";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getBlanketAgreementTableColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import showToast from "@/utils/toast/helpers";
import { formatDecimalValues, formatDate } from "@/utils/helpers";

const formatBlanketAgreement = (data, fromDate, toDate, company) => {
  const filteredData = data.filter(({ agreementStartDate, agreementEndDate }) => {
    try {
      const isOverlapping = areIntervalsOverlapping(
        {
          start: new Date(agreementStartDate),
          end: new Date(agreementEndDate),
        },
        {
          start: new Date(fromDate),
          end: new Date(toDate),
        },
        {
          inclusive: true,
        }
      );

      if (isOverlapping) return true;
    } catch (error) {
      return false;
    }

    return false;
  });

  const totalItems = [];

  filteredData.forEach((blanketAgreement) => {
    blanketAgreement.detail
      .filter(({ lastRecognitionDate }) => {
        let isAlreadyRecognized = false;
        if (lastRecognitionDate) {
          if (
            isEqual(new Date(toDate), new Date(lastRecognitionDate)) ||
            isAfter(new Date(lastRecognitionDate), new Date(toDate))
          ) {
            isAlreadyRecognized = true;
          }
        }

        if (!isAlreadyRecognized) {
          return true;
        }

        return false;
      })
      .forEach((item) => {
        let expenseRecognitionValue = 0;
        let overlappingDays = 0;

        const pendingAmount = item.amount - item.recognizedExpense;

        const interval1 = {
          start: new Date(blanketAgreement.agreementStartDate),
          end: new Date(blanketAgreement.agreementEndDate),
        };
        const interval2 = {
          start: new Date(fromDate),
          end: new Date(toDate),
        };
        overlappingDays = getOverlappingDaysInIntervals(interval1, interval2) + 1;

        const diffInDays = differenceInDays(
          new Date(blanketAgreement.agreementEndDate),
          new Date(blanketAgreement.agreementStartDate)
        );

        const annualAmount = Number(item.amount * (1 / (diffInDays / 365)));
        const monthlyAmount = formatDecimalValues(annualAmount / 12);

        const isFromDateBeforeStartDate = isBefore(
          new Date(fromDate),
          new Date(blanketAgreement.agreementStartDate)
        );

        const { months, days } = intervalToDuration({
          start: isFromDateBeforeStartDate ?
            new Date(blanketAgreement.agreementStartDate) :
            new Date(fromDate),
          end: addDays(new Date(toDate), 1),
        });

        if (months) {
          expenseRecognitionValue += Number(monthlyAmount * months);
        }

        if (days) {
          const daysInTheMonth = getDaysInMonth(new Date(fromDate));
          const totalDaysDifference = overlappingDays / daysInTheMonth;
          expenseRecognitionValue += Number(monthlyAmount * totalDaysDifference);
        }

        expenseRecognitionValue = formatDecimalValues(expenseRecognitionValue);

        if (pendingAmount && expenseRecognitionValue > pendingAmount) {
          expenseRecognitionValue = pendingAmount;
        }

        const isGenericExpense = blanketAgreement.agreementMethod === "GenericExpense";
        let creditAccount = null;
        let debitAccount = null;

        if (isGenericExpense) {
          const { expenseType } = blanketAgreement;

          debitAccount = {
            label: expenseType?.expenseAccount?.name,
            value: expenseType?.expenseAccount?.id,
          };

          creditAccount = {
            label: expenseType?.prepaidAccount?.name,
            value: expenseType?.prepaidAccount?.id,
          };
        } else {
          const { category } = blanketAgreement;

          debitAccount = {
            label: category?.expenseAccount?.name,
            value: category?.expenseAccount?.id,
          };

          creditAccount = {
            label: blanketAgreement?.clearingAccount?.name,
            value: blanketAgreement?.clearingAccount?.id,
          };
        }

        totalItems.push({
          expenseDetailId: item.id,
          company: {
            label: company.name,
            value: company.id,
          },
          key: item.id,
          isSelected: true,
          vendor: {
            label: blanketAgreement.supplier.name,
            value: blanketAgreement.supplier.id,
            lookupObjectName: "Supplier",
          },
          expense: {
            label: blanketAgreement.number,
            value: blanketAgreement.id,
            lookupObjectName: "BlanketAgreement",
          },
          asset: {
            label: item.asset.name,
            value: item.asset.id,
            lookupObjectName: item.asset.objectName,
          },
          debitAccount,
          creditAccount,
          totalAmount: item.amount || 0,
          recognizedAmount: item.recognizedExpense || 0,
          pendingAmount: pendingAmount || 0,
          expenseRecognitionValue: expenseRecognitionValue || 0,
          description: `Expense Recognition from ${formatDate(new Date(fromDate))} to ${formatDate(
            new Date(toDate)
          )}`,
        });
      });
  });
  return {
    expenseRecognitionDetail: totalItems,
  };
};

const expenseItemsCalculations = (items) => {
  const totalExpenseAmount = items.reduce(
    (accumulator, currentValue) => accumulator + currentValue.totalAmount,
    0
  );
  const totalOpenExpenseAmount = items.reduce(
    (accumulator, currentValue) => accumulator + currentValue.pendingAmount,
    0
  );
  return {
    totalExpenseAmount,
    totalOpenExpenseAmount,
  };
};

function HeaderRightContent({ openModal, showButton, ...rest }) {
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {showButton ? (
        <Button small bordered onClick={openModal}>
          Select Blanket Agreements
        </Button>
      ) : null}
    </defaultComponents.HeaderRightContent>
  );
}

function ExpenseRecognitionForm() {
  const activeCompany = useSelector(selectActiveCompany);
  const ref = useRef(null);
  const [expenseTableData, setExpenseTableData] = useState([]);
  const [state, setState] = useState({});
  const [toastId, setToastId] = useState();
  const { isOpen, closeModal, openModal } = useModal(false);

  const { data: blanketAgreementData, isLoading } = useQuery(
    [kebabCase(dynamicObjectMap.get("BlanketAgreementObjectName")), state.fromDate, state.toDate],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("BlanketAgreementObjectName"), {
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
        status: "Active",
      })
  );

  useEffect(() => {
    if (blanketAgreementData || isLoading) {
      const { fromDate, toDate } = state;
      if (!fromDate || !toDate) return;
      if (isLoading && state.fromDate && state.toDate) {
        setToastId(toast.loading("Loading agreements..."));
      }
      if (!blanketAgreementData) return;
      const { expenseRecognitionDetail } = formatBlanketAgreement(
        blanketAgreementData.data,
        fromDate,
        toDate,
        activeCompany
      );

      if (!expenseRecognitionDetail.length) {
        toast.dismiss(toastId);
        showToast("No agreements found for the given date range!", "info");
      } else {
        toast.dismiss(toastId);
        showToast("Agreement loaded!", "success");
      }

      setExpenseTableData(expenseRecognitionDetail);
      openModal();
    }

    return () => {
      if (toastId) {
        toast.dismiss(toastId);
      }
    };
  }, [blanketAgreementData, state, isLoading]);

  const setExpenseRecognitionDetail = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { fromDate, toDate } = formState;

    if (!fromDate || !toDate) return;
    setState({ fromDate, toDate });
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "fromDate":
      case "toDate": {
        setExpenseRecognitionDetail(key, value);
        break;
      }
      default:
        break;
    }
  };

  const handleConfirm = () => {
    if (expenseTableData.length) {
      const selectedExpenses = expenseTableData.filter((q) => q.isSelected);
      if (selectedExpenses) {
        const { totalExpenseAmount, totalOpenExpenseAmount } =
          expenseItemsCalculations(selectedExpenses);
        ref.current.setFormState({
          expenseRecognitionDetail: selectedExpenses,
          totalExpenseAmount,
          totalOpenExpenseAmount,
        });
      }
    }
    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Blanket Agreement"
        subtitle="Following blanket agreements found for the selected date range"
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        size="large"
      >
        {expenseTableData.length ? (
          <TableWithCheckbox
            data={expenseTableData}
            searchKey={["vendor.label", "expense.label"]}
            columns={getBlanketAgreementTableColumns()}
            setData={setExpenseTableData}
            selectAll
            allowMultiple
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("ExpenseRecognitionObjectName")}
        showHeader
        components={{
          HeaderRightContent: (props) =>
            HeaderRightContent({
              openModal,
              showButton: Boolean(expenseTableData.length),
              ...props,
            }),
        }}
        onStateChange={onStateChange}
      />
    </BoxedContent>
  );
}

export default ExpenseRecognitionForm;
