import { Button, Label, ReactSelect } from "@hydra/atom/components";
import { Header, FormLeftHeader, Accordion, FileUploader } from "@/components/common";
import { setNextStep, setFormValue } from "@/reducers/admin/companySettingsReducer.js";
import UploadDocumentsTable from "./UploadDocumentsTable";
import { getUploadDocumentsTablesTableColumns } from "./UploadDocumentsTableData";
import { uploadCompanyAttachment } from "@/api/admin/companySettingApi";

const documents = [
  {
    label: "DTCM Registration",
    value: "DTCM-registration",
  },
];

function UploadDocuments({ state, dispatch }) {
  const leftContent = (title, subtitle) => (
    <div className="container-header-left-content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );

  const handleFileUpload = () => uploadCompanyAttachment({
    documentType: "DTCM Registration",
    file: state.uploadDocument[0].file,
    companyId: state.companyId,
  });

  return (
    <section className="add-people-setting-body">
      <div className="setting-header">
        <Header
          leftContent={leftContent(
            "Upload Documents",
            "Get emails to find out what’s going on when you’re not online. You can turn them off anytime."
          )}
        />
        <hr className="full-hr" />
      </div>
      <div className="row">
        <div className="col-md-3">
          <FormLeftHeader
            title="Document Type"
            subtitle="Provide Name of Cost Center"
            icon="scan-icon"
          />
        </div>
        <div className="col-md-9">
          <Label htmlFor="documentType" label="Select Document*" />
          <ReactSelect id="documentType" value={documents[0]} options={documents} />
        </div>
      </div>
      <hr className="full-hr" />
      <div className="row">
        <div className="col-md-3">
          <FormLeftHeader
            title="Upload Quotations"
            subtitle="Provide Name of Cost Center"
            icon="upload-cloud-stroke-icon"
          />
        </div>
        <div className="col-md-9">
          <FileUploader
            setFiles={(value) => {
              dispatch(setFormValue("uploadDocument", value));
            }}
            files={state.uploadDocument || []}
            testId="Upload-Company-Document"
          />
        </div>
      </div>
      <hr className="full-hr" />
      <div className="save-documents-container">
        <div>
          <Button
            small
            className="save-documents"
            onClick={() => {
              // dispatch(setFormValue("uploadDocumentList"
              // , [...state.uploadDocumentList, state.uploadDocument]));
              // dispatch(setFormValue("uploadDocument", null));
              // handleFileUpload();
            }}
          >
            Add Document
          </Button>
        </div>
      </div>
      <hr className="full-hr" />
      <Accordion title="Uploaded Documents" count={3}>
        <UploadDocumentsTable
          tableColumns={getUploadDocumentsTablesTableColumns()}
          data={[
            {
              title: { value: "file.png", url: "#" },
              documentType: "DTCM Registration",
              entryDate: "20-08-2023",
            },
            {
              title: { value: "file-2.png", url: "#" },
              documentType: "DTCM Registration",
              entryDate: "20-08-2023",
            },
            {
              title: { value: "file-3.png", url: "#" },
              documentType: "DTCM Registration",
              entryDate: "20-08-2023",
            },
          ]}
        />
      </Accordion>
      <footer className="setting-footer">
        <Button
          small
          className="save-btn"
          onClick={() => {
            dispatch(setNextStep(4));
          }}
        >
          Save and Move Next
        </Button>
      </footer>
    </section>
  );
}

export default UploadDocuments;
