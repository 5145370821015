import { useEffect, useState } from "react";
import { kebabCase, startCase } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import pluralize from "pluralize";

import { Loader } from "@hydra/atom/components";
import { selectActiveCompany } from "@/store/appSlice";

import {
  BoxedContent,
  Header,
  CrossButton,
  Drawer,
  Accordion,
} from "@/components/common";

import request from "@/utils/api/helpers";
import { handleDrawer } from "@/utils/modal/helpers";
import {
  getObjectNameFromId
} from "@/utils/dynamic/helpers";
import {
  formatStaticObjectRecord,
  renderStaticFieldValue,
} from "@/utils/static/helpers";

const getStaticObjectRecordById = (applicationName, objectName, recordId) => request({
  url: `/${kebabCase(applicationName.toLowerCase())}/${kebabCase(
    pluralize(objectName)
  )}/${recordId}`
});

export default function StaticObjectDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();

  const drawers = JSON.parse(searchParams.get("staticObjectDrawers") || null) || [];
  const objectName = drawers.length ? getObjectNameFromId(drawers.at(-1)) : null;

  const { data, isInitialLoading: isLoading } = useQuery(
    [kebabCase(objectName), drawers.at(-1)],
    () => getStaticObjectRecordById("finance", objectName, drawers.at(-1)),
    {
      enabled: Boolean(objectName),
    }
  );

  const handleClose = () => {
    handleDrawer(null, setSearchParams, true, "staticObjectDrawers");
  };

  return (
    <Drawer
      isOpen={Boolean(drawers.at(-1))}
      className="drawer-border drawer-profile-border"
      onClose={handleClose}
    >
      <BoxedContent className="profile-drawer mobile-responsive-drawer dynamic-drawer">
        <Header
          leftContent={startCase(objectName)}
          rightContent={(
            <div className="d-flex align-items-center">
              <CrossButton onClick={handleClose} />
            </div>
          )}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="parent-container">
            {drawers.at(-1) && (
              <ObjectOverview objectName={objectName} data={data?.data} />
            )}
          </div>
        )}
      </BoxedContent>
    </Drawer>
  );
}

function ObjectOverview({ objectName, data }) {
  const [recordDetails, setRecordDetails] = useState([]);
  const activeCompany = useSelector(selectActiveCompany);
  const defaultCurrencyCode = activeCompany && activeCompany?.defaultCurrencyCode ? activeCompany.defaultCurrencyCode : "AED";

  useEffect(() => {
    if (data) {
      const {
        recordData
      } =
        formatStaticObjectRecord({
          data,
          defaultCurrencyCode,
        });

      setRecordDetails(recordData);
    }
  }, [data]);

  return (
    <Accordion title={`${startCase(objectName)} details`}>
      <table>
        <tbody>
          {recordDetails.map((item, index) => (
            <tr key={`item-${index}`}>
              <td className="row-headline">{item.label}</td>
              <td className="row-info">{renderStaticFieldValue(item)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Accordion>
  );
}
