/* eslint-disable max-len */
import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";
import { Pagination } from "@hydra/atom/components";
import Skeleton from "react-loading-skeleton";
import { AlertModal } from "@/components/modals";
import { useModal } from "@/hooks";
import {
  getBankReconciliationTableColumns
} from "./bankReconciliationTableData";

export default function BankReconciliationTable({
  data,
  objectName,
  perPageOptions,
  activeStatusTab,
  headerRight,
  headerLeft,
  isLoading
}) {
  const columnMap = {
    "Bank Reconciliation": getBankReconciliationTableColumns
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageOptions[0]);

  const totalCount = useMemo(
    () => (data?.length ? data.length : 0),
    [data]
  );
  const totalPages = useMemo(
    () => (data?.length ? 3 : 0),
    [data]
  );
  const tableData = useMemo(
    // eslint-disable-next-line no-nested-ternary
    () => (data?.length ? data : null),
    [data]
  );

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };
  const columns = useMemo(
    () =>
      (
        isLoading ?
          columnMap[activeStatusTab](objectName).map((column) => ({
            ...column,
            Cell: Skeleton,
          })) :
          columnMap[activeStatusTab](objectName)),
    [activeStatusTab, objectName]
  );
  const { isOpen, closeModal } = useModal(false);

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } =
    useTable({
      columns,
      data: tableData || []
    });

  const handleDelete = async () => {
    // delete mutation
  };

  return (
    <div className="table-wrapper">
      <div className="filters-row table-view-filters-row gap-increase">
        {headerLeft && headerLeft}
        {headerRight && headerRight}
      </div>

      <div className="overflow">
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className="table-header-row"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className={`table-header-cell ${
                      column.collapse ? "collapse" : ""
                    }`}
                    {...column.getHeaderProps()}
                  >
                    <div className="table-header-cell-content">
                      <span>{column.render("Header")}</span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  className="table-row"
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <td
                      className={`table-body-cell ${
                        cell.column.collapse ? "collapse" : ""
                      }`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!isLoading && (
        <Pagination
          className="dashboard-pagination"
          options={perPageOptions}
          perPage={perPage}
          onSelectChange={(val) => handlePagination(currentPage, val)}
          pageRangeDisplayed={3}
          handlePageClick={(pageNo) =>
            handlePagination(pageNo.selected + 1, perPage)}
          showResults
          offset={0}
          totalItems={totalCount}
          pageCount={Math.ceil(totalPages / perPage.value)}
          reactPaginateProps={{
            previousLabel: <span className="material-icons">&#xe5cb;</span>,
            nextLabel: <span className="material-icons">&#xe5cc;</span>,
            forcePage: currentPage - 1,
          }}
        />
      )}
      <AlertModal
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={handleDelete}
      />
    </div>
  );
}

BankReconciliationTable.propTypes = {
  perPageOptions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  activeStatusTab: PropTypes.string.isRequired,
  headerRight: PropTypes.element.isRequired,
  headerLeft: PropTypes.element.isRequired,
};

BankReconciliationTable.defaultProps = {
  isLoading: false,
};
