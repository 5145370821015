import {
  useState, useRef, useMemo, useEffect
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import { Input, Button } from "@hydra/atom/components";
import { SvgIcon } from "@/components/common";
import { inflows as emptyInflows, outflows as emptyOutflows } from "./profit-and-loss-statement/data";
import { calculateOutputVariables } from "./helpers";
import { ReportFilterDrawer } from "@/components/drawers";
import { getAssetValuationRows, getAssetValuationColumns } from "./asset-valuation-statement";
import { buildTree, getExpandedRows } from "./cells";

function ReportSheetAssetValuation() {
  const ref = useRef();
  const [openingBalance] = useState(10000);
  const [rows, setRows] = useState([]);
  const [rowsExpanded, setRowExpanded] = useState([]);
  const [cashInflow] = useState(() => [...emptyInflows]);
  const [cashOutflow] = useState(() => [
    ...emptyOutflows
  ]);
  const [filterText, setFilterText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState({});

  const onClose = (value) => {
    setIsOpen(value);
  };

  const columns = useMemo(
    () => getAssetValuationColumns(ref?.current?.clientWidth),
    [ref?.current?.clientWidth]
  );

  const inputVariables = {
    cashInflow,
    cashOutflow,
    openingBalance,
  };

  const years = new Array(7).fill(0);
  const outputVariables = calculateOutputVariables(inputVariables, years);
  const plannerData = {
    ...inputVariables,
    ...outputVariables
  };

  useEffect(() => {
    setRows(buildTree(getAssetValuationRows(plannerData)));
  }, []);

  useEffect(() => {
    setRowExpanded(getExpandedRows(rows));
  }, [rows]);

  const handleChanges = (changes) => {
    const newRows = [...rows];
    changes.forEach((change) => {
      const changeRowIdx = rows.findIndex((el) => el.rowId === change.rowId);
      const changeColumnIdx = columns.findIndex(
        (el) => el.columnId === change.columnId
      );
      newRows[changeRowIdx].cells[changeColumnIdx] = change.newCell;
    });
    setRows(buildTree(newRows));
    setRowExpanded(getExpandedRows(newRows));
  };

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-5">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-5 offset-2 btn-icon-container">
          <Button
            onClick={() => setIsOpen(true)}
            small
            bordered
          >
            <Button.Prepend>
              <SvgIcon icon="filter-lines" />
            </Button.Prepend>
          </Button>
          <ReportFilterDrawer
            filters={filters}
            setFilters={setFilters}
            size={400}
            isOpen={isOpen}
            onClose={onClose}
          />
        </div>
      </div>
      <div className="react-grid-container">
        <ReactGrid
          rows={rowsExpanded}
          columns={columns}
          onCellsChanged={handleChanges}
          enableFillHandle
        />
      </div>
    </div>
  );
}

export default ReportSheetAssetValuation;
