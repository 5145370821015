import React from "react";
import PropTypes from "prop-types";
import ActivityItem from "./ActivityItem";
import ActivityItemSkeleton from "./ActivityItemSkeleton";
import { NoDataFound } from "@/components/common";

const ActivityTimeline = React.forwardRef(({ activities, className, isLoading }, ref) => (
  <div className={`activity-timeline-container ${className}`}>
    <div className="activity-items-container">
      {activities.map((activity, idx) => (
        <div key={`activity-item-${idx}`}>
          <ActivityItem activity={activity} isLoading={isLoading} />
        </div>
      ))}
      {isLoading &&
        Array(20)
          .fill({})
          .map((_, index) => <ActivityItemSkeleton key={index} />)}
      {!isLoading && <div className="observer" ref={ref} />}
      {!isLoading && !activities.length && (
        <NoDataFound objectName="Activity" title="No recent activity" />
      )}
    </div>
  </div>
));

ActivityTimeline.propTypes = {
  activities: PropTypes.array.isRequired,
  className: PropTypes.string,
};

ActivityTimeline.defaultProps = {
  className: "",
};

export default ActivityTimeline;
