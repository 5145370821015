import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { components } from "react-select";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { kebabCase } from "lodash";
import qs from "qs";
import {
  Form, FormControl, Input, Label, Loader, TextArea
} from "@hydra/atom/components";

import { LookupField, SelectField } from "@/components/dynamic/fields";
import {
  setFormValue,
  scheduleInspectionInitialState,
  scheduleJobInitialState,
  setServiceRequestData,
  setVisualInspectionData,
  setIncidentReportData,
  setState,
} from "@/reducers/facility/workOrderFormReducer";
import { JobsTable } from "@/components/facility/planboard";
import { RequirePermission, FormLeftHeader, RadioWithIcon } from "@/components/common";
import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import { getDynamicObjectByName } from "@/api/dynamic/dynamicObjectSchemaApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import {
  formatSupervisor,
  formatCategory,
  formatSubCategory,
  formatServiceRequest,
  formatServiceRequestOption,
  formatUnit,
  formatVisualInspection,
  formatIncidentReport,
  formatBlanketAgreement,
  formatBuilding
} from "./helpers";
import { useUser } from "@/hooks";
import { VENDOR_USER_TYPE } from "@/utils/userTypes";
import { getTestId } from "@/utils/helpers";

function Option(props) {
  const { data, testId } = props;
  return (
    <div data-testid={getTestId(`${testId}-Option`)}>
      <components.Option {...props}>
        <div className="react-select-option">
          <p>{data.optionLabel}</p>
          {data.optionDetails ? <p>{data.optionDetails}</p> : null}
        </div>
      </components.Option>
    </div>
  );
}

Option.propTypes = {
  data: PropTypes.object.isRequired,
};

const validationMessages = {
  required: {
    required: "This field is required",
  },
};

const AddWorkOrderStep = React.forwardRef(({ state, dispatch, onSubmit }, ref) => {
  const userType = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const serviceRequestIdParam = searchParams.get("serviceRequest");
  const visualInspectionIdParam = searchParams.get("visualInspection");
  const incidentReportIdParam = searchParams.get("incidentReport");
  const { data: objectSchema } = useQuery(
    ["dynamic-object-schema", kebabCase(dynamicObjectMap.get("WorkOrderObjectName"))],
    () => getDynamicObjectByName(dynamicObjectMap.get("WorkOrderObjectName"))
  );
  const { data: serviceRequestData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ServiceRequestObjectName")), serviceRequestIdParam],
    () =>
      getDynamicObjectRecordById(
        dynamicObjectMap.get("ServiceRequestObjectName"),
        serviceRequestIdParam
      ),
    {
      enabled: Boolean(serviceRequestIdParam && !state.serviceRequest),
    }
  );

  const { data: visualInspectionData } = useQuery(
    [kebabCase(dynamicObjectMap.get("VisualInspectionObjectName")), visualInspectionIdParam],
    () =>
      getDynamicObjectRecordById(
        dynamicObjectMap.get("VisualInspectionObjectName"),
        visualInspectionIdParam
      ),
    {
      enabled: Boolean(visualInspectionIdParam),
    }
  );

  const { data: incidentReportData } = useQuery(
    [kebabCase(dynamicObjectMap.get("IncidentReportObjectName")), incidentReportIdParam],
    () =>
      getDynamicObjectRecordById(
        dynamicObjectMap.get("IncidentReportObjectName"),
        incidentReportIdParam
      ),
    {
      enabled: Boolean(incidentReportIdParam),
    }
  );

  const handleServiceRequest = (serviceRequest) => {
    const value = formatServiceRequest(serviceRequest);
    dispatch(setServiceRequestData(value));
    if (value.priority) dispatch(setFormValue("priority", value.priority));

    if (value) {
      if (value?.value !== serviceRequestIdParam) {
        setSearchParams(
          qs.stringify({
            serviceRequest: value?.value,
          })
        );
      }
    } else {
      setSearchParams({});
    }
  };

  const handleBlanketAgreement = (blanketAgreement) => {
    const value = formatBlanketAgreement(blanketAgreement);
    dispatch(setState(value));
  };

  useEffect(() => {
    if (serviceRequestIdParam && serviceRequestData) {
      handleServiceRequest({
        ...serviceRequestData,
        supervisor: state.supervisor ? state.supervisor : serviceRequestData.supervisor
      });
    }
  }, [serviceRequestData, searchParams.size]);

  useEffect(() => {
    if (visualInspectionIdParam && visualInspectionData) {
      const formattedVI = formatVisualInspection(visualInspectionData);
      dispatch(setVisualInspectionData(formattedVI));
    }
  }, [visualInspectionData, searchParams.size]);

  useEffect(() => {
    if (incidentReportIdParam && incidentReportData) {
      const formattedVI = formatIncidentReport(incidentReportData);
      const incidentType = searchParams.get("type") === "reactive" ? "standAlone" : "serviceRequest";
      dispatch(setIncidentReportData(formattedVI));
      dispatch(setFormValue("incidentType", incidentType));
    }
  }, [incidentReportData, searchParams.size]);
  if (!objectSchema) return <Loader />;
  return (
    <Form
      onSubmit={onSubmit}
      className="modal-form add-work-order-step"
      shouldScrollOnError
    >
      {state.type === "reactive" && !serviceRequestIdParam ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <FormLeftHeader title="Incident Type" icon="check-box-icon" />
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6">
                  <RadioWithIcon
                    label="Service Request"
                    helperText=""
                    optionValue="serviceRequest"
                    value={state.incidentType}
                    onChange={(value) => dispatch(setFormValue("incidentType", value))}
                    disabled={state.isReadOnly}
                  />
                </div>
                <div className="col-md-6">
                  <RadioWithIcon
                    label="Stand Alone"
                    helperText=""
                    optionValue="standAlone"
                    value={state.incidentType}
                    onChange={(value) => dispatch(setFormValue("incidentType", value))}
                    disabled={state.isReadOnly}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr className="full-hr" />
        </>
      ) : null}
      {state.type === "reactive" && state.incidentType === "serviceRequest" ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <FormLeftHeader title="Service Request" icon="disc-icon" />
            </div>
            <div className="col-md-8">
              <LookupField
                field={objectSchema?.document.find((f) => f.name === "ServiceRequest")}
                value={state.serviceRequest}
                onChange={handleServiceRequest}
                formatOption={formatServiceRequestOption}
                showLabel={false}
                placeholder="Select Service Request"
              />
            </div>
          </div>

          <hr className="full-hr" />
        </>
      ) : null}
      <div className="row">
        <div className="col-md-4">
          <FormLeftHeader
            title="Name & Description"
            subtitle="Provide name & description of service request"
            icon="scan-icon"
          />
        </div>
        <div className="col-md-8">
          <FormControl>
            <Label htmlFor="workOrderTitle" label="Title*" required />
            <Input
              autoFocus={state.type === "scheduled"}
              type="text"
              name="workOrderTitle"
              id="workOrderTitle"
              placeholder="Title"
              value={state.title}
              onChange={(value) => dispatch(setFormValue("title", value))}
              rules="required"
              messages={validationMessages.required}
              disabled={state.isReadOnly}
            />
          </FormControl>
          <FormControl>
            <Label htmlFor="workOrderDescription" label="Description" />
            <TextArea
              type="text"
              name="workOrderDescription"
              id="workOrderDescription"
              placeholder="Description"
              value={state.description}
              onChange={(value) => dispatch(setFormValue("description", value))}
              disabled={state.isReadOnly}
            />
          </FormControl>
        </div>
      </div>
      <hr className="full-hr" />

      {state.type === "scheduled" ||
      (state.type === "reactive" && state.incidentType === "standAlone") ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <FormLeftHeader title="Category*" icon="frame-icon" />
            </div>
            <div className="col-md-8">
              <LookupField
                name="Category"
                field={objectSchema?.document.find((f) => f.name === "Category")}
                value={state.category}
                onChange={(value) => dispatch(setFormValue("category", value))}
                formatOption={formatCategory}
                showLabel={false}
                placeholder="Select Category"
                rules="required"
                messages={validationMessages.required}
              />
            </div>
          </div>

          <hr className="full-hr" />

          <div className="row">
            <div className="col-md-4">
              <FormLeftHeader title="Subcategory*" icon="disc-icon" />
            </div>
            <div className="col-md-8">
              <LookupField
                name="Subcategory"
                field={objectSchema?.document.find((f) => f.name === "SubCategory")}
                value={state.subCategory}
                onChange={(value) => dispatch(setFormValue("subCategory", value))}
                formatOption={formatSubCategory}
                showLabel={false}
                placeholder="Select Subcategory"
                rules="required"
                messages={validationMessages.required}
                apiFilters={{
                  category: state?.category?.value,
                }}
              />
            </div>
          </div>

          <hr className="full-hr" />
        </>
        ) : null}
      {state.type === "reactive" && state.incidentType === "serviceRequest" ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <FormLeftHeader title="Category*" icon="frame-icon" />
            </div>
            <div className="col-md-8">
              <LookupField
                name="Category"
                field={objectSchema?.document.find((f) => f.name === "Category")}
                value={state.category}
                onChange={(value) => dispatch(setFormValue("category", value))}
                formatOption={formatCategory}
                showLabel={false}
                placeholder="Select Category"
                rules="required"
                messages={validationMessages.required}
              />
            </div>
          </div>

          <hr className="full-hr" />

          <div className="row">
            <div className="col-md-4">
              <FormLeftHeader title="Subcategory*" icon="disc-icon" />
            </div>
            <div className="col-md-8">
              <LookupField
                name="SubCategory"
                field={objectSchema?.document.find((f) => f.name === "SubCategory")}
                value={state.subCategory}
                onChange={(value) => dispatch(setFormValue("subCategory", value))}
                formatOption={formatSubCategory}
                showLabel={false}
                placeholder="Select Subcategory"
                rules="required"
                messages={validationMessages.required}
                apiFilters={{
                  category: state?.category?.value,
                }}
              />
            </div>
          </div>

          <hr className="full-hr" />

          <div className="row">
            <div className="col-md-4">
              <FormLeftHeader title="Unit*" icon="disc-icon" />
            </div>
            <div className="col-md-8">
              <LookupField
                name="Unit"
                field={objectSchema?.document.find((f) => f.name === "Unit")}
                value={state.unit}
                onChange={(value) => dispatch(setFormValue("unit", value))}
                formatOption={formatUnit}
                showLabel={false}
                placeholder="Select Unit"
                rules="required"
                messages={validationMessages.required}
              />
            </div>
          </div>

          <hr className="full-hr" />
        </>
      ) : null}

      {state.type === "scheduled" && (
        <>
          <div className="row">
            <div className="col-md-4">
              <FormLeftHeader title="Building" icon="disc-icon" />
            </div>
            <div className="col-md-8">
              <LookupField
                name="Building"
                field={objectSchema?.document.find((f) => f.name === "Building")}
                value={state.building}
                onChange={(value) => dispatch(setFormValue("building", value))}
                formatOption={formatBuilding}
                showLabel={false}
                placeholder="Select Building"
              />
            </div>
          </div>
          <hr className="full-hr" />
          <div className="row">
            <div className="col-md-4">
              <FormLeftHeader title="Blanket Agreement" icon="frame-icon" />
            </div>
            <div className="col-md-8">
              <LookupField
                name="BlanketAgreement"
                field={objectSchema?.document.find((f) => f.name === "BlanketAgreement")}
                value={state.blanketAgreement}
                onChange={handleBlanketAgreement}
                formState={state}
                showLabel={false}
                placeholder="Select Blanket Agreement"
                messages={validationMessages.required}
                isEditing={state.isEditing}
              />
            </div>
          </div>
          <hr className="full-hr" />
        </>
      )}

      <div className="row">
        <div className="col-md-4">
          <FormLeftHeader title="Supervisor*" icon="frame-icon" />
        </div>
        <div className="col-md-8">
          <LookupField
            name="Supervisor"
            field={objectSchema?.document.find((f) => f.name === "Supervisor")}
            value={state.supervisor}
            onChange={(value) => dispatch(setFormValue("supervisor", value))}
            formatOption={formatSupervisor}
            showLabel={false}
            placeholder="Select Supervisor"
            rules="required"
            messages={validationMessages.required}
          />
        </div>
      </div>

      <hr className="full-hr" />

      {state.type === "reactive" && state.incidentType === "standAlone" ?
        (
          <>
            <div className="row">
              <div className="col-md-4">
                <FormLeftHeader title="Building" icon="disc-icon" />
              </div>
              <div className="col-md-8">
                <LookupField
                  name="Building"
                  field={objectSchema?.document.find((f) => f.name === "Building")}
                  value={state.building}
                  onChange={(value) => dispatch(setFormValue("building", value))}
                  formatOption={formatBuilding}
                  showLabel={false}
                  placeholder="Select Building"
                />
              </div>
            </div>

            <hr className="full-hr" />
            <div className="row">
              <div className="col-md-4">
                <FormLeftHeader title="Unit" icon="disc-icon" />
              </div>
              <div className="col-md-8">
                <LookupField
                  name="Unit"
                  field={objectSchema?.document.find((f) => f.name === "Unit")}
                  value={state.unit}
                  onChange={(value) => dispatch(setFormValue("unit", value))}
                  formatOption={formatUnit}
                  showLabel={false}
                  placeholder="Select Unit"
                  apiFilters={{
                    building: state?.building?.value,
                  }}
                />
              </div>
            </div>

            <hr className="full-hr" />
          </>
        ) : null}
      {state.type === "reactive" ? (
        <>
          <div className="row">
            <div className="col-md-4">
              <FormLeftHeader title="Priority" icon="disc-icon" />
            </div>
            <div className="col-md-8">
              <SelectField
                name="Priority"
                field={objectSchema?.document.find((f) => f.name === "Priority")}
                value={state?.priority}
                onChange={(value) => dispatch(setFormValue("priority", value))}
                showLabel={false}
                placeholder="Select Priority"
                isEditing={state.isEditing}
              />
            </div>
          </div>

          <hr className="full-hr" />
        </>
      ) : null}

      <div className="row">
        <div className="col-md-4">
          <FormLeftHeader title="Status" icon="disc-icon" />
        </div>
        <div className="col-md-8">
          <SelectField
            name="Status"
            field={objectSchema?.document.find((f) => f.name === "Status")}
            value={state.workOrderStatus}
            onChange={(value) => dispatch(setFormValue("workOrderStatus", value))}
            showLabel={false}
            placeholder="Select Status"
            isEditing={state.isEditing}
          />
        </div>
      </div>

      <hr className="full-hr" />

      {userType !== VENDOR_USER_TYPE ? (
        <RequirePermission
          parent="Model"
          scope={dynamicObjectMap.get("WorkOrderJobObjectName")}
          action="Insert"
        >
          <div className="row">
            <div className="col-md-4">
              <FormLeftHeader
                title={state.type === "reactive" ? "Schedule & Inspection" : "Schedule Job"}
                icon="disc-icon"
              />
            </div>
            {state.type === "reactive" && (
              <div className="col-md-4">
                <div className="buttons-at-start">
                  <button
                    type="button"
                    disabled={!!(state.type === "reactive" && !state.unit)}
                    className="btn-link"
                    onClick={() => dispatch(scheduleInspectionInitialState())}
                  >
                    <span className="btn-background material-icons-outlined">add</span>
                    <span>Schedule an inspection</span>
                  </button>
                </div>
              </div>
            )}
            <div className="col-md-4">
              <div className="buttons-at-start">
                <button
                  type="button"
                  disabled={!!(state.type === "reactive" && !state.unit)}
                  className="btn-link"
                  onClick={() => dispatch(scheduleJobInitialState())}
                >
                  <span className="btn-background material-icons-outlined">add</span>
                  <span>Schedule a job</span>
                </button>
              </div>
            </div>
          </div>
          <hr className="full-hr" />
        </RequirePermission>
      ) : null}
      {state.jobs.length ? (
        <>
          <div className="row">
            <div className="col-md-12">
              <JobsTable
                data={state.jobs}
                dispatch={dispatch}
                isReadOnly={state.isReadOnly}
                isEditing={state.isEditing}
                isVendor={userType === VENDOR_USER_TYPE}
              />
            </div>
          </div>
          <hr className="full-hr" />
        </>
      ) : null}

      <button type="submit" tabIndex={-1} ref={ref} style={{ display: "none" }}>
        Save
      </button>
    </Form>
  );
});

AddWorkOrderStep.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

AddWorkOrderStep.defaultProps = {};

export default AddWorkOrderStep;
