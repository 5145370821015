/* eslint-disable no-plusplus */
import { groupBy, range } from "lodash";
import {
  nonEditable,
  textCell,
  headerCell
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT } from "../constants";
import { formatCurrency } from "@/utils/helpers";

function getHeaderRow(columns) {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: columns.map((column) => headerCell(column.label)),
  };
}

function getGroupedRows(title, groups, totalReceiptAmount, columns, groupIndex) {
  const groupCode = groups[0]?.claimNumber;
  if (groups?.length) {
    return [
      {
        rowId: `${"Claim Number"}-${groupIndex}-Header`,
        height: ROW_HEIGHT,
        cells: [
          headerCell("", "light-green-bg"),
          headerCell(`${"Claim Number  : "} (${groupCode})`, "light-green-bg font-bold"),
          ...range(0, columns.length).map(() => headerCell("", "light-green-bg")),
        ],
      },
      ...groups.map((item, index) => ({
        rowId: item.transactionId,
        height: ROW_HEIGHT,
        cells: columns.map((column) => {
          const textCellArgs = {};

          if (column.formatter) {
            textCellArgs.text = column.formatter(item[column.columnId], index);
          } else {
            textCellArgs.text = item[column.columnId] ?? "";
          }

          return nonEditable(textCell(textCellArgs), column.className || "");
        }),
      })),
      {
        rowId: `${title}-${groupIndex}-Footer`,
        height: ROW_HEIGHT,
        cells: [
          headerCell(title, "total-row"),
          ...range(0, columns.length - 2).map(() => headerCell("", "total-row")),
          headerCell(formatCurrency(totalReceiptAmount), "total-row-credit"),
        ],
      },
    ];
  }
  return [];
}

export function getPettyCashBySupervisorRows({
  data,
  columns
}) {

  if (!data) {
    return [];
  }
  const groups = groupBy(data?.data, "claimNumber");
  const groupValues = Object.values(groups);
  const groupedRows = groupValues.flatMap((group, index) => {
    const totalReceiptAmount = group.reduce(
      (accumulator, currentValue) => accumulator + +(currentValue?.receiptAmount || 0),
      0
    );
    return getGroupedRows("Total", group, totalReceiptAmount, columns, index);
  });
  return [getHeaderRow(columns), ...groupedRows];
}
