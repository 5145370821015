import {
  useRef, useState, useEffect, useCallback
} from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, kebabCase } from "lodash";
import { Button } from "@hydra/atom/components";
import { useSearchParams } from "react-router-dom";
import { formatDate, calculateTax } from "@/utils/helpers";
import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import {
  getDynamicObjectRecords,
  getDynamicObjectRecordById,
} from "@/api/dynamic/dynamicObjectNameApi";
import { useModal } from "@/hooks";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import {
  getProformaInvoiceTableColumns,
  getFacilityContractTableColumns,
} from "@/components/finance/account-receivables/tableWithCheckboxData";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";
import { formatDecimalValues } from "../../../utils/helpers";

const formatProformaInvoice = (invoice) => {
  const {
    id, number, lineItem, tenant, date, tax
  } = invoice;

  const data = {
    key: id,
    proformaInvoice: {
      label: number,
      value: id,
    },
    tenant: {
      label: tenant?.name,
      value: tenant?.id,
      lookupObjectName: dynamicObjectMap.get("TenantObjectName"),
    },
    lineItem: [],
    proformaInvoiceDate: formatDate(new Date(date)),
    isSelected: false,
  };

  if (tax && !isEmpty(tax)) {
    data.tax = {
      ...tax,
      label: tax.name,
      value: tax.id,
    };
  }

  if (lineItem && lineItem.length) {
    lineItem.forEach((invoiceItem) => {
      const {
        label,
        quantity,
        uom,
        amount,
        tax: lineItemTax,
        taxAmount,
        totalAmount,
        account,
      } = invoiceItem;

      const lineItemData = {
        account: {
          label: account.name,
          value: account.id
        },
        tax: {
          label: lineItemTax.name,
          value: lineItemTax.id
        },
        amount,
        taxAmount,
        totalAmount,
        description: `${label}, Quantity:${quantity} ${uom}`,
      };

      data.lineItem.push(lineItemData);
    });
  }

  const subtotal = data.lineItem.reduce(
    (total, currentValue) => total + Number(currentValue.amount),
    0
  );

  const taxAmount = data.lineItem.reduce(
    (total, currentValue) => total + Number(currentValue.taxAmount),
    0
  );

  const totalAmount = data.lineItem.reduce(
    (total, currentValue) => total + Number(currentValue.totalAmount),
    0
  );

  data.subtotal = subtotal;
  data.amountBeforeTax = subtotal;
  data.taxAmount = taxAmount;
  data.total = totalAmount;
  data.openBalance = totalAmount;

  return data;
};

const formatFacilityContract = (facilityContract) => {
  const {
    id,
    number,
    tenant,
    paymentDetail,
    total,
    date,
    contractAmount,
    taxAmount,
    amountBeforeTax,
    tax,
    facilityType,
  } = facilityContract;

  const data = {
    key: id,
    facilityContract: {
      label: number,
      value: id,
    },
    tenant: {
      label: tenant.name,
      value: tenant.id,
      lookupObjectName: dynamicObjectMap.get("TenantObjectName"),
    },
    lineItem: [],
    subtotal: contractAmount,
    amountBeforeTax,
    taxAmount,
    total,
    openBalance: total,
    isSelected: false,
    facilityContractDate: formatDate(new Date(date)),
  };

  if (!isEmpty(tax)) {
    data.tax = {
      ...tax,
      label: tax.name,
      value: tax.id,
    };
  }

  const { realizedAccount } = facilityType;

  if (paymentDetail && paymentDetail.length) {
    paymentDetail.forEach((invoiceItem) => {
      const {
        id: paymentDetailId,
        description,
        amount,
        totalAmount,
        taxAmount: lineItemTaxAmount,
        tax: lineItemTax,
      } = invoiceItem;

      const lineItemData = {
        paymentDetailId,
        taxAmount: lineItemTaxAmount,
        amount,
        totalAmount,
        description,
      };

      if (!isEmpty(lineItemTax)) {
        lineItemData.tax = {
          ...lineItemTax,
          label: lineItemTax.name,
          value: lineItemTax.id,
        };
      }

      if (!isEmpty(realizedAccount)) {
        lineItemData.account = {
          label: realizedAccount.name,
          value: realizedAccount.id,
        };
      }

      data.lineItem.push(lineItemData);
    });
  }

  return data;
};

function HeaderRightContent({ openModal, showButton, ...rest }) {
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {showButton && (
        <Button small bordered onClick={openModal}>
          Select invoice and facility contract
        </Button>
      )}
    </defaultComponents.HeaderRightContent>
  );
}

function GeneralInvoiceForm() {
  const ref = useRef(null);
  const [initialState, setInitialState] = useState({});
  const [state, setState] = useState({});
  const [proformaInvoicesTableData, setProformaInvoicesTableData] = useState([]);
  const [facilityContractsTableData, setFacilityContractsTableData] = useState([]);
  const { isOpen, closeModal, openModal } = useModal(false);
  const [searchParams] = useSearchParams();
  const proformaInvoice = searchParams.get("proformaInvoice");
  const facilityContract = searchParams.get("facilityContract");

  const formatContract = useCallback(
    (contract) => ({
      contract: {
        label: contract.number,
        value: contract.id,
      },
    }),
    []
  );

  const { data: facilityContractData } = useQuery(
    [kebabCase(dynamicObjectMap.get("FacilityContractObjectName")), facilityContract],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("FacilityContractObjectName")),
        facilityContract
      ),
    {
      enabled: Boolean(facilityContract),
    }
  );

  const { data: proformaInvoiceData, isFetched } = useQuery(
    [kebabCase(dynamicObjectMap.get("ProformaInvoiceObjectName")), proformaInvoice],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("ProformaInvoiceObjectName")),
        proformaInvoice
      ),
    {
      enabled: Boolean(proformaInvoice),
    }
  );

  const { data: contractData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ContractObjectName")), proformaInvoice?.tenant?.id],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("ContractObjectName")), {
        tenant: proformaInvoice?.tenant?.id,
      }),
    {
      enabled: Boolean(isFetched) && Boolean(proformaInvoice?.id),
    }
  );

  const { data: proformaInvoicesData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ProformaInvoiceObjectName")), state?.tenant?.value],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("ProformaInvoiceObjectName"), {
        tenant: state?.tenant?.value,
        takePage: 1,
        limitPage: 10,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
      }),
    {
      enabled:
        Boolean(state?.tenant?.value) && Boolean(!proformaInvoice) && Boolean(!facilityContract),
    }
  );

  const { data: facilityContractsData } = useQuery(
    [kebabCase(dynamicObjectMap.get("FacilityContractObjectName")), state?.tenant?.value],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("FacilityContractObjectName"), {
        tenant: state?.tenant?.value,
        takePage: 1,
        limitPage: 10,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
      }),
    {
      enabled:
        Boolean(state?.tenant?.value) && Boolean(!proformaInvoice) && Boolean(!facilityContract),
    }
  );

  useEffect(() => {
    if (facilityContractData) {
      const formattedFacilityContract = formatFacilityContract(facilityContractData);
      setInitialState(formattedFacilityContract);
    }
  }, [facilityContractData]);

  useEffect(() => {
    if (proformaInvoiceData) {
      const formattedInvoice = formatProformaInvoice(proformaInvoiceData);
      const invoice = [formattedInvoice.proformaInvoice];
      formattedInvoice.proformaInvoice = invoice;
      setInitialState(formattedInvoice);
    }
  }, [proformaInvoiceData]);

  useEffect(() => {
    if (contractData && contractData?.data?.length) {
      const formattedContract = formatContract(contractData?.data[0]);
      setInitialState((prevState) => ({
        ...prevState,
        ...formattedContract,
      }));
    }
  }, [contractData]);

  useEffect(() => {
    if (proformaInvoicesData && proformaInvoicesData?.data && state?.tenant) {
      const formattedInvoices = proformaInvoicesData.data.map((invoice) =>
        formatProformaInvoice(invoice)
      );
      if (proformaInvoicesData?.data?.length) {
        setProformaInvoicesTableData(formattedInvoices);
      } else {
        setProformaInvoicesTableData([]);
      }
      openModal();
    }
  }, [proformaInvoicesData, state?.tenant?.value]);

  useEffect(() => {
    if (facilityContractsData && facilityContractsData?.data && state?.tenant) {
      const formattedFacilityContracts = facilityContractsData.data.map((fContract) =>
        formatFacilityContract(fContract)
      );
      if (facilityContractsData?.data?.length) {
        setFacilityContractsTableData(formattedFacilityContracts);
      } else {
        setFacilityContractsTableData([]);
      }
      openModal();
    }
  }, [facilityContractsData, state?.tenant?.value]);

  const setLineItem = (proformaInvoices, facilityContracts) => {
    const data = {
      proformaInvoice: [],
      facilityContract: [],
      lineItem: [],
      subtotal: "",
      amountBeforeTax: "",
      total: "",
      openBalance: ""
    };

    proformaInvoices.forEach((proformaInvoiceItem) => {
      const { proformaInvoice: invoice, lineItem } = proformaInvoiceItem;

      data.proformaInvoice.push(invoice);
      data.lineItem = data.lineItem.concat(lineItem);
    });

    facilityContracts.forEach((facilityContractItem) => {
      const { facilityContract: item, lineItem } = facilityContractItem;

      data.facilityContract.push(item);
      data.lineItem = data.lineItem.concat(lineItem);
    });

    const subtotal = data.lineItem.reduce(
      (total, currentValue) => total + Number(currentValue.amount),
      0
    );

    const totalAmount = data.lineItem.reduce(
      (total, currentValue) => total + Number(currentValue.totalAmount),
      0
    );

    const taxAmount = data.lineItem.reduce(
      (total, currentValue) => total + Number(currentValue.taxAmount),
      0
    );

    data.subtotal = subtotal;
    data.amountBeforeTax = totalAmount;
    data.taxAmount = taxAmount;
    data.total = totalAmount;
    data.openBalance = totalAmount;

    ref.current.setFormState(data);
  };

  const setTaxAndTotal = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { lineItem } = formState;

    const subtotal = lineItem?.reduce(
      (total, currentValue) => total + (currentValue?.amount ? Number(currentValue?.amount) : 0),
      0
    );

    const taxAmount = lineItem?.reduce(
      (total, currentValue) =>
        total + (currentValue?.taxAmount ? Number(currentValue?.taxAmount) : 0),
      0
    );

    const totalAmount = lineItem?.reduce(
      (total, currentValue) =>
        total + (currentValue?.totalAmount ? Number(currentValue?.totalAmount) : 0),
      0
    );

    const data = {
      subtotal,
      taxAmount,
      total: totalAmount,
    };

    data.openBalance = data.total;
    ref.current.setFormState(data);
  };

  const updateLineItem = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { amountOfTax, tax: parentTax, lineItem } = formState;

    const updatedLineItem = lineItem?.map((rent) => {
      let { amount } = rent;
      const { tax, taxAmount: prevTaxAmount } = rent;
      let taxAmount = 0;

      const selectedTax = tax ?? parentTax;

      if (selectedTax && amountOfTax) {
        const parameters = {
          amount,
          amountOfTax,
          tax: selectedTax,
          taxAmount: ""
        };

        if (key === "amountOfTax") {
          parameters.taxAmount = prevTaxAmount || 0;
        }

        const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax(parameters);

        amount = Number(principalAmount || 0);
        taxAmount = Number(calculatedTaxAmount || 0);
      }

      return {
        ...rent,
        amount,
        tax: selectedTax,
        taxAmount,
        totalAmount: Number(amount) + Number(taxAmount),
      };
    });

    ref.current.setFormValue("lineItem", updatedLineItem);
    setTaxAndTotal("lineItem", updatedLineItem);
  };

  const onChildStateChange = ({
    index, key, value, parentField, parentFieldType
  }) => {
    const formState = ref.current.getState();
    const stateKey = `${parentField}${parentFieldType}`;
    let parentFieldState = formState[stateKey] ?? {};

    if (index > -1) {
      parentFieldState = formState[parentField][index];
    }

    if (parentField === "lineItem") {
      switch (key) {
        case "invoiceType": {
          parentFieldState[key] = value;

          if (value) {
            const { invoiceAccount } = value;

            if (invoiceAccount && !isEmpty(invoiceAccount)) {
              parentFieldState.account = {
                label: invoiceAccount.name,
                value: invoiceAccount.id,
              };
            }
          }

          if (!value) {
            parentFieldState.account = null;
          }
          break;
        }
        case "tax":
        case "amount": {
          parentFieldState[key] = value;
          const { tax, amount } = parentFieldState;
          parentFieldState.amount = amount;
          const { amountOfTax } = formState;

          if (tax && amountOfTax) {
            const parameters = {
              amount,
              amountOfTax,
              tax,
              taxAmount: "",
            };
            const { taxAmount, principalAmount } = calculateTax(parameters);
            parentFieldState.taxAmount = taxAmount;
            parentFieldState.totalAmount = formatDecimalValues(
              Number(principalAmount) + Number(taxAmount)
            );
          }
          break;
        }

        default:
          break;
      }
    }
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "tenant":
        setState({
          ...state,
          [key]: value,
        });
        ref.current.setFormValue("tRN", value?.tRN);
        break;

      case "lineItem":
      case "amountOfTax":
        updateLineItem(key, value);
        break;

      default:
        break;
    }
  };

  const handleConfirm = () => {
    if (proformaInvoicesTableData.length || facilityContractsTableData.length) {
      const selectedProformaInvoices = proformaInvoicesTableData.filter((r) => r.isSelected);
      const selectedFacilityContracts = facilityContractsTableData.filter((r) => r.isSelected);
      setLineItem(selectedProformaInvoices, selectedFacilityContracts);
    }

    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Tenant Proforma Invoice & Facility Contract"
        subtitle="Selected tenant has following proforma invoices and facility contracts"
        isOpen={isOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
        size="large"
      >
        {proformaInvoicesTableData.length ? (
          <TableWithCheckbox
            data={proformaInvoicesTableData}
            setData={setProformaInvoicesTableData}
            columns={getProformaInvoiceTableColumns()}
            allowMultiple
          />
        ) : null}
        {facilityContractsTableData.length ? (
          <TableWithCheckbox
            data={facilityContractsTableData}
            setData={setFacilityContractsTableData}
            columns={getFacilityContractTableColumns()}
            allowMultiple
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        initialData={initialState}
        objectName={dynamicObjectMap.get("GeneralInvoiceObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        onChildStateChange={onChildStateChange}
        components={{
          HeaderRightContent: (props) =>
            HeaderRightContent({
              openModal,
              showButton: Boolean(proformaInvoicesTableData.length),
              ...props,
            }),
        }}
      />
    </BoxedContent>
  );
}

export default GeneralInvoiceForm;
