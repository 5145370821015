import PropTypes from "prop-types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function TextEditor({
  value, onChange, error, disabled, testId, className
}) {
  return (
    <>
      <CKEditor
        id={testId}
        editor={ClassicEditor}
        className={className}
        data={value}
        config={{
          toolbar: ["heading", "bold", "italic", "link", "bulletedList", "numberedList"]
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data);
        }}
        disabled={disabled}
      />
      {typeof error === "string" && <span className="error-text">{error}</span>}
    </>
  );
}

TextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

TextEditor.defaultProps = {
  error: false,
  disabled: false,
  testId: ""
};

TextEditor.Type = "HydraValidatableComponent";

export default TextEditor;
