export const barChartData = [
  {
    label: "Jan",
    value: 66,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 66,
  },

  {
    label: "Feb",
    value: 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 166,
  },

  {
    label: "Mar",
    value: 146,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 146,
  },

  {
    label: "Apr",
    value: 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 126,
  },

  {
    label: "May",
    value: 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 126,
  },

  {
    label: "Jun",
    value: 76,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 76,
  },

  {
    label: "Jul",
    value: 40,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 40,
  },

  {
    label: "Aug",
    value: 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 126,
  },

  {
    label: "Sep",
    value: 96,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 96,
  },

  {
    label: "Oct",
    value: 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 166,
  },

  {
    label: "Nov",
    value: 80,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 80,
  },

  {
    label: "Dec",
    value: 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 166,
  },
];

export const barChartData2 = [
  {
    label: "Jan",
    Completed: 66,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Escalated: 66,
  },

  {
    label: "Feb",
    Completed: 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Escalated: 166,
  },

  {
    label: "Mar",
    Completed: 146,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Escalated: 146,
  },

  {
    label: "Apr",
    Completed: 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Escalated: 126,
  },

  {
    label: "May",
    Completed: 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Escalated: 126,
  },

  {
    label: "Jun",
    Completed: 76,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Escalated: 76,
  },

  {
    label: "Jul",
    Completed: 40,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Escalated: 40,
  },

  {
    label: "Aug",
    Completed: 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Escalated: 126,
  },

  {
    label: "Sep",
    Completed: 96,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Escalated: 96,
  },

  {
    label: "Oct",
    Completed: 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Escalated: 166,
  },

  {
    label: "Nov",
    Completed: 80,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Escalated: 80,
  },

  {
    label: "Dec",
    Completed: 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Escalated: 166,
  },
];

export const barChartData3 = [
  {
    label: "Jan",
    "Fixed Assets": 66,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Current Assets": 66,
  },

  {
    label: "Feb",
    "Fixed Assets": 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Current Assets": 166,
  },

  {
    label: "Mar",
    "Fixed Assets": 146,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Current Assets": 146,
  },

  {
    label: "Apr",
    "Fixed Assets": 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Current Assets": 126,
  },

  {
    label: "May",
    "Fixed Assets": 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Current Assets": 126,
  },

  {
    label: "Jun",
    "Fixed Assets": 76,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Current Assets": 76,
  },

  {
    label: "Jul",
    "Fixed Assets": 40,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Current Assets": 40,
  },

  {
    label: "Aug",
    "Fixed Assets": 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Current Assets": 126,
  },

  {
    label: "Sep",
    "Fixed Assets": 96,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Current Assets": 96,
  },

  {
    label: "Oct",
    "Fixed Assets": 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Current Assets": 166,
  },

  {
    label: "Nov",
    "Fixed Assets": 80,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Current Assets": 80,
  },

  {
    label: "Dec",
    "Fixed Assets": 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Current Assets": 166,
  },
];

export const barChartData4 = [
  {
    label: "Jan",
    "Active Spots": 66,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Vacant Spots": 66,
  },

  {
    label: "Feb",
    "Active Spots": 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Vacant Spots": 166,
  },

  {
    label: "Mar",
    "Active Spots": 146,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Vacant Spots": 146,
  },

  {
    label: "Apr",
    "Active Spots": 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Vacant Spots": 126,
  },

  {
    label: "May",
    "Active Spots": 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Vacant Spots": 126,
  },

  {
    label: "Jun",
    "Active Spots": 76,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Vacant Spots": 76,
  },

  {
    label: "Jul",
    "Active Spots": 40,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Vacant Spots": 40,
  },

  {
    label: "Aug",
    "Active Spots": 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Vacant Spots": 126,
  },

  {
    label: "Sep",
    "Active Spots": 96,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Vacant Spots": 96,
  },

  {
    label: "Oct",
    "Active Spots": 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Vacant Spots": 166,
  },

  {
    label: "Nov",
    "Active Spots": 80,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Vacant Spots": 80,
  },

  {
    label: "Dec",
    "Active Spots": 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    "Vacant Spots": 166,
  },
];

export const barChartData5 = [
  {
    label: "Jan",
    Debit: 66,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Credit: 66,
  },

  {
    label: "Feb",
    Debit: 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Credit: 166,
  },

  {
    label: "Mar",
    Debit: 146,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Credit: 146,
  },

  {
    label: "Apr",
    Debit: 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Credit: 126,
  },

  {
    label: "May",
    Debit: 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Credit: 126,
  },

  {
    label: "Jun",
    Debit: 76,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Credit: 76,
  },

  {
    label: "Jul",
    Debit: 40,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Credit: 40,
  },

  {
    label: "Aug",
    Debit: 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Credit: 126,
  },

  {
    label: "Sep",
    Debit: 96,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Credit: 96,
  },

  {
    label: "Oct",
    Debit: 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Credit: 166,
  },

  {
    label: "Nov",
    Debit: 80,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Credit: 80,
  },

  {
    label: "Dec",
    Debit: 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Credit: 166,
  },
];

export const barChartData6 = [
  {
    label: "Jan",
    Income: 66,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Expense: 66,
  },

  {
    label: "Feb",
    Income: 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Expense: 166,
  },

  {
    label: "Mar",
    Income: 146,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 146,
  },

  {
    label: "Apr",
    Income: 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 126,
  },

  {
    label: "May",
    Income: 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    value1: 126,
  },

  {
    label: "Jun",
    Income: 76,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Expense: 76,
  },

  {
    label: "Jul",
    Income: 40,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Expense: 40,
  },

  {
    label: "Aug",
    Income: 126,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Expense: 126,
  },

  {
    label: "Sep",
    Income: 96,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Expense: 96,
  },

  {
    label: "Oct",
    Income: 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Expense: 166,
  },

  {
    label: "Nov",
    Income: 80,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Expense: 80,
  },

  {
    label: "Dec",
    Income: 166,
    valueColor: "#9E77ED",
    value1Color: "#D6BBFB",
    Expense: 166,
  },
];

export const pieChartData = [
  {
    id: "erlang",
    label: "erlang",
    value: 111,
    color: "hsl(9, 70%, 50%)",
  },
  {
    id: "c",
    label: "c",
    value: 35,
    color: "hsl(346, 70%, 50%)",
  },
  {
    id: "elixir",
    label: "elixir",
    value: 53,
    color: "hsl(88, 70%, 50%)",
  },
  {
    id: "lisp",
    label: "lisp",
    value: 403,
    color: "hsl(92, 70%, 50%)",
  },
  {
    id: "haskell",
    label: "haskell",
    value: 183,
    color: "hsl(275, 70%, 50%)",
  },
];

export const calendarChartData = [
  {
    value: 161,
    day: "2016-05-10",
  },
  {
    value: 156,
    day: "2016-01-10",
  },
  {
    value: 44,
    day: "2015-12-29",
  },
  {
    value: 108,
    day: "2015-07-13",
  },
  {
    value: 85,
    day: "2016-10-28",
  },
  {
    value: 388,
    day: "2016-08-06",
  },
  {
    value: 346,
    day: "2016-07-24",
  },
  {
    value: 297,
    day: "2016-02-27",
  },
  {
    value: 176,
    day: "2015-11-29",
  },
  {
    value: 41,
    day: "2015-12-23",
  },
  {
    value: 353,
    day: "2015-09-22",
  },
  {
    value: 246,
    day: "2016-04-15",
  },
  {
    value: 336,
    day: "2016-08-16",
  },
  {
    value: 229,
    day: "2016-11-09",
  },
  {
    value: 170,
    day: "2015-08-22",
  },
  {
    value: 86,
    day: "2015-10-04",
  },
  {
    value: 321,
    day: "2016-03-10",
  },
  {
    value: 378,
    day: "2016-09-22",
  },
  {
    value: 134,
    day: "2015-05-03",
  },
  {
    value: 293,
    day: "2016-12-25",
  },
  {
    value: 113,
    day: "2016-10-05",
  },
  {
    value: 53,
    day: "2016-10-04",
  },
  {
    value: 164,
    day: "2016-09-30",
  },
  {
    value: 330,
    day: "2015-10-19",
  },
  {
    value: 368,
    day: "2015-06-17",
  },
  {
    value: 318,
    day: "2015-07-07",
  },
  {
    value: 213,
    day: "2015-11-30",
  },
  {
    value: 331,
    day: "2016-01-01",
  },
  {
    value: 177,
    day: "2015-12-04",
  },
  {
    value: 355,
    day: "2016-08-23",
  },
  {
    value: 63,
    day: "2016-06-15",
  },
  {
    value: 361,
    day: "2015-06-19",
  },
  {
    value: 161,
    day: "2015-07-11",
  },
  {
    value: 400,
    day: "2016-05-31",
  },
  {
    value: 15,
    day: "2016-07-11",
  },
  {
    value: 8,
    day: "2015-07-17",
  },
  {
    value: 363,
    day: "2016-05-03",
  },
  {
    value: 166,
    day: "2016-11-05",
  },
  {
    value: 252,
    day: "2016-06-14",
  },
  {
    value: 271,
    day: "2016-02-20",
  },
  {
    value: 183,
    day: "2016-09-17",
  },
  {
    value: 210,
    day: "2015-09-25",
  },
  {
    value: 272,
    day: "2015-07-28",
  },
  {
    value: 232,
    day: "2016-05-29",
  },
  {
    value: 353,
    day: "2015-12-09",
  },
  {
    value: 262,
    day: "2015-09-13",
  },
  {
    value: 225,
    day: "2016-07-07",
  },
  {
    value: 381,
    day: "2016-04-11",
  },
  {
    value: 18,
    day: "2016-02-23",
  },
  {
    value: 391,
    day: "2016-11-18",
  },
  {
    value: 68,
    day: "2016-05-02",
  },
  {
    value: 87,
    day: "2016-01-29",
  },
  {
    value: 110,
    day: "2015-12-03",
  },
  {
    value: 382,
    day: "2016-09-10",
  },
  {
    value: 185,
    day: "2016-03-19",
  },
  {
    value: 192,
    day: "2015-06-11",
  },
  {
    value: 382,
    day: "2016-08-14",
  },
  {
    value: 333,
    day: "2015-05-17",
  },
  {
    value: 49,
    day: "2016-09-15",
  },
  {
    value: 52,
    day: "2015-12-31",
  },
  {
    value: 309,
    day: "2016-01-26",
  },
  {
    value: 251,
    day: "2016-07-27",
  },
  {
    value: 90,
    day: "2016-01-14",
  },
  {
    value: 92,
    day: "2016-12-18",
  },
  {
    value: 388,
    day: "2015-08-25",
  },
  {
    value: 292,
    day: "2016-04-03",
  },
  {
    value: 336,
    day: "2015-09-01",
  },
  {
    value: 125,
    day: "2016-04-22",
  },
  {
    value: 268,
    day: "2016-07-13",
  },
  {
    value: 201,
    day: "2016-01-18",
  },
  {
    value: 138,
    day: "2016-04-06",
  },
  {
    value: 10,
    day: "2016-06-25",
  },
  {
    value: 149,
    day: "2015-06-13",
  },
  {
    value: 305,
    day: "2016-05-27",
  },
  {
    value: 260,
    day: "2016-06-24",
  },
  {
    value: 273,
    day: "2016-08-29",
  },
  {
    value: 211,
    day: "2016-06-07",
  },
  {
    value: 182,
    day: "2016-12-12",
  },
  {
    value: 208,
    day: "2015-12-19",
  },
  {
    value: 3,
    day: "2015-09-03",
  },
  {
    value: 112,
    day: "2016-05-07",
  },
  {
    value: 334,
    day: "2015-07-22",
  },
  {
    value: 251,
    day: "2016-06-01",
  },
  {
    value: 86,
    day: "2015-11-26",
  },
  {
    value: 166,
    day: "2015-11-20",
  },
  {
    value: 400,
    day: "2016-10-12",
  },
  {
    value: 143,
    day: "2016-06-16",
  },
  {
    value: 337,
    day: "2016-05-05",
  },
  {
    value: 276,
    day: "2015-07-14",
  },
  {
    value: 360,
    day: "2016-11-02",
  },
  {
    value: 138,
    day: "2015-10-20",
  },
  {
    value: 38,
    day: "2015-06-22",
  },
  {
    value: 108,
    day: "2015-12-07",
  },
  {
    value: 54,
    day: "2015-10-21",
  },
  {
    value: 348,
    day: "2015-04-29",
  },
  {
    value: 89,
    day: "2016-02-03",
  },
  {
    value: 145,
    day: "2016-12-11",
  },
  {
    value: 84,
    day: "2015-07-26",
  },
  {
    value: 304,
    day: "2016-09-24",
  },
  {
    value: 27,
    day: "2016-08-28",
  },
  {
    value: 329,
    day: "2015-07-23",
  },
  {
    value: 226,
    day: "2016-03-06",
  },
  {
    value: 255,
    day: "2016-02-21",
  },
  {
    value: 139,
    day: "2016-07-16",
  },
  {
    value: 151,
    day: "2015-05-02",
  },
  {
    value: 62,
    day: "2016-06-26",
  },
  {
    value: 364,
    day: "2016-07-25",
  },
  {
    value: 1,
    day: "2015-10-27",
  },
  {
    value: 93,
    day: "2016-09-26",
  },
  {
    value: 15,
    day: "2016-09-02",
  },
  {
    value: 155,
    day: "2016-01-02",
  },
  {
    value: 111,
    day: "2015-06-30",
  },
  {
    value: 370,
    day: "2015-05-07",
  },
  {
    value: 121,
    day: "2016-01-17",
  },
  {
    value: 248,
    day: "2016-01-21",
  },
  {
    value: 218,
    day: "2016-10-31",
  },
  {
    value: 99,
    day: "2016-06-02",
  },
  {
    value: 178,
    day: "2016-10-02",
  },
  {
    value: 375,
    day: "2015-04-18",
  },
  {
    value: 18,
    day: "2015-05-19",
  },
  {
    value: 321,
    day: "2016-12-24",
  },
  {
    value: 348,
    day: "2016-12-20",
  },
  {
    value: 220,
    day: "2016-07-08",
  },
  {
    value: 99,
    day: "2015-09-05",
  },
  {
    value: 388,
    day: "2016-03-02",
  },
  {
    value: 361,
    day: "2016-02-10",
  },
  {
    value: 152,
    day: "2016-10-23",
  },
  {
    value: 79,
    day: "2016-06-28",
  },
  {
    value: 323,
    day: "2015-04-17",
  },
  {
    value: 71,
    day: "2015-06-16",
  },
  {
    value: 137,
    day: "2015-09-24",
  },
  {
    value: 39,
    day: "2015-09-12",
  },
  {
    value: 333,
    day: "2015-07-27",
  },
  {
    value: 37,
    day: "2016-12-02",
  },
  {
    value: 328,
    day: "2015-08-09",
  },
  {
    value: 112,
    day: "2015-05-31",
  },
  {
    value: 285,
    day: "2015-08-29",
  },
  {
    value: 318,
    day: "2016-02-25",
  },
  {
    value: 302,
    day: "2016-07-18",
  },
  {
    value: 383,
    day: "2016-10-09",
  },
  {
    value: 113,
    day: "2015-07-05",
  },
  {
    value: 237,
    day: "2016-05-14",
  },
  {
    value: 319,
    day: "2016-05-24",
  },
  {
    value: 271,
    day: "2015-10-24",
  },
  {
    value: 290,
    day: "2015-07-24",
  },
  {
    value: 268,
    day: "2016-10-11",
  },
  {
    value: 225,
    day: "2016-03-22",
  },
  {
    value: 284,
    day: "2016-07-21",
  },
  {
    value: 91,
    day: "2015-12-17",
  },
  {
    value: 229,
    day: "2015-08-02",
  },
  {
    value: 331,
    day: "2016-04-30",
  },
  {
    value: 306,
    day: "2016-02-07",
  },
  {
    value: 182,
    day: "2016-04-18",
  },
  {
    value: 392,
    day: "2016-02-09",
  },
  {
    value: 392,
    day: "2015-05-12",
  },
  {
    value: 352,
    day: "2016-12-05",
  },
  {
    value: 394,
    day: "2015-07-15",
  },
  {
    value: 343,
    day: "2015-12-08",
  },
  {
    value: 386,
    day: "2015-06-23",
  },
  {
    value: 305,
    day: "2016-10-01",
  },
  {
    value: 311,
    day: "2015-11-02",
  },
  {
    value: 129,
    day: "2015-04-12",
  },
  {
    value: 278,
    day: "2016-03-16",
  },
  {
    value: 12,
    day: "2015-09-14",
  },
  {
    value: 56,
    day: "2015-04-30",
  },
  {
    value: 353,
    day: "2016-09-25",
  },
  {
    value: 5,
    day: "2015-06-12",
  },
  {
    value: 316,
    day: "2015-07-16",
  },
  {
    value: 276,
    day: "2015-05-14",
  },
  {
    value: 369,
    day: "2015-12-28",
  },
  {
    value: 99,
    day: "2016-11-04",
  },
  {
    value: 115,
    day: "2015-11-22",
  },
  {
    value: 75,
    day: "2016-05-20",
  },
  {
    value: 315,
    day: "2016-10-25",
  },
  {
    value: 313,
    day: "2015-10-03",
  },
  {
    value: 382,
    day: "2015-10-25",
  },
  {
    value: 191,
    day: "2016-06-09",
  },
  {
    value: 299,
    day: "2015-04-23",
  },
  {
    value: 331,
    day: "2016-07-02",
  },
  {
    value: 382,
    day: "2015-10-06",
  },
  {
    value: 384,
    day: "2016-12-15",
  },
  {
    value: 242,
    day: "2015-08-16",
  },
  {
    value: 324,
    day: "2016-12-21",
  },
  {
    value: 207,
    day: "2016-07-09",
  },
  {
    value: 189,
    day: "2016-10-14",
  },
  {
    value: 242,
    day: "2016-04-05",
  },
  {
    value: 169,
    day: "2016-05-28",
  },
  {
    value: 98,
    day: "2016-06-05",
  },
  {
    value: 101,
    day: "2015-12-12",
  },
  {
    value: 235,
    day: "2016-05-13",
  },
  {
    value: 89,
    day: "2015-12-01",
  },
  {
    value: 266,
    day: "2016-09-09",
  },
  {
    value: 54,
    day: "2016-12-04",
  },
  {
    value: 370,
    day: "2015-09-29",
  },
  {
    value: 11,
    day: "2016-09-20",
  },
  {
    value: 306,
    day: "2016-10-03",
  },
  {
    value: 28,
    day: "2016-02-18",
  },
  {
    value: 158,
    day: "2015-11-25",
  },
  {
    value: 389,
    day: "2015-04-21",
  },
  {
    value: 117,
    day: "2016-12-14",
  },
  {
    value: 298,
    day: "2016-02-04",
  },
  {
    value: 276,
    day: "2016-10-29",
  },
  {
    value: 282,
    day: "2016-08-26",
  },
  {
    value: 117,
    day: "2016-12-23",
  },
  {
    value: 287,
    day: "2016-12-19",
  },
  {
    value: 120,
    day: "2015-11-06",
  },
  {
    value: 156,
    day: "2016-10-27",
  },
  {
    value: 261,
    day: "2016-08-17",
  },
  {
    value: 136,
    day: "2016-01-13",
  },
  {
    value: 166,
    day: "2015-04-02",
  },
  {
    value: 187,
    day: "2016-07-03",
  },
  {
    value: 340,
    day: "2015-04-07",
  },
  {
    value: 337,
    day: "2016-08-31",
  },
  {
    value: 175,
    day: "2016-12-10",
  },
  {
    value: 228,
    day: "2016-05-26",
  },
  {
    value: 162,
    day: "2015-10-28",
  },
  {
    value: 290,
    day: "2015-12-10",
  },
  {
    value: 82,
    day: "2016-01-09",
  },
  {
    value: 393,
    day: "2016-11-19",
  },
  {
    value: 151,
    day: "2016-01-11",
  },
  {
    value: 366,
    day: "2016-03-05",
  },
  {
    value: 32,
    day: "2015-06-27",
  },
  {
    value: 85,
    day: "2016-07-15",
  },
  {
    value: 161,
    day: "2015-08-14",
  },
  {
    value: 348,
    day: "2015-12-18",
  },
  {
    value: 149,
    day: "2016-12-09",
  },
  {
    value: 77,
    day: "2016-12-03",
  },
  {
    value: 122,
    day: "2016-03-13",
  },
  {
    value: 240,
    day: "2015-08-27",
  },
  {
    value: 357,
    day: "2016-05-04",
  },
  {
    value: 265,
    day: "2016-09-04",
  },
  {
    value: 159,
    day: "2015-06-14",
  },
  {
    value: 273,
    day: "2015-11-14",
  },
  {
    value: 297,
    day: "2016-10-17",
  },
  {
    value: 215,
    day: "2016-11-17",
  },
  {
    value: 74,
    day: "2015-09-27",
  },
  {
    value: 27,
    day: "2015-09-16",
  },
  {
    value: 26,
    day: "2015-08-04",
  },
  {
    value: 238,
    day: "2015-12-02",
  },
  {
    value: 80,
    day: "2015-07-09",
  },
  {
    value: 335,
    day: "2015-04-19",
  },
  {
    value: 272,
    day: "2016-05-16",
  },
  {
    value: 260,
    day: "2016-05-21",
  },
  {
    value: 2,
    day: "2015-06-01",
  },
  {
    value: 259,
    day: "2016-03-30",
  },
  {
    value: 14,
    day: "2015-06-03",
  },
  {
    value: 351,
    day: "2015-06-04",
  },
  {
    value: 128,
    day: "2015-08-26",
  },
  {
    value: 39,
    day: "2015-08-21",
  },
  {
    value: 272,
    day: "2015-10-15",
  },
  {
    value: 47,
    day: "2016-09-12",
  },
  {
    value: 367,
    day: "2016-11-06",
  },
  {
    value: 363,
    day: "2015-05-13",
  },
  {
    value: 59,
    day: "2016-11-21",
  },
  {
    value: 264,
    day: "2015-10-22",
  },
  {
    value: 318,
    day: "2016-11-01",
  },
  {
    value: 75,
    day: "2015-08-28",
  },
  {
    value: 125,
    day: "2015-08-20",
  },
  {
    value: 300,
    day: "2015-10-11",
  },
  {
    value: 398,
    day: "2015-07-30",
  },
  {
    value: 193,
    day: "2016-07-31",
  },
  {
    value: 356,
    day: "2015-12-16",
  },
  {
    value: 185,
    day: "2016-11-11",
  },
  {
    value: 282,
    day: "2015-09-17",
  },
  {
    value: 68,
    day: "2016-07-12",
  },
  {
    value: 12,
    day: "2016-03-04",
  },
  {
    value: 302,
    day: "2016-03-27",
  },
  {
    value: 242,
    day: "2015-07-25",
  },
  {
    value: 41,
    day: "2015-04-24",
  },
  {
    value: 101,
    day: "2016-01-27",
  },
  {
    value: 97,
    day: "2016-10-21",
  },
  {
    value: 200,
    day: "2015-04-04",
  },
  {
    value: 24,
    day: "2015-11-16",
  },
  {
    value: 347,
    day: "2015-09-30",
  },
  {
    value: 229,
    day: "2015-05-09",
  },
  {
    value: 124,
    day: "2015-08-19",
  },
  {
    value: 326,
    day: "2016-01-30",
  },
  {
    value: 201,
    day: "2016-08-05",
  },
  {
    value: 58,
    day: "2015-07-04",
  },
  {
    value: 319,
    day: "2015-06-21",
  },
  {
    value: 236,
    day: "2016-03-23",
  },
  {
    value: 115,
    day: "2015-12-30",
  },
  {
    value: 252,
    day: "2015-04-15",
  },
  {
    value: 238,
    day: "2016-09-01",
  },
  {
    value: 34,
    day: "2015-06-18",
  },
  {
    value: 378,
    day: "2016-05-23",
  },
  {
    value: 104,
    day: "2015-09-02",
  },
  {
    value: 119,
    day: "2015-08-11",
  },
  {
    value: 6,
    day: "2016-08-01",
  },
  {
    value: 134,
    day: "2016-09-06",
  },
  {
    value: 47,
    day: "2016-11-15",
  },
  {
    value: 338,
    day: "2016-03-25",
  },
  {
    value: 45,
    day: "2015-08-23",
  },
  {
    value: 118,
    day: "2016-02-06",
  },
  {
    value: 181,
    day: "2015-05-22",
  },
  {
    value: 254,
    day: "2015-04-11",
  },
  {
    value: 302,
    day: "2016-11-23",
  },
  {
    value: 169,
    day: "2015-08-31",
  },
  {
    value: 340,
    day: "2016-03-12",
  },
  {
    value: 314,
    day: "2015-12-25",
  },
  {
    value: 53,
    day: "2016-02-16",
  },
  {
    value: 186,
    day: "2015-11-28",
  },
  {
    value: 275,
    day: "2016-03-08",
  },
  {
    value: 334,
    day: "2015-09-11",
  },
  {
    value: 285,
    day: "2016-10-22",
  },
  {
    value: 75,
    day: "2015-07-18",
  },
  {
    value: 113,
    day: "2015-06-25",
  },
  {
    value: 318,
    day: "2016-12-06",
  },
  {
    value: 126,
    day: "2016-07-06",
  },
  {
    value: 125,
    day: "2015-05-27",
  },
  {
    value: 229,
    day: "2016-07-04",
  },
  {
    value: 190,
    day: "2016-05-25",
  },
  {
    value: 246,
    day: "2016-08-18",
  },
  {
    value: 12,
    day: "2016-08-09",
  },
  {
    value: 32,
    day: "2015-07-10",
  },
  {
    value: 400,
    day: "2015-05-26",
  },
  {
    value: 60,
    day: "2015-12-15",
  },
  {
    value: 79,
    day: "2016-11-22",
  },
  {
    value: 296,
    day: "2016-03-18",
  },
  {
    value: 6,
    day: "2016-10-13",
  },
  {
    value: 328,
    day: "2016-05-06",
  },
  {
    value: 143,
    day: "2016-05-19",
  },
  {
    value: 391,
    day: "2015-04-27",
  },
  {
    value: 270,
    day: "2015-04-26",
  },
  {
    value: 347,
    day: "2016-04-20",
  },
  {
    value: 337,
    day: "2015-08-17",
  },
  {
    value: 338,
    day: "2015-08-03",
  },
  {
    value: 19,
    day: "2016-03-09",
  },
  {
    value: 284,
    day: "2015-05-15",
  },
  {
    value: 252,
    day: "2015-09-26",
  },
  {
    value: 150,
    day: "2016-11-08",
  },
  {
    value: 32,
    day: "2016-03-24",
  },
  {
    value: 215,
    day: "2015-08-15",
  },
  {
    value: 193,
    day: "2016-02-14",
  },
  {
    value: 11,
    day: "2015-04-13",
  },
  {
    value: 43,
    day: "2015-10-05",
  },
  {
    value: 253,
    day: "2016-06-08",
  },
  {
    value: 105,
    day: "2015-12-24",
  },
  {
    value: 384,
    day: "2016-10-24",
  },
  {
    value: 309,
    day: "2016-03-11",
  },
  {
    value: 306,
    day: "2016-04-24",
  },
  {
    value: 61,
    day: "2015-06-26",
  },
  {
    value: 160,
    day: "2016-10-26",
  },
  {
    value: 325,
    day: "2015-06-28",
  },
  {
    value: 190,
    day: "2016-05-17",
  },
  {
    value: 160,
    day: "2015-07-01",
  },
  {
    value: 390,
    day: "2016-05-15",
  },
  {
    value: 63,
    day: "2016-03-07",
  },
  {
    value: 176,
    day: "2016-06-23",
  },
  {
    value: 38,
    day: "2016-04-16",
  },
  {
    value: 282,
    day: "2016-01-05",
  },
  {
    value: 349,
    day: "2015-05-05",
  },
  {
    value: 224,
    day: "2016-06-13",
  },
  {
    value: 318,
    day: "2015-06-05",
  },
  {
    value: 386,
    day: "2016-04-19",
  },
  {
    value: 151,
    day: "2016-02-13",
  },
  {
    value: 34,
    day: "2016-12-30",
  },
  {
    value: 83,
    day: "2016-03-15",
  },
  {
    value: 242,
    day: "2016-12-31",
  },
  {
    value: 170,
    day: "2016-10-18",
  },
  {
    value: 286,
    day: "2016-06-27",
  },
  {
    value: 320,
    day: "2016-08-20",
  },
  {
    value: 45,
    day: "2016-04-23",
  },
  {
    value: 97,
    day: "2016-01-22",
  },
  {
    value: 322,
    day: "2016-01-25",
  },
  {
    value: 324,
    day: "2015-12-22",
  },
  {
    value: 99,
    day: "2015-04-05",
  },
  {
    value: 229,
    day: "2016-11-14",
  },
  {
    value: 287,
    day: "2016-08-02",
  },
  {
    value: 112,
    day: "2016-04-14",
  },
  {
    value: 258,
    day: "2015-04-16",
  },
  {
    value: 238,
    day: "2015-10-26",
  },
  {
    value: 142,
    day: "2015-06-02",
  },
  {
    value: 341,
    day: "2016-04-09",
  },
  {
    value: 245,
    day: "2015-07-12",
  },
  {
    value: 54,
    day: "2016-08-10",
  },
  {
    value: 87,
    day: "2016-06-22",
  },
  {
    value: 153,
    day: "2015-11-15",
  },
  {
    value: 281,
    day: "2016-01-03",
  },
  {
    value: 376,
    day: "2016-04-10",
  },
];

export const lineChartData = [
  {
    id: "japan",
    color: "hsl(4, 70%, 50%)",
    data: [
      {
        x: "Jan",
        y: 234,
      },
      {
        x: "Feb",
        y: 19,
      },
      {
        x: "Mar",
        y: 11,
      },
      {
        x: "Apr",
        y: 100,
      },
      {
        x: "May",
        y: 186,
      },
      {
        x: "Jun",
        y: 68,
      },
      {
        x: "Jul",
        y: 120,
      },
      {
        x: "Aug",
        y: 100,
      },
      {
        x: "Sep",
        y: 151,
      },
      {
        x: "Oct",
        y: 295,
      },
      {
        x: "Nov",
        y: 164,
      },
      {
        x: "Dec",
        y: 58,
      },
    ],
  },
  {
    id: "france",
    color: "hsl(14, 70%, 50%)",
    data: [
      {
        x: "Jan",
        y: 14,
      },
      {
        x: "Feb",
        y: 243,
      },
      {
        x: "Mar",
        y: 158,
      },
      {
        x: "Apr",
        y: 244,
      },
      {
        x: "May",
        y: 80,
      },
      {
        x: "Jun",
        y: 38,
      },
      {
        x: "Jul",
        y: 239,
      },
      {
        x: "Aug",
        y: 206,
      },
      {
        x: "Sep",
        y: 62,
      },
      {
        x: "Oct",
        y: 222,
      },
      {
        x: "Nov",
        y: 172,
      },
      {
        x: "Dec",
        y: 36,
      },
    ],
  },
];
