import PropTypes from "prop-types";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { DynamicFormContainer } from "@/components/dynamic";

function MoveOutWorkflowFormContainer({ task, recordData, setIsLoading, onSuccess }) {
  const renderForm = () => {
    if (
      task?.objectName === dynamicObjectMap.get("MoveOutRequestObjectName") &&
      task?.currentStep === "Review Inspection"
    ) {
      return (
        <DynamicFormContainer
          initialData={{
            mODate: null
          }}
          objectName={task.objectName}
          selectedId={recordData?.id}
          setIsLoading={setIsLoading}
          navigate={false}
          showButtons={false}
          layout="UpdateFormModel"
          onSuccess={onSuccess}
        />
      );
    }

    return null;
  };

  return <div>{renderForm()}</div>;
}

MoveOutWorkflowFormContainer.propTypes = {
  task: PropTypes.object.isRequired,
  recordData: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default MoveOutWorkflowFormContainer;
