import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  StatusCircle, Status,
} from "@/components/common";
import { statusColorMap } from "@/utils/maps/statusColorMap";
import { CustomActionDropdown } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { checkPermission } from "@/store/userSlice";
import {
  formatDate
} from "@/utils/helpers";

function TruncatedTextCell({ value }) {
  return (
    <div className="truncated-text-cell">
      <p>{value}</p>
    </div>
  );
}

TruncatedTextCell.propTypes = {
  value: PropTypes.string.isRequired,
};

function ActionCell({ toggleAlertDialogue, editJob, row }) {

  const filteredActions = () => {
    const actions = [
      {
        title: "Delete",
        onClick: () => toggleAlertDialogue(row.original.key),
        icon: "trash-icon"
      },
      {
        title: "Edit",
        onClick: () => { editJob(row.original.key); },
        icon: "edit-icon"
      },
    ];
    return actions.filter((item) => checkPermission("Model", dynamicObjectMap.get("WorkOrderJobObjectName"), item.title));

  };
  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={filteredActions()}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId="Jobs-Table-Action-Dropdown"
      />
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
  toggleAlertDialogue: PropTypes.func.isRequired,
  editJob: PropTypes.func.isRequired,
};

function DateRangeCell({ row }) {
  if (!row.original?.date) return <div />;

  return (
    <div className="date-range-cell">
      <span>{row.original.date[0] ? formatDate(new Date(row.original.date[0])) : ""}</span>
      <span> - </span>
      <span>{row.original.date[1] ? formatDate(new Date(row.original.date[1])) : ""}</span>
    </div>
  );
}

DateRangeCell.propTypes = {
  row: PropTypes.object.isRequired,
};

function StatusCell({ value }) {
  return (
    <Status baseClassName="status-cell" status={value.value} bgColor={statusColorMap.get(value.value?.toLowerCase())}>
      <StatusCircle color={statusColorMap.get(value.value?.toLowerCase())} />
    </Status>
  );
}

StatusCell.propTypes = {
  value: PropTypes.object.isRequired,
};

function LinkCell({ value, row }) {
  return (
    <div className="link-cell truncated-text-cell">
      <Link to={`/facility-management/work-order/${row.original.workOrderId}?jobId=${row.original.key}`}>
        {value}
      </Link>
    </div>
  );
}

LinkCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
};

function CreatedAtDateCell({ value }) {
  return (
    <div className="date-cell">
      {formatDate(new Date(value), "dd/MM/yyyy")}
    </div>
  );
}

CreatedAtDateCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const getJobColumns = (isReadOnly, isVendor) => {
  const columns = [
    {
      Header: "Number",
      accessor: "number",
    },
    {
      Header: "Title",
      accessor: "title",
      Cell: TruncatedTextCell
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: StatusCell
    },
    {
      Header: "Scheduled Date",
      accessor: "date",
      Cell: DateRangeCell
    },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: CreatedAtDateCell,
      defaultCanSort: true,
    },
  ];

  if (isReadOnly) {
    columns[0].Cell = LinkCell;
  }

  if (!isReadOnly && !isVendor) {
    columns.push({
      Header: "Action",
      accessor: "action",
      Cell: ActionCell,
    });
  }

  columns[columns.length - 1].collapse = true;

  return columns;
};

export const getJobMobileColumns = (isReadOnly, isVendor) => {
  const columns = [
    {
      Header: "Title",
      accessor: "title",
      Cell: TruncatedTextCell
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: StatusCell
    },
  ];
  if (isReadOnly) {
    columns[0].Cell = LinkCell;
  }

  if (!isReadOnly && !isVendor) {
    columns.push({
      Header: "Action",
      accessor: "action",
      Cell: ActionCell,
    });
  }

  return columns;
};

export const getJobData = (data) => data.map((job, index) => ({
  key: job.id,
  number: job.number || index + 1,
  workOrderId: job.workOrderId ? job.workOrderId : "",
  title: job.title,
  status: job.status,
  date: job.dateRange,
  createdAt: job.createdAt || new Date(),
  action: "",
}));
