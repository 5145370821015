import {
  useEffect, useState
} from "react";
import PropTypes from "prop-types";
import { FormControl, Input } from "@hydra/atom/components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { pascalize } from "humps";
import FieldLabel from "./FieldLabel";
import { formatNumber, formatDecimalValues } from "@/utils/helpers";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { useDebounce } from "@/hooks";
import { validationFieldDynamicObjectRecord, getFieldDynamicObjectRecordName, getFieldDynamicObjectArabicRecordName } from "@/api/dynamic/dynamicObjectNameApi";
import showToast from "@/utils/toast/helpers";

const getInputFieldType = (fieldType) => {
  switch (fieldType) {
    case "money":
    case "percentage":
    case "area":
      return "number";

    case "autonumber":
      return "text";

    default:
      return fieldType;
  }
};

const getInputFieldPlaceholder = (field, objectName) => {
  if (field.camelizedName === "bankAccount" && objectName === "Supplier") {
    return "IBAN";
  }

  const fieldType = field.objectFieldType.toLowerCase();
  switch (fieldType) {
    case "email":
      return "enter.email@here.com";

    default:
      return "";
  }
};

const handleChequeNumberChange = (chequeNo) => {
  let value = chequeNo;
  const maxLength = 6;
  while (value.length < maxLength) {
    value = `0${value}`;
  }
  if (value.length > maxLength) {
    value = value.substring(0, maxLength);
  }

  return value;
};

function InputField({
  field, value, onChange, name, error, showLabel, state = {}, disabled, objectName = "", handleDispatch = null, checkValidation, isEmailUnique, ...rest
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [validateEmailFieldValue, setValidateEmailFieldValue] = useState("");
  const [validateUniqueText, setValidateUniqueText] = useState(false);
  const [nameApiResult, setName] = useState();
  const [arabicNameApiResult, setArabicName] = useState();
  const debouncedUniqueTextSearch = useDebounce(validateUniqueText, 800);
  const debouncedSearch = useDebounce(arabicNameApiResult, 300);
  const queryClient = useQueryClient();
  const debouncedEmailSearch = useDebounce(validateEmailFieldValue, 300);

  const updateUniqueNames = async () => {
    if (["Expense", "AmcInvoice", "PurchaseInvoice"].includes(objectName) && debouncedUniqueTextSearch) {
      const data = await Promise.all(["Expense", "AmcInvoice", "PurchaseInvoice"]?.map((item) =>
        validationFieldDynamicObjectRecord({
          data: debouncedUniqueTextSearch,
          objectName: item,
          fieldName: ["AmcInvoice", "PurchaseInvoice"].includes(item) ? pascalize("SupplierInvoiceNr") : pascalize("InvoiceNr")
        })
      ));

      if (data.some((r) => r?.exists)) {
        handleDispatch("isUniqueText", {
          ...state.isUniqueText,
          [field.name]: false
        });
        showToast(`${field.label} already exists`, "error");
      } else {
        handleDispatch("isUniqueText", {
          ...state.isUniqueText,
          [field.name]: true
        });
      }
    }
  };

  useEffect(() => {
    if (!debouncedUniqueTextSearch) return;
    updateUniqueNames();
  }, [debouncedUniqueTextSearch]);

  const { data, isFetched } = useQuery(
    [debouncedEmailSearch],
    () => validationFieldDynamicObjectRecord({
      data: debouncedEmailSearch,
      objectName,
      fieldName: "Email"
    }),
    {
      enabled: Boolean((debouncedEmailSearch && field.objectFieldType === "Email") && checkValidation),
    }
  );

  const { data: itemName, isFetched: isFetchedNameApi } = useQuery(
    [nameApiResult],
    () => getFieldDynamicObjectRecordName({
      fullName: nameApiResult
    }),
    {
      enabled: Boolean(nameApiResult && field.objectFieldType === "Text" && ["Tenant", "Landlord", "Lead", "Supplier", "Agent", "Customer"].includes(objectName)),
    }
  );

  const { data: itemArabicName, isFetched: isFetchedArabicNameApi } = useQuery(
    [`arabic-${debouncedSearch}`],
    () => getFieldDynamicObjectArabicRecordName({
      fullName: debouncedSearch,
      outputLanguage: "Arabic"
    }),
    {
      enabled: Boolean(debouncedSearch && field.objectFieldType === "Text" && ["Tenant", "Landlord", "Lead", "Supplier", "Agent", "Customer"].includes(objectName)),
    }
  );

  useEffect(() => {
    if (data && handleDispatch) {
      if (data.exists) {
        handleDispatch("isEmailUnique", false);
        showToast("Email already exists", "error");
      } else {
        handleDispatch("isEmailUnique", true);
      }
    }
  }, [data, isFetched]);

  useEffect(() => {
    if (itemArabicName && handleDispatch) {
      handleDispatch("nameArabic", itemArabicName.fullName);
    }
  }, [itemArabicName, isFetchedArabicNameApi]);

  useEffect(() => {
    if (itemName && handleDispatch) {
      handleDispatch("firstName", itemName.firstName);
      handleDispatch("middleName", itemName.middleName);
      handleDispatch("lastName", itemName.lastName);
    }
  }, [itemName, isFetchedNameApi]);

  const renderAppendIcon = () => {
    if (field.currency) {
      return (
        <Input.AppendIcon>
          <span>{field.currency}</span>
        </Input.AppendIcon>
      );
    }

    if (field.objectFieldType === "Percentage") {
      return (
        <Input.AppendIcon>
          <span>%</span>
        </Input.AppendIcon>
      );
    }

    if (field.objectFieldType === "Area") {
      return (
        <Input.AppendIcon>
          <span>sq. m</span>
        </Input.AppendIcon>
      );
    }

  };

  const onBlur = (inputValue, onBlurFunc) => {
    if (field.objectFieldType === "Money") {
      setIsEditing(false);
      if (inputValue) {
        const formattedNumber = formatDecimalValues(inputValue);
        onChange(formattedNumber);
      }
    }
    if (field.camelizedName === "chequeNo") {
      setIsEditing(false);
      if (inputValue) {
        const formattedNumber = handleChequeNumberChange(inputValue);
        onChange(formattedNumber);
      }
    }
    if (onBlurFunc) onBlurFunc();
  };
  const onChangeInput = (inputValue) => {
    if (field.objectFieldType === "Text" && ["Tenant", "Lead", "Landlord", "Agent", "Customer"].includes(objectName)) {
      if (field.name === "name") {
        setArabicName(inputValue);
        if (["Individual", "Staff", "Intercompany", "FamilyRelated"].includes(state?.type?.value)) {
          setName(inputValue);
          if (inputValue === nameApiResult) {
            return;
          }
          queryClient.invalidateQueries({
            queryKey: [nameApiResult]
          });
        }
      } else if (field.name === "tradeLicenseNumber") {
        if (["Company", "Government"].includes(state?.type?.value)) {
          if (inputValue) {
            if (inputValue === validateUniqueText && !state?.isUniqueText[field.name]) {
              showToast("Trade License Number already exists", "error");
            } else {
              setValidateUniqueText(
                inputValue);
              queryClient.invalidateQueries({
                queryKey: [`unique-text-${inputValue}`]
              });
            }
          }
        }
      } else if (field.name === "emiratesID") {
        const numbersOnly = inputValue.match(/\d+/g);
        const concatenatedNumbers = numbersOnly?.join("");
        if (concatenatedNumbers) {
          if (concatenatedNumbers === validateUniqueText && !state.isUniqueText[field.name]) {
            showToast("Emirates ID already exists", "error");
          } else {
            setValidateUniqueText(concatenatedNumbers);
            queryClient.invalidateQueries({
              queryKey: [`unique-text-${concatenatedNumbers}`]
            });
          }
        }
        onChange(concatenatedNumbers);
        return;
      }
    } else if (field.objectFieldType === "Text" && ["Component", "Building", "Supplier", "PurchaseInvoice"].includes(objectName)) {
      if (field.name === "name" || field.name === "supplierInvoiceNr") {
        if (inputValue) {
          if (objectName === "Supplier") {
            setArabicName(inputValue);
          }
          if (inputValue === validateUniqueText && !state.isUniqueText[field.name] && field.name === "name") {
            showToast(`${objectName} name already exists`, "error");
          } else {
            setValidateUniqueText(inputValue);
            queryClient.invalidateQueries({
              queryKey: [`unique-text-${inputValue}`]
            });
          }
        }
      }
    } else if (field.objectFieldType === "Text" && field.name === "number" && objectName === dynamicObjectMap.get("EmployeeObjectName")) {
      if (inputValue) {
        if (inputValue === validateUniqueText && !state.isUniqueText[field.name]) {
          showToast(`${objectName} Number already exists`, "error");
        } else {
          setValidateUniqueText(inputValue);
          queryClient.invalidateQueries({
            queryKey: [`unique-text-${inputValue}`]
          });
        }
      }
    } else if (field.objectFieldType === "Text" && field.name === "invoiceNr" && objectName === dynamicObjectMap.get("ExpenseObjectName")) {
      if (inputValue) {
        if (inputValue === validateUniqueText && !state.isUniqueText[field.name]) {
          // showToast(`${objectName} already exists`, "error");
        } else {
          setValidateUniqueText(inputValue);
          queryClient.invalidateQueries({
            queryKey: [`unique-text-${inputValue}`]
          });
        }
      }
    } else if (field.objectFieldType === "Text" && field.name === "supplierInvoiceNr" && objectName === dynamicObjectMap.get("AmcInvoiceObjectName")) {
      if (inputValue) {
        if (inputValue === validateUniqueText && !state.isUniqueText[field.name]) {
          // showToast(`${objectName} already exists`, "error");
        } else {
          setValidateUniqueText(inputValue);
          queryClient.invalidateQueries({
            queryKey: [`unique-text-${inputValue}`]
          });
        }
      }
    }
    if (field.objectFieldType === "Email") {
      if (inputValue) {
        if (inputValue === validateEmailFieldValue && !isEmailUnique) {
          showToast("Email already exists", "error");
        } else {
          setValidateEmailFieldValue(inputValue);
          queryClient.invalidateQueries({
            queryKey: [inputValue]
          });
        }
      }
    }

    onChange(inputValue);
  };

  const renderInputField = () => {
    switch (field.objectFieldType.toLowerCase()) {
      case "money":
        return (
          isEditing ? (
            <Input
              id={name}
              type={getInputFieldType(field.objectFieldType.toLowerCase())}
              value={value}
              onChange={onChange}
              disabled={field.disabled || disabled}
              onWheel={(e) => e.target.blur()}
              step="0.01"
              {...rest}
              onBlur={() => onBlur(value, rest.onBlur)}
            >
              {renderAppendIcon()}
            </Input>
          ) : (
            <Input
              id={name}
              type="text"
              value={value ? formatNumber(value) : ""}
              onFocus={() => setIsEditing(true)}
              disabled={field.disabled || disabled}
              {...rest}
              onBlur={() => onBlur(value, rest.onBlur)}
            >
              {renderAppendIcon()}
            </Input>
          )
        );

      default:
        return (
          <Input
            id={name}
            type={getInputFieldType(field.objectFieldType.toLowerCase())}
            value={value}
            onChange={(e) => onChangeInput(e)}
            disabled={field.disabled || disabled}
            onWheel={(e) => e.target.blur()}
            dir={field.direction || "ltr"}
            placeholder={getInputFieldPlaceholder(field, objectName)}
            {...rest}
            onBlur={() => onBlur(value, rest.onBlur)}
          >
            {renderAppendIcon()}
          </Input>
        );
    }
  };

  return (
    <>
      <FormControl>
        {
          showLabel ?
            (
              <FieldLabel
                label={field.label}
                labelFor={field.name}
                helperText={field.helperText}
                isRequired={field.required}
              />
            ) : null
        }
        {renderInputField()}
      </FormControl>
      {typeof error === "string" && <span className="error-text">{error}</span>}
    </>
  );
}

InputField.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  checkValidation: PropTypes.bool
};

InputField.defaultProps = {
  value: "",
  onChange: () => { },
  name: "tuli-input-field",
  error: false,
  showLabel: true,
  disabled: false,
  checkValidation: true
};

InputField.Type = "HydraValidatableComponent";

export default InputField;
