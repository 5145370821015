import {
  Checkbox, Loader, Radio,
  Input
} from "@hydra/atom/components";

import { useEffect, useState } from "react";
import { format, parse } from "date-fns";
import ProfileImg from "@/assets/images/profile-img.png";
import { SvgIcon, Header, Avatar, HeaderLeftContent } from "@/components/common";
import { setFormValue } from "@/reducers/facility/workOrderFormReducer";
import {
  getFullName, getTestId, formatDate, formatApiPayloadDate
} from "@/utils/helpers";
import { useDebounce } from "@/hooks";
import { Pill } from "@/components/dashboard";

const formatTime = (inputTime) => {
  const parsedTime = parse(inputTime, "HH:mm:ss", new Date());
  const formattedTime = format(parsedTime, "h:mm:ss a");
  return formattedTime;
};

function TruncatedTextCell({ value, type = "default" }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (value.split(" ").length > 2) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (value.split(" ").length > 2) {
      setIsHovered(false);
    }
  };

  const displayText = (value?.split(" ").slice(0, 2).join(" ") || "") + (value?.split(" ").length > 2 ? "" : "");

  return (
    <div className="truncated-text-cell title" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {type === "default" ? <p className="text">{displayText}</p> : <a className="text">{displayText}</a>}
      {isHovered && <div className="tooltip">{value}</div>}
    </div>
  );
}

function AddWorkOrderInspectionList({
  value = [], onChange, internal, list, isLoading, proposedTime, dispatch, state
}) {
  const [selectedProposedTimeItem, setSelectedProposedTimeItem] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const debouncedSearch = useDebounce(filterText, 300);

  useEffect(() => {
    onChange(list.filter((item) => item.selected));
  }, [list]);

  useEffect(() => {
    if (debouncedSearch) {
      const filteredList = list.filter((item) =>
        item.label?.toLowerCase().includes(filterText.toLowerCase()) ||
      item?.technician?.number?.toLowerCase().includes(filterText.toLowerCase())
      );
      setFilteredItems(filteredList);
    } else {
      setFilteredItems(list);
    }
  }, [debouncedSearch, list]);

  useEffect(() => {
    if (proposedTime?.length > 0) {
      const selectedProposedTimeItemTemp = proposedTime.find((proposeItem) => (
        formatApiPayloadDate(new Date(state.dateRange[0])) ===
        formatApiPayloadDate(new Date(proposeItem.startDate)) &&
        formatApiPayloadDate(new Date(state.dateRange[1])) ===
        formatApiPayloadDate(new Date(proposeItem.endDate)) &&
          state.timeRange[0] === `${proposeItem.startTime}` &&
          state.timeRange[1] === `${proposeItem.endTime}`
      ));

      if (selectedProposedTimeItemTemp) {
        setSelectedProposedTimeItem(selectedProposedTimeItemTemp);
      }
    }
  }, []);

  const onClick = (item) => {
    let finalList = [];
    if (value?.filter((i) => i).some((techItem) => techItem.value === item.value)) {
      if (internal) {
        finalList = value.filter((techItem) => techItem.value !== item.value);
      } else {
        finalList = [item];
      }
    } else if (internal) finalList = [...value, item];
    else {
      finalList = [item];
    }
    onChange(finalList);
  };

  const onItemChange = (proposeItem) => {
    dispatch(setFormValue("dateRange", [proposeItem?.startDate,
      proposeItem?.endDate]));
    dispatch(setFormValue("timeRange", [`${proposeItem?.startTime}`,
      `${proposeItem?.endTime}`]));
    setSelectedProposedTimeItem(proposeItem);
  };

  return (
    <div className="list-content">
      <Header
        leftContent={(
          <>
            <HeaderLeftContent
              title={proposedTime?.length > 0 ? "Proposed Time" : "Available Technicians"}
              subtitle={proposedTime?.length > 0 ? "These are proposed Date and time from External Supervisor" : "You can select multiple Technicians"}
            />
            <div className="col-md-4">
              <Input
                className="input-height-fix"
                value={filterText}
                onChange={setFilterText}
                placeholder="Search by name, number"
              />
            </div>
          </>
        )}
      />
      <div className="tech-card-list">
        {isLoading ? <Loader /> : (
          <>
            {(proposedTime?.length > 0) && proposedTime.map((proposeItem, i) => (
              <div
                key={`technician-card-${i}`}
                className="tech-card-container"
                onClick={() => onItemChange(proposeItem)}
                role="presentation"
                data-testid={getTestId(`Inspection-Technician-${i}`)}
              >
                <div className="header">
                  <div className="d-flex">
                    <div className="tech-card-img">
                      <img
                        loading="lazy"
                        className="profile-img"
                        src={ProfileImg}
                        alt="profile-img"
                      />
                    </div>
                    <div className="tech-title-container">
                      <div>
                        <TruncatedTextCell value={getFullName(proposeItem.externalSupervisor)} />
                      </div>
                      <div className="sub-title">{proposeItem.externalSupervisor.supplier?.name}</div>
                    </div>
                  </div>
                  <div className="">
                    <div className="schedule-radio">
                      <Radio
                        itemValue
                        label=""
                        type="radio"
                        value={selectedProposedTimeItem?.id === proposeItem.id}
                        onChange={() => {
                          setSelectedProposedTimeItem(proposeItem.id);
                        }}
                      >
                        <Radio.CheckIcon>
                          <span className="material-icons">circle</span>
                        </Radio.CheckIcon>
                      </Radio>
                    </div>
                  </div>
                </div>
                <div className="tech-card-subtitle-container">
                  <div className="tech-card-subtitle">
                    <SvgIcon icon="calender-icon-3" />
                    {`${formatDate(new Date(proposeItem.startDate))} - ${formatDate(new Date(proposeItem.endDate))}`}
                  </div>
                  <div className="tech-card-subtitle">
                    <SvgIcon icon="time-square" />
                    {`${formatTime(proposeItem.startTime)}-${formatTime(proposeItem.endTime)}`}
                  </div>
                </div>
              </div>
            )
            )}
            {!(proposedTime?.length > 0) && filteredItems.map((item, i) => (
              <div
                key={`technician-card-${i}`}
                className="tech-card-container"
                onClick={() => onClick(item)}
                role="presentation"
              >
                <div className="header header-technician">
                  <div className="d-flex">
                    <div className="avatar-wrap" />
                    <div className="tech-card-img">
                      <div className="avatar-wrap">
                        <Avatar name={item.label} imageUrl={item?.technician?.profilePicture && item?.technician?.profilePicture[0]} size="medium" status="online" />
                      </div>
                    </div>
                    <div className="tech-title-container">
                      <TruncatedTextCell value={item.label} />
                      <div className="sub-title">{item.subtitle}</div>
                      <div key={`pill-${item.id}`} className="d-flex">
                        <Pill text={item.technician?.number || item?.number} variant="primary" />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    {internal ? (
                      <Checkbox
                        id={i}
                        name="Data"
                        value={value?.some((techItem) => techItem?.value === item?.value)}
                        onChange={() => onClick(item)}
                      />
                    ) : (
                      <div className="schedule-radio">
                        <Radio
                          itemValue
                          label=""
                          type="radio"
                          value={value?.some(
                            (techItem) => techItem?.value === item?.value)}
                          onChange={() => onClick(item)}
                        >
                          <Radio.CheckIcon>
                            <span className="material-icons">circle</span>
                          </Radio.CheckIcon>
                        </Radio>
                      </div>
                    )}
                  </div>
                </div>
                {internal && (
                  <div className="tech-card-subtitle-container">
                    <div className="tech-card-subtitle">
                      <SvgIcon icon="calender-icon-3" />
                      {item.date}
                    </div>
                    <div className="tech-card-subtitle">
                      <SvgIcon icon="time-square" />
                      {item.time}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default AddWorkOrderInspectionList;
