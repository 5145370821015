import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { Pill } from "@/components/dashboard";

export default function CardAnalytics({
  title,
  subtitle,
  rate,
  isRatePositive,
  link,
}) {
  return (
    <div className="card-analytics">
      {subtitle && <h2 className="subtitle">{subtitle}</h2>}
      <header>
        <h1 className="title">{title}</h1>
        {rate && (
        <Pill
          text={`${rate}%`}
          icon={isRatePositive ? "arrow-up-icon" : "arrow-down-icon"}
          variant={isRatePositive ? "success" : "error"}
        />
        )}
      </header>

      {link && (
        <div className="bottom">
          <Link to={link}>View Report</Link>
        </div>
      )}
    </div>
  );
}

CardAnalytics.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subtitle: PropTypes.string,
  rate: PropTypes.number,
  isRatePositive: PropTypes.bool,
  link: PropTypes.string,
};

CardAnalytics.defaultProps = {
  subtitle: "",
  rate: 0,
  isRatePositive: true,
  link: "",
};
