import Slider from "react-slick";
import { Outlet } from "react-router-dom";
import backgroundImg from "@/assets/images/auth-bg.jpg";

function AuthPageLayout() {
  return (
    <div className="auth-page-layout">
      <div className="left">
        <Outlet />
      </div>
      <div className="right">
        <img src={backgroundImg} alt="" />
        <div className="right-content">
          <h3>Tuli Web Application</h3>
          <SimpleSlider />
        </div>
      </div>
    </div>
  );
}

export default AuthPageLayout;

function CustomSlide({ text }) {
  return <p>{text}</p>;
}

function SimpleSlider() {
  const settings = {
    className: "login-slider",
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider {...settings}>
      <div>
        <CustomSlide text="Manage your Tenants and Properties" />
      </div>
      <div>
        <CustomSlide text="Manage your Service Requests and Work Orders" />
      </div>
      <div>
        <CustomSlide text="Manage your Assets and Materials" />
      </div>
    </Slider>
  );
}
