import request from "@/utils/api/helpers";

const baseUrl = "/finance/companies";

export const getCompanies = () => request({
  url: baseUrl
});

export const getCompanyById = (id) => request({
  url: `${baseUrl}/${id}`
});

export const createCompany = (data) => request({
  url: baseUrl,
  method: "POST",
  data
});

export const updateCompany = (id, data) => request({
  url: `${baseUrl}/${id}`,
  method: "PATCH",
  data
});

export const deleteCompany = (id) => request({
  url: `${baseUrl}/${id}`,
  method: "DELETE",
});
