import { useEffect, useState, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { camelize } from "humps";

import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { useCompanyAccount } from "@/hooks";
import { getLedgerAccounts } from "@/api/finance/ledgerAccountApi";
import { selectCompanies } from "@/store/appSlice";

function SupplierForm() {
  const companies = useSelector(selectCompanies);
  const ref = useRef(null);
  const [initialState, setInitialState] = useState({});
  const defaultAccounts = useCompanyAccount({
    params: {
      accountType: "Supplier Payable,Supplier Advance,Supplier Refund",
      includeCompanyId: false,
      isLinkedWithRecord: false,
    },
    accountMap: {
      supplierPayable: "payableAccount",
      supplierAdvance: "advanceAccount",
      supplierRefund: "refundAccount",
    },
    format: "AccountMap"
  });

  const { data: ledgerAccounts } = useQuery(
    ["ledger-account", { includeCompanyId: false }],
    () => getLedgerAccounts({ includeCompanyId: false })
  );

  useEffect(() => {
    if (defaultAccounts && !initialState.areAccountsSelected) {
      const { supplierPayable, supplierAdvance, supplierRefund } = defaultAccounts;

      setInitialState((prevState) => ({
        ...prevState,
        areAccountsSelected: true,
        ...supplierPayable,
        ...supplierAdvance,
        ...supplierRefund
      }));
    }
  }, [defaultAccounts]);

  const onStateChange = (key, value) => {
    switch (key) {
      case "additionalType": {
        const supplierPayableAccount = {};
        const additionalType = value?.value;

        if (additionalType) {
          let accountCode = 0;

          switch (additionalType) {
            case "LocalSupplier": {
              accountCode = "210100009";
              break;
            }

            case "Foreign": {
              accountCode = "210100007";
              break;
            }

            case "Government": {
              accountCode = "210100008";
              break;
            }

            case "Shareholder": {
              accountCode = "210100010";
              break;
            }

            default:
              break;
          }

          if (ledgerAccounts) {
            const supplierAccounts = ledgerAccounts.data.filter((a) => a.code === accountCode);
            if (supplierAccounts.length) {
              companies.forEach((company) => {
                const { label, value: companyId } = company;
                const camelCaseCompanyName = camelize(label);
                const pascalCaseFieldName = "PayableAccount";
                const stateKey = `${camelCaseCompanyName}${pascalCaseFieldName}`;

                const companyAccount = supplierAccounts.find((a) => a.companyId === companyId);

                if (companyAccount) {
                  supplierPayableAccount[stateKey] = {
                    label: companyAccount.name,
                    value: companyAccount.id
                  };
                }
              });
            }
          }
        }

        ref.current.setFormState(supplierPayableAccount);
        break;
      }

      default:
        break;
    }
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        onStateChange={onStateChange}
        initialData={initialState}
        objectName={dynamicObjectMap.get("SupplierObjectName")}
        showHeader
      />
    </BoxedContent>
  );
}

export default SupplierForm;
