import React from "react";
import {
  useTable, useFilters, useSortBy
} from "react-table";
import Skeleton from "react-loading-skeleton";

import { useNavigate } from "react-router-dom";
import { SortableArrows } from "@/components/common";

function Table({ columns: userColumns, data }) {
  const navigate = useNavigate();
  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useFilters,
    useSortBy,
  );

  const onRowClick = (e, rowProps) => {
    const { tagName, parentNode } = e.target;
    const parentClassName = parentNode?.className?.trim();
    const tagNames = ["BUTTON", "A", "svg"];
    const parentClassNames = [
      "table-row",
      "table-body-cell",
      "truncated-text-cell",
      "status-cell",
      "link-cell",
      "auto-number-cell",
    ];

    if (!tagNames.includes((tagName.split(" "))[0]) && parentClassNames.includes((parentClassName.split(" "))[0])) {
      navigate(`${rowProps.original.id}`);
    }
  };

  return (
    <table className="table checkbox-row-table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr className="table-header-row" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className={`table-header-cell ${column.collapse ? "collapse" : ""} ${
                  column.noLeftPadding ? "no-left-padding" : ""
                }`}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <div className="table-header-cell-content">
                  {column.render("Header")}
                  {column.defaultCanSort ? (
                    <SortableArrows isSorted={column.isSorted} isSortedDesc={column.isSortedDesc} />
                  ) : null}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr className="table-row" onClick={(e) => onRowClick(e, row)} {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td
                  className={`table-body-cell ${cell.column.collapse ? "collapse" : ""} ${
                    cell.column.noLeftPadding ? "no-left-padding" : ""
                  }`}
                  {...cell.getCellProps()}
                >
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function CheckboxFinanceMasterTable({
  tableColumns, data, isLoading, handleDelete, showCheckbox
}) {
  const columns = React.useMemo(
    () =>
      (isLoading ?
        tableColumns.map((column) => ({
          ...column,
          Cell: Skeleton,
        })) :
        tableColumns),
    [isLoading, tableColumns]
  );
  const rows = React.useMemo(() => data, [data]);

  return (
    <div className="table-wrapper">
      <div className="overflow">
        <Table
          columns={columns}
          data={rows}
          handleDelete={handleDelete}
          showCheckbox={showCheckbox}
        />
      </div>
    </div>
  );
}

CheckboxFinanceMasterTable.defaultProps = {
  showCheckbox: true
};

export default CheckboxFinanceMasterTable;
