import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { useSearchParams } from "react-router-dom";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import {
  getDynamicObjectRecordById,
} from "@/api/dynamic/dynamicObjectNameApi";
import useTaskRedirect from "@/hooks/useTaskRedirect";

const formatMoveInRequest = (moveInRequestData) => ({
  moveInRequest: {
    id: moveInRequestData.id,
    label: moveInRequestData.number,
    number: moveInRequestData.number,
    value: moveInRequestData.id,
  },
  contract: {
    id: moveInRequestData.contract.id,
    label: moveInRequestData.contract.number,
    number: moveInRequestData.contract.number,
    value: moveInRequestData.contract.id,}
});

const formatUnit = (unit) => ({
  fixedAsset: unit.fixedAsset,
  checkList: unit.keys.map((key) => ({
    ...key,
    item: {
      label: key.item,
      value: key.item
    },
  }))
});

function MoveInCheckListForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});
  const [initialState, setInitialState] = useState(null);
  const { redirect } = useTaskRedirect();
  const [searchParams] = useSearchParams();
  const moveInRequestId = searchParams.get("moveInRequest");

  const { data: moveInRequestData, isFetched } = useQuery(
    [kebabCase(dynamicObjectMap.get("MoveInRequestObjectName")), moveInRequestId],
    () =>
      getDynamicObjectRecordById(
        dynamicObjectMap.get("MoveInRequestObjectName"),
        moveInRequestId
      ),
    {
      enabled: Boolean(moveInRequestId),
    }
  );

  const { data: unitData } = useQuery(
    [kebabCase(dynamicObjectMap.get("UnitObjectName")), moveInRequestData?.contract?.unit[0]?.id],
    () =>
      getDynamicObjectRecordById(
        dynamicObjectMap.get("UnitObjectName"),
        moveInRequestData?.contract?.unit[0]?.id
      ),
    {
      enabled: Boolean(isFetched),
    }
  );

  useEffect(() => {
    if (!moveInRequestData || !unitData) { return; }
    const formattedMoveInRequest = formatMoveInRequest(moveInRequestData);
    const formattedUnit = formatUnit(unitData);
    setInitialState({ ...formattedMoveInRequest, ...formattedUnit });
  }, [moveInRequestData, unitData]);

  const setTotalAmount = (value) => {
    const { total } = ref.current.getState();
    ref.current.setFormValue("total", Number(total) + Number(value));
  };

  const onStateChange = (key, value) => {
    if (key === "tenant") {
      setState({
        ...state,
        [key]: value,
      });
    }

    if (key === "taxAmount") {
      setTotalAmount(value);
    }
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("MoveInCheckListObjectName")}
        showHeader
        showLinkedViews
        initialData={initialState}
        onSuccess={(id) =>
          redirect(-1, {
            recordId: id,
            success: true,
          })}
        navigate={false}
        onStateChange={onStateChange}
      />
    </BoxedContent>
  );
}

export default MoveInCheckListForm;
