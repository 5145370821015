import request from "@/utils/api/helpers";

const baseUrl = "/configuration/company-settings";

export const getCompanySetting = () => request({
  url: baseUrl
});

export const getCompanySettingById = (id) => request({
  url: `${baseUrl}/${id}`
});

export const createCompanySetting = (data) => request({
  url: baseUrl,
  method: "POST",
  data
});

export const uploadCompanyAttachment = (data) => {

  const formData = new FormData();
  formData.append("file", data.file, data.file.name);
  formData.append("companyId", data.companyId);
  formData.append("documentType", data.documentType);

  return request({
    url: "/configuration/company-documents",
    method: "POST",
    headers: {
      "Content-type": "multipart/form-data",
    },
    data: formData,
  });
};

export const updateCompanySetting = (id, data) => request({
  url: `${baseUrl}/${id}`,
  method: "PATCH",
  data
});

export const updateCompanySettingFinancial = (id, data) => request({
  url: `${baseUrl}/${id}/finance`,
  method: "PUT",
  data
});

export const updateCompanySettingProfile = (id, data) => request({
  url: `${baseUrl}/${id}/profile`,
  method: "PUT",
  data
});

export const updateCompanySettingAlert = (id, data) => request({
  url: `${baseUrl}/${id}/newsletter`,
  method: "PUT",
  data
});

export const deleteCompanySetting = (id) => request({
  url: `${baseUrl}/${id}`,
  method: "DELETE",
});
