import { getCollectionRows, getCollectionColumns } from "./collection-statement";
import { getCollection } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const collectionFilters = {
  TenantName: "*",
  CompanyName: "*",
  TenantType: "*",
  ComponentNumber: "*",
  PaymentMethod: "*",
  PaymentType: "*",
  CollectionType: "*",
  PaymentStatus: "*"
};

const collectionFilterOptions = [
  { key: "tenantName", apiKey: "TenantName", keyType: "label" },
  { key: "companyName", apiKey: "CompanyName" },
  { key: "tenantType", apiKey: "TenantType", },
  { key: "componentNumber", apiKey: "ComponentNumber" },
  { key: "paymentMethod", apiKey: "PaymentMethod" },
  { key: "paymentType", apiKey: "PaymentType" },
  { key: "collectionType", apiKey: "CollectionType" },
  { key: "paymentStatus", apiKey: "PaymentStatus" }
];

export default function ReportCollection({ activeCompany }) {
  return (
    <DynamicReport
      reportName="collection-report"
      activeCompany={activeCompany}
      columnsDataProvider={getCollectionColumns}
      rowsDataProvider={getCollectionRows}
      fetchData={getCollection}
      exportUrl="reports/export-query-report/collection-report"
      filtersInitialState={collectionFilters}
      filterOptions={collectionFilterOptions}
      orderBy="CollectionDate"
    />
  );
}
