import { getExpenseDetailsRows, getExpenseDetailsColumns } from "./expense-details";
import { getExpenseDetails } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const filters = {
  ExpenseNumber: "*",
  SupplierId: "*",
  ExpenseTypeId: "*",
  AssetId: "*",
  AssetName: "*"
};
const additionalFilterOptions = [
  { key: "expenseNumber", apiKey: "ExpenseNumber", keyType: "number"},
  { key: "supplierId", apiKey: "SupplierId" },
  { key: "expenseTypeId", apiKey: "ExpenseTypeId" },
  { key: "assetName", apiKey: "AssetName", keyType: "string" },
];

export default function ReportExpenseDetails({ activeCompany }) {
  return (
    <DynamicReport
      reportName="expense-details"
      activeCompany={activeCompany}
      columnsDataProvider={getExpenseDetailsColumns}
      rowsDataProvider={getExpenseDetailsRows}
      fetchData={getExpenseDetails}
      exportUrl="reports/export-query-report/expense-details"
      filtersInitialState={filters}
      filterOptions={additionalFilterOptions}
      isPaginated={false}
    />
  );
}
