import { useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, kebabCase } from "lodash";
import { DynamicFormContainer } from "@/components/dynamic";
import { TASK_TYPES } from "@/components/workflow/constants";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import { formatDecimalValues } from "@/utils/helpers";

function FixedAssetDisposalWorkflowFormContainer({
  task, recordData, setIsLoading, onSuccess
}) {
  const updateFormRef = useRef(null);
  const { data } = useQuery(
    [kebabCase(dynamicObjectMap.get("ItemObjectName")), recordData.asset.id],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("ItemObjectName"), recordData.asset.id),
    {
      enabled: task?.currentStep === "Update Disposal Request",
    }
  );

  const setUpdateDisposalRequestInitialData = () => {
    const {
      accumulatedDepreciation,
      purchaseDate,
      purchasePrice,
      bookValue,
      building
    } = data;

    const initialData = {
      historicalCost: purchasePrice,
      purchaseDate: new Date(purchaseDate),
      bookValue,
      accumulatedDepreciation: accumulatedDepreciation ?? 0,
    };

    if (building) {
      const { bankAccount } = building;

      if (bankAccount) {
        initialData.bankAccount = {
          label: bankAccount.name,
          value: bankAccount.id
        };
      }
    }

    updateFormRef.current.setFormState(initialData);
  };

  useEffect(() => {
    if (data && task?.currentStep === "Update Disposal Request") {
      setUpdateDisposalRequestInitialData();
    }
  }, [data]);

  const approvalInitialData = useMemo(() => {
    if (task?.currentStep !== "Approval from Financial Analyst") {
      return {};
    }

    const { receivedAmount, bookValue } = recordData;
    const gainOrLoss = formatDecimalValues(Number(receivedAmount - bookValue));

    return {
      netAmount: gainOrLoss,
    };
  }, []);

  const cashReceiptInitialData = useMemo(() => {
    if (task?.currentStep !== "Add Received Amount") {
      return {};
    }

    const { asset } = recordData;
    const { outputTax } = asset;

    if (outputTax && !isEmpty(outputTax)) {
      return {
        tax: {
          label: outputTax.name,
          value: outputTax.id,
          ...outputTax
        }
      };
    }

    return {};
  }, []);

  const onStateChange = (key, value) => {
    if (task?.currentStep === "Add Received Amount") {
      if (key === "receivedAmount" || key === "tax") {
        const formState = updateFormRef.current.getState();
        formState[key] = value;
        const { tax, receivedAmount } = formState;

        let saleRate = 0;

        if (tax && !isEmpty(tax)) {
          saleRate = tax.saleRate;
        }

        const taxAmount = formatDecimalValues(Number((receivedAmount * saleRate) / 100));
        const totalAmount = Number(taxAmount) + Number(receivedAmount);

        updateFormRef.current.setFormState({
          taxAmount,
          totalAmount
        });
      }
    }
  };

  const renderForm = () => {
    if (task?.currentStep === "Select Customer" && task?.taskType === TASK_TYPES.UPDATE) {
      return (
        <DynamicFormContainer
          objectName={task.objectName}
          selectedId={recordData?.id}
          setIsLoading={setIsLoading}
          onStateChange={onStateChange}
          navigate={false}
          showButtons={false}
          layout={task?.currentStep}
          onSuccess={onSuccess}
        />
      );
    }

    if (task?.currentStep === "Add Received Amount" && task?.taskType === TASK_TYPES.UPDATE) {
      return (
        <DynamicFormContainer
          ref={updateFormRef}
          initialData={cashReceiptInitialData}
          objectName={task.objectName}
          selectedId={recordData?.id}
          setIsLoading={setIsLoading}
          onStateChange={onStateChange}
          navigate={false}
          showButtons={false}
          layout={task?.currentStep}
          onSuccess={onSuccess}
        />
      );
    }

    if (task?.currentStep === "Update Disposal Request" && task?.taskType === TASK_TYPES.UPDATE) {
      return (
        <DynamicFormContainer
          ref={updateFormRef}
          objectName={task.objectName}
          selectedId={recordData?.id}
          setIsLoading={setIsLoading}
          navigate={false}
          showButtons={false}
          layout={task?.currentStep}
          onSuccess={onSuccess}
        />
      );
    }

    if (
      task?.currentStep === "Approval from Financial Analyst" &&
      task?.taskType === TASK_TYPES.APPROVAL
    ) {
      return (
        <DynamicFormContainer
          initialData={approvalInitialData}
          objectName={task.objectName}
          selectedId={recordData?.id}
          setIsLoading={setIsLoading}
          navigate={false}
          showButtons={false}
          layout={task?.currentStep}
        />
      );
    }

    return null;
  };

  return <div>{renderForm()}</div>;
}

FixedAssetDisposalWorkflowFormContainer.propTypes = {
  task: PropTypes.object.isRequired,
  recordData: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default FixedAssetDisposalWorkflowFormContainer;
