import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import DynamicObjectOverview from "@/pages/dynamic/list-views/DynamicObjectOverview";

function PettyCashTransactionOverview() {
  return (
    <DynamicObjectOverview
      objectName={dynamicObjectMap.get("PettyCashTransactionObjectName")}
      filters={{
        type: "Debit"
      }}
      showFilters
    />
  );
}

export default PettyCashTransactionOverview;
