import { useEffect, useState } from "react";
import { chunk, kebabCase, startCase } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import pluralize from "pluralize";
import { Loader } from "@hydra/atom/components";

import { selectActiveCompany } from "@/store/appSlice";
import {
  BoxedContent, Header, TwoColumnList, HeaderLeftContent
} from "@/components/common";

import request from "@/utils/api/helpers";

import { getObjectNameFromId } from "@/utils/dynamic/helpers";
import { formatStaticObjectRecord, renderStaticFieldValue } from "@/utils/static/helpers";

const getStaticObjectRecordById = (applicationName, objectName, recordId) =>
  request({
    url: `/${kebabCase(applicationName.toLowerCase())}/${kebabCase(
      pluralize(objectName)
    )}/${recordId}`,
  });

function StaticObjectDetails({ id: idProps, showHeader = true }) {
  const { id: idParams, interimPeriodId } = useParams();
  const [recordDetails, setRecordDetails] = useState([]);
  const activeCompany = useSelector(selectActiveCompany);
  const defaultCurrencyCode = activeCompany ? activeCompany.defaultCurrencyCode : "AED";
  const id = idParams || idProps;

  const objectName = getObjectNameFromId(id);

  const { data, isInitialLoading: isLoading } = useQuery(
    [kebabCase(objectName), id],
    () => getStaticObjectRecordById("finance", objectName, id),
    {
      enabled: Boolean(objectName),
    }
  );

  useEffect(() => {
    if (data) {
      const { recordData } = formatStaticObjectRecord({
        data: data?.data,
        interimPeriodId,
        defaultCurrencyCode,
      });
      setRecordDetails(recordData);
    }
  }, [data]);

  const renderRecordDetails = () => {
    const rows = recordDetails.length > 30 ? Math.ceil(recordDetails.length / 3) : 10;
    const chunks = chunk(recordDetails, rows);

    return (
      <div className="card">
        <div className="row">
          {chunks.map((item, index) => (
            <div key={`chunk-${index}`} className="col">
              <TwoColumnList
                data={item.map((i) => ({
                  ...i,
                  render: renderStaticFieldValue(i),
                }))}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <BoxedContent>
        <Loader />
      </BoxedContent>
    );
  }
  return (
    <BoxedContent className="static-object-overview">
      {showHeader && (
        <Header
          showBreadcrumb
          leftContent={<HeaderLeftContent title={startCase(objectName)} />}
        />
      )}
      {renderRecordDetails()}
    </BoxedContent>
  );
}

export default StaticObjectDetails;
