import { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import { ReactSelect, Button, FormControl } from "@hydra/atom/components";
import { camelize, pascalize } from "humps";
import { useModal } from "@/hooks";
import { Modal } from "@/components/common";
import { ModalHeader, ModalFooter } from "@/components/modals";
import { DynamicObjectSelectField } from "@/components/dynamic/fields";
import { getTestId } from "@/utils/helpers";

function SelectModal({
  isOpen, onSave, onClose, objectNames, value, onChange, testId
}) {
  const getFieldFilters = useCallback((objectName) => {
    const { filters, parentFilters } = objectName;

    if (!isEmpty(filters)) {
      return filters;
    }

    if (value && parentFilters && parentFilters.length) {
      const fieldFilters = {};

      parentFilters.forEach((parentFilter) => {
        const stateKey = camelize(parentFilter);
        const filterValue = value[stateKey];
        if (filterValue && !isEmpty(filterValue)) {
          fieldFilters[stateKey] = filterValue.value;
        }
      });

      return fieldFilters;
    }
  }, [value]);

  if (!isOpen) return null;

  return (
    <Modal
      className="select-unit-modal"
      rootClassName="center-vertically"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="core-modal-content">
        <ModalHeader heading="Select Cost Centers" />
        <div className="row gy-3">
          {objectNames.map((objectName) => (
            <div className="col-md-6">
              <DynamicObjectSelectField
                objectName={objectName.value}
                name={objectName.value}
                label={objectName.label}
                value={value ? value[camelize(objectName.value)] : null}
                onChange={(option) => onChange(camelize(objectName.value), option)}
                optionLabelKey={objectName.optionLabelKey ?? "name"}
                optionValueKey={objectName.optionValueKey ?? "id"}
                isDynamicObject={objectName.isDynamic}
                placeholder={`Select ${objectName.label}`}
                fieldFilters={getFieldFilters(objectName)}
                testId={getTestId(`${testId}-${pascalize(objectName.value || "")}`)}
              />
            </div>
          ))}
        </div>
      </div>
      <ModalFooter>
        <div className="modal-footer-right">
          <button className="cancel-btn" onClick={onClose} type="button">
            Cancel
          </button>
          <Button small className="save-btn" onClick={onSave}>
            Save
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

SelectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  objectNames: PropTypes.array.isRequired,
  testId: PropTypes.string.isRequired,
};

function DynamicObjectSearchSelectField({
  name,
  objectNames,
  value,
  onChange,
  error,
  isSearchableInModal,
  className,
  baseClassName,
  searchableProperties,
  optionLabelKey,
  optionValueKey,
  filterByFieldName,
  testId,
  allowMultiple,
  disabled,
  ...rest
}) {
  const [selectedObjectName, setSelectedObjectName] = useState(null);
  const { isOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    if (!value) {
      setSelectedObjectName(null);
    }
  }, [value]);

  const handleStateChange = (key, option) => {
    const newState = {
      ...value,
      [key]: option
    };

    if (!option && selectedObjectName) {
      if (key === camelize(selectedObjectName?.value)) {
        setSelectedObjectName(null);
      }
    }

    onChange(newState);
  };

  const selectedKeys = value ? Object.keys(value).filter((key) => value[key]) : [];
  const selectedLabels = selectedKeys.map((key) => value[key].label);

  const renderSelectObjectField = useCallback(() => {
    if (selectedObjectName) {
      return null;
    }

    if (selectedLabels.length) {
      return null;
    }

    return (
      <FormControl>
        <ReactSelect
          id={`${name}-select`}
          name={`${name}-select`}
          options={objectNames}
          value={selectedObjectName}
          onChange={setSelectedObjectName}
          placeholder="Select..."
          isClearable
          backSpaceRemovesValue
          styles={rest?.styles}
          testId={getTestId(`${testId}-${pascalize(name || "")}`)}
          isDisabled={disabled}
          menuPortalTarget={document.body}
        />
      </FormControl>
    );
  }, [selectedObjectName, selectedLabels, allowMultiple, value]);

  const renderSelectField = useCallback(() => {
    let selectedObject = selectedObjectName;
    if (allowMultiple) {
      if (!selectedObject) {
        return null;
      }

      if (selectedLabels.length) {
        return null;
      }
    }

    if (!selectedObject) {
      if (value) {
        if (!selectedKeys.length) {
          return null;
        }

        selectedObject = objectNames.find((o) => camelize(o.value) === selectedKeys[0]);
      }
    }

    return (
      <DynamicObjectSelectField
        objectName={selectedObject?.value}
        name={name}
        value={value ? value[camelize(selectedObject?.value)] : null}
        onChange={(option) => handleStateChange(camelize(selectedObject?.value), option)}
        optionLabelKey={selectedObject?.optionLabelKey ?? "name"}
        optionValueKey={selectedObject?.optionValueKey ?? "id"}
        isDynamicObject={selectedObject?.isDynamic}
        placeholder={`Select ${selectedObject?.label}`}
        fieldFilters={selectedObject?.filters ?? {}}
        defaultMenuIsOpen={!selectedLabels.length}
        testId={getTestId(`${testId}-${pascalize(selectedObject?.value)}`)}
        disabled={disabled}
        menuPortalTarget={document.body}
        {...rest}
      />
    );
  }, [selectedObjectName, selectedLabels, allowMultiple, value]);

  return (
    <div className={`${baseClassName} ${className}`}>
      {renderSelectObjectField()}
      {renderSelectField()}
      {
        allowMultiple && (
          <>
            <button disabled={disabled} type="button" onClick={openModal} className="btn-search d-flex">
              <span>{selectedLabels.join(", ")}</span>
              <span className="material-icons-outlined">add</span>
            </button>
            <SelectModal
              isOpen={isOpen}
              onSave={closeModal}
              onClose={closeModal}
              objectNames={objectNames}
              value={value}
              onChange={handleStateChange}
              testId={getTestId(testId)}
            />
          </>
        )
      }
      {typeof error === "string" && <span className="error-text">{error}</span>}
    </div>
  );
}

DynamicObjectSearchSelectField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  baseClassName: PropTypes.string,
  testId: PropTypes.string,
  allowMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

DynamicObjectSearchSelectField.defaultProps = {
  name: null,
  error: false,
  value: null,
  placeholder: "Select...",
  className: "",
  baseClassName: "dynamic-object-search-select-field",
  testId: "",
  allowMultiple: true,
  disabled: false
};

DynamicObjectSearchSelectField.type = "HydraValidatableComponent";

export default DynamicObjectSearchSelectField;
