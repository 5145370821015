import { getInvoiceListingColumns, getInvoiceListingRows } from "./invoice-listing-statement";
import { getInvoiceListing } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function RentRollReport({ activeCompany }) {
  return (
    <DynamicReport
      reportName="invoice-listing"
      activeCompany={activeCompany}
      columnsDataProvider={getInvoiceListingColumns}
      rowsDataProvider={getInvoiceListingRows}
      fetchData={(data) => getInvoiceListing(data)}
      exportUrl="reports/export-query-report/invoice-listing"
      filtersInitialState={null}
      filterOptions={null}
      orderBy="InvoiceNumber"
      showDatePeriod={false}
    />
  );
}
