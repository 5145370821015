import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@hydra/atom/components";
import { Modal } from "@/components/common";
import { ModalHeader } from "@/components/modals";
import { SelectStep } from "@/components/facility/planboard";
import reactiveImage from "@/assets/images/reactive.png";
import scheduledImage from "@/assets/images/scheduled.png";

const options = [
  {
    value: "opex",
    title: "Opex Budget",
    subtitle: "Opex Budget",
    image: reactiveImage,
  },
  {
    value: "capex",
    title: "Capex Budget",
    subtitle: "Capex Budget",
    image: scheduledImage,
    disabled: true
  },
];

export default function SelectBudgetTypeModal({ isOpen, onClose }) {
  const navigate = useNavigate();
  const [budgetType, setBudgetType] = useState("opex");

  const handleNext = () => {
    navigate(`/finance/budget/new?type=${budgetType}`);
  };

  const handleClose = () => {
    onClose(false);
  };

  if (!isOpen) return null;

  return (
    <Modal
      rootClassName="center-vertically add-workorder-modal"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div className="core-modal-content select-work-order-type-modal">
        <ModalHeader
          heading="Select Budget Type"
          subHeading="Select type for budget"
        />
        <SelectStep options={options} value={budgetType} onChange={setBudgetType} />
        <div className="save-btn">
          <Button block onClick={handleNext}>
            Next
          </Button>
        </div>
      </div>
    </Modal>
  );
}
