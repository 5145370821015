import { useMemo } from "react";
import PropTypes from "prop-types";
import { camelize, pascalize } from "humps";
import { Button } from "@hydra/atom/components";

import { renderElement } from "@/components/dynamic";
import { renderField, validateFields } from "@/utils/dynamic/helpers";
import { Modal } from "@/components/common";
import { ModalHeader } from "@/components/modals";
import { getTestId } from "@/utils/helpers";

function DynamicPopup({
  name,
  layout,
  isOpen,
  objectName,
  onCancel,
  onSave,
  fields,
  state,
  objectData,
  dispatch,
  handleDispatch,
  selectedId,
  onChildStateChange,
  onChildFieldBlur,
}) {
  const tableField = useMemo(() => {
    if (layout && layout.elements.length === 1) {
      const element = layout.elements[0];

      if (element.dataType === "TableElement") {
        const field = fields.find((f) => f.camelizedName === camelize(element.title));
        return field;
      }
    }

    return null;
  }, [layout]);

  const renderLayout = () => {
    if (!layout) {
      return null;
    }

    if (tableField) {
      const element = layout.elements[0];

      return renderField({
        field: tableField,
        parentObjectName: objectName,
        state,
        onChange: handleDispatch,
        parentId: selectedId,
        onChildStateChange,
        onChildFieldBlur,
        fieldLayout: element,
        isInsideModal: true,
        objectData,
      });
    }

    return layout.elements.map((element, index) =>
      renderElement(
        {
          element: { ...element, index },
          fields,
          state,
          objectData,
          dispatch,
          handleDispatch,
          objectName,
          selectedId,
          onChildStateChange,
          onChildFieldBlur
        },
        index
      )
    );
  };

  const handleSave = () => {
    if (tableField) {
      const submitButton = document.getElementsByClassName(`${tableField.name}-submit-button`);

      if (submitButton.length) {
        submitButton[0].click();
      }

      const stateKey = `${tableField.name}${tableField.objectFieldType}`;
      const errors = validateFields(tableField.fields, state[stateKey]);

      if (errors.length) {
        return;
      }
    }

    onSave();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      rootClassName="center-vertically add-asset-modal"
      isOpen={isOpen}
      onClose={onCancel}
      showCloseIcon
    >
      <div className="core-modal-content">
        <ModalHeader heading={`${name}`} />
        <div className="dynamic-form">{renderLayout()}</div>
        <div className="buttons-at-end">
          <Button
            small
            bordered
            onClick={onCancel}
            testId={getTestId(`${pascalize(objectName)}-Modal-Cancel-Button`)}
          >
            Cancel
          </Button>
          <Button
            small
            type="submit"
            onClick={handleSave}
            testId={getTestId(`${pascalize(objectName)}-Modal-Submit-Button`)}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}

DynamicPopup.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  name: PropTypes.string,
  objectName: PropTypes.string,
  isOpen: PropTypes.bool,
};

DynamicPopup.defaultProps = {
  onSave: () => {},
  onCancel: () => {},
  name: "",
  objectName: "",
  isOpen: false,
};

DynamicPopup.Type = "HydraValidatableComponent";

export default DynamicPopup;
