import { getExemptSuplliesSaleColumns, getExemptSuplliesSaleRows } from "./exempt-supplies-sale-statement";
import { getExemptSuppliesSale } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportExemptSuppliesSale({ activeCompany }) {
  return (
    <DynamicReport
      reportName="exempt-sale"
      activeCompany={activeCompany}
      columnsDataProvider={getExemptSuplliesSaleColumns}
      rowsDataProvider={getExemptSuplliesSaleRows}
      fetchData={getExemptSuppliesSale}
      exportUrl="reports/export-query-report/exempt-sale"
      filtersInitialState={{}}
      orderBy="InvoiceNumber"
    />
  );
}
