import { request } from "@hydra/atom/lib";
import { camelizeKeys, pascalizeKeys } from "humps";

import appSettings from "@/settings";
import { getToken, getCompany } from "@/api/user/authApi";

request.defaults.baseURL = appSettings.baseUrl;
request.defaults.withCredentials = true;
request.defaults.headers = {
  includeCompanyId: true
};

// Axios middleware to convert all api requests to PascalCase
request.interceptors.request.use((config) => {
  const newConfig = { ...config };

  const authToken = getToken();
  const companyId = getCompany();

  if (authToken) {
    newConfig.headers.Authorization = `Bearer ${authToken}`;
  }

  if (companyId && !config.headers?.Companyid && config.headers?.includeCompanyId) {
    newConfig.headers.Companyid = companyId;
  }

  if (
    ["post", "patch", "put"].includes(newConfig.method) &&
    !newConfig.url.includes("/attachment/") &&
    !newConfig.url.includes("/import") &&
    !newConfig.url.includes("journal-entries/project")
  ) {
    newConfig.data = pascalizeKeys(newConfig.data);
  }

  return newConfig;
});

// Axios middleware to convert all api requests to camelCase
request.interceptors.response.use((response) => camelizeKeys(response));

export default request;
