import { useNavigate, } from "react-router-dom";
import { NoDataFound } from "@/components/common";

function Unauthorized() {
  const navigate = useNavigate();

  return (
    <NoDataFound
      title="You do not have access to the requested page."
      buttonText="Go Back"
      onClick={() => navigate(-1)}
    />
  );
}

export default Unauthorized;
