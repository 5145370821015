import { estimatedColumnWidth } from "../helpers";
import { formatDate } from "@/utils/helpers";

export default function getPlannedActualPpmExternalColumns() {

  const colArr = [
    {
      label: "Work Order Number",
      value: true,
      columnId: "number",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Building Name",
      value: true,
      columnId: "buildingName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Description",
      value: true,
      columnId: "description",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Planned PPM Count",
      value: true,
      columnId: "plannedPPMCount",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Maintenance Category",
      value: true,
      columnId: "mainCategory",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Supervisor Name",
      value: true,
      columnId: "supervisorName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Planned Frequency",
      value: true,
      columnId: "plannedFrequency",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Number of PPM Conducted",
      value: true,
      columnId: "numberOfPPMConducted",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Remaining PPM",
      value: true,
      columnId: "remainingPPM",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Last PPM Date",
      value: true,
      columnId: "lastPPMDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))
    },
    {
      label: "Next PPM Date",
      value: true,
      columnId: "nextPPMTime",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))
    },
    {
      label: "Aging",
      value: true,
      columnId: "aging",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "0")
    }
  ];

  return colArr;
}
