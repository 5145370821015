import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getGeneralJournalColumns(company) {
  const colArr = [
    {
      label: "Trans #",
      value: true,
      columnId: "journalEntryId",
      resizable: true,
      width: estimatedColumnWidth("number"),
      formatter: (value, index) => {
        if (index !== 0 || !value) {
          return "";
        }

        return String(value);
      },
      className: "justify-content-center"
    },
    {
      label: "Type",
      value: true,
      columnId: "type",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value, index) => {
        if (index !== 0 || !value) {
          return "";
        }

        if (value === "Adjusting Entry") {
          return "Journal";
        }

        return String(value);
      },
    },
    {
      label: "Date",
      value: true,
      columnId: "postingDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value, index) => {
        if (index !== 0 || !value) {
          return "";
        }

        return (value ? formatDate(new Date(value)) : "");
      },
    },
    {
      label: "Number",
      value: true,
      columnId: "number",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value, index) => {
        if (index !== 0 || !value) {
          return "";
        }

        return String(value);
      },
    },
    {
      label: "Name",
      value: true,
      columnId: "name",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Building",
      value: true,
      columnId: "buildingName",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Component",
      value: true,
      columnId: "componentName",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Unit",
      value: true,
      columnId: "unitName",
      width: estimatedColumnWidth("number"),
      resizable: true,
    },
    {
      label: "Description",
      value: true,
      columnId: "description",
      width: estimatedColumnWidth("description"),
      resizable: true,
    },
    {
      label: "GL Code",
      value: true,
      columnId: "accountCode",
      width: estimatedColumnWidth("code"),
      resizable: true,
    },
    {
      label: "Account",
      value: true,
      columnId: "accountName",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Company",
      value: true,
      columnId: "companyName",
      width: estimatedColumnWidth("name"),
      formatter: () => `${company?.name}`,
      resizable: true,
    },
    {
      label: "Department",
      value: true,
      columnId: "departmentName",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Class",
      value: true,
      columnId: "className",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Location",
      value: true,
      columnId: "locationName",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Debit",
      value: true,
      columnId: "debitAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => {
        if (!value) {
          return "";
        }

        return formatCurrency(Math.abs(value));
      },
    },
    {
      label: "Credit",
      value: true,
      columnId: "creditAmount",
      width: estimatedColumnWidth("amount"),
      formatter: (value) => {
        if (!value) {
          return "";
        }

        return formatCurrency((value));
      },
    },
  ];
  return colArr;
}
