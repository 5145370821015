import request from "@/utils/api/helpers";

const baseUrl = "/finance/tax-rules";

export const getTaxRules = () => request({
  url: baseUrl
});

export const getTaxRuleById = (id) => request({
  url: `${baseUrl}/${id}`
});

export const createTaxRule = (data) => request({
  url: baseUrl,
  method: "POST",
  data
});

export const updateTaxRule = (id, data) => request({
  url: `${baseUrl}/${id}`,
  method: "PATCH",
  data
});

export const deleteTaxRule = (id) => request({
  url: `${baseUrl}/${id}`,
  method: "DELETE",
});
