import { getColumnWidth } from "../helpers";

export default function getCashFlowColumns(period) {
  const windowWidth = window.innerWidth - 300;
  const totalColumns = 2;
  const colArr = [
    {
      label: "Accounts",
      value: true,
      columnId: "name",
      resizable: true,
      width: getColumnWidth(1 / totalColumns, windowWidth),
      formatter: (value) => String(value),
    },
    {
      label: `${period?.label}`,
      value: true,
      columnId: "amount",
      width: getColumnWidth(1 / totalColumns, windowWidth),
      resizable: true,
      formatter: (value) => {
        if (!value) {
          return "";
        }
        return value;
      },
    }
  ];
  return colArr;
}
