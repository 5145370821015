import PropTypes from "prop-types";

import { Input } from "@hydra/atom/components";
import { SvgIcon } from "@/components/common";

export default function SearchInput({ className, value, onChange }) {
  return (
    <Input
      className={`search-input ${className}`}
      placeholder="Search"
      value={value}
      onChange={onChange}
    >
      <Input.PrependIcon>
        <SvgIcon icon="search" />
      </Input.PrependIcon>
    </Input>
  );
}

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string
};

SearchInput.defaultProps = {
  className: ""
};
