import { useReducer, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { kebabCase } from "lodash";

import { Form } from "@hydra/atom/components";
import { Accordion } from "@/components/common";
import {
  dynamicFormReducer,
  initialState,
  setInitialState,
  setFormValue,
} from "@/reducers/dynamic/dynamicFormReducer";
import {
  updateDynamicObjectRecord,
} from "@/api/dynamic/dynamicObjectNameApi";
import QuotationsTable from "./QuotationsTable";
import {
  getQuotationsTableColumns,
  getQuotationsTableData,
} from "./quotationTableData";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import showToast from "@/utils/toast/helpers";

export default function QuotationForm({
  initialData,
  setIsLoading,
  onSuccess,
  onError,
}) {
  const objectName = dynamicObjectMap.get("QuotationObjectName");
  const [state, dispatch] = useReducer(dynamicFormReducer, initialState);

  useEffect(() => {
    if (initialData) {
      dispatch(setInitialState(initialData));
    }
  }, [initialData]);

  const updateMutation = useMutation(
    ({ recordId, dataObject }) => updateDynamicObjectRecord(objectName, recordId, dataObject)
  );

  const updateData = () => {
    const selectedQuotation = state.quotations.find((q) => q.isApproved);

    if (!selectedQuotation) {
      showToast("Please approve a quotation", "error");
      return;
    }

    const recordId = selectedQuotation.id;
    const recordData = {
      isApproved: true
    };

    updateMutation.mutate({
      recordId,
      dataObject: recordData
    },
    {
      onError: () => {
        showToast("Could not approve quotation", "error");
        onError();
      },
      onSuccess: () => {
        showToast("Quotation selected successfully", "success");
        onSuccess();
      }
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    updateData();
  };

  const handleDelete = (item) => {
    const values = state.item;
    dispatch(
      setFormValue(
        "item",
        [...values].filter((m) => m.quotation[0] !== item.quotation[0])
      )
    );
  };

  const handleApprove = (item) => {
    const values = state.quotations;
    values.forEach((obj) => {
      obj.isApproved = false;
    });
    values[item.index] = { ...item, isApproved: !item.isApproved };
    dispatch(setFormValue("item", values));
  };

  return (
    <Form onSubmit={handleSubmit} shouldScrollOnError>
      <div className="accordion-row">
        <Accordion title="Quotations" count={state?.quotations?.length}>
          <QuotationsTable
            tableColumns={getQuotationsTableColumns()}
            data={getQuotationsTableData(state)}
            handleDelete={handleDelete}
            handleApprove={handleApprove}
          />
        </Accordion>
      </div>

      <button
        type="submit"
        style={{ display: "none" }}
        id={`dynamic-form-submit-button-${kebabCase(objectName)}`}
      >
        Save
      </button>
    </Form>
  );
}
