/* eslint-disable no-plusplus */
import { range } from "lodash";
import {
  nonEditable,
  textCell,
  headerCell,
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT } from "../constants";
import { formatCurrency } from "@/utils/helpers";

function getHeaderRow(columns) {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: columns.map((column) => headerCell(column.label)),
  };
}

function getOpenTransactionGroupRows(title, groups, columns, total, type) {
  const totalObjects = type === "open" ? [
    {
      rowId: `${title}-Footer`,
      height: ROW_HEIGHT,
      cells: [
        headerCell(title, "total-row"),
        ...range(0, columns.length - 5).map(() => headerCell("", "total-row")),
        headerCell(
          "Total", "total-row-debit"),
        headerCell(formatCurrency(total?.totalOriginalAmount || 0), "total-row-credit"),
        headerCell(formatCurrency(total?.totalPaidAmount || 0), "total-row-credit"),
        headerCell(formatCurrency(total?.totalBalance || 0), "total-row-credit"),
      ],
    },
    {
      rowId: `${title}-Footer`,
      height: ROW_HEIGHT,
      cells: [
        headerCell(title, "total-row"),
        ...range(0, columns.length - 3).map(() => headerCell("", "total-row")),
        headerCell(
          "Net to be Collected", "total-row-debit"),
        headerCell(formatCurrency(total?.totalBalance || 0), "total-row-credit"),
      ],
    },
  ] : [
    {
      rowId: `${title}-Footer`,
      height: ROW_HEIGHT,
      cells: [
        headerCell(
          "Total", "total-row-debit"),
        headerCell(formatCurrency(total?.totalNotDue || 0), "total-row-credit"),
        headerCell(formatCurrency(total?.totalOneMonth || 0), "total-row-credit"),
        headerCell(formatCurrency(total?.totalTwoMonths || 0), "total-row-credit"),
        headerCell(formatCurrency(total?.totalThreeMonths || 0), "total-row-credit"),
        headerCell(formatCurrency(total?.totalOverThreeMonths || 0), "total-row-credit"),
      ],
    },
    {
      rowId: `${title}-Footer`,
      height: ROW_HEIGHT,
      cells: [
        headerCell(title, "total-row"),
        ...range(0, columns.length - 3).map(() => headerCell("", "total-row")),
        headerCell(
          "Total Aging", "total-row-debit"),
        headerCell(formatCurrency(total?.totalOverThreeMonths || 0), "total-row-credit"),
      ],
    },
  ];
  if (groups?.length) {
    return [
      ...groups.map((item, index) => ({
        rowId: item.id,
        height: ROW_HEIGHT,
        cells: columns.map((column) => {
          const textCellArgs = {};

          if (column.formatter) {
            textCellArgs.text = column.formatter(item[column.columnId], index);
          } else {
            textCellArgs.text = item[column.columnId] ?? "";
          }

          return nonEditable(textCell(textCellArgs), column.className || "");
        }),
      })),
      ...totalObjects
    ];
  }
  return [];
}

function getAdvancePaymentGroupRows(title, groups, columns, total) {
  const totalObjects = [{
    rowId: `${title}-Footer`,
    height: ROW_HEIGHT,
    cells: [
      headerCell(title, "total-row"),
      ...range(0, columns.length - 3).map(() => headerCell("", "total-row")),
      headerCell(
        "Total Amount", "total-row-debit"),
      headerCell(formatCurrency(total?.totalAdvancePayment || 0), "total-row-credit"),
    ],
  },
  {
    rowId: `${title}-Footer`,
    height: ROW_HEIGHT,
    cells: [
      headerCell(title, "total-row"),
      ...range(0, columns.length - 3).map(() => headerCell("", "total-row")),
      headerCell(
        "Total Recovered", "total-row-debit"),
      headerCell(formatCurrency(total?.totalRecoveredAmount || 0), "total-row-credit"),
    ],
  },
  {
    rowId: `${title}-Footer`,
    height: ROW_HEIGHT,
    cells: [
      headerCell(title, "total-row"),
      ...range(0, columns.length - 3).map(() => headerCell("", "total-row")),
      headerCell(
        "Total Remaining", "total-row-debit"),
      headerCell(formatCurrency(total?.totalRemainingAmount || 0), "total-row-credit"),
    ],
  },
  ];
  if (groups?.length) {
    return [
      ...groups.map((item, index) => ({
        rowId: item.id,
        height: ROW_HEIGHT,
        cells: columns.map((column) => {
          const textCellArgs = {};

          if (column.formatter) {
            textCellArgs.text = column.formatter(item[column.columnId], index);
          } else {
            textCellArgs.text = item[column.columnId] ?? "";
          }

          return nonEditable(textCell(textCellArgs), column.className || "");
        }),
      })),
      ...totalObjects
    ];
  }
  return [];
}

function getPaidInvoiceGroupRows(title, groups, columns, total) {
  const totalObjects = [{
    rowId: `${title}-Footer`,
    height: ROW_HEIGHT,
    cells: [
      headerCell(title, "total-row"),
      ...range(0, columns.length - 3).map(() => headerCell("", "total-row")),
      headerCell(
        "Total Amount", "total-row-debit"),
      headerCell(formatCurrency(total?.totalPaidInvoice || 0), "total-row-credit"),
    ],
  }
  ];
  if (groups?.length) {
    return [
      ...groups.map((item, index) => ({
        rowId: item.id,
        height: ROW_HEIGHT,
        cells: columns.map((column) => {
          const textCellArgs = {};

          if (column.formatter) {
            textCellArgs.text = column.formatter(item[column.columnId], index);
          } else {
            textCellArgs.text = item[column.columnId] ?? "";
          }

          return nonEditable(textCell(textCellArgs), column.className || "");
        }),
      })),
      ...totalObjects
    ];
  }
  return [];
}

export function getSupplierStatementRows({
  data,
  columns
}) {
  if (!data) {
    return [];
  }
  const totalOriginalAmount = data.data.reduce((acc, obj) => acc + +(obj.total || 0), 0);
  const totalPaidAmount = data.data.reduce((acc, obj) => acc + +(obj.paidAmount || 0), 0);
  const totalBalance = data.data.reduce((acc, obj) => acc + +(obj.openBalance || 0), 0);
  const total = {
    totalBalance,
    totalOriginalAmount,
    totalPaidAmount
  };

  return [
    getHeaderRow(columns),
    ...getOpenTransactionGroupRows("", data.data, columns, total, "open"),
  ];
}

export function getAgingRows({
  data,
  columns
}) {
  if (!data) {
    return [];
  }

  const totalNotDue = data.data.reduce((acc, obj) => acc + +(obj.notDue || 0), 0);
  const totalOneMonth = data.data.reduce((acc, obj) => acc + +(obj.oneMonth || 0), 0);
  const totalTwoMonths = data.data.reduce((acc, obj) => acc + +(obj.twoMonths || 0), 0);
  const totalThreeMonths = data.data.reduce((acc, obj) => acc + +(obj.threeMonths || 0), 0);
  const totalOverThreeMonths = data.data.reduce((acc, obj) => acc + +(obj.overThreeMonths || 0), 0);

  const total = {
    totalTwoMonths,
    totalNotDue,
    totalOneMonth,
    totalThreeMonths,
    totalOverThreeMonths
  };
  return [getHeaderRow(columns), ...getOpenTransactionGroupRows("", data.data, columns, total, "aging"),
  ];
}

export function getAdvancePaymentRows({
  data,
  columns
}) {
  if (!data) {
    return [];
  }
  const totalAdvancePayment = data.data.filter((item) => item.type === "AdvancePayment").reduce((acc, obj) => acc + +(obj.amount || 0), 0);
  const totalRecoveredAmount = data.data.filter((item) => item.type === "RecoveredAmount").reduce((acc, obj) => acc + +(obj.amount || 0), 0);
  const totalRemainingAmount = (totalAdvancePayment || 0) - (totalRecoveredAmount || 0);

  const total = {
    totalAdvancePayment,
    totalRecoveredAmount,
    totalRemainingAmount
  };
  return [getHeaderRow(columns), ...getAdvancePaymentGroupRows("", data.data, columns, total),
  ];
}

export function getSupplierPaidInvoicesRows({
  data,
  columns
}) {
  if (!data) {
    return [];
  }
  const totalPaidInvoice = data.data.reduce((acc, obj) => acc + +(obj.paid || 0), 0);

  const total = {
    totalPaidInvoice,
  };
  return [getHeaderRow(columns), ...getPaidInvoiceGroupRows("", data.data, columns, total),
  ];
}
