import { getRentRollColumns, getRentRollRows } from "./rent-roll-statement";
import { getRentRoll } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const rentRollFilters = {
  LocationId: "*",
  TenantId: "*",
  UnitType: "*",
  UnitId: "*",
  UnitCurrentStatus: "*",
  BuildingId: "*",
  UnitCategory: "*",
  UnitStatus: "*",
  PaymentStatus: "*",
  ContractNumber: "*"
};

const rentRollFilterOptions = [
  { key: "location", apiKey: "LocationId" },
  { key: "tenant", apiKey: "TenantId" },
  { key: "building", apiKey: "BuildingId" },
  { key: "unit", apiKey: "UnitId" },
  { key: "unitType", apiKey: "UnitType" },
  { key: "unitCategory", apiKey: "UnitCategory" },
  { key: "unitCurrentStatus", apiKey: "UnitCurrentStatus" },
  { key: "unitStatus", apiKey: "UnitStatus" },
  { key: "paymentStatus", apiKey: "PaymentStatus" },
  { key: "contractNumber", apiKey: "ContractNumber", keyType: "label" }
];

export default function RentRollReport({ activeCompany }) {
  return (
    <DynamicReport
      reportName="rent-roll"
      activeCompany={activeCompany}
      columnsDataProvider={getRentRollColumns}
      rowsDataProvider={getRentRollRows}
      fetchData={getRentRoll}
      exportUrl="reports/export-query-report/rent-roll"
      filtersInitialState={rentRollFilters}
      filterOptions={rentRollFilterOptions}
      orderBy="Company"
    />
  );
}
