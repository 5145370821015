import { camelize, pascalize } from "humps";
import { estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getOpexColumns(data) {
  const additionalColumns = [];
  data?.forEach((item) => {
    additionalColumns.push({
      label: item?.groupName,
      value: true,
      columnId: `${camelize(pascalize(item?.groupName))}ActualAmount`,
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(Math.abs(value) || 0),
    });

    additionalColumns.push({
      label: "",
      value: true,
      columnId: `${camelize(pascalize(item?.groupName))}BudgetAmount`,
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(Math.abs(value) || 0),
    });
  });

  const colArr = [
    {
      label: "Component Name",
      value: true,
      columnId: "name",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Component Number",
      value: true,
      columnId: "number",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    // {
    //   label: "Total COS",
    //   value: true,
    //   columnId: "totalCOSActual",
    //   width: estimatedColumnWidth("number"),
    //   resizable: true,
    //   formatter: (value) => String(value || "0"),
    // },
    // {
    //   label: "",
    //   value: true,
    //   columnId: "totalCOSBudget",
    //   width: estimatedColumnWidth("number"),
    //   resizable: true,
    //   formatter: (value) => String(value || "1"),
    // }
  ];

  colArr.push(...additionalColumns);

  return colArr;
}
