import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { DynamicFormContainer } from "@/components/dynamic";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { TASK_TYPES } from "@/components/workflow/constants";

function SupervisorPettyCashWorkflowFormContainer({ task, recordData, setIsLoading, onSuccess }) {
  const ref = useRef(null);
  const [initialState, setInitialState] = useState({});

  const { data: supervisorBuildings } = useQuery(
    [kebabCase(dynamicObjectMap.get("BuildingObjectName")), recordData?.supervisor],
    () =>
      getDynamicObjectRecords(
        kebabCase(dynamicObjectMap.get("BuildingObjectName")),
        {
          supervisor: recordData?.supervisor,
          takePage: 1,
          limitPage: 1,
          sortBy: "CreatedAt",
          sortType: "DESC",
          queryMode: "Deep",
        }
      ),
    {
      enabled: task?.currentStep === "Issuance of Payment",
    }
  );

  useEffect(() => {
    const data = {
      amountApproved: recordData.amountApproved || recordData.amount
    };

    if (supervisorBuildings && supervisorBuildings?.data.length) {
      const selectedBuilding = supervisorBuildings.data[0];
      const { bankAccount } = selectedBuilding;

      if (bankAccount) {
        data.bankAccount = {
          label: bankAccount.name,
          value: bankAccount.id
        };
      }
    }

    setInitialState(data);
  }, [supervisorBuildings]);

  const renderForm = () => {
    if (
      task?.objectName === dynamicObjectMap.get("SupervisorPettyCashRequestObjectName") &&
      task?.currentStep === "Request for Petty Cash Float" &&
      task?.taskType === TASK_TYPES.APPROVAL
    ) {
      return (
        <DynamicFormContainer
          initialData={initialState}
          ref={ref}
          objectName={task.objectName}
          selectedId={recordData?.id}
          setIsLoading={setIsLoading}
          navigate={false}
          showButtons={false}
          layout={task?.currentStep}
          readOnlyFields={["amount"]}
        />
      );
    }

    if (
      task?.objectName === dynamicObjectMap.get("SupervisorPettyCashRequestObjectName") &&
      task?.currentStep === "Request for Petty Cash Float" &&
      task?.taskType === TASK_TYPES.UPDATE
    ) {
      return (
        <DynamicFormContainer
          initialData={initialState}
          ref={ref}
          objectName={task.objectName}
          selectedId={recordData?.id}
          setIsLoading={setIsLoading}
          navigate={false}
          showButtons={false}
          layout="Update Petty Cash Float"
          onSuccess={onSuccess}
        />
      );
    }

    if (
      task?.objectName === dynamicObjectMap.get("SupervisorPettyCashRequestObjectName") &&
      task?.currentStep === "Issuance of Payment"
    ) {
      return (
        <DynamicFormContainer
          ref={ref}
          objectName={task.objectName}
          selectedId={recordData?.id}
          setIsLoading={setIsLoading}
          navigate={false}
          showButtons={false}
          layout={task?.currentStep}
          readOnlyFields={["amount", "amountApproved"]}
          onSuccess={onSuccess}
        />
      );
    }

    return null;
  };

  return <div>{renderForm()}</div>;
}

SupervisorPettyCashWorkflowFormContainer.propTypes = {
  task: PropTypes.object.isRequired,
  recordData: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default SupervisorPettyCashWorkflowFormContainer;
