import { useEffect } from "react";
import PropTypes from "prop-types";

import { FormControl, Input } from "@hydra/atom/components";

import FieldLabel from "@/components/dynamic/fields/FieldLabel";

const getFormulaBreakdown = (formula) => {
  const [formulaType, fieldExpression] = formula.split("(");
  const [fields] = fieldExpression.split(")");
  const fieldNames = fields.split(",");

  return {
    formulaType,
    fieldNames
  };
};

const getValue = (formula, state) => {
  const { formulaType, fieldNames } = getFormulaBreakdown(formula);
  const stateValues = fieldNames.map((fieldName) => state[fieldName]);

  let result = "";

  switch (formulaType) {
    case "PRODUCT":
      result = stateValues.reduce((a, b) => a * b, 1);
      break;

    case "SUM":
      result = stateValues.reduce((a, b) => a + b, 0);
      break;

    default:
      break;
  }

  return result;
};

function FormulaField({
  name,
  field,
  state,
  value,
  onChange,
  error,
  showLabel,
  placeholder
}) {
  useEffect(() => {
    onChange(getValue(field.formula, state));

  }, [state]);
  return (
    <>
      <FormControl>
        {showLabel ? (
          <FieldLabel
            label={field.label}
            labelFor={field.name}
            helperText={field.helperText}
            isRequired={field.required}
          />
        ) : null}
        <Input
          name={name}
          value={value}
          placeholder={placeholder}
        />
      </FormControl>
      {typeof error === "string" && <span className="error-text">{error}</span>}
    </>
  );
}

FormulaField.propTypes = {
  field: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([null])]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showLabel: PropTypes.bool,
};

FormulaField.defaultProps = {
  value: null,
  onChange: () => {},
  name: "tuli-formula-field",
  placeholder: "",
  error: false,
  showLabel: true,
};

FormulaField.Type = "HydraValidatableComponent";

export default FormulaField;
