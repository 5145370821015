import { Tab } from "@hydra/atom/components";

export default function TabWithBadge({
  tabs, onClick, className
}) {

  return (
    <Tab
      className={className}
      tabs={tabs.map((tab, index) => (
        <div key={`tab-${index}`} className="tabs-badge-container">
          <div>{tab.label}</div>
          {tab.badgeContent && (
          <div className="tabs-badge">
            {tab.badgeContent}
          </div>
          )}
        </div>
      ))}
      onClick={onClick}
    />
  );
}
