import { SvgIcon } from "@/components/common";

export default function Page404() {
  return (
    <div className="page404-container">
      <div className="page404-items">
        <div>
          <SvgIcon icon="page404-icon" />
        </div>
        <h1 className="page404-title">Oops! Something Went Wrong</h1>
        <p className="page404-subtitle">
          Contact support: If the problem persists or you need further assistance,
          please contact our support team at
          <a href="mailto:support@email.com"> [support@email.com]</a>
        </p>
      </div>
    </div>
  );
}
