import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";

function DynamicObjectForm() {
  return (
    <BoxedContent>
      <DynamicFormContainer
        showHeader
        showLinkedViews
      />
    </BoxedContent>
  );
}

export default DynamicObjectForm;
