import PropTypes from "prop-types";

function VerticalEventCard({ event }) {
  const { content } = event;
  return (
    <div
      className={`event-vertical-container event-bg-${content.type.toLowerCase()}`}
    >
      <div>
        <h4 className={`event-text-${content.type.toLowerCase()}`}>
          {content.title}
        </h4>
        <p className={`event-text-${content.type.toLowerCase()}`}>{event.id}</p>
      </div>
      <div>
        <p className="event-status-text event-status-bg">{content.priority}</p>
        <p className="event-status-text event-status-bg">{content.status}</p>
      </div>
    </div>
  );
}

VerticalEventCard.propTypes = {
  event: PropTypes.shape({
    content: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default VerticalEventCard;
