import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox } from "@hydra/atom/components";
import { SvgIcon } from "@/components/common";
import { useOnClickOutside } from "@/hooks";

function CustomDropdown({
  data, setColumnData, buttonText, objectName
}) {
  const ref = useRef();
  function removeItemsByField(items) {
    const fieldsToRemove = [];
    if (objectName === "Landlord") {
      fieldsToRemove.push("addressEmail");
    }
    if (!items || !Array.isArray(items) || items.length === 0 ||
     !fieldsToRemove || !Array.isArray(fieldsToRemove) || fieldsToRemove.length === 0) {
      return items;
    }

    return items.filter((item) => {
      for (const fieldName of fieldsToRemove) {
        if (item.fieldName === fieldName) {
          return false;
        }
      }
      return true;
    });
  }
  const [editColumnView, setEditColumnView] = useState(false);
  useOnClickOutside(ref, () => setEditColumnView(false));

  const handleCheckboxChange = (label, newValue) => {
    const updatedCheckboxData = [...data];
    const checkboxItem = updatedCheckboxData.find((item) => item.label === label);
    if (checkboxItem) {
      checkboxItem.value = newValue;

      if (label === "All") {
        updatedCheckboxData.forEach((item) => {

          if (item.label !== "All") {
            item.value = newValue;
          }
        });
      } else {
        const anyUnchecked = updatedCheckboxData.some((item) => item.label !== "All" && item.value === false);
        const allCheckbox = updatedCheckboxData.find((item) => item.label === "All");
        if (allCheckbox) {
          allCheckbox.value = !anyUnchecked;
        }
      }

      setColumnData(updatedCheckboxData);
    }
  };
  return (
    <div
      ref={ref}
      className="edit-column-container"
      style={{ position: "relative" }}
    >
      <Button
        onClick={() => {
          setEditColumnView(!editColumnView);
        }}
        small
        bordered
        style={{ display: "relative" }}
      >
        <Button.Prepend>
          <SvgIcon icon="edit-icon" />
        </Button.Prepend>
        <span>{buttonText || null}</span>
      </Button>
      {editColumnView && (
        <div className="edit-column-modal" style={{ position: "absolute" }}>
          <div className="edit-column-heading">Show/Hide Columns</div>
          <div className="edit-column-hr-line" />
          <div className="edit-column-checkbox">
            {data &&
              removeItemsByField(data)
                .slice()
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((item, index) => (
                  <Checkbox
                    key={`col-checkbox-${index}`}
                    label={item.label}
                    value={item.value}
                    onChange={(value) =>
                      handleCheckboxChange(item.label, value)}
                  />
                ))}
          </div>
        </div>
      )}
    </div>
  );
}

CustomDropdown.propTypes = {
  objectName: PropTypes.string,
  data: PropTypes.array,
  setColumnData: PropTypes.func,
  buttonText: PropTypes.string,
};

CustomDropdown.defaultProps = {
  data: [],
  setColumnData: () => {},
  buttonText: "",
  objectName: ""
};

export default CustomDropdown;
