import PropTypes from "prop-types";
import { Button } from "@hydra/atom/components";
import { RequirePermission } from "@/components/common";
import NoUnitDataFoundImage from "@/assets/images/no-unit.png";
import NoTechnicianDataImage from "@/assets/images/no-technician.png";
import NoKeyDataImage from "@/assets/images/no-key.png";
import NoServiceRequestDataImage from "@/assets/images/no-service-request.png";
import NoActivityImage from "@/assets/images/no-activity.png";
import NoMoveImage from "@/assets/images/no-move-request.png";
import NoLeadImage from "@/assets/images/no-lead.png";
import NoTask from "@/assets/images/no-task.png";
import NoNotificationImage from "@/assets/images/no-notification.png";

function NoDataFound({
  title, buttonText, onClick, objectName, showButtonWithPermission, noDataHideButton
}) {

  const getSrcImage = () => {
    switch (objectName) {
      case "Contract":
        return NoServiceRequestDataImage;

      case "Tenant":
        return NoUnitDataFoundImage;

      case "ServiceRequest":
        return NoServiceRequestDataImage;

      case "Unit":
        return NoTechnicianDataImage;

      case "Reservation":
        return NoServiceRequestDataImage;

      case "MoveInRequest":
        return NoMoveImage;

      case "MoveOutRequest":
        return NoMoveImage;

      case "UnitKey":
        return NoKeyDataImage;

      case "Activity":
        return NoActivityImage;

      case "Lead":
        return NoLeadImage;

      case "Notification":
        return NoNotificationImage;

      default:
        return NoTask;
    }
  };

  const renderButton = () => {
    if (showButtonWithPermission && buttonText) {
      return (
        <RequirePermission parent="Model" scope={objectName} action="Insert">
          <Button small onClick={onClick}>{buttonText}</Button>
        </RequirePermission>
      );
    }

    if (buttonText) {
      return <Button small onClick={onClick}>{buttonText}</Button>;
    }

    return null;
  };

  return (
    <div className="no-data-found">
      <img src={getSrcImage()} width="500px" height="auto" alt="No data found" />
      <h5>{title}</h5>
      {!noDataHideButton && renderButton()}
    </div>
  );
}

NoDataFound.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  noDataHideButton: PropTypes.bool,
  objectName: PropTypes.string,
  showButtonWithPermission: PropTypes.bool,
};

NoDataFound.defaultProps = {
  buttonText: "",
  onClick: () => {},
  objectName: "",
  noDataHideButton: false,
  showButtonWithPermission: false
};

export default NoDataFound;
