import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

import { Input, Button, ReactSelect } from "@hydra/atom/components";
import {
  BoxedContent, Header, NoDataFound, SvgIcon, HeaderLeftContent, IconButton
} from "@/components/common";
import { ExpandingRowsTable } from "@/components/finance";
import {
  getLedgerAccountTableColumns,
  getLedgerAccountTableData,
} from "@/components/finance/ledger-account/ledgerAccountTableData";
import {
  getLedgerAccounts,
  deleteLedgerAccount,
  uploadCOAExcelFile,
} from "@/api/finance/ledgerAccountApi";
import { skeletonLoadingRows } from "@/utils/helpers";
import { buildTreeForChartOfAccounts } from "@/utils/finance/helpers";
import { selectActiveCompany } from "@/store/appSlice";
import { accountTypeOptions, rootTypeOptions } from "@/utils/finance/constants";
import showToast from "@/utils/toast/helpers";
import { useDebounce } from "@/hooks";
import appSettings from "@/settings";

function ChartOfAccounts() {
  const fileInputRef = useRef(null);
  const activeCompany = useSelector(selectActiveCompany);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    name: "",
    code: "",
    rootType: null,
    accountType: null,
  });
  const debouncedSearch = useDebounce(filters, 1000);

  const handleFilterChange = (key, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const { data, isLoading } = useQuery(["ledger-account"], () => getLedgerAccounts());

  const queryClient = useQueryClient();

  const importMutation = useMutation(({ file, companyId }) =>
    uploadCOAExcelFile({
      file,
      companyId,
    })
  );

  const deleteMutation = useMutation(deleteLedgerAccount, {
    onError: () => {
      showToast("Could not delete. Try again!", "error");
    },
    onSuccess: () => {
      showToast("Deleted successfully", "success");
      queryClient.invalidateQueries({
        queryKey: ["ledger-account"],
      });
    },
  });

  const handleDelete = useCallback((id) => deleteMutation.mutate(id), []);

  const exportExcel = () => window.open(`${appSettings.baseUrl}/finance/ledger-accounts/xls?CompanyId=${activeCompany.id}`, "_blank", "noopener,noreferrer");

  const renderTable = () => {
    if (!isLoading && !data && !data?.data) {
      return (
        <NoDataFound
          title="No account has been added"
          buttonText="Add account"
          onClick={() => navigate("/finance/ledger-account/new")}
        />
      );
    }

    return (
      <ExpandingRowsTable
        objectName="LedgerAccount"
        isLoading={isLoading}
        tableColumns={getLedgerAccountTableColumns(data?.data || [])}
        data={getLedgerAccountTableData(
          data?.data ?
            buildTreeForChartOfAccounts(data?.data, debouncedSearch) :
            skeletonLoadingRows(5)
        )}
        handleDelete={handleDelete}
        expandAllRowsByDefault={
          debouncedSearch &&
          (debouncedSearch.name ||
            debouncedSearch.code ||
            debouncedSearch.rootType?.value ||
            debouncedSearch.accountType?.value)
        }
      />
    );
  };

  const onUploadFile = (event) => {
    const file = event.target.files[0];

    const toastId = toast.loading("Importing Chart of Accounts...");

    importMutation.mutate(
      {
        file,
        companyId: activeCompany.id,
      },
      {
        onError: () => {
          toast.error("Could not import the chart of accounts. Try again!", {
            id: toastId,
          });
        },
        onSuccess: () => {
          toast.success("Chart of Accounts imported successfully!", {
            id: toastId,
          });
          queryClient.invalidateQueries({
            queryKey: ["ledger-account"],
          });
        },
      }
    );
  };

  return (
    <BoxedContent>
      <input
        type="file"
        ref={fileInputRef}
        onChange={onUploadFile}
        onClick={(event) => {
          event.target.value = null;
        }}
        style={{ display: "none" }}
        accept=".xlsx"
      />
      <Header
        showBreadcrumb
        leftContent={<HeaderLeftContent title="Chart Of Accounts" icon="pie-chart-2-stroke-icon" />}
        rightContent={(
          <div className="buttons-container buttons-at-end">
            <Button
              small
              onClick={() => fileInputRef.current.click()}
              className="btn-with-icon"
              loading={importMutation.isLoading}
              bordered
            >
              <Button.Prepend>
                <SvgIcon icon="upload-cloud-stroke-icon" />
              </Button.Prepend>
              Import
            </Button>
            <IconButton
              className="icon-button-with-text"
              onClick={() => exportExcel()}
            >
              <SvgIcon icon="excel" />
              <span>Export</span>
            </IconButton>
            <Button small onClick={() => navigate("/finance/ledger-account/new")} className="btn-with-icon">
              <Button.Prepend>
                <SvgIcon icon="plus-icon" />
              </Button.Prepend>
              Account
            </Button>
          </div>
        )}
      />

      <div className="table-wrapper">
        <div className="row my-3 px-4">
          <div className="col-md-3">
            <Input
              className="input-height-fix"
              value={filters.name}
              onChange={(value) => handleFilterChange("name", value)}
              placeholder="Search by name"
            />
          </div>
          <div className="col-md-3">
            <Input
              className="input-height-fix"
              value={filters.code}
              onChange={(value) => handleFilterChange("code", value)}
              placeholder="Search by code"
            />
          </div>
          <div className="col-md-3">
            <ReactSelect
              name="rootType"
              placeholder="Filter by root type"
              value={filters.rootType}
              onChange={(value) => handleFilterChange("rootType", value)}
              options={rootTypeOptions}
              isClearable
            />
          </div>
          <div className="col-md-3">
            <ReactSelect
              name="accountType"
              placeholder="Filter by account type"
              value={filters.accountType}
              onChange={(value) => handleFilterChange("accountType", value)}
              options={accountTypeOptions}
              isClearable
            />
          </div>
        </div>
        {renderTable()}
      </div>
    </BoxedContent>
  );
}

export default ChartOfAccounts;
