import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { format, parse } from "date-fns";
import { kebabCase, capitalize } from "lodash";
import {
  Form, Label, FormControl, Button
} from "@hydra/atom/components";
import { formatDate, formatApiPayloadDate } from "@/utils/helpers";

import {
  BoxedContent,
  Header,
  CustomizedDateRangePicker,
  CustomizedTimeRangePicker,
  FormLeftHeader,
  Accordion,
  Status,
  StatusCircle,
  TwoColumnList,
  HeaderLeftContent
} from "@/components/common";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import useTaskRedirect from "@/hooks/useTaskRedirect";
import {
  createDynamicObjectRecord,
  getDynamicObjectRecordById,
  getDynamicObjectRecords,
  deleteDynamicObjectRecord
} from "@/api/dynamic/dynamicObjectNameApi";
import { statusColorMap } from "@/utils/maps/statusColorMap";
import { selectActiveApp } from "@/store/appSlice";
import { InfoTable, ImageSlider } from "@/components/facility/maintenance";
import { getProposeTimeColumns } from "@/components/facility/maintenance/infoTableData";
import showToast from "@/utils/toast/helpers";
import { getCompany } from "@/api/user/authApi";

const renderStatus = (value) => (
  <Status
    baseClassName="status-cell"
    status={value}
    bgColor={statusColorMap.get(value?.toLowerCase())}
  >
    <StatusCircle color={statusColorMap.get(value?.toLowerCase())} />
  </Status>
);

const formatTime = (inputTime) => {
  const parsedTime = parse(inputTime, "HH:mm:ss", new Date());
  const formattedTime = format(parsedTime, "h:mm a");
  return formattedTime;
};

const renderImage = (images) => <ImageSlider images={images} />;

// TODO: add check for count
function ProposeJobTimeForm() {
  const { id } = useParams();
  const [dateRange, setDateRange] = useState(null);
  const [timeRange, setTimeRange] = useState(null);
  const { redirect } = useTaskRedirect();
  const queryClient = useQueryClient();
  const activeApp = useSelector(selectActiveApp);
  const [selectedProposedId, setSelectedProposedId] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const workflowIdParam = searchParams.get("workflowId");

  const resetForm = () => {
    setDateRange("");
    setTimeRange("");
  };

  const createMutation = useMutation(
    (dataObject) =>
      createDynamicObjectRecord(
        dynamicObjectMap.get("WorkOrderJobProposedTime"),
        dataObject
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          kebabCase(dynamicObjectMap.get("WorkOrderJobProposedTime")),
        ]);
        resetForm();
      },
    }
  );

  const { data: workOrderJob } = useQuery(
    [kebabCase(dynamicObjectMap.get("WorkOrderJobObjectName")), id],
    () =>
      getDynamicObjectRecordById(
        dynamicObjectMap.get("WorkOrderJobObjectName"),
        id
      )
  );

  const { data: workOrderJobProposedData, isLoading: isWorkOrderJobProposedDataLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("WorkOrderJobProposedTime")),
      selectedProposedId,
      {
        sortBy: "CreatedAt",
        sortType: "DESC",
        detailId: workOrderJob?.id
      },
      workOrderJob?.id
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("WorkOrderJobProposedTime"), {
        sortBy: "CreatedAt",
        sortType: "DESC",
        detailId: workOrderJob?.id
      }),
    {
      enabled: Boolean(workOrderJob?.id),
    }
  );

  const formatWorkOrderJobProposed = (data) => data?.map((item) => ({
    id: item.id,
    workorderName: `${workOrderJob.workOrder.name}`,
    technician: {
      name: "Haider Ali"
    },
    date: `${formatDate(new Date(item?.startDate))} - ${formatDate(new Date(item?.endDate))}`,
    time: `${formatTime(item.startTime)} - ${formatTime(item.endTime)}`
  }));

  const addIfNotEmpty = (formattedData, label, value) => {
    if (value) {
      const dataObject = { label, value };
      formattedData.push(dataObject);
    }
  };

  const formatDetails = (data) => {
    const formattedData = [
      {
        label: "Type",
        value: capitalize(data.workOrder.type),
      },
      {
        label: "Title",
        value: data.name,
      },
      {
        label: "Category",
        value: data?.serviceRequest?.category?.name,
      },
      {
        label: "Priority",
        value: data.priority,
        render: renderStatus(data.priority),
      },
      {
        label: "Status",
        value: data.jobStatus,
        render: renderStatus(data.jobStatus),
      },
      {
        label: "Maintenance Description",
        value: data?.serviceRequest?.description,
      },
      {
        label: "Gallery",
        value: "",
        render: renderImage(data?.serviceRequest?.photos),
      },
    ];
    addIfNotEmpty(formattedData, "Unit", data?.serviceRequest?.unit?.name);
    addIfNotEmpty(formattedData, "Building", data?.serviceRequest?.unit?.building?.name);
    addIfNotEmpty(formattedData, "Service Request", data?.serviceRequest?.name);
    addIfNotEmpty(formattedData, "Job For", data?.jobFor?.name ? `${data.jobFor.name} (${data.jobFor.number})` : null);

    return formattedData;
  };

  const handleSubmit = () => {
    const dataObject = {
      detailId: id,
      startDate: `${formatApiPayloadDate(new Date(dateRange[0]))}`,
      endDate: `${formatApiPayloadDate(new Date(dateRange[1]))}`,
      startTime: `${timeRange[0]}`,
      endTime: `${timeRange[1]}`,
      company: getCompany()
    };

    if (workflowIdParam) {
      dataObject.workflowId = workflowIdParam;
    }

    createMutation.mutate(dataObject);
  };

  const onSave = () => {
    redirect(-1, {
      recordId: id,
      success: true,
    });
  };

  const deleteMutation = useMutation(
    (recordId) => deleteDynamicObjectRecord(dynamicObjectMap.get("WorkOrderJobProposedTime"), recordId),
    {
      onError: () => {
        showToast("Could not delete work order. Try again!", "error");
      },
      onSuccess: (recordId) => {
        queryClient.invalidateQueries({
          queryKey: [kebabCase(dynamicObjectMap.get("WorkOrderJobProposedTime"))]
        });
        showToast("Proposed Time Deleted", "success");
        setSelectedProposedId(recordId);
      },
    }
  );

  const handleDelete = async (selectedId) => {
    deleteMutation.mutate(selectedId);
  };
  const onCancel = () => navigate(`/${kebabCase(activeApp?.value)}/tasks`);
  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<HeaderLeftContent title="Propose Time" />}
      />
      <div className="row">
        <div className="col-md-5">
          <div className="card propose-job-card">
            {workOrderJob && (
              <TwoColumnList data={formatDetails(workOrderJob)} />
            )}
          </div>
        </div>
      </div>
      <Form onSubmit={handleSubmit} shouldScrollOnError>
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="Select Date" icon="calendar-icon" />
          </div>

          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4">
                <FormControl>
                  <Label htmlFor="dateRange" label="Date" />
                  <CustomizedDateRangePicker
                    name="dateRange"
                    value={dateRange}
                    onChange={setDateRange}
                    minDate={new Date()}
                    required
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="Select Time" icon="calendar-icon" />
          </div>

          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4">
                <FormControl>
                  <Label htmlFor="timeRange" label="Time" />
                  <CustomizedTimeRangePicker
                    name="timeRange"
                    value={timeRange}
                    onChange={setTimeRange}
                    required
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        <hr className="full-hr" />

        <div className="row add-proposed-time-container">
          <div className="col">
            <Button small loading={createMutation.isLoading} type="submit">
              Add Propose Time
            </Button>
          </div>
        </div>
      </Form>

      <div className="propose-time-accordion">
        <Accordion title="Proposed Time Slots">
          {!isWorkOrderJobProposedDataLoading ? (
            <InfoTable
              className="view-task-items-table"
              columns={getProposeTimeColumns()}
              data={formatWorkOrderJobProposed(workOrderJobProposedData.data)}
              onDelete={handleDelete}
            />
          ) : ""}
        </Accordion>
      </div>

      <div className="row proposed-action">
        <div className="col">
          <div className="buttons-at-end">
            <Button bordered small onClick={onCancel}>
              Cancel
            </Button>
            <Button small className="save-btn" onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </BoxedContent>
  );
}

export default ProposeJobTimeForm;
