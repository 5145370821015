import { getRentRollArchiveColumns, getRentRollArchiveRows } from "./rent-roll-archive-statement";
import { getRentRollArchive } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportRentRollArchive({ activeCompany }) {
  return (
    <DynamicReport
      reportName="rent-roll"
      activeCompany={activeCompany}
      columnsDataProvider={getRentRollArchiveColumns}
      rowsDataProvider={getRentRollArchiveRows}
      fetchData={getRentRollArchive}
      exportUrl="reports/export-query-report/rent-roll-archive"
      filtersInitialState={{}}
      orderBy="Company"
      showDateRangeFilter={false}
      showDateFilter
    />
  );
}
