import { kebabCase } from "lodash";
import { camelize } from "humps";
import request from "@/utils/api/helpers";
import { camelizeObjectFieldNames } from "@/utils/dynamic/helpers";

const baseUrl = "/dynamic-object/schema";

export const getDynamicObjectByKey = (id) => request({
  url: `${baseUrl}/object-schema/${id}`,
  method: "GET",
}).then((response) => {
  response.document = response.document.objects.map((field) => ({
    camelizedName: camelize(field.name),
    ...field
  }));

  return response;
});

export const getDynamicObjectByName = (objectName) => request({
  url: `${baseUrl}/object-schema-k/${kebabCase(objectName)}`,
  method: "GET",
}).then((response) => {
  response.document = response.document.objects.map((field) => ({
    camelizedName: camelize(field.name),
    ...field
  }));

  return response;
});

export const getDynamicObjectByNameWithCamelizedFieldNames = (objectName) => request({
  url: `${baseUrl}/object-schema-k/${kebabCase(objectName)}`,
  method: "GET",
}).then((response) => {
  response.layout = response.document.layout;
  response.actions = response.document.actions;
  response.links = response.document.links.filter((view) => view.linkedView);
  response.document = camelizeObjectFieldNames(response.document.objects);
  return response;
});

export const getAllDynamicObjects = () => request({
  url: `${baseUrl}/object-names`,
  method: "GET",
});

export const getAllObjects = () => request({
  url: `${baseUrl}/object-names`,
  method: "GET",
});

export const getObjectFieldTypes = () => request({
  url: `${baseUrl}/object-field-types`,
  method: "GET",
});
