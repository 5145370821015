import { getPettyCashBySupervisorColumns, getPettyCashBySupervisorRows } from "./petty-cash-supervisor-statement";
import { getPettyCashBySupervisor } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const pettyCashByBuildingFilters = {
  SupervisorId: "*",
  BuildingId: "*",
};

export default function ReportPettyCashBySupervisor({ id, activeCompany }) {
  pettyCashByBuildingFilters.SupervisorId = id;

  return (
    <DynamicReport
      reportName="petty-cash-claim-by-supervisor"
      activeCompany={activeCompany}
      columnsDataProvider={getPettyCashBySupervisorColumns}
      rowsDataProvider={getPettyCashBySupervisorRows}
      fetchData={getPettyCashBySupervisor}
      exportUrl="reports/export-query-report/petty-cash-claim-by-supervisor"
      filtersInitialState={pettyCashByBuildingFilters}
      orderBy="BuildingName"
      showDateRangeFilter={false}
    />
  );
}
