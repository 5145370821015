import { useState, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Form, Button, Input, Loader, Label
} from "@hydra/atom/components";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import pluralize from "pluralize";
import {
  Modal, SvgIcon, IconButton
} from "@/components/common";
import { ModalHeader } from "@/components/modals";
import { ListWithCheckbox, ListWithCards } from "@/components/facility/planboard";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { getDynamicObjectByName } from "@/api/dynamic/dynamicObjectSchemaApi";
import { useDebounce, useIsMobile } from "@/hooks";
import { getTestId } from "@/utils/helpers";
import { renderField } from "@/utils/dynamic/helpers";

export default function AdvanceSearchModal({
  onSubmit,
  isOpen,
  objectName,
  objectLabel,
  filters,
  multiSelect,
  filterOption,
  setOpenModal,
}) {
  const [search, setSearch] = useState("");
  const [mobileStep, setMobileStep] = useState(1);
  const debouncedSearch = useDebounce(search, 500);
  const [apiFilters, setApiFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [recordList, setRecordList] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const isMobileView = useIsMobile(992);
  const [selectedRecordList, setSelectedRecordList] = useState([]);

  const { data: objectSchema, isInitialLoading } = useQuery(
    ["dynamic-object-schema", kebabCase(objectName)],
    () => getDynamicObjectByName(objectName),
    {
      enabled: isOpen,
    }
  );

  const searchFieldName = useMemo(
    () =>
      objectSchema?.document.filter((f) => f.searchField === true)?.[0]
        ?.camelizedName || "name",
    [objectSchema]
  );

  const formatApiFilters = () => {
    const finalObj = {
      takePage: 1,
      limitPage: 20,
      ...filters,
    };
    if (search && search !== "") {
      finalObj[`${searchFieldName}[like]`] = `'%${search}%'`;
    }
    for (const key in apiFilters) {
      if (apiFilters[key] !== "" && apiFilters[key] !== null) {
        if (key === "Phone" && apiFilters[key] === undefined) {
          finalObj[`${key}[like]`] = "%%";
        } else if (apiFilters[key]?.value) {
          finalObj[`${key}`] = `${apiFilters[key]?.value}`;
        } else {
          finalObj[`${key}[like]`] = `'%${apiFilters[key]}%'`;
        }
      }
    }
    return finalObj;
  };

  const handleQuantity = (quantityValue) => {
    setErrorMessage();
    setQuantity(quantityValue !== "" && quantityValue < 1 ? 1 : quantityValue);
  };

  const { data, isInitialLoading: isLoading } = useQuery(
    [
      "getDynamicObjectRecords",
      objectName,
      objectLabel,
      debouncedSearch,
      apiFilters,
    ],
    () => getDynamicObjectRecords(objectName, formatApiFilters()),
    {
      enabled: isOpen,
    }
  );

  useEffect(() => {
    if (typeof filterOption === "object") {
      setRecordList(
        (data?.data.filter(
          (item) => !filterOption.some((filter) => filter.value === item.id)
        ) ?? []).map((item) => ({
          ...item,
          isSelected: false
        }))
      );
    }
  }, [data, filterOption]);

  const filteredFields = useMemo(
    () =>
      objectSchema?.document.filter(
        (f) => f.searchFilter === true && f.searchField === false
      ) || [],
    [objectSchema]
  );

  const handleSubmit = () => {
    setOpenModal(false);
    setSelectedRecordList([]);
    setMobileStep(1);
    selectedRecordList[0].quantity = quantity;
    onSubmit(selectedRecordList);
  };

  const handlePrevious = () => {
    setSelectedRecordList([]);
    setOpenModal(false);
    setMobileStep(1);
  };

  const onChangesetAssetList = (index, isSelected) => {
    setErrorMessage();
    if (isSelected) {
      setRecordList([...recordList, selectedRecordList[index]]);
      const tempList = selectedRecordList;
      tempList.splice(index, 1);
      if (!multiSelect) {
        setSelectedRecordList([]);
      } else {
        setSelectedRecordList(tempList);
      }
    } else {
      if (!multiSelect) {
        setSelectedRecordList([recordList[index]]);
      } else {
        setSelectedRecordList([...selectedRecordList, recordList[index]]);
      }
      const tempList = recordList;
      tempList.splice(index, 1);
      if (selectedRecordList.length > 0) {
        tempList.push(selectedRecordList[0]);
      }
      setRecordList(tempList);
    }
  };

  const onListChange = (index, item) => {
    if (multiSelect) {
      const tempRecordList = recordList;
      tempRecordList[index] = { ...item, isSelected: !recordList[index].isSelected };
      setRecordList(tempRecordList);
      setSelectedRecordList(tempRecordList.filter(((listItem) => listItem.isSelected === true)));
    } else {
      const tempRecordList = recordList.map(((listItem) => ({ ...listItem, isSelected: false })));
      tempRecordList[index] = { ...item, isSelected: true };
      setRecordList(tempRecordList);
      setSelectedRecordList(tempRecordList.filter(((listItem) => listItem.isSelected === true)));
    }
  };

  const renderView = (fieldArray) => fieldArray.map((field, index) =>
    renderField({
      index,
      field: {
        ...field,
        drawerField: true,
        required: false
      },
      state: apiFilters,
      onChange: (key, value) => {
        setApiFilters({ ...apiFilters, [field.name]: value });
      },
      showLabel: true,
    })
  );

  const renderListView = () =>
  // eslint-disable-next-line no-nested-ternary
    (recordList.length > 0 || selectedRecordList.length > 0 ? (
      objectName === "Item" ? (
        <ListWithCheckbox
          list={recordList}
          onChange={onChangesetAssetList}
          selected={false}
        />
      ) : (
        <ListWithCards
          list={recordList}
          onChange={onListChange}
          objectName={objectName}
        />
      )
    ) : (
      <div className="no-list-item">
        <div className="center">
          <SvgIcon icon="question" />
          <h1 className="text">
            {`No ${pluralize.singular(objectLabel)} Available`}
          </h1>
        </div>
      </div>
    ));

  const onSave = () => {
    if (isMobileView && mobileStep === 1) {
      return setMobileStep(2);
    }
    if (isMobileView && mobileStep === 2) {
      if (selectedRecordList.length === 0) {
        setErrorMessage("Please select an item.");
        return;
      }
      if (filters.itemType === "Service") {
        return setMobileStep(3);
      }
    }
    if (mobileStep === 3) {
      if (!quantity) {
        setErrorMessage("This field is required.");
        return;
      }
    }
    handleSubmit();
  };

  const renderSelectedListView = () => (
    objectName === "Item" && (
      <ListWithCheckbox
        list={selectedRecordList}
        onChange={onChangesetAssetList}
        selected
      />
    )
  );

  const handleStep = (activeStepIndex) => {
    setMobileStep(activeStepIndex);
    setErrorMessage();
  };

  if (!isOpen) return null;

  return (
    <Modal
      rootClassName="center-vertically add-asset-modal core-modal-content-right"
      isOpen={isOpen}
      onClose={handlePrevious}
      showCloseIcon={!isMobileView}
    >
      {isInitialLoading ? (
        <Loader />
      ) : (
        <div style={{ display: "flex", height: "85vh" }}>
          {!isMobileView && (
            <>
              <div className="col-md-6 core-modal-content primary-right-border">
                <ModalHeader
                  heading={`Search ${objectLabel}`}
                />
                <Form className="modal-form add-work-order-job-step">
                  <div className="row">
                    <h1 className="filter-title">Search</h1>
                    <Input
                      className="search-bar-container"
                      placeholder="Search"
                      value={search}
                      onChange={setSearch}
                    >
                      <Input.PrependIcon>
                        <SvgIcon icon="search" />
                      </Input.PrependIcon>
                    </Input>
                    {filteredFields?.length > 0 && (
                      <div className="d-flex additional-filters">
                        <IconButton
                          onClick={() => {
                            setShowFilters(!showFilters);
                          }}
                        >
                          <SvgIcon
                            icon={`${showFilters ? "minus-circle" : "plus-circle"
                            } `}
                          />
                        </IconButton>
                        Additional Filters
                      </div>
                    )}
                    {showFilters ? (
                      <div>
                        <div>{renderView(filteredFields)}</div>
                      </div>
                    ) : null}
                  </div>
                </Form>
              </div>
              <div className="core-modal-content col-md-6 core-modal-content-right-item">
                <ModalHeader
                  heading={`Available ${objectLabel}`}
                />
                {isLoading ? <Loader /> : (
                  <div className="asset-list-container">
                    {renderSelectedListView()}
                    {renderListView()}
                  </div>
                )}
                {!isLoading && (
                  <div className="d-flex justify-content-end save-button-container">
                    <Button
                      className={`${!selectedRecordList.length > 0 ? "" : "btn-primary"}`}
                      bordered
                      small
                      loading={false}
                      onClick={onSave}
                      testId={getTestId("Save-Button")}
                      disabled={!selectedRecordList?.length > 0}
                    >
                      Select
                    </Button>
                    <Button bordered small onClick={handlePrevious} testId={getTestId("Cancel-Button")}>
                      Cancel
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
          {isMobileView && mobileStep === 1 && (
            <div className="col-md-12 core-modal-content">
              <ModalHeader
                heading={`Search ${objectLabel}`}
              />
              <Form className="modal-form add-work-order-job-step">
                <div className="row">
                  <h1 className="filter-title">Search</h1>
                  <Input
                    className="search-bar-container"
                    placeholder="Search"
                    value={search}
                    onChange={setSearch}
                  >
                    <Input.PrependIcon>
                      <SvgIcon icon="search" />
                    </Input.PrependIcon>
                  </Input>
                  {filteredFields?.length > 0 && (
                    <div className="d-flex additional-filters">
                      <IconButton
                        onClick={() => {
                          setShowFilters(!showFilters);
                        }}
                      >
                        <SvgIcon
                          icon={`${showFilters ? "minus-circle" : "plus-circle"
                          } `}
                        />
                      </IconButton>
                      Additional Filters
                    </div>
                  )}
                  {showFilters ? (
                    <div>
                      <div>{renderView(filteredFields)}</div>
                    </div>
                  ) : null}
                </div>
              </Form>
              <div className="save-button-container d-grid">
                <Button
                  className="btn-primary"
                  bordered
                  small
                  loading={false}
                  onClick={onSave}
                  testId={getTestId("Save-Button")}
                >
                  Save
                </Button>
                <Button bordered small onClick={handlePrevious} testId={getTestId("Cancel-Button")}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
          {isMobileView && mobileStep === 2 && (
            <div className="core-modal-content col-md-12">
              <ModalHeader
                heading={`Available ${objectLabel}`}
              />
              {isLoading ? <Loader /> : (
                <div className="asset-list-container">
                  {renderSelectedListView()}
                  {renderListView()}
                </div>
              )}
              {errorMessage && <p className="error-text">{errorMessage}</p>}
              <div className="save-button-container d-grid">
                <Button
                  className="btn-primary"
                  bordered
                  small
                  loading={false}
                  onClick={onSave}
                >
                  Add
                </Button>
                <Button
                  bordered
                  small
                  onClick={() => handleStep(1)}
                >
                  Back
                </Button>
              </div>
            </div>
          )}
          {isMobileView && mobileStep === 3 && (
            <div className="core-modal-content col-md-12">
              <ModalHeader heading="Select Qauntity" />
              <div className="modal-form add-work-order-job-step">
                <Label
                  htmlFor={quantity}
                  label={`Select Quantity for ${selectedRecordList[0]?.itemName}`}
                />
                <Input
                  type="number"
                  name="quantity"
                  id="quantity"
                  placeholder="Quantity"
                  value={quantity}
                  onChange={handleQuantity}
                />
              </div>
              {errorMessage && <p className="error-text">{errorMessage}</p>}
              <div className="save-button-container d-grid">
                <Button
                  className="btn-primary"
                  bordered
                  small
                  loading={false}
                  onClick={onSave}
                >
                  Add
                </Button>
                <Button
                  bordered
                  small
                  onClick={() => handleStep(2)}
                >
                  back
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}

AdvanceSearchModal.propTypes = {
  multiSelect: PropTypes.bool,
};

AdvanceSearchModal.defaultProps = {
  multiSelect: false,
};
