import { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Link, useNavigate
} from "react-router-dom";
import { isValid, differenceInDays, parseISO } from "date-fns";
import { pascalize, camelize } from "humps";
import { Button } from "@hydra/atom/components";
import { kebabCase, startCase } from "lodash";
import { SUPPLIER_USER_TYPE } from "@/utils/userTypes";
import {
  StatusCircle,
  Status,
  Tooltip,
} from "@/components/common";

import { formatCurrency, getTestId, formatDate } from "@/utils/helpers";
import { handleDrawer } from "@/utils/modal/helpers";
import { statusColorMap } from "@/utils/maps/statusColorMap";
import { prepareFieldState } from "@/utils/dynamic/helpers";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { checkPermission } from "@/store/userSlice";
import intermediateObjectFieldNamesMap from "@/utils/maps/intermediateObjectFieldNamesMap";
import ActionDropdown from "./ActionDropdown";
import { useUser } from "@/hooks";
import { selectActiveApp } from "@/store/appSlice";
import { statusFieldNames } from "@/utils/dynamic/constants";

function AutoNumberCell({ value, row }, objectName) {
  const navigate = useNavigate();
  const activeApp = useSelector(selectActiveApp);

  const onEdit = () => {
    navigate(`/${kebabCase(activeApp?.value)}/${kebabCase(objectName)}/${row.original.key}`);
  };

  const onView = () => {
    navigate(
      `/${kebabCase(activeApp?.value)}/${kebabCase(objectName)}/details/${row.original.key}`
    );
  };

  const updatePermission = useSelector(
    checkPermission({
      parent: "Model",
      scope: objectName,
      action: "Update",
    })
  );

  const viewPermission = useSelector(
    checkPermission({
      parent: "Model",
      scope: objectName,
      action: "View",
    })
  );

  const renderValue = () => {
    if (updatePermission) {
      return (
        <div className="auto-number-cell">
          <button type="button" onClick={() => onEdit(row.original.key)}>
            {value}
          </button>
        </div>
      );
    }

    if (viewPermission) {
      return (
        <div className="auto-number-cell">
          <button type="button" onClick={() => onView(row.original.key)}>
            {value}
          </button>
        </div>
      );
    }

    return <div className="auto-number-cell">{value}</div>;
  };

  return renderValue();
}

AutoNumberCell.propTypes = {
  row: PropTypes.object.isRequired,
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

function StatusCell({ value }) {
  return (
    <Status
      baseClassName="status-cell"
      status={startCase(value)}
      bgColor={statusColorMap.get(value?.toLowerCase())}
    >
      <StatusCircle color={statusColorMap.get(value?.toLowerCase())} />
    </Status>
  );
}

StatusCell.propTypes = {
  value: PropTypes.string.isRequired,
};

function TruncatedTextCell({ value, type = "default" }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (value.split(" ").length > 2) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (value.split(" ").length > 2) {
      setIsHovered(false);
    }
  };

  const displayText = (String(value)?.split(" ").slice(0, 2).join(" ") || "") + (String(value)?.split(" ").length > 2 ? "..." : "");

  return (
    <div className="truncated-text-cell" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {type === "default" ? <p className="text">{displayText}</p> : <a className="text">{displayText}</a>}
      {isHovered && <div className="tooltip">{value}</div>}
    </div>
  );
}

function ActionCell({
  row
}, objectName, actions) {
  const navigation = useNavigate();
  const { userType } = useUser();
  return (
    <div className="action-cell">
      {userType === SUPPLIER_USER_TYPE && objectName === dynamicObjectMap.get("RequestForQuotationObjectName") ? (
        <Button
          small
          className="save-btn"
          onClick={() => {
            navigation(`/facility-management/submit-quotation/${row.original.key}`);
          }}
        >
          <span data-test="view-button">View</span>
        </Button>
      ) : (
        <ActionDropdown
          objectName={objectName}
          id={row.original.key}
          data={row.original}
          actions={actions}
          testId={getTestId(`Action-Dropdown-${row.index}`)}
          trigger={(
            <div className="action-icon-trigger">
              <span className="material-icons-outlined">more_vert</span>
            </div>
          )}
          showTableActions
        />
      )}
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
};

function UrlCell({ value }) {
  return (
    <div className="link-cell">
      <a href={value} target="_blank">
        {value}
      </a>
    </div>
  );
}

UrlCell.propTypes = {
  value: PropTypes.string.isRequired,
};

function UnitCell({ value }) {
  if (!value) return <div />;

  return value?.value ? (
    <div className="tooltip-cell link-cell">
      <Tooltip
        side="right"
        className="bg-gray-900 z-index-1"
        activator={(
          <Link
            to={handleDrawer(
              value.value,
              null,
              false,
              "dynamicObjectDrawers"
            )}
          >
            <div className="truncated-text-cell">
              <a>{`${value?.building?.number} ${value?.number}`}</a>
            </div>
          </Link>
        )}
      >
        <ul className="tooltip-list">
          <li>
            <span>{`${value?.building?.number} ${value?.number}`}</span>
          </li>
          <li>
            <span>Building Name: </span>
            <strong>{value?.building?.name}</strong>
          </li>
          <li>
            <span>Unit Name: </span>
            <strong>{value?.label}</strong>
          </li>
        </ul>
      </Tooltip>
    </div>
  ) : (
    <TruncatedTextCell value={value.label || value} />
  );
}

UnitCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

UnitCell.defaultProps = {
  value: "",
};

function LookupCell({ value }) {
  if (!value) return <div />;

  if (Array.isArray(value)) {
    return <TruncatedTextCell value={value.map((v) => v.label).join(", ")} />;
  }

  return value?.value ? (
    <div className="link-cell">
      <Link
        to={handleDrawer(
          value.value,
          null,
          false,
          value.isStaticObject ? "staticObjectDrawers" : "dynamicObjectDrawers"
        )}
      >
        <div className="truncated-text-cell">
          <a><TruncatedTextCell value={value.label} type="lookup" /></a>
        </div>
      </Link>
    </div>
  ) : (
    <TruncatedTextCell value={value} />
  );
}

LookupCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

LookupCell.defaultProps = {
  value: "",
};

function PolymorphicCell({ value }) {
  if (!value) return <div />;

  const fieldValue = value;

  if (Array.isArray(fieldValue)) {
    return <TruncatedTextCell value={fieldValue.map((v) => v.label).join(", ")} />;
  }
  return (
    fieldValue?.value ? (
      <div className="link-cell">
        <Link
          to={handleDrawer(
            fieldValue.value,
            null,
            false,
            "dynamicObjectDrawers"
          )}
        >
          <div className="truncated-text-cell">
            <a><TruncatedTextCell value={fieldValue.label} type="lookup" /></a>
          </div>
        </Link>
      </div>
    ) : (
      <div className="link-cell">
        <Link
          to={handleDrawer(
            fieldValue,
            null,
            false,
            "dynamicObjectDrawers"
          )}
        >
          <div className="truncated-text-cell">
            <a>{fieldValue}</a>
          </div>
        </Link>
      </div>
    )
  );
}

PolymorphicCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

PolymorphicCell.defaultProps = {
  value: "",
};

function RelationalFieldCell({
  row, cell, rowState, updateRowState
}) {
  const [isClicked, setIsClicked] = useState(false);

  const updateState = () => {
    if (rowState[row.id]) {
      updateRowState(row.id, cell.column.id, !rowState[row.id][cell.column.id]);
    } else {
      updateRowState(row.id, cell.column.id, !isClicked);
      setIsClicked(true);
    }
  };

  return (
    <button className="btn-toggle" type="button" onClick={updateState}>
      {rowState[row.id] && rowState[row.id][cell.column.id] ? (
        <span className="expand-more material-icons">expand_less</span>
      ) : (
        <span className="expand-more material-icons">expand_more</span>
      )}
    </button>
  );
}

RelationalFieldCell.propTypes = {
  row: PropTypes.object.isRequired,
  cell: PropTypes.object.isRequired,
  rowState: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  updateRowState: PropTypes.func.isRequired,
};

function DateCell({ value }) {
  return <span className="date-cell">{value ? formatDate(new Date(value)) : ""}</span>;
}

DateCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const filterFieldColumn = (f) => !f.multiLine &&
!f.richText &&
!["attachment", "checkbox"].includes(f.objectFieldType?.toLowerCase()) &&
!f.hidden &&
f.name.toLowerCase() !==
intermediateObjectFieldNamesMap.get("DetailIdFieldName").toLowerCase();

export const getDynamicObjectOverviewTableColumns = (
  data,
  objectName,
  actions,
  shownColumns,
  objectData,
  showActionCell = true,
  showCreatedAtCell = true,

) => {
  const { pathname } = window.location;
  const objectNameFromURL = pathname.substring(pathname.lastIndexOf("/") + 1);
  const fields = data;
  const shownColumnFieldNames = shownColumns.filter((c) => c.value).map((c) => c.fieldName);
  const columns = fields
    .filter(
      (f) => filterFieldColumn(f))
    .filter(
      (f) => shownColumnFieldNames.includes(f.camelizedName)
    )
    .map((field) => {
      const { camelizedName: fieldName } = field;

      if (field.objectFieldType?.toLowerCase() === "url") {
        return {
          Header: field.label || startCase(fieldName),
          accessor: fieldName,
          Cell: UrlCell,
          defaultCanSort: true,
        };
      }

      if (field.objectFieldType?.toLowerCase() === "number") {
        return {
          Header: field.label || startCase(fieldName),
          accessor: fieldName,
          defaultCanSort: true,
          collapse: true,
        };
      }

      if (["table"].includes(field.objectFieldType?.toLowerCase())) {
        return {
          Header: field.label || startCase(fieldName),
          accessor: fieldName,
          Cell: RelationalFieldCell,
          defaultCanSort: false,
          collapse: true,
        };
      }
      if (field.objectFieldType?.toLowerCase() === "lookup") {
        if (
          (fieldName === "unit" &&
            pascalize(objectName) === dynamicObjectMap.get("ServiceRequestObjectName")) ||
          (fieldName === "unit" &&
            pascalize(objectName) === dynamicObjectMap.get("ReservationObjectName")) ||
          (fieldName === "unit" &&
            pascalize(objectName) === dynamicObjectMap.get("CustomerQuotationObjectName"))
        ) {
          return {
            Header: field.label || startCase(fieldName),
            accessor: fieldName,
            defaultCanSort: false,
            Cell: UnitCell,
          };
        }

        return {
          Header: field.label || startCase(fieldName),
          accessor: fieldName,
          defaultCanSort: false,
          Cell: LookupCell,
        };
      }

      if (field.objectFieldType?.toLowerCase() === "polymorphic") {
        return {
          Header: field.label || startCase(fieldName),
          accessor: fieldName,
          defaultCanSort: false,
          Cell: PolymorphicCell,
        };
      }

      if (field.objectFieldType?.toLowerCase() === "text") {
        return {
          Header: field.label || startCase(fieldName),
          accessor: fieldName,
          defaultCanSort: true,
          Cell: TruncatedTextCell,
        };
      }

      if (field.objectFieldType?.toLowerCase() === "date") {
        return {
          Header: field.label || startCase(fieldName),
          accessor: fieldName,
          defaultCanSort: false,
          Cell: DateCell,
        };
      }

      if (statusFieldNames.includes(fieldName)) {
        return {
          Header: field.label || startCase(fieldName),
          accessor: fieldName,
          Cell: StatusCell,
        };
      }

      return {
        Header: field.label || startCase(fieldName),
        accessor: fieldName,
        defaultCanSort: true,
      };
    });

  if (showCreatedAtCell) {
    columns.push({
      Header: "Created At",
      accessor: "createdAt",
      Cell: DateCell,
      defaultCanSort: true,
    });
  }

  if (showActionCell) {
    columns.push({
      Header: "Action",
      accessor: "action",
      Cell: (props) => ActionCell(props, objectName, actions),
      defaultCanSort: false,
      collapse: true,
    });
  }

  if (objectNameFromURL === "work-order-aging") {
    columns.push({
      Header: "Aging",
      accessor: "aging",
      defaultCanSort: true,
      collapse: true,
    });
  }

  if (objectData?.relationalObjectType === "Table") {
    const accessorValue = objectData?.objectName?.split("_")[0];
    columns.unshift({
      Header: startCase(accessorValue),
      accessor: camelize(accessorValue),
      defaultCanSort: true,
      Cell: LookupCell,
    });
  }

  if (columns.length) {
    columns[0].collapse = false;
  }

  return columns;
};

export const getDynamicObjectFieldValue = (value, field) => {
  const { objectFieldType, camelizedName } = field;
  const fieldValue = prepareFieldState({ field, value: value[camelizedName] });

  switch (objectFieldType?.toLowerCase()) {
    case "table":
      return "";

    case "lookup": {
      if (!fieldValue) return "";

      if (Array.isArray(fieldValue)) {
        return fieldValue.map((fieldItem) => ({
          ...fieldItem,
          isStaticObject: fieldItem.isStaticObject,
          label: fieldItem.label,
          value: fieldItem.value,
          objectName: fieldItem.lookupObjectName,
        }));
      }

      return {
        ...fieldValue,
        isStaticObject: field.isStaticObject,
        label: fieldValue.label,
        value: fieldValue.value,
        objectName: field.lookupObjectName,
      };
    }

    case "polymorphic": {
      if (!fieldValue) return "";

      const lookupValue = fieldValue;

      if (Array.isArray(lookupValue)) {
        return lookupValue.map((v) => v.label).join(", ");
      }

      return {
        ...lookupValue,
        label: lookupValue.label,
        value: lookupValue.value,
        objectName: fieldValue.lookupObjectName,
      };
    }

    case "select": {
      if (!fieldValue) return "";

      if (Array.isArray(fieldValue)) {
        return fieldValue.map((v) => v.label).join(", ");
      }

      if (statusFieldNames.includes(camelizedName)) {
        return fieldValue.value;
      }

      return fieldValue.label;
    }

    case "text": {
      if (!field.richText) {
        return fieldValue;
      }

      return "";
    }

    case "money":
      if (fieldValue) {
        return formatCurrency(fieldValue);
      }

      return "-";

    case "date":
      if (fieldValue && isValid(fieldValue)) {
        return fieldValue;
      }

      return "";

    default:
      if (typeof fieldValue !== "object") {
        return fieldValue;
      }

      return "";
  }
};

export const getDynamicObjectOverviewTableData = (values, columns, objectName, objectData) => {
  const data = [];
  const { pathname } = window.location;
  const objectNameFromURL = pathname.substring(pathname.lastIndexOf("/") + 1);
  values.forEach((value, index) => {
    const entry = {};

    columns.forEach((field) => {
      entry[field.camelizedName] = getDynamicObjectFieldValue(value, field);
    });

    entry.id = index;
    entry.key = value.id;
    entry.createdAt = value.createdAt;
    entry.isNewCreated = value.isNewCreated;
    entry.action = "";

    if (objectNameFromURL === "work-order-aging") {
      const currentDate = new Date();
      const createdDate = parseISO(value.createdAt);
      entry.aging = differenceInDays(currentDate, createdDate);
    }

    if (objectData?.relationalObjectType === "Table") {
      const accessorValue = objectData?.objectName?.split("_")[0];
      entry[camelize(accessorValue)] = {
        label: value[camelize(accessorValue)]?.number ||
        value[camelize(accessorValue)]?.name || null,
        value: value[camelize(accessorValue)]?.id || null
      };
    }
    data.push(entry);
  });

  return data;
};
