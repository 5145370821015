import PropTypes from "prop-types";
import DatePicker from "react-date-picker";
import { getTestId, getDateFormat } from "@/utils/helpers";

export default function CustomizedDatePicker({
  value, onChange, format, disabled, name, error, testId, className, ...rest
}) {
  const dateFormat = getDateFormat();
  return (
    <div>
      <DatePicker
        className={`custom-date-picker ${className}`}
        calendarIcon={null}
        onChange={onChange}
        defaultValue={(rest.id === "dOB" && value === null) ? rest.maxDate : value}
        value={value}
        format={dateFormat}
        disabled={disabled}
        data-testid={getTestId(testId)}
        closeCalendar
        {...rest}
      />
      {typeof error === "string" && <span className="error-text">{error}</span>}
    </div>
  );
}

CustomizedDatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  testId: PropTypes.string,
};

CustomizedDatePicker.defaultProps = {
  format: "dd/MM/y",
  disabled: false,
  name: "datePicker",
  error: false,
  value: null,
  testId: ""
};

CustomizedDatePicker.Type = "HydraValidatableComponent";
