import qs from "qs";

export default function usePreviousFormState(key = "previousFormState") {
  const setPreviousFormState = (previousFormState) => {
    window.localStorage.setItem(key, qs.stringify(previousFormState));
  };

  const getPreviousFormState = () => {
    const stringifiedState = window.localStorage.getItem(key);

    if (stringifiedState) {
      return qs.parse(stringifiedState);
    }

    return stringifiedState;
  };

  const previousFormState = getPreviousFormState();

  const setValueInPreviousFormState = (name, value) => {
    previousFormState[name] = value;

    setPreviousFormState(previousFormState);
  };

  return {
    previousFormState,
    setPreviousFormState,
    setValueInPreviousFormState
  };
}
