import appSettings from "@/settings";
import { registerUserDevice } from "@/api/user/notificationApi";

function prepareSubscriptionData(subscription) {
  const parsedSubscription = JSON.parse(JSON.stringify(subscription));

  const data = {
    $type: "Web",
    name: "Browser",
    pushEndpoint: parsedSubscription.endpoint,
    pushP256DH: parsedSubscription.keys.p256dh,
    pushAuth: parsedSubscription.keys.auth
  };

  return data;
}

function urlBase64ToUint8Array(base64String) {
  // eslint-disable-next-line no-mixed-operators
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export async function askPermission() {
  return new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission((result) => {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then((permissionResult) => {
    if (permissionResult !== "granted") {
      throw new Error("We weren't granted permission.");
    }

    return permissionResult;
  });
}

function checkSupport() {
  if (!("serviceWorker" in navigator)) {
    // Service Worker isn't supported on this browser, disable or hide UI.
    return false;
  }

  if (!("PushManager" in window)) {
    // Push isn't supported on this browser, disable or hide UI.
    return false;
  }

  return true;
}

export function registerServiceWorker() {
  const isSupported = checkSupport();

  if (!isSupported) {
    return;
  }

  return navigator.serviceWorker
    .register("/service-worker.js")
    .then((registration) => {
      console.log("Service worker successfully registered.");
      return registration;
    })
    .catch((err) => {
      console.error("Unable to register service worker.", err);
    });
}

function sendSubscriptionToBackEnd(subscription) {
  const data = prepareSubscriptionData(subscription);
  return registerUserDevice(data)
    .then((response) => {
      if (!response.success) {
        throw new Error("Bad status code from server.");
      }

      return response;
    })
    .then((responseData) => {
      if (!responseData.data) {
        throw new Error("Bad response from server.");
      }

      return responseData;
    });
}

export async function subscribeUserToPush() {
  return navigator.serviceWorker
    .register("/service-worker.js")
    .then((registration) => {
      const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(appSettings.publicVapidKey),
      };

      return registration.pushManager.subscribe(subscribeOptions);
    })
    .then((pushSubscription) => sendSubscriptionToBackEnd(pushSubscription))
    .catch((err) => {
      console.error("Unable to register service worker.", err);
    });
}
