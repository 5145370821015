import request from "@/utils/api/helpers";

const baseUrl = "/finance/tax-groups";

export const getTaxGroups = () => request({
  url: baseUrl
});

export const getTaxGroupById = (id) => request({
  url: `${baseUrl}/${id}`
});

export const createTaxGroup = (data) => request({
  url: baseUrl,
  method: "POST",
  data
});

export const updateTaxGroup = (id, data) => request({
  url: `${baseUrl}/${id}`,
  method: "PATCH",
  data
});

export const deleteTaxGroup = (id) => request({
  url: `${baseUrl}/${id}`,
  method: "DELETE",
});
