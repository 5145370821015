import { actionCreator } from "@/utils/helpers";

export const SET_FORM_VALUE = "SET_FORM_VALUE";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";

export const initialState = {
  code: "",
  name: "",
  agency: "Federal Tax Authority",
  company: null,
  saleCollected: false,
  saleReclaimable: false,
  saleRate: "",
  saleAccount: null,
  purchaseCollected: false,
  purchaseReclaimable: false,
  purchaseRate: "",
  purchaseAccount: null,
  description: "",
  taxType: {
    label: "VAT",
    value: "VAT"
  },
  currency: null
};

export const taxRuleFormReducer = (state, action) => {
  switch (action.type) {
    case SET_FORM_VALUE:
      return ({
        ...state,
        [action.payload.key]: action.payload.value
      });

    case SET_INITIAL_STATE:
      return {
        ...action.payload
      };

    default:
      throw new Error(`${action.type} not found`);
  }
};

export const setFormValue = (key, value) => actionCreator(SET_FORM_VALUE, { key, value });

export const setInitialState = (value) => actionCreator(SET_INITIAL_STATE, value);
