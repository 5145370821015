import PropTypes from "prop-types";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { SvgIcon } from "@/components/common";

export default function Tooltip({
  className,
  activator,
  children,
  side,
  bottom,
  ...props
}) {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root delayDuration="250">
        <TooltipPrimitive.Trigger className="tooltip-activator" asChild>
          {activator}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content className={`tooltip-content ${className}`} side={side} align={bottom} {...props}>
          {children}
          <TooltipPrimitive.Arrow className="tooltip-arrow" width={12} height={5} />
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}

Tooltip.propTypes = {
  activator: PropTypes.node,
  children: PropTypes.node.isRequired,
  side: PropTypes.string,
  align: PropTypes.string,
  bottom: PropTypes.string,
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  className: "",
  side: "bottom",
  bottom: "",
  align: "center",
  activator: (
    <span>
      <SvgIcon icon="tooltip-icon" label="info" />
    </span>
  ),
};
