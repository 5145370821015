import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { getToken } from "@/api/user/authApi";

export default function makeConnection(
  hubUrl,
  setCurrentConnection
) {

  const connection = new HubConnectionBuilder()
    .withUrl(hubUrl, { accessTokenFactory: () => getToken() })
    .configureLogging(LogLevel.Information)
    .build();

  // Counters to keep track of backoff/retry
  let retryDelay = 1000;
  let retryCount = 0;

  const start = () => {
    retryCount += 1;

    if (retryCount > 1) {
      console.log("signalR connection attempt");
    }

    connection
      .start()
      .then(() => {
        setCurrentConnection(connection);
      })
      .catch((error) => {
        console.log(error);

        if (retryCount === 15) {
          // stop
          console.error("signalR connection retry exhausted");
        } else {
          retryDelay *= 1.2;
          setTimeout(() => {
            start();
          }, retryDelay);
        }
      });
  };

  connection.onclose(() => {
    // Retry connection when closed
    retryDelay = 1000;
    retryCount = 0;
    start();
  });

  start();
}
