export const TASK_STATUSES = {
  CREATED: "Created",
  COMPLETED: "Completed",
  APPROVED: "Approved",
  VALID: "Validated",
  UPDATED: "Updated",
  SUBMITTED: "Submitted",
  INPROGRESS: "InProgress",
  REJECTED: "Rejected",
  INVALID: "NotValidated",
  STOPPED: "Stopped",
  SKIPPED: "Skipped"
};

export const TASK_TYPES = {
  CREATE: "Create",
  SUBMIT: "Submit",
  UPDATE: "Update",
  VALIDATE: "Validate",
  APPROVAL: "Approval",
};

export const APPROVED_STATUSES = [
  TASK_STATUSES.APPROVED,
  TASK_STATUSES.SUBMITTED,
  TASK_STATUSES.REJECTED,
  TASK_STATUSES.CREATED,
  TASK_STATUSES.COMPLETED,
  TASK_STATUSES.UPDATED,
  TASK_STATUSES.INVALID,
  TASK_STATUSES.VALID,
];
