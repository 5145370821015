/* eslint-disable default-param-last */

import { numberFormat } from "./helpers";
import { HEADER_ROW_ID, ROW_HEIGHT } from "./constants";

export const emptyTextCell = (className = "") => ({ type: "text", text: "", className });

export const textCell = (args) => {
  const {
    text, style, className, colspan, rowspan
  } = args;

  return {
    type: "text",
    text,
    className,
    style,
    colspan,
    rowspan
  };
};

export const checkboxCell = (args) => {
  const { checked, style, className } = args;

  return {
    type: "checkbox",
    checked,
    className,
    style,
  };
};

export const numberCell = (args) => {
  const { value, style, className } = args;

  return {
    type: "number",
    value,
    className: className ?? "",
    style,
    format: numberFormat,
  };
};

export const simpleNumberCell = (args) => {
  const { value, style, className } = args;

  return {
    type: "number",
    value,
    className: className ?? "",
    style,
  };
};

export const chevronCell = (args) => {
  const {
    text, isExpanded, hasChildren, parentId, indent, className, style
  } =
    args;

  return {
    type: "chevron",
    text,
    isExpanded: isExpanded ?? true,
    hasChildren,
    parentId,
    indent: indent ?? 0,
    className: className ?? "",
    style,
  };
};

export const nonEditable = (cell, className, groupId = "default") => ({
  ...cell,
  nonEditable: true,
  className,
  groupId
});

export const showZero = (cell) => ({
  ...cell,
  nanToZero: true,
  hideZero: false,
});

export const bottomLine = (cell) => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      ...cell.style?.border,
      bottom: {
        width: "1px",
        color: "#A6A6A6",
        style: "solid",
      },
    },
  },
});

export const noSideBorders = (cell) => ({
  ...cell,
  style: {
    ...cell.style,
    border: {
      ...cell.style?.border,
      left: {
        style: "none",
      },
      right: {
        style: "none",
      },
    },
  },
});

export const cellWithPadding = (cell, depth) => ({
  ...cell,
  style: {
    ...cell.style,
    paddingLeft: `${depth}rem`,
  },
});

export function headerCell(text, additionalClassNames = "trial-balance-header font-bold", colspan = 1) {
  return nonEditable(
    textCell({
      text,
      colspan
    }),
    additionalClassNames
  );
}

export function monthHeaderCell(month, additionalClassNames = "", colspan = 1, rowspan = 1) {
  return nonEditable(
    textCell({
      text: month,
      className: additionalClassNames,
      colspan,
      rowspan,
      style: {
        border: {
          bottom: { style: "none" },
          left: { style: "none" },
          right: { style: "none" }
        }
      }
    })
  );
}

const findChevronCell = (row) =>
  row.cells.find((cell) => cell.type === "chevron");

const findParentRow = (rows, row) =>
  rows.find((r) => {
    const foundChevronCell = findChevronCell(row);
    return foundChevronCell ? r.rowId === foundChevronCell.parentId : false;
  });

const hasChildren = (rows, row) =>
  rows.some((r) => {
    const foundChevronCell = findChevronCell(r);
    return foundChevronCell ? foundChevronCell.parentId === row.rowId : false;
  });

const isRowFullyExpanded = (rows, row) => {
  const parentRow = findParentRow(rows, row);
  if (parentRow) {
    const foundChevronCell = findChevronCell(parentRow);
    if (foundChevronCell && !foundChevronCell.isExpanded) return false;
    return isRowFullyExpanded(rows, parentRow);
  }
  return true;
};

export const getExpandedRows = (rows) =>
  rows.filter((row) => {
    const areAllParentsExpanded = isRowFullyExpanded(rows, row);
    return areAllParentsExpanded !== undefined ? areAllParentsExpanded : true;
  });

const getDirectChildRows = (rows, parentRow) => rows.filter(
  (row) =>
    !!row.cells.find(
      (cell) =>
        cell.type === "chevron" &&
          (cell).parentId === parentRow.rowId
    )
);
const assignIndentAndHasChildren = (
  rows,
  parentRow,
  indent = 0
) => {

  getDirectChildRows(rows, parentRow).forEach((row) => {
    const inData = indent + 1;
    const foundChevronCell = findChevronCell(row);
    const hasRowChildren = hasChildren(rows, row);
    if (foundChevronCell) {
      foundChevronCell.indent = inData;
      foundChevronCell.hasChildren = hasRowChildren;
    }
    if (hasRowChildren) assignIndentAndHasChildren(rows, row, indent);
  });
};

export const buildTree = (rows) =>
  rows.map((row) => {
    const foundChevronCell = findChevronCell(row);
    if (foundChevronCell && !foundChevronCell.parentId) {
      const hasRowChildren = hasChildren(rows, row);
      foundChevronCell.hasChildren = hasRowChildren;
      if (hasRowChildren) assignIndentAndHasChildren(rows, row);
    }
    return row;
  });

export function getHeaderRow(columns) {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: columns.map((column) => headerCell(column.label)),
  };
}
