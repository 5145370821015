import qs from "qs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const parseSearchParams = (params) => qs.parse(params.split("?")[1]);

export default function useUrlSearchParams() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    if (location.search) {
      const params = parseSearchParams(location.search);

      setSearchParams({
        ...params
      });
    } else {
      setSearchParams({});
    }

    return () => {};
  }, [location.search]);

  return searchParams;
}
