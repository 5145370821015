import PropTypes from "prop-types";

function StatusCircle({
  color, size, baseClassName, className
}) {
  const getClassName = () => {
    if (className) {
      return `${baseClassName} ${size} ${className}`;
    }

    return `${baseClassName} ${size}`;
  };
  return (
    <div
      className={getClassName()}
      style={{ backgroundColor: color }}
    />
  );
}

StatusCircle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(["small", "large"]),
  baseClassName: PropTypes.string,
  className: PropTypes.string,
};

StatusCircle.defaultProps = {
  color: "#90a3ae",
  size: "small",
  baseClassName: "status-circle",
  className: "",
};

export default StatusCircle;
