import { estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

const colClassSelector = (value) => {
  if (value > 0) {
    return "total-row-credit";
  }

  if (value < 0) {
    return "total-row-debit";
  }

  return "";
};

export default function getTrialBalanceColumns() {
  const colArr = [
    {
      label: "Account code",
      value: true,
      columnId: "code",
      resizable: true,
      width: estimatedColumnWidth("code"),
      formatter: (value) => String(value),
    },
    {
      label: "Account name",
      value: true,
      columnId: "name",
      resizable: true,
      width: estimatedColumnWidth("name"),
      formatter: (value) => String(value),
    },
    {
      label: "Opening Balance",
      value: true,
      columnId: "openingBalance",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      classname: (item) => colClassSelector(item.openingBalanceInCurrency),
      formatter: (value) => {
        if (!value) {
          return "";
        }
        return formatCurrency(value);
      },
    },
    {
      label: "Debit",
      value: true,
      columnId: "debits",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => {
        if (!value) {
          return "";
        }
        return formatCurrency(value);
      },
    },
    {
      label: "Credit",
      value: true,
      columnId: "credits",
      resizable: true,
      width: estimatedColumnWidth("amount"),
      formatter: (value) => {
        if (!value) {
          return "";
        }

        return formatCurrency(value);
      },
    },
    {
      label: "Net Activity",
      value: true,
      columnId: "netActivity",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      classname: (item) => colClassSelector(item.netActivityInCurrency),
      formatter: (value) => {
        if (!value) {
          return "";
        }
        return formatCurrency(value);
      },
    },
    {
      label: "Closing Balance",
      value: true,
      columnId: "closingBalance",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      classname: (item) => colClassSelector(item.closingBalanceInCurrency),
      formatter: (value) => {
        if (!value) {
          return "";
        }
        return formatCurrency(value);
      },
    },
  ];
  return colArr;
}
