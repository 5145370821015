import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getBankAccountStatementColumns() {

  const colArr = [
    {
      label: "Object Name",
      value: true,
      columnId: "ObjectNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Type",
      value: true,
      columnId: "type",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Date",
      value: true,
      columnId: "date",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))
    },
    {
      label: "Person Number", value: true, columnId: "personNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Person Name", value: true, columnId: "personName", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Component Number", value: true, columnId: "componentNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Component Name", value: true, columnId: "componentName", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Payment Type",
      value: true,
      columnId: "PaymentType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Payment Number", value: true, columnId: "paymentNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Debit Amount", value: true, columnId: "debit", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Credit Amount", value: true, columnId: "credit", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || 0))
    }
  ];

  return colArr;
}
