import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import {
  areIntervalsOverlapping,
  differenceInDays,
  differenceInMonths,
  getDaysInMonth,
  getOverlappingDaysInIntervals,
  isAfter,
  isEqual,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameDay,
  isSameMonth,
} from "date-fns";
import { toast } from "react-hot-toast";
import { kebabCase } from "lodash";
import { Button } from "@hydra/atom/components";
import { selectActiveCompany } from "@/store/appSlice";
import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { useCompanyAccount, useModal } from "@/hooks";
import { getFullName, formatDecimalValues } from "@/utils/helpers";
import {
  calculateUtilityAnnualAmount,
  calculateAmountForStayedPeriod,
} from "@/utils/leasing/helpers";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getRevenueRecognitionTable } from "@/components/finance/account-receivables/tableWithCheckboxData";
import showToast from "@/utils/toast/helpers";

function calculateAmountBasedOnDuration({
  annualAmount,
  pendingAmount,
  fromDate,
  toDate,
  lastRecognitionDate,
  agreementStartDate,
  agreementEndDate,
  moveOutDate,
}) {
  if (!annualAmount) {
    return 0;
  }

  const isFromDateFirstDayOfMonth = isFirstDayOfMonth(fromDate);
  const isToDateLastDayOfMonth = isLastDayOfMonth(toDate);
  const monthlyAmount = formatDecimalValues(Number(annualAmount / 12));
  let isFirstRecognitionMonth = false;
  let isLastRecognitionMonth = false;
  let isAlreadyRecognized = false;
  let recognizeByMonth = false;

  if (lastRecognitionDate) {
    if (
      isEqual(toDate, new Date(lastRecognitionDate)) ||
      isAfter(new Date(lastRecognitionDate), toDate)
    ) {
      isAlreadyRecognized = true;
    }
  }

  if (isAlreadyRecognized) {
    return 0;
  }

  if (!lastRecognitionDate && isSameMonth(new Date(agreementStartDate), new Date(fromDate))) {
    isFirstRecognitionMonth = true;
  }

  if (!differenceInMonths(new Date(lastRecognitionDate), new Date(agreementEndDate))) {
    isLastRecognitionMonth = true;
  }

  if (isSameDay(new Date(moveOutDate), new Date(toDate))) {
    isLastRecognitionMonth = true;
  }

  const isStartDateFirstDayOfMonth = isFirstDayOfMonth(new Date(agreementStartDate));
  const isEndDateLastDayOfMonth = isLastDayOfMonth(new Date(agreementEndDate));

  if (isFirstRecognitionMonth && isStartDateFirstDayOfMonth) {
    recognizeByMonth = true;
  }

  if (isLastRecognitionMonth && isEndDateLastDayOfMonth) {
    recognizeByMonth = true;
  }

  const numberOfDays = differenceInDays(toDate, fromDate) + 1;
  const overlappingDays =
    getOverlappingDaysInIntervals(
      {
        start: new Date(agreementStartDate),
        end: new Date(agreementEndDate),
      },
      {
        start: fromDate,
        end: toDate,
      }
    ) + 1;

  if (isFromDateFirstDayOfMonth && isToDateLastDayOfMonth && overlappingDays === numberOfDays) {
    recognizeByMonth = true;
  }

  if (recognizeByMonth) {
    const diffInMonths = differenceInMonths(toDate, fromDate) + 1;

    const revenueAmount = Number(monthlyAmount * diffInMonths);

    if (revenueAmount >= pendingAmount) {
      return formatDecimalValues(revenueAmount);
    }

    return formatDecimalValues(revenueAmount);
  }

  if (isLastRecognitionMonth && pendingAmount < monthlyAmount) {
    return formatDecimalValues(pendingAmount);
  }

  const daysInTheMonth = getDaysInMonth(fromDate);
  const totalDaysDifference = overlappingDays / daysInTheMonth;
  const revenueAmount = Number(monthlyAmount * totalDaysDifference);

  if (!revenueAmount) {
    return 0;
  }

  if (revenueAmount >= pendingAmount) {
    return formatDecimalValues(revenueAmount);
  }

  return formatDecimalValues(revenueAmount);
}

const formatContracts = (data, fromDate, toDate, accounts, company) => {
  const filteredData = data.filter(
    ({ agreementStartDate, agreementEndDate, contractTerminationDate, moveOutDate }) => {
      let terminationDate = null;
      let tenantMoveOutDate = null;

      if (moveOutDate) {
        tenantMoveOutDate = new Date(moveOutDate);

        if (isAfter(new Date(fromDate), tenantMoveOutDate)) {
          return false;
        }
      }

      if (contractTerminationDate && !moveOutDate) {
        terminationDate = new Date(contractTerminationDate);

        if (isAfter(new Date(fromDate), terminationDate)) {
          return false;
        }
      }

      try {
        const isOverlapping = areIntervalsOverlapping(
          {
            start: new Date(agreementStartDate),
            end: new Date(agreementEndDate),
          },
          {
            start: new Date(fromDate),
            end: new Date(toDate),
          },
          {
            inclusive: true,
          }
        );

        if (isOverlapping) return true;
      } catch (error) {
        return false;
      }

      return false;
    }
  );

  const items = filteredData.map((item) => {
    let terminationDate = null;
    let tenantMoveOutDate = null;
    let endDate = toDate;

    if (item.contractTerminationDate) {
      terminationDate = new Date(item.contractTerminationDate);
    }

    if (item.moveOutDate) {
      tenantMoveOutDate = new Date(item.moveOutDate);
    }

    if (terminationDate && !tenantMoveOutDate && isAfter(toDate, terminationDate)) {
      endDate = terminationDate;
    }

    if (tenantMoveOutDate && isAfter(toDate, tenantMoveOutDate)) {
      endDate = tenantMoveOutDate;
    }

    const contractEndDate = item.agreementEndDateWithoutGracePeriod ?? item.agreementEndDate;
    const utilityAnnualAmount = calculateUtilityAnnualAmount({
      amount: item.utilityCharge,
      agreementStartDate: item.agreementStartDate,
      agreementEndDate: contractEndDate,
    });

    const recognizedRentalAmount = item.recognizedRentalRevenue ?? 0;
    const recognizedUtilityAmount = item.recognizedUtilityRevenue ?? 0;
    let pendingRentalAmount = item.contractAmount - recognizedRentalAmount;
    let pendingUtilityAmount = (item.utilityCharge ?? 0) - recognizedUtilityAmount;

    if (tenantMoveOutDate) {
      const contractAmountForStayedPeriod = calculateAmountForStayedPeriod({
        annualAmount: item.annualAmount,
        agreementStartDate: item.agreementStartDate,
        moveOutDate: tenantMoveOutDate,
      });
      const utilityAmountForStayedPeriod = calculateAmountForStayedPeriod({
        annualAmount: item.totalCharge,
        agreementStartDate: item.agreementStartDate,
        moveOutDate: tenantMoveOutDate,
      });

      pendingRentalAmount = contractAmountForStayedPeriod - recognizedRentalAmount;
      pendingUtilityAmount = utilityAmountForStayedPeriod - recognizedUtilityAmount;
    }

    if (pendingRentalAmount < 0) {
      pendingRentalAmount = 0;
    }

    if (pendingUtilityAmount < 0 || !pendingUtilityAmount) {
      pendingUtilityAmount = 0;
    }

    const pendingAmount = pendingRentalAmount + pendingUtilityAmount;

    return {
      isSelected: true,
      company: {
        label: company.name,
        value: company.id,
      },
      key: item.number,
      tenant: {
        label: getFullName(item.tenant),
        value: item.tenant.id,
      },
      contract: {
        label: item.number,
        value: item.id,
      },
      building: {
        label: item?.building?.name,
        value: item?.building?.id,
      },
      component: {
        label: item?.component?.name,
        value: item?.component?.id,
      },
      unit: item?.unit.map((unit) => ({
        label: unit?.name,
        value: unit?.id,
      })),
      agreementStartDate: new Date(item?.agreementStartDate),
      agreementEndDate: new Date(item?.agreementEndDate),
      rentalAmount: item.contractAmount,
      utilityAmount: item.utilityCharge,
      totalUtilityAmount: item.utilityCharge,
      totalAmount: item.contractAmount,
      totalContractAmount: item.contractAmount + item.utilityCharge,
      pendingAmount,
      recognizeRentalRevenue: true,
      recognizedRentalAmount,
      pendingRentalAmount,
      rentalRevenueRecognitionValue: calculateAmountBasedOnDuration({
        annualAmount: item.annualAmount,
        pendingAmount: pendingRentalAmount,
        fromDate,
        toDate: endDate,
        agreementStartDate: item.agreementStartDate,
        agreementEndDate: item.agreementEndDateWithoutGracePeriod ?? item.agreementEndDate,
        lastRecognitionDate: item.lastRentalRevenueRecognitionDate,
        moveOutDate: terminationDate,
      }),
      recognizedUtilityAmount,
      pendingUtilityAmount,
      recognizeUtilityRevenue: true,
      utilityRevenueRecognitionValue: calculateAmountBasedOnDuration({
        annualAmount: utilityAnnualAmount,
        pendingAmount: pendingUtilityAmount,
        fromDate,
        toDate: endDate,
        agreementStartDate: item.agreementStartDate,
        agreementEndDate: item.agreementEndDateWithoutGracePeriod ?? item.agreementEndDate,
        lastRecognitionDate: item.lastUtilityRevenueRecognitionDate,
        moveOutDate: terminationDate,
      }),
      unearnedRentalRevenueAccount: accounts.unearnedRentalRevenueAccount,
      rentalRevenueAccount: accounts.rentalRevenueAccount,
      unearnedUtilityRevenueAccount: accounts.unearnedUtilityRevenueAccount,
      utilityRevenueAccount: accounts.utilityRevenueAccount,
    };
  });

  return {
    revenueRecognitionDetail: items,
  };
};

const contractItemsCalculations = (items) => {
  const filteredContracts = items.filter((i) => i.pendingAmount);

  const totalRentalAmount = filteredContracts.reduce(
    (prevValue, { rentalAmount }) => prevValue + rentalAmount,
    0
  );

  const totalRecognizedRentalAmount = filteredContracts.reduce(
    (prevValue, { recognizedRentalAmount }) => prevValue + recognizedRentalAmount,
    0
  );

  const balanceRentalAmount = filteredContracts.reduce(
    (prevValue, { pendingRentalAmount }) => prevValue + pendingRentalAmount,
    0
  );

  const totalUtilityAmount = filteredContracts.reduce(
    (prevValue, { utilityAmount }) => prevValue + utilityAmount,
    0
  );

  const totalRecognizedUtilityAmount = filteredContracts.reduce(
    (prevValue, { recognizedUtilityAmount }) => prevValue + recognizedUtilityAmount,
    0
  );

  const balanceUtilityAmount = filteredContracts.reduce(
    (prevValue, { pendingUtilityAmount }) => prevValue + pendingUtilityAmount,
    0
  );

  return {
    revenueRecognitionDetail: filteredContracts,
    totalRentalAmount,
    totalRecognizedRentalAmount,
    balanceRentalAmount,
    totalUtilityAmount,
    totalRecognizedUtilityAmount,
    balanceUtilityAmount,
  };
};

function HeaderRightContent({ openModal, showButton, ...rest }) {
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {showButton ? (
        <Button small bordered onClick={openModal}>
          Select contracts
        </Button>
      ) : null}
    </defaultComponents.HeaderRightContent>
  );
}

function RevenueRecognitionForm() {
  const activeCompany = useSelector(selectActiveCompany);
  const ref = useRef(null);
  const [contractTableData, setContractTableData] = useState([]);
  const [state, setState] = useState({});
  const [toastId, setToastId] = useState();
  const [accounts, setAccounts] = useState({
    unearnedRevenueAccount: null,
    revenueAccount: null,
  });
  const { isOpen, closeModal, openModal } = useModal(false);

  const defaultAccounts = useCompanyAccount({
    params: {
      includeCompanyId: true,
      isLinkedWithRecord: false,
    },
  });

  useEffect(() => {
    if (defaultAccounts) {
      const { unrealisedRentalRevenue, rentalRevenue, unrealisedUtilityRevenue, utilityRevenue } =
        defaultAccounts;

      setAccounts((prevState) => ({
        ...prevState,
        unearnedRentalRevenueAccount: unrealisedRentalRevenue,
        rentalRevenueAccount: rentalRevenue,
        unearnedUtilityRevenueAccount: unrealisedUtilityRevenue,
        utilityRevenueAccount: utilityRevenue,
      }));
    }
  }, [defaultAccounts]);

  const { data: contractData, isLoading } = useQuery(
    [kebabCase(dynamicObjectMap.get("ContractObjectName")), "Active"],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("ContractObjectName"), {
        sortBy: "CreatedAt",
        sortType: "DESC",
        "status[in]": "Active,Approved,RenewalPending,Ended,Expired,Broken,TerminationInProgress",
      })
  );

  useEffect(() => {
    if (contractData || isLoading) {
      const { fromDate, toDate } = state;
      if (!fromDate || !toDate) return;
      if (isLoading && state.fromDate && state.toDate) {
        setToastId(toast.loading("Loading contracts..."));
      }
      if (!contractData) return;
      const { revenueRecognitionDetail } = formatContracts(
        contractData.data,
        fromDate,
        toDate,
        accounts,
        activeCompany
      );

      if (!revenueRecognitionDetail.length) {
        toast.dismiss(toastId);
        showToast("No contracts found for the given date range!", "info");
      } else {
        toast.dismiss(toastId);
        showToast("Contracts loaded!", "success");
      }

      const filteredContracts = revenueRecognitionDetail.filter(
        (c) => {
          if (c.rentalRevenueRecognitionValue) {
            return true;
          }

          if (c.utilityRevenueRecognitionValue) {
            return true;
          }

          return false;
        }
      );

      setContractTableData(filteredContracts);
      openModal();
    }

    return () => {
      if (toastId) {
        toast.dismiss(toastId);
      }
    };
  }, [contractData, state, isLoading]);

  const setRevenueRecognitionDetail = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { fromDate, toDate } = formState;

    if (!fromDate || !toDate) return;
    setState({ fromDate, toDate });
  };

  const setTotalContractAmount = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { revenueRecognitionDetail: contracts } = formState;

    const totalRentalAmount = contracts.reduce(
      (prevValue, { rentalAmount }) => prevValue + rentalAmount,
      0
    );

    const totalOpenRentalAmount = contracts.reduce(
      (prevValue, { pendingRentalAmount }) => prevValue + pendingRentalAmount,
      0
    );

    const totalUtilityAmount = contracts.reduce(
      (prevValue, { utilityAmount }) => prevValue + utilityAmount,
      0
    );

    const totalOpenUtilityAmount = contracts.reduce(
      (prevValue, { pendingUtilityAmount }) => prevValue + pendingUtilityAmount,
      0
    );

    const totalContractAmount = contracts.reduce(
      (prevValue, { totalAmount }) => prevValue + totalAmount,
      0
    );

    const totalOpenContractAmount = contracts.reduce(
      (prevValue, { pendingAmount }) => prevValue + pendingAmount,
      0
    );

    ref.current.setFormState({
      totalOpenRentalAmount,
      totalRentalAmount,
      totalOpenUtilityAmount,
      totalUtilityAmount,
      totalOpenContractAmount,
      totalContractAmount,
    });
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "fromDate":
      case "toDate": {
        setRevenueRecognitionDetail(key, value);
        break;
      }

      case "revenueRecognitionDetail":
        setTotalContractAmount(key, value);
        break;

      default:
        break;
    }
  };

  const handleConfirm = () => {
    if (contractTableData.length) {
      const selectedContracts = contractTableData.filter((q) => q.isSelected);

      if (selectedContracts) {
        const {
          totalRentalAmount,
          totalOpenRentalAmount,
          totalUtilityAmount,
          totalOpenUtilityAmount,
          totalOpenContractAmount,
          totalContractAmount,
        } = contractItemsCalculations(selectedContracts);

        ref.current.setFormState({
          revenueRecognitionDetail: selectedContracts,
          totalRentalAmount,
          totalOpenRentalAmount,
          totalUtilityAmount,
          totalOpenUtilityAmount,
          totalContractAmount,
          totalOpenContractAmount,
        });
      }
    }

    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Contracts"
        subtitle="Selected revenue recognition has following active contracts"
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        size="large"
      >
        {contractTableData.length ? (
          <TableWithCheckbox
            data={contractTableData}
            searchKey={["contract.label"]}
            columns={getRevenueRecognitionTable()}
            setData={setContractTableData}
            selectAll
            allowMultiple
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("RevenueRecognitionObjectName")}
        showHeader
        components={{
          HeaderRightContent: (props) =>
            HeaderRightContent({
              openModal,
              showButton: Boolean(contractTableData.length),
              ...props,
            }),
        }}
        onStateChange={onStateChange}
      />
    </BoxedContent>
  );
}

export default RevenueRecognitionForm;
