import { useEffect, useState, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { kebabCase, startCase } from "lodash";
import { useSearchParams } from "react-router-dom";

import { BoxedContent } from "@/components/common";
import { getDynamicObjectRecords, getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { AlertModal } from "@/components/modals";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import useTaskRedirect from "@/hooks/useTaskRedirect";
import { getPurchaseOrderTableColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import { useModal } from "@/hooks";

const formatPurchaseOrder = (purchaseOrder) => {
  const {
    id, number, itemDetail, discount, discountType, subtotal, total, itemType, supplier
  } = purchaseOrder;

  const data = {
    key: id,
    supplier: {
      label: supplier.name,
      value: supplier.id
    },
    purchaseOrder: {
      label: number,
      value: id,
    },
    itemDetail: itemDetail.map((item) => ({
      item: {
        label: item.item.itemName,
        value: item.item.id,
      },
      rate: item.rate,
      acceptedQty: item.quantity,
      suppliedQty: item.quantity,
      orderedQty: item.quantity,
    })),
    discount,
    discountType: {
      label: startCase(discountType),
      value: discountType,
    },
    subtotal,
    total,
    itemType: {
      label: itemType,
      value: itemType,
    }
  };

  if (purchaseOrder?.supplier && JSON.stringify(purchaseOrder?.supplier) !== "{}") {
    data.supplier = {
      ...purchaseOrder?.supplier,
      label: purchaseOrder?.supplier?.name,
      value: purchaseOrder?.supplier?.id
    };
  }
  return data;
};

function GRNForm({ }) {
  const ref = useRef();
  const [state, setState] = useState({});
  const [initialState, setInitialState] = useState(null);
  const [searchParams] = useSearchParams();
  const [purchaseOrderTableData, setPurchaseOrderData] = useState([]);
  const { redirect } = useTaskRedirect();
  const { isOpen, closeModal, openModal } = useModal(false);

  const purchaseOrderId = searchParams.get("purchaseOrder");

  const { data: purchaseOrderData } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("PurchaseOrderObjectName")),
      state.purchaseOrder?.value || purchaseOrderId,
    ],
    () =>
      getDynamicObjectRecordById(
        dynamicObjectMap.get("PurchaseOrderObjectName"),
        state.purchaseOrder?.value || purchaseOrderId
      ),
    {
      enabled: Boolean(state.purchaseOrder?.value) || Boolean(purchaseOrderId),
    }
  );

  const { data: supplierPurchaseOrderData } = useQuery(
    [kebabCase(dynamicObjectMap.get("PurchaseOrderObjectName")), state?.supplier?.value],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("PurchaseOrderObjectName")), {
        supplier: state?.supplier?.value,
        queryMode: "Deep",
      }),
    {
      enabled: Boolean(state?.supplier?.value),
    }
  );

  useEffect(() => {
    if (supplierPurchaseOrderData?.data &&
      supplierPurchaseOrderData?.data?.length &&
      state?.supplier) {
      const formattedPurchaseOrder = supplierPurchaseOrderData?.data?.map(
        (r) => formatPurchaseOrder(r));
      setPurchaseOrderData(formattedPurchaseOrder);
      openModal();
    } else {
      setPurchaseOrderData([]);
    }
  }, [supplierPurchaseOrderData, state?.supplier]);

  useEffect(() => {
    if (purchaseOrderData) {
      const formattedPurchaseOrder = formatPurchaseOrder(purchaseOrderData);
      setInitialState(formattedPurchaseOrder);
    }
  }, [purchaseOrderData]);

  const setSubTotal = (value) => {
    const total = value.reduce(
      (prevValue, currentValue) =>
        Number(prevValue) + Number(currentValue.rate ?? 0 * currentValue.acceptedQty),
      0
    );

    ref.current.setFormValue("subtotal", total);
    ref.current.setFormValue("total", total);
  };

  const setItemDetail = (value, key) => {
    let calculatedItemDetail = value;
    if (value && value.length > 0) {
      calculatedItemDetail = calculatedItemDetail.map((item) => ({
        ...item,
        suppliedQty: (
          parseInt(item?.acceptedQty === "" ? 0 : item?.acceptedQty ?? 0, 10) +
          parseInt(item?.rejectedQty === "" ? 0 : item?.rejectedQty ?? 0, 10)
        ),
        orderedQty: parseInt(item?.acceptedQty === "" ? 0 : item?.acceptedQty ?? 0, 10) +
          parseInt(item?.rejectedQty === "" ? 0 : item?.rejectedQty ?? 0, 10) +
          parseInt(item?.shortQty === "" ? 0 : item?.shortQty ?? 0, 10)
      }));
    } else if (value) {
      calculatedItemDetail = {
        ...value,
        suppliedQty: (
          parseInt(value?.acceptedQty === "" ? 0 : value?.acceptedQty ?? 0, 10) +
          parseInt(value?.rejectedQty === "" ? 0 : value?.rejectedQty ?? 0, 10)
        ),
        orderedQty: parseInt(value?.acceptedQty === "" ? 0 : value?.acceptedQty ?? 0, 10) +
          parseInt(value?.rejectedQty === "" ? 0 : value?.rejectedQty ?? 0, 10) +
          parseInt(value?.shortQty === "" ? 0 : value?.shortQty ?? 0, 10)
      };
    }

    ref.current.setFormValue(key, calculatedItemDetail);
  };

  const setTotalWithDiscountType = (value) => {
    const formState = ref.current.getState();

    const { discount, subtotal } = formState;

    if (!subtotal || !discount) {
      return;
    }

    if (value.value === "Amount") {
      const total = Number(subtotal) - Number(discount);

      ref.current.setFormValue("total", total);
      return;
    }

    if (value.value === "Percentage") {
      const total = subtotal - (subtotal * value) / 100;
      ref.current.setFormValue("total", total);
    }
  };

  const setTotalWithDiscount = (value) => {
    const formState = ref.current.getState();

    const { discountType, subtotal } = formState;

    if (!subtotal || !value) {
      return;
    }

    if (discountType.value === "Amount") {
      const total = Number(subtotal) - Number(value);

      ref.current.setFormValue("total", total);
      return;
    }

    if (discountType.value === "Percentage") {
      const total = subtotal - (subtotal * value) / 100;
      ref.current.setFormValue("total", total);
    }
  };

  const onStateChange = (key, value) => {

    if (key === "supplier") {
      setState({
        ...state,
        [key]: value,
      });
    }

    if (key === "purchaseOrder") {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }

    if (key === "itemDetail") {
      setItemDetail(value, key);
      setSubTotal(value);
    }

    if (key === "itemDetailTable") {
      setItemDetail(value, key);
    }

    if (key === "discountType") {
      setTotalWithDiscountType(value);
    }

    if (key === "discount") {
      setTotalWithDiscount(value);
    }
  };

  const handleConfirm = () => {
    if (purchaseOrderTableData.length) {
      const selectedQuotation = purchaseOrderTableData.find((q) => q.isSelected);

      if (selectedQuotation) {
        ref.current.setFormState(selectedQuotation);
      }
    }

    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Purchase Order"
        subtitle="Selected supplier has following submitted purchase orders"
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        size="large"
      >
        {purchaseOrderTableData.length ? (
          <TableWithCheckbox
            data={purchaseOrderTableData}
            columns={getPurchaseOrderTableColumns()}
            setData={setPurchaseOrderData}
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        initialData={initialState}
        objectName={dynamicObjectMap.get("GoodsReceiptNoteObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        onSuccess={(id) =>
          redirect("../goods-receipt-notes", {
            recordId: id,
            success: true,
          })}
        navigate={false}
      />
    </BoxedContent>
  );
}

export default GRNForm;
