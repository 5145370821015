import { useRef, useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { Button } from "@hydra/atom/components";

import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import useTaskRedirect from "@/hooks/useTaskRedirect";
import { getDynamicObjectRecordById, getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";
import { handleDrawer } from "@/utils/modal/helpers";

const getMoveInCheckList = async (objectName, contract) => {
  if (contract) {
    const moveInCheckListData = await getDynamicObjectRecords(
      dynamicObjectMap.get("MoveInCheckListObjectName"), {
        contract
      }
    );

    if (moveInCheckListData?.data?.length) {
      return moveInCheckListData.data[0].id;
    }
  }

  return null;
};

function HeaderRightContent(props) {
  const ref = useRef(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const moveOutRequest = searchParams.get("moveOutRequest");
  const contract = searchParams.get("contract");

  const { data: checkListId, isLoading } = useQuery(
    [kebabCase(dynamicObjectMap.get("MoveInCheckListObjectName"), contract)],
    () => getMoveInCheckList(dynamicObjectMap.get("MoveInCheckListObjectName"), contract),
    {
      enabled: Boolean(ref.current),
    }
  );

  useEffect(() => {
    if (moveOutRequest) {
      ref.current = true;
    }
  }, [moveOutRequest]);

  const handleViewCheckList = () => {
    if (checkListId && !isLoading) {
      navigate(
        handleDrawer(
          checkListId,
          null,
          false,
          "dynamicObjectDrawers"
        )
      );
    }
  };

  return (
    <defaultComponents.HeaderRightContent {...props}>
      {(ref.current && checkListId) ? (
        <Button small bordered onClick={handleViewCheckList} isLoading={isLoading}>
          View Move In Checklist
        </Button>
      ) : null}
    </defaultComponents.HeaderRightContent>
  );
}

const formatMoveOutRequest = (recordData) => {
  const data = {
    jobFor: {
      lookupObjectName: dynamicObjectMap.get("UnitObjectName"),
      label: recordData?.contract?.unit[0].name,
      value: recordData?.contract?.unit[0].id,
    },
    type: { label: "Move Out Purpose", value: "MoveOutPurpose" },
  };

  return data;
};

function VisualInspectionForm() {
  const { id } = useParams();
  const ref = useRef(null);
  const { redirect } = useTaskRedirect();
  const [searchParams] = useSearchParams();
  const moveOutRequest = searchParams.get("moveOutRequest");
  const [initialState, setInitialState] = useState(null);

  const isEditing = Boolean(id);

  const { data: moveOutRequestData } = useQuery(
    [kebabCase(dynamicObjectMap.get("MoveOutRequestObjectName")), moveOutRequest],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("MoveOutRequestObjectName"), moveOutRequest),
    {
      enabled: Boolean(moveOutRequest && !isEditing),
    }
  );

  useEffect(() => {
    if (moveOutRequestData && !isEditing) {
      const formattedMoveOutRequest = formatMoveOutRequest(moveOutRequestData);
      setInitialState(formattedMoveOutRequest);
    }
  }, [moveOutRequestData]);

  const onStateChange = (key, value) => {
    if (key === "defect") {
      const totalDamageAmount = value?.reduce((total, item) => total + Number(item.totalAmount), 0);
      ref.current.setFormState({
        total: totalDamageAmount,
      });
    }
  };

  const onChildStateChange = ({
    index,
    key,
    value,
    parentField,
    parentFieldType
  }) => {
    const formState = ref.current.getState();
    const stateKey = `${parentField}${parentFieldType}`;
    let parentFieldState = formState[stateKey] ?? {};

    if (index > -1) {
      parentFieldState = formState[parentField][index];
    }

    if (parentField === "defect") {
      switch (key) {
        case "amount":
        case "discount": {
          parentFieldState[key] = value;
          const { amount, discount } = parentFieldState;

          const totalAmount = parseInt(`${amount - discount}`, 10);
          if (totalAmount >= 0) {
            parentFieldState.totalAmount = totalAmount;
          } else {
            parentFieldState.totalAmount = 0;
          }
          break;
        }

        default:
          break;
      }
    }
  };

  const onSuccess = (recordId) => {
    redirect(-1, {
      recordId,
      success: true,
    });
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("VisualInspectionObjectName")}
        showHeader
        initialData={initialState}
        navigate={false}
        onSuccess={onSuccess}
        onStateChange={onStateChange}
        onChildStateChange={onChildStateChange}
        components={{
          HeaderRightContent,
        }}
      />
    </BoxedContent>
  );
}

export default VisualInspectionForm;
