import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Loader } from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { useUser } from "@/hooks";
import { getDashboardData } from "@/api/dynamic/dynamicDashboardApi";
import { formatDecimalValues } from "@/utils/helpers";

import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { LookupField } from "@/components/dynamic/fields";
import {
  ReportCard,
  TabWithBadge
} from "@/components/property";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectByName } from "@/api/dynamic/dynamicObjectSchemaApi";
import DynamicObjectOverview from "@/pages/dynamic/list-views/DynamicObjectOverview";
import { DynamicObjectActivityDrawer } from "@/components/dynamic";
import {
  BoxedContent,
  Header,
} from "@/components/common";
import { BarChart } from "@/components/charts";

function DetailItem({ heading, children }) {
  return (
    <div className="row">
      <div className="col-12">
        <h6 className="headline">{heading}</h6>
        {children}
      </div>
    </div>
  );
}

DetailItem.propTypes = {
  heading: PropTypes.string.isRequired,
};

function PettyCashDashboard() {
  const [activeTab, setStatusTab] = useState("Supervisor Petty Cash Request");
  const [barChartData, setBarChartData] = useState([]);
  const [totalCash, setTotalCash] = useState();
  const [totalExpense, setTotalExpense] = useState();
  const [, setTotalTransaction] = useState();
  const [selectedSupervisor, setSelectedSupervisor] = useState();
  const { user } = useUser();

  const { data, isLoading } = useQuery(
    ["facility-detail", selectedSupervisor],
    () => {
      let params;
      if (user?.roles.includes("supervisor") || user?.roles.includes("cash_officer")) {
        params = [];
        params.push({
          Name: "Technician",
          Label: "@Technician",
          Value: `${user.userKey}`,
          Required: false,
        });
      } else if (selectedSupervisor?.value) {
        params = [];
        params.push({
          Name: "Technician",
          Label: "@Technician",
          Value: `${selectedSupervisor?.value}`,
          Required: false,
        });
      }
      return getDashboardData({
        DashboardName: "petty-cash-web-dashboard",
        Params: params,
      });
    },
    {
      enabled: Boolean(user?.userKey),
    }
  );

  const { data: supervisorData } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("EmployeeObjectName"))
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("EmployeeObjectName"), {
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "List"
      })
  );

  useEffect(() => {
    if (!supervisorData) return;
    if (user?.roles.includes("supervisor") || user?.roles.includes("cash_officer")) {
      let supervisorDataTemp = supervisorData.data.find((item) => item.id === user.userKey);
      supervisorDataTemp = {
        ...supervisorDataTemp,
        label: supervisorDataTemp.name,
        value: supervisorDataTemp.id,
      };
      setSelectedSupervisor(supervisorDataTemp);
    }
  }, [supervisorData]);

  const formatBarChartData = (chartData) => {
    chartData.data.forEach((entry) => {
      entry.creationMonth = entry.creationMonth.trim();
    });

    const monthMap = {
      January: "Jan",
      February: "Feb",
      March: "Mar",
      April: "Apr",
      May: "May",
      June: "Jun",
      July: "Jul",
      August: "Aug",
      September: "Sep",
      October: "Oct",
      November: "Nov",
      December: "Dec"
    };

    const aggregatedData = chartData.data.reduce((acc, entry) => {
      const month = monthMap[entry.creationMonth];
      if (!acc[month]) {
        acc[month] = { label: month, Income: 0, Expense: 0 };
      }
      if (entry.type === "Credit") {
        acc[month].Income += entry.amount;
      } else if (entry.type === "Debit") {
        acc[month].Expense += entry.amount;
      }
      return acc;
    }, {});

    const barChartDataFinal = Object.keys(monthMap).map((month) => {
      const label = monthMap[month];
      return {
        label,
        Income: formatDecimalValues(aggregatedData[label]?.Income) || 0,
        Expense: formatDecimalValues(aggregatedData[label]?.Expense) || 0,
        valueColor: "#9E77ED",
        value1Color: "#D6BBFB"
      };
    });
    setBarChartData(barChartDataFinal);
  };

  useEffect(() => {
    if (!data) return;
    formatBarChartData(data.data.find((item) => item.name === "cashflow"));
    setTotalCash(data.data.find((item) => item.name === "totalcash")?.data[0]?.total);
    setTotalExpense(data.data.find((item) => item.name === "expense")?.data[0]?.total);
    setTotalTransaction(data.data.find((item) => item.name === "totaltransactions")?.data[0]?.total);

  }, [data]);
  const tabs = [
    { label: "Supervisor Petty Cash Request" },
    { label: "Petty Cash Requests" },
    { label: "Petty Cash Transactions" },
  ];

  const handleStatusTabChange = (index) => {
    setStatusTab(tabs[index].label);
  };

  const { data: objectSchema } = useQuery(
    ["dynamic-object-schema-supervisor-petty-cash", kebabCase(dynamicObjectMap.get("SupervisorPettyCashRequestObjectName"))],
    () => getDynamicObjectByName(dynamicObjectMap.get("SupervisorPettyCashRequestObjectName"))
  );

  const handleSupervisorListChange = (e) => {
    setSelectedSupervisor(e);
  };

  const rightContent = () => (
    <div className="container-right-content">
      <LookupField
        field={objectSchema?.document.find((f) => f.name === "Supervisor")}
        value={selectedSupervisor}
        onChange={handleSupervisorListChange}
        showLabel={false}
        disabled={user?.roles.includes("supervisor") || user?.roles.includes("cash_officer")}
      />
    </div>
  );

  const leftContent = (title, subtitle) => (
    <div className="container-left-content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );

  const renderView = () => {
    switch (activeTab) {
      case "Supervisor Petty Cash Request":
        return (
          <DynamicObjectOverview
            objectName={activeTab}
            showActionCell={false}
            showBreadcrumb={false}
            showActionButton={false}
          />
        );
      case "Petty Cash Requests":
        return (
          <DynamicObjectOverview
            objectName={dynamicObjectMap.get("PettyCashRequestObjectName")}
            showActionCell={false}
            showBreadcrumb={false}
            showActionButton={false}
          />
        );

      case "Petty Cash Transactions":
        return (
          <DynamicObjectOverview
            objectName={dynamicObjectMap.get("PettyCashTransactionObjectName")}
            showActionCell={false}
            showBreadcrumb={false}
            showActionButton={false}
            filters={{
              type: "Debit"
            }}
          />
        );
      default:
        return (
          <div>404 Page Not Found !</div>
        );
    }
  };

  return (
    <BoxedContent className="dashboard">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header
            className="report-dashboard-header"
            showBreadcrumb
            leftContent={(
              <div>
                <h1>Petty Cash Dashboard</h1>
              </div>
            )}
          />
          <div className="report-container">
            <div className="report-img-container card">
              <Header
                leftContent={leftContent("Cash Flow", "Manage your team members and their account permissions here.")}
                rightContent={rightContent("View Requests")}
              />
              <div>
                <BarChart data={barChartData} keys={["Income", "Expense"]} />
              </div>
            </div>
            <div className="cards-container">
              <div className="card-container">
                <ReportCard
                  data={[
                    {
                      titleValue: formatDecimalValues(totalCash || 0),
                      titleColor: "primary",
                      subtitle: "Total Cash (AED)",
                      subtitleColor: "gray",
                    },
                    {
                      titleValue: formatDecimalValues((totalCash || 0) - (totalExpense || 0)),
                      titleColor: "primary",
                      subtitle: "Cash in Hand",
                      subtitleColor: "gray",
                    },
                    {
                      titleValue: formatDecimalValues(totalExpense || 0),
                      titleColor: "primary",
                      subtitle: "Total Expense (AED)",
                      subtitleColor: "gray",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <TabWithBadge
            tabs={tabs}
            className="primary-tabs"
            onClick={handleStatusTabChange}
          />
          {renderView()}
        </>
      )}
      <DynamicObjectActivityDrawer />
    </BoxedContent>
  );
}

PettyCashDashboard.propTypes = {};

export default PettyCashDashboard;
