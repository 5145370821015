import { getStandardSalesColumns, getStandardSalesRows } from "./standard-sales-statement";
import { getStandardRatedSales } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportStandardRatedSales({ activeCompany }) {
  return (
    <DynamicReport
      reportName="standard-rate-sale"
      activeCompany={activeCompany}
      columnsDataProvider={getStandardSalesColumns}
      rowsDataProvider={getStandardSalesRows}
      fetchData={getStandardRatedSales}
      exportUrl="reports/export-query-report/standard-rate-sale"
      filtersInitialState={{}}
      orderBy="InvoiceNumber"
    />
  );
}
