/* eslint-disable no-plusplus */
import { range } from "lodash";
import {
  nonEditable,
  textCell,
  headerCell
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT } from "../constants";
import { formatCurrency } from "@/utils/helpers";

export const CASH_IN_CHANGE_ROW_ID = "cashInChange";

function getHeaderRow(columns) {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: columns.map((column) => headerCell(column.label)),
  };
}

function getGroupRows(title, groups, columns, totalDebit, totalCredit, totalBalance) {
  if (groups?.length) {
    return [
      ...groups.map((item, index) => ({
        rowId: item.id,
        height: ROW_HEIGHT,
        cells: columns.map((column) => {
          const textCellArgs = {};

          if (column.formatter) {
            textCellArgs.text = column.formatter(item[column.columnId], index);
          } else {
            textCellArgs.text = item[column.columnId] ?? "";
          }

          return nonEditable(textCell(textCellArgs), column.className || "");
        }),
      })),
      {
        rowId: `${title}-Footer`,
        height: ROW_HEIGHT,
        cells: [
          ...range(0, columns.length - 6).map(() => headerCell("", "total-row")),
          headerCell(
            "Total", "total-row"),
          headerCell(formatCurrency(totalDebit || 0), "total-row total-row-credit"),
          headerCell(formatCurrency(totalCredit || 0), "total-row total-row-credit"),
          headerCell(formatCurrency(totalBalance || 0), "total-row total-row-credit"),
          ...range(0, columns.length - 8).map(() => headerCell("", "total-row")),
        ],
      },
    ];
  }
  return [];
}

export function getTenantOutstandingRows({
  data,
  columns
}) {

  if (!data) {
    return [];
  }

  let totalBalance = 0;
  let totalCredit = 0;
  let totalDebit = 0;

  data.data?.forEach((item) => {
    totalBalance += item.balance || 0;
    totalCredit += item.totalCollected || 0;
    totalDebit += item.totalOutstanding || 0;
  });

  return [getHeaderRow(columns), ...getGroupRows("Total", data.data, columns, totalDebit, totalCredit, totalBalance)];
}
