import { getClearChequeRows, getClearChequeColumns } from "./clear-cheque-statement";
import { getClearCheque } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const clearChequeFilters = {
  TenantName: "*",
  TenantKey: "*",
  ComponentName: "*",
  ComponentKey: "*",
  BankName: "*",
  BankKey: "*",
  ClearedBankName: "*",
  ClearedBankCode: "*",
};

const clearChequeFilterOptions = [
  { key: "tenantName", apiKey: "TenantName", keyType: "label" },
  { key: "tenantKey", apiKey: "TenantKey", keyType: "string" },
  { key: "componentName", apiKey: "ComponentName", keyType: "string" },
  { key: "componentKey", apiKey: "ComponentKey", keyType: "string" },
  { key: "bankName", apiKey: "BankName", keyType: "string" },
  { key: "bankKey", apiKey: "BankKey", keyType: "string" },
  { key: "clearedBankName", apiKey: "ClearedBankName", keyType: "string" },
  { key: "clearedBankCode", apiKey: "ClearedBankCode", keyType: "string" },
];

export default function ReportClearCheque({ activeCompany }) {
  return (
    <DynamicReport
      reportName="clear-cheque"
      activeCompany={activeCompany}
      columnsDataProvider={getClearChequeColumns}
      rowsDataProvider={getClearChequeRows}
      fetchData={getClearCheque}
      exportUrl="reports/export-query-report/clear-cheque"
      filtersInitialState={clearChequeFilters}
      filterOptions={clearChequeFilterOptions}
      isPaginated={false}
    />
  );
}
