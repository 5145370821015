import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getPettyCashByBuildingColumns() {

  const colArr = [
    {
      label: "Name",
      value: true,
      columnId: "supervisorName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Building Name",
      value: true,
      columnId: "buildingName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Building Number",
      value: true,
      columnId: "buildingNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit Name",
      value: true,
      columnId: "unitName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit Number",
      value: true,
      columnId: "unitNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Expense Type",
      value: true,
      columnId: "expenseType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Bill Number",
      value: true,
      columnId: "receiptNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Bill Date",
      value: true,
      columnId: "receiptDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Loc. Number",
      value: true,
      columnId: "locNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Amount",
      value: true,
      columnId: "receiptAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || "0"))
    }
  ];

  return colArr;
}
