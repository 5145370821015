import { getZeroPurchaseColumns, getZeroPurchaseRows } from "./zero-purchase-statement";
import { getZeroRatedPurchase } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportZeroRatedPurchase({ activeCompany }) {
  return (
    <DynamicReport
      reportName="zero-rate-purchase"
      activeCompany={activeCompany}
      columnsDataProvider={getZeroPurchaseColumns}
      rowsDataProvider={getZeroPurchaseRows}
      fetchData={getZeroRatedPurchase}
      exportUrl="reports/export-query-report/zero-rate-purchase"
      filtersInitialState={{}}
      orderBy="InvoiceNumber"
    />
  );
}
