import { getPlannedActualPpmInhouseRows, getPlannedActualPpmInhouseColumns } from "./planned-actual-ppm-inhouse-statement";
import { getPlannedActualPpmInhouse } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const plannedVsActualPpmInhouseFilters = {
  WorkOrderNumber: "*",
  BuildingId: "*",
  SupervisorId: "*",
  CategoryId: "*"
};

const plannedVsActualPpmInhouseFilterOptions = [
  { key: "workOrderNumber", apiKey: "WorkOrderNumber", keyType: "string" },
  { key: "buildingId", apiKey: "BuildingId" },
  { key: "supervisorId", apiKey: "SupervisorId" },
  { key: "categoryId", apiKey: "CategoryId" },
];

export default function ReportPlannedVsActualPpmInhouse({ activeCompany }) {
  return (
    <DynamicReport
      reportName="ppm-report-internal"
      activeCompany={activeCompany}
      columnsDataProvider={getPlannedActualPpmInhouseColumns}
      rowsDataProvider={getPlannedActualPpmInhouseRows}
      fetchData={getPlannedActualPpmInhouse}
      exportUrl="reports/export-query-report/ppm-report-internal"
      filtersInitialState={plannedVsActualPpmInhouseFilters}
      filterOptions={plannedVsActualPpmInhouseFilterOptions}
      orderBy="BuildingName"
    />
  );
}
