/* eslint-disable max-len */
import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Pagination } from "@hydra/atom/components";
import { kebabCase } from "lodash";
import { useSelector } from "react-redux";
import { AlertModal } from "@/components/modals";
import { useModal } from "@/hooks";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";

import {
  getUnitTableColumns,
  getTenantsTableColumns,
  getWorkOrdersTableColumns,
  getTechnicianTableColumns,
  getTechnicianFinanceTableColumns,
  getReportOverviewTableColumns,
} from "./reportTableData";
import formatBuildingReport from "@/utils/leasing/helpers";
import { selectActiveApp } from "@/store/appSlice";

export default function ReportTable({
  data,
  objectName,
  perPageOptions,
  activeStatusTab,
  headerRight,
  headerLeft,
}) {
  const columnMap = {
    Overview: getUnitTableColumns,
    Units: getUnitTableColumns,
    Tenants: getTenantsTableColumns,
    "Work orders": getWorkOrdersTableColumns,
    Technician: getTechnicianTableColumns,
    "Reports Overview": getReportOverviewTableColumns,
    "Technician Finance": getTechnicianFinanceTableColumns,
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageOptions[0]);

  const { data: buildingList, isLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("BuildingObjectName")),
      "Building",
      currentPage,
      perPage.value,
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("BuildingObjectName"), {
        takePage: currentPage,
        limitPage: perPage.value,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
      })
  );

  const totalCount = useMemo(
    () => (data?.length ? data.length : buildingList?.totalCount || 0),
    [buildingList, data]
  );
  const totalPages = useMemo(
    () => (data?.length ? 3 : buildingList?.totalPages),
    [buildingList, data]
  );
  const tableData = useMemo(
    // eslint-disable-next-line no-nested-ternary
    () => (data?.length ? data : objectName === dynamicObjectMap.get("BuildingObjectName") ? formatBuildingReport(buildingList?.data) : null),
    [buildingList, data]
  );

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };
  const columns = useMemo(
    () =>
      (isLoading ?
        columnMap[activeStatusTab](objectName).map((column) => ({
          ...column,
          Cell: Skeleton,
        })) :
        columnMap[activeStatusTab](objectName)),
    [isLoading, activeStatusTab, buildingList, objectName]
  );
  const [, setSelectedId] = useState(null);
  const { isOpen, closeModal, openModal } = useModal(false);
  const navigate = useNavigate();
  const activeApp = useSelector(selectActiveApp);

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } =
    useTable({
      columns,
      data: tableData || [],
      toggleAlertDialogue: (id) => {
        openModal();
        setSelectedId(id);
      },
      editJob: (id) => {
        navigate(`/facility/work-order/${id}`);
      },
    });

  const handleDelete = async () => {
    // delete mutation
  };

  const onClick = async (e, rowProps) => {
    const { tagName, parentNode } = e.target;
    const parentClassName = parentNode?.className?.trim();
    const tagNames = ["BUTTON", "A", "svg"];
    const parentClassNames = [
      "table-row",
      "table-body-cell",
      "truncated-text-cell",
      "status-cell",
      "link-cell",
      "auto-number-cell",
    ];
    if ((!tagNames.includes(tagName)) && parentClassNames.includes(parentClassName)) {
      if (objectName === "work-order-job") {
        navigate(`/${kebabCase(activeApp?.value)}/work-order/${rowProps.original.workOrderId}?jobId=${rowProps.original.key}`);
      } else if (rowProps?.original?.to) {
        navigate(`${rowProps.original?.to}`);
      } else if (objectName === "tenant") {
        navigate(`/${kebabCase(activeApp?.value)}/${kebabCase(objectName)}/details/${rowProps.original.id}?readOnly=true`);
      } else {
        navigate(`/${kebabCase(activeApp?.value)}/${kebabCase(objectName)}/${rowProps.original.key}?readOnly=true`);
      }
    }
  };

  return (
    <div className="table-wrapper">
      <div className="filters-row table-view-filters-row gap-increase">
        {headerLeft && headerLeft}
        {headerRight && headerRight}
      </div>

      <div className="overflow">
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className="table-header-row"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className={`table-header-cell ${
                      column.collapse ? "collapse" : ""
                    }`}
                    {...column.getHeaderProps()}
                  >
                    <div className="table-header-cell-content">
                      <span>{column.render("Header")}</span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  className="table-row"
                  {...row.getRowProps()}
                  onClick={(e) => onClick(e, row)}
                >
                  {row.cells.map((cell) => (
                    <td
                      className={`table-body-cell ${
                        cell.column.collapse ? "collapse" : ""
                      }`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!isLoading && (
        <Pagination
          className="dashboard-pagination"
          options={perPageOptions}
          perPage={perPage}
          onSelectChange={(val) => handlePagination(currentPage, val)}
          pageRangeDisplayed={3}
          handlePageClick={(pageNo) =>
            handlePagination(pageNo.selected + 1, perPage)}
          showResults
          offset={0}
          totalItems={totalCount}
          pageCount={Math.ceil(totalPages / perPage.value)}
          reactPaginateProps={{
            previousLabel: <span className="material-icons">&#xe5cb;</span>,
            nextLabel: <span className="material-icons">&#xe5cc;</span>,
            forcePage: currentPage - 1,
          }}
        />
      )}

      <AlertModal
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={handleDelete}
      />
    </div>
  );
}

ReportTable.propTypes = {
  perPageOptions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  activeStatusTab: PropTypes.string.isRequired,
  headerRight: PropTypes.element.isRequired,
  headerLeft: PropTypes.element.isRequired,
};

ReportTable.defaultProps = {
  isLoading: false,
};
