import PropTypes from "prop-types";
import {
  ListItemWithCheckbox
} from "@/components/facility/planboard";

function ListWithCheckbox({ list, onChange, selected }) {
  list.sort((a, b) => {
    if (a.itemName !== b.itemName) {
      return a.itemName.localeCompare(b.itemName);
    }
    return a.itemName.localeCompare(b.itemName);
  });

  return list.map((item, i) => (
    <ListItemWithCheckbox key={`list-item-with-checkbox-${i}`} onChange={onChange} item={item} keyId={i} selected={selected} />
  )
  );
}

ListWithCheckbox.propTypes = {
  list: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default ListWithCheckbox;
