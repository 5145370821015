import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getForthComingColumns() {

  const colArr = [
    {
      label: "Contract No", value: true, columnId: "contractNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Contract Type", value: true, columnId: "propertyType", width: estimatedColumnWidth("type"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Company",
      value: true,
      columnId: "company",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Building", value: true, columnId: "building", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Region",
      value: true,
      columnId: "region",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit", value: true, columnId: "unit", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit No.", value: true, columnId: "unitNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Type", value: true, columnId: "unitType", width: estimatedColumnWidth("type"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Area", value: true, columnId: "area", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Status", value: true, columnId: "status", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Bedroom", value: true, columnId: "bedroom", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Tenant No.",
      value: true,
      columnId: "tenantNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Name", value: true, columnId: "tenantName", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Type", value: true, columnId: "tenantType", width: estimatedColumnWidth("type"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Telephone", value: true, columnId: "tenantPhone", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Mobile", value: true, columnId: "tenantMobile", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Email", value: true, columnId: "tenantEmail", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Date Start", value: true, columnId: "startDate", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Date End", value: true, columnId: "endDate", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "PSQM", value: true, columnId: "pSQM", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Annual", value: true, columnId: "annual", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || "0"))
    },
    {
      label: "Utility Charges", value: true, columnId: "utilityAmount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || "0"))
    },
    {
      label: "Contract Amount", value: true, columnId: "contractAmount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || "0"))
    },
    {
      label: "Reference", value: true, columnId: "reference", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "View", value: true, columnId: "view", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Months", value: true, columnId: "months", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Value", value: true, columnId: "cMA", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
  ];

  return colArr;
}
