import { getColumnWidth } from "../helpers";

export default function getApprotionmentColumns() {
  const windowWidth = window.innerWidth - 300;
  const totalColumns = 7;

  const colArr = [
    {
      label: "Row Labels", value: true, columnId: "rowLabels", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Commerical", value: true, columnId: "commerical", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Residential", value: true, columnId: "residential", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value === "No" ? "Active" : "Inactive" || "")
    },
    {
      label: "Admin", value: true, columnId: "admin", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value === "No" ? "Active" : "Inactive" || "")
    },
    {
      label: "Amount", value: true, columnId: "amount", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value === "No" ? "Active" : "Inactive" || "")
    },
    {
      label: "Revised Commerical", value: true, columnId: "revisedCommerical", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value === "No" ? "Active" : "Inactive" || "")
    },
    {
      label: "Revised Residential", value: true, columnId: "revisedResidential", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value === "No" ? "Active" : "Inactive" || "")
    }
  ];

  return colArr;
}
