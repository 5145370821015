import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { CustomActionDropdown } from "@/components/dynamic";
import { checkPermission } from "@/store/userSlice";

function ActionCell({ row }) {
  const navigate = useNavigate();

  const filteredActions = () => {
    const actions = [
      {
        title: "View",
        onClick: () => navigate(`${row.original.key}`),
        icon: "eye"
      },
    ];

    return actions.filter((item) => checkPermission("Model", row.original.name, item.title));
  };

  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={filteredActions()}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId="Jobs-Table-Action-Dropdown"
      />
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
};

function LinkCell({ row }) {
  return (
    <div className="link-cell">
      <Link to={`${row.original.key}`}>{row.original.name}</Link>
    </div>
  );
}

LinkCell.propTypes = {
  row: PropTypes.object.isRequired,
};

export const getDynamicObjectColumns = () => [
  {
    Header: "Name",
    accessor: "name",
    Cell: LinkCell
  },
  {
    Header: "Label",
    accessor: "label",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionCell,
    collapse: true
  }
];

export const getDynamicObjectData = (data) => data.map((dynamicObject) => ({
  key: dynamicObject.key,
  name: dynamicObject.objectName,
  label: dynamicObject.label,
  action: "",
}));
