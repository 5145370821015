import { useRef, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { components } from "react-select";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { kebabCase } from "lodash";
import {
  Button,
  Form,
  FormControl,
  Input,
  Label,
  TextArea,
  Loader,
  Checkbox,
  ReactSelect
} from "@hydra/atom/components";
import { selectPermission } from "@/store/userSlice";
import { CustomActionDropdown } from "@/components/dynamic";

import {
  Select, BoxedContent, Header, FormLeftHeader, CheckboxWithIcon
} from "@/components/common";
import {
  taxRuleFormReducer,
  initialState,
  setFormValue,
  setInitialState,
} from "@/reducers/finance/taxRuleFormReducer";
import { getDynamicObjectRecords, getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import {
  createTaxRule,
  updateTaxRule,
  getTaxRuleById,
} from "@/api/finance/taxRuleApi";
import { selectActiveCompany } from "@/store/appSlice";
import { nestedOptionStyles } from "@/utils/select/constants";
import { getLedgerAccounts } from "@/api/finance/ledgerAccountApi";
import { buildTree, flattenTree } from "@/utils/finance/helpers";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import showToast from "@/utils/toast/helpers";
import { getTestId } from "@/utils/helpers";
import { deleteTaxRate } from "@/api/finance/taxRateApi";

const taxTypeOptions = [
  {
    value: "VAT",
    label: "VAT",
  },
  {
    value: "Excise",
    label: "Excise Tax",
  },
  {
    value: "Custom",
    label: "Custom Tax",
  },
];

const purchaseReturnLineOptions = [
  {
    label: "Net Value of Purchases",
    value: "NetValueOfPurchase",
  },
  {
    label: "Net Value of Purchases to other GCC member States",
    value: "NetValueOfPurchaseToOtherGccMemberStates",
  },
];

const saleReturnLineOptions = [
  {
    label: "Net Value of Sale",
    value: "NetValueOfSale",
  },
  {
    label: "Net Value of Sale to other GCC member States",
    value: "NetValueOfSaleToOtherGccMemberStates",
  },
  {
    label: "Not Applicable",
    value: "NotApplicable",
  },
];

const validationMessages = {
  required: {
    required: "This field is required",
  },
};

function Option(props) {
  const { data, testId } = props;
  return (
    <div data-testid={getTestId(`${testId}-Option`)}>
      <components.Option {...props}>
        <div className="react-select-option">
          <p>{data.label}</p>
          <p>{data.value}</p>
        </div>
      </components.Option>
    </div>
  );
}

function ActionCell({
  deleteMutation, id
}) {
  const actions = [
    {
      title: "Delete",
      onClick: () => { deleteMutation.mutate(id); },
      icon: "trash-icon"
    }
  ];
  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={actions}
        trigger={(
          <div className="action-dropdown-trigger">
            <span className="text">Actions</span>
            <span className="material-icons-outlined">expand_more</span>
          </div>
        )}
        testId="Add-Tax-Action-Button"
      />
    </div>
  );
}

Option.propTypes = {
  data: PropTypes.object.isRequired,
};

const prepareData = (state) => {
  const data = {};
  const {
    code,
    name,
    agency,
    description,
    region,
    company,
    taxType,
    currencyCode,
    purchaseRate,
    purchaseCollected,
    purchaseAccount,
    showPurchaseTaxAmountOnReturnLine,
    netPurchaseAmountOnReturnLine,
    purchaseReclaimable,
    saleRate,
    saleCollected,
    saleAccount,
    showSaleTaxAmountOnReturnLine,
    netSaleAmountOnReturnLine,
    isFrozen
  } = state;

  data.code = code;
  data.name = name;
  data.agency = agency;
  data.description = description;
  data.taxRateId = null;
  data.taxGroupId = null;
  data.isFrozen = isFrozen ?? false;

  if (currencyCode) {
    data.currencyCode = currencyCode.value;
  }

  if (company) {
    data.companyId = company.value;
  }

  if (taxType) {
    data.taxType = taxType.value;
  }

  if (region) {
    data.region = region.value;
  }

  switch (data.taxType) {
    case "VAT":
      data.saleRate = saleRate || null;
      data.saleCollected = saleCollected;
      data.saleAccountId = saleAccount?.value;
      data.showSaleTaxAmountOnReturnLineId = showSaleTaxAmountOnReturnLine?.value;
      data.netSaleAmountOnReturnLine = netSaleAmountOnReturnLine?.value;

      data.purchaseRate = purchaseRate || null;
      data.purchaseCollected = purchaseCollected;
      data.purchaseAccountId = purchaseAccount?.value;
      data.showPurchaseTaxAmountOnReturnLineId = showPurchaseTaxAmountOnReturnLine?.value;
      data.netPurchaseAmountOnReturnLine = netPurchaseAmountOnReturnLine?.value;
      break;

    case "Excise":
      data.saleRate = saleRate || null;
      data.saleCollected = saleCollected;
      data.saleAccountId = saleAccount?.value;
      break;

    case "Custom":
      data.saleRate = saleRate || null;
      data.saleCollected = saleCollected;
      data.saleAccountId = saleAccount?.value;

      data.purchaseRate = purchaseRate || null;
      data.purchaseCollected = purchaseCollected;
      data.purchaseAccountId = purchaseAccount?.value;
      data.purchaseReclaimable = purchaseReclaimable;
      break;

    default:
      break;
  }

  return data;
};

const prepareState = (data) => {
  const {
    taxType,
    purchaseAccount,
    showPurchaseTaxAmountOnReturnLine,
    netPurchaseAmountOnReturnLine,
    saleAccount,
    showSaleTaxAmountOnReturnLine,
    netSaleAmountOnReturnLine,
    region,
  } = data;
  const state = { ...data };

  state.regionId = region;

  state.taxType = {
    label: taxType,
    value: taxType,
  };

  switch (state.taxType.value) {
    case "VAT":
      if (netSaleAmountOnReturnLine) {
        state.netSaleAmountOnReturnLine = {
          label: netSaleAmountOnReturnLine?.name,
          value: netSaleAmountOnReturnLine?.id,
        };
      }

      if (saleAccount) {
        state.saleAccount = {
          ...saleAccount,
          label: saleAccount?.name,
          value: saleAccount?.id,
        };
      }

      if (showSaleTaxAmountOnReturnLine) {
        state.showSaleTaxAmountOnReturnLine = saleReturnLineOptions.find(
          (o) => o.value === showSaleTaxAmountOnReturnLine
        );
      }

      if (purchaseAccount) {
        state.purchaseAccount = {
          label: purchaseAccount?.name,
          value: purchaseAccount?.id,
        };
      }

      if (netPurchaseAmountOnReturnLine) {
        state.netPurchaseAmountOnReturnLine = {
          label: netPurchaseAmountOnReturnLine?.name,
          value: netPurchaseAmountOnReturnLine?.id,
        };
      }

      if (showPurchaseTaxAmountOnReturnLine) {
        state.showPurchaseTaxAmountOnReturnLine = purchaseReturnLineOptions.find(
          (o) => o.value === showPurchaseTaxAmountOnReturnLine
        );
      }
      break;

    case "Excise":
      if (saleAccount) {
        state.saleAccount = {
          label: saleAccount?.name,
          value: saleAccount?.id,
        };
      }
      break;

    case "Custom":
      if (saleAccount) {
        state.saleAccount = {
          label: saleAccount?.name,
          value: saleAccount?.id,
        };
      }
      if (purchaseAccount) {
        state.purchaseAccount = {
          label: purchaseAccount?.name,
          value: purchaseAccount?.id,
        };
      }
      break;

    default:
      break;
  }

  return state;
};

export default function AddTaxCategory() {
  const activeCompany = useSelector(selectActiveCompany);
  const deletePermission = useSelector(selectPermission({ parent: "Model", scope: "TaxRule", action: "Delete" }));

  const [state, dispatch] = useReducer(taxRuleFormReducer, initialState);

  const submitButtonRef = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data, isInitialLoading } = useQuery(["tax-rule", id], () => getTaxRuleById(id), {
    enabled: Boolean(id),
  });

  const { data: locationData } = useQuery(
    [kebabCase(dynamicObjectMap.get("LocationObjectName")), state.regionId],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("LocationObjectName"), state.regionId),
    {
      enabled: Boolean(state.regionId),
    }
  );

  useEffect(() => {
    if (id && data) {
      const stateData = prepareState(data?.data);
      dispatch(setInitialState(stateData));
    }
  }, [data]);

  useEffect(() => {
    if (state.regionId && locationData) {
      const location = {
        label: locationData?.name,
        value: locationData?.id
      };
      dispatch(setFormValue("region", location));
    }
  }, [locationData]);

  useEffect(() => {
    if (!id && activeCompany) {
      const { defaultCurrencyCode } = activeCompany;

      const currencyCode = {
        label: defaultCurrencyCode,
        value: defaultCurrencyCode,
      };

      dispatch(setFormValue("currencyCode", currencyCode));
    }
  }, [id, activeCompany]);

  const saveMutation = useMutation(createTaxRule, {
    onError: () => {
      showToast("Could not create. Try again!", "error");
    },
    onSuccess: () => {
      showToast("Created successfully", "success");

      navigate(-1);
    },
  });

  const updateMutation = useMutation(
    ({ id: recordId, data: recordData }) => updateTaxRule(recordId, recordData),
    {
      onError: () => {
        showToast("Could not update. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Updated successfully", "success");
        navigate(-1);
      },
    }
  );

  const deleteMutation = useMutation(
    deleteTaxRate,
    {
      onError: () => {
        showToast("Could not delete. Try again!", "error");

      },
      onSuccess: () => {
        showToast("Deleted successfully", "success");

        queryClient.invalidateQueries({
          queryKey: ["tax-rate"]
        });
      },
    }
  );

  const handleCancel = () => {
    dispatch(setInitialState(initialState));
    navigate(-1);
  };

  const handleSave = () => {
    submitButtonRef.current.click();
  };

  const handleSubmit = async () => {
    const recordData = prepareData({
      ...state,
      company: {
        label: activeCompany.name,
        value: activeCompany.id,
      },
    });

    if (id) {
      updateMutation.mutate({
        id,
        data: recordData,
      });

      return;
    }

    saveMutation.mutate(recordData);
  };

  if (id && isInitialLoading) {
    return <Loader />;
  }

  const renderPurchaseSection = () => {
    const { taxType } = state;

    if (taxType.value === "Excise") {
      return null;
    }

    return (
      <>
        <div className="col-md-12">
          <div className="hr" />
        </div>
        <div className="col-md-3">
          <FormLeftHeader
            title="Configure Purchase"
            subtitle="Provide Purchase Details"
            icon="scan-icon"
          />
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-12">
              <FormControl>
                <Checkbox
                  label="Is collected on purchase"
                  value={state.purchaseCollected}
                  onChange={(value) => {
                    dispatch(setFormValue("purchaseCollected", value));
                  }}
                  testId="TaxRule-PurchaseCollected-Checkbox"
                />
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl>
                <Label htmlFor="purchaseRate" label="Purchase Rate" />
                <Input
                  name="purchaseRate"
                  type="Number"
                  placeholder="Purchase Rate"
                  value={state.purchaseRate}
                  onChange={(value) => dispatch(setFormValue("purchaseRate", parseInt(value, 10)))}
                  testId="TaxRule-PurchaseRate-Input"
                >
                  <Input.AppendIcon>
                    <span>%</span>
                  </Input.AppendIcon>
                </Input>
              </FormControl>
            </div>
            <div className="col-md-6">
              <FormControl>
                <Label htmlFor="purchaseAccount" label="Purchase A/C" />
                <Select
                  id="purchaseAccount"
                  name="purchaseAccount"
                  queryKey="ledger-account"
                  placeholder="Select purchase account"
                  value={state.purchaseAccount}
                  onChange={(value) => dispatch(setFormValue("purchaseAccount", value))}
                  optionsLoader={() =>
                    getLedgerAccounts().then((res) => {
                      res.data = flattenTree(buildTree(res.data, 1));
                      return res;
                    })}
                  dataAccessKey="data"
                  optionLabelKey="name"
                  optionValueKey="id"
                  hasNestedOptions
                  styles={nestedOptionStyles}
                  isClearable
                  testId="TaxRule-PurchaseAccount-Select"
                />
              </FormControl>
            </div>
            {/* {taxType.value === "VAT" && (
              <>
                <div className="col-md-6">
                  <FormControl>
                    <Label
                      htmlFor="showPurchaseTaxAmountOnReturnLine"
                      label="Show tax amount on return line"
                    />
                    <Select
                      name="showPurchaseTaxAmountOnReturnLine"
                      value={state.showPurchaseTaxAmountOnReturnLine}
                      queryKey="purchase-tax"
                      onChange={(value) =>
                        dispatch(setFormValue("showPurchaseTaxAmountOnReturnLine", value))}
                      optionsLoader={() => getTaxRules()}
                      dataAccessKey="data"
                      optionLabelKey="name"
                      optionValueKey="id"
                      testId="TaxRule-ShowPurchaseTaxAmountOnReturnLine-Select"
                    />
                  </FormControl>
                </div>
                <div className="col-md-6">
                  <FormControl>
                    <Label
                      htmlFor="netPurchaseAmountOnReturnLine"
                      label="Net amount on return line"
                    />
                    <ReactSelect
                      name="netPurchaseAmountOnReturnLine"
                      placeholder="Net amount on return line"
                      value={state.netPurchaseAmountOnReturnLine}
                      onChange={(value) =>
                        dispatch(setFormValue("netPurchaseAmountOnReturnLine", value))}
                      options={purchaseReturnLineOptions}
                      testId="TaxRule-NetPurchaseAmountOnReturnLine-Select"
                    />
                  </FormControl>
                </div>
              </>
            )} */}
            {taxType.value === "Custom" && (
              <div className="col-md-12">
                <FormControl>
                  <Checkbox
                    label="Is purchase tax reclaimable"
                    value={state.purchaseReclaimable}
                    onChange={(value) => {
                      dispatch(setFormValue("purchaseReclaimable", value));
                    }}
                    testId="TaxRule-PurchaseReclaimable-Checkbox"
                  />
                </FormControl>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <BoxedContent className="gl-account-form">
      <Header
        showBreadcrumb
        leftContent={(
          <h1>
            {id ? "Edit" : "Create"}
            {" "}
            Tax
          </h1>
        )}
        // rightContent={id && deletePermission ? <ActionCell deleteMutation={deleteMutation} id={id} /> : null}
      />
      <Form
        onSubmit={handleSubmit}
        key="tax-rule-form"
        className="dynamic-object-form"
        shouldScrollOnError
      >
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="Select Tax Type" icon="check-box-icon" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <ReactSelect
                    name="TaxType"
                    placeholder="Select Tax Type"
                    rules="required"
                    value={state.taxType}
                    onChange={(value) => dispatch(setFormValue("taxType", value))}
                    options={taxTypeOptions}
                    testId="TaxRule-TaxType-Select"
                  />
                </FormControl>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="hr" />
          </div>

          <div className="col-md-3">
            <FormLeftHeader title="Basic Info" subtitle="Provide Name & Details" icon="scan-icon" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="taxCode" label="Tax Code*" />
                  <Input
                    name="taxCode"
                    placeholder="Tax code"
                    value={state.code}
                    onChange={(value) => dispatch(setFormValue("code", value))}
                    rules="required"
                    messages={validationMessages.required}
                    testId="TaxRule-Code-Input"
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="taxName" label="Tax Name*" />
                  <Input
                    name="taxName"
                    placeholder="Tax Name"
                    value={state.name}
                    onChange={(value) => dispatch(setFormValue("name", value))}
                    rules="required"
                    messages={validationMessages.required}
                    testId="TaxRule-Name-Input"
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="agency" label="Tax Agency" />
                  <Input
                    name="agency"
                    placeholder="Tax Agency"
                    value={state.agency}
                    onChange={(value) => dispatch(setFormValue("agency", value))}
                    testId="TaxRule-Agency-Input"
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="region" label="Region" />
                  <Select
                    name="region"
                    value={state.region}
                    queryKey="region"
                    onChange={(value) => dispatch(setFormValue("region", value))}
                    optionsLoader={() => getDynamicObjectRecords("Location")}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    testId="TaxRule-Region-Select"
                  />
                </FormControl>
              </div>
              <div className="col-md-12">
                <FormControl>
                  <Label htmlFor="description" label="Description" />
                  <TextArea
                    name="description"
                    placeholder="Tax description"
                    value={state.description}
                    onChange={(value) => dispatch(setFormValue("description", value))}
                    testId="TaxRule-Description-Textarea"
                  />
                </FormControl>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="hr" />
          </div>

          <div className="col-md-3">
            <FormLeftHeader
              title="Configure Sale"
              subtitle="Provide Sale Details"
              icon="scan-icon"
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12">
                <FormControl>
                  <Checkbox
                    label="Is collected on sale"
                    value={state.saleCollected}
                    onChange={(value) => {
                      dispatch(setFormValue("saleCollected", value));
                    }}
                    testId="TaxRule-SaleCollected-Checkbox"
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="SaleRate" label="Sale Rate" />
                  <Input
                    name="SaleRate"
                    type="Number"
                    placeholder="Sate Rate"
                    value={state.saleRate}
                    onChange={(value) => dispatch(setFormValue("saleRate", parseInt(value, 10)))}
                    testId="TaxRule-SaleRate-Input"
                  >
                    <Input.AppendIcon>
                      <span>%</span>
                    </Input.AppendIcon>
                  </Input>
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="saleAccount" label="Sale A/C" />
                  <Select
                    id="saleAccount"
                    name="saleAccount"
                    queryKey="ledger-account"
                    placeholder="Select sale account"
                    value={state.saleAccount}
                    onChange={(value) => dispatch(setFormValue("saleAccount", value))}
                    optionsLoader={() =>
                      getLedgerAccounts().then((res) => {
                        res.data = flattenTree(buildTree(res.data, 1));
                        return res;
                      })}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    isClearable
                    testId="TaxRule-SaleAccount-Select"
                  />
                </FormControl>
              </div>
              {/* {state.taxType.value === "VAT" && (
                <>
                  <div className="col-md-6">
                    <FormControl>
                      <Label
                        htmlFor="showSaleTaxAmountOnReturnLine"
                        label="Show tax amount on return line"
                      />
                      <Select
                        name="showSaleTaxAmountOnReturnLine"
                        value={state.showSaleTaxAmountOnReturnLine}
                        queryKey="sale-tax"
                        onChange={(value) =>
                          dispatch(setFormValue("showSaleTaxAmountOnReturnLine", value))}
                        optionsLoader={() => getTaxRules()}
                        dataAccessKey="data"
                        optionLabelKey="name"
                        optionValueKey="id"
                        testId="TaxRule-ShowSaleTaxAmountOnReturnLine-Select"
                      />
                    </FormControl>
                  </div>
                  <div className="col-md-6">
                    <FormControl>
                      <Label
                        htmlFor="netSaleAmountOnReturnLine"
                        label="Net amount on return line"
                      />
                      <ReactSelect
                        name="netSaleAmountOnReturnLine"
                        placeholder="Net amount on return line"
                        value={state.netSaleAmountOnReturnLine}
                        onChange={(value) =>
                          dispatch(setFormValue("netSaleAmountOnReturnLine", value))}
                        options={saleReturnLineOptions}
                        testId="TaxRule-NetSaleAmountOnReturnLine-Select"
                      />
                    </FormControl>
                  </div>
                </>
              )} */}
            </div>
          </div>
          {renderPurchaseSection()}
          <div className="col-md-12">
            <div className="hr" />
          </div>
          <div className="col-md-3">
            <FormLeftHeader title="Freeze Tax Rule" subtitle="Tax rule will not show up in options if frozen" icon="disc-icon" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-3">
                <CheckboxWithIcon
                  id="isFrozen"
                  name="isFrozen"
                  label="Is frozen"
                  value={state.isFrozen}
                  onChange={() => dispatch(setFormValue("isFrozen", !state.isFrozen))}
                  testId="GLAccount-IsFrozen-Checkbox"
                />
              </div>
            </div>
          </div>
          <button type="submit" style={{ display: "none" }} ref={submitButtonRef}>
            Save
          </button>
        </div>
      </Form>
      <div className="col-md-12">
        <div className="hr" />
      </div>

      <div className="col">
        <div className="buttons-at-end">
          <Button bordered small onClick={handleCancel} testId="TaxRule-Cancel-Button">
            Cancel
          </Button>
          <Button
            small
            className="save-btn"
            loading={saveMutation.isLoading || updateMutation.isLoading}
            onClick={handleSave}
            testId="TaxRule-Submit-Button"
          >
            Save
          </Button>
        </div>
      </div>
    </BoxedContent>
  );
}
