import { getWorkOrderAgingRows, getWorkOrderAgingColumns } from "./work-order-aging-statement";
import { getWorkOrderAgingReport } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const workOrderAgingFilters = {
  WorkOrderNumber: "*",
  BuildingId: "*",
  UnitId: "*",
  ServiceRequestId: "*",
  CategoryId: "*",
};

const workOrderAgingFilterOptions = [
  { key: "workOrderNumber", apiKey: "WorkOrderNumber", keyType: "string" },
  { key: "buildingId", apiKey: "BuildingId" },
  { key: "unitId", apiKey: "UnitId" },
  { key: "serviceRequestId", apiKey: "ServiceRequestId" },
  { key: "categoryId", apiKey: "CategoryId" },
];

export default function ReportWorkOrderAging({ activeCompany }) {
  return (
    <DynamicReport
      reportName="work-order-aging"
      activeCompany={activeCompany}
      columnsDataProvider={getWorkOrderAgingColumns}
      rowsDataProvider={getWorkOrderAgingRows}
      fetchData={getWorkOrderAgingReport}
      exportUrl="reports/export-query-report/work-order-aging"
      filtersInitialState={workOrderAgingFilters}
      filterOptions={workOrderAgingFilterOptions}
      orderBy="Number"
    />
  );
}
