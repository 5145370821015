import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getRentRollArchiveColumns() {

  const colArr = [
    {
      label: "Company",
      value: true,
      columnId: "company",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Emirates", value: true, columnId: "emirates", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Building",
      value: true,
      columnId: "property",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => (value || "")
    },
    {
      label: "Component", value: true, columnId: "component", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Payment Status", value: true, columnId: "paymentStatus", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Actual Status", value: true, columnId: "actualStatus", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Category", value: true, columnId: "unitCategory", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Number", value: true, columnId: "unitNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Mark", value: true, columnId: "unitMark", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Status", value: true, columnId: "unitStatus", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "CMA Rent Amount", value: true, columnId: "cMARentAmount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Payment Method", value: true, columnId: "paymentMethod", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Type", value: true, columnId: "unitType", width: estimatedColumnWidth("type"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "View", value: true, columnId: "view", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Floor", value: true, columnId: "floorName", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Floor Type", value: true, columnId: "floorType", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Built Up Area", value: true, columnId: "builtUpArea", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Carpet Area", value: true, columnId: "carpetArea", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Rate Per Square Meter", value: true, columnId: "ratePerSquareMeter", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Rate Per Square Feet", value: true, columnId: "ratePerSquareFeet", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Bedroom", value: true, columnId: "bedroom", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Contract Number", value: true, columnId: "contractNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "No. of Installments", value: true, columnId: "numberOfInstallments", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Number", value: true, columnId: "customerNumber", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Name", value: true, columnId: "tenantName", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Type", value: true, columnId: "tenantType", width: estimatedColumnWidth("type"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Contract Type", value: true, columnId: "purpose", width: estimatedColumnWidth("type"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Usage", value: true, columnId: "usage", width: estimatedColumnWidth("type"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Date Start", value: true, columnId: "dateStart", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Date End", value: true, columnId: "dateEnd", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Contract Amount", value: true, columnId: "contractAmount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Annual Rent", value: true, columnId: "annualRent", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Utility Amount", value: true, columnId: "utilityAmount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Unit Total Rent Amount", value: true, columnId: "unitTotalRentAmount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Total Admin Charges", value: true, columnId: "totalAdminCharges", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Total Contract Amount", value: true, columnId: "totalContractAmount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Age Days", value: true, columnId: "ageDays", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Leasing Agent", value: true, columnId: "leasingAgent", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Continuity Mode", value: true, columnId: "purpose", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => (String(value || ""))
    },
    {
      label: "Previous Contract Number", value: true, columnId: "previousContract", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => (String(value || ""))
    },
    {
      label: "Previous Contract Amount", value: true, columnId: "previousContractAmount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Previous End Date", value: true, columnId: "previousEndDate", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => (value ? String(value ? formatDate(new Date(value)) : "") : "")
    },
    {
      label: "Previous Start Date", value: true, columnId: "previousStartDate", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => String(value ? formatDate(new Date(value)) : "")
    },
  ];

  return colArr;
}
