import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getForthcomingVacancyColumns() {

  const colArr = [
    {
      label: "Company",
      value: true,
      columnId: "company",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Property",
      value: true,
      columnId: "property",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Emirates",
      value: true,
      columnId: "emirates",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit Status",
      value: true,
      columnId: "unitStatus",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Notice Date",
      value: true,
      columnId: "tenantNoticeDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))
    },
    {
      label: "Unit Type",
      value: true,
      columnId: "unitType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "View",
      value: true,
      columnId: "view",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit Mark",
      value: true,
      columnId: "unitMark",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Area Per Square Meter",
      value: true,
      columnId: "areaPerSquareMeter",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Area Per Square Feet",
      value: true,
      columnId: "areaPerSquareFeet",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Bedrooms",
      value: true,
      columnId: "bedrooms",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Contract Number",
      value: true,
      columnId: "contractNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "No. of Installments",
      value: true,
      columnId: "numberOfInstallments",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Number",
      value: true,
      columnId: "customerNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Name",
      value: true,
      columnId: "tenantName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Type",
      value: true,
      columnId: "tenantType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Purpose",
      value: true,
      columnId: "purpose",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Usage",
      value: true,
      columnId: "usage",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Start Date",
      value: true,
      columnId: "startDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))
    },
    {
      label: "End Date",
      value: true,
      columnId: "endDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))
    },
    {
      label: "Months",
      value: true,
      columnId: "months",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Contract Amount",
      value: true,
      columnId: "contractAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Payment Method",
      value: true,
      columnId: "paymentMethod",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "CMA Rate",
      value: true,
      columnId: "cMARate",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Rent for 12 Months",
      value: true,
      columnId: "annualyRent",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Utility Amount",
      value: true,
      columnId: "utilityAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Rate Per SQM",
      value: true,
      columnId: "ratePerSquareMeter",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Rate Per SQFT",
      value: true,
      columnId: "ratePerSquareFeet",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Remarks",
      value: true,
      columnId: "remarks",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Continuity Mode",
      value: true,
      columnId: "continuityMode",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Release Date",
      value: true,
      columnId: "releaseDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value !== "0001-01-01" ? formatDate(new Date(value)) : "")
    },
    {
      label: "Release Reason",
      value: true,
      columnId: "releaseReason",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Release Type",
      value: true,
      columnId: "releaseType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Discontinued By",
      value: true,
      columnId: "discontinuedBy",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    }
  ];

  return colArr;
}
