import { actionCreator } from "@/utils/helpers";

export const SET_FORM_VALUE = "SET_FORM_VALUE";
export const SET_STATE = "SET_STATE";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const RESET_FORM_STATE = "RESET_FORM_STATE";
export const ADD_MASTER_DETAIL_FIELD_VALUE = "ADD_MASTER_DETAIL_FIELD_VALUE";
export const REMOVE_RELATIONAL_FIELD_VALUE = "REMOVE_RELATIONAL_FIELD_VALUE";
export const UPDATE_RELATIONAL_FIELD_VALUE = "UPDATE_RELATIONAL_FIELD_VALUE";
export const ADD_TABLE_FIELD_VALUE = "ADD_TABLE_FIELD_VALUE";

export const initialState = {};

export const dynamicFormReducer = (state, action) => {
  switch (action.type) {
    case SET_FORM_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case SET_INITIAL_STATE:
      return {
        ...state,
        ...action.payload,
        isDynamicPopupModalOpen: false,
        isDynamicPopupModalHasError: "",
      };

    case SET_STATE:
      return {
        ...state,
        ...action.payload,
      };

    case RESET_FORM_STATE: {
      return {};
    }

    case ADD_MASTER_DETAIL_FIELD_VALUE: {
      const masterDetailFieldValues = state[action.payload.key] ?? [];
      const resetData = {};

      Object.keys(action.payload.data)
        .filter((key) => key !== action.payload.key)
        .forEach((k) => {
          // TODO: handle for lookup and select
          resetData[k] = "";
        });

      return {
        ...state,
        [`${action.payload.key}Select`]: null,
        ...resetData,
        [action.payload.key]: [...masterDetailFieldValues, action.payload.data],
      };
    }

    case ADD_TABLE_FIELD_VALUE: {
      const tableFieldValues = state[action.payload.key] ?? [];
      const resetData = {};

      Object.keys(action.payload.data)
        .filter((key) => key !== action.payload.key)
        .forEach((k) => {
          // TODO: handle for lookup and select
          resetData[k] = "";
        });

      return {
        ...state,
        ...resetData,
        [action.payload.key]: [...tableFieldValues, action.payload.data],
      };
    }

    case UPDATE_RELATIONAL_FIELD_VALUE: {
      const masterDetailValues = [...state[action.payload.key]];

      masterDetailValues[action.payload.index] = {
        ...masterDetailValues[action.payload.index],
        ...action.payload.data,
      };

      return {
        ...state,
        [action.payload.key]: masterDetailValues,
      };
    }

    case REMOVE_RELATIONAL_FIELD_VALUE:
      return {
        ...state,
        [action.payload.key]: state[action.payload.key].filter(
          (_, index) => index !== action.payload.index
        ),
      };

    default:
      throw new Error(`${action.type} not found`);
  }
};

export const setFormValue = (key, value) => actionCreator(SET_FORM_VALUE, { key, value });

export const setInitialState = (value) => actionCreator(SET_INITIAL_STATE, value);

export const setState = (value) => actionCreator(SET_STATE, value);

export const resetFormState = () => actionCreator(RESET_FORM_STATE);

export const addMasterDetailFieldValue = (key, data) =>
  actionCreator(ADD_MASTER_DETAIL_FIELD_VALUE, { key, data });

export const addTableFieldValue = (key, data) =>
  actionCreator(ADD_TABLE_FIELD_VALUE, { key, data });

export const updateRelationalFieldValue = (key, index, data) =>
  actionCreator(UPDATE_RELATIONAL_FIELD_VALUE, { key, index, data });

export const removeRelationalFieldValue = (key, index) =>
  actionCreator(REMOVE_RELATIONAL_FIELD_VALUE, { key, index });
