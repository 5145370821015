import PropTypes from "prop-types";

import { Button } from "@hydra/atom/components";
import { SvgIcon } from "@/components/common";

export default function AttachedFile({ title, date, src }) {

  return (
    <div className="file-container">
      <div className="circle">
        <SvgIcon icon="document-icon" />
      </div>
      <div className="description">
        <p className="title">{title}</p>
        <p className="sub-title">{date}</p>
      </div>
      <Button
        small
        onClick={() => {
          window.open(src, "_blank");
        }}
        className="btn-with-icon"
      >
        Download
      </Button>
    </div>
  );
}

AttachedFile.prototype = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  src: PropTypes.string,
};
