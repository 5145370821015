import {
  useCallback, useState, useEffect, useMemo
} from "react";
import PropTypes from "prop-types";

import { FormControl, ReactSelect } from "@hydra/atom/components";

import FieldLabel from "@/components/dynamic/fields/FieldLabel";
import LookupField from "./LookupField";
import { getTestId } from "@/utils/helpers";

const getTypeOptions = (lookups) =>
  lookups.map((lookup) => ({
    ...lookup,
    label: lookup.label,
    value: lookup.lookupObjectName,
  }));

function PolymorphicField({
  name,
  field,
  value,
  onChange,
  error,
  placeholder,
  allowMultiple,
  showLabel,
  disabled,
  isChildField,
  relationalFieldState,
  formState,
  isEditing,
  parentId,
  testId,
  ...rest
}) {
  const [selectedType, setSelectedType] = useState(null);
  const isTypeSelected = Boolean(selectedType);
  const { advanceSearch } = field;

  const typeOptions = useMemo(() => getTypeOptions(field.lookups), [field]);

  const resetSelectedType = useCallback(() => {
    setSelectedType(null);
    onChange(null);
  }, []);

  const handleChange = useCallback((option) => {
    if (selectedType.allowMultiple) {
      if (option.length) {
        option[0].lookupObjectName = selectedType.value;
      }
    } else if (option) {
      option.lookupObjectName = selectedType.value;
    }

    onChange(option);
  }, [selectedType]);

  useEffect(() => {
    if (!selectedType && value) {
      let selectedLookup = {};
      if (field.allowMultiple && allowMultiple) {
        selectedLookup = typeOptions.find((o) => o.value === value[0].lookupObjectName);
      } else {
        const { lookupObjectName } = value;
        selectedLookup = typeOptions.find((o) => o.value === lookupObjectName);
      }
      if (selectedLookup) {
        setSelectedType(selectedLookup);
      }
    }
  }, [value]);

  return (
    <>
      <FormControl>
        {showLabel ? (
          <FieldLabel
            label={field.label}
            labelFor={field.name}
            helperText={field.helperText}
            isRequired={field.required}
          />
        ) : null}
        {!isTypeSelected ? (
          <ReactSelect
            id={`${name}-select`}
            name={field.name}
            options={typeOptions}
            value={selectedType}
            onChange={setSelectedType}
            placeholder={placeholder}
            isClearable
            backSpaceRemovesValue
            isDisabled={disabled}
            testId={getTestId(`${testId}-Lat`)}
            {...rest}
          />
        ) : (
          <LookupField
            key={field.name}
            field={{
              ...field,
              ...selectedType,
              selectedType,
              resetSelectedType,
              advanceSearch
            }}
            value={value}
            onChange={handleChange}
            showCreateNew={false}
            showLabel={false}
            isChildField={isChildField}
            relationalFieldState={relationalFieldState}
            isDisabled={disabled}
            parentId={parentId}
            isEditing={isEditing}
            formState={formState}
            defaultMenuIsOpen={!isTypeSelected || !value}
            allowMultiple={field.allowMultiple && allowMultiple}
            testId={getTestId(`${testId}-Lng`)}
            {...rest}
          />
        )}
      </FormControl>
      {typeof error === "string" && <span className="error-text">{error}</span>}
    </>
  );
}

PolymorphicField.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.oneOf([null])]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  allowMultiple: PropTypes.bool,
  showLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  isChildField: PropTypes.bool,
  relationalFieldState: PropTypes.array,
  formState: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  isEditing: PropTypes.bool,
  parentId: PropTypes.string,
  testId: PropTypes.string,
};

PolymorphicField.defaultProps = {
  value: null,
  onChange: () => {},
  name: "tuli-polymorphic-field",
  placeholder: "Select",
  error: false,
  allowMultiple: true,
  showLabel: true,
  disabled: false,
  isChildField: false,
  relationalFieldState: [],
  isEditing: false,
  parentId: "",
  formState: null,
  testId: "",
};

PolymorphicField.Type = "HydraValidatableComponent";

export default PolymorphicField;
