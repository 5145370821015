import { useState } from "react";
import { Accordion as HydraAccordion } from "@hydra/atom/components";

export default function Accordion({
  title, count, children, open, ...rest
}) {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <HydraAccordion
      manualControl
      isOpen={isOpen}
      onClick={() => setIsOpen(!isOpen)}
      {...rest}
    >
      <HydraAccordion.Activator>
        <span className={`symbol ${isOpen ? "" : "mode-vertical"}`}>-</span>
        <span className="title">{title}</span>
        {count ? <span className="count">{count}</span> : null}
      </HydraAccordion.Activator>
      <HydraAccordion.Body>
        { children }
      </HydraAccordion.Body>
    </HydraAccordion>
  );
}

Accordion.defaultProps = {
  open: true,
};
