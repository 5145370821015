import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Button, FormControl, Loader, Form, Radio
} from "@hydra/atom/components";
import PropTypes from "prop-types";

import { startOfDay } from "date-fns";
import { isNull, kebabCase } from "lodash";
import {
  Modal,
  FormLeftHeader,
  MultiSelectUser,
  Header,
  CustomizedDatePicker,
  CustomizedTimeRangePicker,
  HeaderLeftContent
} from "@/components/common";
import { ModalFooter } from "@/components/modals";
import { getDynamicObjectByName } from "@/api/dynamic/dynamicObjectSchemaApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

const validationMessages = {
  required: {
    required: "This field is required",
  },
};

export default function ExtendTimeModal({
  onSubmit, isOpen, setOpenModal, state
}) {
  const [details, setDetails] = useState();
  const [dateError, setDateError] = useState(false);
  const [extend, setExtend] = useState(false);

  useEffect(() => {
    setDetails({
      timeRange: state.timeRange,
      endDate: state.dateRange?.[1],
      technician: [],
    });
  }, [state.timeRange, state.dateRange?.[1]]);

  const { data: objectSchema, isLoading: isLoadingSchema } = useQuery(
    ["dynamic-object-schema", kebabCase(dynamicObjectMap.get("WorkOrderJobObjectName"))],
    () => getDynamicObjectByName(dynamicObjectMap.get("WorkOrderJobObjectName"))
  );

  const validateDate = (value) => {
    setDateError(false);
    if (isNull(value)) {
      return;
    }
    if (startOfDay(state.dateRange?.[0]) <= startOfDay(value)) {
      setDetails({ ...details, endDate: value });
      return;
    }
    setDateError(true);
  };

  const handleSubmit = () => {
    setOpenModal(false);
    onSubmit(details);
    setDetails({
      endDate: state.dateRange?.[1],
      timeRange: state.timeRange,
      technician: "",
    });
  };

  const handlePrevious = () => {
    setOpenModal(false);
    setDetails({
      endDate: state.dateRange?.[1],
      timeRange: state.timeRange,
      technician: "",
    });
  };
  const onTeamChange = (value) => {
    setDetails({
      ...details,
      technician: value.filter(
        (obj1) => !state.technician.some((obj2) => obj1.value === obj2.value)
      ),
    });
  };

  if (!isOpen) return null;

  return (
    <Modal
      rootClassName="center-vertically"
      className="extend-time-modal"
      isOpen={isOpen}
      onClose={handlePrevious}
    >
      {isLoadingSchema ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-md-12 core-modal-content">
            <Form
              onSubmit={handleSubmit}
              className="extend-modal-form"
              shouldScrollOnError
            >
              <Header leftContent={<HeaderLeftContent title="Extend Time" />} />
              <div>
                <div className="row">
                  <div className="col-md-3 block-gap">
                    <FormLeftHeader title="Extend by" subtitle="Select by dates or hours" />
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4 schedule-radio block-gap">
                        <Radio
                          label="By Days"
                          itemValue
                          type="radio"
                          value={!extend}
                          onChange={() => setExtend(!extend)}
                        >
                          <Radio.CheckIcon>
                            <span className="material-icons">circle</span>
                          </Radio.CheckIcon>
                        </Radio>
                      </div>
                      <div className="col-md-4 schedule-radio block-gap">
                        <Radio
                          label="By Hours"
                          itemValue
                          type="radio"
                          value={extend}
                          onChange={() => setExtend(!extend)}
                        >
                          <Radio.CheckIcon>
                            <span className="material-icons">circle</span>
                          </Radio.CheckIcon>
                        </Radio>
                      </div>
                    </div>
                  </div>
                  <hr className="full-hr" />
                </div>
                {!extend && (
                  <>
                    <div className="row">
                      <div className="col-md-3 block-gap">
                        <FormLeftHeader title="New End Date" subtitle="Update your Dates" />
                      </div>
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-4">
                            <CustomizedDatePicker
                              name="end-date"
                              value={details.endDate}
                              onChange={(value) => validateDate(value)}
                            />
                          </div>
                          {dateError && <span className="error-text">Please select a valid date</span>}
                        </div>
                      </div>
                    </div>
                    <hr className="full-hr" />
                  </>
                )}
                {extend && (
                  <>
                    <div className="row">
                      <div className="col-md-3 block-gap">
                        <FormLeftHeader title="New Time" subtitle="Update your Time" />
                      </div>
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-4">
                            <FormControl>
                              <CustomizedTimeRangePicker
                                name="timeRange"
                                value={details.timeRange}
                                onChange={(value) => setDetails({ ...details, timeRange: value })}
                                required
                                disabled={state.isReadOnly}
                              />
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="full-hr" />
                  </>
                )}
                <div className="row">
                  <div className="col-md-3 block-gap">
                    <FormLeftHeader title="Team" subtitle="Update your Team" />
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-6">
                        <MultiSelectUser
                          field={objectSchema?.document.find((f) => f.name === "TechnicianSkill")}
                          id="TechnicianSkill"
                          name="TechnicianSkill"
                          value={[...state.technician, ...details.technician]}
                          onChange={(value) => onTeamChange(value)}
                          placeholder="Select Team"
                          messages={validationMessages.required}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="full-hr" />
              <ModalFooter bordered={false}>
                <Button small bordered onClick={handlePrevious}>
                  Cancel
                </Button>
                <Button type="submit" small>
                  Confirm
                </Button>
              </ModalFooter>
            </Form>
          </div>
        </div>
      )}
    </Modal>
  );
}

ExtendTimeModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  state: PropTypes.object.isRequired,
  setOpenModal: PropTypes.func.isRequired,
};
