import Slider from "react-slick";

export default function SimpleSlider({ items }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <Slider {...settings}>
      { items.map((item, i) => <div key={i}>{item}</div>)}
    </Slider>
  );
}
