import PropTypes from "prop-types";
import { Checkbox } from "@hydra/atom/components";

function CheckboxWithImage({
  name, title, subtitle, img, onChange, value, disabled
}) {
  return (
    <label htmlFor={name} className={`select-step-card-checkbox ${disabled ? "disabled" : ""}`}>
      <Checkbox
        id={name}
        name={name}
        value={value === name}
        onChange={onChange}
        disabled={disabled}
      />
      <div className="checkbox-label-container">
        <img src={img} alt="scheduled maintenance icon" />
        <div className="checkbox-label">
          <h4>{title}</h4>
          <p>{subtitle}</p>
        </div>
      </div>
    </label>
  );
}

function SelectStep({ value, onChange, options }) {
  return (
    <div className="select-step">
      <div className="select-cards-wrapper">
        {
          options.map((option, index) => (
            <CheckboxWithImage
              key={`checkbox-${index}`}
              name={option.value}
              title={option.title}
              subtitle={option.subtitle}
              img={option.image}
              onChange={() => onChange(option.value)}
              value={value}
              disabled={option.disabled}
            />
          ))
        }
      </div>
    </div>
  );
}

SelectStep.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectStep;
