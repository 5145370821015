import { getColumnWidth } from "../helpers";

const COL_WIDTH = 135;

function filterColumns(colArr, data) {
  return colArr.filter((col) => {
    const dataItem = data?.find((item) => item.label === col.columnId);
    return !dataItem || dataItem.value;
  });
}

export default function getFixedAssetListingColumns(windowWidth, columnData) {
  const colArr = [
    { columnId: "Transaction", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) },
    { columnId: "FAM No.", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) },
    {
      columnId: "Purchase Date",
      width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth)
    },
    { columnId: "Quantity on Hand", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) },
    { columnId: "Purchase Description", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) },
    { columnId: "Account", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) },
    { columnId: "Cost", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) },
    { columnId: "Accumulated Depreciation", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) }
  ];

  const filteredColArr = filterColumns(colArr, columnData);
  return filteredColArr;
}
