import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import LookupField from "./LookupField";

function DependentField({
  name,
  field,
  state,
  value,
  onChange,
  showLabel,
  placeholder,
  ...rest
}) {
  const parentFieldName = useMemo(() => field.parent, []);

  const getApiFilters = useCallback(() => {
    if (state[parentFieldName]) {
      return {
        [parentFieldName]: state[parentFieldName]?.value
      };
    }

    return {};
  }, [state[parentFieldName]]);

  return (
    <LookupField
      name={name}
      field={field}
      value={value}
      onChange={onChange}
      showLabel={showLabel}
      placeholder={placeholder}
      apiFilters={getApiFilters()}
      {...rest}
    />
  );
}

DependentField.propTypes = {
  field: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([null])]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showLabel: PropTypes.bool,
};

DependentField.defaultProps = {
  value: null,
  onChange: () => {},
  name: "tuli-dependent-field",
  placeholder: "",
  error: false,
  showLabel: true,
};

DependentField.Type = "HydraValidatableComponent";

export default DependentField;
