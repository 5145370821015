import PropTypes from "prop-types";
import { SvgIcon } from "@/components/common";

function HeaderLeftContent({ title, subtitle, icon }) {
  return (
    <div className="header-left-content">
      {icon && (
        <div className="icon-container">
          <SvgIcon icon={icon} />
        </div>
      )}
      <div>
        <h1>{title}</h1>
        {subtitle && <p className="sub-title">{subtitle}</p>}
      </div>
    </div>
  );
}

HeaderLeftContent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
};

HeaderLeftContent.defaultProps = {
  subtitle: null,
  icon: null,
};

export default HeaderLeftContent;
