import { useEffect, useState } from "react";

export default function useIsMobile(size = 576) {
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return (() => {
      window.removeEventListener("resize", updateDimension);
    });
  }, []);

  return screenSize.width <= size;
}
