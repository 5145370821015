import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, kebabCase } from "lodash";
import { toast } from "react-hot-toast";
import { formatCurrency, handleChequeNumberChange, formatApiPayloadDate } from "@/utils/helpers";
import { BoxedContent } from "@/components/common";
import { AlertModal } from "@/components/modals";
import { useModal } from "@/hooks";
import { DynamicFormContainer } from "@/components/dynamic";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getChequePaymentsTableColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import showToast from "@/utils/toast/helpers";

const formatPayments = (data) => {
  const items = [];
  for (let i = 0; i < data.length; i += 1) {
    const item = data[i];
    const { contract } = item;

    const itemData = {
      key: item.id,
      cheque: {
        label: item.number,
        value: item.id
      },
      recalled: false,
      chequeNo: handleChequeNumberChange(item?.chequeNo),
      paymentDate: new Date(item.dueDate),
      chequeDate: new Date(item.dueDate),
      totalAmount: formatCurrency(item.amount || 0),
      amount: item.amount,
      description: item.description,
      status: item.status,
      isSelected: true,
      memo: item.description,
    };

    if (!isEmpty(contract)) {
      const { objectName, building, unit } = contract;

      itemData.contract = {
        label: contract.number,
        value: contract.id,
        lookupObjectName: objectName
      };

      itemData.tenant = {
        label: contract.tenant.name,
        value: contract.tenant.id,
      };

      if (unit && !isEmpty(unit)) {
        itemData.unit = contract.unit.map((u) => ({
          label: u.name,
          value: u.id,
        }));
      }

      if (building && !isEmpty(building)) {
        itemData.building = {
          label: building.name,
          value: building.id,
        };
      }

      items.push(itemData);
    }
  }

  const filteredItems = items.filter((item) => item.chequeNo !== "" && item.chequeNo !== null);
  filteredItems.sort((a, b) => a.chequeNo - b.chequeNo);
  items.forEach((item) => {
    if (item.chequeNo === "" || item.chequeNo === null) {
      filteredItems.push(item);
    }
  });
  return {
    paymentDetail: filteredItems,
  };
};

function RecallChequeForm() {
  const ref = useRef(null);
  const [paymentDetailTableData, setPaymentDetailTableData] = useState([]);
  const [state, setState] = useState({});
  const { isOpen, closeModal, openModal } = useModal(false);

  const { data: pdcData } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("PDCObjectName")),
      state.fromDate,
      state.toDate,
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("PDCObjectName"), {
        sortBy: "CreatedAt",
        sortType: "DESC",
        "dueDate[gte]": formatApiPayloadDate(new Date(state.fromDate)),
        "dueDate[lte]": formatApiPayloadDate(new Date(state.toDate)),
        "status[in]": "Collected,Warehoused",
        queryMode: "Deep",
      }),
    {
      enabled: Boolean(state.fromDate) && Boolean(state.toDate),
    }
  );

  const setPaymentDetail = () => {
    const { fromDate, toDate } = state;

    if (!fromDate || !toDate) return;

    const toastId = toast.loading("Loading payments...");
    const { paymentDetail } = formatPayments(pdcData.data);
    if (!paymentDetail?.length) {
      toast.dismiss(toastId);
      showToast("No cheques found for the given date range!", "info");
    } else {
      toast.dismiss(toastId);
      showToast("Cheques loaded!", "success");
    }

    setPaymentDetailTableData(paymentDetail);
    openModal();
  };

  useEffect(() => {
    if (pdcData && pdcData.data) {
      setPaymentDetail();
      if (!pdcData.data.length) {
        showToast("No cheques found for the given date range!", "info");
      }
    }
  }, [pdcData]);

  const onStateChange = (key, value) => {
    if (key === "fromDate" || key === "toDate") {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const handleConfirm = () => {
    if (paymentDetailTableData?.length) {
      const selectedPayments = paymentDetailTableData.filter((q) => q.isSelected).map((payment) => {
        payment.recalled = true;

        return payment;
      });

      if (selectedPayments) {
        ref.current.setFormState({
          paymentDetail: selectedPayments,
        });
      }
    }

    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Payments"
        subtitle="Selected date range has following cheques"
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        size="large"
      >
        {paymentDetailTableData?.length ? (
          <TableWithCheckbox
            data={paymentDetailTableData}
            searchKey={["chequeNo"]}
            columns={getChequePaymentsTableColumns()}
            setData={setPaymentDetailTableData}
            selectAll
            allowMultiple
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("RecallChequeObjectName")}
        showHeader
        onStateChange={onStateChange}
      />
    </BoxedContent>
  );
}

export default RecallChequeForm;
