import PropTypes from "prop-types";

import { Label } from "@hydra/atom/components";
import { Tooltip } from "@/components/common";

function FieldLabel({
  labelFor, label, helperText, isRequired
}) {
  return (
    <Label
      labelFor={labelFor}
      label={(
        <div className="label-with-tooltip">
          <span>
            {label}
            {isRequired ? "*" : null}
          </span>
          {helperText ? <Tooltip>{helperText}</Tooltip> : null}
        </div>
      )}
    />
  );
}

FieldLabel.propTypes = {
  labelFor: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  isRequired: PropTypes.bool,
};

FieldLabel.defaultProps = {
  labelFor: "",
  label: "",
  helperText: "",
  isRequired: false,
};

export default FieldLabel;
