import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getOutOfScopeColumns() {

  const colArr = [
    {
      label: "Tax Payer TRN",
      value: true,
      columnId: "taxPayerTRN",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Company Name",
      value: true,
      columnId: "companyName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tax Invoice/Tax Credit Note No.",
      value: true,
      columnId: "invoiceNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tax Invoice/Tax Credit Note Date",
      value: true,
      columnId: "invoiceDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Reporting Period From",
      value: true,
      columnId: "periodFrom",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Reporting Period To",
      value: true,
      columnId: "periodTo",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Tax Invoice/Tax Credit Note Amount AED (before VAT)",
      value: true,
      columnId: "amount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "VAT Amount",
      value: true,
      columnId: "vatAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Customer Name",
      value: true,
      columnId: "customerName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Customer TRN",
      value: true,
      columnId: "customerTRN",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Clear Description of the Supply",
      value: true,
      columnId: "description",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Reason of Out-of-Scope Sales Treatment",
      value: true,
      columnId: "reason",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    }
  ];

  return colArr;
}
