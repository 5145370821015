import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import DynamicObjectOverview from "@/pages/dynamic/list-views/DynamicObjectOverview";

function AssetRegisterOverview() {

  return (
    <DynamicObjectOverview
      objectName={dynamicObjectMap.get("ItemObjectName")}
      filters={{
        itemType: "Asset"
      }}
      showFilters
    />
  );
}

export default AssetRegisterOverview;
