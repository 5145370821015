import PropTypes from "prop-types";
import DateTimePicker from "react-datetime-picker";
import { getTestId } from "@/utils/helpers";

export default function CustomizedDateTimePicker({
  value,
  onChange,
  testId,
  ...rest
}) {
  return (
    <DateTimePicker
      className="custom-date-time-picker"
      clockIcon={null}
      clearIcon={null}
      onChange={onChange}
      value={value}
      data-testid={getTestId(testId)}
      {...rest}
    />
  );
}

CustomizedDateTimePicker.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

CustomizedDateTimePicker.defaultProps = {
  testId: ""
};
