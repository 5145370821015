import { useState, useEffect, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import qs from "qs";
import { kebabCase } from "lodash";
import { selectActiveApp } from "@/store/appSlice";

export default function useTaskRedirect() {
  const [taskId, setTaskId] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const activeApp = useSelector(selectActiveApp);

  const taskIdParam = searchParams.get("taskId");

  useEffect(() => {
    if (taskIdParam) {
      setTaskId(taskIdParam);
    }

    return () => {
      setTaskId("");
    };
  }, [taskIdParam]);

  const redirect = useCallback((path, params = {}) => {
    if (taskId) {
      navigate(`/${kebabCase(activeApp?.value)}/tasks/${taskIdParam}?${qs.stringify(params)}`);
      return;
    }

    navigate(path);
  }, [taskId]);

  return { redirect, taskId };
}
