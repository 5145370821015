/* eslint-disable no-plusplus */
import {
  nonEditable,
  textCell,
  monthHeaderCell,
  bottomLine,
  showZero,
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT } from "../constants";

export const CASH_IN_CHANGE_ROW_ID = "cashInChange";

function getHeaderRow() {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: [
      nonEditable(monthHeaderCell("Transaction"), "trial-balance-header", "Transaction"),
      nonEditable(monthHeaderCell("FAM No."), "trial-balance-header ", "type"),
      nonEditable(monthHeaderCell("Purchase Date"), "trial-balance-header justify-content-start", "date"),
      nonEditable(monthHeaderCell("Quantity on Hand"), "trial-balance-header justify-content-start", "number"),
      nonEditable(monthHeaderCell("Purchase Description"), "trial-balance-header justify-content-start", "name"),
      nonEditable(monthHeaderCell("Account"), "trial-balance-header justify-content-start", "building-name"),
      nonEditable(monthHeaderCell("Cost"), "trial-balance-header justify-content-start", "unit"),
      nonEditable(monthHeaderCell("Accumulated Depreciation"), "trial-balance-header justify-content-start", "account"),
    ]
  };
}

function getGroupRows(
  title,
  groups = []
) {
  const arrayToMap = new Array(12).fill(null);
  if (groups?.length) {
    return [
      ...groups.map((item) => ({
        rowId: item.journalEntryId,
        height: ROW_HEIGHT,
        cells: [
          nonEditable(bottomLine(textCell({ text: "13256" })), "trial-balance-row justify-content-start", "Transaction"),
          nonEditable(showZero(textCell({ text: "435" })), "trial-balance-row justify-content-start", "type"),
          nonEditable(showZero(textCell({ text: "" })), "trial-balance-row justify-content-start", "date"),
          nonEditable(showZero(textCell({ text: "19" })), "trial-balance-row justify-content-start", "number"),
          nonEditable(showZero(textCell({ text: "Equipment" })), "trial-balance-row justify-content-start", "name"),
          nonEditable(showZero(textCell({ text: "Construction Equipment" })), "trial-balance-row justify-content-start", "building-name"),
          nonEditable(showZero(textCell({ text: "15,300" })), "trial-balance-row justify-content-start", "unit"),
          nonEditable(showZero(textCell({ text: "5932" })), "trial-balance-row justify-content-start", "account"),
        ]
      })),
      {
        rowId: `${title}Footer`,
        height: ROW_HEIGHT,
        cells: [
          nonEditable(monthHeaderCell("Total"), "trial-balance-header font-bold", "Transaction"),
          nonEditable(monthHeaderCell(""), "trial-balance-header ", "type"),
          nonEditable(monthHeaderCell(""), "trial-balance-header ", "date"),
          nonEditable(monthHeaderCell(""), "trial-balance-header ", "number"),
          nonEditable(monthHeaderCell(""), "trial-balance-header ", "name"),
          nonEditable(monthHeaderCell(""), "trial-balance-header ", "building-name"),
          nonEditable(monthHeaderCell(""), "trial-balance-header ", "unit"),
          nonEditable(monthHeaderCell(""), "trial-balance-header ", "account"),
        ]
      }
    ];
  }
  return [
    ...arrayToMap.map((item, index) => ({
      rowId: index,
      height: ROW_HEIGHT,
      cells: [
        nonEditable(bottomLine(textCell({ text: "13256" })), "trial-balance-row justify-content-start", "Transaction"),
        nonEditable(showZero(textCell({ text: "435" })), "trial-balance-row justify-content-start", "type"),
        nonEditable(showZero(textCell({ text: "" })), "trial-balance-row justify-content-start", "date"),
        nonEditable(showZero(textCell({ text: "19" })), "trial-balance-row justify-content-start", "number"),
        nonEditable(showZero(textCell({ text: "Equipment" })), "trial-balance-row justify-content-start", "name"),
        nonEditable(showZero(textCell({ text: "Construction Equipment" })), "trial-balance-row justify-content-start", "building-name"),
        nonEditable(showZero(textCell({ text: "15,300" })), "trial-balance-row justify-content-start", "unit"),
        nonEditable(showZero(textCell({ text: "5932" })), "trial-balance-row justify-content-start", "account"),
      ]
    }))
  ];
}

export function getFixedAssetListingRows({
  // rowsData
}) {
  return [
    getHeaderRow(),
    ...getGroupRows(
      "Unrealized Revenue",
    ),
  ];
}
