import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export function getSupplierPaymentColumns() {
  const colArr = [
    {
      label: "Payment Number",
      value: true,
      columnId: "paymentNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Payment Date",
      value: true,
      columnId: "paymentDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value !== "0001-01-01" && value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Transaction Date",
      value: true,
      columnId: "transactionDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value !== "0001-01-01" && value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Bank Account",
      value: true,
      columnId: "bankAccount",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Supplier Name",
      value: true,
      columnId: "supplierName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Expense/AMC/Purchase",
      value: true,
      columnId: "expense/AMC/Purchase",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Invoice Number",
      value: true,
      columnId: "invoiceNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Payment Type",
      value: true,
      columnId: "paymentType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Payment Method",
      value: true,
      columnId: "paymentMethod",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Cheque No. / Bank Transfer",
      value: true,
      columnId: "chequeNumber/BankTransfer",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Cheque Date",
      value: true,
      columnId: "chequeDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value !== "0001-01-01" && value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Payment Amount",
      value: true,
      columnId: "paymentAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Outstanding Amount",
      value: true,
      columnId: "outstandingAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Status",
      value: true,
      columnId: "status",
      width: estimatedColumnWidth("status"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Cancellation Date",
      value: true,
      columnId: "cancellationDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value !== "0001-01-01" && value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Created At",
      value: true,
      columnId: "createdAt",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value !== "0001-01-01" && value ? formatDate(new Date(value)) : "")
    }
  ];

  return colArr;
}
