import request from "@/utils/api/helpers";

const baseUrl = "/identity/permissions";

// eslint-disable-next-line import/prefer-default-export
export function getAllPermissions() {
  return request({
    url: `${baseUrl}`
  });
}
