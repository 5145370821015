/* eslint-disable no-plusplus */
import { range } from "lodash";
import {
  nonEditable,
  textCell,
  headerCell,
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT } from "../constants";

export const CASH_IN_CHANGE_ROW_ID = "cashInChange";

function getHeaderRow(columns) {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: columns.map((column) => {
      if (column.columnId.includes("Actual")) {
        return headerCell(column.label, "trial-balance-header font-bold", 2);
      }
      return headerCell(column.label, "trial-balance-header font-bold", 1);
    }),
  };
}

function formatCamelCaseString(str) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") //
    .replace(/^./, (str) => str.toUpperCase());
}

function getGroupRows(groups, sums, columns) {
  if (groups?.length) {
    return [
      {
        rowId: "title-row",
        height: ROW_HEIGHT,
        cells: [
          headerCell("", "total-row"),
          headerCell("", "total-row"),
          ...Array.from({ length: columns.length - 2 }, (_, i) =>
            headerCell(i % 2 === 0 ? "Actual" : "Budget", "total-row font-bold")
          ),
        ],
      },
      ...groups.map((item, index) => ({
        rowId: item.id,
        height: ROW_HEIGHT,
        cells: columns.map((column) => {
          const textCellArgs = {};

          if (column.formatter) {
            textCellArgs.text = column.formatter(item[column.columnId], index);
          } else {
            textCellArgs.text = item[column.columnId] ?? "";
          }
          return nonEditable(textCell(textCellArgs), column.className || "");
        }),
      })),
      {
        rowId: "total-row",
        height: ROW_HEIGHT,
        cells: [
          headerCell("", ""),
          headerCell("", ""),
          ...columns.slice(2).map((column, i) =>
            headerCell(
              i % 2 === 0 ? `Total ${formatCamelCaseString(column.columnId)}` : `Total ${formatCamelCaseString(column.columnId)}`,
              i % 2 === 0 ? "total-row-credit" : "total-row-debit"
            )
          ),
        ],
      },
      {
        rowId: "total-row",
        height: ROW_HEIGHT,
        cells: columns.map((column, index) => {
          const textCellArgs = {};

          if (column.formatter) {
            textCellArgs.text = column.formatter(sums[column.columnId], index);
          } else {
            textCellArgs.text = sums[column.columnId] ?? "";
          }
          return nonEditable(textCell(textCellArgs), column.className || "font-bold");
        }),
      },
    ];
  }
  return [];
}

export function getOpexRows({
  data,
  columns
}) {

  if (!data) {
    return [];
  }

  return [getHeaderRow(columns),
    ...getGroupRows(data.data, data.sums, columns)
  ];
}
