import { getExemptSuplliesPurchaseColumns, getExemptSuplliesPurchaseRows } from "./exempt-supplies-purchase-statement";
import { getExemptSuppliesPurchase } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportExemptSuppliesPurchase({ activeCompany }) {
  return (
    <DynamicReport
      reportName="exempt-purchase"
      activeCompany={activeCompany}
      columnsDataProvider={getExemptSuplliesPurchaseColumns}
      rowsDataProvider={getExemptSuplliesPurchaseRows}
      fetchData={getExemptSuppliesPurchase}
      exportUrl="reports/export-query-report/exempt-purchase"
      filtersInitialState={{}}
      orderBy="InvoiceNumber"
    />
  );
}
