import PropTypes from "prop-types";
import { SvgIcon } from "@/components/common";

function GoBackButton({
  className, label, href, renderAsExternalLink
}) {
  const makeClasses = () => (className !== "" ? `${className} go-back-button` : "go-back-button");

  if (renderAsExternalLink) {
    return (
      <a href={href} className={makeClasses()}>
        <span className="material-icons">chevron_left</span>
        <span className="text">{label}</span>
      </a>
    );
  }

  return (
    <div className={makeClasses()}>
      <div className="avatar">
        <SvgIcon icon="users-icon" />
      </div>
      <div>
        <h1 className="title">{label}</h1>
        <span className="sub-title">Lorem Ipsum</span>
      </div>
    </div>
  );
}

GoBackButton.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
  renderAsExternalLink: PropTypes.bool,
  className: PropTypes.string,
};

GoBackButton.defaultProps = {
  label: "",
  href: "",
  renderAsExternalLink: false,
  className: "",
};

export default GoBackButton;
