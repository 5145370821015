import axios from "axios";
import qs from "qs";
import appSettings from "@/settings";

export default function getPlaceDetails(address) {
  const params = qs.stringify({
    address,
    sensor: true,
    key: appSettings.googleMapsApiKey
  });

  return axios({
    url: `https://maps.googleapis.com/maps/api/geocode/json?${params}`,
    method: "GET",
  });
}
