import { estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getBuildingCostColumns() {

  const colArr = [
    {
      label: "Component",
      value: true,
      columnId: "number",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Component Name",
      value: true,
      columnId: "name",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Totals",
      value: true,
      columnId: "totals",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Civil Defence Certificate",
      value: true,
      columnId: "civilDefenceCertificate",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Facilities Management Services",
      value: true,
      columnId: "facilitiesManagementServices",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Facility Management Charges",
      value: true,
      columnId: "facilityManagementCharges",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Input VAT Non-recoverable - Residential & Admin",
      value: true,
      columnId: "inputVatNonRecoverableResidentialAdmin",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Insurance - Direct Building Cost",
      value: true,
      columnId: "insuranceDirectBuildingCost",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Land Rent - Direct Building Cost",
      value: true,
      columnId: "landRentDirectBuildingCost",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Property Management Fee",
      value: true,
      columnId: "propertyManagementFee",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - AIR CONDITIONING",
      value: true,
      columnId: "rmAirConditioning",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - Cardle",
      value: true,
      columnId: "rmCardle",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - ELECTRICALS",
      value: true,
      columnId: "rmElectricals",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - ELEVATORS",
      value: true,
      columnId: "rmElevators",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - FIRE ALARM SYSTEM",
      value: true,
      columnId: "rmFireAlarmSystem",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - Fire Fighting System",
      value: true,
      columnId: "rmFireFightingSystem",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - GARDEN MAINTENANCE",
      value: true,
      columnId: "rmGardenMaintenance",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - Generator & Transformer Maint",
      value: true,
      columnId: "rmGeneratorTransformerMaint",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - GENERAL CLEANING CHARGES",
      value: true,
      columnId: "rmGeneralCleaningCharges",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - GLASS CLEANING CHARGES",
      value: true,
      columnId: "rmGlassCleaningCharges",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - OTHERS",
      value: true,
      columnId: "rmOthers",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - PAINTING",
      value: true,
      columnId: "rmPainting",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - PEST CONTROL CHARGES",
      value: true,
      columnId: "rmPestControlCharges",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - SERVICE FEES",
      value: true,
      columnId: "rmServiceFees",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - SEWERAGE TANK EXP",
      value: true,
      columnId: "rmSewerageTankExp",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - SWIMMING POOL",
      value: true,
      columnId: "rmSwimmingPool",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - UPS Maint",
      value: true,
      columnId: "rmUpsMaint",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "R & M - Water Treatment",
      value: true,
      columnId: "rmWaterTreatment",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Rent Expenses (Reversal)- IFRS 16 (External)",
      value: true,
      columnId: "rentExpensesReversalIfrs16External",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Security",
      value: true,
      columnId: "security",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Security- Direct Building Cost",
      value: true,
      columnId: "securityDirectBuildingCost",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Water & Electricity- Direct Building Cost",
      value: true,
      columnId: "waterElectricityDirectBuildingCost",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    }
  ];

  return colArr;
}
