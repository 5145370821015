import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

function ServiceRequestForm() {
  return (
    <BoxedContent>
      <DynamicFormContainer
        objectName={dynamicObjectMap.get("ServiceRequestObjectName")}
        showHeader
        showLinkedViews
        readOnlyFields={["status"]}
      />
    </BoxedContent>
  );
}

export default ServiceRequestForm;
