import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import {
  addDays,
  addYears,
  differenceInDays,
  differenceInMonths,
  getOverlappingDaysInIntervals,
  isBefore,
  isEqual,
  isFirstDayOfMonth,
  isLastDayOfMonth,
} from "date-fns";
import { kebabCase } from "lodash";
import { toast } from "react-hot-toast";
import { selectActiveCompany } from "@/store/appSlice";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import { useModal } from "@/hooks";
import { getAssetDetailTable } from "@/components/finance/account-receivables/tableWithCheckboxData";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import showToast from "@/utils/toast/helpers";
import { formatDecimalValues } from "@/utils/helpers";

function calculateDepreciationBasedOnDuration(item, fromDate, toDate) {
  const {
    usefulLife,
    serviceStartDate,
    purchasePrice,
    salvageValue,
    depreciationMethod,
    depreciationDate,
  } = item;

  if (depreciationMethod !== "StraightLine") {
    return {
      depreciationForPeriod: 0,
      depreciationRate: 0,
    };
  }

  let depreciationFromDate = new Date(serviceStartDate);

  if (depreciationDate) {
    depreciationFromDate = addDays(new Date(depreciationDate), 1);
  }

  const isFromDateFirstDayOfMonth = isFirstDayOfMonth(fromDate);
  const isToDateLastDayOfMonth = isLastDayOfMonth(toDate);
  const isDepreciationFromDateFirstDayOfMonth = isFirstDayOfMonth(new Date(depreciationFromDate));

  const annualDepreciation = (purchasePrice - salvageValue) / usefulLife;
  const depreciationRate = formatDecimalValues(Number((annualDepreciation / purchasePrice) * 100));
  const retirementDate = addYears(new Date(serviceStartDate), usefulLife);

  const numberOfDays = differenceInDays(new Date(toDate), new Date(fromDate));
  const overlappingDays = getOverlappingDaysInIntervals(
    {
      start: new Date(depreciationFromDate),
      end: new Date(retirementDate),
    },
    {
      start: fromDate,
      end: toDate,
    }
  );

  const areDaysEqual = overlappingDays === numberOfDays;

  if (areDaysEqual && overlappingDays === 365) {
    return {
      depreciationForPeriod: annualDepreciation,
      depreciationRate,
    };
  }

  if (areDaysEqual && isFromDateFirstDayOfMonth && isToDateLastDayOfMonth) {
    const diffInMonths = differenceInMonths(toDate, fromDate) + 1;

    const depreciationForPeriod = formatDecimalValues(
      Number((diffInMonths / 12) * annualDepreciation));

    return {
      depreciationForPeriod,
      depreciationRate,
    };
  }

  if (!areDaysEqual && isDepreciationFromDateFirstDayOfMonth && isToDateLastDayOfMonth) {
    const diffInMonths = differenceInMonths(toDate, new Date(depreciationFromDate)) + 1;

    const depreciationForPeriod = formatDecimalValues(
      Number((diffInMonths / 12) * annualDepreciation));

    return {
      depreciationForPeriod,
      depreciationRate,
    };
  }

  const depreciationForPeriod = formatDecimalValues(
    Number((overlappingDays / 365) * annualDepreciation));

  return {
    depreciationForPeriod,
    depreciationRate,
  };
}

const filterAsset = (asset, fromDate, toDate) => {
  const { serviceStartDate, depreciationDate, usefulLife } = asset;
  const retirementDate = addYears(new Date(serviceStartDate), usefulLife);
  const isRetirementDateBeforeFromDate = isBefore(retirementDate, new Date(fromDate));
  const isRetirementDateEqualToFromDate = isEqual(retirementDate, new Date(fromDate));

  if (isRetirementDateBeforeFromDate || isRetirementDateEqualToFromDate) {
    return false;
  }

  const isToDateBeforeServiceStartDate = isBefore(new Date(toDate), new Date(serviceStartDate));
  const isToDateEqualToServiceStartDate = isEqual(new Date(toDate), new Date(serviceStartDate));
  if (isToDateBeforeServiceStartDate || isToDateEqualToServiceStartDate) {
    return false;
  }

  let isToDateBeforeDepreciationDate = false;
  let isToDateEqualToDepreciationDate = false;
  if (depreciationDate) {
    isToDateBeforeDepreciationDate = isBefore(new Date(toDate), new Date(depreciationDate));
    isToDateEqualToDepreciationDate = isEqual(new Date(toDate), new Date(depreciationDate));
  }

  if (isToDateBeforeDepreciationDate || isToDateEqualToDepreciationDate) {
    return false;
  }

  return true;
};

const formatAssets = (data, fromDate, toDate, transactionDate, company) => {
  const items = data
    ?.filter((i) => filterAsset(i, fromDate, toDate))
    ?.map((item) => {
      const numberOfDays = differenceInDays(new Date(toDate), new Date(fromDate));

      const { depreciationForPeriod, depreciationRate } = calculateDepreciationBasedOnDuration(
        item,
        fromDate,
        toDate
      );

      return {
        company: {
          label: company.name,
          value: company.id
        },
        skip: false,
        asset: {
          label: item.itemName,
          value: item.id,
        },
        key: item.id,
        isSelected: true,
        category: item.category,
        purchaseDate: new Date(item.purchaseDate),
        rate: depreciationRate,
        cost: item.purchasePrice,
        method: item.depreciationMethod,
        totalDepreciated: item.accumulatedDepreciation || 0,
        bookValue: item.bookValue,
        depreciationForPeriod,
        description: `Depreciation for ${item.number}`,
        numberOfDays,
        depreciatedTill: new Date(toDate),
        transactionDate: new Date(transactionDate),
      };
    });

  const totalOpenAssetsAmount = items
    ?.filter((i) => !i.skip)
    ?.reduce((prevValue, { bookValue }) => prevValue + bookValue, 0);

  return {
    assetDetail: items,
    totalOpenAssetsAmount,
  };
};

function DepreciationForm() {
  const activeCompany = useSelector(selectActiveCompany);
  const ref = useRef(null);
  const [state, setState] = useState({});
  const [assetDetailData, setAssetDetailData] = useState({});
  const { isOpen, closeModal, openModal } = useModal(false);
  const [toastId, setToastId] = useState("");

  const { data: assetData, isLoading } = useQuery(
    [kebabCase(dynamicObjectMap.get("ItemObjectName")), "Active"],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("ItemObjectName"), {
        sortBy: "CreatedAt",
        sortType: "DESC",
        status: "Active",
        itemType: "Asset",
        isDepreciated: true,
      })
  );

  const setAssetDetail = () => {
    const formState = ref.current.getState();
    const { fromDate, toDate, transactionDate } = formState;
    const numberOfDays = differenceInDays(new Date(toDate), new Date(fromDate));
    const { assetDetail } = formatAssets(
      assetData?.data, fromDate, toDate, transactionDate, activeCompany);

    if (!assetDetail?.length) {
      toast.dismiss(toastId);
      showToast("No assets found for the given date range!", "info");
    } else {
      toast.dismiss(toastId);
      showToast("Assets loaded!", "success");
    }
    ref.current.setFormState({
      numberOfDays,
    });
    setAssetDetailData(assetDetail);
    openModal();
  };

  const setTotalOpenAssetsAmount = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { assetDetail } = formState;

    const totalOpenAssetsAmount = assetDetail
      .filter((i) => !i.skip)
      .reduce((prevValue, { bookValue }) => prevValue + bookValue, 0);

    ref.current.setFormState({
      totalOpenAssetsAmount,
    });
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "fromDate":
      case "toDate":
      case "transactionDate": {
        setState((prevState) => ({
          ...prevState,
          [key]: value,
        }));
        break;
      }

      case "assetDetail":
        setTotalOpenAssetsAmount(key, value);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (!state?.fromDate || !state?.toDate) return;
    if (isLoading) {
      const tId = toast.loading("Loading assets...");
      setToastId(tId);
    }
    if (assetData && assetData.data) {
      setAssetDetail();
    }
  }, [state?.toDate, state?.fromDate, assetData?.data]);

  const handleConfirm = () => {
    if (assetDetailData?.length) {
      const selectedAssets = assetDetailData.filter((q) => q.isSelected);

      if (selectedAssets) {
        const totalOpenAssetsAmount = selectedAssets
          .filter((i) => !i.skip)
          .reduce((prevValue, { bookValue }) => prevValue + bookValue, 0);

        ref.current.setFormState({
          totalOpenAssetsAmount,
          assetDetail: selectedAssets,
        });
      }
    }

    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Assets"
        subtitle="Selected date range has following active assets"
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        size="large"
      >
        {assetDetailData?.length ? (
          <TableWithCheckbox
            data={assetDetailData}
            searchKey={["asset.label"]}
            columns={getAssetDetailTable()}
            setData={setAssetDetailData}
            selectAll
            allowMultiple
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("DepreciationObjectName")}
        showHeader
        onStateChange={onStateChange}
      />
    </BoxedContent>
  );
}

export default DepreciationForm;
