import { range } from "lodash";
import {
  nonEditable,
  textCell,
  headerCell
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT } from "../constants";
import { formatCurrency } from "@/utils/helpers";

function getHeaderRow(columns) {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: columns.map((column) => headerCell(column.label)),
  };
}

function getGroupRows(title, groups, totalDebit, totalCredit, columns) {
  if (groups?.length) {
    return [
      ...groups.map((item, index) => ({
        rowId: item.id,
        height: ROW_HEIGHT,
        cells: columns.map((column) => {
          const textCellArgs = {};

          if (column.formatter) {
            textCellArgs.text = column.formatter(item[column.columnId], index);
          } else {
            textCellArgs.text = item[column.columnId] ?? "";
          }

          return nonEditable(textCell(textCellArgs), column.className || "");
        }),
      })),
      {
        rowId: `${title}-Footer`,
        height: ROW_HEIGHT,
        cells: [
          ...range(0, columns.length - 3).map(() => headerCell("", "total-row")),
          headerCell(
            "Total", "total-row-debit"),
          headerCell(formatCurrency(totalDebit || 0), "total-row-credit"),
          headerCell(formatCurrency(totalCredit || 0), "total-row-credit"),
        ],
      },
    ];
  }
  return [];
}

export function getBankAccountStatementRows({
  data,
  columns
}) {
  let grandTotalCredit = 0;
  let grandTotalDebit = 0;

  if (!data) {
    return [];
  }

  grandTotalCredit = data.data.reduce((acc, obj) => acc + +(obj.credit || 0), 0);
  grandTotalDebit = data.data.reduce((acc, obj) => acc + +(obj.debit || 0), 0);
  return [getHeaderRow(columns), ...getGroupRows("Total", data.data, grandTotalCredit, grandTotalDebit, columns)];
}
