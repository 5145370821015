import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getOutputVATColumns() {

  const colArr = [
    {
      label: "Company Name",
      value: true,
      columnId: "companyName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "VAT Code",
      value: true,
      columnId: "vATCode",
      width: estimatedColumnWidth("code"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "VAT Description",
      value: true,
      columnId: "vATDescription",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Service Value",
      value: true,
      columnId: "serviceValue",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "VAT Value",
      value: true,
      columnId: "vATValue",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Record Type",
      value: true,
      columnId: "recordType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Component Location",
      value: true,
      columnId: "componentLocation",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Component Number",
      value: true,
      columnId: "componentNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Component Name",
      value: true,
      columnId: "componentName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Customer Number",
      value: true,
      columnId: "customerNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Customer Name",
      value: true,
      columnId: "customerName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "TRN Number",
      value: true,
      columnId: "tRNNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Description",
      value: true,
      columnId: "description",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Invoice Number",
      value: true,
      columnId: "invoiceNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Transaction Date",
      value: true,
      columnId: "transactionDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Invoice Current Status",
      value: true,
      columnId: "invoiceCurrentStatus",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Invoice Status Date",
      value: true,
      columnId: "invoiceStatusDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Invoice Date GL",
      value: true,
      columnId: "invoiceDateGL",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Invoice Due Date",
      value: true,
      columnId: "invoiceDueDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Collection Date",
      value: true,
      columnId: "collectionDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Invoice Cancelled Date GL",
      value: true,
      columnId: "invoiceCancelledDateGL",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value ? formatDate(new Date(value)) : "")
    }
  ];

  return colArr;
}
