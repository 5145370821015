import { useMemo } from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";
import Skeleton from "react-loading-skeleton";

export default function DynamicObjectTable({ data, tableColumns, isLoading }) {
  const tableData = useMemo(() => data, [data]);
  const columns = useMemo(() => (isLoading ?
    tableColumns.map((column) => ({
      ...column,
      Cell: Skeleton,
    })) :
    tableColumns), [isLoading, tableColumns]);

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } =
    useTable({
      columns,
      data: tableData,
    });

  return (
    <div className="table-wrapper">
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="table-header-row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className={`table-header-cell ${column.collapse ? "collapse" : ""}`}
                  {...column.getHeaderProps()}
                >
                  <div className="table-header-cell-content">
                    <span>{column.render("Header")}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr className="table-row" {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    className={`table-body-cell ${cell.column.collapse ? "collapse" : ""}`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

DynamicObjectTable.propTypes = {
  data: PropTypes.array.isRequired,
  tableColumns: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

DynamicObjectTable.defaultProps = {
  isLoading: false
};
