import {
  useRef, useState, useEffect, useMemo
} from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, kebabCase } from "lodash";
import { Button } from "@hydra/atom/components";
import { useParams, useSearchParams } from "react-router-dom";
import { formatDate } from "@/utils/helpers";
import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import {
  getDynamicObjectRecords,
  getDynamicObjectRecordById,
} from "@/api/dynamic/dynamicObjectNameApi";
import { useModal } from "@/hooks";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getGeneralInvoiceTableColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";
import { getLedgerAccountById, getLedgerAccounts } from "@/api/finance/ledgerAccountApi";
import useTaskRedirect from "@/hooks/useTaskRedirect";

const formatReservation = (reservation) => {
  const data = {
    reservation: {
      label: reservation?.number,
      value: reservation?.id,
    },
    tenant: {
      ...reservation?.lead,
      value: reservation?.lead?.id,
      label: reservation?.lead?.name,
      lookupObjectName: reservation?.lead.objectName,
    },
    paymentDetail: [],
  };

  const paymentDetailData = {
    account: {
      label: reservation.paymentAccount.name,
      value: reservation.paymentAccount.id,
    },
    totalAmount: reservation.total,
    paymentType: {
      label: "Reservation Deposit",
      value: "ReservationDeposit",
    },
  };

  data.paymentDetail.push(paymentDetailData);

  return data;
};

const formatDisposal = (disposal) => {
  const data = {
    disposal: {
      label: disposal?.number,
      value: disposal?.id,
    },
    tenant: {
      ...disposal?.customer,
      value: disposal?.customer?.id,
      label: disposal?.customer?.name,
      lookupObjectName: dynamicObjectMap.get("CustomerObjectName"),
    },
    paymentDetail: [],
  };

  const paymentDetailData = {
    account: null,
    totalAmount: disposal.totalAmount,
    paymentType: {
      label: "Fixed Asset Sale",
      value: "FixedAssetSale",
    },
    description: `Payment received for sale of ${disposal.asset.itemName} (${disposal.asset.number})`,
  };

  const { bankAccount } = disposal;

  if (bankAccount) {
    const { account: ledgerAccount } = bankAccount;

    paymentDetailData.account = {
      label: ledgerAccount.name,
      value: ledgerAccount.id,
    };

    data.paymentMethod = {
      label: "Wire Transfer",
      value: "WireTransfer"
    };
  }

  data.paymentDetail.push(paymentDetailData);

  return data;
};

const formatGeneralInvoice = (invoice, account = null) => {
  const {
    id, number, date, tenant, openBalance, company
  } = invoice;

  const data = {
    key: id,
    generalInvoice: {
      label: number,
      value: id,
    },
    tenant: {
      label: tenant.name,
      value: tenant?.id,
      lookupObjectName: tenant?.objectName,
    },
    invoiceDate: formatDate(new Date(date)),
    paymentDetail: [
      {
        account,
        paymentType: {
          label: "General Invoice",
          value: "GeneralInvoice",
        },
        company: {
          label: company?.name,
          value: company?.id,
        },
        totalAmount: openBalance,
        description: `Receive payment for General Invoice ${number}`,
      },
    ],
    isSelected: false,
  };

  data.total = openBalance;

  return data;
};

const formatContractTermination = async (contractTermination) => {
  const {
    status, totalRefundAmount, contract, id, number, tenant
  } =
    contractTermination;
  const data = {
    paymentMethod: {
      label: "Wire Transfer",
      value: "WireTransfer"
    }
  };

  if (status === "Closed" || totalRefundAmount >= 0) {
    return data;
  }

  const outstandingAmount = Math.abs(totalRefundAmount);

  const { building } = contract;
  let account = null;
  let bankAccount = null;

  if (!isEmpty(building.bankAccount)) {
    bankAccount = {
      label: building.bankAccount.name,
      value: building.bankAccount.id,
    };

    const buildingAccount = await getLedgerAccountById(building.bankAccount.account);
    if (buildingAccount.success) {
      account = {
        label: buildingAccount.data.name,
        value: buildingAccount.data.id,
      };
    }
  }

  if (totalRefundAmount < 0) {
    const paymentDetail = [
      {
        account,
        totalAmount: outstandingAmount,
        description: `Remaining amount for ${contract.number}`,
        paymentType: {
          label: "Outstanding Amount",
          value: "OutstandingAmount",
        },
      },
    ];

    data.bankAccount = bankAccount;
    data.tenant = {
      lookupObjectName: dynamicObjectMap.get("TenantObjectName"),
      label: tenant.name,
      value: tenant.id,
    };

    data.contract = {
      label: contract.number,
      value: contract.id,
    };

    data.paymentDetail = paymentDetail;
    data.contractTermination = {
      label: number,
      value: id,
    };
  }

  return data;
};

function HeaderRightContent({ openModal, showButton, ...rest }) {
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {showButton && (
        <Button small bordered onClick={openModal}>
          Select general invoices
        </Button>
      )}
    </defaultComponents.HeaderRightContent>
  );
}

function GeneralReceiptForm() {
  const ref = useRef(null);
  const { id } = useParams();
  const [initialState, setInitialState] = useState({});
  const [state, setState] = useState({});
  const [generalInvoicesTableData, setGeneralInvoicesTableData] = useState([]);
  const { isOpen, closeModal, openModal } = useModal(false);
  const [searchParams] = useSearchParams();
  const isEditing = Boolean(id);
  const generalInvoice = searchParams.get("generalInvoice");
  const contractTermination = searchParams.get("contractTermination");
  const reservationId = searchParams.get("reservation");
  const disposal = searchParams.get("disposal");

  const { redirect } = useTaskRedirect();
  const { data: ledgerAccountsData } = useQuery(["ledger-account"], () => getLedgerAccounts());

  const pdcOnHandAccount = useMemo(() => {
    const account = ledgerAccountsData?.data.filter((item) =>
      item.name.includes("Post Dated Cheques-On Hand")
    );
    return account?.length > 0 ?
      {
        label: account[0].name,
        value: account[0].id,
      } :
      null;
  }, [ledgerAccountsData]);

  const cashCollectionAccount = useMemo(() => {
    const account = ledgerAccountsData?.data.filter((item) =>
      item.name.includes("Cash Collection - Till")
    );
    return account?.length > 0 ?
      {
        label: account[0].name,
        value: account[0].id,
      } :
      null;
  }, [ledgerAccountsData]);

  const creditCardCollectionAccount = useMemo(() => {
    const account = ledgerAccountsData?.data.filter((item) =>
      item.name.includes("Credit Card Collection - Till")
    );
    return account?.length > 0 ?
      {
        label: account[0].name,
        value: account[0].id,
      } :
      null;
  }, [ledgerAccountsData]);

  const onlinePaymentCollectionClearingAccount = useMemo(() => {
    const account = ledgerAccountsData?.data.filter((item) =>
      item.name.includes("Online Payment Collection Clearing Account")
    );
    return account?.length > 0 ?
      {
        label: account[0].name,
        value: account[0].id,
      } :
      null;
  }, [ledgerAccountsData]);

  const getPaymentMethodAccount = (paymentMethod, bankAccount) => {
    let account = null;

    if (paymentMethod) {
      switch (paymentMethod?.value) {
        case "Cheque":
          account = pdcOnHandAccount;
          break;

        case "Cash":
          account = cashCollectionAccount;
          break;

        case "CreditCards":
          account = creditCardCollectionAccount;
          break;

        case "OnlinePayment":
          account = onlinePaymentCollectionClearingAccount;
          break;

        case "WireTransfer":
          if (bankAccount) {
            const { account: glAccount } = bankAccount;

            account = {
              label: glAccount.name,
              value: glAccount.id,
            };
          }
          break;

        default:
          break;
      }
    }

    return account;
  };

  const { data: generalInvoiceData } = useQuery(
    [kebabCase(dynamicObjectMap.get("GeneralInvoiceObjectName")), generalInvoice],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("GeneralInvoiceObjectName")),
        generalInvoice
      ),
    {
      enabled: Boolean(generalInvoice),
    }
  );

  const { data: disposalInvoicesData } = useQuery(
    [kebabCase(dynamicObjectMap.get("GeneralInvoiceObjectName")), disposal, contractTermination],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("GeneralInvoiceObjectName")), {
        takePage: 1,
        limitPage: 1,
        sortBy: "CreatedAt",
        sortType: "DESC",
        disposal,
        contractTermination,
      }),
    {
      enabled: Boolean(disposal) || Boolean(contractTermination),
    }
  );

  const { data: disposalData } = useQuery(
    [kebabCase(dynamicObjectMap.get("DisposalObjectName")), disposal],
    () =>
      getDynamicObjectRecordById(kebabCase(dynamicObjectMap.get("DisposalObjectName")), disposal),
    {
      enabled: Boolean(disposal),
    }
  );

  const { data: contractTerminationData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ContractTerminationObjectName")), contractTermination],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("ContractTerminationObjectName")),
        contractTermination
      ),
    {
      enabled: Boolean(contractTermination),
    }
  );

  const { data: generalInvoicesData } = useQuery(
    [kebabCase(dynamicObjectMap.get("GeneralInvoiceObjectName")), state?.tenant?.value],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("GeneralInvoiceObjectName"), {
        tenant: state?.tenant?.value,
        takePage: 1,
        limitPage: 100,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
      }),
    {
      enabled: Boolean(state?.tenant?.value) && Boolean(!generalInvoice),
    }
  );

  const { data: reservationData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ReservationObjectName")), reservationId],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("ReservationObjectName"), reservationId),
    {
      enabled: Boolean(reservationId) && !isEditing,
    }
  );

  useEffect(() => {
    (async () => {
      if (contractTerminationData) {
        const formattedContractTermination = await formatContractTermination(
          contractTerminationData
        );
        setInitialState((prevState) => ({
          ...prevState,
          ...formattedContractTermination,
        }));
      }
    })();
  }, [contractTerminationData]);

  useEffect(() => {
    if (generalInvoiceData) {
      const { paymentMethod, bankAccount } = ref.current.getState();
      const account = getPaymentMethodAccount(paymentMethod, bankAccount);
      const formattedInvoice = formatGeneralInvoice(generalInvoiceData, account);
      setInitialState(formattedInvoice);
    }
  }, [generalInvoiceData]);

  useEffect(() => {
    if (disposalInvoicesData && disposalInvoicesData.data.length) {
      const selectedInvoice = disposalInvoicesData.data[0];
      setInitialState((prevState) => ({
        ...prevState,
        generalInvoice: {
          label: selectedInvoice.number,
          value: selectedInvoice.id,
        },
      }));
    }
  }, [disposalInvoicesData]);

  useEffect(() => {
    if (disposalData) {
      const formattedDisposal = formatDisposal(disposalData);
      setInitialState((prevState) => ({
        ...prevState,
        ...formattedDisposal,
      }));
    }
  }, [disposalData]);

  useEffect(() => {
    if (generalInvoicesData && generalInvoicesData?.data && state?.tenant) {
      const formattedGeneralInvoices = generalInvoicesData?.data
        .filter((g) => g?.openBalance > 0)
        .map((gInvoice) => formatGeneralInvoice(gInvoice));
      if (generalInvoicesData?.data?.length) {
        setGeneralInvoicesTableData(formattedGeneralInvoices);
      } else {
        setGeneralInvoicesTableData([]);
      }
      openModal();
    }
  }, [generalInvoicesData, state?.tenant?.value]);

  useEffect(() => {
    if (reservationData) {
      const formattedReservation = formatReservation(reservationData);
      setInitialState(formattedReservation);
    }
  }, [reservationData]);

  const onPaymentMethodChange = (key, value) => {
    const { paymentDetailTable, paymentDetail, bankAccount } = ref.current.getState();
    const account = getPaymentMethodAccount(value, bankAccount);

    if (paymentDetailTable) {
      ref.current.setFormState({
        paymentDetailTable: { ...paymentDetailTable, account },
      });
    }

    if (paymentDetail && paymentDetail.length > 0) {
      ref.current.setFormState({
        paymentDetail: paymentDetail.map((item) => ({
          ...item,
          account,
        })),
      });
    }
  };

  const setPaymentDetail = (selectedInvoice) => {
    const data = {
      generalInvoice: selectedInvoice.generalInvoice,
      paymentDetail: [],
    };
    const { paymentMethod, bankAccount } = ref.current.getState();
    const account = getPaymentMethodAccount(paymentMethod, bankAccount);
    const { paymentDetail } = selectedInvoice;
    data.paymentDetail = paymentDetail.map((p) => ({
      ...p,
      account
    }));

    ref.current.setFormState(data);
  };

  const onBankAccountChange = (key, value) => {
    const { paymentDetailTable, paymentDetail } = ref.current.getState();
    let account = null;

    if (value) {
      const { account: glAccount } = value;
      account = {
        label: glAccount.name,
        value: glAccount.id,
      };
    }

    if (paymentDetailTable) {
      ref.current.setFormState({
        paymentDetailTable: { ...paymentDetailTable, account },
      });
    }

    if (paymentDetail && paymentDetail.length > 0) {
      ref.current.setFormState({
        paymentDetail: paymentDetail.map((item) => ({ ...item, account })),
      });
    }
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "tenant":
        setState({
          ...state,
          [key]: value,
        });
        break;

      case "paymentMethod":
        onPaymentMethodChange(key, value);
        break;

      case "bankAccount":
        onBankAccountChange(key, value);
        break;

      default:
        break;
    }
  };

  const handleConfirm = () => {
    if (generalInvoicesTableData.length) {
      const selectedGeneralInvoice = generalInvoicesTableData.find((r) => r.isSelected);
      setPaymentDetail(selectedGeneralInvoice);
    }

    closeModal();
  };

  const handleSuccess = (recordId) => {
    redirect(-1, {
      recordId,
      success: true,
    });
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Tenant General Invoice"
        subtitle="Selected tenant has following general invoices"
        isOpen={isOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
        size="large"
      >
        {generalInvoicesTableData.length ? (
          <TableWithCheckbox
            data={generalInvoicesTableData}
            setData={setGeneralInvoicesTableData}
            columns={getGeneralInvoiceTableColumns()}
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        initialData={initialState}
        objectName={dynamicObjectMap.get("GeneralReceiptObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        onSuccess={handleSuccess}
        navigate={false}
        components={{
          HeaderRightContent: (props) =>
            HeaderRightContent({
              openModal,
              showButton: Boolean(generalInvoicesTableData.length),
              ...props,
            }),
        }}
      />
    </BoxedContent>
  );
}

export default GeneralReceiptForm;
