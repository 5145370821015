import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { Loader, Input, Button } from "@hydra/atom/components";

import { BoxedContent, Header, FormLeftHeader, Avatar, CustomSwitch } from "@/components/common";
import { getDynamicObjectByNameWithCamelizedFieldNames } from "@/api/dynamic/dynamicObjectSchemaApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { uploadAttachmentWithDynamicObjectRecord } from "@/api/dynamic/dynamicObjectNameApi";
import { selectIsLoggedIn } from "@/store/userSlice";
import { renderField } from "@/utils/dynamic/helpers";
import { useUser } from "@/hooks";
import { TabWithBadge } from "@/components/property";
import { askPermission, subscribeUserToPush } from "@/utils/service-workers/helpers";
import {
  initIDB,
  addDataToIDB,
  deleteDataFromIDB,
  getDataByKeyFromIDB,
} from "@/utils/indexed-db/helpers";
import { getUserData } from "@/api/user/authApi";
import showToast from "@/utils/toast/helpers";

const tabContentData = [
  { title: "Profile Settings", subtitle: "You can see your profile settings here" },
  { title: "Notification Settings", subtitle: "You can see your notification settings here" },
];

function ProfileSettings() {
  const { user, userType } = useUser();
  const { userId } = user;
  const [activeTab, setStatusTab] = useState("Profile");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    personalEmail: "",
    profilePicture: null,
    notifications: false,
  });
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const { data: objectSchema, isInitialLoading: isLoadingSchema } = useQuery(
    ["dynamic-object-camelized-schema", dynamicObjectMap.get("EmployeeObjectName")],
    () => getDynamicObjectByNameWithCamelizedFieldNames(dynamicObjectMap.get("EmployeeObjectName")),
    {
      enabled: Boolean(dynamicObjectMap.get("EmployeeObjectName")),
    }
  );

  const { data: userData, isInitialLoading: isLoadingUser } = useQuery(
    ["profileData", userId],
    () => getUserData(userType, userId),
    {
      enabled: isLoggedIn,
    }
  );

  useEffect(() => {
    async function setData() {
      if (!userData) {
        return;
      }
      const userTempData = userData?.data?.[0];
      const userEmail = userTempData.personalEmail ?? userTempData.email;
      await initIDB();
      const notificationSettings = await getDataByKeyFromIDB("pushNotification", userEmail);

      const userProfile = {
        id: userTempData?.id,
        firstName: userTempData?.firstName,
        lastName: userTempData?.lastName,
        profilePicture: isEmpty(userTempData?.profilePicture) ? null : userTempData?.profilePicture,
        role: user?.roles?.join(","),
        personalEmail: userEmail,
        notifications: false,
      };

      if (notificationSettings && notificationSettings.showNotifications) {
        userProfile.notifications = notificationSettings.showNotifications;
      }

      setProfileData(userProfile);
    }

    setData();
  }, [userData]);

  const onSubmit = async () => {
    try {
      await uploadAttachmentWithDynamicObjectRecord({
        objectName: dynamicObjectMap.get("EmployeeObjectName"),
        attachmentFieldName: "profilePicture",
        files: profileData.profilePicture,
        recordId: profileData.id,
      });
      queryClient.invalidateQueries({ queryKey: ["user-data"] });
      showToast("Updated successfully", "success");
    } catch (error) {
      showToast("Could not update user profile. Try again!", "error");
    }
  };

  const tabs = [{ label: "Profile" }, { label: "Notification" }];

  const handleStatusTabChange = (index) => {
    setStatusTab(tabs[index].label);
  };

  const leftContent = (title, subtitle) => (
    <div className="container-header-left-content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );

  const handleCancel = () => {
    navigate(-1);
  };

  const handlePushNotifications = async () => {
    const newValue = !profileData.notifications;
    const userTempData = userData?.data?.[0];
    const userEmail = userTempData.personalEmail ?? userTempData.email;
    const indexDBPayload = {
      email: userEmail,
      showNotifications: newValue,
    };

    if (newValue) {
      const permission = await askPermission();

      if (permission === "granted") {
        const response = await subscribeUserToPush(indexDBPayload);

        if (response.success) {
          await addDataToIDB("pushNotification", indexDBPayload);
        }
      }
    } else {
      const pushNotificationSettings = await getDataByKeyFromIDB("pushNotification", userEmail);

      if (pushNotificationSettings) {
        await deleteDataFromIDB("pushNotification", userEmail);
      }
    }

    setProfileData((prevState) => ({
      ...prevState,
      notifications: newValue,
    }));
  };

  const renderView = () => {
    switch (activeTab) {
      case "Profile":
        return (
          <>
            <div className="profile-buttons">
              <Header
                showBreadcrumb={false}
                leftContent={leftContent(tabContentData[0].title, tabContentData[0].subtitle)}
                rightContent={(
                  <div className="buttons-at-end">
                    <Button bordered small onClick={handleCancel}>
                      Back
                    </Button>
                    <Button small className="save-btn" onClick={onSubmit}>
                      Save
                    </Button>
                  </div>
                )}
              />
            </div>
            <div className="row mt-5">
              <div className="col-md-3">
                <FormLeftHeader
                  title="Your photo"
                  subtitle="This will be displayed on your profile."
                  icon="frame-icon"
                />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-4">
                    {objectSchema &&
                      renderField({
                        field: objectSchema.document.find(
                          (f) => f.camelizedName === "profilePicture"
                        ),
                        isDisabled: false,
                        state: profileData,
                        onChange: (value, text) => {
                          setProfileData({ ...profileData, profilePicture: text });
                        },
                      })}
                  </div>
                </div>
              </div>
            </div>
            <hr className="full-hr" />
            <div className="row mt-5">
              <div className="col-md-3">
                <FormLeftHeader title="Name" icon="frame-icon" />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-6">
                    {objectSchema &&
                      renderField({
                        field: objectSchema.document.find((f) => f.camelizedName === "firstName"),
                        isDisabled: true,
                        state: profileData,
                      })}
                  </div>
                  <div className="col-md-6">
                    {objectSchema &&
                      renderField({
                        field: objectSchema.document.find((f) => f.camelizedName === "lastName"),
                        isDisabled: true,
                        state: profileData,
                      })}
                  </div>
                </div>
              </div>
            </div>
            <hr className="full-hr" />
            <div className="row mt-5">
              <div className="col-md-3">
                <FormLeftHeader title="Email address" icon="frame-icon" />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-6">
                    {objectSchema &&
                      renderField({
                        field: objectSchema.document.find(
                          (f) => f.camelizedName === "personalEmail"
                        ),
                        isDisabled: true,
                        showLabel: false,
                        state: profileData,
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-3">
                <FormLeftHeader title="Role" icon="frame-icon" />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-6">
                    {objectSchema && (
                      <Input key="role" name="role" value={profileData.role} disabled />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <hr className="full-hr" />
          </>
        );
      case "Notification":
        return (
          <>
            <div className="profile-buttons">
              <Header
                showBreadcrumb={false}
                leftContent={leftContent(tabContentData[1].title, tabContentData[1].subtitle)}
              />
            </div>
            <div className="row mt-5">
              <div className="col-md-3">
                <FormLeftHeader
                  title="Settings for notifications"
                  subtitle="Settings for notifications"
                />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-4">
                    <CustomSwitch
                      title="Turn on push notifications"
                      subtitle="Get push notifications for important tasks"
                      value={profileData.notifications}
                      onChange={handlePushNotifications}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
  };

  return (
    <BoxedContent>
      <div>
        <Header
          showBreadcrumb
          leftContent={(
            <div className="profile-info custom-profile-info">
              <Avatar name={user?.name} imageUrl={user?.profilePicture} size="extra-large" />
              <div>
                <h1>{user?.name}</h1>
              </div>
            </div>
          )}
        />
        <TabWithBadge tabs={tabs} className="primary-tabs" onClick={handleStatusTabChange} />
        {isLoadingSchema || isLoadingUser ? <Loader /> : renderView()}
      </div>
    </BoxedContent>
  );
}

export default ProfileSettings;
