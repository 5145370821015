import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { Loader, Pagination } from "@hydra/atom/components";
import { useState, useMemo } from "react";
import {
  CustomizedDateRangePicker,
  Header,
  NoDataFound,
  SvgIcon,
  IconButton,
  HeaderLeftContent
} from "@/components/common";
import { Task } from "@/components/facility/board";
import { formatApiPayloadDate } from "@/utils/helpers";

import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";

const perPageOptions = [
  { label: "12", value: 12 },
  { label: "24", value: 24 },
  { label: "36", value: 36 },
];

function UpcomingView() {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageOptions[0]);

  const { data: jobs, isInitialLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("WorkOrderJobObjectName")),
      currentPage, perPage.value
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("WorkOrderJobObjectName"), {
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
        "startDate[gte]": formatApiPayloadDate(new Date()),
        takePage: currentPage,
        limitPage: perPage.value,
      })
  );

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };

  const totalCount = useMemo(() => jobs?.totalCount || 0, [jobs]);

  if (isInitialLoading) {
    return <Loader />;
  }

  if (!jobs || !jobs?.data?.length) {
    return <NoDataFound title="No job has been found" />;
  }

  return (
    <div className="job-board-container">
      <Header
        className="mt-15"
        leftContent={(
          <div className="board-left-content">
            <HeaderLeftContent
              title="Upcoming Jobs"
              subtitle="Supercharge your workflow and connect the tool you use every day."
            />
          </div>
        )}
        rightContent={(
          <div className="work-order-right-content">
            <CustomizedDateRangePicker name="dateRange" />
            <IconButton onClick={() => {}}>
              <SvgIcon icon="more" />
            </IconButton>
          </div>
        )}
      />
      <div className="upcoming-view-task-container">
        {jobs?.data &&
          jobs?.data.map((task, index) => (
            <div key={index} className="upcoming-task-img">
              <Task key={task.id} index={index} task={task} />
            </div>
          ))}
      </div>
      {!isInitialLoading && (
      <Pagination
        className="dashboard-pagination"
        options={perPageOptions}
        perPage={perPage}
        onSelectChange={(val) => handlePagination(currentPage, val)}
        pageRangeDisplayed={3}
        handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
        showResults
        offset={0}
        totalItems={totalCount}
        pageCount={Math.ceil(totalCount / perPage.value)}
        reactPaginateProps={{
          previousLabel: <span className="material-icons">&#xe5cb;</span>,
          nextLabel: <span className="material-icons">&#xe5cc;</span>,
          forcePage: currentPage - 1,
        }}
      />
      )}
    </div>
  );
}

export default UpcomingView;
