import { useMutation } from "@tanstack/react-query";

import {
  bulkCreateDynamicObjectRecord,
} from "@/api/dynamic/dynamicObjectNameApi";

export default function useSaveBulkMutation() {
  const saveBulkMutation = useMutation(({ objectName, data }) =>
    bulkCreateDynamicObjectRecord(objectName, data)
  );

  return { saveBulkMutation };
}
