export const accountTypeOptions = [
  {
    label: "Root",
    value: "Root",
  },
  {
    label: "Assets",
    value: "Assets",
    rootType: "Asset",
    balanceType: "Credit",
    reportType: "BalanceSheet",
  },
  {
    label: "Current Asset",
    value: "CurrentAsset",
    rootType: "Asset",
    balanceType: "Debit",
    reportType: "BalanceSheet",
  },
  {
    label: "Other Current Asset",
    value: "OtherCurrentAsset",
    rootType: "Asset",
    balanceType: "Debit",
    reportType: "BalanceSheet",
  },
  {
    label: "Non-Current Asset",
    value: "NonCurrentAsset",
    rootType: "Asset",
    balanceType: "Debit",
    reportType: "BalanceSheet",
  },
  {
    label: "Bank",
    value: "Bank",
    rootType: "Asset",
    balanceType: "Debit",
    reportType: "BalanceSheet",
  },
  {
    label: "Cash & Cash Equivalent",
    value: "Cash",
    rootType: "Asset",
    balanceType: "Debit",
    reportType: "BalanceSheet",
  },
  {
    label: "Credit Card",
    value: "CreditCard",
    rootType: "Expense",
    balanceType: "Debit",
    reportType: "ProfitAndLoss",
  },
  {
    label: "Current Liability",
    value: "CurrentLiability",
    rootType: "Liability",
    balanceType: "Credit",
    reportType: "BalanceSheet",
  },
  {
    label: "Non-Current Liabilities",
    value: "NonCurrentLiabilities",
    rootType: "Liability",
    balanceType: "Credit",
    reportType: "BalanceSheet",
  },
  {
    label: "Accounts Receivable (A/R)",
    value: "AccountsReceivable",
    rootType: "Asset",
    balanceType: "Credit",
    reportType: "BalanceSheet",
  },
  {
    label: "Accounts Payable (A/P)",
    value: "AccountsPayable",
    rootType: "Asset",
    balanceType: "Debit",
    reportType: "BalanceSheet",
  },
  {
    label: "Fixed Asset",
    value: "FixedAsset",
    rootType: "Asset",
    balanceType: "Debit",
    reportType: "BalanceSheet",
  },
  {
    label: "Inventory",
    value: "Inventory",
    rootType: "Asset",
    balanceType: "Debit",
    reportType: "BalanceSheet",
  },
  {
    label: "Equity",
    value: "Equity",
    rootType: "Equity",
    balanceType: "Credit",
    reportType: "BalanceSheet",
  },
  {
    label: "Income",
    value: "Income",
    rootType: "Income",
    balanceType: "Credit",
    reportType: "ProfitAndLoss",
  },
  {
    label: "Other Income",
    value: "OtherIncome",
    rootType: "Income",
    balanceType: "Credit",
    reportType: "ProfitAndLoss",
  },
  {
    label: "Expenses",
    value: "Expenses",
    rootType: "Expense",
    balanceType: "Debit",
    reportType: "ProfitAndLoss",
  },
  {
    label: "Other Expense",
    value: "OtherExpense",
    rootType: "Expense",
    balanceType: "Debit",
    reportType: "ProfitAndLoss",
  },
  {
    label: "Cost of Sales",
    value: "CostOfSales",
    rootType: "Expense",
    balanceType: "Debit",
    reportType: "ProfitAndLoss",
  },
  {
    label: "Duties & Taxes",
    value: "DutiesAndTaxes",
    rootType: "Expense",
    balanceType: "Debit",
    reportType: "ProfitAndLoss",
  },
];

export const rootTypeOptions = [
  {
    label: "Asset",
    value: "Asset",
    balanceType: "Debit",
    reportType: "BalanceSheet",
  },
  {
    label: "Liability",
    value: "Liability",
    balanceType: "Credit",
    reportType: "BalanceSheet",
  },
  {
    label: "Income",
    value: "Revenue",
    balanceType: "Credit",
    reportType: "ProfitAndLoss",
  },
  {
    label: "Expense",
    value: "Expense",
    balanceType: "Debit",
    reportType: "ProfitAndLoss",
  },
  {
    label: "Equity",
    value: "Equity",
    balanceType: "Credit",
    reportType: "BalanceSheet",
  }
];

export const exemptedTaxTypes = ["FamilyRelated"];

export const taxableTypes = ["Commercial"];
