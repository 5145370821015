import Skeleton from "react-loading-skeleton";

function TableSkeleton() {
  const rows = new Array(10).fill(0);
  const columns = new Array(10).fill(0);

  return (
    <div className="table-wrapper">
      <table className="table" style={{width: "100%"}}>
        <thead>
          <tr>
            {columns.map((column, j) => (
              <td key={`column-${j}`} aria-label="Loading" style={{ paddingInline: "5px", paddingBlock: 0 }}>
                <Skeleton height={6} style={{ padding: 0 }} />
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, i) => (
              <tr key={`row-${i}`}>
                {columns.map((column, j) => (
                  <td key={`column-${j}`} aria-label="Loading" style={{ paddingInline: "5px", paddingBlock: 0 }}>
                    <Skeleton height={6} style={{ padding: 0 }} />
                  </td>
                ))}
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}

export default TableSkeleton;
