import PropTypes from "prop-types";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import { getTestId } from "@/utils/helpers";

function CustomizedTimeRangePicker({
  value, onChange, format, required, testId, ...rest
}) {

  return (
    <TimeRangePicker
      format={format}
      hourPlaceholder="--"
      minutePlaceholder="--"
      className="custom-time-range-picker"
      clockIcon={null}
      clearIcon={null}
      disableClock
      onChange={onChange}
      value={value}
      required={required}
      data-testid={getTestId(testId)}
      {...rest}
    />
  );
}

CustomizedTimeRangePicker.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
  required: PropTypes.bool,
  testId: PropTypes.string,
};

CustomizedTimeRangePicker.defaultProps = {
  format: "hh:mm a",
  required: false,
  testId: "",
};

export default CustomizedTimeRangePicker;
