import { groupBy, range } from "lodash";
import {
  nonEditable,
  textCell,
  headerCell,
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT } from "../constants";
import { formatCurrency } from "@/utils/helpers";

function getHeaderRow(columns) {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: columns.map((column) => headerCell(column.label)),
  };
}

function getGroupRows(title, groups, totalDebit, totalCredit, columns, groupIndex) {
  if (groups?.length) {
    return [
      ...groups.map((item, index) => ({
        rowId: item.transactionId,
        height: ROW_HEIGHT,
        cells: columns.map((column) => {
          const textCellArgs = {};

          if (column.formatter) {
            textCellArgs.text = column.formatter(item[column.columnId], index);
          } else {
            textCellArgs.text = item[column.columnId] ?? "";
          }

          return nonEditable(textCell(textCellArgs), column.className || "");
        }),
      })),
      {
        rowId: `${title}-${groupIndex}-Footer`,
        height: ROW_HEIGHT,
        cells: [
          headerCell(title, "total-row"),
          ...range(0, columns.length - 3).map(() => headerCell("", "total-row")),
          headerCell(formatCurrency(Math.abs(totalDebit)), "total-row-debit"),
          headerCell(formatCurrency(totalCredit), "total-row-credit"),
        ],
      },
    ];
  }
  return [];
}

function getGrandTotalRow(title, totalDebit, totalCredit, columns) {
  return {
    rowId: `${title}Footer`,
    height: ROW_HEIGHT,
    cells: [
      headerCell(title, "light-green-bg font-bold"),
      ...range(0, columns.length - 3).map(() => headerCell("", "light-green-bg")),
      headerCell(formatCurrency(Math.abs(totalDebit)), "light-green-bg font-bold"),
      headerCell(formatCurrency(totalCredit), "light-green-bg font-bold"),
    ],
  };
}

// eslint-disable-next-line import/prefer-default-export
export function getGeneralJournalRows({ data, columns }) {
  let grandTotalCredit = 0;
  let grandTotalDebit = 0;

  if (!data) {
    return [];
  }

  const groups = groupBy(data?.data, "journalEntryId");
  const groupValues = Object.values(groups);

  // Create group rows
  const groupedRows = groupValues.flatMap((group, index) => {
    const totalCredit = group.reduce(
      (accumulator, currentValue) => accumulator + +(currentValue?.creditAmount || 0),
      0
    );

    const totalDebit = group.reduce(
      (accumulator, currentValue) => accumulator + +(currentValue.debitAmount || 0),
      0
    );

    grandTotalCredit += totalCredit;
    grandTotalDebit += totalDebit;

    return getGroupRows("Total", group, totalDebit, totalCredit, columns, index);
  });

  return [getHeaderRow(columns), ...groupedRows, getGrandTotalRow("Grand Total", grandTotalDebit, grandTotalCredit, columns)];
}
