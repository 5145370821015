import PropTypes from "prop-types";
import { format } from "date-fns";
import { startCase } from "lodash";
import { useSelector } from "react-redux";
import { SvgIcon } from "@/components/common";
import { CustomActionDropdown } from "@/components/dynamic";
import { formatDate } from "@/utils/helpers";
import { selectPermission } from "@/store/userSlice";

export default function ActionDropDownCell({
  onDelete,
  onEdit,
  onCreateChild,
  row,
  onView
}) {
  const deletePermission = useSelector(selectPermission({ parent: "Model", scope: "LedgerAccount", action: "Delete" }));

  const actions = [
    {
      title: "Create child account",
      onClick: () => { onCreateChild(row.original.id); },
      icon: "document-icon"
    },
    {
      title: "View",
      onClick: () => { onView(row.original.id); },
      icon: "eye"
    },
    {
      title: "Edit",
      onClick: () => { onEdit(row.original.id); },
      icon: "edit-icon"
    },
  ];

  // if (deletePermission) {
  //   actions.push({
  //     title: "Delete",
  //     onClick: () => { onDelete(row.original.id); },
  //     icon: "trash-icon"
  //   });
  // }

  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={actions}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId="Ledger-Account-Button"
      />
    </div>
  );
}

ActionDropDownCell.propTypes = {
  row: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreateChild: PropTypes.func.isRequired,
};

function StatusCell({ row }) {
  return (
    <div className={`status-cell ${row?.original?.isFrozen ? "red" : "green"}`}>
      <div className="dot" />
      {row.original.isFrozen ? "Frozen" : "Active"}
    </div>
  );
}

StatusCell.propTypes = {
  row: PropTypes.object.isRequired,
};

function DateCell({ value }) {
  return (
    <div className="date-cell">{`${formatDate(new Date(value))} ${format(new Date(`${value}Z`), "hh:mm a")}`}</div>
  );
}

DateCell.propTypes = {
  value: PropTypes.string.isRequired,
};

function CreatedByCell({ value }) {
  return (
    <div className="date-cell">{value ? startCase(value) : ""}</div>
  );
}

CreatedByCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const getLedgerAccountTableColumns = () => [
  {
    id: "expander",
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <span {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? (
          <span className="material-icons-outlined">expand_more</span>
        ) : (
          <span className="material-icons-outlined">chevron_right</span>
        )}
      </span>
    ),
    Cell: ({ row }) => (row.canExpand ? (
      <span
        {...row.getToggleRowExpandedProps({
          style: {
            paddingLeft: `${row.depth * 0.5}rem`,
          },
        })}
      >
        {row.isExpanded ? (
          <span className="material-icons-outlined">expand_more</span>
        ) : (
          <span className="material-icons-outlined">chevron_right</span>
        )}
      </span>
    ) : null),
    collapse: true,
  },
  {
    Header: "Code",
    accessor: "code",
    defaultCanSort: true,
    Cell: ({ value }) => (
      <div className="code-cell">
        <SvgIcon icon="folder-icon" />
        {value}
      </div>
    ),
  },
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ row, value }) => (
      <span
        {...row.getToggleRowExpandedProps({
          style: {
            paddingLeft: `${row.depth * 2}rem`,
          },
        })}
      >
        {value}
      </span>
    ),
    defaultCanSort: true,
    filter: "text",
  },
  {
    Header: "Status",
    accessor: "isFrozen",
    Cell: StatusCell,
  },
  {
    Header: "Created By",
    accessor: "createdByName",
    Cell: CreatedByCell,
    defaultCanSort: true,
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    Cell: DateCell,
    defaultCanSort: true,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionDropDownCell,
    collapse: true,
  },
];

export const getLedgerAccountTableData = (data) => data.map((row) => ({
  ...row,
  action: "",
}));
