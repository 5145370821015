function years() {
  return new Array(7).fill(0);
}

// eslint-disable-next-line no-bitwise, prefer-exponentiation-operator, no-restricted-properties
const toFixed = (n, fixed) => ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);

function generateRandomValues(value, variation) {
  const min = value - variation;
  const max = value + variation;
  return years().map(() => toFixed(Math.random() * (max - min + 1) + min, 2));
}

export const inflows = [
  {
    title: "Sales",
    values: generateRandomValues(32000, 5000)
  },
];

export const outflows = [
  {
    title: "Sales",
    values: generateRandomValues(2000, 100)
  },
];
