export const validationMessages = {
  required: {
    required: "This field is required",
  },
  fieldName: {
    required: "This field is required",
    alpha: "Field name can only contain letters",
  },
  minimum: {
    min: "The value cannot be less than zero",
  },
};

export const currencies = [
  {
    label: "Canadian Dollar (CAD)",
    value: "CAD",
  },
  {
    label: "Euro (EUR)",
    value: "EUR",
  },
  {
    label: "Japanese Yen (JPY)",
    value: "Japanese Yen (JPY)",
  },
  {
    label: "UAE Dirham (AED)",
    value: "AED",
  },
  {
    label: "US Dollar (USD)",
    value: "USD",
  },
];

export const areaUnitType = [
  {
    label: "Sq.m",
    value: "SquareMeter",
  },
  {
    label: "Sq.ft",
    value: "SquareFeet",
  }
];

export const userType = [
  {
    label: "Web",
    value: "tuli-web-client",
  },
  {
    label: "Mobile",
    value: "tuli-technician",
  }
];

export const dateFormat = [
  {
    label: "dd/mm/yyyy",
    value: "dd/MM/yyyy",
  },
  {
    label: "mm/dd/yyyy",
    value: "MM/dd/yyyy",
  }
];

export const companyAccountTypes = [
  "Unrealised Rental Revenue",
  "Rental Revenue",
  "Customer Receivable",
  "Contract Termination",
  "Bounce Cheque Penalty",
  "Security Deposit",
  "Customer Advance",
  "Maintenance Revenue",
  "Reservation Deposit",
  "Reservation Fee Income",
  "Expense",
  "Customer Refund",
  "Damage Break Penalty",
  "Unrealised Utility Revenue",
  "Utility Revenue",
  "Pdc on Hand",
  "Pdc Contra",
  "Fixed Asset Clearing",
  "Fixed Asset Sale Clearing",
  "Fixed Asset Revaluation",
  "Fixed Asset Surplus Revaluation",
  "Fixed Asset Control",
  "Fixed Asset Reclassification",
  "Fixed Asset Inter Component Transfer",
  "Service Contract Clearing",
  "Employee Expense",
];
