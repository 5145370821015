import { useRef, useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import { useCompanyAccount } from "@/hooks";
import { getFullName, calculateTax } from "@/utils/helpers";
import { exemptedTaxTypes } from "@/utils/finance/constants";

const formatLead = (lead) => {
  const data = {};

  let address = "";

  try {
    address = JSON.parse(lead?.addressStreet)?.value?.description;
  } catch (error) {
    console.log(error);
  }

  data.lead = {
    lookupObjectName: "Lead",
    label: getFullName(lead),
    value: lead.id,
    email: lead.email,
    phone: lead.phone,
    address,
    type: lead.type
  };

  if (!isEmpty(lead.building)) {
    data.building = {
      label: lead.building.name,
      value: lead.building.id
    };

    const { emirate, bankAccount } = lead.building;

    if (!isEmpty(emirate)) {
      data.salesLocation = {
        label: emirate?.name,
        value: emirate?.id
      };
    }

    if (!isEmpty(lead.component)) {
      data.component = {
        label: lead.component?.name,
        value: lead.component?.id
      };
    }

    if (!isEmpty(bankAccount) && bankAccount.account && bankAccount.account?.name) {
      data.paymentAccount = {
        label: bankAccount.account.name,
        value: bankAccount.account.key
      };
    }
  }

  return data;
};

function ReservationForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});
  const [initialState, setInitialState] = useState({});
  const [searchParams] = useSearchParams();
  const leadId = searchParams.get("lead");
  const defaultAccounts = useCompanyAccount({
    params: {
      includeCompanyId: true,
      isLinkedWithRecord: false
    }
  });

  const { data: leadData } = useQuery(
    [dynamicObjectMap.get("LeadObjectName"), leadId],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("LeadObjectName"), leadId),
    {
      enabled: Boolean(leadId),
    }
  );

  useEffect(() => {
    if (defaultAccounts && !initialState.areAccountsSelected) {
      const { reservationDeposit, reservationFeeIncome } = defaultAccounts;

      setInitialState((prevState) => ({
        ...prevState,
        areAccountsSelected: true,
        reservationDepositAccount: reservationDeposit,
        reservationFeeIncomeAccount: reservationFeeIncome
      }));
    }
  }, [defaultAccounts]);

  useEffect(() => {
    if (leadData && leadId) {
      setInitialState((prevState) => ({
        ...prevState,
        ...formatLead(leadData)
      }));
    }
  }, [leadData, leadId]);

  const setBuildingAndLead = (value) => {
    const data = formatLead(value);
    ref.current.setFormState(data);
  };

  const setBuildingAccount = (value) => {
    const data = {};
    if (value) {
      const { bankAccount } = value;

      if (!isEmpty(bankAccount) && bankAccount.account) {
        data.paymentAccount = {
          label: bankAccount.account.name,
          value: bankAccount.account.id
        };
      }
    }

    ref.current.setFormState(data);
  };

  const setTaxAndTotal = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { tax, amountOfTax, subtotal } = formState;

    const data = {
      subtotal,
      amountBeforeTax: subtotal,
      taxAmount: "",
      total: ""
    };

    if (amountOfTax && tax) {
      const parameters = {
        amount: subtotal,
        amountOfTax,
        tax,
        taxAmount: ""
      };

      const { taxAmount, principalAmount } = calculateTax(parameters);

      data.taxAmount = taxAmount;
      data.amountBeforeTax = principalAmount;
    }

    data.total = Number(data.amountBeforeTax) + Number(data.taxAmount);
    // Hidden fields
    data.openBalance = data.total;
    ref.current.setFormState(data);
  };

  const setUnit = (value) => {
    const formState = ref.current.getState();
    const { lead, type } = formState;

    const data = {
      tax: null,
      reservationSubtotal: 0
    };

    if (!value || !value.length || exemptedTaxTypes.includes(lead?.type)) {
      ref.current.setFormState(data);
      return;
    }

    value.forEach(({ tax, totalRentAmount }) => {
      if (tax && !isEmpty(tax)) {
        data.tax = {
          ...tax,
          label: tax.name,
          value: tax.id,
        };
      }

      if (totalRentAmount) {
        data.reservationSubtotal += totalRentAmount;
      }
    });

    if (data.reservationSubtotal) {
      const fivePercent = data.reservationSubtotal * 0.05;
      const reservationAmount = (fivePercent < 3000) ? 3000 : fivePercent;
      data.subtotal = reservationAmount;
      data.total = reservationAmount;
      data.openBalance = reservationAmount;
      ref.current.setFormState(data);
    }

    if (type && type.value === "NonRefundable") {
      ref.current.setFormValue("tax", data.tax);
      setTaxAndTotal("tax", data.tax);
    }
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "lead":
        setBuildingAndLead(value);
        break;

      case "building":
        setBuildingAccount(value);
        break;

      case "unit":
        setUnit(value);
        break;

      case "subtotal":
        setTaxAndTotal(key, value);
        break;

      case "tax": {
        setTaxAndTotal(key, value);
        break;
      }

      case "amountOfTax": {
        if (state[key]) {
          if (state[key]?.value === value?.value) {
            break;
          }
        }

        setState((prevState) => ({
          ...prevState,
          [key]: value
        }));

        setTaxAndTotal(key, value);
        break;
      }

      default:
        break;
    }
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        initialData={initialState}
        ref={ref}
        objectName={dynamicObjectMap.get("ReservationObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
      />
    </BoxedContent>
  );
}

export default ReservationForm;
