import { estimatedColumnWidth } from "../helpers";
import { formatDate } from "@/utils/helpers";

export default function getWorkOrderAgingColumns() {
  const colArr = [
    {
      label: "Number",
      value: true,
      columnId: "number",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Title",
      value: true,
      columnId: "title",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Type",
      value: true,
      columnId: "type",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Building",
      value: true,
      columnId: "building",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Priority",
      value: true,
      columnId: "priority",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Status",
      value: true,
      columnId: "status",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Supervisor",
      value: true,
      columnId: "supervisor",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit",
      value: true,
      columnId: "unit",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Service Request",
      value: true,
      columnId: "serviceRequest",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Category",
      value: true,
      columnId: "category",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Created At",
      value: true,
      columnId: "createdAt",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? String(value ? formatDate(new Date(value)) : "") : "")
    },
    {
      label: "Closed At",
      value: true,
      columnId: "closedAt",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? String(value ? formatDate(new Date(value)) : "") : "")
    },
    {
      label: "Aging",
      value: true,
      columnId: "aging",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || 0)
    }
  ];

  return colArr;
}
