import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, kebabCase } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";

import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import {
  getDynamicObjectRecords,
  getDynamicObjectRecordById,
} from "@/api/dynamic/dynamicObjectNameApi";
import { getTaxRules } from "@/api/finance/taxRuleApi";
import { calculateTax } from "@/utils/helpers";
import { formatWorkOrder, formatWorkOrderJob, getUniqueData } from "@/utils/facility/helpers";
import { useCompanyAccount } from "@/hooks";

const prepareInitialState = (workOrder, workOrderJobs, tax, maintenanceRevenueAccount) => {
  const formattedWorkOrder = formatWorkOrder(workOrder);
  const formattedJobs = workOrderJobs.data.map((j) => formatWorkOrderJob(j));
  formattedWorkOrder.jobs = formattedJobs;

  const { id, number, tenant, jobs } = formattedWorkOrder;

  const data = {
    workOrder: {
      label: number,
      value: id,
    },
    tenant,
    lineItem: [],
  };

  if (tax && !isEmpty(tax)) {
    data.tax = {
      ...tax,
      label: tax.name,
      value: tax.id,
    };
  }

  const taxRate = data.tax ? data.tax.saleRate : 0;

  const technicians = getUniqueData(jobs, "technician").map((t) => {
    const lineItem = {
      lookupObjectName: dynamicObjectMap.get("EmployeeObjectName"),
      label: t.label,
      value: t.technicianId,
    };
    const amount = t.quantity * t.rate;
    const taxAmount = (amount * taxRate) / 100;
    const totalAmount = amount + taxAmount;

    return {
      ...t,
      account: maintenanceRevenueAccount,
      lineItem,
      tax: data.tax,
      amount,
      taxAmount,
      totalAmount,
      uom: "hours",
      isIncluded: true,
    };
  });

  data.lineItem = data.lineItem.concat(technicians);

  const materials = getUniqueData(jobs, "materials").map((t) => {
    const lineItem = {
      lookupObjectName: dynamicObjectMap.get("ItemObjectName"),
      label: t.label,
      value: t.value,
    };
    const amount = t.quantity * t.rate;
    const taxAmount = (amount * taxRate) / 100;
    const totalAmount = amount + taxAmount;

    return {
      ...t,
      account: maintenanceRevenueAccount,
      lineItem,
      tax: data.tax,
      amount,
      taxAmount,
      totalAmount,
      uom: t.unit,
      isIncluded: true,
    };
  });

  data.lineItem = data.lineItem.concat(materials);

  const equipment = getUniqueData(jobs, "equipment").map((t) => {
    const lineItem = {
      lookupObjectName: dynamicObjectMap.get("ItemObjectName"),
      label: t.label,
      value: t.value,
    };
    const amount = t.quantity * t.rate;
    const taxAmount = (amount * taxRate) / 100;
    const totalAmount = amount + taxAmount;

    return {
      ...t,
      account: maintenanceRevenueAccount,
      lineItem,
      tax: data.tax,
      amount,
      taxAmount,
      totalAmount,
      uom: "hours",
      isIncluded: true,
    };
  });

  data.lineItem = data.lineItem.concat(equipment);

  const subtotal = data.lineItem.reduce(
    (total, currentValue) => total + Number(currentValue.amount),
    0
  );

  const totalAmount = data.lineItem.reduce(
    (total, currentValue) => total + Number(currentValue.totalAmount),
    0
  );

  const taxAmount = data.lineItem.reduce(
    (total, currentValue) => total + Number(currentValue.taxAmount),
    0
  );

  data.subtotal = subtotal;
  data.amountBeforeTax = totalAmount;
  data.taxAmount = taxAmount;
  data.total = totalAmount;

  return data;
};

function ProformaInvoiceForm() {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [initialState, setInitialState] = useState({});
  const [state, setState] = useState({});
  const [searchParams] = useSearchParams();
  const workOrder = searchParams.get("workOrder");
  const defaultAccounts = useCompanyAccount({
    params: {
      includeCompanyId: true,
      isLinkedWithRecord: false,
    },
  });

  const { data: taxRulesData } = useQuery(["tax-rule"], getTaxRules, {
    enabled: Boolean(workOrder),
  });

  const { data: workOrderData } = useQuery(
    [kebabCase(dynamicObjectMap.get("WorkOrderObjectName")), workOrder],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("WorkOrderObjectName"), workOrder),
    {
      enabled: Boolean(workOrder),
    }
  );

  const { data: workOrderJobsData } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("WorkOrderJobObjectName")),
      {
        workOrder,
        queryMode: "Deep",
      },
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("WorkOrderJobObjectName"), {
        workOrder,
        queryMode: "Deep",
      })
  );

  useEffect(() => {
    if (workOrder && workOrderData && workOrderJobsData && taxRulesData && defaultAccounts) {
      const { building } = workOrderData;
      let taxItem = null;

      if (building) {
        const { emirate } = building;

        taxItem = taxRulesData?.data
          .filter((t) => t.saleCollected)
          .find((i) => i.region === emirate?.id);
      }

      const { maintenanceRevenue, utilityRevenue } = defaultAccounts;
      const initialFormState = prepareInitialState(
        workOrderData,
        workOrderJobsData,
        taxItem,
        maintenanceRevenue ?? utilityRevenue
      );
      setInitialState(initialFormState);
    }
  }, [workOrder, workOrderJobsData, taxRulesData, defaultAccounts]);

  const updateLineItem = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { amountOfTax, tax: parentTax } = formState;

    if (key === "lineItemTable") {
      const { quantity, rate } = value;
      let amount = quantity * rate;
      const { tax, taxAmount: prevTaxAmount } = value;
      let taxAmount = 0;

      const selectedTax = tax;

      if (selectedTax) {
        const parameters = {
          amount,
          amountOfTax,
          tax: selectedTax,
          taxAmount: prevTaxAmount,
        };

        const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax(parameters);

        amount = principalAmount;
        taxAmount = calculatedTaxAmount;
      }

      ref.current.setFormValue("lineItemTable", {
        ...value,
        amount,
        tax: selectedTax,
        taxAmount,
        totalAmount: Number(amount) + Number(taxAmount),
      });
    } else if (key === "lineItem") {
      let subtotal = 0;
      let taxAmountTotal = 0;
      const updatedLineItem = value.map((item) => {
        const { quantity, rate } = item;
        let amount = quantity * rate;
        subtotal += amount;
        const { tax, taxAmount: prevTaxAmount } = item;
        let taxAmount = 0;

        const selectedTax = tax ?? parentTax;

        if (selectedTax && amountOfTax) {
          const parameters = {
            amount,
            amountOfTax,
            tax: selectedTax,
            taxAmount: "",
          };

          if (key === "amountOfTax") {
            parameters.taxAmount = prevTaxAmount || 0;
          }

          const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax(parameters);

          amount = principalAmount;
          taxAmount = calculatedTaxAmount;
        }
        taxAmountTotal += Number(taxAmount);
        return {
          ...item,
          amount,
          tax: selectedTax,
          taxAmount,
          totalAmount: Number(amount) + Number(taxAmount),
        };
      });
      const data = {
        subtotal,
        taxAmount: taxAmountTotal,
        total: Number(subtotal) + Number(taxAmountTotal),
      };
      ref.current.setFormState(data);
      ref.current.setFormValue("lineItem", updatedLineItem);
    }
  };

  const setTaxAndTotal = (key, value) => {
    updateLineItem(key, value);
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "tenant":
        setState({
          ...state,
          [key]: value,
        });
        break;

      case "tax":
      case "lineItemTable":
      case "lineItem": {
        setTaxAndTotal(key, value);
        break;
      }

      case "amountOfTax": {
        if (state[key]) {
          if (state[key]?.value === value?.value) {
            break;
          }
        }

        setState((prevState) => ({
          ...prevState,
          [key]: value,
        }));

        setTaxAndTotal(key, value);
        break;
      }

      default:
        break;
    }
  };

  const handleSuccess = () => {
    if (workOrder) {
      const workOrderDetailRoute = `/facility-management/work-order/details/${workOrder}`;
      navigate(workOrderDetailRoute);
      return;
    }

    navigate(-1);
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        initialData={initialState}
        objectName={dynamicObjectMap.get("ProformaInvoiceObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        onSuccess={handleSuccess}
      />
    </BoxedContent>
  );
}

export default ProformaInvoiceForm;
