import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Button, ReactSelect } from "@hydra/atom/components";
import { components } from "react-select";
import { useQuery } from "@tanstack/react-query";
import { kebabCase, camelCase } from "lodash";
import { AdvanceSearchModal, MultiLevelSearchModal } from "@/components/modals";
import { getTestId } from "@/utils/helpers";

const getOptions = ({
  data,
  dataAccessKey,
  optionLabelKey,
  optionValueKey,
  hasNestedOptions,
  formatOption
}) => {
  if (!data) return [];
  let options = [];

  if (formatOption) {
    return data[dataAccessKey].map((o) => formatOption(o));
  }

  if (hasNestedOptions) {
    options = data[dataAccessKey].map((o) => ({
      ...o,
      label: o[optionLabelKey],
      value: o[optionValueKey],
      depth: o.depth,
    }));

    return options;
  }

  // TODO: add create new option with proper support
  // if (showCreateNew) {
  //   const createNewOption = {
  //     label: "Create New",
  //     value: "new"
  //   };

  //   options.push(createNewOption);
  // }

  data[dataAccessKey].forEach((o) => {
    o.label = o[optionLabelKey];
    o.value = o[optionValueKey];
    options.push(o);
  });

  return options;
};
function PillWithIconButton({ label, onMouseDown, testId }) {
  return (
    <div className="poly-pill-with-icon-button d-flex align-item-center">
      <span className="text">{label}</span>
      <button type="button" onMouseDown={onMouseDown} data-testid={getTestId(`${testId}-Pill-Button`)}>
        <span className="icon material-icons">close</span>
      </button>
    </div>
  );
}

PillWithIconButton.propTypes = {
  label: PropTypes.string.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

PillWithIconButton.defaultProps = {
  testId: ""
};

export function Control({ children, ...props }) {
  const { inputId: testId } = props;
  const { field } = props.selectProps;

  return (
    <div data-testid={getTestId(testId)}>
      <components.Control {...props}>
        {field?.label && field.selectedType && (
          <PillWithIconButton
            label={field.label}
            onMouseDown={field.resetSelectedType}
            testId={getTestId(testId)}
          />
        )}
        {children}
      </components.Control>
    </div>
  );
}

export function DropdownIndicator(props) {
  const { selectProps } = props;
  const { handleSubmit, inputId, setOpenState } = selectProps;
  return (
    <div onMouseEnter={() => { setOpenState(true); }} onMouseLeave={() => { setOpenState(false); }} role="banner" onClick={handleSubmit}>
      <components.DropdownIndicator {...props} onMouseDown={handleSubmit} className="p-0">
        <Button onMouseEnter={() => { setOpenState(true); }} onMouseLeave={() => { setOpenState(false); }} small className="button-secondary" testId={getTestId(`${inputId}-Search-Button`)}>
          Search
        </Button>
      </components.DropdownIndicator>
    </div>
  );
}

function Select({
  value,
  onChange,
  placeholder,
  name,
  error,
  optionsLoader,
  optionLabelKey,
  optionValueKey,
  dataAccessKey,
  hasNestedOptions,
  showCreateNew,
  apiFilters,
  formatOption,
  lookupDisplayFieldName,
  lookupObjectName,
  componentOptions,
  handleDispatch,
  formState,
  queryKey,
  ...rest
}) {
  const [openModal, setOpenModal] = useState(false);

  const { data, isLoading } = useQuery(
    [lookupObjectName ? kebabCase(lookupObjectName) : queryKey, apiFilters],
    optionsLoader
  );
  const selectOptions = useCallback(
    getOptions({
      data,
      dataAccessKey,
      optionLabelKey,
      optionValueKey,
      hasNestedOptions,
      showCreateNew,
      lookupDisplayFieldName,
      formatOption,
      lookupObjectName
    }),
    [data]
  );
  const [openState, setOpenState] = useState(false);

  const handleSubmit = () => {
    setOpenModal(true);
  };
  return (
    <>
      <ReactSelect
        id={name}
        name={name}
        closeMenuOnSelect
        handleSubmit={handleSubmit}
        className={openState ? "search-advance-filter" : ""}
        hideSelectedOptions={false}
        options={selectOptions}
        value={value}
        placeholder={placeholder}
        onChange={(option) => { onChange(option); }}
        noOptionsMessage={() => "No options found"}
        isLoading={isLoading}
        openState={openState}
        setOpenState={setOpenState}
        isOptionDisabled={(option) => option.disabled}
        components={{
          Control,
          ...componentOptions,
          ...(rest?.field?.advanceSearch && !(rest?.field?.name === "subCategory" && !rest.field.drawerField) ? { DropdownIndicator } : {}),
        }}
        {...rest}
      />
      {typeof error === "string" && <span className="error-text">{error}</span>}
      {rest?.field?.advanceSearch && !(rest?.field.childFieldName === "subCategory" && rest?.field.parentFieldName === "category") && (
        <AdvanceSearchModal
          isOpen={openModal}
          filterOption={[]}
          multiSelect={rest.isMulti}
          objectLabel={lookupObjectName}
          objectName={lookupObjectName}
          filters={apiFilters}
          setOpenModal={setOpenModal}
          onSubmit={(selectedItems) => {
            let finalValue;
            if (rest.isMulti) {
              finalValue = selectedItems.map((item) => ({
                ...item,
                label: item[optionLabelKey],
                value: item.id,
              }));
            } else {
              const item = selectedItems[0];
              finalValue = {
                ...item,
                label: item[optionLabelKey],
                value: item.id,
              };
            }
            onChange(finalValue);
          }}
        />
      )}
      {rest?.field?.advanceSearch && (rest?.field.childFieldName === "subCategory" && rest?.field.parentFieldName === "category") && (
      <MultiLevelSearchModal
        isOpen={openModal}
        filterOption={[]}
        multiSelect={rest.isMulti}
        objectLabel={lookupObjectName}
        objectName={lookupObjectName}
        parentObjectName={kebabCase(rest?.field?.parentFieldName)}
        childObjectName={kebabCase(rest?.field?.childFieldName)}
        filters={apiFilters}
        setOpenModal={setOpenModal}
        onSubmit={(selectedItems = [], selectedChildRecordList = []) => {
          let finalValue; let
            childFinalValue;

          if (rest.isMulti) {
            finalValue = selectedItems?.map((item) => ({
              ...item,
              label: item[optionLabelKey],
              value: item.id,
            }));
            childFinalValue = selectedChildRecordList?.map((item) => ({
              ...item,
              label: item[optionLabelKey],
              value: item.id,
            }));
          } else {
            const item = selectedItems[0];
            const childItem = selectedChildRecordList[0];
            if (selectedItems.length > 0) {
              finalValue = {
                ...item,
                label: item[optionLabelKey],
                value: item.id,
              };
            }
            if (selectedChildRecordList.length > 0) {
              childFinalValue = {
                ...childItem,
                label: childItem[optionLabelKey],
                value: childItem.id,
              };
            }
          }
          handleDispatch(camelCase(rest?.field?.parentFieldName), finalValue);
          handleDispatch(camelCase(rest?.field?.childFieldName), childFinalValue);
        }}
        selectedParentValue={formState[rest.field.parentFieldName]}
        selectedChildValue={formState[rest.field?.childFieldName]}
      />
      )}
    </>
  );
}

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.oneOf([null])]),
  lookupObjectName: PropTypes.string,
  lookupDisplayFieldName: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hasNestedOptions: PropTypes.bool,
  optionsLoader: PropTypes.func.isRequired,
  optionLabelKey: PropTypes.string,
  optionValueKey: PropTypes.string,
  dataAccessKey: PropTypes.string,
  showCreateNew: PropTypes.bool,
  apiFilters: PropTypes.object,
  queryKey: PropTypes.string,
  formatOption: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
};

Select.defaultProps = {
  value: null,
  placeholder: "Select",
  lookupDisplayFieldName: [],
  name: "Select",
  lookupObjectName: "",
  error: false,
  hasNestedOptions: false,
  optionLabelKey: "label",
  optionValueKey: "value",
  dataAccessKey: "data",
  showCreateNew: false,
  apiFilters: {},
  formatOption: null,
  queryKey: ""
};

Select.Type = "HydraValidatableComponent";

export default Select;
