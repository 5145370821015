import { getColumnWidth } from "../helpers";

const COL_WIDTH = 135;

export default function getTransactionDetailColumns(windowWidth) {
  return [
    { columnId: "srNo", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: "transactionNumber", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    {
      columnId: "titlesColumn",
      width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth)
    },
    { columnId: 1, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: 2, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: 3, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: 4, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: 5, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: 6, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: 7, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: 8, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: 9, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: 10, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: 11, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: 12, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: 13, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: 14, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    { columnId: 15, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 6, windowWidth) },
    {
      columnId: "Totals",
      width: 130
    }
  ];
}
