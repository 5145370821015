import { useReducer, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  Button,
  Form,
  FormControl,
  Input,
  Label,
  ReactSelect,
  TextArea,
  Loader,
} from "@hydra/atom/components";
import { selectPermission } from "@/store/userSlice";

import showToast from "@/utils/toast/helpers";
import {
  Select,
  BoxedContent,
  Header,
  FormLeftHeader,
  CustomizedDatePicker,
  CurrencyField,
  CheckboxWithIcon,
} from "@/components/common";
import { useUrlSearchParams, useModal } from "@/hooks";
import {
  ledgerAccountFormReducer,
  initialState,
  setFormValue,
  setInitialState,
} from "@/reducers/finance/ledgerAccountFormReducer";
import { nestedOptionStyles } from "@/utils/select/constants";
import {
  createLedgerAccount,
  updateLedgerAccount,
  getLedgerAccountById,
  getLedgerAccounts,
  getLedgerAccountCode,
  deleteLedgerAccount,
  createMultiCompanyLedgerAccount,
} from "@/api/finance/ledgerAccountApi";
import { getCurrencies } from "@/api/finance/currencyApi";
import { getTaxGroups } from "@/api/finance/taxGroupApi";
import { buildTree, flattenTree, formatGLAccountOption } from "@/utils/finance/helpers";
import { selectActiveCompany } from "@/store/appSlice";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { accountTypeOptions, rootTypeOptions } from "@/utils/finance/constants";
import { CustomActionDropdown } from "@/components/dynamic";
import { formatApiPayloadDate } from "@/utils/helpers";
import { getRoles } from "@/api/user/authApi";
import { AlertModal } from "@/components/modals";

const validationMessages = {
  required: {
    required: "This field is required",
  },
};

const prepareData = (state) => {
  const data = { ...state };
  const {
    parent,
    accountType,
    balanceAsOfDate,
    currencyCode,
    company,
    taxGroup,
    balance,
    rootType,
    isFrozen,
  } = state;

  data.balance = Number(balance);
  data.parentId = parent?.value || null;

  if (company) {
    data.companyId = company.value;
  }

  if (taxGroup) {
    data.taxGroupId = taxGroup.value;
  }

  data.accountType = accountType?.value;
  data.headerType = accountType?.value;

  if (accountType?.value === "Root") {
    data.rootType = rootType?.value;
    data.reportType = rootType?.reportType;
    data.balanceType = rootType?.balanceType;
  } else {
    data.rootType = accountType?.rootType;
    data.reportType = accountType?.reportType;
    data.balanceType = accountType?.balanceType;
  }

  if (currencyCode) {
    data.currencyCode = state.currencyCode.value;
  }

  if (balanceAsOfDate) {
    data.balanceAsOfDate = formatApiPayloadDate(balanceAsOfDate);
  }

  data.isFrozen = isFrozen;
  data.isNegativeBalanceAllowed = true;
  data.allowedRoles = [];

  return data;
};

const prepareState = (data) => {
  const state = { ...data };
  const {
    parent,
    headerType,
    company,
    taxGroup,
    currencyCode,
    balanceAsOfDate,
    rootType,
    isFrozen,
  } = data;

  if (balanceAsOfDate) {
    state.balanceAsOfDate = new Date(balanceAsOfDate);
  } else {
    state.balanceAsOfDate = new Date();
  }

  if (parent) {
    state.parent = {
      label: parent.name,
      value: parent.id,
    };
  }

  if (company) {
    state.company = {
      label: company.name,
      value: company.id,
    };
  }

  if (taxGroup) {
    state.taxGroup = {
      label: taxGroup.name,
      value: taxGroup.id,
    };
  }

  if (currencyCode) {
    state.currencyCode = {
      label: currencyCode,
      value: currencyCode,
    };
  }

  state.accountType = accountTypeOptions.find((t) => t.value === headerType);

  if (headerType === "Root") {
    state.rootType = rootTypeOptions.find((r) => r.value === rootType);
  }

  state.isFrozen = isFrozen;

  return state;
};

function ActionCell({ deleteMutation, id }) {
  const actions = [
    {
      title: "Delete",
      onClick: () => {
        deleteMutation.mutate(id);
      },
      icon: "delete-icon",
    },
  ];
  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={actions}
        trigger={(
          <div className="action-dropdown-trigger">
            <span className="text">Actions</span>
            <span className="material-icons-outlined">expand_more</span>
          </div>
        )}
        testId="Add-GL-Action-Button"
      />
    </div>
  );
}

export default function AddGLAccount() {
  const { isOpen, closeModal, openModal } = useModal(false);
  const activeCompany = useSelector(selectActiveCompany);
  const [state, dispatch] = useReducer(ledgerAccountFormReducer, initialState);
  const deletePermission = useSelector(
    selectPermission({ parent: "Model", scope: "LedgerAccount", action: "Delete" })
  );

  const navigate = useNavigate();
  const { id } = useParams();
  const searchParameters = useUrlSearchParams();

  const parentId = searchParameters?.parentId ?? null;
  const queryClient = useQueryClient();
  const userRoles = getRoles();

  const deleteMutation = useMutation(deleteLedgerAccount, {
    onError: () => {
      showToast("Could not delete. Try again!", "error");
    },
    onSuccess: () => {
      showToast("Deleted successfully", "success");
      queryClient.invalidateQueries({
        queryKey: ["ledger-account"],
      });
    },
  });

  const { data, isInitialLoading } = useQuery(
    ["ledger-account", id],
    () => getLedgerAccountById(id),
    {
      enabled: typeof id === "string",
    }
  );

  const { data: parentData } = useQuery(
    ["ledger-account", state?.parent?.value || parentId],
    () => getLedgerAccountById(state?.parent?.value || parentId),
    {
      enabled: typeof parentId === "string",
    }
  );

  useEffect(() => {
    if (id && data) {
      const stateData = prepareState(data?.data);
      dispatch(setInitialState(stateData));
    }
  }, [data]);

  useEffect(() => {
    if (!id && activeCompany) {
      const { defaultCurrencyCode } = activeCompany;

      const currencyCode = {
        label: defaultCurrencyCode,
        value: defaultCurrencyCode,
      };

      dispatch(setFormValue("currencyCode", currencyCode));
    }
  }, [id, activeCompany]);

  const saveMutation = useMutation(createLedgerAccount, {
    onError: (error) => {
      if (error) {
        const { data: errorMessage } = error;

        if (errorMessage) {
          showToast(errorMessage, "error");
        } else {
          showToast("Could not create. Try again!", "error");
        }
      }
    },
    onSuccess: () => {
      showToast("Created successfully", "success");
      navigate(-1);
    },
  });

  const saveMultiCompanyMutation = useMutation(createMultiCompanyLedgerAccount, {
    onError: (error) => {
      if (error) {
        const { data: errorMessage } = error;

        if (errorMessage) {
          showToast(errorMessage, "error");
        } else {
          showToast("Could not create. Try again!", "error");
        }
      }
    },
    onSuccess: () => {
      showToast("Created successfully", "success");
      navigate(-1);
    },
  });

  const getCodeMutation = useMutation(getLedgerAccountCode, {
    onError: () => {
      showToast("Could not generate code. Try again!", "error");
    },
    onSuccess: (response) => {
      if (response.status === 200) {
        dispatch(setFormValue("code", response.projectedCode));
      }
    },
  });

  const updateMutation = useMutation(
    ({ id: recordId, data: recordData }) => updateLedgerAccount(recordId, recordData),
    {
      onError: () => {
        showToast("Could not update. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Updated successfully", "success");
        navigate(-1);
      },
    }
  );

  useEffect(() => {
    if (parentData?.data) {
      const { id: value, name: label } = parentData.data;
      const stateData = prepareState(parentData.data);
      dispatch(
        setInitialState({
          ...initialState,
          accountType: stateData.accountType,
          currencyCode: stateData.currencyCode,
          balanceAsOfDate: new Date(),
          details: `GL postings under ${label}`,
          parent: {
            label,
            value,
          },
        })
      );
    }
  }, [parentData]);

  useEffect(() => {
    if (!id) {
      const { accountType, parent, rootType } = state;

      if (accountType) {
        if (parent || rootType) {
          const payload = {
            companyId: activeCompany.id,
            headerType: accountType.value,
          };

          if (accountType.value === "Root") {
            payload.rootType = rootType?.value;
          } else {
            payload.parentId = parent?.value;
            payload.rootType = accountType?.rootType;
          }

          getCodeMutation.mutate(payload);
        }
      }
    }
  }, [state.accountType, state.parent, state.rootType]);

  const handleCancel = () => {
    dispatch(setInitialState(initialState));
    navigate(-1);
  };

  const handleSubmit = async () => {
    const recordData = prepareData({
      ...state,
      company: {
        label: activeCompany.name,
        value: activeCompany.id,
      },
    });

    if (id) {
      updateMutation.mutate({
        id,
        data: recordData,
      });

      return;
    }

    if (state.multiCompany) {
      saveMultiCompanyMutation.mutate(recordData);
    } else {
      saveMutation.mutate(recordData);
    }
  };

  if (id && isInitialLoading) {
    return <Loader />;
  }

  return (
    <BoxedContent className="gl-account-form">
      <Header
        showBreadcrumb
        leftContent={(
          <h1>
            {id ? "Edit" : "Create"}
            {" "}
            Ledger Account
          </h1>
        )}
        // rightContent={
        //   id && deletePermission ? <ActionCell deleteMutation={deleteMutation} id={id} /> : null
        // }
      />
      <Form
        onSubmit={openModal}
        key="gl-acc-form"
        className="dynamic-object-form"
        shouldScrollOnError
      >
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Select Account Type*"
              subtitle="Select Account Type"
              icon="check-box-icon"
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <ReactSelect
                    name="accountType"
                    placeholder="Select account type"
                    options={accountTypeOptions}
                    value={state.accountType}
                    onChange={(value) => dispatch(setFormValue("accountType", value))}
                    rules="required"
                    messages={validationMessages.required}
                    isClearable
                    backspaceRemovesValue
                    testId="LedgerAccount-AccountType-Select"
                  />
                </FormControl>
              </div>
            </div>
          </div>
          {state.accountType && state.accountType.value === "Root" && (
            <>
              <div className="col-md-3">
                <FormLeftHeader
                  title="Select Root Type*"
                  subtitle="Select Root Type"
                  icon="check-box-icon"
                />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-6">
                    <FormControl>
                      <ReactSelect
                        name="rootType"
                        placeholder="Select root type"
                        options={rootTypeOptions}
                        value={state.rootType}
                        onChange={(value) => dispatch(setFormValue("rootType", value))}
                        rules="required"
                        messages={validationMessages.required}
                        isClearable
                        backspaceRemovesValue
                        testId="LedgerAccount-RootType-Select"
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="col-md-12">
            <div className="hr" />
          </div>
          <div className="col-md-3">
            <FormLeftHeader
              title="Name & Description"
              subtitle="Provide Name & Description of Cost Center"
              icon="scan-icon"
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="name" label="Title*" />
                  <Input
                    name="name"
                    placeholder="Account name"
                    value={state.name}
                    onChange={(value) => dispatch(setFormValue("name", value))}
                    rules="required"
                    messages={validationMessages.required}
                    testId="LedgerAccount-Name-Input"
                  />
                </FormControl>
              </div>
              <div className="col-md-3">
                <FormControl>
                  <Label htmlFor="code" label="Code*" />
                  <Input
                    name="code"
                    placeholder="Account code"
                    value={state.code}
                    onChange={(value) => dispatch(setFormValue("code", value))}
                    rules="required"
                    messages={validationMessages.required}
                    disabled={!userRoles.includes("admin")}
                    testId="LedgerAccount-Code-Input"
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="details" label="Details" />
                  <TextArea
                    name="details"
                    placeholder="Account details"
                    value={state.details}
                    onChange={(value) => dispatch(setFormValue("details", value))}
                    testId="LedgerAccount-Details-Textarea"
                  />
                </FormControl>
              </div>
            </div>
          </div>
          {state.accountType && state.accountType.value !== "Root" && (
            <>
              <div className="col-md-12">
                <div className="hr" />
              </div>
              <div className="col-md-3">
                <FormLeftHeader title="Select Parent Account" icon="disc-icon" />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-6">
                    <FormControl>
                      <Select
                        id="parentAccount"
                        name="parentAccount"
                        queryKey="ledger-account"
                        placeholder="Select parent"
                        value={state.parent}
                        onChange={(value) => dispatch(setFormValue("parent", value))}
                        optionsLoader={() =>
                          getLedgerAccounts().then((res) => {
                            res.data = flattenTree(buildTree(res.data, 1));
                            return res;
                          })}
                        formatOption={formatGLAccountOption}
                        dataAccessKey="data"
                        optionLabelKey="name"
                        optionValueKey="id"
                        hasNestedOptions
                        styles={nestedOptionStyles}
                        isClearable
                        isOptionDisabled={(option) => option.depth > 4}
                        testId="LedgerAccount-ParentAccount-Select"
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="col-md-12">
            <div className="hr" />
          </div>
          <div className="col-md-3">
            <FormLeftHeader title="Select Tax Group" icon="check-box-icon" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Select
                    name="taxGroup"
                    queryKey="tax-group"
                    placeholder="Select tax group"
                    value={state.taxGroup}
                    onChange={(value) => dispatch(setFormValue("taxGroup", value))}
                    optionsLoader={() => getTaxGroups()}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    isClearable
                    testId="LedgerAccount-TaxGroup-Select"
                  />
                </FormControl>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="hr" />
          </div>
          <div className="col-md-3">
            <FormLeftHeader title="Select Currency*" icon="check-box-icon" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Select
                    name="currencyCode"
                    queryKey="currency"
                    placeholder="Select currency"
                    value={state.currencyCode}
                    onChange={(value) => dispatch(setFormValue("currencyCode", value))}
                    optionsLoader={() => getCurrencies()}
                    dataAccessKey="data"
                    optionLabelKey="code"
                    optionValueKey="code"
                    isClearable
                    rules="required"
                    messages={validationMessages.required}
                    testId="LedgerAccount-CurrencyCode-Select"
                  />
                </FormControl>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="hr" />
          </div>
          <div className="col-md-3">
            <FormLeftHeader title="Account Balance Details" icon="frame-icon" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-3">
                <FormControl>
                  <Label htmlFor="balance" label="Balance" />
                  <CurrencyField
                    name="balance"
                    value={state.balance}
                    onChange={(value) => dispatch(setFormValue("balance", value))}
                    rules="required"
                    messages={validationMessages.required}
                    testId="LedgerAccount-Balance-Input"
                  />
                </FormControl>
              </div>
              <div className="col-md-3">
                <FormControl>
                  <Label htmlFor="balanceAsOfDate" label="Balance As Of Date" />
                  <CustomizedDatePicker
                    name="balanceAsOfDate"
                    value={state.balanceAsOfDate}
                    onChange={(value) => dispatch(setFormValue("balanceAsOfDate", value))}
                    required
                    testId="LedgerAccount-BalanceAsOfDate-DatePicker"
                  />
                </FormControl>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="hr" />
          </div>
          <div className="col-md-3">
            <FormLeftHeader title="Cost Centers" icon="frame-icon" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Select
                    name="department"
                    queryKey="organization"
                    placeholder="Select department"
                    value={state.department}
                    onChange={(value) => dispatch(setFormValue("department", value))}
                    optionsLoader={() =>
                      getDynamicObjectRecords(dynamicObjectMap.get("OrganizationObjectName"))}
                    dataAccessKey="data"
                    optionLabelKey="orgName"
                    optionValueKey="id"
                    isClearable
                    testId="LedgerAccount-Department-Select"
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Select
                    name="class"
                    queryKey="class"
                    placeholder="Select class"
                    value={state.department}
                    onChange={(value) => dispatch(setFormValue("class", value))}
                    optionsLoader={() =>
                      getDynamicObjectRecords(dynamicObjectMap.get("ClassObjectName"))}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    isClearable
                    testId="LedgerAccount-Class-Select"
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Select
                    name="location"
                    queryKey="location"
                    placeholder="Select location"
                    value={state.location}
                    onChange={(value) => dispatch(setFormValue("location", value))}
                    optionsLoader={() =>
                      getDynamicObjectRecords(dynamicObjectMap.get("LocationObjectName"))}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    isClearable
                    testId="LedgerAccount-Location-Select"
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Select
                    name="unit"
                    queryKey="unit"
                    placeholder="Select unit"
                    value={state.unit}
                    onChange={(value) => dispatch(setFormValue("unit", value))}
                    optionsLoader={() =>
                      getDynamicObjectRecords(dynamicObjectMap.get("UnitObjectName"))}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    isClearable
                    testId="LedgerAccount-Unit-Select"
                  />
                </FormControl>
              </div>
            </div>
          </div>
          {!id && (
            <>
              <div className="col-md-12">
                <div className="hr" />
              </div>
              <div className="col-md-3">
                <FormLeftHeader title="Create Ledger Account for all companies" icon="disc-icon" />
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-3">
                    <CheckboxWithIcon
                      id="multiCompany"
                      name="multiCompany"
                      label="Create for all companies"
                      value={state.multiCompany}
                      onChange={() => dispatch(setFormValue("multiCompany", !state.multiCompany))}
                      testId="GLAccount-MultiCompany-Checkbox"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="col-md-12">
            <div className="hr" />
          </div>
          <div className="col-md-3">
            <FormLeftHeader title="Freeze Ledger Account" icon="disc-icon" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-3">
                <CheckboxWithIcon
                  id="isFrozen"
                  name="isFrozen"
                  label="Is frozen"
                  value={state.isFrozen}
                  onChange={() => dispatch(setFormValue("isFrozen", !state.isFrozen))}
                  testId="GLAccount-IsFrozen-Checkbox"
                />
              </div>
            </div>
          </div>

          <div className="col">
            <div className="buttons-at-end">
              <Button bordered small onClick={handleCancel} testId="LedgerAccount-Cancel-Button">
                Cancel
              </Button>
              <Button
                small
                type="submit"
                className="save-btn"
                loading={saveMutation.isLoading || updateMutation.isLoading}
                testId="LedgerAccount-Submit-Button"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Form>
      <AlertModal
        title={`Are you sure you want to ${id ? "edit" : "create"} ledger account?`}
        subtitle={`This action will ${id ? "edit" : "create"} a ledger account`}
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={() => {
          handleSubmit();
          closeModal();
        }}
      />
    </BoxedContent>
  );
}
