import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, kebabCase } from "lodash";
import { useSearchParams } from "react-router-dom";
import { Button } from "@hydra/atom/components";
import { formatDate, formatDecimalValues } from "@/utils/helpers";
import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import {
  getDynamicObjectRecords,
  getDynamicObjectRecordById,
} from "@/api/dynamic/dynamicObjectNameApi";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import {
  getAMCInvoiceTableColumns,
  getPurchaseInvoiceTableColumns,
  getExpenseTableColumns,
} from "@/components/finance/account-receivables/tableWithCheckboxData";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";
import { getLedgerAccountById } from "@/api/finance/ledgerAccountApi";
import { useCompanyAccount, useTaskRedirect, useModal } from "@/hooks";

const formatReservation = (reservation) => {
  const {
    status, total, lead, id, number, building, component
  } = reservation;
  const data = {};

  if (status !== "ApprovedForRefund") {
    return data;
  }

  let account = null;
  let bankAccount = null;

  if (!isEmpty(component)) {
    data.component = {
      label: component.name,
      value: component.id
    };
  }

  if (!isEmpty(building.bankAccount)) {
    bankAccount = {
      label: building.bankAccount.name,
      value: building.bankAccount.id,
    };

    if (building.bankAccount.account && building.bankAccount.account?.name) {
      account = {
        label: building.bankAccount.account.name,
        value: building.bankAccount.account.key,
      };
    }
  }

  if (total > 0) {
    const paymentDetail = [
      {
        amount: total,
        memo: `Refund amount for ${number}`,
      },
    ];

    data.bankAccount = bankAccount;
    data.account = account;
    data.paymentMethod = {
      label: "Cheque",
      value: "Cheque",
    };
    data.paymentType = {
      label: "Refund",
      value: "Refund",
    };

    data.payee = {
      lookupObjectName: lead.objectName,
      label: lead.name,
      value: lead.id,
    };

    data.reservation = {
      label: number,
      value: id,
    };
    data.paymentDetail = paymentDetail;
    data.total = formatDecimalValues(Number(total));
    data.memo = `Refund amount for ${number}`;
  }

  return data;
};

const formatContractTermination = async (contractTermination) => {
  const {
    status, totalRefundAmount, contract, id, number, tenant
  } = contractTermination;
  const data = {};

  if (status === "Closed" || totalRefundAmount <= 0) {
    return data;
  }

  const { building, component } = contract;
  let account = null;
  let bankAccount = null;

  if (!isEmpty(component)) {
    data.component = {
      label: component.name,
      value: component.id
    };
  }

  if (!isEmpty(building.bankAccount)) {
    bankAccount = {
      label: building.bankAccount.name,
      value: building.bankAccount.id,
    };

    const buildingAccount = await getLedgerAccountById(building.bankAccount.account);
    if (buildingAccount.success) {
      account = {
        label: buildingAccount.data.name,
        value: buildingAccount.data.id,
      };
    }
  }

  if (totalRefundAmount > 0) {
    const paymentDetail = [
      {
        amount: totalRefundAmount,
        memo: `Refund amount for ${contract.number}`,
      },
    ];

    data.paymentType = {
      label: "Refund",
      value: "Refund",
    };

    data.bankAccount = bankAccount;
    data.account = account;
    data.paymentMethod = {
      label: "Cheque",
      value: "Cheque",
    };

    data.payee = {
      lookupObjectName: "Tenant",
      label: tenant.name,
      value: tenant.id,
    };

    data.contract = {
      label: contract.number,
      value: contract.id,
    };
    data.contractTermination = {
      label: number,
      value: id,
    };
    data.paymentDetail = paymentDetail;
    data.total = formatDecimalValues(Number(totalRefundAmount));
    data.memo = `Refund amount for ${contract.number}`;
  }

  return data;
};

const formatAMCInvoice = (invoice) => {
  const {
    id,
    number,
    status,
    dueDate,
    total,
    supplier,
    blanketAgreement,
    openBalance,
    supplierInvoiceNr,
    supplierInvoiceDate,
    invoiceReceivedDate,
  } = invoice;

  const data = {
    key: id,
    number,
    bankAccount: null,
    account: null,
    paymentMethod: {
      label: "Cheque",
      value: "Cheque",
    },
    paymentType: {
      label: "Invoice Payment",
      value: "InvoicePayment",
    },
    dueDate: formatDate(new Date(dueDate)),
    amcInvoice: [
      {
        label: number,
        value: id,
      },
    ],
    supplierInvoiceDate: supplierInvoiceDate ? formatDate(new Date(supplierInvoiceDate)) : "",
    invoiceReceivedDate: invoiceReceivedDate ? formatDate(new Date(invoiceReceivedDate)) : "",
    supplierInvoiceNr,
    amcInvoiceStatus: status,
    total: formatDecimalValues(Number(total)),
    openBalance: formatDecimalValues(Number(openBalance)),
    memo: `Payment for AMC Agreement (${blanketAgreement.number}) to Supplier (${supplier.number}) for Invoice ${number}`,
  };

  data.paymentDetail = [
    {
      paymentFor: {
        lookupObjectName: "AmcInvoice",
        label: number,
        value: id,
      },
      amount: formatDecimalValues(Number(openBalance)),
      memo: `Payment for AMC Agreement (${blanketAgreement.number}) to Supplier (${supplier.number}) for Invoice ${number}`,
      invoiceNumber: supplierInvoiceNr,
      invoiceDate: supplierInvoiceDate
    },
  ];

  return data;
};

const formatPurchaseInvoice = (invoice) => {
  const {
    id, number, supplier, status, dueDate, openBalance
  } = invoice;

  const data = {
    key: id,
    number,
    purchaseInvoice: [
      {
        label: number,
        value: id,
      },
    ],
    paymentType: {
      label: "Invoice Payment",
      value: "InvoicePayment",
    },
    payee: {
      lookupObjectName: "Supplier",
      label: supplier.name,
      value: supplier.id,
      number: supplier.number,
    },
    paymentDetail: [
      {
        amount: openBalance,
        memo: `Payment to Supplier (${supplier.number}) for Purchase Invoice (${number}) `,
        paymentId: "",
        paymentFor: {
          lookupObjectName: "PurchaseInvoice",
          label: number,
          value: id,
        },
      },
    ],
    memo: `Payment to Supplier (${supplier.number}) for Purchase Invoice (${number}) `,
    total: formatDecimalValues(Number(openBalance)),
    dueDate: formatDate(new Date(dueDate)),
    purchaseInvoiceStatus: status,
    bankAccount: null,
    account: null,
    paymentMethod: {
      label: "Cheque",
      value: "Cheque",
    },
    openBalance: formatDecimalValues(Number(openBalance)),
  };

  return data;
};

const formatExpense = (expense) => {
  const {
    id, number, vendor, status, dueDate, openBalance, date, invoiceNr
  } = expense;
  const data = {
    key: id,
    number,
    expense: [
      {
        label: number,
        value: id,
      },
    ],
    paymentType: {
      label: "Invoice Payment",
      value: "InvoicePayment",
    },
    payee: {
      lookupObjectName: vendor.objectName,
      label: vendor.name,
      value: vendor.id,
      number: vendor.number,
    },
    dueDate: formatDate(new Date(dueDate)),
    expenseStatus: status,
    bankAccount: null,
    account: null,
    paymentMethod: {
      label: "Cheque",
      value: "Cheque",
    },
    paymentDetail: [
      {
        amount: openBalance,
        memo: `Payment to Supplier (${vendor.number}) for Expense (${number}) `,
        paymentId: "",
        paymentFor: {
          lookupObjectName: "Expense",
          label: number,
          value: id,
        },
        invoiceNumber: invoiceNr,
        invoiceDate: date
      },
    ],
    memo: `Payment to Supplier (${vendor.number}) for Expense (${number}) `,
    total: formatDecimalValues(Number(openBalance)),
    openBalance: formatDecimalValues(Number(openBalance)),
    invoiceNumber: invoiceNr,
    invoiceDate: formatDate(new Date(date))
  };

  return data;
};

const combineInvoices = (amcInvoices, purchaseInvoices, expenses) => {
  const data = {
    expense: [],
    purchaseInvoice: [],
    amcInvoice: [],
    paymentDetail: [],
    total: 0,
  };

  amcInvoices.forEach((invoice) => {
    const { amcInvoice, paymentDetail, openBalance } = invoice;

    data.amcInvoice = data.amcInvoice.concat(amcInvoice);
    data.paymentDetail = data.paymentDetail.concat(paymentDetail);
    data.total += Number(openBalance);
  });

  const amcInvoiceNumbers = amcInvoices.map((i) => i.number).join(", ");

  purchaseInvoices.forEach((invoice) => {
    const { purchaseInvoice, paymentDetail, openBalance } = invoice;

    data.purchaseInvoice = data.purchaseInvoice.concat(purchaseInvoice);
    data.paymentDetail = data.paymentDetail.concat(paymentDetail);
    data.total += Number(openBalance);
  });

  const purchaseInvoiceNumbers = purchaseInvoices.map((i) => i.number).join(", ");

  expenses.forEach((expenseDetail) => {
    const { expense, paymentDetail, openBalance } = expenseDetail;

    data.expense = data.expense.concat(expense);
    data.paymentDetail = data.paymentDetail.concat(paymentDetail);
    data.total += Number(openBalance);
  });

  const expenseNumbers = expenses.map((i) => i.number).join(", ");

  data.memo = `Payment for ${amcInvoiceNumbers} ${purchaseInvoiceNumbers} ${expenseNumbers}`;

  return data;
};

function HeaderRightContent({ openModal, showButton, ...rest }) {
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {showButton && (
        <Button small bordered onClick={openModal}>
          Select liabilities
        </Button>
      )}
    </defaultComponents.HeaderRightContent>
  );
}

function PaymentForm() {
  const ref = useRef(null);
  const [initialState, setInitialState] = useState({});
  const [state, setState] = useState({});
  const [amcInvoicesTableData, setAMCInvoicesTableData] = useState([]);
  const [purchaseInvoiceTableData, setPurchaseInvoiceTableData] = useState([]);
  const [expenseTableData, setExpenseTableData] = useState([]);
  const { isOpen, closeModal, openModal } = useModal(false);
  const [searchParams] = useSearchParams();
  const contractTermination = searchParams.get("contractTermination");
  const reservation = searchParams.get("reservation");
  const fixedAssetCreationRequest = searchParams.get("fixedAssetCreationRequest");
  const purchaseInvoice = searchParams.get("purchaseInvoice");
  const { redirect } = useTaskRedirect();
  const defaultAccounts = useCompanyAccount({
    params: {
      includeCompanyId: true,
      isLinkedWithRecord: false,
    },
  });

  const { data: amcInvoicesData } = useQuery(
    [kebabCase(dynamicObjectMap.get("AmcInvoiceObjectName")), state?.supplier?.value],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("AmcInvoiceObjectName")), {
        supplier: state?.supplier?.value,
        "status[in]": "Partial,Approved",
        queryMode: "Deep",
      }),
    {
      enabled: Boolean(state?.supplier?.value),
    }
  );

  const { data: purchaseInvoicesData } = useQuery(
    [kebabCase(dynamicObjectMap.get("PurchaseInvoiceObjectName")), state?.supplier?.value],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("PurchaseInvoiceObjectName")), {
        supplier: state?.supplier?.value,
        "status[in]": "Partial,Approved",
        holdInvoice: false,
        queryMode: "Deep",
      }),
    {
      enabled: Boolean(state?.supplier?.value),
    }
  );

  const { data: expensesData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ExpenseObjectName")), state?.supplier?.value],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("ExpenseObjectName")), {
        vendor: state?.supplier?.value,
        "status[in]": "Partial,Approved",
        queryMode: "Deep",
      }),
    {
      enabled: Boolean(state?.supplier?.value),
    }
  );

  const handleOpenModal = () => {
    const formState = ref.current.getState();
    const { paymentType } = formState;

    if (paymentType && paymentType?.value === "InvoicePayment") {
      openModal();
    }
  };

  useEffect(() => {
    if (purchaseInvoicesData && purchaseInvoicesData?.data && state?.supplier) {
      const formattedPaymentInvoices = purchaseInvoicesData.data.map((invoice) =>
        formatPurchaseInvoice(invoice)
      );
      if (purchaseInvoicesData?.data?.length) {
        setPurchaseInvoiceTableData(formattedPaymentInvoices);
      } else {
        setPurchaseInvoiceTableData([]);
      }
      handleOpenModal();
    }
  }, [purchaseInvoicesData, state?.supplier?.value]);

  useEffect(() => {
    if (amcInvoicesData && amcInvoicesData?.data && state?.supplier) {
      const formattedAMCInvoices = amcInvoicesData.data.map((invoice) => formatAMCInvoice(invoice));
      if (amcInvoicesData?.data?.length) {
        setAMCInvoicesTableData(formattedAMCInvoices);
      } else {
        setAMCInvoicesTableData([]);
      }
      handleOpenModal();
    }
  }, [amcInvoicesData, state?.supplier?.value]);

  useEffect(() => {
    if (expensesData && expensesData?.data && state?.supplier) {
      const formattedExpenses = expensesData.data.map((expense) => formatExpense(expense));
      if (expensesData?.data?.length) {
        setExpenseTableData(formattedExpenses);
      } else {
        setExpenseTableData([]);
      }
      handleOpenModal();
    }
  }, [expensesData, state?.supplier?.value]);

  const { data: contractTerminationData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ContractTerminationObjectName")), contractTermination],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("ContractTerminationObjectName")),
        contractTermination
      ),
    {
      enabled: Boolean(contractTermination),
    }
  );

  useEffect(() => {
    (async () => {
      if (contractTerminationData) {
        const formattedContractTermination = await formatContractTermination(
          contractTerminationData
        );
        setInitialState(formattedContractTermination);
      }
    })();
  }, [contractTerminationData]);

  const { data: reservationData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ReservationObjectName")), reservation],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("ReservationObjectName")),
        reservation
      ),
    {
      enabled: Boolean(reservation),
    }
  );

  useEffect(() => {
    if (reservationData) {
      const formattedReservation = formatReservation(reservationData);
      setInitialState(formattedReservation);
    }
  }, [reservationData]);

  const { data: fixedAssetRequestData } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("FixedAssetCreationRequestObjectName")),
      fixedAssetCreationRequest,
    ],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("FixedAssetCreationRequestObjectName")),
        fixedAssetCreationRequest
      ),
    {
      enabled: Boolean(fixedAssetCreationRequest),
    }
  );

  const { data: purchaseInvoiceData } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("PurchaseInvoiceObjectName")),
      state?.purchaseInvoice?.value || purchaseInvoice,
    ],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("PurchaseInvoiceObjectName")),
        state?.purchaseInvoice?.value || purchaseInvoice
      ),
    {
      enabled: Boolean(state?.purchaseInvoice?.value || purchaseInvoice),
    }
  );

  useEffect(() => {
    if (fixedAssetRequestData) {
      const selectedPurchaseInvoice = {
        label: fixedAssetRequestData.purchaseInvoice.number,
        value: fixedAssetRequestData.purchaseInvoice.id,
      };
      setState((prevState) => ({
        ...prevState,
        purchaseInvoice: selectedPurchaseInvoice,
      }));
    }
  }, [fixedAssetRequestData]);

  useEffect(() => {
    if (purchaseInvoiceData) {
      const formattedPurchaseInvoice = formatPurchaseInvoice(purchaseInvoiceData);
      setInitialState(formattedPurchaseInvoice);
    }
  }, [purchaseInvoiceData]);

  const onStateChange = (key, value) => {
    switch (key) {
      case "payee":
        if (value.lookupObjectName === dynamicObjectMap.get("TenantObjectName")) {
          setState({
            ...state,
            supplier: null,
            tenant: value,
          });
        }

        if (value.lookupObjectName === dynamicObjectMap.get("SupplierObjectName")) {
          setState({
            ...state,
            tenant: null,
            supplier: value,
          });
        }
        break;

      case "paymentMethod": {
        if (value?.value === "PDC" && defaultAccounts) {
          const { pdcOnHand } = defaultAccounts;
          ref.current.setFormValue("account", pdcOnHand);
        }
        break;
      }

      case "paymentDetail": {
        if (value && value.length) {
          const totalAmount = value.reduce(
            (total, currentValue) => total + Number(currentValue.amount),
            0
          );
          ref.current.setFormValue("total", totalAmount);
        }
        break;
      }

      case "bankAccount": {
        if (!value) {
          ref.current.setFormValue("account", null);
        } else {
          const { account } = value;
          if (account) {
            ref.current.setFormValue("account", {
              label: account?.name,
              value: account?.id
            });
          }
        }
        break;
      }

      default:
        break;
    }
  };

  const handleConfirm = () => {
    const selectedAMCInvoices = amcInvoicesTableData.filter((r) => r.isSelected);
    const selectedPurchaseInvoices = purchaseInvoiceTableData.filter((r) => r.isSelected);
    const selectedExpenses = expenseTableData.filter((r) => r.isSelected);
    const combinedInvoices = combineInvoices(
      selectedAMCInvoices,
      selectedPurchaseInvoices,
      selectedExpenses
    );
    ref.current.setFormState(combinedInvoices);

    closeModal();
  };

  const handleSuccess = (id) => {
    redirect(-1, {
      recordId: id,
      success: true,
    });
  };

  const handleAMCChange = (e) => {
    setAMCInvoicesTableData(e);
  };

  const handlePurchaseInvoiceChange = (e) => {
    setPurchaseInvoiceTableData(e);
  };

  const handleExpenseChange = (e) => {
    setExpenseTableData(e);
  };
  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Invoices"
        subtitle="Selected supplier has following open invoices"
        isOpen={isOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
        size="large"
      >
        {amcInvoicesTableData.length ? (
          <TableWithCheckbox
            data={amcInvoicesTableData}
            setData={(e) => handleAMCChange(e)}
            columns={getAMCInvoiceTableColumns()}
            allowMultiple
          />
        ) : null}
        {purchaseInvoiceTableData.length ? (
          <TableWithCheckbox
            data={purchaseInvoiceTableData}
            setData={(e) => handlePurchaseInvoiceChange(e)}
            columns={getPurchaseInvoiceTableColumns()}
            allowMultiple
          />
        ) : null}
        {expenseTableData.length ? (
          <TableWithCheckbox
            data={expenseTableData}
            setData={(e) => handleExpenseChange(e)}
            columns={getExpenseTableColumns()}
            allowMultiple
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        initialData={initialState}
        objectName={dynamicObjectMap.get("PaymentObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        onSuccess={handleSuccess}
        navigate={false}
        components={{
          HeaderRightContent: (props) =>
            HeaderRightContent({
              openModal: handleOpenModal,
              showButton: Boolean(
                amcInvoicesTableData.length ||
                  purchaseInvoiceTableData.length ||
                  expenseTableData.length
              ),
              ...props,
            }),
        }}
      />
    </BoxedContent>
  );
}

export default PaymentForm;
