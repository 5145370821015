import { useState, useMemo, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Form, Button, Input, Loader
} from "@hydra/atom/components";
import PropTypes from "prop-types";
import { kebabCase, startCase } from "lodash";
import pluralize from "pluralize";
import { pascalize } from "humps";
import { Modal, SvgIcon, IconButton } from "@/components/common";
import { ModalHeader } from "@/components/modals";
import { ListWithCheckbox, ListWithCards } from "@/components/facility/planboard";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { getDynamicObjectByName } from "@/api/dynamic/dynamicObjectSchemaApi";
import { LookupField, SelectField } from "@/components/dynamic/fields";
import { useDebounce, useIsMobile } from "@/hooks";
import { getTestId } from "@/utils/helpers";

function getTextBetweenPercentSigns(str) {
  const matches = str.match(/%([^%]+)%/);
  if (matches && matches.length > 1) {
    return matches[1];
  }
  return null;
}

export default function MultiLevelSearchModal({
  onSubmit,
  isOpen,
  objectName,
  objectLabel,
  parentObjectName,
  childObjectName,
  filters,
  multiSelect,
  filterOption,
  setOpenModal,
  selectedParentValue,
  selectedChildValue: childValue,
}) {
  const [search, setSearch] = useState("");
  const [childSearch] = useState("");
  const [mobileStep, setMobileStep] = useState(1);
  const debouncedSearch = useDebounce(search, 500);
  const debouncedChildSearch = useDebounce(childSearch, 500);
  const [apiFilters, setApiFilters] = useState({});
  const [showFilters, setShowFilters] = useState(true);
  const [, setErrorMessage] = useState("");
  const [recordList, setRecordList] = useState([]);
  const [childRecordList, setChildRecordList] = useState([]);
  const [quantity] = useState(1);
  const isMobileView = useIsMobile(992);
  const [selectedRecordList, setSelectedRecordList] = useState([]);
  const [selectedChildRecordList, setSelectedChildRecordList] = useState([]);
  const [selectedChildValue, setSelectedChildValue] = useState(childValue);
  const [selectedParentObject, setSelectedParentObject] = useState(selectedParentValue);
  const { data: objectSchema, isInitialLoading } = useQuery(
    ["dynamic-object-schema", kebabCase(objectName)],
    () => getDynamicObjectByName(objectName),
    {
      enabled: isOpen,
    }
  );

  const { data: childObjectSchema } = useQuery(
    ["dynamic-object-schema", kebabCase(childObjectName)],
    () => getDynamicObjectByName(childObjectName),
    {
      enabled: isOpen,
    }
  );
  const searchFieldName = useMemo(
    () =>
      objectSchema?.document.filter((f) => f.searchField === true)?.[0]?.camelizedName || "name",
    [objectSchema]
  );

  const childSearchFieldName = useMemo(
    () =>
      childObjectSchema?.document.filter((f) => f.searchField === true)?.[0]?.camelizedName ||
      "name",
    [childObjectSchema]
  );

  const formatApiFilters = (isChild = false) => {
    const finalObj = {
      takePage: 1,
      limitPage: 20,
      ...filters,
    };
    if (getTextBetweenPercentSigns(filters["name[like]"])) {
      const text = getTextBetweenPercentSigns(filters["name[like]"]);
      setSearch(text);
    }
    const searchValue = isChild ? childSearch : search;
    const fieldName = isChild ? childSearchFieldName : searchFieldName;
    if (searchValue && searchValue !== "") {
      finalObj[`${fieldName}[like]`] = `'%${searchValue}%'`;
    }
    if (selectedParentObject) {
      finalObj[`${fieldName}[like]`] = `'%${selectedParentObject.name}%'`;
      setSearch(selectedParentObject.name);
      setSelectedParentObject(null);
    }

    for (const key in apiFilters) {
      if (apiFilters[key] !== "" && apiFilters[key] !== null) {
        finalObj[key] = apiFilters[key].value || apiFilters[key];
      }
    }
    return finalObj;
  };

  const { data, isInitialLoading: isLoading } = useQuery(
    ["getDynamicObjectRecords", parentObjectName, objectLabel, debouncedSearch, apiFilters],
    () => getDynamicObjectRecords(parentObjectName, formatApiFilters(false)),
    {
      enabled: isOpen,
    }
  );
  const { data: childData, isInitialLoading: isChildLoading } = useQuery(
    [
      "getDynamicObjectRecords",
      childObjectName,
      objectLabel,
      debouncedChildSearch,
      apiFilters,
      selectedRecordList,
    ],
    () =>
      getDynamicObjectRecords(childObjectName, {
        category: selectedRecordList[0]?.id,
      }),
    {
      enabled: isOpen,
    }
  );
  useEffect(() => {
    if (typeof filterOption === "object") {
      const filteredData = (
        data?.data.filter((item) => !filterOption.some((filter) => filter.value === item.id)) ?? []
      ).map((item) => ({
        ...item,
        isSelected: item.id === selectedParentValue?.id,
      }));
      setRecordList(filteredData);
      if (selectedParentValue) {
        setSelectedRecordList([selectedParentValue]);
      } else {
        setSelectedRecordList([]);
        setSelectedChildRecordList([]);
        setChildRecordList([]);
      }
    }
  }, [data, filterOption]);

  useEffect(() => {
    if (typeof filterOption === "object") {
      setChildRecordList(
        (
          childData?.data.filter(
            (item) => !filterOption.some((filter) => filter.value === item.id)
          ) ?? []
        ).map((item) => ({
          ...item,
          isSelected: item.id === selectedChildValue?.id,
        }))
      );
    }
  }, [childData, filterOption]);

  useEffect(() => {
    setSelectedChildRecordList(childValue ? [childValue] : []);
    setSelectedChildValue(childValue);
  }, [childValue]);

  useEffect(() => {
    setSelectedParentObject(selectedParentValue);
  }, [selectedParentValue]);

  const filteredFields = useMemo(
    () =>
      objectSchema?.document.filter((f) => f.searchFilter === true && f.searchField === false) ||
      [],
    [objectSchema]
  );

  const handleSubmit = () => {
    setOpenModal(false);
    setSelectedRecordList([]);
    setSelectedChildRecordList([]);
    setMobileStep(1);
    onSubmit(selectedRecordList, selectedChildRecordList);
  };

  const handlePrevious = () => {
    setSelectedRecordList([]);
    setOpenModal(false);
    setMobileStep(1);
    setSearch("");
  };

  const onChangesetAssetList = (index, isSelected) => {
    setErrorMessage();
    if (isSelected) {
      setRecordList([...recordList, selectedRecordList[index]]);
      const tempList = selectedRecordList;
      tempList.splice(index, 1);
      if (!multiSelect) {
        setSelectedRecordList([]);
      } else {
        setSelectedRecordList(tempList);
      }
    } else {
      if (!multiSelect) {
        setSelectedRecordList([recordList[index]]);
      } else {
        setSelectedRecordList([...selectedRecordList, recordList[index]]);
      }
      const tempList = recordList;
      tempList.splice(index, 1);
      if (selectedRecordList.length > 0) {
        tempList.push(selectedRecordList[0]);
      }
      setRecordList(tempList);
    }
  };
  const handleListChange = (index, item, list, setList, setSelectedList) => {
    const tempRecordList = list.map((listItem, i) => ({
      ...listItem,
      isSelected: i === index,
    }));

    setList(tempRecordList);
    setSelectedList(tempRecordList.filter((listItem) => listItem.isSelected));
  };

  const onListChange = (index, item) => {
    setSelectedChildRecordList([]);
    handleListChange(index, item, recordList, setRecordList, setSelectedRecordList);
  };

  const onChildListChange = (index, item) => {
    handleListChange(index, item, childRecordList, setChildRecordList, setSelectedChildRecordList);
  };

  const renderView = (fieldArray) =>
    fieldArray.map((field) => {
      switch (field.objectFieldType.toLowerCase()) {
        case "select":
          return (
            <SelectField
              key={field.name}
              name={field.name}
              field={field}
              value={apiFilters[field.name]}
              onChange={(value) => {
                setApiFilters({ ...apiFilters, [field.name]: value });
              }}
            />
          );

        case "lookup": {
          return (
            <LookupField
              key={field.name}
              name={field.name}
              field={field}
              value={apiFilters[field.name]}
              onChange={(value) => {
                setApiFilters({ ...apiFilters, [field.name]: value });
              }}
            />
          );
        }

        default:
          return (
            <div key={field.name}>
              {field.objectFieldType}
              {" "}
              {field.name}
            </div>
          );
      }
    });

  const renderListView = () =>
    // eslint-disable-next-line no-nested-ternary
    (recordList.length > 0 || selectedRecordList.length > 0 ? (
      objectName === "Item" ? (
        <ListWithCheckbox list={recordList} onChange={onChangesetAssetList} selected={false} />
      ) : (
        <ListWithCards list={recordList} onChange={onListChange} />
      )
    ) : (
      <div className="no-list-item">
        <div className="center">
          <SvgIcon icon="question" />
          <h1 className="text">{`No ${pluralize.singular(objectLabel)} Available`}</h1>
        </div>
      </div>
    ));

  const renderChildListView = () =>
    // eslint-disable-next-line no-nested-ternary
    (childRecordList.length > 0 || selectedChildRecordList.length > 0 ? (
      objectName === "Item" ? (
        <ListWithCheckbox list={recordList} onChange={onChangesetAssetList} selected={false} />
      ) : (
        <ListWithCards list={childRecordList} onChange={onChildListChange} />
      )
    ) : (
      <div className="no-list-item">
        <div className="center">
          <SvgIcon icon="question" />
          <h1 className="text">{`No ${pluralize.singular(childObjectName)} Available`}</h1>
        </div>
      </div>
    ));

  const onSave = () => {
    if (isMobileView && mobileStep === 1) {
      return setMobileStep(2);
    }
    if (isMobileView && mobileStep === 2) {
      if (selectedRecordList.length === 0) {
        setErrorMessage("Please select an item.");
        return;
      }
      if (filters.itemType === "Service") {
        return setMobileStep(3);
      }
    }
    if (mobileStep === 3) {
      if (!quantity) {
        setErrorMessage("This field is required.");
        return;
      }
    }
    handleSubmit();
  };

  if (!isOpen) return null;

  return (
    <Modal
      rootClassName="center-vertically add-asset-modal core-modal-content-right"
      isOpen={isOpen}
      onClose={handlePrevious}
      showCloseIcon={!isMobileView}
    >
      {isInitialLoading ? (
        <Loader />
      ) : (
        <div className="row">
          {!isMobileView && (
            <div className="main-container">
              <div className="primary-right-border">
                <div className="col-md-12 core-modal-content">
                  <ModalHeader heading={`Search ${pascalize(parentObjectName)}`} />
                  <Form className="modal-form add-work-order-job-step">
                    <div className="row">
                      <h1 className="filter-title">Search</h1>
                      <Input
                        className="search-bar-container"
                        placeholder="Search"
                        value={search}
                        onChange={setSearch}
                      >
                        <Input.PrependIcon>
                          <SvgIcon icon="search" />
                        </Input.PrependIcon>
                      </Input>
                      {filteredFields?.length > 0 && (
                        <div className="d-flex additional-filters">
                          <IconButton
                            onClick={() => {
                              setShowFilters(!showFilters);
                            }}
                          >
                            <SvgIcon icon={`${showFilters ? "minus-circle" : "plus-circle"} `} />
                          </IconButton>
                          Additional Filters
                        </div>
                      )}
                      {showFilters ? (
                        <div>
                          <div>{renderView(filteredFields)}</div>
                        </div>
                      ) : null}
                    </div>
                  </Form>
                </div>
                <div className="core-modal-content col-md-12">
                  <ModalHeader heading={`Available ${parentObjectName}`} />
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div className="asset-list-container parent-list-container">
                      {renderListView()}
                    </div>
                  )}
                </div>
              </div>
              {selectedRecordList.length > 0 ? (
                <div className="child-col">
                  <div className="core-modal-content col-md-12">
                    <ModalHeader heading={`Available ${startCase(pascalize(childObjectName))}`} />
                    {isChildLoading ? (
                      <Loader />
                    ) : (
                      <div className="asset-list-container child-list-container">
                        {renderChildListView()}
                      </div>
                    )}
                    {!isLoading && !isChildLoading && (
                      <div className="d-flex justify-content-end save-button-container">
                        <Button
                          className={` ${
                            !selectedRecordList.length > 0 || !selectedChildRecordList.length > 0 ?
                              "" :
                              "btn-primary"
                          } `}
                          bordered
                          small
                          loading={false}
                          onClick={onSave}
                          testId={getTestId("Save-Button")}
                          disabled={
                            !selectedRecordList.length > 0 || !selectedChildRecordList.length > 0
                          }
                        >
                          Select
                        </Button>
                        <Button
                          bordered
                          small
                          onClick={handlePrevious}
                          testId={getTestId("Cancel-Button")}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="no-list-item no-child-data">
                  <div className="center">
                    <SvgIcon icon="question" />
                    <h1 className="text">
                      {`No ${startCase(pascalize(childObjectName))} Available`}
                    </h1>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Modal>
  );
}

MultiLevelSearchModal.propTypes = {
  multiSelect: PropTypes.bool,
};

MultiLevelSearchModal.defaultProps = {
  multiSelect: false,
};
