import {
  useEffect,
  useRef,
  useState
} from "react";

import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { useCompanyAccount, useTaskRedirect } from "@/hooks";

function CustomerForm() {
  const ref = useRef(null);
  const [initialState, setInitialState] = useState({});
  const defaultAccounts = useCompanyAccount({
    params: {
      includeCompanyId: true,
      isLinkedWithRecord: false
    }
  });
  const { redirect } = useTaskRedirect();

  useEffect(() => {
    if (defaultAccounts && !initialState.areAccountsSelected) {
      const { customerReceivable } = defaultAccounts;

      setInitialState((prevState) => ({
        ...prevState,
        areAccountsSelected: true,
        receivableAccount: customerReceivable,
      }));
    }
  }, [defaultAccounts]);

  const onSuccess = (recordId) => {
    redirect(-1, {
      recordId,
      success: true,
    });
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        initialData={initialState}
        objectName={dynamicObjectMap.get("CustomerObjectName")}
        showHeader
        navigate={false}
        onSuccess={onSuccess}
      />
    </BoxedContent>
  );
}

export default CustomerForm;
