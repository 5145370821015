import { useEffect } from "react";
import PropTypes from "prop-types";

import { ReactSelect } from "@hydra/atom/components";

import countryNames from "react-phone-number-input/locale/en.json";

import { getCountries } from "react-phone-number-input/input";

const countries = getCountries();

const options = countries.map((countryInitials) => ({
  label: countryNames[countryInitials],
  value: countryInitials,
  icon: `https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryInitials}.svg`
})).sort((a, b) => a.label.localeCompare(b.label));

function IndicatorSeparator() {
  return null;
}

function ValueContainer({ children, ...props }) {
  return (
    <div className="value-container">
      <img loading="lazy" className="country-flag" src={props.getValue()[0]?.icon} alt="" />
      {children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.object.isRequired,
  getValue: PropTypes.func.isRequired
};

function CountrySelector({ autoFocus, value, onChange }) {
  useEffect(() => {
    if (!value) {
      onChange({
        label: countryNames[value.value],
        value: value.value,
        icon: `https://purecatamphetamine.github.io/country-flag-icons/3x2/${value.value}.svg`
      });
    }
  }, []);

  return (
    <ReactSelect
      id="select-country"
      name="select-country"
      rules="required"
      className="country-selector-container"
      components={{ ValueContainer, IndicatorSeparator }}
      value={value}
      onChange={onChange}
      autoFocus={autoFocus}
      options={options}
    />
  );
}

CountrySelector.propTypes = {
  autoFocus: PropTypes.bool,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

CountrySelector.defaultProps = {
  autoFocus: false,
};

export default CountrySelector;
