import request from "@/utils/api/helpers";

const baseUrl = "/dynamic-objects/user-management";
const identityUrl = "/identity/user/reset-password";

export const getUserManagement = () => request({
  url: baseUrl
});

export const getUserManagementById = (id) => request({
  url: `${baseUrl}/${id}`
});

export const passwordResetById = (id) => request({
  url: `${identityUrl}/${id}`
});

export const updateUserManagementById = (data) => request({
  url: `${baseUrl}`,
  method: "PUT",
  data
});
