import qs from "qs";
import request from "@/utils/api/helpers";

const baseUrl = "/notifications";

export function generateEmail(data) {
  return request({
    url: `${baseUrl}/flow-notifications/generate-email`,
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    data
  });
}

export function registerUserDevice(data) {
  return request({
    url: `${baseUrl}/push-clients`,
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    data
  });
}

export function getNotifications(params) {
  const apiParams = { ...params };

  if (!apiParams.page) {
    apiParams.page = 1;
  }

  if (!apiParams.pageSize) {
    apiParams.pageSize = 10;
  }

  const queryParams = qs.stringify(apiParams, { addQueryPrefix: true });

  return request({
    url: `${baseUrl}/app-notifications${queryParams}`,
  });
}

export function setNotificationStatusBulk(data) {
  return request({
    url: `${baseUrl}/app-notifications/set-status-bulk`,
    method: "PATCH",
    data
  });
}

export function getUnreadNotificationsCount() {
  return request({
    url: `${baseUrl}/app-notifications/unread-notifications-count`,
    method: "GET"
  });
}
