import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { useQuery } from "@tanstack/react-query";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { DynamicFormContainer } from "@/components/dynamic";
import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";

const formatContract = (data) => ({
  contract: {
    building: data.building,
    unit: {
      name: data.unit.map((u) => u.name).join(", ")
    },
    label: data.number,
    value: data.id
  },
  tenant: {
    ...data.tenant,
    label: data.tenant.name,
    value: data.tenant.id
  },
  tawtheeqContractNumber: data.number,
  tcDate: new Date(),
  clearanceDate: new Date(),
  comments: "Move In Request for Contract CONT-24-0012"
});

const getContractId = (steps, task) => {
  if (task.objectName !== dynamicObjectMap.get("MoveInRequestObjectName")) {
    return "";
  }

  const contractId = steps[
    steps.findIndex(
      (step) => step.step === "Upload Sign Copy"
    )
  ].recordId;

  return contractId;
};

function MoveInWorkflowFormContainer({
  task, setIsLoading, steps, onSuccess
}) {
  const [initialState, setInitialState] = useState(null);

  const contractId = getContractId(steps, task);

  const { data } = useQuery(
    [kebabCase(dynamicObjectMap.get("ContractObjectName")), contractId],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("ContractObjectName"), contractId),
    {
      enabled: Boolean(task?.objectName === dynamicObjectMap.get("MoveInRequestObjectName")),
    }
  );

  useEffect(() => {
    if (data) {
      const formattedContract = formatContract(data);
      setInitialState(formattedContract);
    }
  }, [data]);

  const renderForm = () => {
    if (
      task?.objectName === dynamicObjectMap.get("MoveInRequestObjectName")
    ) {
      return (
        <DynamicFormContainer
          initialData={initialState}
          objectName={task.objectName}
          setIsLoading={setIsLoading}
          navigate={false}
          showButtons={false}
          onSuccess={onSuccess}
        />
      );
    }

    return null;
  };

  return <div>{renderForm()}</div>;
}

MoveInWorkflowFormContainer.propTypes = {
  task: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
};

export default MoveInWorkflowFormContainer;
