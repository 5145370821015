import {
  Button, Form, Radio, ReactSelect
} from "@hydra/atom/components";
import { kebabCase } from "lodash";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  FormLeftHeader, Select,
} from "@/components/common";
import { setFormValue } from "@/reducers/admin/userManagementReducer";
import { userType } from "@/utils/admin/constants";
import { updateUserManagementById, passwordResetById } from "@/api/admin/userManagementApi";
import showToast from "@/utils/toast/helpers";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

function UserManagementForm({ state, dispatch }) {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(0);
  const [employeeId, setEmployeeId] = useState();

  const { data: passwordResetData, isInitialLoading } = useQuery(
    ["password-reset-by-id", employeeId],
    () => passwordResetById(employeeId),
    {
      enabled: employeeId !== undefined,

    }
  );

  const handlePasswordReset = () => {
    if (timer === 0 && state?.employeeId?.value) {
      showToast("Reset Password Email has been sent to user sucesssfully", "success");
      setTimer(15);
      setEmployeeId(state.employeeId.value);
    }
  };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const updateMutation = useMutation(
    ({ data: recordData }) => updateUserManagementById(recordData),
    {
      onError: () => {
        showToast("Could not update. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Updated successfully", "success");
        navigate(-1);
      },
    }
  );

  const handleSubmit = () => {
    const data = {
      defaultModule:
      kebabCase(state.defaultModule.value),
      employeeId:
        state.employeeId.value,
      isUserEnabled:
      state.isUserEnabled,
      roles:
      state.roles.map((item) => item.label),
      userAccessTypes:
      state.userAccessTypes.map((item) => item.value),
    };

    updateMutation.mutate({
      data
    });

  };

  return (
    <section className="general-setting-body">
      <Form onSubmit={() => {}} shouldScrollOnError>
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="List of Employee" subtitle="This will be displayed employees." />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <Select
                  name="employeeId"
                  queryKey="employees"
                  placeholder="Select Employee"
                  value={state.employeeId}
                  onChange={(value) => dispatch(setFormValue("employeeId", value))}
                  optionsLoader={() => getDynamicObjectRecords(dynamicObjectMap.get("EmployeeObjectName"))}
                  apiFilters={{
                    status: "Active"
                  }}
                  dataAccessKey="data"
                  optionLabelKey="name"
                  optionValueKey="id"
                  isClearable
                  isDisabled
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="List of Roles" subtitle="This will be displayed roles." />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <ReactSelect
                  name="role"
                  placeholder="Select Roles"
                  options={state.roleOptions}
                  isMulti
                  value={state.roles}
                  onChange={(value) => dispatch(setFormValue("roles", value))}
                  multi
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Default Module"
              subtitle="This will be displayed default module."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <ReactSelect
                  name="module"
                  placeholder="Select Module"
                  options={state.moduleOptions}
                  value={state.defaultModule}
                  onChange={(value) => dispatch(setFormValue("defaultModule", value))}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader title="User Type" subtitle="This will be displayed user type." />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <ReactSelect
                  name="userType"
                  placeholder="Select User Type"
                  options={userType}
                  value={state.userAccessTypes}
                  onChange={(value) => dispatch(setFormValue("userAccessTypes", value))}
                  isMulti
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Enable or Disable User"
              subtitle="Choose to Enable or Disable User Accounts"
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4">
                <div
                  className={state.multiLanguageSupport ? "form-radio-card-primary" : "card"}
                  role="presentation"
                  onClick={() => dispatch(setFormValue("multiLanguageSupport", true))}
                >
                  <Radio
                    className="radio-text"
                    label="Yes"
                    itemValue
                    type="radio"
                    value={state.isUserEnabled}
                    onChange={() => dispatch(setFormValue("isUserEnabled", true))}
                  >
                    <Radio.CheckIcon>
                      <span className="material-icons">circle</span>
                    </Radio.CheckIcon>
                  </Radio>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className={!state.isUserEnabled ? "form-radio-card-primary" : "card"}
                  role="presentation"
                  onClick={() => dispatch(setFormValue("isUserEnabled", false))}
                >
                  <Radio
                    className="radio-text"
                    label="No"
                    itemValue
                    type="radio"
                    value={!state.isUserEnabled}
                  >
                    <Radio.CheckIcon>
                      <span className="material-icons">circle</span>
                    </Radio.CheckIcon>
                  </Radio>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />

        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Password Reset"
              subtitle="Choose to Reset Password User Account"
            />
          </div>
          <div className="col-md-9">
            <Button disabled={timer > 0} onClick={handlePasswordReset} className="save-btn">
              {timer > 0 ? `Retry in ${timer}s` : "Password Reset"}
            </Button>
          </div>
        </div>
        <hr className="full-hr" />
        <footer className="setting-footer">
          <Button
            disabled={updateMutation.isLoading}
            onClick={handleSubmit}
            className="save-btn"
          >
            Save
          </Button>
        </footer>
      </Form>
    </section>
  );
}

export default UserManagementForm;
