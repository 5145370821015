import PropTypes from "prop-types";
import { pascalize } from "humps";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { SvgIcon } from "@/components/common";
import { getTestId } from "@/utils/helpers";

function WidgetDropdown({ label, items, isLoading, testId, isDisabled }) {
  return (
    <Dropdown.Root modal={false} data-testid={getTestId(testId)}>
      <Dropdown.Trigger
        className={`action-dropdown-trigger ${isDisabled && "action-dropdown-trigger-disabled"}`}
        disabled={isLoading || isDisabled}
        data-testid={getTestId(`${testId}-Button`)}
      >
        <span className="text">{label}</span>
        {isLoading ? (
          <i className="fas fa-circle-notch spin" />
        ) : (
          <span className="material-icons-outlined">expand_more</span>
        )}
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content
          className="dropdown-menu-content action-dropdown-menu"
          side="bottom"
          align="end"
        >
          {items.map((item, index) => (
            <Dropdown.Item
              key={`action-${index}`}
              className="dropdown-menu-item action-menu-item"
              onSelect={() => item.onClick()}
              data-testid={getTestId(`${testId}-${pascalize(item.title)}-Button`)}
            >
              <SvgIcon icon={item.icon} />
              <p>{item.title}</p>
            </Dropdown.Item>
          ))}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
}

WidgetDropdown.propTypes = {
  items: PropTypes.array,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

WidgetDropdown.defaultProps = {
  items: [],
  label: "",
  isLoading: false,
  isDisabled: false,
};

export default WidgetDropdown;
