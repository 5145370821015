export const getDashboardTableData = (data) => data;

export const getDashboardTableColumns = (data) => {
  if (!data.length) return data;

  const keys = Object.keys(data[0]);

  const columns = keys.map((key) => ({
    Header: key,
    accessor: key
  }));

  if (columns.length > 1) {
    columns[columns.length - 1].collapse = true;
  }

  return columns;
};
