import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { kebabCase } from "lodash";
import JobBoardColumn from "./JobBoardColumn";
import { getDynamicObjectByName } from "@/api/dynamic/dynamicObjectSchemaApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

function JobBoard({ jobs }) {
  const { data: objectSchema } = useQuery(
    [
      "dynamic-object-schema",
      kebabCase(dynamicObjectMap.get("WorkOrderJobObjectName")),
    ],
    () => getDynamicObjectByName(dynamicObjectMap.get("WorkOrderJobObjectName"))
  );

  const statusOptions = useMemo(() => {
    if (objectSchema) {
      return (
        [
          ...objectSchema.document.find((f) => f.camelizedName === "jobStatus")
            .options,
        ] || []
      );
    }

    return [];
  }, [objectSchema]);

  return (
    <div className="work-order-job-board">
      {/* eslint-disable-next-line max-len */}
      {statusOptions &&
        statusOptions.map((item) => (
          <JobBoardColumn
            status={{ label: item.label, value: item.value }}
            tasks={jobs?.data}
          />
        ))}
    </div>
  );
}

export default JobBoard;
