import PropTypes from "prop-types";
import { ResponsiveTimeRange } from "@nivo/calendar";

const legendColors = [
  "#5973930a",
  "#FECDCA",
  "#F04438",
  "#912018"
];

function CalendarChart({ data }) {
  return (
    <div className="calendar-chart">
      <div className="calendar-chart-container">
        <ResponsiveTimeRange
          data={data}
          from="2015-01-01"
          to="2015-12-31"
          emptyColor="#5973930a"
          colors={["#FECDCA", "#F04438", "#912018"]}
          margin={{
            top: 20, left: 0
          }}
          dayBorderWidth={2}
          dayBorderColor="#ffffff"
          firstWeekday="monday"
          weekdayTicks={[0, 1, 2, 3, 4, 5, 6]}
          dayRadius={2.5}
          weekdayLegendOffset={70}
          theme={{
            text: {
              fontSize: 16.62,
              fontWeight: 400,
              fontFamily: "Inter",
              fill: "#59739380",
            }
          }}
        />
      </div>
      <div className="calendar-chart-legend">
        <p>Less</p>
        {
          legendColors.map((color) => (
            <div
              key={color}
              className="calendar-chart-legend-box"
              style={{
                backgroundColor: color
              }}
            />
          ))
        }
        <p>More</p>
      </div>
    </div>
  );
}

CalendarChart.propTypes = {
  data: PropTypes.array.isRequired
};

export default CalendarChart;
