import { useState, forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { validationFieldDynamicObjectRecord } from "@/api/dynamic/dynamicObjectNameApi";
import showToast from "@/utils/toast/helpers";
import { getTestId } from "@/utils/helpers";
import { useDebounce } from "@/hooks";

const Input = forwardRef(
  (props, ref) => (
    <input ref={ref} {...props} data-testid={getTestId(props.id)} />
  )
);

function PhoneNumberSelector({
  defaultCountryInitials,
  value,
  onChange,
  placeholder,
  disabled,
  objectName = "",
  testId,
  handleDispatch = null,
  isPhoneUnique,
  error,
  required,
  checkValidation = true,
  ...rest
}) {
  const [isValid, setIsValid] = useState(true);
  const [validatePhoneFieldValue, setValidatePhoneFieldValue] = useState("");
  const queryClient = useQueryClient();
  const debouncedPhoneSearch = useDebounce(validatePhoneFieldValue, 300);

  const { data, isFetched } = useQuery(
    [debouncedPhoneSearch],
    () => validationFieldDynamicObjectRecord({
      data: debouncedPhoneSearch.replace(/\s/g, ""),
      objectName,
      fieldName: "Phone"
    }),
    {
      enabled: Boolean(debouncedPhoneSearch && checkValidation),
    }
  );

  useEffect(() => {
    if (data) {
      if (data.exists && handleDispatch) {
        handleDispatch("isPhoneUnique", false);
        showToast("Phone already exists", "error");
      } else {
        handleDispatch("isPhoneUnique", true);
      }
    }
  }, [data, isFetched]);

  const handleChange = (phoneNumber) => {
    if (phoneNumber) {
      if (phoneNumber === validatePhoneFieldValue && !isPhoneUnique) {
        showToast("Phone already exists", "error");
        return;
      }
      setValidatePhoneFieldValue(phoneNumber);
      queryClient.invalidateQueries({
        queryKey: [phoneNumber]
      });
      const isValidNumber = isValidPhoneNumber(phoneNumber);
      setIsValid(isValidNumber);

      if (required) {
        if (!isValidNumber) {
          handleDispatch("hasErrors", true);
        } else {
          handleDispatch("hasErrors", false);
        }
      }
    }
    onChange(phoneNumber);

  };

  return (
    <>
      <PhoneInput
        id={testId}
        className="phone-number-selector"
        defaultCountry={defaultCountryInitials}
        country={defaultCountryInitials}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        international
        withCountryCallingCode
        countrySelectProps={{
          tabIndex: -1
        }}
        inputComponent={Input}
        {...rest}
      />
      <div>{!isValid && <span className="error-text">Phone number is not valid</span>}</div>
      <div>{typeof error === "string" && <span className="error-text">{error}</span>}</div>
    </>
  );
}

PhoneNumberSelector.propTypes = {
  defaultCountryInitials: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  testId: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  checkValidation: PropTypes.bool
};

PhoneNumberSelector.defaultProps = {
  value: "",
  placeholder: "Enter phone number",
  disabled: false,
  defaultCountryInitials: "AE",
  testId: "",
  error: false,
  required: false,
  checkValidation: true
};

PhoneNumberSelector.Type = "HydraValidatableComponent";

export default PhoneNumberSelector;
