import request from "@/utils/api/helpers";

const baseUrl = "/dashboards/data";

export const getDynamicDashboardDataset = () => request({
  url: `${baseUrl}`
});

export const getDashboardData = (data) => request({
  url: `${baseUrl}`,
  method: "POST",
  headers: {
    "Content-Type": "application/json"
  },
  data
});
