import { startCase } from "lodash";

export const hasChildren =
(items, parentItem) => items.some((i) => (i.parentId ? i.parentId === parentItem.id : false));

export const findChildren =
(items, parentItem) => items.filter((i) => (i.parentId ? i.parentId === parentItem.id : false));

export const assignDepthAndHasChildren = (items, parentItem, depth) => {
  const children = findChildren(items, parentItem);

  parentItem.subRows = children;
  parentItem.depth = depth;

  children.forEach((item) => {
    const hasSubRows = hasChildren(items, item);
    item.depth = depth + 1;

    if (hasSubRows) {
      assignDepthAndHasChildren(items, item, depth + 1);
    }
  });
};

export const buildTree =
(items, depth = 0) => items
  .filter((i) => !i.parentId)
  .map((item) => {
    const hasSubRows = hasChildren(items, item);
    item.depth = depth;

    if (hasSubRows) {
      assignDepthAndHasChildren(items, item, depth);
    }

    return item;
  });

export const buildTreeForChartOfAccounts = (items, search) => {
  const filterItem = (item) => {
    const nameMatch = !search.name || item.name.toLowerCase().includes(search.name.toLowerCase());
    const codeMatch = !search.code || item.code.toLowerCase().includes(search.code.toLowerCase());
    const accountTypeMatch = !search.accountType?.value ||
      item.headerType === search.accountType.value;
    const rootTypeMatch = !search.rootType?.value || (item.headerType === "Root" && item.name.toLowerCase().includes(search.rootType.value.toLowerCase()));
    return nameMatch && codeMatch && accountTypeMatch && rootTypeMatch;
  };

  const findMatchingItems = (currentItems, parentId = null) => currentItems
    .filter((item) => item.parentId === parentId)
    .reduce((acc, item) => {
      if (filterItem(item)) {
        acc.push(item);
        const children = findMatchingItems(currentItems, item.id);
        if (children.length > 0) {
          item.subRows = children;
        }
      } else {
        const matchingChildren = findMatchingItems(currentItems, item.id);
        if (matchingChildren.length > 0) {
          item.subRows = matchingChildren;
          acc.push(item);
        }
      }
      return acc;
    }, []);

  return findMatchingItems(items);
};

export const flattenTree = (items, arr = []) => {
  items.forEach((item) => {
    arr.push(item);

    if (item.subRows) {
      return flattenTree(item.subRows, arr);
    }
  });

  return arr;
};

export const filterClearChequePaymentAccountOption = (option, inputValue) => {

  if (inputValue && !option.data.name?.toLowerCase()?.includes(inputValue?.toLowerCase())) {
    return false;
  }

  if (option.data.name === "Bank") {
    return false;
  }

  if (option.data.headerType === "Bank") {
    return true;
  }
  return false;
};

export const formatGLAccountOption = (option) => ({
  ...option,
  label: `${option.name} (${option.code})`,
  value: option.id,
  subtitle: startCase(option.accountType),
  disabled: option.isFrozen
});

export const filterTaxRuleOption = (option, inputValue) => {
  if (option.data.isFrozen) {
    return false;
  }

  if (inputValue && !option.data.name?.toLowerCase()?.includes(inputValue?.toLowerCase())) {
    return false;
  }

  return true;
};

export const formatBankOption = (option) => ({
  ...option,
  label: option.name,
  value: option.id,
  subtitle: option.accountNumber
});

export const formatBudgetCategory = (option) => ({
  label: `${option.name} (${option.code})`,
  value: option.id,
  code: option.code
});
