import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getJournalLedgerColumns(company) {
  const colArr = [
    {
      label: "Trans #",
      value: true,
      columnId: "transferId",
      resizable: true,
      width: estimatedColumnWidth("number"),
      formatter: (value) => String(value),
      className: "justify-content-center"
    },
    {
      label: "Type",
      value: true,
      columnId: "type",
      width: estimatedColumnWidth("type"),
      resizable: true,
    },
    {
      label: "Date",
      value: true,
      columnId: "postingDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Number",
      value: true,
      columnId: "number",
      width: estimatedColumnWidth("number"),
      resizable: true,
    },
    {
      label: "Name",
      value: true,
      columnId: "name",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Building",
      value: true,
      columnId: "buildingName",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Component",
      value: true,
      columnId: "componentName",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Unit",
      value: true,
      columnId: "unitName",
      width: estimatedColumnWidth("number"),
      resizable: true,
    },
    {
      label: "GL Code",
      value: true,
      columnId: "accountCode",
      width: estimatedColumnWidth("code"),
      resizable: true,
    },
    {
      label: "Account",
      value: true,
      columnId: "accountName",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Company",
      value: true,
      columnId: "companyName",
      width: estimatedColumnWidth("name"),
      formatter: () => `${company?.name}`,
      resizable: true,
    },
    {
      label: "Department",
      value: true,
      columnId: "departmentName",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Class",
      value: true,
      columnId: "className",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Location",
      value: true,
      columnId: "locationName",
      width: estimatedColumnWidth("name"),
      resizable: true,
    },
    {
      label: "Opening Balance",
      value: true,
      columnId: "openingBalance",
      width: estimatedColumnWidth("amount"),
    },
    {
      label: "Debit",
      value: true,
      columnId: "debitAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => {
        if (!value) {
          return formatCurrency(0);
        }

        return formatCurrency(value);
      },
    },
    {
      label: "Credit",
      value: true,
      columnId: "creditAmount",
      width: estimatedColumnWidth("amount"),
      formatter: (value) => {
        if (!value) {
          return formatCurrency(0);
        }

        return formatCurrency(value);
      },
    },
    {
      label: "Closing Balance",
      value: true,
      columnId: "closingBalance",
      width: estimatedColumnWidth("amount"),
    },
  ];
  return colArr;
}
