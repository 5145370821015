import {
  Button, Checkbox, Form, Input, Radio, ReactSelect
} from "@hydra/atom/components";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Header, FormLeftHeader, PhoneNumberSelector } from "@/components/common";
import ProfileAvatar from "@/assets/images/avatar-arab.png";
import { PrependInput } from "@/components/admin";
import { setFormValue, setNextStep } from "@/reducers/admin/companySettingsReducer.js";
import { createCompanySetting, updateCompanySettingProfile } from "@/api/admin/companySettingApi";
import { prepareGeneralData } from "@/utils/admin/helpers";
import { validationMessages, areaUnitType, dateFormat } from "@/utils/admin/constants";
import showToast from "@/utils/toast/helpers";
import { selectActiveCompany } from "@/store/appSlice";

function GeneralSettings({ state, dispatch }) {
  const [sameAsCompanyAddress, setSameAsCompanyAddress] = useState(false);
  const activeCompany = useSelector(selectActiveCompany);

  const leftContent = (title, subtitle) => (
    <div className="container-header-left-content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );

  const saveMutation = useMutation(createCompanySetting, {
    onError: () => {
      showToast("Could not create. Try again!", "error");
    },
    onSuccess: (saveMutationData) => {
      dispatch({
        type: "SET_FORM_VALUE",
        payload: {
          key: "companyId",
          value: saveMutationData.data?.id,
        },
      });
      showToast("Created successfully", "success");
      dispatch(setNextStep(2));
    },
  });

  const updateMutation = useMutation(
    ({ id: recordId, data: recordData }) => updateCompanySettingProfile(recordId, recordData),
    {
      onError: () => {
        showToast("Could not update. Try again!", "error");
      },
      onSuccess: (data) => {
        const company = activeCompany?.companySettings?.id === data?.data?.id ?
          activeCompany : null;
        if (company) {
          window.localStorage.setItem("date_format", data?.data?.dateFormat);
        }
        showToast("Updated successfully", "success");
        dispatch(setNextStep(2));
      },
    }
  );

  const onSubmit = () => {
    const stepOneFormattedData = prepareGeneralData(state);
    if (state.generalSettingsEditStatus) {
      updateMutation.mutate({
        id: state.companyId,
        data: stepOneFormattedData,
      });
    } else {
      saveMutation.mutate(stepOneFormattedData);
    }
  };

  return (
    <section className="general-setting-body">
      <Form onSubmit={onSubmit} shouldScrollOnError>
        <div className="setting-header">
          <Header
            leftContent={leftContent(
              "General Information",
              "Get emails to find out what’s going on when you’re not online. You can turn them off anytime."
            )}
          />
          <hr className="full-hr" />
        </div>
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Company Logo"
              subtitle="This will be displayed on your Company profile."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-1">
                <img src={ProfileAvatar} height="64px" width="64px" alt="profile avatar" />
              </div>
              <div className="col-md-7">
                <div className="form-action-item">
                  <h1 className="form-action-text" onClick={() => {}}>
                    Update
                  </h1>
                  <h1 className="form-action-text" onClick={() => {}}>
                    Delete
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Company Name"
              subtitle="This will be displayed on your Company profile."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <PrependInput
                  value={state.companyName}
                  name="Company Name"
                  id="companyName"
                  error={validationMessages.required}
                  onChange={(value) => dispatch(setFormValue("companyName", value))}
                  prependText="tulierp.com/"
                  rules="required"
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Company Website"
              subtitle="This will be displayed on your Company profile."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <PrependInput
                  value={state.companyWebsite}
                  onChange={(value) => dispatch(setFormValue("companyWebsite", value))}
                  name="Company Website"
                  id="companyWebsite"
                  prependText="https://"
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Company Address"
              subtitle="This will be displayed on your Company profile."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <Input
                  value={state.companyAddress}
                  onChange={(value) => dispatch(setFormValue("companyAddress", value))}
                  name="Company Address"
                  id="companyAddress"
                  placeholder="Company Name"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Company Postal Address"
              subtitle="This will be displayed on your Company profile."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <Input
                  value={state.companyPostalAddress}
                  onChange={(value) => dispatch(setFormValue("companyPostalAddress", value))}
                  name="Company Postal Address"
                  id="companyPostalAddress"
                  placeholder="Company Postal Address"
                  type="text"
                />
              </div>
              <div className="col-md-12">
                <Checkbox
                  className="settings-checkbox"
                  label="Same as Company Address"
                  value={sameAsCompanyAddress}
                  onChange={() => {
                    if (!sameAsCompanyAddress === true) {
                      dispatch(setFormValue("companyPostalAddress", state.companyAddress));
                    }
                    setSameAsCompanyAddress(!sameAsCompanyAddress);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Phone Numbers"
              subtitle="This will be displayed on your Company profile."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <h1 className="form-field-text">Phone Number 01</h1>
                <PhoneNumberSelector
                  id="number-1"
                  name="number-1"
                  value={state.phoneNumbers[0]}
                  onChange={(value) =>
                    dispatch(setFormValue("phoneNumbers", [value, state.phoneNumbers[1]]))}
                  error={validationMessages.required}
                  rules="required"
                />
              </div>
              <div className="col-md-6">
                <h1 className="form-field-text">Phone Number 02</h1>
                <PhoneNumberSelector
                  id="number-2"
                  name="number-2"
                  value={state.phoneNumbers[1]}
                  onChange={(value) =>
                    dispatch(setFormValue("phoneNumbers", [state.phoneNumbers[0], value]))}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Company General Information Email"
              subtitle="Enter a General Information email of your company"
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <Input
                  value={state.email}
                  onChange={(value) => dispatch(setFormValue("email", value))}
                  name="Company General Information Email"
                  id="email"
                  placeholder="you@example.com"
                  type="email"
                  rules="required"
                  messages={validationMessages.required}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Does your business require multi language support"
              subtitle="Select to specify if segments of information should be added to the standard account codes."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4">
                <div
                  className={state.multiLanguageSupport ? "form-radio-card-primary" : "card"}
                  role="presentation"
                  onClick={() => dispatch(setFormValue("multiLanguageSupport", true))}
                >
                  <Radio
                    className="radio-text"
                    label="Yes"
                    itemValue
                    type="radio"
                    value={state.multiLanguageSupport}
                    onChange={() => dispatch(setFormValue("multiLanguageSupport", true))}
                  >
                    <Radio.CheckIcon>
                      <span className="material-icons">circle</span>
                    </Radio.CheckIcon>
                  </Radio>
                  <h1 className="form-field-radio-text">We need Multi-language support</h1>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className={!state.multiLanguageSupport ? "form-radio-card-primary" : "card"}
                  role="presentation"
                  onClick={() => dispatch(setFormValue("multiLanguageSupport", false))}
                >
                  <Radio
                    className="radio-text"
                    label="No"
                    itemValue
                    type="radio"
                    value={!state.multiLanguageSupport}
                  >
                    <Radio.CheckIcon>
                      <span className="material-icons">circle</span>
                    </Radio.CheckIcon>
                  </Radio>
                  <h1 className="form-field-radio-text">We don’t need multi-language support</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Unit Type And Date Format"
              subtitle="This will be displayed on your Company profile."
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <ReactSelect
                  name="unitType"
                  placeholder="Select Area Unit"
                  options={areaUnitType}
                  value={state.areaMeasurementUnitType}
                  onChange={(value) => dispatch(setFormValue("areaMeasurementUnitType", value))}
                />
              </div>
              <div className="col-md-6">
                <ReactSelect
                  name="dateFormat"
                  placeholder="Select Date Format"
                  options={dateFormat}
                  value={state.dateFormat}
                  onChange={(value) => dispatch(setFormValue("dateFormat", value))}
                />
              </div>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <footer className="setting-footer">
          <Button className="save-btn" type="submit" loading={saveMutation.isLoading}>
            Save and Move Next
          </Button>
        </footer>
      </Form>
    </section>
  );
}

export default GeneralSettings;
