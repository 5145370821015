import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getTenantOutstandingColumns() {
  const colArr = [
    {
      label: "Tenant Number",
      value: true,
      columnId: "tenantNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Name",
      value: true,
      columnId: "tenantName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Country",
      value: true,
      columnId: "tenantCountry",
      width: estimatedColumnWidth("country"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Mobile",
      value: true,
      columnId: "tenantMobile",
      width: estimatedColumnWidth("phone"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Phone",
      value: true,
      columnId: "tenantPhone",
      width: estimatedColumnWidth("phone"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Contract Number",
      value: true,
      columnId: "contractNumber",
      width: estimatedColumnWidth("ContractNumber"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Component Number",
      value: true,
      columnId: "componentNumber",
      width: estimatedColumnWidth("ComponentNumber"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Component Name",
      value: true,
      columnId: "componentName",
      width: estimatedColumnWidth("Component"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "PDC Number",
      value: true,
      columnId: "pDCNumber",
      width: estimatedColumnWidth("PDCNumber"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "PDC Applied Amount",
      value: true,
      columnId: "pDCAppliedAmount",
      width: estimatedColumnWidth("pDCAppliedAmount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Unit Number",
      value: true,
      columnId: "unitNumber",
      width: estimatedColumnWidth("UnitNumber"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Invoice Type",
      value: true,
      columnId: "invoiceType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Invoice Number",
      value: true,
      columnId: "invoiceNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Transaction Date",
      value: true,
      columnId: "transactionDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Due Date",
      value: true,
      columnId: "dueDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Total Outstanding Amount",
      value: true,
      columnId: "totalOutstanding",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Total Collected Amount",
      value: true,
      columnId: "totalCollected",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Balance",
      value: true,
      columnId: "balance",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Total PDCs",
      value: true,
      columnId: "totalPDCs",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Remarks",
      value: true,
      columnId: "remarks",
      width: estimatedColumnWidth("comments"),
      resizable: true,
      formatter: (value) => String(value || "")
    }
  ];

  return colArr;
}
