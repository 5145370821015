import { useRef, useReducer, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";

import {
  Button,
  Form,
  FormControl,
  Input,
  Label,
  Loader,
} from "@hydra/atom/components";
import { selectPermission } from "@/store/userSlice";
import { CustomActionDropdown } from "@/components/dynamic";

import { BoxedContent, Header, FormLeftHeader } from "@/components/common";
import {
  currencyFormReducer,
  initialState,
  setFormValue,
  setInitialState,
} from "@/reducers/finance/currencyFormReducer";
import {
  createCurrency,
  updateCurrency,
  getCurrencyById,
  deleteCurrency
} from "@/api/finance/currencyApi";
import showToast from "@/utils/toast/helpers";
import { useSelector } from "react-redux";

const validationMessages = {
  required: {
    required: "This field is required",
  },
};

const prepareData = (state) => state;

const prepareState = (data) => data;

function ActionCell({
  deleteMutation, id
}) {
  const actions = [
    {
      title: "Delete",
      onClick: () => { deleteMutation.mutate(id); },
      icon: "delete-icon"
    }
  ];
  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={actions}
        trigger={(
          <div className="action-dropdown-trigger">
            <span className="text">Actions</span>
            <span className="material-icons-outlined">expand_more</span>
          </div>
        )}
        testId="Add-GL-Action-Button"
      />
    </div>
  );
}

export default function AddCurrency() {
  const [state, dispatch] = useReducer(currencyFormReducer, initialState);
  const deletePermission = useSelector(selectPermission({ parent: "Model", scope: "AccountingPeriod", action: "Delete" }));

  const submitButtonRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isInitialLoading } = useQuery(
    ["currency", id],
    () => getCurrencyById(id),
    {
      enabled: id !== undefined,
    }
  );

  useEffect(() => {
    if (id && data) {
      const stateData = prepareState(data?.data);
      dispatch(setInitialState(stateData));
    }
  }, [data]);

  const saveMutation = useMutation(createCurrency, {
    onError: () => {
      showToast("Could not create. Try again!", "error");
    },
    onSuccess: () => {
      showToast("Created successfully", "success");
      navigate(-1);
    },
  });

  const updateMutation = useMutation(
    ({ id: recordId, data: recordData }) => updateCurrency(recordId, recordData),
    {
      onError: () => {
        showToast("Could not update. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Updated successfully", "success");
        navigate(-1);
      },
    }
  );

  const deleteMutation = useMutation(deleteCurrency, {
    onError: () => {
      showToast("Could not delete. Try again!", "error");
    },
    onSuccess: () => {
      showToast("Deleted successfully", "success");
      QueryClient.invalidateQueries({
        queryKey: ["delete-currency"],
      });
    },
  });

  const handleCancel = () => {
    dispatch(setInitialState(initialState));
    navigate(-1);
  };

  const handleSave = () => {
    submitButtonRef.current.click();
  };

  const handleSubmit = async () => {
    const recordData = prepareData(state);

    if (id) {
      updateMutation.mutate({
        id,
        data: recordData,
      });
      return;
    }

    saveMutation.mutate(recordData);
  };

  if (id && isInitialLoading) {
    return <Loader />;
  }

  return (
    <BoxedContent className="gl-account-form">
      <Header
        showBreadcrumb
        leftContent={(
          <h1>{`${id ? "Edit" : "Create"} Currency`}</h1>
        )}
        // rightContent={(id && deletePermission) ? <ActionCell deleteMutation={deleteMutation} id={id} /> : null}
      />
      <Form
        onSubmit={handleSubmit}
        key="currency-form"
        className="dynamic-object-form"
        shouldScrollOnError
      >
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Name & Details"
              subtitle="Provide Name & Details of currency"
              icon="scan-icon"
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="name" label="Name" />
                  <Input
                    name="name"
                    placeholder="Name"
                    value={state.name}
                    onChange={(value) => dispatch(setFormValue("name", value))}
                    rules="required"
                    messages={validationMessages.required}
                    testId="Currency-Name-Input"
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="code" label="Code" />
                  <Input
                    placeholder="Code"
                    value={state.code}
                    onChange={(value) => dispatch(setFormValue("code", value))}
                    rules="required"
                    messages={validationMessages.required}
                    testId="Currency-Code-Input"
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="symbol" label="Symbol" />
                  <Input
                    name="symbol"
                    placeholder="Symbol"
                    value={state.symbol}
                    onChange={(value) => dispatch(setFormValue("symbol", value))}
                    rules="required"
                    messages={validationMessages.required}
                    testId="Currency-Symbol-Input"
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="precision" label="Precision" />
                  <Input
                    name="precision"
                    placeholder="Precision"
                    value={state.precision}
                    onChange={(value) => dispatch(setFormValue("precision", value))}
                    rules="required"
                    messages={validationMessages.required}
                    testId="Currency-Precision-Input"
                  />
                </FormControl>
              </div>
            </div>
          </div>

          <button
            type="submit"
            style={{ display: "none" }}
            ref={submitButtonRef}
          >
            Save
          </button>
        </div>
      </Form>
      <div className="col-md-12">
        <div className="hr" />
      </div>

      <div className="col">
        <div className="buttons-at-end">
          <Button bordered small onClick={handleCancel} testId="Currency-Cancel-Button">
            Cancel
          </Button>
          <Button
            small
            className="save-btn"
            loading={saveMutation.isLoading || updateMutation.isLoading}
            onClick={handleSave}
            testId="Currency-Submit-Button"
          >
            Save
          </Button>
        </div>
      </div>
    </BoxedContent>
  );
}
