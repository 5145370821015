import { pascalize } from "humps";
import {
  emptyTextCell,
  nonEditable,
  textCell,
  getHeaderRow,
  chevronCell
} from "../cells";
import { HEADING_ROW_HEIGHT } from "../constants";
import { formatCurrency } from "@/utils/helpers";

export const CASHINCHANGE_ROW_ID = "cashInChange";

const transformAccountsToRows = (accounts, parentId = null, rowId = 0) => {
  let rId = rowId;
  let rows = [];

  accounts.forEach((account) => {
    const currentRowId = rId;
    rId += 1;
    const hasChildren = account.children && account.children.length > 0;
    const balanceText = !hasChildren ? account.balance : "";

    const row = {
      rowId: currentRowId,
      height: HEADING_ROW_HEIGHT,
      cells: [
        chevronCell({
          text: account.groupHeader || account.name,
          parentId,
          isExpanded: true,
          hasChildren
        }),
        nonEditable(textCell({ text: `${formatCurrency(balanceText)}` }), "font-bold trial-balance-row justify-content-start") // Add the balance text
      ]
    };

    rows.push(row);

    if (hasChildren) {
      const { rows: childRows, rowId: newRowId } = transformAccountsToRows(
        account.children,
        currentRowId,
        rId
      );
      rows = rows.concat(childRows);
      rId = newRowId;
    }
  });

  return { rows, rowId: rId };
};

function getAccountsRows(title, rowsData, parentId = null) {
  const result = [];
  let rowNumber = 0;

  const titleRow = [
    {
      // eslint-disable-next-line no-plusplus
      rowId: `${title}${++rowNumber}`,
      height: HEADING_ROW_HEIGHT,
      cells: [
        chevronCell({
          text: title,
          parentId,
          isExpanded: true,
          hasChildren: true
        }),
        nonEditable(emptyTextCell(), ""),
      ]
    },
  ];

  if (rowsData) {
    const { rows: accountsRows, rowId } = transformAccountsToRows(rowsData?.accounts,
      `${title}${1}`,
      // eslint-disable-next-line no-plusplus
      `${title}${++rowNumber}`);
    const netCashRow = [
      {
        // eslint-disable-next-line no-plusplus
        rowId: `${title}${++rowNumber}`,
        height: HEADING_ROW_HEIGHT,
        cells: [
          chevronCell({
            text: `Total ${title}`,
            parentId: `${title}${1}`,
            hasChildren: false,
            className: "font-bold trial-balance-row justify-content-start total-row"
          }),
          nonEditable(textCell({ text: `${formatCurrency(rowsData.balance)}` }), "font-bold trial-balance-row justify-content-start total-row"),
        ]
      },
    ];
    result.push(...titleRow, ...accountsRows, ...netCashRow);
    rowNumber = rowId;
  }
  return result;
}

function formatString(inputString) {
  return inputString.replace(/([A-Z])/g, " $1").trim();
}

function getTopParent(title) {
  let rowNumber = 0;
  if (title) {
    const tempArr = ({
      // eslint-disable-next-line no-plusplus
      rowId: `${title}${++rowNumber}`,
      height: HEADING_ROW_HEIGHT,
      cells: [
        chevronCell({
          text: formatString(pascalize(title)),
          parentId: null,
          isExpanded: true,
          hasChildren: true
        }),
        nonEditable(textCell({ text: "" }), "font-bold trial-balance-row justify-content-start"),
      ]
    });

    return tempArr;
  }

  return {};
}

function getTotalRow(title, amount, parentId = null) {
  let rowNumber = 0;
  if (title) {
    const tempArr = ({
      // eslint-disable-next-line no-plusplus
      rowId: `${title}${++rowNumber}`,
      height: HEADING_ROW_HEIGHT,
      cells: [
        chevronCell({
          text: formatString(pascalize(title)),
          parentId,
          hasChildren: false,
          className: "font-bold trial-balance-row justify-content-start"
        }),
        nonEditable(textCell({ text: formatCurrency(amount || 0) }), "font-bold trial-balance-row justify-content-start"),
      ]
    });

    return tempArr;
  }

  return {};
}

export function getProfitAndLossRows({
  data,
  columns
}) {

  if (data.data.length === 0) {
    return [];
  }
  const {
    ordinaryIncome, otherIncomeAndExpense, netIncome
  } = data.data;

  const ordinaryIncomeRow = getTopParent("Ordinary Income");
  const costOfSalesRow = getAccountsRows("Cost of Sales", ordinaryIncome?.costOfSales, ordinaryIncomeRow.rowId);
  const incomeRow = getAccountsRows("Income", ordinaryIncome?.income, ordinaryIncomeRow.rowId);
  const grossProfitRow = getTotalRow("Gross Profit", ordinaryIncome?.grossProfit.netActivity, ordinaryIncomeRow.rowId);
  const expensesRow = getAccountsRows("Expenses", ordinaryIncome?.expenses, ordinaryIncomeRow.rowId);
  const netOrdinaryIncomeRow = getTotalRow("Net Ordinary Income", ordinaryIncome?.netOrdinaryIncome.ordinaryIncomeRow, ordinaryIncomeRow.rowId);

  const otherIncomeAndExpenseRow = getTopParent("Other Income And Expense");
  const otherIncomeRows = getAccountsRows("Other Income", otherIncomeAndExpense?.otherIncome, otherIncomeAndExpenseRow.rowId);
  const otherExpenseRows = getAccountsRows("Other Expense", otherIncomeAndExpense?.otherExpense, otherIncomeAndExpenseRow.rowId);
  const netOtherIncomeRow = getTotalRow("Net Other Income", otherIncomeAndExpense?.netOtherIncome.ordinaryIncomeRow, otherIncomeAndExpenseRow.rowId);
  const netIncomeRow = getTotalRow("Net Income", netIncome?.netIncome?.ordinaryIncomeRow);

  return [getHeaderRow(columns),
    ordinaryIncomeRow,
    ...costOfSalesRow,
    ...incomeRow,
    grossProfitRow,
    ...expensesRow,
    netOrdinaryIncomeRow,
    otherIncomeAndExpenseRow,
    ...otherIncomeRows,
    ...otherExpenseRows,
    netOtherIncomeRow,
    netIncomeRow
  ];

}
