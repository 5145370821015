import PropTypes from "prop-types";

import { SearchBar, SwitchAppDropdown } from "@/components/dashboard";
import NotificationsDropDown from "@/components/dashboard/NotificationsDropdown";
import { useIsMobile } from "@/hooks";
import TuliLogo from "@/assets/images/logos/tuli.svg";

export default function TopNav({ showSideBar }) {
  const isMobileView = useIsMobile(992);
  const isMobileViewLarge = useIsMobile(1400);

  return (
    <nav className="nav">
      {isMobileView && (
        <>
          <button type="button" className="btn-icon" onClick={showSideBar}>
            <span className="material-icons-outlined">
              menu
            </span>
          </button>
          <div className="main-logo-container">
            <img src={TuliLogo} className="main-logo" alt="Tuli Logo" />
          </div>
          <div />
          <div className="mobile-switch-app-dropdown">
            <SwitchAppDropdown />
          </div>
        </>
      )}
      {!isMobileView && <div />}
      {!isMobileViewLarge && <SearchBar />}
      {!isMobileView ? (
        <div className="right">
          {isMobileViewLarge && <SearchBar />}
          <NotificationsDropDown />
          <SwitchAppDropdown />
        </div>
      ) : null}
    </nav>
  );
}

TopNav.propTypes = {
  showSideBar: PropTypes.func.isRequired
};
