import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import {
  camelCase, isEmpty, kebabCase, startCase
} from "lodash";
import { pascalize } from "humps";
import { Link } from "react-router-dom";
import { Checkbox, Input } from "@hydra/atom/components";

import {
  formatDate,
  formatCurrency,
  getAttachmentUrl,
  getTestId,
  getDateFormat,
  handleChequeNumberChange,
} from "@/utils/helpers";
import {
  Select, CustomizedTimePicker, CustomizedDatePicker, Tooltip
} from "@/components/common";
import {
  getDynamicObjectRecords,
  getDynamicObjectRecordById,
} from "@/api/dynamic/dynamicObjectNameApi";
import { LookupField, SelectField, PolymorphicField } from "@/components/dynamic/fields";
import { checkRules, checkWordMatch } from "@/utils/dynamic/helpers";
import { formatGLAccountOption, formatBankOption } from "@/utils/finance/helpers";
import PdfFileIcon from "@/assets/images/file-pdf-icon.png";
import { handleDrawer } from "@/utils/modal/helpers";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

function ActionCell({
  onDelete, onEdit, onSave, row, disabled, testId
}) {
  const { objectName } = row.original;

  const deletePermission = objectName !== dynamicObjectMap.get("ContractUnitAnnualAmountObjectName");

  return (
    <div className="action-cell">
      {!row.original.isEditing ? (
        <>
          <button
            type="button"
            className="add-action-btn"
            onClick={() => onEdit(row.original.key)}
            disabled={disabled}
            data-testid={getTestId(`${testId}-Edit-Button-${row.index}`)}
          >
            <span className="material-icons-outlined">edit</span>
          </button>
          {deletePermission && (
            <button
              type="button"
              className="remove-action-btn"
              onClick={() => onDelete(row.original.key)}
              disabled={disabled}
              data-testid={getTestId(`${testId}-Delete-Button-${row.index}`)}
            >
              <span className="material-icons-outlined">delete</span>
            </button>
          )}
        </>
      ) : (
        <button
          type="button"
          className="add-action-btn"
          onClick={() => onSave(row.original.key)}
          disabled={disabled}
          data-testid={getTestId(`${testId}-Submit-Button-${row.index}`)}
        >
          <span className="material-icons-outlined">done</span>
        </button>
      )}
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

ActionCell.defaultProps = {
  disabled: false,
  testId: "",
};

function InputCell({
  onUpdate, onBlur, value, row, cell, testId, errors = []
}) {
  const { fieldData } = cell.column;

  return (
    <div className="input-cell">
      {row.original.isEditing ? (
        <Input
          type={fieldData.objectFieldType.toLowerCase()}
          value={value}
          onChange={(v) => onUpdate(row.original.key, fieldData.name, v)}
          className={checkWordMatch(`${fieldData.name}`, errors) ? "error-outline" : ""}
          disabled={fieldData.readOnly}
          onBlur={() => {
            if (fieldData.camelizedName === "chequeNo") {
              if (value) {
                const formattedNumber = handleChequeNumberChange(value);
                onUpdate(row.original.key, fieldData.name, formattedNumber);
              }
            }
            onBlur({
              fieldName: fieldData.name,
              value,
              rowIndex: row.original.key,
            });
          }}
          testId={getTestId(
            `${testId}-${pascalize(fieldData.name)}-${pascalize(
              fieldData.objectFieldType.toLowerCase()
            )}-${row.index}`
          )}
        />
      ) : (
        <Tooltip activator={<span>{value}</span>} side="top" align="start" className="bg-gray-900">
          <div className="table-text-tooltip">{value}</div>
        </Tooltip>
      )}
    </div>
  );
}

InputCell.propTypes = {
  row: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  cell: PropTypes.object.isRequired,
  testId: PropTypes.string,
};

InputCell.defaultProps = {
  value: null,
  testId: "",
};

function ValueCell({ value }) {
  return <div>{value}</div>;
}

ValueCell.propTypes = {
  value: PropTypes.string.isRequired,
};

function MoneyCell({
  onUpdate, value, row, cell, testId, errors
}) {
  const { fieldData } = cell.column;

  return (
    <div className="input-cell">
      {row.original.isEditing ? (
        <Input
          type="number"
          value={value}
          onChange={(v) => onUpdate(row.original.key, fieldData.name, v)}
          className={checkWordMatch(`${fieldData.name}`, errors) ? "error-outline" : ""}
          appendIcon={fieldData.currency}
          disabled={fieldData.readOnly}
          onWheel={(e) => e.target.blur()}
          testId={getTestId(
            `${testId}-${pascalize(fieldData.name)}-${pascalize(
              fieldData.objectFieldType.toLowerCase()
            )}-${row.index}`
          )}
        />
      ) : (
        <span>{formatCurrency(value)}</span>
      )}
    </div>
  );
}

MoneyCell.propTypes = {
  row: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([null])]),
  cell: PropTypes.object.isRequired,
  testId: PropTypes.string,
};

MoneyCell.defaultProps = {
  value: "",
  testId: "",
};

function AreaCell({
  onUpdate, value, row, cell, testId, errors = []
}) {
  const { fieldData } = cell.column;

  return (
    <div className="input-cell">
      {row.original.isEditing ? (
        <Input
          type="number"
          value={value}
          onChange={(v) => onUpdate(row.original.key, fieldData.name, v)}
          className={checkWordMatch(`${fieldData.name}`, errors) ? "error-outline" : ""}
          appendIcon={fieldData.currency}
          disabled={fieldData.readOnly}
          onWheel={(e) => e.target.blur()}
          testId={getTestId(
            `${testId}-${pascalize(fieldData.name)}-${pascalize(
              fieldData.objectFieldType.toLowerCase()
            )}-${row.index}`
          )}
        />
      ) : (
        <span>sq. m</span>
      )}
    </div>
  );
}

AreaCell.propTypes = {
  row: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  cell: PropTypes.object.isRequired,
  testId: PropTypes.string,
};

AreaCell.defaultProps = {
  testId: "",
};

function PercentageCell({
  onUpdate, value, row, cell, testId, errors = []
}) {
  const { fieldData } = cell.column;

  return (
    <div className="input-cell">
      {row.original.isEditing ? (
        <Input
          type="number"
          value={value}
          onChange={(v) => onUpdate(row.original.key, fieldData.name, v)}
          className={checkWordMatch(`${fieldData.name}`, errors) ? "error-outline" : ""}
          appendIcon={fieldData.currency}
          disabled={fieldData.readOnly}
          onWheel={(e) => e.target.blur()}
          testId={getTestId(
            `${testId}-${pascalize(fieldData.name)}-${pascalize(
              fieldData.objectFieldType.toLowerCase()
            )}-${row.index}`
          )}
        />
      ) : (
        <span>%</span>
      )}
    </div>
  );
}

PercentageCell.propTypes = {
  row: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  cell: PropTypes.object.isRequired,
  testId: PropTypes.string,
};

PercentageCell.defaultProps = {
  testId: "",
};

function LinkCell({
  value, row, onUpdate, cell, testId, errors = []
}) {
  const { fieldData } = cell.column;

  return (
    <div className="link-cell">
      {row.original.isEditing ? (
        <Input
          type="url"
          value={value}
          onChange={(v) => onUpdate(row.original.key, fieldData.name, v)}
          className={checkWordMatch(`${fieldData.name}`, errors) ? "error-outline" : ""}
          disabled={fieldData.readOnly}
          testId={getTestId(
            `${testId}-${pascalize(fieldData.name)}-${pascalize(
              fieldData.objectFieldType.toLowerCase()
            )}-${row.index}`
          )}
        />
      ) : (
        <a href={value} target="_blank">
          {value}
        </a>
      )}
    </div>
  );
}

LinkCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cell: PropTypes.object.isRequired,
  testId: PropTypes.string,
};

LinkCell.defaultProps = {
  testId: "",
};

function PettyCashTransactionLookupCell({ value }) {
  const { data: transactionData, isInitialLoading } = useQuery(
    [kebabCase(dynamicObjectMap.get("PettyCashTransactionObjectName")), value],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("PettyCashTransactionObjectName"), value),
    {
      enabled: Boolean(value),
    }
  );

  if (isInitialLoading) return <div />;

  if (!transactionData?.childTransaction) return <div />;

  return (
    <div className="link-cell">
      <Link
        to={handleDrawer(transactionData?.childTransaction, null, false, "dynamicObjectDrawers")}
      >
        View attachments
      </Link>
    </div>
  );
}

PettyCashTransactionLookupCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

PettyCashTransactionLookupCell.defaultProps = {
  value: "",
};

function AttachmentCell({ value }) {
  if (!value) {
    return <div />;
  }
  const renderAttachment = (file) => {
    if (file?.file) {
      return file.file.type.toLowerCase().endsWith("pdf") ? PdfFileIcon : file.url;
    }
    return file.url.toLowerCase().endsWith("pdf") ? PdfFileIcon : getAttachmentUrl(file.url);
  };

  return (
    <div className="attachment-cell">
      {value.map((file) => (
        <a key={file.url} target="_blank" href={file.file ? file.url : getAttachmentUrl(file.url)}>
          <img width="24" height="24" loading="lazy" src={renderAttachment(file)} alt={file.url} />
        </a>
      ))}
    </div>
  );
}

AttachmentCell.propTypes = {
  value: PropTypes.string.isRequired,
};

function CheckboxCell({
  value, row, onUpdate, cell, testId
}) {
  const { fieldData } = cell.column;

  const getValue = () => {
    if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    }
    return value || "No";
  };

  return (
    <div className="checkbox-cell">
      {row.original.isEditing ? (
        <Checkbox
          id={row.original.key}
          key={row.original.key}
          value={value}
          onChange={() => onUpdate(row.original.key, fieldData.name, !value)}
          disabled={fieldData.readOnly}
          testId={getTestId(
            `${testId}-${pascalize(fieldData.name)}-${pascalize(
              fieldData.objectFieldType.toLowerCase()
            )}-${row.index}`
          )}
        />
      ) : (
        <span>{getValue()}</span>
      )}
    </div>
  );
}

CheckboxCell.propTypes = {
  value: PropTypes.bool.isRequired,
  row: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cell: PropTypes.object.isRequired,
  testId: PropTypes.string,
};

CheckboxCell.defaultProps = {
  testId: "",
};

function MasterSelectCell({
  value, row, onUpdate, fieldName, cell
}) {
  return (
    <div className="link-cell">
      {row.original.isEditing ? (
        <Select
          name={cell.column.accessor}
          queryKey={cell.column.masterObjectName}
          value={value}
          onChange={(v) => onUpdate(row.original.key, fieldName, v)}
          optionsLoader={() =>
            getDynamicObjectRecords(cell.column.masterObjectName, {
              takePage: 1,
              limitPage: 100,
              sortBy: "Id",
              sortType: "ASC",
            })}
          optionLabelKey={cell.column.displayFieldName}
          optionValueKey="id"
          dataAccessKey="data"
          isClearable={false}
        />
      ) : (
        <span>{value?.label}</span>
      )}
    </div>
  );
}

MasterSelectCell.propTypes = {
  value: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cell: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
};

function LookupCell({
  value,
  row,
  onUpdate,
  cell,
  relationalFieldState,
  parentId,
  testId,
  onBlur,
}) {
  const { fieldData } = cell.column;

  const renderLabel = () => {
    if (
      Array.isArray(fieldData.lookupDisplayFieldName) &&
      fieldData.lookupDisplayFieldName.length
    ) {
      return value[camelCase(fieldData.lookupDisplayFieldName[0])] || value.label;
    }
    return value.label;
  };

  const renderValue = () => {
    if (!value) return "";

    if (fieldData.allowMultiple) {
      return value.map((v) => v.label).join(", ");
    }

    return renderLabel();
  };

  const onChange = (v) => {
    onUpdate(row.original.key, fieldData.name, v);
    onBlur({
      fieldName: fieldData.name,
      value: v,
      rowIndex: row.original.key,
    });
  };

  const additionalProps = {};

  if (fieldData.lookupObjectName === "LedgerAccount") {
    additionalProps.formatOption = formatGLAccountOption;
  }

  if (fieldData.lookupObjectName === "Bank") {
    additionalProps.formatOption = formatBankOption;
  }

  return (
    <div className="link-cell">
      {row.original.isEditing ? (
        <LookupField
          formState={row.original}
          field={fieldData}
          value={value}
          onChange={onChange}
          showLabel={false}
          isChildField
          relationalFieldState={relationalFieldState}
          parentId={parentId}
          isEditing={Boolean(parentId)}
          disabled={fieldData.readOnly}
          menuPortalTarget={document.body}
          testId={getTestId(
            `${testId}-${pascalize(fieldData.name)}-${pascalize(
              fieldData.objectFieldType.toLowerCase()
            )}-${row.index}`
          )}
          {...additionalProps}
        />
      ) : (
        renderValue()
      )}
    </div>
  );
}

LookupCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.oneOf([null])]),
  relationalFieldState: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  cell: PropTypes.object.isRequired,
  parentId: PropTypes.string,
  testId: PropTypes.string,
};

LookupCell.defaultProps = {
  parentId: "",
  testId: "",
  value: null,
};

function PolymorphicCell({
  value, row, onUpdate, cell, relationalFieldState, parentId, testId
}) {
  const { fieldData } = cell.column;

  const renderValue = () => {
    if (!value || isEmpty(value)) return "";

    let lookupObjectName;

    if (fieldData.allowMultiple) {
      lookupObjectName = value[0].lookupObjectName;
    } else {
      lookupObjectName = value.lookupObjectName;
    }

    const selectedLookup = fieldData.lookups.find(
      (lookup) => lookup.lookupObjectName === lookupObjectName
    );

    const displayField = selectedLookup.lookupDisplayFieldName[0];

    if (fieldData.allowMultiple) {
      return value.map((v) => v[camelCase(displayField)] || v.label).join(", ");
    }

    return value[camelCase(displayField)] || value.label;
  };

  return (
    <div className="link-cell">
      {row.original.isEditing ? (
        <PolymorphicField
          field={fieldData}
          value={value}
          onChange={(v) => onUpdate(row.original.key, fieldData.name, v)}
          showLabel={false}
          isChildField
          relationalFieldState={relationalFieldState}
          parentId={parentId}
          isEditing={Boolean(parentId)}
          testId={getTestId(
            `${testId}-${pascalize(fieldData.name)}-${pascalize(
              fieldData.objectFieldType.toLowerCase()
            )}-${row.index}`
          )}
        />
      ) : (
        renderValue()
      )}
    </div>
  );
}

PolymorphicCell.propTypes = {
  value: PropTypes.object.isRequired,
  relationalFieldState: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cell: PropTypes.object.isRequired,
  parentId: PropTypes.string,
  testId: PropTypes.string,
};

PolymorphicCell.defaultProps = {
  parentId: "",
  testId: "",
};

function SelectCell({
  value, row, onUpdate, cell, relationalFieldState, testId
}) {
  const { fieldData } = cell.column;

  return (
    <div className="select-cell">
      {row.original.isEditing ? (
        <SelectField
          field={fieldData}
          value={value}
          onChange={(v) => onUpdate(row.original.key, fieldData.name, v)}
          showLabel={false}
          isChildField
          relationalFieldState={relationalFieldState}
          disabled={fieldData.readOnly}
          menuPortalTarget={document.body}
          testId={getTestId(
            `${testId}-${pascalize(fieldData.name)}-${pascalize(
              fieldData.objectFieldType.toLowerCase()
            )}-${row.index}`
          )}
        />
      ) : (
        <span>{value?.label}</span>
      )}
    </div>
  );
}

SelectCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.oneOf([null])]),
  relationalFieldState: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cell: PropTypes.object.isRequired,
  testId: PropTypes.string,
};

SelectCell.defaultProps = {
  value: null,
  testId: "",
};

function DatePickerCell({
  value, row, onUpdate, cell, testId
}) {
  const { fieldData } = cell.column;

  return (
    <div className="select-cell">
      {row.original.isEditing ? (
        <CustomizedDatePicker
          id={row.original.key}
          name={row.original.key}
          value={value}
          onChange={(v) => onUpdate(row.original.key, fieldData.name, v)}
          format={getDateFormat()}
          minDate={fieldData.minDate}
          maxDate={fieldData.maxDate}
          disabled={fieldData.readOnly}
          portalContainer={document.getElementById(`${fieldData.parentField}-container`)}
          testId={getTestId(
            `${testId}-${pascalize(fieldData.name)}-${pascalize(
              fieldData.objectFieldType.toLowerCase()
            )}-${row.index}`
          )}
        />
      ) : (
        <span>{value ? formatDate(new Date(value)) : ""}</span>
      )}
    </div>
  );
}

DatePickerCell.propTypes = {
  value: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cell: PropTypes.object.isRequired,
  testId: PropTypes.string,
};

DatePickerCell.defaultProps = {
  testId: "",
};

function TimePickerCell({
  value, row, onUpdate, cell, testId
}) {
  const { fieldData } = cell.column;

  return (
    <div className="select-cell">
      {row.original.isEditing ? (
        <CustomizedTimePicker
          id={row.original.key}
          name={row.original.key}
          value={value}
          onChange={(v) => onUpdate(row.original.key, fieldData.name, v)}
          format={getDateFormat()}
          minTime={fieldData.minTime}
          maxTime={fieldData.maxTime}
          disabled={fieldData.readOnly}
          testId={getTestId(
            `${testId}-${pascalize(fieldData.name)}-${pascalize(
              fieldData.objectFieldType.toLowerCase()
            )}-${row.index}`
          )}
        />
      ) : (
        <span>{value}</span>
      )}
    </div>
  );
}

TimePickerCell.propTypes = {
  value: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  cell: PropTypes.object.isRequired,
  testId: PropTypes.string,
};

TimePickerCell.defaultProps = {
  testId: "",
};

export const getRelationalFieldColumns = (relationalField, parentState, state) => {
  const fieldsAccessKey =
    relationalField.objectFieldType.toLowerCase() === "table" ? "fields" : "additionalFields";

  const columns = relationalField[fieldsAccessKey]
    .filter((f) => {
      if (f.hidden) {
        return false;
      }

      if (!f.showInTable) {
        return false;
      }

      if (f.name === "chequeNo" || f.name === "refNo") return true;

      if (!checkRules(f, state, true, parentState)) {
        return false;
      }

      if (f.name === "transferStatus") {
        return false;
      }

      return true;
    })
    .map((field) => {
      const fieldColumnData = {
        Header: field.label || startCase(field.name),
        accessor: field.name,
        fieldType: field.objectFieldType.toLowerCase(),
      };

      switch (field.objectFieldType.toLowerCase()) {
        case "url":
          fieldColumnData.Cell = LinkCell;
          break;

        case "checkbox":
          fieldColumnData.Cell = CheckboxCell;
          break;

        case "select":
          fieldColumnData.Cell = SelectCell;
          break;

        case "lookup": {
          fieldColumnData.fieldData = {
            ...field,
            intermediateObjectName: relationalField.intermediateObjectName,
          };
          fieldColumnData.Cell = LookupCell;
          break;
        }

        case "polymorphic": {
          fieldColumnData.Cell = PolymorphicCell;
          break;
        }

        case "date":
          fieldColumnData.fieldData = {
            ...field,
            parentField: relationalField.name,
          };
          fieldColumnData.Cell = DatePickerCell;
          break;

        case "time":
          fieldColumnData.Cell = TimePickerCell;
          break;

        case "money":
          fieldColumnData.Cell = MoneyCell;
          break;

        case "area":
          fieldColumnData.Cell = AreaCell;
          break;

        case "percentage":
          fieldColumnData.Cell = PercentageCell;
          break;

        case "autonumber":
          fieldColumnData.Cell = ValueCell;
          break;

        case "attachment":
          fieldColumnData.Cell = AttachmentCell;
          break;

        default:
          fieldColumnData.Cell = InputCell;
          break;
      }

      if (!fieldColumnData.fieldData) {
        fieldColumnData.fieldData = field;
      }

      return fieldColumnData;
    });

  if (
    pascalize(relationalField.intermediateObjectName) ===
    dynamicObjectMap.get("PettyCashClaimTransactionObjectName")
  ) {
    columns.push({
      Header: "Attachment",
      accessor: "pettyCashTransactionId",
      Cell: PettyCashTransactionLookupCell,
    });
  }

  columns.push({
    Header: "Action",
    accessor: "action",
    Cell: ActionCell,
    collapse: true,
  });

  return columns;
};

export const getRelationalFieldData = (values, field) => {
  const data = [];
  const objectName = pascalize(field.intermediateObjectName);

  if (!values) {
    return data;
  }
  values.forEach((value, index) => {
    const entry = {
      objectName,
      ...value,
    };

    entry.key = index;
    entry.action = "";
    data.unshift(entry);
  });

  return data;
};
