import PropTypes from "prop-types";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { getTestId } from "@/utils/helpers";
import { useModal } from "@/hooks";
import { SvgIcon } from "@/components/common";

function ActionDropdownItem({ action, onClick, testId }) {
  return (
    <Dropdown.Item
      className={`dropdown-menu-item action-menu-item ${action.title === "Delete" ? "action-menu-delete-item" : ""}`}
      onSelect={onClick}
      data-testid={getTestId(`${testId}-${action.title}-Button`)}
    >
      {action?.icon && <SvgIcon icon={action.icon} />}
      <a>{action.title}</a>
    </Dropdown.Item>
  );
}

ActionDropdownItem.propTypes = {
  action: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

ActionDropdownItem.defaultProps = {
  testId: "",
};

function ActionDropdownContent({ actions, testId }) {
  return (
    <Dropdown.Content className="dropdown-menu-content action-dropdown-menu" side="bottom" align="end">
      {actions.map((action, index) => (
        <ActionDropdownItem key={`action-${index}`} action={action} onClick={action.onClick} testId={testId} />
      ))}
    </Dropdown.Content>
  );
}

ActionDropdownContent.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  testId: PropTypes.string,
};

ActionDropdownContent.defaultProps = {
  testId: "",
};

export function BankReconciliationActionDropdown({ actions, trigger, testId }) {
  const {
    open: openDropdown,
  } = useModal(false);

  return (
    <Dropdown.Root data-testid={getTestId(testId)}>
      <Dropdown.Trigger asChild data-testid={getTestId(`${testId}-Button`)} onClick={openDropdown}>
        {trigger}
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <ActionDropdownContent actions={actions} testId={testId} />
      </Dropdown.Portal>
    </Dropdown.Root>
  );
}

BankReconciliationActionDropdown.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  trigger: PropTypes.node.isRequired,
};

export default BankReconciliationActionDropdown;
