import {
  useState, useRef, useMemo
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import { Input } from "@hydra/atom/components";
import { CustomDropdown } from "@/components/common";
import { getApprotionmentRows, getApprotionmentColumns } from "./approtionment-statement";

function ReportApprotionment() {
  const ref = useRef();
  // eslint-disable-next-line no-unused-vars
  const [columnsData, setColumnsData] = useState(
    getApprotionmentColumns(ref?.current?.clientWidth)
  );
  const [filterText, setFilterText] = useState("");

  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const rows = useMemo(
    () =>
      getApprotionmentRows({
        data: {
          data: [],
        },
        columns,
      }),
    [columns]
  );

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-6">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-6 btn-icon-container">
          <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
        </div>
      </div>
      <div className="react-grid-container">
        <ReactGrid
          rows={rows}
          columns={columns}
          enableFillHandle
          enableRangeSelection
          onColumnResized={handleColumnResize}
          disableVirtualScrolling
        />
      </div>
    </div>
  );
}

export default ReportApprotionment;
