import {
  useState, useRef, useMemo
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input, Button
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { useSearchParams } from "react-router-dom";
import DynamicObjectDetails from "@/pages/dynamic/detail-views/DynamicObjectDetails";
import { SvgIcon, CustomDropdown, IconButton } from "@/components/common";
import { getBankAccountStatementRows, getBankAccountStatementColumns } from "./bank-account-statement";
import { getBankAccountStatement } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";
import { ReportFilterDrawer } from "@/components/drawers";
import appSettings from "@/settings";

function ReportBankAccountStatement({ activeCompany }) {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id").substring(1);
  const ref = useRef();
  const [columnsData, setColumnsData] = useState(
    getBankAccountStatementColumns(ref?.current?.clientWidth)
  );
  const [filterText, setFilterText] = useState("");
  const [filters, setFilters] = useState({
    TenantName: "*",
    CompanyName: "*",
    TenantKey: "*",
    PaymentMethod: "*",
    BankAccount: "*",
    BankAccountKey: id,
    PaymentType: "*",
  });
  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);
  const [isOpen, setIsOpen] = useState(false);

  const formatFilters = (data) => {
    const filterData = {
      TenantName: "*",
      CompanyName: "*",
      TenantKey: "*",
      PaymentMethod: "*",
      BankAccount: "*",
      BankAccountKey: id,
      PaymentType: "*",
    };

    if (!data) { return filterData; }

    if (data?.tenantName?.length > 0) {
      filterData.TenantName = data.tenantName;
    }

    if (data?.tenantType) {
      filterData.TenantType = data?.tenantType?.value;
    }

    if (data?.company) {
      filterData.CompanyName = data?.company?.name;
    }
    return filterData;
  };

  const exportExcel = () => window.open(`${appSettings.baseUrl}/reports/export-query-report/bank-account-statement?CompanyId=${activeCompany.id}&${qs.stringify(formatFilters(filters))}`, "_blank", "noopener,noreferrer");

  const { data: bankAccountStatementReportData, isLoading } = useQuery(["bank-account-statement-report", filters], () => getBankAccountStatement(formatFilters(filters)));

  const onClose = (value) => {
    setIsOpen(value);
  };

  const formatReport = (transaction) => {
    if (filterText) {
      return transaction?.filter((obj) => obj?.tenantName.toLowerCase().includes(filterText.toLowerCase()));
    }
    return transaction;
  };

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const rows = useMemo(
    () =>
      getBankAccountStatementRows({
        data: {
          data: formatReport(bankAccountStatementReportData?.data) || [],
        },
        columns,
      }),
    [bankAccountStatementReportData, columns, filterText]
  );

  return (
    <>
      <DynamicObjectDetails objectName="BankAccount" id={id} showHeader={false} showTransfers={false} />
      <div className="table-wrapper report-sheet" ref={ref}>
        <div className="row filter-row">
          <div className="col-md-6">
            <Input
              className="input-height-fix"
              value={filterText}
              onChange={setFilterText}
              placeholder="Search by name"
            />
          </div>
          <div className="col-md-6 btn-icon-container">
            <IconButton
              className="icon-button-with-text"
              onClick={() => exportExcel()}
            >
              <SvgIcon icon="excel" />
              <span>Download Excel</span>
            </IconButton>
            <Button
              onClick={() => setIsOpen(true)}
              small
              bordered
            >
              <Button.Prepend>
                <SvgIcon icon="filter-lines" />
              </Button.Prepend>
            </Button>
            <ReportFilterDrawer filters={filters} setFilters={setFilters} type="aging" size={400} isOpen={isOpen} onClose={onClose} />
            <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
          </div>
        </div>
        <div className="react-grid-container">
          {!isLoading ? (
            <ReactGrid
              rows={rows}
              columns={columns}
              enableFillHandle
              enableRangeSelection
              onColumnResized={handleColumnResize}
              disableVirtualScrolling
            />
          ) : <TableSkeleton />}
        </div>
      </div>
    </>
  );
}

export default ReportBankAccountStatement;
