import { useMemo } from "react";
import PropTypes from "prop-types";
import chroma from "chroma-js";
import { getAttachmentUrl } from "@/utils/helpers";
import AvatarIcon from "@/assets/images/common-avatar.svg";

const userColorsMap = new Map();

const getProfileImage = (imageUrl) => {
  if (imageUrl) {
    return getAttachmentUrl(imageUrl);
  }

  return AvatarIcon;
};

const getAvatarBackgroundColor = (name) => {
  if (!name) return "#d0d5dd";

  if (name && userColorsMap.has(name)) {
    return userColorsMap.get(name);
  }

  const backgroundColor = chroma.random().hex();
  userColorsMap.set(name, backgroundColor);
  return backgroundColor;
};

function Avatar({
  name, imageUrl, size, status
}) {
  const showProfileImage = Boolean(imageUrl);
  const profileImage = useMemo(() => getProfileImage(imageUrl), []);
  const backgroundColor = useMemo(() => getAvatarBackgroundColor(name), [name]);
  const initials = name ? name[0] : "";

  return (
    <div
      className={`avatar-container ${size} ${status} ${showProfileImage ? "image" : "initials"}`}
      style={{ backgroundColor }}
    >
      {showProfileImage && <img loading="lazy" src={profileImage} alt={`${name} profile`} />}

      {!showProfileImage && <span>{initials}</span>}
    </div>
  );
}

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  status: PropTypes.oneOf(["", "visible", "invisible", "online", "offline"]),
};

Avatar.defaultProps = {
  imageUrl: "",
  size: "small",
  status: "",
};

export default Avatar;
