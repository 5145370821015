export const recordType = [
  {
    label: "Opening Entry",
    value: "Opening Entry",
  },
  {
    label: "Adjusting Entry",
    value: "Adjusting Entry",
  },
  {
    label: "Rental Invoice",
    value: "Rental Invoice",
  },
  {
    label: "Fixed Asset Depreciation",
    value: "Fixed Asset Depreciation",
  },
  {
    label: "General Receipt",
    value: "General Receipt",
  },
  {
    label: "Payment Receipt",
    value: "Payment Receipt",
  },
  {
    label: "Contract Termination",
    value: "Contract Termination",
  },
  {
    label: "Clear Cheque",
    value: "Clear Cheque",
  },
  {
    label: "Amc Invoice",
    value: "Amc Invoice",
  },
  {
    label: "Bounce Cheque",
    value: "Bounce Cheque",
  },
  {
    label: "Payment",
    value: "Payment",
  },
  {
    label: "Expense",
    value: "Expense",
  },
  {
    label: "General Invoice",
    value: "General Invoice",
  },
  {
    label: "Revenue Recognition",
    value: "Revenue Recognition",
  },
  {
    label: "Expense Recognition",
    value: "Expense Recognition",
  },
  {
    label: "Petty Cash",
    value: "Petty Cash",
  },
  {
    label: "Fixed Asset",
    value: "Fixed Asset",
  },
  {
    label: "Recall Cheque",
    value: "Recall Cheque",
  },
];
