import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { FormControl } from "@hydra/atom/components";
import { camelize, pascalize } from "humps";
import pluralize from "pluralize";
import { isEmpty, kebabCase, startCase } from "lodash";
import { components } from "react-select";
import qs from "qs";

import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { Select } from "@/components/common";
import FieldLabel from "@/components/dynamic/fields/FieldLabel";
import { Pill } from "@/components/dashboard";
import { getFullName, getTestId } from "@/utils/helpers";
import { createNewObjURL } from "@/utils/dynamic/helpers";
import { usePreviousFormState, useDebounce } from "@/hooks";
import request from "@/utils/api/helpers";
import { menuStyles } from "@/utils/select/constants";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

const getStaticObjectRecords = (applicationName, objectName, filters) => {
  const apiFilters = { ...filters };
  const headers = {};

  if (apiFilters.Company || apiFilters.company) {
    headers.Companyid = apiFilters.Company || apiFilters.company;
  }

  const params = qs.stringify(apiFilters);

  return request({
    url: `/${kebabCase(applicationName.toLowerCase())}/${kebabCase(
      pluralize(objectName)
    )}?${params}`,
    headers
  });
};

const objectNamesWithFullOptions = ["LedgerAccount", "TaxRule", "Country", "Bank", "Location", "UtilityType", "FacilityCharge", "TechnicianSkill", "Employee", "BankAccount"];

function Option(props) {
  const { data, lookupObjectName, testId } = props;
  const number = lookupObjectName === "TechnicianSkill" ? data?.technician?.number : data?.number;
  const pills = useMemo(() => {
    const pillData = [];
    switch (lookupObjectName) {
      case "Unit":
        if (!isEmpty(data.building)) {
          pillData.push({
            label: data.building.name,
            variant: "success",
          });
        }
        break;

      case "ServiceRequest":
        if (!isEmpty(data.unit)) {
          pillData.push({
            label: data.unit.name,
            variant: "success",
          });
        }

        if (!isEmpty(data.tenant)) {
          pillData.push({
            label: getFullName(data.tenant),
            variant: "pink",
          });
        }

        if (!isEmpty(data.tenant)) {
          pillData.push({
            label: data.category.name,
            variant: "blue",
          });
        }
        break;

      case "Item":
        if (data.sellingRate && data.uom) {
          pillData.push({
            label: `${data.sellingRate} AED / ${data.uom}`,
            variant: "pink",
          });
        }

        if (!isEmpty(data.itemCategory)) {
          pillData.push({
            label: data.itemCategory.name,
            variant: "blue",
          });
        }
        break;

      case "TechnicianSkill":
        if (data.time) {
          pillData.push({
            label: data.time,
            variant: "success",
          });
        }

        if (data.perHour) {
          pillData.push({
            label: `${data.perHour} AED / hr`,
            variant: "pink",
          });
        }

        if (!isEmpty(data.category)) {
          pillData.push({
            label: data.category[0].name,
            variant: "blue",
          });
        }
        break;

      case dynamicObjectMap.get("BlanketAgreementObjectName"):
        if (!isEmpty(data.category)) {
          pillData.push({
            label: data.category.name,
            variant: "success",
          });
        }
        if (!isEmpty(data.supplier)) {
          pillData.push({
            label: data.supplier.name,
            variant: "pink",
          });
        }
        break;
      default:
        break;
    }

    if (data.status) {
      pillData.push({
        label: data.status,
        variant: "blue",
      });
    }

    return pillData;
  }, []);

  return (
    <div data-testid={getTestId(`${testId}-Option`)}>
      <components.Option {...props}>
        <div className="react-select-option">
          <p className="d-flex justify-content-between align-items-center">
            {data.label}
            {number && <Pill text={number} variant="primary" />}
          </p>
          {pills.length ? (
            <div className="d-flex" style={{ gap: "4px" }}>
              {pills.map((pill, index) => (
                <Pill key={`pill-${index}`} text={pill.label} variant={pill.variant} />
              ))}
            </div>
          ) : null}
          {data.subtitle && <p>{data.subtitle}</p>}
        </div>
      </components.Option>
    </div>
  );
}

Option.propTypes = {
  data: PropTypes.object.isRequired,
};

function LookupField({
  name,
  field,
  value,
  onChange,
  error,
  placeholder,
  disabled,
  showLabel,
  showCreateNew,
  apiFilters,
  formatOption,
  allowMultiple,
  isChildField,
  relationalFieldState,
  parentId,
  isEditing,
  formState,
  parentState,
  handleDispatch,
  ...rest
}) {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  const navigate = useNavigate();
  const { setPreviousFormState } = usePreviousFormState();
  const parentFormState = formState;
  const hasParentField = Boolean(field?.lookupFieldParent?.length);
  // TODO: refactor unique options
  const filterOption = useCallback(
    (option) => {
      const selectedIds = relationalFieldState.map((item) => item[field.name]?.value);
      return !selectedIds.includes(option.value);
    },
    [relationalFieldState]
  );

  const getFilterProps = useCallback(() => {
    if (field.camelizedName === "technicianSkill") {
      return {};
    }
    if (rest.filterOption) {
      return {
        filterOption: rest.filterOption
      };
    }
    if (isChildField && field.unique) {
      return {
        filterOption,
      };
    }

    if (field.isStaticObject) {
      return {};
    }

    return {
      filterOption: () => true,
    };
  }, [isChildField, field.unique, filterOption]);

  const handleInputChange = useCallback((inputValue) => {
    if (!field.isStaticObject) {
      setSearch(inputValue);
    }
  }, []);

  const handleOnChange = (selectValue) => {
    let isCreateNewSelected = false;
    if (field.allowMultiple && Array.isArray(selectValue)) {
      const selectedValues = selectValue?.map((v) => v.value);
      if (selectedValues.includes("new")) {
        isCreateNewSelected = true;
      }
    }

    if (!field.allowMultiple && selectValue?.value === "new") {
      isCreateNewSelected = true;
    }

    if (isCreateNewSelected) {
      const newFormParams = {
        createObjectName: field.lookupObjectName,
        createObjectFieldName: field.name,
        createObjectDisplayFieldName: field.lookupDisplayFieldName[0],
        createObjectAllowMultiple: field.allowMultiple,
      };

      if (formState) {
        setPreviousFormState(formState);
      }

      const newFormUrl = createNewObjURL(newFormParams);

      navigate(newFormUrl);
      return;
    }

    onChange(selectValue);
  };

  const lookupFieldFilters = useMemo(() => {
    if (field.drawerField) {
      return {};
    }
    const fieldFilters = {};

    if (!field) return fieldFilters;

    field?.lookupFieldFilter.forEach(({ fieldName, value: filterValue, operator }) => {
      if (filterValue === "@currentvalue") {
        if (isEditing && parentId) {
          fieldFilters[fieldName] = parentId;
        }
      } else if (operator === "in") {
        const filterKey = `${fieldName}[in]`;
        fieldFilters[filterKey] = filterValue;
      } else {
        fieldFilters[fieldName] = filterValue;
      }
    });

    return fieldFilters;
  }, [field?.lookupFieldFilters]);

  const parentFieldFilters = useMemo(() => {
    const fieldFilters = {};

    if (!field) return fieldFilters;

    if (hasParentField) {
      field.lookupFieldParent.forEach(({ parentField, parentObjectField }) => {
        if (parentField && parentObjectField && parentFormState) {
          const parentFieldValue = parentFormState[camelize(parentField)];

          if (parentFieldValue) {
            fieldFilters[parentObjectField] = parentFieldValue?.value;
          }
        }
      });
    }

    return fieldFilters;
  }, [parentFormState, field.lookupFieldParent]);

  const isDisabled = hasParentField && isEmpty(parentFieldFilters) && !field.drawerField;

  return (
    <>
      <FormControl>
        {showLabel ? (
          <FieldLabel
            label={field.label || startCase(field.name)}
            labelFor={field.name}
            helperText={field.helperText}
            isRequired={field.required}
          />
        ) : null}
        <Select
          id={name}
          field={field}
          name={field.name}
          lookupObjectName={field.lookupObjectName}
          lookupDisplayFieldName={field.lookupDisplayFieldName}
          value={value || null}
          onChange={handleOnChange}
          isMulti={allowMultiple && field.allowMultiple}
          placeholder={placeholder}
          isClearable
          backSpaceRemovesValue
          isDisabled={isDisabled || disabled}
          optionLabelKey={camelize(field.lookupDisplayFieldName[0])}
          optionValueKey="id"
          dataAccessKey="data"
          showCreateNew={!field.isStaticObject && showCreateNew}
          onInputChange={handleInputChange}
          formatOption={formatOption}
          handleDispatch={handleDispatch}
          styles={menuStyles}
          formState={formState}
          componentOptions={{
            Option: (props) => Option({ lookupObjectName: field.lookupObjectName, ...props }),
          }}
          optionsLoader={
            field.isStaticObject ?
              () =>
                getStaticObjectRecords(field.applicationName, field.lookupObjectName, {
                  ...lookupFieldFilters,
                  ...parentFieldFilters,
                  ...apiFilters
                }) :
              () => getDynamicObjectRecords(field.lookupObjectName, {
                ...lookupFieldFilters,
                ...parentFieldFilters,
                [`${field.lookupDisplayFieldName[0]}[like]`]: `'%${debouncedSearch}%'`,
                ...apiFilters,
                selectFields: `Key,Number,${field.lookupDisplayFieldName
                  .map((n) => pascalize(n))
                  .join(",")}`,
                sort_by: "CreatedAt",
                sort_type: "DESC",
                take_page: 1,
                limit_page: objectNamesWithFullOptions.includes(field.lookupObjectName) ?
                  undefined :
                  5,
                queryMode: "Deep"
              })
              }
          apiFilters={
            {
              ...lookupFieldFilters,
              ...parentFieldFilters,
              [`${field.lookupDisplayFieldName[0]}[like]`]: field.camelizedName === "technicianSkill" ? "" : `'%${debouncedSearch}%'`,
              ...apiFilters,
            }
               }
          {...getFilterProps()}
          {...rest}
        />
      </FormControl>
      {typeof error === "string" && <span className="error-text">{error}</span>}
    </>
  );
}

LookupField.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.oneOf([null])]),
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showLabel: PropTypes.bool,
  showCreateNew: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  apiFilters: PropTypes.object,
  formatOption: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  isChildField: PropTypes.bool,
  disabled: PropTypes.bool,
  relationalFieldState: PropTypes.array,
  formState: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  isEditing: PropTypes.bool,
  parentId: PropTypes.string,
};

LookupField.defaultProps = {
  value: null,
  onChange: () => {},
  name: "tuli-lookup-field",
  placeholder: "Select",
  error: false,
  showLabel: true,
  showCreateNew: false,
  allowMultiple: true,
  apiFilters: {},
  formatOption: null,
  isChildField: false,
  disabled: false,
  relationalFieldState: [],
  isEditing: false,
  parentId: "",
  formState: null,
};

LookupField.Type = "HydraValidatableComponent";

export default LookupField;
