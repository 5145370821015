import { Radio } from "@hydra/atom/components";
import { SvgIcon } from "@/components/common";

function RadioWithIcon({
  label, helperText, icon, optionValue, value, onChange, disabled, testId
}) {
  const LabelNode = (
    <div className="radio-label-container">
      <SvgIcon className="field-icon" icon={icon || "layers-icon"} />
      <div className="radio-label">
        <span>{label}</span>
        {helperText ? <p>{helperText}</p> : null}
      </div>
    </div>
  );

  return (
    <div className={`radio-option-container ${value === optionValue && "checked"}`}>
      <Radio
        label={LabelNode}
        itemValue={optionValue}
        type="radio"
        value={value}
        onChange={!disabled ? onChange : () => {}}
        testId={testId}
        disabled={disabled}
      >
        <Radio.CheckIcon>
          <span className="material-icons">circle</span>
        </Radio.CheckIcon>
      </Radio>
    </div>
  );
}

export default RadioWithIcon;
