import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

export const dateFormatOptions = [
  {
    label: "y/MM/dd",
    value: "yyyy-MM-dd",
  },
];

export const geoLocationFieldRadioOptions = [
  {
    stateKey: "geoLocationDisplayFormat",
    value: "Degrees, Minutes, seconds",
    label: "Degrees, Minutes, Seconds",
    helperText: null,
  },
  {
    stateKey: "geoLocationDisplayFormat",
    value: "Decimal",
    label: "Decimal",
    helperText: null,
  },
];

export const imageFileTypes = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/svg",
  "png",
  "jpg",
  "jpeg",
  "gif",
  "svg",
];

export const allowedFileTypeOptions = [
  {
    label: "Allow All",
    value: "*",
  },
  {
    label: "png",
    value: "png",
  },
  {
    label: "jpg",
    value: "jpg",
  },
  {
    label: "jpeg",
    value: "jpeg",
  },
  {
    label: "svg",
    value: "svg",
  },
  {
    label: "webp",
    value: "webp",
  },
];

export const sectionTypeIcons = (index) => {
  const icons = ["scan-icon", "frame-icon", "check-box-icon", "disc-icon"];

  return icons[index % icons.length];
};

export const objectWithFullNameFields = [
  dynamicObjectMap.get("TenantObjectName"),
  dynamicObjectMap.get("EmployeeObjectName"),
  dynamicObjectMap.get("LeadObjectName"),
  dynamicObjectMap.get("LandlordObjectName"),
];

export const statusFieldNames = ["status", "priority", "jobStatus"];

export const validationMessages = {
  required: "This field is required",
  min: "The value must be greater than min.",
  minLen: "The value must be greater than min. length",
  max: "The value must be greater than max.",
  maxLen: "The value must be greater than max. length",
  email: "Please enter a valid email address",
  url: "Please enter a valid url",
  accepted: "Please accept this to continue",
};
