import { useState } from "react";
import PropTypes from "prop-types";

import FileUploadInput from "./FileUploadInput";
import FileViewer from "./FileViewer";
import { getTestId } from "@/utils/helpers";

function FileUploader({
  buttonText,
  buttonClass,
  setFiles,
  files,
  isEditing,
  allowMultiple,
  disabled,
  testId,
  required,
  handleFileUpload
}) {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const reset = () => {
    setIsUploading(false);
    setProgress(0);
  };

  const onFileUploaded = async (uploadedFiles) => {
    if (!isEditing) {
      if (allowMultiple) {
        setFiles([...files, ...uploadedFiles]);
      } else {
        setFiles([...uploadedFiles]);
      }
      return;
    }

    if (handleFileUpload) {
      setIsUploading(true);
      let uploadedFileKeys = [];

      try {
        const response = await handleFileUpload(uploadedFiles, setProgress);
        uploadedFileKeys = response;
        reset();
      } catch (error) {
        reset();
        return;
      }

      const newFiles = uploadedFileKeys.paths.map((fileKey, index) => ({
        url: fileKey,
        isImage: uploadedFiles[index].isImage,
      }));

      if (allowMultiple) {
        setFiles([...files, ...newFiles]);
      } else {
        setFiles([...newFiles]);
      }
    }
  };

  const removeFile = async (f) => {
    // TODO: handle delete file while editing
    const fData = files.filter((a) => a.url !== f.url);
    setFiles([...fData]);
  };

  return (
    <div className="file-uploader">
      <FileUploadInput
        buttonText={buttonText}
        onFileUploaded={onFileUploaded}
        buttonClass={`${buttonClass} button-file-upload`}
        allowMultiple={allowMultiple}
        disabled={disabled}
        isUploading={isUploading}
        uploadProgress={progress}
        testId={getTestId(testId)}
        required={required && !files.length}
      />
      <FileViewer
        isEditing={isEditing}
        attachments={files}
        onFileRemoved={removeFile}
        testId={getTestId(testId)}
      />
    </div>
  );
}

FileUploader.propTypes = {
  buttonText: PropTypes.string,
  files: PropTypes.array,
  setFiles: PropTypes.func,
  isEditing: PropTypes.bool,
  buttonClass: PropTypes.string,
  handleFileUpload: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  allowMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  required: PropTypes.bool
};

FileUploader.defaultProps = {
  buttonText: "Click to Upload",
  buttonClass: "",
  files: [],
  setFiles: () => {},
  isEditing: false,
  handleFileUpload: null,
  allowMultiple: false,
  disabled: false,
  testId: "",
  required: false
};

export default FileUploader;
