export const mdFieldColumnsMap = new Map([
  ["number", 3],
  ["area", 3],
  ["percentage", 3],
  ["money", 3],
  ["time", 3],
  ["date", 3],
  ["phone", 6],
  ["text", 6],
  ["url", 6],
  ["email", 6],
  ["geolocation", 6],
  ["select", 6],
  ["lookup", 6],
  ["attachment", 12],
  ["checkbox", 12],
  ["textarea", 12],
  ["richtext", 12],
  ["table", 12],
]);

export const smFieldColumnsMap = new Map([
  ["number", 6],
  ["area", 6],
  ["percentage", 6],
  ["money", 6],
  ["time", 6],
  ["date", 6],
  ["phone", 12],
  ["text", 12],
  ["url", 12],
  ["email", 12],
  ["geolocation", 12],
  ["select", 12],
  ["lookup", 12],
  ["attachment", 12],
  ["checkbox", 12],
  ["textarea", 12],
  ["richtext", 12],
  ["table", 12],
]);
