let dbOpenRequest;
let db;
let version = 1;
const stores = {
  PushNotifications: "pushNotification"
};

export function initIDB() {
  if (!("indexedDB" in window)) {
    console.log("This browser doesn't support IndexedDB.");
    return false;
  }

  return new Promise((resolve) => {
    dbOpenRequest = indexedDB.open("tuliDB");

    dbOpenRequest.onupgradeneeded = () => {
      db = dbOpenRequest.result;

      if (!db.objectStoreNames.contains(stores.PushNotifications)) {
        db.createObjectStore(stores.PushNotifications, { keyPath: "email" });
      }
    };

    dbOpenRequest.onsuccess = () => {
      db = dbOpenRequest.result;
      version = db.version;
      resolve(true);
    };

    dbOpenRequest.onerror = () => {
      resolve(false);
    };
  });
}

export const addDataToIDB = (storeName, data) => new Promise((resolve) => {
  dbOpenRequest = indexedDB.open("tuliDB", version);

  dbOpenRequest.onsuccess = () => {
    db = dbOpenRequest.result;
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    store.add(data);
    resolve(data);
  };

  dbOpenRequest.onerror = () => {
    const error = dbOpenRequest.error?.message;
    if (error) {
      resolve(error);
    } else {
      resolve("Unknown error");
    }
  };
});

export const getDataByKeyFromIDB = (storeName, key) => new Promise((resolve) => {
  dbOpenRequest = indexedDB.open("tuliDB");

  dbOpenRequest.onsuccess = () => {
    db = dbOpenRequest.result;
    const tx = db.transaction(storeName, "readonly");
    const store = tx.objectStore(storeName);
    const res = store.get(key);
    res.onsuccess = () => {
      resolve(res.result);
    };
  };
});

export const deleteDataFromIDB = (storeName, key) => new Promise((resolve) => {
  dbOpenRequest = indexedDB.open("tuliDB", version);

  dbOpenRequest.onsuccess = () => {
    db = dbOpenRequest.result;
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    const res = store.delete(key);

    res.onsuccess = () => {
      resolve(true);
    };
    res.onerror = () => {
      resolve(false);
    };
  };
});
