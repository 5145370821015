import { useReducer, useEffect, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { camelize } from "humps";

import { Loader } from "@hydra/atom/components";
import { BoxedContent, Header } from "@/components/common";

import {
  companySettingsReducer,
  initialState,
  setInitialState,
} from "@/reducers/admin/companySettingsReducer";
import {
  GeneralSettings,
  FinancialInformation,
  UploadDocuments,
  Alerts,
  HeaderSteps,
} from "@/components/admin";
import { formatGLAccountOption } from "@/utils/finance/helpers";
import { useScrollToTop } from "@/hooks";
import { getCompanyAccounts } from "@/api/admin/companyAccountApi";
import { getCompanyById } from "@/api/finance/companyApi";
import {
  formatCompanySetting
} from "@/utils/admin/helpers";

function CompanySettings() {
  const [state, dispatch] = useReducer(companySettingsReducer, initialState);
  const { scrollToTopRef } = useScrollToTop(state.activeStep);
  const params = useParams();
  const companyId = params?.id;

  const { data, isInitialLoading } = useQuery(
    ["company", companyId],
    () => getCompanyById(companyId),
    {
      enabled: Boolean(companyId),
    }
  );

  const { data: companyAccounts, isInitialLoading: isLoadingCompanyAccounts } = useQuery(
    ["company-account", { companyId, isLinkedWithRecord: false }],
    () => getCompanyAccounts({ companyId, isLinkedWithRecord: false }),
    {
      enabled: Boolean(companyId),
    }
  );

  const formatCompanyAccounts = useCallback(() => {
    const accountsData = {};
    const accountsKeys = {};

    companyAccounts?.data?.forEach((account) => {
      const { value } = formatGLAccountOption(account.account);
      accountsData[camelize(account.accountType)] = value;
      accountsKeys[camelize(account.accountType)] = account.account.id;
    });
    return {
      accountsData,
      accountsKeys,
    };
  }, [isLoadingCompanyAccounts, companyId]);

  useEffect(() => {
    if (data?.data && companyAccounts?.data) {
      const { companySettings } = data.data;
      const formattedStepOneData = formatCompanySetting(companySettings);
      const { accountsData, accountsKeys } = formatCompanyAccounts();
      const final = {
        ...initialState,
        ...formattedStepOneData,
        ...accountsData,
        accountsKeys,
        generalSettingsEditStatus: true,
        companyId: companySettings.id,
      };

      dispatch(setInitialState(final));
    }
  }, [isInitialLoading, isLoadingCompanyAccounts, companyId]);

  const leftContent = (title, subtitle) => (
    <div className="container-header-left-content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );

  if (isInitialLoading || isLoadingCompanyAccounts) {
    return (
      <BoxedContent>
        <Loader />
      </BoxedContent>
    );
  }

  return (
    <BoxedContent>
      <div ref={scrollToTopRef}>
        <Header
          showBreadcrumb
          leftContent={leftContent("Company Settings", "You can see your company settings here")}
        />
        <HeaderSteps state={state} dispatch={dispatch} />
        {state.activeStep === 1 ? <GeneralSettings state={state} dispatch={dispatch} /> : null}
        {state.activeStep === 2 ? <FinancialInformation state={state} dispatch={dispatch} /> : null}
        {state.activeStep === 3 ? <UploadDocuments state={state} dispatch={dispatch} /> : null}
        {state.activeStep === 4 ? <Alerts state={state} dispatch={dispatch} /> : null}
      </div>
    </BoxedContent>
  );
}

export default CompanySettings;
