import { useRef } from "react";
import { Button } from "@hydra/atom/components";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { kebabCase } from "lodash";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import DynamicObjectOverview, { defaultComponents } from "@/pages/dynamic/list-views/DynamicObjectOverview";
import { SvgIcon } from "@/components/common";

function HeaderRightContent(props) {
  const fileInputRef = useRef(null);
  const queryClient = useQueryClient();

  const onUploadFile = () => {
    queryClient.invalidateQueries({
      queryKey: [kebabCase(dynamicObjectMap.get("LeadObjectName"))],
    });
    toast.success("Leads are imported successfully!");
  };

  return (
    <defaultComponents.HeaderRightContent {...props}>
      <input
        type="file"
        ref={fileInputRef}
        onChange={onUploadFile}
        style={{ display: "none" }}
        accept=".csv"
      />
      <Button
        small
        onClick={() => fileInputRef.current.click()}
        className="btn-with-icon"
        bordered
      >
        <Button.Prepend>
          <SvgIcon icon="upload-cloud-stroke-icon" />
        </Button.Prepend>
        Import
      </Button>
    </defaultComponents.HeaderRightContent>
  );
}

function LeadOverview() {

  return (
    <DynamicObjectOverview
      objectName={dynamicObjectMap.get("LeadObjectName")}
      components={{
        HeaderRightContent
      }}
      showFilters
    />
  );
}

export default LeadOverview;
