import PropTypes from "prop-types";

function Status({
  children, status, baseClassName, className, bgColor,
}) {
  const getClassName = () => {
    if (className) {
      return `${baseClassName} ${className}`;
    }

    return `${baseClassName}`;
  };

  return (
    <div
      className={getClassName()}
      style={bgColor ? { color: bgColor, backgroundColor: `${bgColor}18` } : {}}
    >
      {children || null}
      <span>{status}</span>
    </div>
  );
}

Status.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  status: PropTypes.string,
  baseClassName: PropTypes.string,
  className: PropTypes.string,
  bgColor: PropTypes.string,
};

Status.defaultProps = {
  children: null,
  status: "",
  baseClassName: "status-with-circle",
  className: "",
  bgColor: "",
};

export default Status;
