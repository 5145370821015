import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Loader, Tab } from "@hydra/atom/components";
import { useEffect, useRef, useState } from "react";
import { NotificationItem, formatNotification } from "@/components/dashboard/NotificationItem";
import { getNotifications, setNotificationStatusBulk } from "@/api/user/notificationApi";

import { BoxedContent, Header } from "@/components/common";
import { selectIsLoggedIn } from "@/store/userSlice";
import { useUser } from "@/hooks";
import NoDataFound from "@/components/common/layout/NoDataFound";

function Notifications() {
  const { user } = useUser();
  const ref = useRef(null);
  const { userId } = user;
  const queryClient = useQueryClient();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const tabs = ["All Notifications", "Tasks", "Facility Management", "Leasing"];

  const filterTabs = (value) => {
    switch (value) {
      case "All Notifications":
        return null;
      case "Tasks":
        return "tasks";
      case "Facility Management":
        return "FacilityManagement";
      case "Leasing":
        return "Leasing";
      default:
        return null;
    }
  };
  const {
    isInitialLoading,
    data: notifications,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ["notifications", userId, tabs[activeTabIndex]],
    ({ pageParam }) => getNotifications({
      page: pageParam,
      applicationName: filterTabs(tabs[activeTabIndex])
    }),
    {
      getNextPageParam: (lastPage) => {
        const { page: currentPage, pageSize, totalCount } = lastPage;

        if (!currentPage || !pageSize || !totalCount) {
          return undefined;
        }

        const totalPages = Math.ceil(totalCount / pageSize);

        if (currentPage < totalPages) {
          return currentPage + 1;
        }

        return undefined;
      },
      enabled: isLoggedIn,
      keepPreviousData: true,
    }
  );

  const observerCallback = (entries) => {
    const [entry] = entries;

    if (entry.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  };

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };

  const setNotificationsUnread = async (isReadNotifications) => {
    await setNotificationStatusBulk({
      AppNotificationIds: isReadNotifications,
      Status: true
    });
    queryClient.refetchQueries({
      queryKey: ["unread-notifications-count"],
    });
  };

  useEffect(() => {
    if (!notifications?.pages) return;
    const isReadNotifications = notifications?.pages?.[0]?.data.filter(
      (item) => !item.isRead).map((notificationItem) => notificationItem?.id);
    if (isReadNotifications?.length > 0) {
      setNotificationsUnread(isReadNotifications);
    }

  }, [notifications?.pages]);

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, options);
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  const notificationsData = notifications?.pages ?
    notifications?.pages?.flatMap((p) => p?.data?.flatMap((d) =>
      formatNotification(d))) :
    [];

  const leftContent = (title, subtitle) => (
    <div className="container-header-left-content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );

  return (
    <BoxedContent>
      <div>
        <Header
          showBreadcrumb
          leftContent={leftContent("Notifications", "You can see your notifications here")}
        />
        {isInitialLoading ? (
          <Loader />
        ) : (
          <>
            <div className="row mt-5">
              <div className="col-md-12">
                <Tab
                  className="table-tabs-container"
                  tabs={tabs}
                  onClick={setActiveTabIndex}
                />
              </div>
            </div>
            <div className="col-md-12 mt-3">
              {
                notificationsData.length > 0 ? notificationsData?.map((item, index, array) => (
                  <NotificationItem
                    key={index}
                    {...item}
                    isLastItem={index === array.length - 1}
                  />
                )) : (
                  <NoDataFound
                    title="You don't have any notifications"
                    objectName="Notification"
                  />
                )
              }
              {(!(isInitialLoading || isFetchingNextPage)) && <div className="observer" ref={ref} />}
            </div>

          </>
        )}
      </div>
    </BoxedContent>
  );
}

export default Notifications;
