import PropTypes from "prop-types";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";
import { handleDrawer } from "@/utils/modal/helpers";

function LinkCell({ value, row }) {
  return row?.original?.lookupObjectKey ? (
    <div className="link-cell">
      <Link to={handleDrawer(row.original.lookupObjectKey)}>
        {value}
      </Link>
    </div>
  ) :
    value;
}

LinkCell.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
};

export const getDynamicObjectFieldsColumns = () => {
  const fieldObj = [
    {
      Header: "Name",
      accessor: "name",
      Cell: LinkCell,
    },
    {
      Header: "Label",
      accessor: "label",
    },
    {
      Header: "Field Type",
      accessor: "fieldType",
    }
  ];

  return fieldObj;
};

export const getDynamicObjectFieldsData = (data, hideActionColumn) => data.map((field) => {
  const fieldObj = {
    id: field.id,
    name: field.fieldName,
    label: field.label,
    lookupObjectKey: field.lookupObjectKey,
    fieldType: capitalize(field.fieldType),
  };

  if (!hideActionColumn) fieldObj.action = "";

  return fieldObj;
});
