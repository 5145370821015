import { useSelector } from "react-redux";

import { getUserType, getToken } from "@/api/user/authApi";
import { selectUser } from "@/store/userSlice";

export default function useUser() {
  const userType = getUserType();
  const token = getToken();
  const user = useSelector(selectUser);

  return {
    user,
    userType,
    token
  };
}
