import { getColumnWidth } from "../helpers";

const COL_WIDTH = 135;

export default function getProfitAndLossVsActualColumns(windowWidth) {
  return [
    {
      columnId: "titlesColumn",
      width: 300
    },
    { columnId: 1, width: 300 },
    { columnId: 2, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 3, windowWidth) },
    { columnId: 3, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 3, windowWidth) },
    { columnId: 4, width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 3, windowWidth) }
  ];
}
