/* eslint-disable no-plusplus */
import { pascalize } from "humps";
import {
  emptyTextCell,
  nonEditable,
  textCell,
  getHeaderRow,
  chevronCell
} from "../cells";
import { HEADING_ROW_HEIGHT } from "../constants";
import { formatCurrency } from "@/utils/helpers";

export const CASHINCHANGE_ROW_ID = "cashInChange";

const transformAccountsToRows = (accounts, parentId = null, rowId = 0) => {
  let rId = rowId;
  let rows = [];
  accounts.forEach((account, index) => {
    const currentRowId = rId + index;
    const hasChildren = account.accounts && account.accounts.length > 0;
    const balanceText = !hasChildren ? account.balance : "";
    const row = {
      rowId: currentRowId,
      height: HEADING_ROW_HEIGHT,
      cells: [
        chevronCell({
          text: account.groupHeader || account.name,
          parentId,
          isExpanded: true,
          hasChildren
        }),
        nonEditable(textCell({ text: `${formatCurrency(balanceText)}` }), "font-bold trial-balance-row justify-content-start")
      ]
    };
    rows.push(row);

    if (hasChildren) {
      const { rows: childRows } = transformAccountsToRows(account.accounts,
        currentRowId,
        currentRowId + 1);
      rows = rows.concat(childRows);
      rId += childRows.length;
    }
  });

  return { rows, rowId: rId };
};

function getAccountsRows(title, rowsData) {
  const result = [];
  let rowNumber = 0;

  const titleRow = [
    {
      rowId: `${title}${++rowNumber}`,
      height: HEADING_ROW_HEIGHT,
      cells: [
        chevronCell({
          text: title,
          parentId: null,
          isExpanded: true,
          hasChildren: true
        }),
        nonEditable(emptyTextCell(), ""),
      ]
    },
  ];

  if (rowsData) {
    const { rows: accountsRows, rowId } = transformAccountsToRows(rowsData?.accounts,
      `${title}${1}`,
      `${title}${++rowNumber}`);
    const netCashRow = [
      {
        rowId: `${title}${++rowNumber}`,
        height: HEADING_ROW_HEIGHT,
        cells: [
          chevronCell({
            text: "Net Cash",
            parentId: `${title}${1}`,
            hasChildren: false,
            className: "font-bold trial-balance-row justify-content-start"
          }),
          nonEditable(textCell({ text: `${formatCurrency(rowsData.netCashInCurrency)}` }), "font-bold trial-balance-row justify-content-start"),
        ]
      },
    ];
    result.push(...titleRow, ...accountsRows, ...netCashRow);
    rowNumber = rowId;
  }
  return result;
}

function formatString(inputString) {
  return inputString.replace(/([A-Z])/g, " $1").trim();
}

function getNetCashFooter(rowsData) {
  let rowNumber = 0;
  const result = [];
  if (rowsData) {
    const tempArr = Object.entries(rowsData).map(([key, value]) =>
      ({
        rowId: `${key}${++rowNumber}`,
        height: HEADING_ROW_HEIGHT,
        cells: [
          nonEditable(textCell({ text: formatString(pascalize(key)) }), "font-bold trial-balance-row justify-content-start"),
          nonEditable(textCell({ text: `${formatCurrency(value)}` }), "font-bold trial-balance-row justify-content-start"),
        ]
      })
    );
    result.push(...tempArr);
  }

  return result;
}

export function getCashFlowRows({
  data,
  columns
}) {
  if (!data) {
    return [];
  }
  const {
    operatingActivities, investingActivities, financingActivities, cashFlow
  } = data.data;

  return [getHeaderRow(columns),
    ...getAccountsRows("Operative Activities", operatingActivities),
    ...getAccountsRows("Investing Activities", investingActivities),
    ...getAccountsRows("Financing Activities", financingActivities),
    ...getNetCashFooter(cashFlow)
  ];
}
