import { useRef } from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import {
  Header, CrossButton, Drawer, ActivityTimeline
} from "@/components/common";
import { handleDrawer } from "@/utils/modal/helpers";

export default function WorkflowActivityDrawer({ activities, title, className }) {
  const ref = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const drawers = JSON.parse(searchParams.get("workflowActivityDrawers") || null) || [];

  const handleClose = () => {
    handleDrawer(null, setSearchParams, true, "workflowActivityDrawers");
  };

  return (
    <Drawer
      isOpen={drawers.at(-1)}
      overlay
      size={400}
      className="drawer-border drawer-border-activity"
      onClose={handleClose}
    >
      <div className="activity-drawer mobile-responsive-drawer">
        <Header
          leftContent={<h6>{title}</h6>}
          rightContent={<CrossButton onClick={handleClose} />}
        />
        <ActivityTimeline ref={ref} activities={activities} className={className} />
      </div>
    </Drawer>
  );
}

WorkflowActivityDrawer.propTypes = {
  activities: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
};

WorkflowActivityDrawer.defaultProps = {
  activities: [],
  title: "Activity Log",
  className: "work-flow-activity-timeline-container",
};
