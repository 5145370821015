import {
  useState, useRef, useMemo, useEffect
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input, Button, Loader, FormControl
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import {
  SvgIcon, CustomDropdown, CustomizedDateRangePicker, IconButton
} from "@/components/common";
import { getTrialBalanceRows, getTrialBalanceColumns } from "./trial-balance-statement";
import { ReportFilterDrawer } from "../../drawers";
import { getTrialBalance } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";
import { formatApiPayloadDate } from "@/utils/helpers";
import appSettings from "@/settings";

function ReportTrialBalance({ accountingPeriod: accountingPeriodData, activeCompany }) {
  const [screenWidth] = useState(window.innerWidth);
  const ref = useRef();
  const [filterText, setFilterText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [accountingPeriod, setAccountingPeriod] = useState(null);
  const [columnsData, setColumnsData] = useState(getTrialBalanceColumns(screenWidth)
  );
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [filters, setFilters] = useState({});
  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);

  const onClose = (value) => {
    setIsOpen(value);
  };

  const exportCsv = () => window.open(`${appSettings.baseUrl}/finance/reports/trial-balance/csv?CompanyId=${activeCompany.id}&StartDate=${formatApiPayloadDate(dateRange[0])}&EndDate=${formatApiPayloadDate(dateRange[1])}`, "_blank", "noopener,noreferrer");
  const exportExcel = () => window.open(`${appSettings.baseUrl}/finance/reports/trial-balance/xls?CompanyId=${activeCompany.id}&StartDate=${formatApiPayloadDate(dateRange[0])}&EndDate=${formatApiPayloadDate(dateRange[1])}`, "_blank", "noopener,noreferrer");

  useEffect(() => {
    setAccountingPeriod(accountingPeriodData);
  }, [accountingPeriodData]);

  const { data: getTrialBalanceData, isLoading } = useQuery(["trial-balance", dateRange],
    () => getTrialBalance({
      startDate: formatApiPayloadDate(dateRange[0]),
      endDate: formatApiPayloadDate(dateRange[1]),
    }),
    {
      enabled: Boolean(accountingPeriod?.value)
    });

  const formatReport = (data) => {
    if (filterText && data) {
      return data.filter(
        (obj) => obj.name.toLowerCase().includes(filterText.toLowerCase()) ||
          obj.code.includes(filterText)
      );
    }
    return data;
  };

  const rows = useMemo(
    () =>
      getTrialBalanceRows({
        data: {
          data: formatReport(getTrialBalanceData?.data) || [],
          totalCredit: getTrialBalanceData?.totalCredits,
          totalDebit: getTrialBalanceData?.totalDebits
        },
        columns,
      }),
    [getTrialBalanceData, columns, accountingPeriod, filterText]
  );

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  if (getTrialBalanceData && accountingPeriod) {
    <Loader />;
  }
  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-3">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by code or name"
          />
        </div>
        <div className="col-md-3 general-date-range">
          <FormControl>
            <CustomizedDateRangePicker
              name="dateRange"
              value={dateRange}
              onChange={(value) => setDateRange(value)}
              required
            />
          </FormControl>
        </div>
        <div className="col-md-6 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportCsv()}
          >
            <SvgIcon icon="upload-cloud-stroke-icon" />
            <span>Download CSV</span>
          </IconButton>
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
          <Button
            onClick={() => setIsOpen(true)}
            small
            bordered
          >
            <Button.Prepend>
              <SvgIcon icon="filter-lines" />
            </Button.Prepend>
          </Button>
          <ReportFilterDrawer
            filters={filters}
            setFilters={setFilters}
            size={400}
            isOpen={isOpen}
            onClose={onClose}
          />
          {" "}

        </div>
      </div>
      <div className="react-grid-container">
        {!isLoading ? (
          <ReactGrid
            key={rows}
            rows={rows}
            columns={columns}
            onColumnResized={handleColumnResize}
          />
        ) : <TableSkeleton />}
      </div>
    </div>
  );
}

export default ReportTrialBalance;
