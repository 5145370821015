import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Input, Button, FormControl
} from "@hydra/atom/components";
import {
  BoxedContent, Header,
  FormLeftHeader,
  SvgIcon,
  CustomizedDateRangePicker,
  FileUploader
} from "@/components/common";
import DollarSign from "@/assets/images/breadcrumb-btn.png";
import {
  BankReconciliationTable,
} from "@/components/finance";
import { getTestId, formatDate } from "@/utils/helpers";

const perPageOptions = [
  { label: "12", value: 12 },
  { label: "24", value: 24 },
  { label: "36", value: 36 },
];

function HeaderRightContent() {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [filterText, setFilterText] = useState("");

  return (
    <div className="row filter-row">
      <div className="col-md-5">
        <Input
          className="input-height-fix"
          value={filterText}
          onChange={setFilterText}
          placeholder="Search by name"
          testId={getTestId("Bank-Reconciliation-Search-Button")}
        />
      </div>
      <div className="col-md-3 general-date-range">
        <FormControl>
          <CustomizedDateRangePicker
            name="dateRange"
            value={dateRange}
            onChange={(value) => setDateRange(value)}
            required
            testId={getTestId("Bank-Reconciliation-Date-Range-Button")}
          />
        </FormControl>
      </div>
      <div className="col-md-4 btn-icon-container btn-float-right">
        <Button
          testId={getTestId("Bank-Reconciliation-Export-Button")}
          onClick={() => {}}
          small
          bordered
        >
          <Button.Prepend>
            <SvgIcon icon="excel" />
          </Button.Prepend>
        </Button>
      </div>
    </div>
  );
}

function HeaderLeftContent({ title }) {
  return (
    <div className="left-content">
      <div className="header-title">{title}</div>
    </div>
  );
}

export default function BankReconciliation() {
  const [state,] = useState({
    statementDate: new Date()
  });
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [, setSearchParams] = useSearchParams();

  const leftContent = (title, subtitle) => (
    <div className="container-header-left-content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
    setSearchParams(`?page=${pageNumber}&pageSize=${pageSize.label}`);
  };

  const dummyData = Array.from({ length: 10 }, (_, index) => ({
    date: "29/01/2024",
    category1: "436",
    category2: "12344",
    category3: `Payee-${index + 1}`,
    category4: `Description-${index + 1}`,
    category5: {
      value: "153000",
      color: "success"
    },
    category6: {
      value: "125000",
      color: "error"
    },
    category7: { value: "1000", color: "primary" },
    category8: { value: "Matched", color: "success" },
    action: `Action-${index + 1}`,
  }));

  return (
    <BoxedContent className="bank-reconciliation">
      <Header
        showBreadcrumb
        leftContent={(
          <div>
            <h1>Bank Reconciliation</h1>
            <p className="sub-title">{`For period ${formatDate(state.statementDate)}`}</p>
          </div>
        )}
      />
      <div>
        <div className="setting-header">
          <Header
            leftContent={leftContent(
              "Upload Documents",
              "Get emails to find out what’s going on when you’re not online. You can turn them off anytime."
            )}
          />
          <hr className="full-hr" />
        </div>
        <div className="row">
          <div className="col-md-6">
            <FormLeftHeader
              title="Upload Bank Statement"
              subtitle="Select Tenant and Unit"
              icon="upload-cloud-stroke-icon"
            />
          </div>
          <div className="col-md-6 bank-rec-upload">
            <FileUploader
              files={state.uploadDocument || []}
              testId="Bank-Reconciliation-Upload"
            />
          </div>
          <div className="save-documents-container">
            <div className="bank-reconcile-save-btn">
              <Button
                small
                className="save-documents"
                onClick={() => {
                }}
                testId={getTestId("Bank-Reconciliation-Save-Button")}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        <hr className="full-hr" />
        <div className="summary-box">
          <div className="summary-rectangle">
            <div className="summary-heading">
              <div className="profile-avatar">
                <img src={DollarSign} alt="profile avatar" />
              </div>
              <div className="label">
                <div className="summary-text">Summary</div>
              </div>
            </div>
            <div className="frame">
              <div className="div">
                <div className="text-wrapper">Cheques</div>
                <div className="text-wrapper-2">82,987 AED</div>
              </div>
              <div className="frame-rectangle" />
              <div className="div">
                <div className="text-wrapper">Deposits/Credits</div>
                <div className="text-wrapper-2">90,987 AED</div>
              </div>
              <div className="frame-rectangle" />
              <div className="div">
                <div className="text-wrapper">Difference</div>
                <div className="text-wrapper-3">-16,890 AED</div>
              </div>
              <div className="frame-rectangle" />
              <div className="div">
                <div className="text-wrapper">Lorem</div>
                <div className="text-wrapper-3">-16,890 AED</div>
              </div>
              <div className="frame-rectangle" />
              <div className="div">
                <div className="text-wrapper">Lorem</div>
                <div className="text-wrapper-3">-16,890 AED</div>
              </div>
            </div>
          </div>
        </div>
        <BankReconciliationTable
          data={dummyData}
          headerRight={<HeaderRightContent />}
          headerLeft={
            <HeaderLeftContent title="Reconciliation" />
          }
          perPage={perPage}
          perPageOptions={perPageOptions}
          currentPage={currentPage}
          handlePagination={handlePagination}
          totalCount={1}
          isLoading={null}
          activeStatusTab="Bank Reconciliation"
        />
      </div>
    </BoxedContent>
  );
}
