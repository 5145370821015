import PropTypes from "prop-types";
import { multiSelectUserStyles } from "@/utils/select/constants";
import LookupField from "@/components/dynamic/fields/LookupField";
import { formatTechnician } from "@/components/facility/planboard/helpers";

Option.propTypes = {
  data: PropTypes.object.isRequired
};

function MultiSelectUser({
  state,
  value,
  onChange,
  placeholder,
  error,
  field,
  apiFilters
}) {
  return (
    <>
      <LookupField
        name={field.name}
        field={field}
        value={value}
        onChange={(option) => onChange(option)}
        placeholder={placeholder}
        showLabel={false}
        formatOption={formatTechnician}
        styles={multiSelectUserStyles}
        apiFilters={apiFilters}
        formState={state}
      />
      {typeof error === "string" && <span className="error-text">{error}</span>}
    </>
  );
}

MultiSelectUser.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  apiFilters: PropTypes.object,
};

MultiSelectUser.defaultProps = {
  placeholder: "Select team",
  error: false,
  apiFilters: {}
};

MultiSelectUser.Type = "HydraValidatableComponent";

export default MultiSelectUser;
