import { useState, useEffect } from "react";
import {
  Button, Loader,
} from "@hydra/atom/components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { useSearchParams } from "react-router-dom";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import {
  Header,
  BoxedContent,
  NoDataFound,
  HeaderLeftContent
} from "@/components/common";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getDynamicObjectRecords, getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import { getMatchingUnitColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import { generateEmail } from "@/api/user/notificationApi";
import showToast from "@/utils/toast/helpers";

function UnitMatching() {
  const [matchingUnitData, setMatchingUnitData] = useState([]);
  const [searchParams] = useSearchParams();
  const leadId = searchParams.get("lead");

  const saveMutation = useMutation(generateEmail, {
    onError: () => {
      showToast("Could not send. Try again!", "error");
    },
    onSuccess: () => {
      showToast("Matching units are sent to the lead", "success");
    },
  });

  const { data: leadData, isFetched } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("LeadObjectName")),
      leadId
    ],
    () =>
      getDynamicObjectRecordById(
        dynamicObjectMap.get("LeadObjectName"),
        leadId
      ),
    {
      enabled: Boolean(leadId),
    }
  );

  const { data: unitList, isLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("UnitObjectName")),
      "Unit",
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("UnitObjectName"), {
        takePage: 1,
        limitPage: 1000,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
        building: leadData.building.id,
        unitType: leadData.inquiry,
        status: "Available",
      }),
    {
      enabled: Boolean(isFetched),
    }
  );

  useEffect(() => {
    if (!unitList) return;
    const matchingUnitList = unitList.data.map((u) => {
      u.key = u.id;
      return u;
    });
    setMatchingUnitData(matchingUnitList);
  }, [unitList]);

  const selectDeselectAll = (selectAll) => {
    setMatchingUnitData(matchingUnitData.map((item) => ({ ...item, isSelected: selectAll })));
  };

  const sendEmail = () => {
    const selectedUnits = matchingUnitData.filter((item) => item.isSelected);
    if (selectedUnits?.length > 0 && leadData) {
      const mappedUnits = selectedUnits.map((unit) => ({
        Number: unit.number,
        Building: unit.building.name,
        Name: unit.name,
        Type: unit.unitType || "N/A"
      }));

      const data = {
        TemplateName: "Lead.UnitRecommendations",
        Recipients: [
          {
            Name: `${leadData.firstName} ${leadData.lastName}`,
            Email: leadData.email,
            RecipientType: "To"
          }
        ],
        DataObject: {
          Lead: {
            Name: `${leadData.firstName} ${leadData.lastName}`
          },
          Company: {
            Phone: leadData.phone
          },
          Unit: mappedUnits
        }
      };

      saveMutation.mutate(data);
    }
  };

  return (
    <BoxedContent className="unit-matching">
      <Header
        showBreadcrumb
        leftContent={<HeaderLeftContent title="Matching Units" subtitle="Select From Matching Units List" />}
        rightContent={(
          <div>
            {matchingUnitData && matchingUnitData.some((item) => item?.isSelected === true) && (
              <Button className="save-btn m-3" small onClick={() => sendEmail()}>
                Send Email
              </Button>
            )}
            <Button
              small
              className="save-btn m-3"
              onClick={() =>
                selectDeselectAll(!matchingUnitData.some((item) => item?.isSelected === true))}
            >
              {matchingUnitData.some((item) => item?.isSelected === true) ? "Deselect All" : "Select All"}
            </Button>
          </div>
        )}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {matchingUnitData.length ? (
            <TableWithCheckbox
              data={matchingUnitData}
              columns={getMatchingUnitColumns()}
              setData={setMatchingUnitData}
              allowMultiple
            />
          ) : (
            <NoDataFound
              title="No matching unit found"
            />
          )}
        </div>
      )}
    </BoxedContent>
  );
}

UnitMatching.propTypes = {};

export default UnitMatching;
