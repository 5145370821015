import { useMemo } from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";

export default function DashboardTable({
  data,
  columns
}) {
  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(() => columns, [columns]);

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } =
    useTable({
      columns: tableColumns,
      data: tableData,
    });

  return (
    <div className="table-wrapper">
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="table-header-row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className={`table-header-cell ${
                    column.collapse ? "collapse" : ""
                  }`}
                  {...column.getHeaderProps()}
                >
                  <div className="table-header-cell-content">
                    <span>{column.render("Header")}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr className="table-row" {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    className={`table-body-cell ${
                      cell.column.collapse ? "collapse" : ""
                    }`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

DashboardTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};
