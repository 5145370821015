import { isValid } from "date-fns";
import { Link } from "react-router-dom";
import { isEmpty, kebabCase, startCase } from "lodash";
import { pascalize } from "humps";
import { handleDrawer } from "@/utils/modal/helpers";
import { getObjectNameFromId } from "@/utils/dynamic/helpers";
import { formatDate } from "@/utils/helpers";

const staticObjectWithLinks = ["LedgerAccount", "TaxRule", "Company", "Currency"];

export const formatStaticObjectRecord = ({ data }) => {
  const recordData = [];

  const keys = Object.keys(data);

  keys.filter((k) => k !== "id" && !k.endsWith("Id")).forEach((key) => {
    const value = data[key];
    const label = startCase(kebabCase(key));

    const fieldValue = {
      label,
      value: "-",
    };

    if (!Array.isArray(value)) {
      if (typeof value === "object") {
        if (isEmpty(value)) {
          recordData.push(fieldValue);
          return;
        }

        fieldValue.value = value?.name;

        const id = value?.id;

        if (id) {
          const objectName = getObjectNameFromId(id);

          if (staticObjectWithLinks.includes(pascalize(objectName))) {
            fieldValue.isLink = true;
            fieldValue.id = id;
          }
        }

        recordData.push(fieldValue);
        return;
      }

      if (typeof value === "boolean") {
        fieldValue.value = value ? "Yes" : "No";
        recordData.push(fieldValue);
        return;
      }

      if (typeof value === "number") {
        fieldValue.value = value;
        recordData.push(fieldValue);
        return;
      }

      if (!value) {
        recordData.push(fieldValue);
        return;
      }

      if (isValid(new Date(value)) && key !== "code" && key !== "name") {
        fieldValue.value = formatDate(new Date(value));
        recordData.push(fieldValue);
        return;
      }

      if (typeof value === "string") {
        fieldValue.value = value;
        recordData.push(fieldValue);
      }
    }
  });

  return {
    recordData
  };
};

export const renderStaticFieldValue = (item) => {
  const { isLink, id, value } = item;

  if (isLink) {
    return <Link to={handleDrawer(id, null, false, "staticObjectDrawers")}>{value}</Link>;
  }

  return value;
};
