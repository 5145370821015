import React, { useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useTable, useSortBy } from "react-table";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "@tanstack/react-query";
import { pascalize } from "humps";
import { kebabCase, startCase } from "lodash";
import { Loader, Pagination } from "@hydra/atom/components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SortableArrows, NoDataFound } from "@/components/common";
import { InfoTable } from "@/components/facility/maintenance";
import { selectActiveApp } from "@/store/appSlice";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { getDynamicObjectFieldValue } from "./dynamicObjectOverviewTableData";
import intermediateObjectFieldNamesMap from "@/utils/maps/intermediateObjectFieldNamesMap";
import { formatDate } from "@/utils/helpers";

function LookupCell({ value }) {
  return <div className="lookup-cell">{value.label}</div>;
}

function DateCell({ value }) {
  return <span className="date-cell">{value ? formatDate(new Date(value)) : ""}</span>;
}

function SubRowAsync({ relationalField, row }) {
  const [relationalFieldValues, setRelationalFieldValues] = useState([]);

  const additionalFieldsKey = "fields";

  const columns = useMemo(() => {
    const columnsData = [];
    relationalField[additionalFieldsKey]?.filter((item) => !item.hidden)?.forEach((field) => {
      switch (field.objectFieldType.toLowerCase()) {
        case "attachment":
          break;

        case "lookup":
        case "polymorphic":
          if (field.lookupObjectName === "Company") {
            columnsData.push({
              Header: field.lookupObjectName,
              accessor: field.name,
              Cell: LookupCell,
            });
          } else {
            columnsData.push({
              Header: field.label,
              accessor: field.name,
              Cell: LookupCell,
            });
          }
          break;

        case "date":
          columnsData.push({
            Header: field?.label,
            accessor: field?.name,
            Cell: DateCell,
          });
          break;

        default:
          columnsData.push({
            Header: field.label,
            accessor: field.name,
          });
          break;
      }
    });

    return columnsData;
  }, []);

  const { isLoading: isLoadingValues } = useQuery(
    [
      kebabCase(relationalField.intermediateObjectName),
      row.original.key,
      {
        takePage: 1,
        limitPage: 100,
        sortBy: "CreatedAt",
        sortType: "DESC",
        [pascalize(intermediateObjectFieldNamesMap.get("DetailIdFieldName"))]: row.original.key,
      },
    ],
    () =>
      getDynamicObjectRecords(relationalField.intermediateObjectName, {
        takePage: 1,
        limitPage: 100,
        sortBy: "CreatedAt",
        sortType: "DESC",
        [pascalize(intermediateObjectFieldNamesMap.get("DetailIdFieldName"))]: row.original.key,
      }),
    {
      onSuccess: (data) => {
        const values = data?.data.map((d, index) => {
          const value = {};

          relationalField[additionalFieldsKey].forEach((field) => {
            value[field.name] = getDynamicObjectFieldValue(d, field);
          });

          value.key = d.id;
          value.id = index;

          return value;
        });

        setRelationalFieldValues(values);
      },
    }
  );

  if (isLoadingValues) {
    return (
      <tr key={`${relationalField.name}-${row.original.id}-loader`}>
        <td aria-label="Loader"><Loader /></td>
      </tr>
    );
  }

  return (
    <tr
      key={`${relationalField.name}-${row.original.id}`}
      style={{
        position: "relative",
        height: `${relationalFieldValues.length * 49 + 97}px`,
      }}
    >
      <td aria-label="info-table" style={{ position: "absolute", width: "100%" }}>
        <InfoTable data={relationalFieldValues} columns={columns} />
      </td>
    </tr>
  );
}

SubRowAsync.propTypes = {
  relationalField: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
};

export default function DynamicObjectOverviewTable({
  data,
  columns,
  fields,
  navigationLink,
  perPage,
  perPageOptions,
  handlePagination,
  totalCount,
  totalPages,
  currentPage,
  setSortByColumns,
  objectName,
  sortByColumns,
  isLoading,
  redirectLink,
  showPagination,
}) {
  const tableData = useMemo(() => data, [data]);
  const hasEmptyData = tableData.some((obj) => Object.keys(obj).length === 0);
  const activeApp = useSelector(selectActiveApp);
  const navigate = useNavigate();
  const tableColumns = useMemo(
    () =>
      (isLoading ?
        columns.map((column) => ({
          ...column,
          Cell: Skeleton,
        })) :
        columns),
    [isLoading, columns]
  );

  const relationalFields = useMemo(
    () => fields.filter((f) => ["table"].includes(f.objectFieldType.toLowerCase())),
    [fields]
  );

  const getRowState = useCallback(
    () =>
      data?.map(() => {
        const cellState = {};
        relationalFields?.forEach((m) => {
          cellState[m.name] = false;
        });
        return cellState;
      }),
    [data?.length]
  );

  const [rowState, setRowState] = useState({ ...getRowState() });

  const updateRowState = (rowIndex, columnId, value) => {
    const newRowState = { ...rowState };
    if (newRowState[rowIndex]) {
      newRowState[rowIndex] = {
        ...newRowState[rowIndex],
        [columnId]: value,
      };
    } else {
      newRowState[rowIndex] = {
        [columnId]: value,
      };
    }
    setRowState(newRowState);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
    toggleSortBy,
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      rowState,
      updateRowState,
      manualSortBy: true,
      initialState: {
        sortBy: sortByColumns,
      },
    },
    useSortBy
  );

  const handleSort = (column) => {
    if (!column.defaultCanSort) return;

    const sortedColumn = {};

    if (!column.isSorted) {
      sortedColumn.id = column.id;
      sortedColumn.desc = false;

      toggleSortBy(sortedColumn.id, sortedColumn.desc);
      setSortBy([sortedColumn]);
      setSortByColumns([sortedColumn]);
      return;
    }

    if (column.isSorted && !column.isSortedDesc) {
      sortedColumn.id = column.id;
      sortedColumn.desc = true;

      toggleSortBy(sortedColumn.id, sortedColumn.desc);
      setSortBy([sortedColumn]);
      setSortByColumns([sortedColumn]);
      return;
    }

    if (column.isSorted && column.isSortedDesc) {
      setSortBy([]);
      setSortByColumns([]);
    }
  };

  const onRowClick = (e, rowProps) => {
    if (redirectLink && redirectLink === "tenant-statement") {
      navigate(`${rowProps.original.key}`);
      return;
    }
    const { tagName, parentNode } = e.target;
    const parentClassName = parentNode?.className?.trim();
    const tagNames = ["BUTTON", "A", "svg"];
    const parentClassNames = [
      "table-row",
      "table-body-cell",
      "truncated-text-cell",
      "status-cell",
      "link-cell",
      "auto-number-cell",
    ];

    if (!tagNames.includes(tagName) && parentClassNames.includes(parentClassName)) {
      navigate(
        navigationLink ? `${navigationLink}/${rowProps.original.key}` : `/${kebabCase(activeApp?.value)}/${kebabCase(objectName)}/details/${
          rowProps.original.key
        }`
      );
    }
  };

  if (hasEmptyData) {
    return (
      <NoDataFound
        title={
          `No ${startCase(objectName)} has been added`
      }
        objectName={objectName}
      />
    );
  }
  return (
    <>
      <div className="overflow">
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr className="table-header-row" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={`table-header-cell ${column.collapse ? "collapse" : ""}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={() => handleSort(column)}
                  >
                    <div className="table-header-cell-content">
                      <span>{column.render("Header")}</span>
                      {column.defaultCanSort ? (
                        <SortableArrows
                          isSorted={column.isSorted}
                          isSortedDesc={column.isSortedDesc}
                        />
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                <React.Fragment key={`row-${index}`}>
                  <tr className={`table-row ${row.original?.isNewCreated ? "fade-out" : ""}`} {...rowProps} onClick={(e) => onRowClick(e, row)}>
                    {row.cells.map((cell) => (
                      <td
                        className={`table-body-cell ${cell.column.collapse ? "collapse" : ""}`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                  {relationalFields.map((relationalField) =>
                    (rowState[row.original.id] && rowState[row.original.id][relationalField.name] ?
                      (
                        <SubRowAsync
                          key={`${relationalField.name}-${row.original.id}-sub-row`}
                          relationalField={relationalField}
                          row={row}
                        />
                      ) : null)
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      {!isLoading && showPagination && (
        <Pagination
          className="dashboard-pagination"
          options={perPageOptions}
          perPage={perPage}
          onSelectChange={(val) => handlePagination(currentPage, val)}
          pageRangeDisplayed={3}
          pageNo={currentPage}
          handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
          showResults
          offset={0}
          totalItems={totalCount}
          pageCount={totalPages || Math.ceil(totalCount / perPage.value)}
          reactPaginateProps={{
            previousLabel: <span className="material-icons">&#xe5cb;</span>,
            nextLabel: <span className="material-icons">&#xe5cc;</span>,
            forcePage: currentPage - 1,
          }}
        />
      )}
    </>
  );
}

DynamicObjectOverviewTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  perPage: PropTypes.object,
  perPageOptions: PropTypes.array,
  handlePagination: PropTypes.func,
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  navigationLink: PropTypes.string,
  setSortByColumns: PropTypes.func,
  sortByColumns: PropTypes.array,
  isLoading: PropTypes.bool,
  showFilters: PropTypes.bool,
  showPagination: PropTypes.bool,
  redirectLink: PropTypes.string
};

DynamicObjectOverviewTable.defaultProps = {
  navigationLink: "",
  perPage: {},
  perPageOptions: [],
  handlePagination: () => {},
  totalCount: 0,
  totalPages: 0,
  currentPage: 0,
  redirectLink: "",
  sortByColumns: [],
  setSortByColumns: () => {},
  isLoading: false,
  showFilters: false,
  showPagination: true,
};
