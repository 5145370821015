import { estimatedColumnWidth } from "../helpers";
import { formatDate, formatCurrency } from "@/utils/helpers";

export function getReceiptColumns() {
  const colArr = [
    {
      label: "Reference No.",
      value: true,
      columnId: "referenceNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Trans #",
      value: true,
      columnId: "transferId",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Date", value: true, columnId: "date", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))
    },
    {
      label: "Type", value: true, columnId: "type", width: estimatedColumnWidth("type"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Description", value: true, columnId: "description", width: estimatedColumnWidth("description"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Debit", value: true, columnId: "debit", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Credit", value: true, columnId: "credit", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Running Balance", value: true, columnId: "runningBalance", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
  ];

  return colArr;
}

export function getTenantStatementColumns() {
  const colArr = [
    {
      label: "Description",
      value: true,
      columnId: "description",
      width: estimatedColumnWidth("description"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Invoice No Due Date",
      value: true,
      columnId: "invoiceNo",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => value
    },
    {
      label: "Amount", value: true, columnId: "amount", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "VAT",
      value: true,
      columnId: "vat",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Total", value: true, columnId: "total", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Paid/Adjusted", value: true, columnId: "paidAdjusted", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Balance", value: true, columnId: "balance", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => String(value || "")
    }
  ];

  return colArr;
}
