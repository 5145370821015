import { getSupplierPaymentColumns, getSupplierPaymentRows } from "./supplier-payments";
import { getSupplierPayment } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const supplierPaymentFilters = {
  SupplierKey: "*",
  ReportType: "*",
};

const supplierPaymentFilterOptions = [
  { key: "supplierKey", apiKey: "SupplierKey" },
  { key: "reportType", apiKey: "ReportType" },
];

export default function ReportSupplierPayment({ activeCompany }) {
  return (
    <DynamicReport
      reportName="supplier-payment"
      activeCompany={activeCompany}
      columnsDataProvider={getSupplierPaymentColumns}
      rowsDataProvider={getSupplierPaymentRows}
      fetchData={getSupplierPayment}
      exportUrl="reports/export-query-report/supplier-payment"
      filtersInitialState={supplierPaymentFilters}
      filterOptions={supplierPaymentFilterOptions}
      orderBy=""
      isPaginated={false}
    />
  );
}
