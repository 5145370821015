import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getBlanketAgreementDetailColumns() {
  const colArr = [
    {
      label: "Supplier Name",
      value: true,
      columnId: "supplierName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Supplier Invoice Date",
      value: true,
      columnId: "invoiceDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Blanket Agreement Number",
      value: true,
      columnId: "blanketAgreementNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Invoice Booked",
      value: true,
      columnId: "invoiceBooked",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Invoice Booked Total Amount",
      value: true,
      columnId: "invoiceBookedTotalAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Last Recognition Date",
      value: true,
      columnId: "lastRecognitionDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Total Amount",
      value: true,
      columnId: "totalAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Agreement Start Date",
      value: true,
      columnId: "agreementStartDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Agreement End Date",
      value: true,
      columnId: "agreementEndDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : "")
    },
    {
      label: "Status",
      value: true,
      columnId: "status",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Category",
      value: true,
      columnId: "category",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Building Name",
      value: true,
      columnId: "building",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Recognised Expense Value",
      value: true,
      columnId: "recognisedExpenseValue",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Unrecognised",
      value: true,
      columnId: "unrecognised",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    }
  ];

  return colArr;
}
