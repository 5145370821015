import { Checkbox } from "@hydra/atom/components";
import PropTypes from "prop-types";
import { Pill } from "@/components/dashboard";
import { getTestId } from "@/utils/helpers";

function ListItemWithCheckbox({
  item, keyId, onChange, selected
}) {
  return (
    <div className="asset-item" key={keyId}>
      <div className="d-flex">
        <Checkbox
          id={keyId}
          name={`Checkbox-${keyId}`}
          value={selected}
          onChange={() => onChange(keyId, selected)}
          testId={getTestId(`Checkbox-${keyId}`)}
        />
        <div className="asset-title">
          {item.itemName}
        </div>
      </div>
      <Pill
        text="Available"
        variant="success"
      />
    </div>
  );
}

ListItemWithCheckbox.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  keyId: PropTypes.string.isRequired
};

export default ListItemWithCheckbox;
