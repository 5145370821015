import { useMemo } from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";
import Skeleton from "react-loading-skeleton";

import {
  getDynamicObjectFieldsColumns,
  getDynamicObjectFieldsData,
} from "./dynamicObjectFieldsTableData";

export default function DynamicObjectFieldsTable({
  fields,
  isLoading,
  hideActionColumn,
  objectName,
  isRelationalField,
}) {
  const data = useMemo(() => getDynamicObjectFieldsData(fields, hideActionColumn), [fields]);
  const columns = useMemo(() => (isLoading ?
    getDynamicObjectFieldsColumns(hideActionColumn, objectName).map((column) => ({
      ...column,
      Cell: Skeleton,
    })) :
    getDynamicObjectFieldsColumns(hideActionColumn, objectName)), [isLoading]);

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } =
    useTable({
      columns,
      data,
      isRelationalField
    });

  if (!fields.length) return null;

  return (
    <div className="table-wrapper">
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="table-header-row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className={`table-header-cell ${column.collapse ? "collapse" : ""}`}
                  {...column.getHeaderProps()}
                >
                  <div className="table-header-cell-content">
                    <span>{column.render("Header")}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                className="table-row"
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <td
                    className={`table-body-cell ${cell.column.collapse ? "collapse" : ""}`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

DynamicObjectFieldsTable.propTypes = {
  fields: PropTypes.array.isRequired,
  isRelationalField: PropTypes.bool,
  isLoading: PropTypes.bool,
  hideActionColumn: PropTypes.bool,
};

DynamicObjectFieldsTable.defaultProps = {
  isRelationalField: false,
  isLoading: false,
  hideActionColumn: false
};
