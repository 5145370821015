const workflowMap = new Map([
  ["P2P", "Procure2Pay"],
  ["ServiceRequest", "ServiceRequestFlow"],
  ["SREscalation", "ServiceRequestEscalationFlow"],
  ["PettyCashFlow", "PettyCashFlow"],
  ["SupervisorPettyCashFlow", "SupervisorPettyCashFlow"],
  ["PettyCashClaimFlow", "PettyCashClaimFlow"],
  ["VendorFlow", "WorkOrderJobVendorFlow"],
  ["LeasingFlow", "LeasingFlow"],
  ["RenewContractFlow", "RenewContractFlow"],
  ["MoveOutFlow", "MoveOutFlow"],
  ["FitOutFlow", "FitOutFlow"],
  ["FixedAssetTransferFlow", "FixedAssetTransferFlow"],
  ["FixedAssetDisposalFlow", "FixedAssetDisposalFlow"],
  ["CancelReservationFlow", "CancelReservationFlow"],
]);

export default workflowMap;
