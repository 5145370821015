import PropTypes from "prop-types";
import { formatDate } from "@/utils/helpers";

function DateCell({ value }) {
  return (
    <div className="date-cell">
      {value ? formatDate(new Date(value)) : ""}
    </div>
  );
}

DateCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const getTransferTableColumns = () => [
  {
    Header: "Trans #",
    accessor: "journalEntryId",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Date",
    accessor: "postingDate",
    Cell: DateCell,
  },
  {
    Header: "Company",
    accessor: "companyName",
  },
  {
    Header: "Building",
    accessor: "buildingName",
  },
  {
    Header: "Component",
    accessor: "componentName",
  },
  {
    Header: "Unit",
    accessor: "unitName",
  },
  {
    Header: "GL Account",
    accessor: "accountName",
  },
  {
    Header: "GL Account Code",
    accessor: "accountCode",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Debit (AED)",
    accessor: "debitAmount",
  },
  {
    Header: "Credit (AED)",
    accessor: "creditAmount",
  }
];

export const getTransferTableData = (data, companyName) => {
  if (!data) {
    return [];
  }

  return data.map((transfer) => ({
    ...transfer,
    companyName
  }));
};
