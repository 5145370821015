import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, kebabCase } from "lodash";

import {
  addDays,
  addMonths,
  addQuarters,
  addYears,
  differenceInDays,
  isFuture,
  isToday,
} from "date-fns";
import { Button } from "@hydra/atom/components";

import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { useModal } from "@/hooks";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getReservationTableColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";
import { calculateDiscount, calculateTax, formatDecimalValues } from "@/utils/helpers";

const formatReservation = (reservation) => {
  const data = {
    key: reservation.id,
    reservation: {
      label: reservation.number,
      id: reservation.id,
    },
    building: {
      label: reservation?.building?.name,
      value: reservation?.building?.id,
    },
    unit: reservation.unit?.map((unit) => ({
      ...unit,
      label: unit?.name,
      value: unit?.id,
    })),
    salesLocation: {
      label: reservation.salesLocation?.name,
      value: reservation.salesLocation?.id,
    },
    annualAmount: 0,
    credit: reservation.total,
    reservationAmount: reservation.total,
    isSelected: false,
  };

  data.unit.forEach(({ totalRentAmount }) => {
    data.annualAmount += totalRentAmount;
  });

  if (reservation.tax && !isEmpty(reservation.tax)) {
    data.tax = {
      ...reservation.tax,
      label: reservation.tax.name,
      value: reservation.tax.id,
    };
  }

  const isValid =
    isFuture(new Date(reservation?.reservationEndDate)) ||
    isToday(new Date(reservation?.reservationEndDate));

  data.isValid = isValid;

  return data;
};

const reservationInitialState = {
  building: null,
  unit: null,
  salesLocation: null,
  credit: "",
};

function HeaderRightContent({ openModal, showButton, ...rest }) {
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {
        showButton ? (
          <Button small bordered onClick={openModal}>
            Select quotation
          </Button>
        ) : null
      }
    </defaultComponents.HeaderRightContent>
  );
}

function CustomerQuotationForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});
  const [reservationTableData, setReservationTableData] = useState({});
  const { isOpen, closeModal, openModal } = useModal(false);

  const { data: reservationData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ReservationObjectName")), state?.lead?.value],
    () =>
      getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("ReservationObjectName")), {
        lead: state?.lead?.value,
        takePage: 1,
        limitPage: 5,
        sortBy: "CreatedAt",
        sortType: "DESC",
      }),
    {
      enabled: Boolean(state?.lead?.value),
    }
  );

  useEffect(() => {
    if (reservationData?.data && reservationData?.data?.length && state?.lead) {
      const formattedReservations = reservationData?.data
        .map((r) => formatReservation(r))
        .filter((r) => r.isValid);
      if (formattedReservations.length) {
        setReservationTableData(formattedReservations);
        openModal();
      }
    }
  }, [reservationData, state?.lead]);

  const setBuilding = (value) => {
    const data = {
      ...reservationInitialState,
    };

    const { building } = value;

    if (building) {
      data.building = {
        label: building.name,
        value: building.id,
      };
    }

    ref.current.setFormState(data);
  };

  const setUnit = (value) => {
    const data = {
      tax: null,
      annualAmount: "",
    };

    if (!value || !value.length) {
      ref.current.setFormState(data);
      return;
    }

    value.forEach(({ totalRentAmount, tax }) => {
      if (tax && !isEmpty(tax)) {
        data.tax = {
          ...tax,
          label: tax.name,
          value: tax.id,
        };
      }

      data.annualAmount += totalRentAmount;
    });

    ref.current.setFormState(data);
  };

  const setPeriodEndDate = (periodStartDate) => {
    let periodEndDate = null;

    if (!periodStartDate) {
      ref.current.setFormValue("periodEndDate", periodEndDate);
      return;
    }

    const formState = ref.current.getState();
    const { duration, noOfDuration } = formState;

    if (!duration || !noOfDuration) {
      return;
    }

    switch (duration.value) {
      case "Nights":
        periodEndDate = addDays(periodStartDate, noOfDuration);
        break;

      case "Months":
        periodEndDate = addMonths(periodStartDate, noOfDuration);
        break;

      case "Quarters":
        periodEndDate = addQuarters(periodStartDate, noOfDuration);
        break;

      case "Years":
        periodEndDate = addYears(periodStartDate, noOfDuration);
        break;

      default:
        break;
    }

    ref.current.setFormValue("periodEndDate", periodEndDate);
  };

  const getPaymentDate = ({
    periodStartDate, periodEndDate, noOfPayments, index
  }) => {
    if (index === Number(noOfPayments)) {
      return periodEndDate;
    }

    const days = differenceInDays(periodEndDate, periodStartDate) + 1;

    const paymentDate = addDays(periodStartDate, Math.floor(days / noOfPayments) * index);

    return paymentDate;
  };

  const setRentalDetail = (noOfPayments) => {
    const {
      annualAmount,
      periodStartDate,
      periodEndDate,
      tax,
      amountOfTax,
      discountType,
      discount,
      credit,
    } = ref.current.getState();

    if (!annualAmount || !periodStartDate || !periodEndDate) {
      return;
    }

    let amount = formatDecimalValues(annualAmount / noOfPayments);
    let discountAmount = 0;
    let taxAmount = 0;

    if (discountType && discount) {
      discountAmount = calculateDiscount(
        {
          amount, discountType, discount, noOfPayments
        });
      amount -= discountAmount;
    }

    if (tax && amountOfTax) {
      const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax({
        amount,
        amountOfTax,
        tax,
        taxAmount: ""
      });
      amount = principalAmount;
      taxAmount = calculatedTaxAmount;
    }

    const rentalDetail = [];

    for (let i = 1; i <= noOfPayments; i += 1) {
      rentalDetail.unshift({
        paymentDate: getPaymentDate({
          periodStartDate,
          periodEndDate,
          index: i,
          noOfPayments,
        }),
        paymentMethod: {
          label: "Cheque",
          value: "Cheque",
        },
        discount: discountAmount,
        amount,
        tax,
        taxAmount,
        totalAmount: amount + taxAmount,
      });
    }

    const totalAmount = rentalDetail.reduce(
      (total, currentValue) => total + Number(currentValue.totalAmount),
      0
    );
    const subtotal = totalAmount;
    const amountBeforeTax = totalAmount;

    ref.current.setFormState({
      rentalDetail,
      total: totalAmount - Number(credit),
      subtotal,
      amountBeforeTax,
    });
  };

  const updateRentalDetail = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const {
      annualAmount,
      noOfPayments,
      tax,
      amountOfTax,
      discountType,
      discount,
      rentalDetail,
      credit,
    } = formState;

    const updatedRentalDetail = rentalDetail.map((rent) => {
      let amount = formatDecimalValues(annualAmount / noOfPayments);
      let discountAmount = 0;
      let taxAmount = 0;

      if (discountType && discount) {
        discountAmount = calculateDiscount(
          {
            amount, discountType, discount, noOfPayments
          });
        amount -= discountAmount;
      }

      if (tax && amountOfTax) {
        const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax({
          amount,
          amountOfTax,
          tax,
          taxAmount: ""
        });
        amount = principalAmount;
        taxAmount = calculatedTaxAmount;
      }

      const creditPerPayment = formatDecimalValues((credit || 0) / noOfPayments);

      return {
        ...rent,
        amount,
        discount: discountAmount,
        tax,
        taxAmount,
        totalAmount: Number(amount) + Number(taxAmount) - creditPerPayment,
      };
    });

    ref.current.setFormValue("rentalDetail", updatedRentalDetail);
  };

  const setTaxAndTotal = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const {
      discount,
      discountType,
      subtotal,
      tax,
      amountOfTax,
      amountBeforeTax,
      taxAmount: prevTaxAmount,
      credit,
    } = formState;

    const data = {
      amountBeforeTax,
      taxAmount: "",
      total: "",
    };

    if (discount && discountType) {
      const discountAmount = calculateDiscount(
        {
          amount: subtotal, discountType, discount, undefined
        });
      data.amountBeforeTax = subtotal - discountAmount;
    } else {
      data.amountBeforeTax = subtotal;
    }

    if (amountOfTax && tax) {
      const parameters = {
        amount: amountBeforeTax,
        amountOfTax,
        tax,
        taxAmount: ""
      };

      if (key === "amountOfTax") {
        parameters.taxAmount = prevTaxAmount;
      }

      const { taxAmount, principalAmount } = calculateTax(parameters);

      data.taxAmount = taxAmount;
      data.amountBeforeTax = principalAmount;
    }

    data.total = Number(data.amountBeforeTax) + Number(data.taxAmount) - Number(credit || 0);
    ref.current.setFormState(data);
    updateRentalDetail(key, value);
  };

  const setCommissionRate = (value) => {
    const { amountBeforeTax } = ref.current.getState();

    if (amountBeforeTax) {
      const commissionAmount = amountBeforeTax * (value / 100);
      ref.current.setFormValue("totalCommission", formatDecimalValues(commissionAmount));
    }
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "lead":
        setState({
          ...state,
          [key]: value,
        });

        if (!value) {
          ref.current.setFormState(reservationInitialState);
        } else {
          setBuilding(value);
        }
        break;

      case "unit":
        setUnit(value);
        break;

      case "periodStartDate":
        setPeriodEndDate(value);
        break;

      case "noOfPayments":
        setRentalDetail(value);
        break;

      case "discountType":
      case "discount":
      case "tax": {
        setTaxAndTotal(key, value);
        break;
      }

      case "amountOfTax": {
        if (state[key]) {
          if (state[key]?.value === value?.value) {
            break;
          }
        }

        setState((prevState) => ({
          ...prevState,
          [key]: value,
        }));

        setTaxAndTotal(key, value);
        break;
      }

      case "commissionRate":
        setCommissionRate(value);
        break;

      default:
        break;
    }
  };

  const handleConfirm = () => {
    if (reservationTableData.length) {
      const selectedReservation = reservationTableData.find((r) => r.isSelected);
      if (selectedReservation) {
        ref.current.setFormState(selectedReservation);
        setState({
          ...state,
          unit: selectedReservation.unit,
        });
      }
    }

    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Tenant Reservation"
        subtitle="Selected tenant has an open reservation"
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        size="large"
      >
        {reservationTableData.length ? (
          <TableWithCheckbox
            data={reservationTableData}
            columns={getReservationTableColumns()}
            setData={setReservationTableData}
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("CustomerQuotationObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        components={{
          HeaderRightContent: (props) => HeaderRightContent({
            openModal,
            showButton: Boolean(reservationTableData.length),
            ...props
          })
        }}
      />
    </BoxedContent>
  );
}

export default CustomerQuotationForm;
