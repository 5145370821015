import {
  differenceInMonths, addMonths, format, startOfYear
} from "date-fns";
import { estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getBuildingRevenueColumns() {
  const startOfCurrentYear = startOfYear(new Date());
  const currentDate = new Date();
  const monthsBetween = differenceInMonths(currentDate, startOfCurrentYear);
  const monthColumns = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= monthsBetween; i++) {
    const currentDateIteration = addMonths(startOfCurrentYear, i);
    const monthLabel = format(currentDateIteration, "MMM yyyy");
    const monthShort = format(currentDateIteration, "MMM").toLowerCase();

    const columnObject = {
      label: monthLabel,
      value: true,
      columnId: monthShort,
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    };

    monthColumns.push(columnObject);
  }

  const colArr = [
    {
      label: "Building Name",
      value: true,
      columnId: "buildingName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Building Number",
      value: true,
      columnId: "buildingNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Component Name",
      value: true,
      columnId: "name",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Component Number",
      value: true,
      columnId: "number",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "External Number",
      value: true,
      columnId: "externalNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
  ];
  colArr.push(...monthColumns);
  return colArr;
}
