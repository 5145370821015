import PropTypes from "prop-types";

function WeeklyEventCard({ event }) {
  const { content } = event;
  return (
    <div
      className={`event-weekly-container event-bg-${content.type.toLowerCase()}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      role="presentation"
    >
      <div className="event-text-weekly-container">
        <p
          className={`event-text-${content.type.toLowerCase()}  event-text-weekly`}
        >
          {content.title}
        </p>
      </div>
    </div>
  );
}

WeeklyEventCard.propTypes = {
  event: PropTypes.shape({
    content: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default WeeklyEventCard;
