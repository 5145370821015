import PropTypes from "prop-types";

function SortableArrows({ isSorted, isSortedDesc }) {
  return (
    <div className="sortable-arrows">
      <span className={`icon material-icons ${isSorted && !isSortedDesc ? "active" : ""}`}>
        expand_less
      </span>
      <span className={`icon material-icons ${isSorted && isSortedDesc ? "active" : ""}`}>
        expand_more
      </span>
    </div>
  );
}

SortableArrows.propTypes = {
  isSorted: PropTypes.bool,
  isSortedDesc: PropTypes.bool
};

SortableArrows.defaultProps = {
  isSorted: false,
  isSortedDesc: false
};

export default SortableArrows;
