import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { Button } from "@hydra/atom/components";
import { formatDate, getFullName } from "@/utils/helpers";
import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { useModal } from "@/hooks";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getGeneralReceiptTableColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";

const formatGeneralReceipt = (generalReceipt) => {
  const {
    id, number, paymentDetail, tenant, date
  } = generalReceipt;

  const data = {
    key: id,
    generalReceipt: {
      label: number,
      value: id
    },
    tenant: {
      label: getFullName(tenant),
      value: tenant?.id
    },
    paymentDetail: [],
    receiptDate: formatDate(new Date(date)),
    isSelected: true,
  };

  if (paymentDetail && paymentDetail.length) {
    paymentDetail.forEach((item) => {
      const { tax, account, company } = item;
      const paymentDetailData = {
        ...item,
      };

      if (tax) {
        paymentDetailData.tax = {
          ...tax,
          label: tax?.name,
          value: tax?.id,
        };
      }

      if (account) {
        paymentDetailData.account = {
          ...account,
          label: account?.name,
          value: account?.id
        };
      }

      if (company) {
        paymentDetailData.company = {
          label: company?.name,
          value: company?.id
        };
      }

      data.paymentDetail.push(paymentDetailData);
    });
  }

  const totalAmount = data.paymentDetail.reduce(
    (total, currentValue) => total + Number(currentValue.amount),
    0
  );

  data.subtotal = totalAmount;
  data.total = totalAmount;

  return data;
};

function HeaderRightContent({ openModal, showButton, ...rest }) {
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {showButton && (
        <Button small bordered onClick={openModal}>
          Select general receipts
        </Button>
      )}
    </defaultComponents.HeaderRightContent>
  );
}

function PrintReceiptForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});
  const [generalReceiptsTableData, setGeneralReceiptsTableData] = useState([]);
  const { isOpen, closeModal, openModal } = useModal(false);

  const { data: generalReceiptsData } = useQuery(
    [kebabCase(dynamicObjectMap.get("GeneralReceiptObjectName")), state?.tenant?.value],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("GeneralReceiptObjectName"), {
        tenant: state?.tenant?.value,
        takePage: 1,
        limitPage: 10,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
      }),
    {
      enabled:
        Boolean(state?.tenant?.value),
    }
  );

  useEffect(() => {
    if (generalReceiptsData && generalReceiptsData?.data && state?.tenant) {
      const formattedReceipts = generalReceiptsData.data.map((generalReceipt) =>
        formatGeneralReceipt(generalReceipt)
      );
      if (generalReceiptsData?.data?.length) {
        setGeneralReceiptsTableData(formattedReceipts);
      } else {
        setGeneralReceiptsTableData([]);
      }
      openModal();
    }
  }, [generalReceiptsData, state?.tenant?.value]);

  const setPaymentDetail = (generalReceipts) => {
    const data = {
      generalReceipt: [],
      paymentDetail: []
    };

    generalReceipts.forEach((generalReceipt) => {
      const { generalReceipt: receipt, paymentDetail } = generalReceipt;

      data.generalReceipt.push(receipt);
      data.paymentDetail = data.paymentDetail.concat(paymentDetail);
    });

    ref.current.setFormState(data);
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "tenant":
        setState({
          ...state,
          [key]: value,
        });
        break;

      default:
        break;
    }
  };

  const handleConfirm = () => {
    if (generalReceiptsTableData.length) {
      const selectedGeneralReceipts = generalReceiptsTableData.filter((r) => r.isSelected);
      setPaymentDetail(selectedGeneralReceipts);
    }

    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Tenant General Receipts"
        subtitle="Selected tenant has following general receipts"
        isOpen={isOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
        size="large"
      >
        {generalReceiptsTableData.length ? (
          <TableWithCheckbox
            data={generalReceiptsTableData}
            setData={setGeneralReceiptsTableData}
            columns={getGeneralReceiptTableColumns()}
            allowMultiple
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("ReceiptObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        components={{
          HeaderRightContent: (props) =>
            HeaderRightContent({
              openModal,
              showButton: Boolean(generalReceiptsTableData.length),
              ...props,
            }),
        }}
      />
    </BoxedContent>
  );
}

export default PrintReceiptForm;
