import { useRef, useState } from "react";
import { isEmpty } from "lodash";
import { addDays, intervalToDuration } from "date-fns";

import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getPaymentDate, calculateTax, formatDecimalValues } from "@/utils/helpers";

function FacilityContractForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});

  const setTaxAndTotal = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { tax, amountOfTax, contractAmount } = formState;

    const data = {
      contractAmount,
      amountBeforeTax: contractAmount,
      taxAmount: "",
      total: ""
    };

    if (amountOfTax && tax) {
      const parameters = {
        amount: contractAmount,
        amountOfTax,
        tax,
        taxAmount: ""
      };

      const { taxAmount, principalAmount } = calculateTax(parameters);

      data.taxAmount = taxAmount;
      data.amountBeforeTax = principalAmount;
    }

    data.total = Number(data.amountBeforeTax) + Number(data.taxAmount);
    ref.current.setFormState(data);
  };

  const setTax = (key, value) => {
    const data = {};
    if (!value) {
      ref.current.setFormState(data);
      return;
    }

    const { tax } = value;

    if (!isEmpty(tax)) {
      data.tax = {
        ...tax,
        label: tax?.name,
        value: tax?.id
      };
    }

    ref.current.setFormState(data);
  };

  const setDuration = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { startDate, endDate } = formState;

    if (!startDate || !endDate) return;

    const { years, months, days } = intervalToDuration({
      start: startDate,
      end: addDays(endDate, 1),
    });

    const data = {
      durationYears: `${years}`,
      durationMonths: `${months}`,
      durationDays: `${days}`,
    };

    ref.current.setFormState(data);
  };

  const setPaymentDetail = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;

    const {
      contractAmount,
      startDate: agreementStartDate,
      endDate: agreementEndDate,
      tax,
      amountOfTax,
      noOfPayments,
      facilityType
    } = formState;

    if (!contractAmount || !agreementStartDate || !agreementEndDate) {
      return;
    }

    let amount = formatDecimalValues(contractAmount / noOfPayments);
    let taxAmount = 0;
    const paymentDetail = [];

    if (tax && amountOfTax) {
      const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax({
        amount,
        amountOfTax,
        tax,
        taxAmount: ""
      });
      amount = principalAmount;
      taxAmount = calculatedTaxAmount;
    }

    for (let i = 0; i < noOfPayments; i += 1) {
      paymentDetail.unshift({
        paymentDate: getPaymentDate({
          agreementStartDate,
          agreementEndDate,
          index: i,
          noOfPayments,
        }),
        paymentMethod: {
          label: "Cheque",
          value: "Cheque",
        },
        amount,
        tax,
        taxAmount,
        totalAmount: Number(amount) + Number(taxAmount),
        description: facilityType?.label,
        // Hidden fields
        openBalance: Number(amount) + Number(taxAmount),
        paymentStatus: "Pending",
      });
    }

    ref.current.setFormValue("paymentDetail", paymentDetail);
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "facilityType":
        setTax(key, value);
        break;
      case "contractAmount":
        setTaxAndTotal(key, value);
        break;

      case "tax": {
        setTaxAndTotal(key, value);
        break;
      }

      case "amountOfTax": {
        if (state[key]) {
          if (state[key]?.value === value?.value) {
            break;
          }
        }

        setState((prevState) => ({
          ...prevState,
          [key]: value
        }));

        setTaxAndTotal(key, value);
        break;
      }

      case "startDate":
      case "endDate":
        setDuration(key, value);
        break;

      case "noOfPayments":
        setPaymentDetail(key, value);
        break;

      default:
        break;
    }
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("FacilityContractObjectName")}
        showHeader
        onStateChange={onStateChange}
      />
    </BoxedContent>
  );
}

export default FacilityContractForm;
