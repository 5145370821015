import { useEffect, useMemo, useState } from "react";
import { chunk, kebabCase } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Loader, TextArea } from "@hydra/atom/components";
import { pascalize } from "humps";
import { ActionDropdown } from "@/components/dynamic";
import DynamicObjectOverview from "@/pages/dynamic/list-views/DynamicObjectOverview";
import { selectActiveCompany } from "@/store/appSlice";
import {
  BoxedContent,
  Header,
  Accordion,
  TwoColumnList,
  TextEditor,
  HeaderLeftContent
} from "@/components/common";

import { InfoTable } from "@/components/facility/maintenance";
import {
  getTransferTableColumns,
  getTransferTableData,
} from "@/components/finance/transfer/transferTableData";
import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import { getDynamicObjectByNameWithCamelizedFieldNames } from "@/api/dynamic/dynamicObjectSchemaApi";
import { getTransfers } from "@/api/finance/transferApi";

import {
  formatDynamicObjectRecord,
  getDynamicObjectName,
  renderDynamicFieldValue,
} from "@/utils/dynamic/helpers";
import { getFileExtension } from "@/utils/helpers";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import PdfFileIcon from "@/assets/images/file-pdf-icon.png";
import { selectPermission } from "@/store/userSlice";

function HeaderRightContent({
  objectName, selectedId, recordValues, data
}) {
  return (
    <div className="buttons-at-end">
      <ActionDropdown
        objectName={pascalize(objectName)}
        id={selectedId}
        data={recordValues}
        actions={data?.actions || []}
        showTableActions
        removeActions={["View"]}
        testId="Action-Dropdown"
        trigger={(
          <div className="action-dropdown-trigger">
            <span className="text">Actions</span>
            <span className="material-icons-outlined">expand_more</span>
          </div>
        )}
      />
    </div>
  );
}

function FileViewer({ url, fileExtension }) {
  switch (fileExtension) {
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
      return (
        <a target="_blank" href={url}>
          <img src={url} alt="Attachment" width={200} height={200} />
        </a>
      );

    case "mp3":
    case "wav":
    case "ogg":
    case "aac":
      return (
        <audio controls>
          <source src={url} type={`audio/${fileExtension}`} />
          Your browser does not support the audio element.
        </audio>
      );

    case "mp4":
    case "webm":
      return (
        <video width={200} height={200} controls>
          <source src={url} type={`video/${fileExtension}`} />
          Your browser does not support the video element.
        </video>
      );

    case "pdf":
      return (
        <a target="_blank" href={url}>
          <img src={PdfFileIcon} alt="Pdf" width={28} height={28} />
        </a>
      );

    default:
      return (
        <a target="_blank" href={url}>
          {url}
        </a>
      );
  }
}

function HistoryTable({ objectName, id, data }) {
  const getHistoryTableData = () => {
    if (objectName === dynamicObjectMap.get("ContractObjectName")) {
      return {
        title: "Revenue Recognition History",
        objectName: dynamicObjectMap.get("RevenueRecognitionRevenueRecognitionDetailObjectName"),
        filters: {
          limit_page: undefined,
          contract: id,
        },
      };
    }

    if (objectName === dynamicObjectMap.get("BlanketAgreementObjectName")) {
      return {
        title: "Expense Recognition History",
        objectName: dynamicObjectMap.get("ExpenseRecognitionExpenseRecognitionDetailObjectName"),
        filters: {
          limit_page: undefined,
          expense: {
            key: id,
          },
        },
      };
    }

    if (objectName === dynamicObjectMap.get("ItemObjectName")) {
      if (data?.itemType !== "Asset") {
        return null;
      }

      if (data?.isDepreciated) {
        return {
          title: "Depreciation History",
          objectName: dynamicObjectMap.get("DepreciationAssetDetailObjectName"),
          filters: {
            limit_page: undefined,
            asset: id,
          },
        };
      }

      return {
        title: "Revaluation History",
        objectName: dynamicObjectMap.get("RevaluationAssetDetailObjectName"),
        filters: {
          limit_page: undefined,
          asset: id,
        },
      };
    }

    return null;
  };

  const tableData = getHistoryTableData();

  if (!tableData) {
    return null;
  }

  const { title, objectName: linkedObjectName, filters } = tableData;

  return (
    <Accordion key={`accordion-${objectName}`} open title={title}>
      <DynamicObjectOverview
        showTableOnly
        objectName={linkedObjectName}
        filters={filters}
        showPagination={false}
        showActionCell={false}
        showFilters={false}
      />
    </Accordion>
  );
}

function TransfersTable({ objectName, id, number }) {
  const activeCompany = useSelector(selectActiveCompany);
  const viewTransferPermission = useSelector(
    selectPermission({ parent: "Model", scope: "JournalEntry", action: "View" })
  );

  const { data, isInitialLoading: isLoading } = useQuery(
    [kebabCase(objectName), id, "transfers"],
    () => getTransfers({ number }),
    {
      enabled: Boolean(number),
    }
  );

  const columns = useMemo(() => getTransferTableColumns(), []);
  const tableData = useMemo(() => getTransferTableData(data?.data, activeCompany?.name), [data]);

  if (isLoading || !viewTransferPermission) {
    return null;
  }

  if (!tableData || !tableData.length) {
    return null;
  }

  return (
    <Accordion key={`accordion-${objectName}`} open={false} title="Transfers">
      <InfoTable data={tableData} columns={columns} />
    </Accordion>
  );
}

function DynamicObjectDetails({
  objectName: objectNameProps,
  id: idProps,
  showHeader = true,
  showTransfers = false,
}) {
  const { objectName: objectNameParams, id: idParams } = useParams();
  const [recordDetails, setRecordDetails] = useState([]);
  const [tableDetails, setTableDetails] = useState([]);
  const [attachmentDetails, setAttachmentDetails] = useState([]);
  const [textAreaDetails, setTextAreaDetails] = useState([]);
  const [richTextDetails, setRichTextDetails] = useState([]);
  const activeCompany = useSelector(selectActiveCompany);
  const defaultCurrencyCode = activeCompany ? activeCompany.defaultCurrencyCode : "AED";
  const objectName = objectNameParams || objectNameProps;
  const id = idParams || idProps;

  const { data: objectSchema, isInitialLoading: isLoadingSchema } = useQuery(
    ["dynamic-object-camelized-schema", objectName],
    () => getDynamicObjectByNameWithCamelizedFieldNames(objectName),
    {
      enabled: Boolean(objectName),
    }
  );

  const { data, isInitialLoading: isLoading } = useQuery(
    [kebabCase(objectName), id],
    () => getDynamicObjectRecordById(objectName, id),
    {
      enabled: Boolean(objectName),
    }
  );

  useEffect(() => {
    if (objectSchema && data) {
      const {
        recordData, tableData, attachmentData, textAreaData, richTextData
      } =
        formatDynamicObjectRecord({
          objectSchema,
          data,
          defaultCurrencyCode,
        });
      setRecordDetails(recordData);
      setTableDetails(tableData);
      setAttachmentDetails(attachmentData);
      setTextAreaDetails(textAreaData);
      setRichTextDetails(richTextData);
    }
  }, [objectSchema, data]);

  const renderRecordDetails = () => {
    let details = recordDetails.sort(
      (a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

    if (objectName === "Building") {
      // TODO: handle from model file
      const removeFields = ["noOfUnits"];
      details = recordDetails.filter((field) => !removeFields.includes(field.fieldName));
    }
    const rows = details.length > 30 ? Math.ceil(details.length / 3) : 10;
    const chunks = chunk(details, rows);
    return (
      <div className="card">
        <div className="row">
          {chunks.map((item, index) => (
            <div key={`chunk-${index}`} className="col">
              <TwoColumnList
                data={item.map((i) => ({
                  ...i,
                  render: renderDynamicFieldValue(i),
                }))}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (isLoadingSchema || isLoading) {
    return (
      <BoxedContent>
        <Loader />
      </BoxedContent>
    );
  }
  return (
    <BoxedContent className="dynamic-object-overview">
      {showHeader && (
        <Header
          showBreadcrumb
          leftContent={<HeaderLeftContent title={getDynamicObjectName(objectName, data)} />}
          rightContent={(
            <HeaderRightContent
              objectName={objectName}
              selectedId={id}
              recordValues={data}
              data={objectSchema}
            />
          )}
        />
      )}
      {renderRecordDetails()}
      {tableDetails.map((tableDetail, index) => (
        <Accordion key={`table-detail-${index}`} title={tableDetail.label}>
          <InfoTable data={tableDetail.data} columns={tableDetail.columns} />
        </Accordion>
      ))}
      {
        objectName === kebabCase(dynamicObjectMap.get("PettyCashObjectName")) && (
          <DynamicObjectOverview
            showTableOnly
            objectName={dynamicObjectMap.get("PettyCashTransactionObjectName")}
            showActionCell={false}
            showFilters
            filters={{
              detailId: id,
            }}
          />
        )
      }
      {textAreaDetails.map((textAreaDetail, index) => (
        <Accordion key={`text-detail-${index}`} title={textAreaDetail.label}>
          <TextArea
            id={textAreaDetail.label}
            name={textAreaDetail.label}
            value={textAreaDetail.value}
          />
        </Accordion>
      ))}
      {attachmentDetails.map((attachmentDetail, index) => (
        <Accordion key={`attachment-${index}`} title={attachmentDetail.label}>
          <div className="attachment-container">
            {attachmentDetail.value.map((attachment) => (
              <FileViewer
                key={attachment.url}
                url={attachment.url}
                fileExtension={getFileExtension(attachment.url)}
              />
            ))}
          </div>
        </Accordion>
      ))}
      {richTextDetails.map((richText, index) => (
        <Accordion key={`rich-text-detail-${index}`} title={richText.label}>
          <TextEditor
            id={richText.label}
            name={richText.label}
            value={richText.value || ""}
            disabled
          />
        </Accordion>
      ))}
      <HistoryTable objectName={pascalize(objectName)} id={id} data={data} />
      {showTransfers && (
        <TransfersTable objectName={pascalize(objectName)} id={id} number={data?.number} />
      )}
    </BoxedContent>
  );
}

export default DynamicObjectDetails;
