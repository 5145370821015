import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { useSelector } from "react-redux";
import {
  ReportPettyCashBySupervisor
} from "@/components/finance/reporting";
import { BoxedContent, Header, HeaderLeftContent } from "@/components/common";
import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import DynamicObjectDetails from "@/pages/dynamic/detail-views/DynamicObjectDetails";
import { selectActiveCompany } from "@/store/appSlice";

function ReportPettyCashBySupervisorStatementWrapper() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("supervisorId").substring(1);
  const activeCompany = useSelector(selectActiveCompany);

  const { data: pettyCashData, isLoading } = useQuery(
    [kebabCase(dynamicObjectMap.get("PettyCashObjectName")), id],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("PettyCashObjectName"), id),
    {
      enabled: Boolean(id),
    }
  );

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<HeaderLeftContent title="Petty Cash By Supervisor Statement" subtitle="" icon="file-check-stroke-icon" />}
      />
      {!isLoading && (
      <>
        <DynamicObjectDetails objectName="Employee" id={pettyCashData?.technician.id} showHeader={false} />
        <ReportPettyCashBySupervisor id={pettyCashData?.technician.id} activeCompany={activeCompany} />
      </>
      )}
    </BoxedContent>
  );
}

export default ReportPettyCashBySupervisorStatementWrapper;
