import { useQuery } from "@tanstack/react-query";
import { useReducer, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { DynamicObjectFieldsTable } from "@/components/dynamic/object-manager";
import {
  initialState,
  dynamicObjectFieldFormReducer,
  showFieldForm,
  setObjectFields,
  selectObjectFields,
} from "@/reducers/dynamic/dynamicObjectFieldFormReducer";
import { getDynamicObjectByKey } from "@/api/dynamic/dynamicObjectSchemaApi";
import { dateFormatOptions, allowedFileTypeOptions } from "@/utils/dynamic/constants";
import {
  BoxedContent, Header, NoDataFound, CrossButton, Drawer
} from "@/components/common";
import { useUrlSearchParams } from "@/hooks";
import { skeletonLoadingRows } from "@/utils/helpers";
import { handleDrawer } from "@/utils/modal/helpers";

const DynamicObjectDrawer = () => {
  const [state, dispatch] = useReducer(
    dynamicObjectFieldFormReducer,
    initialState
  );
  const [, setSearchParams] = useSearchParams();
  const searchParameters = useUrlSearchParams();
  const drawers = JSON.parse(searchParameters?.drawers || null) || [];
  const isLoaded = useRef(false);

  const { isInitialLoading, data, refetch } = useQuery(
    ["dynamic-object-fields", drawers.at(-1)],
    () => getDynamicObjectByKey(drawers.at(-1)),
    {
      enabled: (drawers.length > 0 && !isLoaded.current),
    }
  );

  useEffect(() => {
    if (drawers.length) refetch();
  }, [drawers.at(-1)]);

  const handleClose = () => {
    handleDrawer(null, setSearchParams, true);
  };

  const prepareStateData = (fields, relationalFieldId = null) => {
    const fieldsState = [];

    fields.forEach((field, index) => {
      if (!relationalFieldId) {
        const fieldIndex = fields
          .filter((f) => !f.relationalFieldId)
          .findIndex((objectField) => objectField.name === field.name);
        field.id = `${field.objectFieldType.toLowerCase()}-${fieldIndex}`;
      }

      field.fieldType = field.objectFieldType.toLowerCase();
      field.fieldName = field.name;
      field.isIndexed = field.indexed;
      field.isRequired = field.required;
      // Email field case is handled
      field.isUnique = field.unique;

      if (relationalFieldId) {
        field.relationalFieldId = relationalFieldId;
        field.id = `${field.relationalFieldId}-${index}`;
      }

      switch (field.fieldType) {
        case "text":
          field.minimum = field.minimumLength || "";
          field.maximum = field.maximumLength || "";
          break;

        case "select":
          field.selectFieldOptions = field.options;
          break;

        case "lookup":
          field.objectName = {
            label: field.lookupObjectName,
            value: field.lookupObjectName,
            key: field.lookupObjectKey,
          };

          field.lookupField = field.lookupDisplayFieldName.map((fieldName) => ({
            label: fieldName,
            value: fieldName,
          }));
          break;

        case "table": {
          const additionalFields = prepareStateData(field.fields, field.id);
          if (additionalFields.length) {
            additionalFields.forEach((f) => fieldsState.push(f));
          }
          break;
        }

        case "geolocation":
          field.geoLocationDisplayFormat = field.displayFormat;
          break;

        case "date": {
          const dateFormat =
            dateFormatOptions.find((d) => d.value === field.displayFormat) ||
            dateFormatOptions[0];
          field.dateDisplayFormat = {
            label: dateFormat.label,
            value: dateFormat.value,
          };
          field.minimumDate = field.minimumDate ?
            new Date(field.minimumDate) :
            null;
          field.maximumDate = field.maximumDate ?
            new Date(field.maximumDate) :
            null;
          break;
        }

        case "attachment": {
          field.allowedFileTypes =
            field.allowedFileTypes.map((fileType) => allowedFileTypeOptions.find(
              ({ value: fileTypeValue }) => fileTypeValue === fileType
            ));
          break;
        }

        default:
          break;
      }

      if (!field.regexValidation) {
        field.regexValidation = "";
      }

      fieldsState.push(field);
    });

    return fieldsState;
  };

  useEffect(() => {
    if (data) {
      const stateData = prepareStateData(data.document);
      dispatch(setObjectFields(stateData));
      isLoaded.current = true;
    }

    return () => { };
  }, [data]);

  const borderColor = (i) => {
    if (i === 0) return;
    return i % 2 ? "second" : "tertiary";
  };

  const renderTable = () => {
    if (!isInitialLoading && data && !data.document) {
      return (
        <NoDataFound
          title="No fields have been added"
          buttonText="Add new field"
          onClick={() => dispatch(showFieldForm())}
        />
      );
    }

    return (
      <DynamicObjectFieldsTable
        fields={selectObjectFields(state) || skeletonLoadingRows(3)}
        dispatch={dispatch}
        isLoading={isInitialLoading}
        hideActionColumn
      />
    );
  };

  return (
    drawers.map((ele, i) => (
      <Drawer key={`drawer-${i}`} isOpen size={500 - (i * 15)} className={`drawer-border ${borderColor(i)}`}>
        <BoxedContent className="mobile-responsive-drawer">
          <Header
            leftContent={<div />}
            rightContent={(
              <CrossButton onClick={handleClose} />
            )}
          />
          {renderTable()}
        </BoxedContent>
      </Drawer>
    ))
  );
};

export default DynamicObjectDrawer;
