import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { format } from "date-fns";
import enUS from "date-fns/locale/en-US";

function DayHeader({ date }) {
  const formattedDate = useMemo(() => format(new Date(date), "EEEE, MMMM dd", { locale: enUS }), [date]);

  useEffect(() => {
    const date = formattedDate.split(" ");
    const dateHTML = `
      <div class="rbc-time-header rbc-overflowing day-header-container">
        <div class="rbc-label rbc-time-header-gutter"></div>
        <div class="rbc-time-header-content"><h3>${date[2]} ${
  date[1]
}, </h3><h6>${date[0].slice(0, -1)}</h6></div>
      </div>
    `;

    const element = document.querySelector(".rbc-time-view");

    if (element) {
      element.insertAdjacentHTML("afterbegin", dateHTML);
    }

    return () => {
      if (element) {
        element.removeChild(element.firstChild);
      }
    };
  }, [formattedDate]);

  return null;
}

DayHeader.propTypes = {
  date: PropTypes.string.isRequired,
};

export default DayHeader;
