import PropTypes from "prop-types";

export default function ModalHeader({ heading, subHeading }) {
  return (
    <header className="modal-header">
      {heading && <h2 className="heading">{heading}</h2>}
      {subHeading && <p className="sub-heading">{subHeading}</p>}
    </header>
  );
}

ModalHeader.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
};

ModalHeader.defaultProps = {
  heading: "",
  subHeading: "",
};
