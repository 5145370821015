import {
  useState, useRef, useMemo, useEffect
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Input, FormControl, Pagination } from "@hydra/atom/components";
import { format } from "date-fns";
import {
  CustomDropdown, CustomizedDateRangePicker, SvgIcon, IconButton
} from "@/components/common";
import { getJournalLedger } from "@/api/finance/reportApi";
import { getJournalLedgerRows, getJournalLedgerColumns } from "./journal-ledger-statement";
import { selectActiveCompany } from "@/store/appSlice";
import TableSkeleton from "@/components/common/TableSkeleton";
import { useDebounce } from "@/hooks";
import appSettings from "@/settings";
import { formatApiPayloadDate } from "@/utils/helpers";

const perPageOptions = [
  { label: "1000", value: 1000 },
  { label: "2000", value: 2000 },
  { label: "3000", value: 3000 },
];

function ReportJournalLedger() {
  const ref = useRef();
  const [filterText, setFilterText] = useState("");
  const debounceSearch = useDebounce(filterText, 800);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const activeCompany = useSelector(selectActiveCompany);
  const [columnsData, setColumnsData] = useState(getJournalLedgerColumns(activeCompany));
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const columns = useMemo(
    () => columnsData.filter((c) => c.value),
    [columnsData, activeCompany?.id]
  );

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };

  const { data: reportData, isLoading } = useQuery(
    ["general-journal", "leasing", dateRange, activeCompany?.id],
    () =>
      getJournalLedger({
        scope: "leasing",
        startDate: formatApiPayloadDate(dateRange[0]),
        endDate: formatApiPayloadDate(dateRange[1]),
        companyId: activeCompany?.id,
      })
  );

  useEffect(() => {
    if (!isLoading) {
      let items = reportData.data;
      if (debounceSearch) {
        items = items
          ?.filter(
            (obj) =>
              obj?.name?.toLowerCase().includes(debounceSearch.toLowerCase()) ||
            obj?.accountName?.toLowerCase().includes(debounceSearch.toLowerCase()) ||
            obj?.buildingName?.toLowerCase().includes(debounceSearch.toLowerCase()) ||
            obj?.accountCode?.toLowerCase().includes(debounceSearch.toLowerCase())
          )
          ?.map((obj, index) => ({ ...obj, sn: index + 1 }));

      }
      const startIndex = (currentPage - 1) * perPage.value;
      const endIndex = Math.min(startIndex + perPage.value, items.length);
      const slicedData = items.slice(startIndex, endIndex);
      setFilteredData(slicedData);
      setTotalCount(items?.length);
    }
  }, [currentPage, perPage.value, isLoading, reportData?.data, dateRange, debounceSearch]);

  useEffect(() => {
    setTotalCount(reportData?.data?.length || 0);
  }, [reportData]);

  const formatReport = (transaction) =>
    transaction?.map((obj, index) => ({ ...obj, sn: index + 1 }));

  const rows = useMemo(
    () =>
      getJournalLedgerRows({
        data: {
          data: formatReport(filteredData) || [],
        },
        columns,
      }),
    [filteredData, columns, debounceSearch]
  );

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const exportCsv = () => window.open(`${appSettings.baseUrl}/finance/reports/journal-ledger/csv?scope=leasing&startDate=${format(dateRange[0], "yyyy-MM-dd")}&endDate=${format(dateRange[1], "yyyy-MM-dd")}&CompanyId=${activeCompany?.id}`, "_blank", "noopener,noreferrer");
  const exportExcel = () => window.open(`${appSettings.baseUrl}/finance/reports/journal-ledger/xls?scope=leasing&startDate=${format(dateRange[0], "yyyy-MM-dd")}&endDate=${format(dateRange[1], "yyyy-MM-dd")}&CompanyId=${activeCompany?.id}`, "_blank", "noopener,noreferrer");

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-5">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name, cost center, account, account code"
          />
        </div>
        <div className="col-md-3 general-date-range">
          <FormControl>
            <CustomizedDateRangePicker
              name="dateRange"
              value={dateRange}
              onChange={(value) => setDateRange(value)}
              required
            />
          </FormControl>
        </div>
        <div className="col-md-4 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportCsv()}
          >
            <SvgIcon icon="upload-cloud-stroke-icon" />
            <span>Download CSV</span>
          </IconButton>
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
        </div>
      </div>
      <div className="react-grid-container">
        {!isLoading ? (
          <ReactGrid
            key={debounceSearch}
            rows={rows}
            columns={columns}
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        ) : (
          <TableSkeleton />
        )}
        {!isLoading && (
        <Pagination
          className="dashboard-pagination journal-ledger-pagination"
          options={perPageOptions}
          perPage={perPage}
          onSelectChange={(val) => handlePagination(currentPage, val)}
          pageRangeDisplayed={3}
          pageNo={currentPage}
          handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
          showResults
          offset={0}
          totalItems={totalCount}
          pageCount={Math.ceil(totalCount / perPage.value)}
          reactPaginateProps={{
            previousLabel: <span className="material-icons">&#xe5cb;</span>,
            nextLabel: <span className="material-icons">&#xe5cc;</span>,
            forcePage: currentPage - 1,
          }}
        />
        )}
      </div>
    </div>
  );
}

export default ReportJournalLedger;
