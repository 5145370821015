import PropTypes from "prop-types";

function TwoColumnList({ data, className = "" }) {
  return (
    <ul className="info-list">
      {
        data.map((item, index) => (
          <li key={`${index}-${item.value}`} className={className}>
            <span className="label">{item.label}</span>
            <span className="value">{item.render ? item.render : item.value}</span>
          </li>
        ))
      }
    </ul>
  );
}

TwoColumnList.propTypes = {
  data: PropTypes.array.isRequired
};

export default TwoColumnList;
