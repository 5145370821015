import PropTypes from "prop-types";
import pluralize from "pluralize";
import { startCase } from "lodash";

import { Accordion } from "@/components/common";
import DynamicObjectOverview from "@/pages/dynamic/list-views/DynamicObjectOverview";

function DynamicLinkedViews({ linkedObjects, id }) {
  return (
    <div className="linked-views-container">
      {linkedObjects
        .filter((v) => v.linkedView)
        .map(({ linkedObjectName, linkedFieldName }) => (
          <Accordion
            key={`accordion-${linkedObjectName}`}
            open={false}
            title={pluralize(startCase(linkedObjectName))}
          >
            <DynamicObjectOverview
              showTableOnly
              objectName={linkedObjectName}
              filters={{
                [linkedFieldName]: id,
              }}
            />
          </Accordion>
        ))}
    </div>
  );
}

DynamicLinkedViews.propTypes = {
  linkedObjects: PropTypes.array,
};

DynamicLinkedViews.defaultProps = {
  linkedObjects: [],
};

export default DynamicLinkedViews;
