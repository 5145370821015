import { useState } from "react";
import PropTypes from "prop-types";
import CalendarDrawer from "./CalendarDrawer";

function CalenderViewMoreSection({ filterEvents }) {
  const [isOpen, setIsOpen] = useState(false);
  const handleViewMore = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  return (
    <div className="calendar-drawer">
      {isOpen && (
        <CalendarDrawer
          isOpen={isOpen}
          onClose={setIsOpen}
          tasks={filterEvents}
        />
      )}
      <button
        type="button"
        className="view-more-container"
        onClick={(e) => { handleViewMore(e); }}
      >
        View more
      </button>
    </div>
  );
}

CalenderViewMoreSection.propTypes = {
  filterEvents: PropTypes.array.isRequired,
};

export default CalenderViewMoreSection;
