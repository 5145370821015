import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { useSearchParams } from "react-router-dom";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import {
  getDynamicObjectRecordById,
} from "@/api/dynamic/dynamicObjectNameApi";
import useTaskRedirect from "@/hooks/useTaskRedirect";
import { getFullName } from "@/utils/helpers";

const formatContract = (contract) => ({
  tcDate: new Date(),
  clearanceDate: new Date(),
  tawtheeqContractNumber: contract.number,
  comments: `Move In Request for Contract ${contract.number}`,
  tenant: {
    ...contract?.tenant,
    value: contract?.tenant?.id,
    label: getFullName(contract?.tenant),
    lookupObjectName: contract?.tenant?.objectName
  },
  contract: {
    label: contract?.number,
    value: contract?.id,
    unit: contract?.unit?.map((u) => u.name).join(", "),
    building: contract?.building
  }
});

function MoveInRequestForm() {
  const ref = useRef(null);
  const [initialState, setInitialState] = useState(null);
  const { redirect } = useTaskRedirect();
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get("contract");

  const { data: contractData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ContractObjectName")), contractId],
    () =>
      getDynamicObjectRecordById(
        dynamicObjectMap.get("ContractObjectName"),
        contractId
      ),
    {
      enabled: Boolean(contractId),
    }
  );

  useEffect(() => {
    if (!contractData) { return; }
    const formattedContract = formatContract(contractData);
    setInitialState(formattedContract);
  }, [contractData]);

  const setTotalAmount = (value) => {
    const { total } = ref.current.getState();
    ref.current.setFormValue("total", Number(total) + Number(value));
  };

  const onStateChange = (key, value) => {
    if (key === "taxAmount") {
      setTotalAmount(value);
    }
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("MoveInRequestObjectName")}
        showHeader
        showLinkedViews
        initialData={initialState}
        onSuccess={(id) =>
          redirect(-1, {
            recordId: id,
            success: true,
          })}
        navigate={false}
        onStateChange={onStateChange}
      />
    </BoxedContent>
  );
}

export default MoveInRequestForm;
