import { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import useTaskRedirect from "@/hooks/useTaskRedirect";
import { useUser } from "@/hooks";
import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";

const formatIncidentReportRequest = (recordData) => {
  const data = {
    jobFor: {
      lookupObjectName: dynamicObjectMap.get("UnitObjectName"),
      label: recordData?.contract?.unit[0].name,
      value: recordData?.contract?.unit[0].id,
    },
    type: { label: "Chargeable", value: "Chargeable" },
  };

  return data;
};

function IncidentReportForm() {
  const { id } = useParams();
  const ref = useRef(null);
  const { redirect } = useTaskRedirect();
  const [initialState, setInitialState] = useState({});
  const { user } = useUser();

  useEffect(() => {
    const userRole = user?.roles;
    if (userRole && (userRole.includes("supervisor") || userRole.includes("cash_officer"))) {
      const supervisor = {
        label: user?.name,
        value: user?.userKey
      };

      setInitialState({
        supervisor,
        isSupervisorDisabled: true
      });
    }
  }, [user]);

  const isEditing = Boolean(id);

  const { data: incidentReportRequestData } = useQuery(
    [kebabCase(dynamicObjectMap.get("IncidentReportObjectName"))],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("IncidentReportObjectName")),
    {
      enabled: Boolean(!isEditing),
    }
  );

  useEffect(() => {
    if (incidentReportRequestData && !isEditing) {
      const formattedIncidentReportRequest = formatIncidentReportRequest(incidentReportRequestData);
      setInitialState(formattedIncidentReportRequest);
    }
  }, [incidentReportRequestData]);

  const onStateChange = (key, value) => {
    if (key === "incident") {
      const totalDamageAmount = value?.reduce((total, item) => total + Number(item.totalAmount), 0);
      ref.current.setFormState({
        total: totalDamageAmount,
      });
    }
  };

  const onChildStateChange = ({
    index,
    key,
    value,
    parentField,
    parentFieldType
  }) => {
    const formState = ref.current.getState();
    const stateKey = `${parentField}${parentFieldType}`;
    let parentFieldState = formState[stateKey] ?? {};

    if (index > -1) {
      parentFieldState = formState[parentField][index];
    }

    if (parentField === "incident") {
      switch (key) {
        case "amount":
        case "discount": {
          parentFieldState[key] = value;
          const { amount, discount } = parentFieldState;

          const totalAmount = parseInt(`${amount - discount}`, 10);
          if (totalAmount >= 0) {
            parentFieldState.totalAmount = totalAmount;
          } else {
            parentFieldState.totalAmount = 0;
          }
          break;
        }

        default:
          break;
      }
    }
  };

  const onSuccess = (recordId) => {
    redirect(-1, {
      recordId,
      success: true,
    });
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("IncidentReportObjectName")}
        showHeader
        initialData={initialState}
        navigate={false}
        onSuccess={onSuccess}
        onStateChange={onStateChange}
        onChildStateChange={onChildStateChange}
        readOnlyFields={initialState.isSupervisorDisabled ? ["supervisor"] : []}
      />
    </BoxedContent>
  );
}

export default IncidentReportForm;
