import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getInvoiceListingColumns() {

  const colArr = [
    {
      label: "Invoice No",
      value: true,
      columnId: "invoiceNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Invoice Type",
      value: true,
      columnId: "invoiceType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Invoice Date",
      value: true,
      columnId: "invoiceDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : ""),
    },
    {
      label: "Due Date",
      value: true,
      columnId: "dueDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : ""),
    },
    {
      label: "Component",
      value: true,
      columnId: "component",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Tenant Code",
      value: true,
      columnId: "tenantCode",
      width: estimatedColumnWidth("code"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Tenant Name",
      value: true,
      columnId: "tenantName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Contract No",
      value: true,
      columnId: "contractNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "Invoice Amount",
      value: true,
      columnId: "invoiceAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "Amount Paid",
      value: true,
      columnId: "amountPaid",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "Balance Amount",
      value: true,
      columnId: "balanceAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    },
    {
      label: "Outstanding",
      value: true,
      columnId: "outstanding",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || ""),
    },
    {
      label: "PDC Amount",
      value: true,
      columnId: "pDCAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => (value ? formatCurrency(String(value || "")) : ""),
    }
  ];

  return colArr;
}
