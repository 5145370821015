import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Loader } from "@hydra/atom/components";

import { DynamicObjectDrawer } from "@/components/dynamic/object-manager";
import { DynamicDrawer, StaticObjectDrawer } from "@/components/drawers";

import { Sidebar, TopNav } from "@/components/dashboard";
import { ErrorBoundary, Page404 } from "@/components/common";

export default function DashboardLayout({ isLoading }) {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  return (
    <div className="dashboard-layout">
      {isSideBarOpen && (
        <div
          className="overlay-item"
          role="presentation"
          onClick={() => setIsSideBarOpen(!isSideBarOpen)}
        />
      )}
      <DynamicObjectDrawer />
      <Sidebar
        isSideBarOpen={isSideBarOpen}
        setIsSideBarOpen={setIsSideBarOpen}
        isLoading={isLoading}
      />
      <TopNav isLoading={isLoading} showSideBar={() => setIsSideBarOpen(!isSideBarOpen)} />
      <main className="dashboard-content">
        {isLoading ? (
          <Loader />
        ) : (
          <ErrorBoundary fallback={<Page404 />}>
            <Outlet />
          </ErrorBoundary>
        )}
      </main>
      <DynamicDrawer />
      <StaticObjectDrawer />
    </div>
  );
}
