import qs from "qs";
import request from "@/utils/api/helpers";

export default async function getElasticSearchResults(params) {
  const queryParams = qs.stringify(params, {
    addQueryPrefix: true
  });

  return request({
    url: `/object-search/find-matches${queryParams}`,
    method: "GET"
  });
}
