import PropTypes from "prop-types";

function ActivityTimelineHeader({ title, children }) {
  return (
    <header className="activity-timeline-header">
      <h2>{title}</h2>
      {children}
    </header>
  );
}

ActivityTimelineHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default ActivityTimelineHeader;
