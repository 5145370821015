import { useEffect, useState } from "react";
import { kebabCase, startCase } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Loader, TextArea } from "@hydra/atom/components";
import { Pill } from "@/components/dashboard";
import { selectActiveCompany } from "@/store/appSlice";
import { ActionDropdown } from "@/components/dynamic";
import PdfFileIcon from "@/assets/images/file-pdf-icon.png";

import {
  BoxedContent,
  Header,
  CrossButton,
  Drawer,
  Accordion,
  TextEditor,
} from "@/components/common";
import { InfoTable } from "@/components/facility/maintenance";
import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import { getDynamicObjectByNameWithCamelizedFieldNames } from "@/api/dynamic/dynamicObjectSchemaApi";

import { getTestId } from "@/utils/helpers";
import { handleDrawer } from "@/utils/modal/helpers";
import {
  formatDynamicObjectRecord,
  getDynamicObjectName,
  renderDynamicFieldValue,
  getObjectNameFromId
} from "@/utils/dynamic/helpers";

export default function DynamicDrawer() {
  const [searchParams, setSearchParams] = useSearchParams();

  const drawers = JSON.parse(searchParams.get("dynamicObjectDrawers") || null) || [];
  const objectName = drawers.length ? getObjectNameFromId(drawers.at(-1)) : null;

  const { data: objectSchema, isInitialLoading: isLoadingSchema } = useQuery(
    ["dynamic-object-camelized-schema", objectName],
    () => getDynamicObjectByNameWithCamelizedFieldNames(objectName),
    {
      enabled: Boolean(objectName),
    }
  );

  const { data, isInitialLoading: isLoading } = useQuery(
    [kebabCase(objectName), drawers.at(-1)],
    () => getDynamicObjectRecordById(objectName, drawers.at(-1)),
    {
      enabled: Boolean(objectName),
    }
  );

  const handleClose = () => {
    handleDrawer(null, setSearchParams, true, "dynamicObjectDrawers");
  };

  return (
    <Drawer
      isOpen={Boolean(drawers.at(-1))}
      className="drawer-border drawer-profile-border"
      onClose={handleClose}
    >
      <BoxedContent className="profile-drawer mobile-responsive-drawer dynamic-drawer">
        <Header
          leftContent={startCase(objectName)}
          rightContent={(
            <div className="d-flex align-items-center">
              <ActionDropdown
                objectName={objectName}
                id={data?.id}
                data={data}
                actions={objectSchema?.actions || []}
                testId={getTestId("Action-Dropdown")}
                trigger={(
                  <div className="action-dropdown-menu-trigger">
                    <span className="material-icons material-icons-outlined">more_vert</span>
                  </div>
                )}
                showTableActions
              />
              <CrossButton onClick={handleClose} />
            </div>
          )}
        />
        {isLoading || isLoadingSchema ? (
          <Loader />
        ) : (
          <div className="parent-container">
            {drawers.at(-1) && (
              <ObjectOverview objectName={objectName} data={data} objectSchema={objectSchema} />
            )}
          </div>
        )}
      </BoxedContent>
    </Drawer>
  );
}

function ObjectOverview({ objectName, data, objectSchema }) {
  const [recordDetails, setRecordDetails] = useState([]);
  const [tableDetails, setTableDetails] = useState([]);
  const [attachmentDetails, setAttachmentDetails] = useState([]);
  const [textAreaDetails, setTextAreaDetails] = useState([]);
  const [richTextDetails, setRichTextDetails] = useState([]);
  const activeCompany = useSelector(selectActiveCompany);
  const defaultCurrencyCode = activeCompany && activeCompany?.defaultCurrencyCode ? activeCompany.defaultCurrencyCode : "AED";

  useEffect(() => {
    if (objectSchema && data) {
      const {
        recordData, tableData, attachmentData, textAreaData, richTextData
      } =
        formatDynamicObjectRecord({
          objectSchema,
          data,
          defaultCurrencyCode,
        });

      setRecordDetails(recordData);
      setTableDetails(tableData);
      setAttachmentDetails(attachmentData);
      setTextAreaDetails(textAreaData);
      setRichTextDetails(richTextData);
    }
  }, [objectSchema, data]);

  return (
    <>
      <p className="headline">{getDynamicObjectName(objectName, data)}</p>
      <div className="tags-container">
        <Pill className="tag" text={data.number} variant="blue" />
      </div>
      <Accordion title={`${startCase(objectName)} details`}>
        <table>
          <tbody>
            {recordDetails.map((item, index) => (
              <tr key={`item-${index}`}>
                <td className="row-headline">{item.label}</td>
                <td className="row-info">{renderDynamicFieldValue(item)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Accordion>
      {tableDetails.map((tableDetail, index) => (
        <Accordion key={`table-detail-${index}`} title={tableDetail.label}>
          <InfoTable data={tableDetail.data} columns={tableDetail.columns} />
        </Accordion>
      ))}
      {textAreaDetails.map((textAreaDetail, index) => (
        <Accordion key={`text-detail-${index}`} title={textAreaDetail.label}>
          <TextArea
            id={textAreaDetail.label}
            name={textAreaDetail.label}
            value={textAreaDetail.value}
          />
        </Accordion>
      ))}
      {attachmentDetails.map((attachmentDetail, index) => (
        <Accordion key={`attachment-${index}`} title={attachmentDetail.label}>
          <div className="attachment-container">
            {attachmentDetail.value.map((attachment) => (
              <a key={attachment.url} target="_blank" href={attachment.url}>
                <img src={attachment.url.toLowerCase().endsWith(".pdf") ? PdfFileIcon : attachment.url} alt="Attachment" width={attachment.url.toLowerCase().endsWith(".pdf") ? 35 : 100} height={attachment.url.toLowerCase().endsWith(".pdf") ? 35 : 100} />
              </a>
            ))}
          </div>
        </Accordion>
      ))}
      {richTextDetails.map((richText, index) => (
        <Accordion key={`rich-text-detail-${index}`} title={richText.label}>
          <TextEditor
            id={richText.label}
            name={richText.label}
            value={richText.value || ""}
            disabled
          />
        </Accordion>
      ))}
    </>
  );
}
