import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { SvgIcon, IconButton } from "@/components/common";
import { handleDrawer } from "@/utils/modal/helpers";

function LinkCell({ value, row }) {
  return (
    <div className="link-cell">
      <Link to={handleDrawer(
        row.original.id,
        null,
        false,
        "dynamicObjectDrawers"
      )}
      >
        {value}
      </Link>
    </div>
  );
}

function ActionCell({ remove, row }) {
  return (
    <div className="action-cell">
      <IconButton onClick={() => remove(row.original)}>
        <SvgIcon icon="delete-stroke-icon" />
      </IconButton>
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
};

function CheckboxCell({ value }) {
  return (
    <div className="checkbox-cell">
      <span>{value ? "Yes" : "No"}</span>
    </div>
  );
}

export const getQuotationsTableColumns = () => [
  {
    Header: "Quotation",
    accessor: "number",
    Cell: LinkCell,
  },
  {
    Header: "Approved",
    accessor: "isApproved",
    Cell: CheckboxCell,
  },
  {
    Header: "Action",
    Cell: ActionCell,
    collapse: true,
  },
];

export const getQuotationsTableData = (data) => {
  const items = data.quotations;
  if (!items) return [];

  return items?.map((item, index) => ({
    ...item,
    index,
  }));
};
