import PropTypes from "prop-types";
import { Header } from "@/components/common";
import { getTestId } from "@/utils/helpers";

function LeftContent({ title, subtitle }) {
  return (
    <div className="container-header-left-content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );
}

LeftContent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

LeftContent.defaultProps = {
  title: "",
  subtitle: ""
};

function WidgetSteps({
  steps, onClickStep, testId
}) {
  const getStepClassName = (step) => {
    let className = "company-step-item";

    if (step.isSkipped) {
      className += " company-step-skipped";
    }

    if (step.isCompleted) {
      className += " company-step-success";
    }

    if (step.isActive) {
      className += " company-step-primary";
    }

    return className;
  };

  return (
    <div className="company-step">
      {
        steps.map((step, index) => (
          <div
            key={`company-step-${index}`}
            className={getStepClassName(step)}
            role="presentation"
            onClick={() => onClickStep(index)}
            data-testid={getTestId(`${testId}-${index}`)}
          >
            <Header
              leftContent={<LeftContent {...step} />}
            />
          </div>
        ))
      }
    </div>
  );
}

export default WidgetSteps;
