import { useRef } from "react";
import PropTypes from "prop-types";
import { components } from "react-select";

import {
  Form, Button, FormControl, Label,
  ReactSelect, Input
} from "@hydra/atom/components";
import {
  Modal, FormLeftHeader, CustomizedDatePicker
} from "@/components/common";
import { ModalHeader, ModalFooter } from "@/components/modals";
import { getTestId } from "@/utils/helpers";
import FieldLabel from "@/components/dynamic/fields/FieldLabel";

function Option(props) {
  const { data, testId } = props;
  return (
    <div data-testid={getTestId(`${testId}-Option`)}>
      <components.Option {...props}>
        <div className="react-select-option">
          <p>{data.label}</p>
          <p>{data.subtitle}</p>
        </div>
      </components.Option>
    </div>
  );
}

Option.propTypes = {
  data: PropTypes.object.isRequired,
};

const options = [
  { label: "Credit", value: "credit" },
  { label: "Debit", value: "debit" },
];

const contractOptions = [
  { label: "#00934239482", value: "00934239482" },
  { label: "#009343424482", value: "009343424482" },
];

const paymentTypeOptions = [
  { label: "Cheque", value: "cheque" },
];

const chequeOptions = [
  { label: "56450-343", value: "56450-343" },
  { label: "76450-343", value: "76450-343" },
];

const periodTypeOptions = [
  { label: "Grace Period", value: "grace-period" },
];

const periodDurationOptions = [
  { label: "1 month", value: "one-month" },
];

const statusOptions = [
  { label: "Paid", value: "paid" },
];

const paymentMethodOptions = [
  { label: "Bank Transfer", value: "bank-transfer" },
];

export default function BankReconciliationModal({
  isOpen, onClose, onSave, state, onChange
}) {
  const submitButtonRef = useRef(null);

  const handleClose = () => {
    onClose(false);
  };

  const handleSubmit = () => {
    onSave();
  };

  if (!isOpen) return null;

  switch (state.type) {
    case "update-transaction":
      return (
        <Modal
          rootClassName="center-vertically add-workorder-modal bank-reconcile-modal"
          isOpen={isOpen}
          onClose={handleClose}
        >
          <div className="core-modal-content select-work-order-type-modal">
            <ModalHeader
              heading="Update Transaction"
            />
            <Form onSubmit={handleSubmit} shouldScrollOnError>
              <div className="row gy-3">
                <div className="col-md-3">
                  <FormLeftHeader
                    title="Select Category"
                    subtitle="Provide Name of Cost Center"
                  />
                </div>
                <div className="col-md-9">
                  <div className="row gy-3">
                    <div className="col-md-6">
                      <FormControl>
                        <Label htmlFor="transaction-category" label="Transaction Category*" />
                        <ReactSelect
                          id="select-category"
                          name="transaction-category"
                          rules="required"
                          value={state.transactionCategory}
                          onChange={(value) => onChange("transactionCategory", value)}
                          options={options}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
                <hr className="full-hr" />
                <div className="col-md-3">
                  <FormLeftHeader
                    title="Payment Details"
                    subtitle="Provide Name of Cost Center"
                  />
                </div>
                <div className="col-md-9">
                  <div className="row gy-3">
                    <div className="col-md-6">
                      <FormControl>
                        <Label htmlFor="contract-number" label="Contract Number*" />
                        <ReactSelect
                          id="select-contract"
                          name="contract-number"
                          rules="required"
                          value={state?.contractNumber}
                          onChange={(value) => onChange("contractNumber", value)}
                          options={contractOptions}
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl>
                        <FieldLabel label="Tenant Name" />
                        <Input
                          name="tenant-name"
                          placeholder="Enter Tenant Name"
                          value={state.tenantName}
                          onChange={(value) => onChange("tenantName", value)}
                          isClearable
                          backspaceRemovesValue
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl>
                        <Label htmlFor="payment-type" label="Payment Type" />
                        <ReactSelect
                          id="payment-type"
                          name="payment-type"
                          rules="required"
                          value={state?.paymentType}
                          onChange={(value) => onChange("paymentType", value)}
                          options={paymentTypeOptions}
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl>
                        <Label htmlFor="select-cheque" label="Select Cheque" />
                        <ReactSelect
                          id="select-cheque"
                          name="cheque"
                          rules="required"
                          value={state?.cheque}
                          onChange={(value) => onChange("cheque", value)}
                          options={chequeOptions}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="col-md-12"><hr className="full-hr" /></div>
                <div className="col-md-3">
                  <FormLeftHeader
                    title="Amount"
                    subtitle="Provide Name of Cost Center"
                  />
                </div>
                <div className="col-md-9">
                  <div className="row gy-3">
                    <div className="col-md-6">
                      <FormControl>
                        <FieldLabel label="Original Amount" />
                        <Input
                          name="originalAmount"
                          placeholder="Enter Amount"
                          value={state.originalAmount}
                          onChange={(value) => onChange("originalAmount", value)}
                          isClearable
                          backspaceRemovesValue
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl>
                        <FieldLabel label="Cheque Amount" />
                        <Input
                          name="chequeAmount"
                          placeholder="Enter Amount"
                          value={state.chequeAmount}
                          onChange={(value) => onChange("chequeAmount", value)}
                          isClearable
                          backspaceRemovesValue
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl>
                        <FieldLabel label="Adjustment" />
                        <Input
                          name="adjustment"
                          placeholder="Enter Adjustment"
                          value={state.adjustment}
                          onChange={(value) => onChange("adjustment", value)}
                          isClearable
                          backspaceRemovesValue
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl>
                        <Label htmlFor="status" label="Status" />
                        <ReactSelect
                          id="status"
                          name="status"
                          rules="required"
                          value={state?.status}
                          onChange={(value) => onChange("status", value)}
                          options={statusOptions}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              <button ref={submitButtonRef} type="submit" style={{ display: "none" }}>Submit</button>
            </Form>
          </div>
          <ModalFooter>
            <div className="modal-footer-right">
              <button className="cancel-btn" onClick={handleClose} type="button">
                Delete
              </button>
              <Button small className="save-btn" onClick={() => submitButtonRef.current.click()}>
                Update
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      );
    case "allocated-amount":
      return (
        <Modal
          rootClassName="center-vertically add-workorder-modal"
          isOpen={isOpen}
          onClose={handleClose}
        >
          <div className="core-modal-content select-work-order-type-modal">
            <ModalHeader
              heading="Allocate The Amount"
            />
            <Form onSubmit={handleSubmit} shouldScrollOnError>
              <div className="row gy-3">
                <div className="col-md-3">
                  <FormLeftHeader
                    title="Customer"
                    subtitle="Provide Name of Cost Center"
                  />
                </div>
                <div className="col-md-9">
                  <div className="row gy-3">
                    <div className="col-md-6">
                      <FormControl>
                        <FieldLabel label="Customer ID" />
                        <Input
                          name="customer-id"
                          placeholder="Enter Customer ID"
                          value={state.customerId}
                          onChange={(value) => onChange("customerId", value)}
                          isClearable
                          backspaceRemovesValue
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl>
                        <FieldLabel label="Customer Name" />
                        <Input
                          name="customer-name"
                          placeholder="Enter Customer Name"
                          value={state.customerName}
                          onChange={(value) => onChange("customerName", value)}
                          isClearable
                          backspaceRemovesValue
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
                <hr className="full-hr" />
                <div className="col-md-3">
                  <FormLeftHeader
                    title="Payment Details"
                    subtitle="Provide Name of Cost Center"
                  />
                </div>
                <div className="col-md-9">
                  <div className="row gy-3">
                    <div className="col-md-6">
                      <FormControl>
                        <Label htmlFor="payment-method" label="Payment Method" />
                        <ReactSelect
                          id="payment-method"
                          name="paymentMethod"
                          rules="required"
                          value={state?.paymentMethod}
                          onChange={(value) => onChange("paymentMethod", value)}
                          options={paymentMethodOptions}
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl>
                        <FieldLabel label="Reference Number" />
                        <Input
                          name="reference-number"
                          placeholder="Enter Reference Number"
                          value={state.referenceNumber}
                          onChange={(value) => onChange("referenceNumber", value)}
                          isClearable
                          backspaceRemovesValue
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl>
                        <Label htmlFor="date" label="Date" />
                        <CustomizedDatePicker
                          name="date"
                          value={state?.date}
                          onChange={(value) => onChange("date", value)}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="col-md-12"><hr className="full-hr" /></div>
                <div className="col-md-3">
                  <FormLeftHeader
                    title="Contract Duration"
                    subtitle="Provide Name of Cost Center"
                  />
                </div>
                <div className="col-md-9">
                  <div className="row gy-3">
                    <div className="col-md-6">
                      <FormControl>
                        <Label htmlFor="startDate" label="Start Date" />
                        <CustomizedDatePicker
                          name="startDate"
                          value={state?.startDate}
                          onChange={(value) => onChange("startDate", value)}
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl>
                        <Label htmlFor="endDate" label="End Date" />
                        <CustomizedDatePicker
                          name="endDate"
                          value={state?.endDate}
                          onChange={(value) => onChange("endDate", value)}
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl>
                        <Label htmlFor="period-type" label="Period Type" />
                        <ReactSelect
                          id="period-type"
                          name="periodType"
                          rules="required"
                          value={state?.periodType}
                          onChange={(value) => onChange("periodType", value)}
                          options={periodTypeOptions}
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl>
                        <Label htmlFor="period-duration" label="Period Duration" />
                        <ReactSelect
                          id="period-duration"
                          name="periodDuration"
                          rules="required"
                          value={state?.periodDuration}
                          onChange={(value) => onChange("periodDuration", value)}
                          options={periodDurationOptions}
                        />
                      </FormControl>
                    </div>
                    <div className="col-md-6">
                      <FormControl>
                        <FieldLabel label="Number of Duration" />
                        <Input
                          name="duration"
                          placeholder="Enter Number of Duration"
                          value={state.duration}
                          onChange={(value) => onChange("duration", value)}
                          isClearable
                          backspaceRemovesValue
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              <button ref={submitButtonRef} type="submit" style={{ display: "none" }}>Submit</button>
            </Form>
          </div>
          <ModalFooter>
            <div className="modal-footer-right">
              <button className="cancel-btn" onClick={handleClose} type="button">
                Delete
              </button>
              <Button small className="save-btn" onClick={() => submitButtonRef.current.click()}>
                Update
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      );
    default:
      return null;
  }
}
