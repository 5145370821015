import { useState } from "react";
import {
  DynamicReport
} from "@/components/dynamic";
import { getOutputVATRows, getOutputVATColumns } from "./output-vat-statement";
import { getOutputVat } from "@/api/finance/reportApi";
import { formatNumber } from "@/utils/helpers";

const outputVATFilters = {
  RecordType: "*",
};

const outputVATFilterOptions = [
  { key: "recordType", apiKey: "RecordType", keyType: "label" },
];

export default function ReportOutputVAT({ activeCompany }) {
  const [reportCardState, setReportCardState] = useState(null);

  const handleDataLoaded = (data) => {
    const sums = data.reduce((acc, item) => {
      acc.vATValue += item.vATValue;
      return acc;
    }, {
      vATValue: 0,
    });

    const reportCardData = {
      size: 1,
      dataChunks: 1,
      data: [
        {
          titleValue: `${formatNumber(sums?.vATValue || 0)}`,
          titleColor: "success",
          subtitle: "VAT Value (AED)",
          subtitleColor: "primary",
        }
      ],
    };
    setReportCardState(reportCardData);
  };

  return (
    <DynamicReport
      reportName="vat-input"
      activeCompany={activeCompany}
      columnsDataProvider={getOutputVATColumns}
      rowsDataProvider={getOutputVATRows}
      fetchData={getOutputVat}
      exportUrl="reports/export-query-report/vat-output"
      filtersInitialState={outputVATFilters}
      filterOptions={outputVATFilterOptions}
      orderBy="CompanyName"
      onDataLoaded={handleDataLoaded}
      reportCardData={reportCardState}
    />
  );
}
