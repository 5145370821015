import { createSlice } from "@reduxjs/toolkit";
import { parsePermissions } from "@/utils/helpers";

const initialState = {
  user: null,
  isLoggedIn: false,
  permissions: null,
};

export const userSlice = createSlice({
  name: "user",

  initialState,

  reducers: {
    setUserConnect(state, action) {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logoutUserConnect(state) {
      state.user = null;
      state.isLoggedIn = false;
      state.permissions = null;
    },
    setPermissions(state, action) {
      state.permissions = parsePermissions(action.payload);
    },
  },
});

export const { setUserConnect, logoutUserConnect, setPermissions } = userSlice.actions;

export const selectUser = (state) => state[userSlice.name].user;
export const selectIsLoggedIn = (state) => state[userSlice.name].isLoggedIn;
export const selectPermissions = (state) => state[userSlice.name].permissions;
export const checkPermission =
  ({ parent, scope, action }) =>
    (state) => {
      const { permissions } = state[userSlice.name];

      if (!permissions) return false;

      return (
        permissions[parent][scope]?.includes("Any") || permissions[parent][scope]?.includes(action)
      );
    };
export const selectPermission = ({ parent, scope, action }) =>
  checkPermission({ parent, scope, action });
