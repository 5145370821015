import { getDailyTransactionDetailRows, getDailyTransactionDetailColumns } from "./daily-transaction-detail-statement";
import { getDailyTransactionDetail } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportDailyTransactionDetail({ activeCompany }) {
  return (
    <DynamicReport
      reportName="daily-transaction-detail"
      activeCompany={activeCompany}
      columnsDataProvider={getDailyTransactionDetailColumns}
      rowsDataProvider={getDailyTransactionDetailRows}
      fetchData={getDailyTransactionDetail}
      exportUrl="reports/export-query-report/daily-transaction-detail"
      filtersInitialState={{}}
      orderBy="Week"
    />
  );
}
