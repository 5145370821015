import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { BoxedContent, Header } from "@/components/common";
import { CardLayout, CardAnalytics } from "@/components/dashboard";
import { BarChart, PieChart } from "@/components/charts";
import { barChartData, pieChartData } from "@/components/charts/chartData";
import { selectActiveApp } from "@/store/appSlice";
import {
  getDynamicDashboardView,
} from "@/api/dynamic/dynamicDashboardViewApi";
import { DashboardComponentLoader } from "@/components/dynamic";

function Dashboard() {
  const activeApp = useSelector(selectActiveApp);
  const { schemaId } = useParams();

  const {
    data: dashboardComponents
  } = useQuery(
    ["dynamic-dashboard-schema", schemaId],
    () => getDynamicDashboardView(schemaId),
    {
      enabled: schemaId !== undefined,
    }
  );

  const getDashboardComponentContainerClassName = (componentConfig) => {
    switch (componentConfig.dashboardType.toLowerCase()) {
      case "tabular":
        return "col-12";

      default:
        return "col-lg-6 col-md-12";
    }
  };

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        className="page-header"
        leftContent={(
          <h1>
            {activeApp.value}
            {" "}
            Dashboard
          </h1>
        )}
      />

      <div className="row cards-row">
        <div className="col-md-3">
          <CardLayout
            className="analytics"
            title="Total impressions"
          >
            <CardAnalytics title={435.435} />
          </CardLayout>
        </div>
        <div className="col-md-3">
          <CardLayout
            className="analytics"
            title="Total actions"
          >
            <CardAnalytics title={643} />
          </CardLayout>
        </div>
        <div className="col-md-3">
          <CardLayout className="analytics" title="Total locations">
            <CardAnalytics title={44} />
          </CardLayout>
        </div>
        <div className="col-md-3">
          <CardLayout className="analytics" title="Unverified listings">
            <CardAnalytics title={6} />
          </CardLayout>
        </div>
      </div>
      <div className="row cards-row">
        <div className="col-lg-6 col-md-12">
          <CardLayout title="Insights">
            <BarChart data={barChartData} keys={["value", "value1"]} />
          </CardLayout>
        </div>
        <div className="col-lg-6 col-md-12">
          <CardLayout title="Summary">
            <PieChart data={pieChartData} />
          </CardLayout>
        </div>
        {dashboardComponents?.data.map((dashboardComponent, index) => (
          <div
            key={`${dashboardComponent.name}-${index}`}
            className={getDashboardComponentContainerClassName(
              dashboardComponent
            )}
          >
            <CardLayout title={dashboardComponent.label}>
              <DashboardComponentLoader config={dashboardComponent} />
            </CardLayout>
          </div>
        ))}
      </div>
    </BoxedContent>
  );
}

export default Dashboard;
