import PropTypes from "prop-types";
import { useTable } from "react-table";
import { Input } from "@hydra/atom/components";
import { useEffect, useState } from "react";
import { pascalize } from "humps";
import {
  SvgIcon
} from "@/components/common";

function Table({
  columns, data, updateMyData, className = "", type, allowMultiple, isSelectAll, onSelectAll
}) {
  const {
    getTableProps, getTableBodyProps, headerGroups, prepareRow, rows
  } =
    useTable({
      columns,
      data,
      updateMyData,
    });

  return (
    <table className={`table ${className}`} {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr
            className="table-header-row"
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className={`table-header-cell ${column.collapse ? "collapse" : ""}`}
              >
                <div className="table-header-cell-content">
                  {column.Header === "Select" && allowMultiple && (
                  <input
                    type="checkbox"
                    checked={isSelectAll}
                    onChange={onSelectAll}
                  />
                  )}
                  {column.render("Header")}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (type === "default" ? (
            <tr {...row.getRowProps()} className="table-row">
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className={`table-body-cell ${cell.column.collapse ? "collapse" : ""}`}
                >
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          ) : (
            <tr {...row.getRowProps()} className="table-row">
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className={`table-body-cell ${cell.column.collapse ? "collapse" : ""}`}
                >
                  {cell.render("Cell")}
                </td>
              ))}
            </tr>
          )
          );
        })}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  updateMyData: PropTypes.func.isRequired,
};

function TableWithCheckbox({
  columns, data, setData, allowMultiple, className = "", selectAll, searchKey = [], type = "default"
}) {
  const [search, setSearch] = useState("");
  const [isSelectAll, setIsSelectAll] = useState(selectAll);
  const [dataItems, setDataItems] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);

  function searchObjectsByKeys(obj, keyStrings, searchString) {
    return obj.filter((item) => {
      for (const keyString of keyStrings) {
        const keys = keyString.split(".");
        let value = item;
        let isValid = true;
        for (let i = 0; i < keys.length; i += 1) {
          const key = keys[i];
          if (isValid && value && typeof value === "object" && Object.prototype.hasOwnProperty.call(value, key)) {
            value = value[key];
            if (value && value[keys[i + 1]] && (typeof value[keys[i + 1]] === "string" && (value[keys[i + 1]]).toLowerCase().includes(searchString))) {
              return true;
            }
            if (typeof value === "string" && value && `${value}`.toLowerCase().includes(searchString)) {
              return true;
            }
          } else {
            isValid = false;
            break;
          }
        }
      }
      return false;
    });
  }

  useEffect(() => { setDataItems(data); }, [data]);

  useEffect(() => {
    if (search && search !== "") {
      const matchingObjects = searchObjectsByKeys(data, searchKey, search);
      setDataItems(matchingObjects);
    } else {
      setDataItems(data);
    }
  }, [search]);

  const updateMyData = (rowIndex, columnId, value) => {
    setDataItems((old) => old.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          [columnId]: value,
        };
      }

      if (value && !allowMultiple) {
        return {
          ...row,
          [columnId]: false
        };
      }

      return row;
    })
    );
    const rowKey = dataItems.find((item, index) => index === rowIndex).key;
    if (!rowKey) {
      return;
    }

    setData((old) =>
      old.map((row) => {
        if (rowKey === row.key) {
          return {
            ...row,
            [columnId]: value,
            updateSequence: updateCount,
          };
        }
        if (value && !allowMultiple) {
          return {
            ...row,
            [columnId]: false,
          };
        }

        return { ...row };
      }));

    setUpdateCount((prevCount) => prevCount + 1);

  };

  const onSelectAll = () => {
    setData((old) => old.map((row) => ({ ...row, isSelected: !isSelectAll })));
    setDataItems((old) => old.map((row) => ({ ...row, isSelected: !isSelectAll })));
    setIsSelectAll(!isSelectAll);
  };

  return (
    <>
      {searchKey.length > 0 ? (
        <div className="row">
          <h1 className="filter-title">Search</h1>
          <Input
            className="search-bar-container"
            placeholder={`Search by ${searchKey.map((item) => pascalize(item.split(".")[0]).replace(/([a-z])([A-Z])/g, "$1 $2")).join(", ")}`}
            value={search}
            onChange={setSearch}
          >
            <Input.PrependIcon>
              <SvgIcon icon="search" />
            </Input.PrependIcon>
          </Input>
        </div>
      ) : null}
      <div className="table-wrapper scrollable" style={{ maxHeight: "40vh" }}>
        <Table
          className={className}
          columns={columns}
          data={dataItems}
          updateMyData={updateMyData}
          type={type}
          onSelectAll={onSelectAll}
          allowMultiple={allowMultiple}
          isSelectAll={isSelectAll}
        />
      </div>
    </>
  );
}

export default TableWithCheckbox;
