import { useState, useEffect } from "react";
import { kebabCase } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "@hydra/atom/components";
import PropTypes from "prop-types";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getSubmittedQuotationTableColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";

import { Accordion } from "@/components/common";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import P2PWorkflowFormContainer from "./P2PWorkflowFormContainer";

const formatQuotation = (quotation) => ({
  key: quotation.id,
  quotation: {
    label: quotation.number,
    value: quotation.id
  },
  supplier: {
    label: quotation.supplier.number,
    value: quotation.supplier.id
  },
  total: quotation.total,
  isApproved: quotation.isApproved ? "Yes" : "No"
});

function DetailItem({ heading, item }) {
  return (
    <div className="row">
      <div className="col-12">
        <h6 className="headline">{heading}</h6>
        {item}
      </div>
    </div>
  );
}

DetailItem.propTypes = {
  item: PropTypes.node.isRequired,
};

function QuotationsTable({ task }) {
  const [quotationsTableData, setQuotationsTableData] = useState([]);

  const { data, isInitialLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("QuotationObjectName")),
      {
        requestForQuotation: task?.recordId,
        queryMode: "Deep",
      },
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("QuotationObjectName"), {
        requestForQuotation: task?.recordId,
        queryMode: "Deep",
      }),
    { enabled: task?.preObjectName === "RequestForQuotation" }
  );

  useEffect(() => {
    if (data?.data && data?.data?.length) {
      const formattedQuotations = data?.data.map((q) => formatQuotation(q));
      setQuotationsTableData(formattedQuotations);
    }
  }, [data]);

  if (task?.preObjectName !== "RequestForQuotation" || task?.taskType === "Submit") {
    return null;
  }

  if (isInitialLoading) return <Loader />;

  return quotationsTableData.length ? (
    <div className="accordion-row">
      <Accordion title="Quotations" count={data.data?.length}>
        <TableWithCheckbox
          data={quotationsTableData}
          setData={setQuotationsTableData}
          columns={getSubmittedQuotationTableColumns()}
          disabled
        />
      </Accordion>
    </div>
  ) : null;
}

function P2PWorkflowContainer({
  task, recordData, onSuccess, setIsLoading
}) {
  return (
    <div>
      <QuotationsTable task={task} />
      <P2PWorkflowFormContainer
        task={task}
        recordData={recordData}
        setIsLoading={setIsLoading}
        onSuccess={onSuccess}
      />
    </div>
  );
}

P2PWorkflowContainer.propTypes = {
  task: PropTypes.object.isRequired,
  recordData: PropTypes.object.isRequired,
};

export default P2PWorkflowContainer;
