import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { selectActiveApp } from "@/store/appSlice";
import { SvgIcon, Tooltip, IconButton } from "@/components/common";
import { ProfileDropdown, SideBarDrawer, SearchInput } from "@/components/dashboard";
import { useIsMobile, useUser } from "@/hooks";
import { settingsMenu } from "./data";
import TuliLogo from "@/assets/images/logos/tuli.svg";
import { VENDOR_USER_TYPE, SUPPLIER_USER_TYPE } from "@/utils/userTypes";

function assignDepth(items, parentName = null, currentDepth = 0) {
  let itemsWithDepth = [];

  items.forEach((item) => {
    if (item.parent === parentName) {
      const newItem = { ...item, depth: currentDepth };
      itemsWithDepth.push(newItem);
      itemsWithDepth = itemsWithDepth.concat(assignDepth(items, item.text, currentDepth + 1));
    }
  });

  return itemsWithDepth;
}

function SideBarNavItem({
  href,
  item,
  setIsSideBarOpen,
  submenu,
  setSubmenu,
  renderAsExternalLink,
}) {
  const [isActiveLink, setIsActiveLink] = useState(false);
  const isMobileView = useIsMobile(992);
  const { icon, text, type } = item;

  useEffect(() => {
    if (submenu !== item) {
      setIsActiveLink(false);
    }
  }, [submenu]);

  if (type === "profile") {
    return <ProfileDropdown />;
  }

  if (renderAsExternalLink) {
    return (
      <Tooltip
        activator={(
          <span>
            <SvgIcon icon={icon} />
          </span>
        )}
        side="right"
      >
        <a href={href}>{text}</a>
      </Tooltip>
    );
  }

  if (type === "submenu") {
    return (
      <NavLink
        className={() => (isActiveLink ? "active" : "")}
        to={null}
        onClick={() => {
          setIsActiveLink(true);
          setSubmenu(item);
          setIsSideBarOpen(true);
        }}
      >
        {(!submenu || (submenu && !isMobileView)) && (
          <span className="mobile-nav-items">
            <SvgIcon icon={icon} />
            <h4>{text}</h4>
          </span>
        )}
        {submenu && isMobileView && (
          <Tooltip
            activator={(
              <span>
                <SvgIcon icon={icon} />
              </span>
            )}
            side="right"
          >
            {text}
          </Tooltip>
        )}
      </NavLink>
    );
  }

  return (
    <NavLink
      className={({ isActive }) => (isActive && !submenu ? "active" : "")}
      onClick={() => {
        setSubmenu(null);
        setIsSideBarOpen(false);
      }}
      to={href}
    >
      {(!submenu || (submenu && !isMobileView)) && (
        <span className="mobile-nav-items">
          <SvgIcon icon={icon} />
          <h4>{text}</h4>
        </span>
      )}
      {submenu && isMobileView && (
        <Tooltip
          activator={(
            <span>
              <SvgIcon icon={icon} />
            </span>
          )}
          side="right"
        >
          {text}
        </Tooltip>
      )}
    </NavLink>
  );
}

SideBarNavItem.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  setIsSideBarOpen: PropTypes.func,
  renderAsExternalLink: PropTypes.bool,
};

SideBarNavItem.defaultProps = {
  href: null,
  text: null,
  icon: null,
  setIsSideBarOpen: () => {},
  renderAsExternalLink: false,
};

function SideBarNavGroup({
  navItems,
  className,
  setSubmenu,
  setIsSideBarOpen,
  isSideBarOpen,
  submenu,
  query,
}) {
  const renderNavItems = (items) =>
    items
      .filter((item) => {
        if (item.type === "submenu" && item.items && item.items.length > 0) return true;
        if (item.type !== "submenu") {
          return true;
        }
        return false;
      }).map((item, idx) => (
        <li key={idx} className="nav-item" style={{ marginLeft: item?.depth ? item.depth * 15 : 0 }}>
          <SideBarNavItem
            href={item.href}
            item={item}
            submenu={submenu}
            setIsSideBarOpen={setIsSideBarOpen}
            isSideBarOpen={isSideBarOpen}
            setSubmenu={setSubmenu}
            showComingSoonLabel={item.showComingSoonLabel}
            renderAsExternalLink={item.renderAsExternalLink}
          />
        </li>
      ));

  const decoratedNavItems = query ? assignDepth(navItems) : navItems;

  return (
    <div className={`sidebar-nav-group ${className}`}>
      <ul>{renderNavItems(decoratedNavItems)}</ul>
    </div>
  );
}

SideBarNavGroup.propTypes = {
  navItems: PropTypes.array,
  className: PropTypes.string,
  setIsSideBarOpen: PropTypes.func,
  isSideBarOpen: PropTypes.bool,
};

SideBarNavGroup.defaultProps = {
  className: "",
  navItems: [],
  setIsSideBarOpen: () => {},
  isSideBarOpen: false,
};

const flattenMenuItems = (items, userType, arr = []) => {
  items.forEach((item) => {
    switch (userType) {
      case VENDOR_USER_TYPE:
        if (["Tasks", "Work Order Job"].includes(item.text)) {
          arr.push(item);
        }
        break;

      case SUPPLIER_USER_TYPE:
        if (["Tasks", "Request For Quotation", "Quotations"].includes(item.text)) {
          arr.push(item);
        }
        break;

      default:
        if (["link", "submenu"].includes(item.type)) {
          arr.push(item);
        }
        break;
    }

    if (item.type === "menu") {
      return flattenMenuItems(item.items, userType, arr);
    }
  });

  return arr;
};

export default function Sidebar({ isSideBarOpen, setIsSideBarOpen }) {
  const activeApp = useSelector(selectActiveApp);
  const [submenu, setSubmenu] = useState();
  const [query, setQuery] = useState("");
  const isMobileView = useIsMobile(992);
  const shownMenuItems = activeApp?.value ? activeApp.menuItems : [];
  const { userType } = useUser();
  const filteredItems = flattenMenuItems(shownMenuItems, userType);

  useEffect(() => {
    if (isMobileView && submenu == null) {
      setIsSideBarOpen(!isSideBarOpen);
    }
  }, [submenu]);

  const flattenMenu = (items, parent = null) => {
    let flattenedItems = [];

    items.forEach((item) => {
      const flattenedItem = { ...item, parent };
      flattenedItems.push(flattenedItem);

      if (item.type === "submenu" && item.items) {
        flattenedItems = flattenedItems.concat(flattenMenu(item.items, item.text));
      }
    });

    return flattenedItems;
  };

  const handleSearch = () => {
    if (query) {
      const flatItems = flattenMenu(filteredItems);
      const searchValue = query.toLowerCase();

      let filteredResults = flatItems.filter(
        (item) =>
          item.text.toLowerCase().includes(searchValue) ||
        (item.type === "submenu" &&
          item.items.some((subItem) =>
            subItem.text.toLowerCase().includes(searchValue)
          ))
      );

      const matchingParents = flatItems.filter(
        (item) => item.type === "submenu" && item.text.toLowerCase().includes(searchValue)
      );

      matchingParents.forEach((parent) => {
        const children = flatItems.filter(
          (item) => item.parent === parent.text
        );
        filteredResults.push(parent, ...children);
      });

      filteredResults = filteredResults.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.text === item.text && t.parent === item.parent
        ))
      );
      return filteredResults;

    }

    const flatItems = flattenMenu(filteredItems);
    const filteredResults = query ?
      flatItems
        .filter(
          (item) =>
            item.text.toLowerCase().includes(query.toLowerCase()) ||
                (item.type === "submenu" &&
                  (item.items.some((subItem) =>
                    subItem.text.toLowerCase().includes(query.toLowerCase())
                  ) ||
                    item.text.toLowerCase().includes(query.toLowerCase())))
        )
        .reduce((result, item) => {
          if (item.type === "submenu") {
            result.push({ ...item });
          } else {
            result.push(item);
          }
          return result;
        }, []) :
      filteredItems;
    return filteredResults;
  };
  return (
    <>
      <aside className={`dashboard-sidebar ${isSideBarOpen || !isMobileView ? "open" : ""}`}>
        {isMobileView && !submenu ? (
          <div
            className={`menu-toggle ${isSideBarOpen ? "" : "rotate-icon"}`}
            role="presentation"
            onClick={() => setIsSideBarOpen(!isSideBarOpen)}
          >
            {isSideBarOpen ? <h5>Menu</h5> : null}
            <SvgIcon icon="back-arrow" />
          </div>
        ) : null}
        <nav className="dashboard-sidebar-nav">
          <div className="sidebar-top-container">
            {!isMobileView ? (
              <>
                <div className="main-logo-container">
                  <img src={TuliLogo} className="main-logo" alt="Tuli Logo" />
                </div>
                <div className="search-with-close-icon">
                  <SearchInput
                    className="sidebar-search-input"
                    value={query}
                    onChange={setQuery}
                  />
                  {query && (
                    <IconButton
                      onClick={() => {
                        setQuery("");
                      }}
                    >
                      <SvgIcon icon="icon-close" />
                    </IconButton>
                  )}
                </div>
              </>
            ) : null}
            <SideBarNavGroup
              navItems={handleSearch()}
              submenu={submenu}
              setSubmenu={setSubmenu}
              isSideBarOpen={isSideBarOpen}
              setIsSideBarOpen={setIsSideBarOpen}
              query={query}
            />
          </div>

          <SideBarNavGroup
            className="settings"
            navItems={settingsMenu}
            title="Settings"
            setIsSideBarOpen={setIsSideBarOpen}
            isSideBarOpen={isSideBarOpen}
            submenu={submenu}
            setSubmenu={setSubmenu}
          />
        </nav>
      </aside>
      {!query && (
        <div className="side-bar-drawer">
          <SideBarDrawer
            submenu={submenu}
            setSubmenu={setSubmenu}
            setIsSideBarOpen={setIsSideBarOpen}
            isSideBarOpen={isSideBarOpen}
          />
        </div>
      )}
    </>
  );
}

Sidebar.propTypes = {
  isSideBarOpen: PropTypes.bool.isRequired,
  setIsSideBarOpen: PropTypes.func.isRequired,
};
