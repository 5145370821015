import qs from "qs";
import request from "@/utils/api/helpers";
import { buildTree, flattenTree } from "@/utils/finance/helpers";

const baseUrl = "/finance/ledger-accounts";

export const getLedgerAccounts = (params = {}) => {
  const headers = {
    includeCompanyId: true
  };

  const { companyId, includeCompanyId, ...rest } = params;

  if (typeof includeCompanyId === "boolean" && !includeCompanyId) {
    headers.includeCompanyId = false;
  }

  if (headers.includeCompanyId && companyId) {
    headers.Companyid = params.companyId;
  }

  const queryParams = qs.stringify(rest, {
    addQueryPrefix: true
  });

  return request({
    url: `${baseUrl}${queryParams}`,
    headers
  });
};

export const getLedgerAccountById = (id) =>
  request({
    url: `${baseUrl}/${id}`,
  });

export const createLedgerAccount = (data) =>
  request({
    url: baseUrl,
    method: "POST",
    data,
  });

export const createMultiCompanyLedgerAccount = (data) =>
  request({
    url: `${baseUrl}/multi-company`,
    method: "POST",
    data,
  });

export const updateLedgerAccount = (id, data) =>
  request({
    url: `${baseUrl}/${id}`,
    method: "PATCH",
    data,
  });

export const deleteLedgerAccount = (id) =>
  request({
    url: `${baseUrl}/${id}`,
    method: "DELETE",
  });

export const uploadCOAExcelFile = async (data) => {
  const { file, companyId } = data;

  const formData = new FormData();
  formData.append("File", file, file.name);
  formData.append("CompanyId", companyId);

  return request({
    url: `${baseUrl}/import`,
    method: "POST",
    headers: {
      "Content-type": "multipart/form-data",
    },
    data: formData,
  });
};

export const getLedgerAccountCode = (data) =>
  request({
    url: `${baseUrl}/next-code`,
    method: "POST",
    data,
  });

export const getFormattedLedgerAccounts = (params = {}) => {
  const queryParams = qs.stringify(params);

  const searchParams = queryParams ? `?${queryParams}` : "";

  return request({
    url: `${baseUrl}${searchParams}`,
  }).then((res) => {
    res.data = flattenTree(buildTree(res.data, 1));
    return res;
  });
};
