import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import { getDynamicObjectRecordById, getFieldDynamicObjectArabicRecordName } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { useCompanyAccount } from "@/hooks";

const tenantInitialState = {
  firstName: "",
  middleName: "",
  lastName: "",
  gender: null,
  dOB: "",
  email: "",
  phone: "",
  mobile: "",
  permanentAddress: "",
};

const formatReservation = (reservation, itemArabicName) => {
  const data = {
    reservation: { label: reservation.number, value: reservation.id },
    firstName: reservation?.lead?.firstName,
    middleName: reservation?.lead?.middleName,
    lastName: reservation?.lead?.lastName,
    name: reservation?.lead?.name,
    email: reservation?.lead?.email,
    openingBalance: reservation?.openBalance,
    nameArabic: itemArabicName?.fullName || "",
    phone: reservation?.lead?.phone,
    dOB: reservation?.lead?.dOB,
    permanentAddress: reservation?.lead?.address || reservation?.lead?.permanentAddress,

  };

  if (reservation?.lead?.gender) {
    data.gender = {
      label: reservation?.gender,
      value: reservation?.gender,
    };
  }

  if (reservation?.lead?.type) {
    data.type = {
      label: reservation?.lead?.type,
      value: reservation?.lead?.type
    };
  }

  if (reservation?.lead?.tRN) {
    data.tRN = reservation?.lead?.tRN;
  }

  if (reservation?.lead?.dateOfBirth || reservation?.lead?.dOB) {
    data.dOB = new Date(reservation?.dateOfBirth || reservation?.dOB);
  }
  return data;
};

const formatLead = (lead) => {
  const data = {
    firstName: lead.firstName,
    middleName: lead.middleName,
    lastName: lead.lastName,
    name: lead.name,
    email: lead.email,
    phone: lead.phone,
    dOB: lead.dOB,
    nameArabic: lead.nameArabic,
    permanentAddress: lead.address || lead.permanentAddress,
    type: {
      label: lead.type,
      value: lead.type
    },
    tRN: lead.tRN
  };

  if (lead.gender) {
    data.gender = {
      label: lead.gender,
      value: lead.gender,
    };
  }

  if (lead.dateOfBirth || lead.dOB) {
    data.dOB = new Date(lead.dateOfBirth || lead.dOB);
  }

  return data;
};

function TenantForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});
  const [initialState, setInitialState] = useState({});
  const [searchParams] = useSearchParams();
  const leadId = searchParams.get("lead");
  const reservationId = searchParams.get("reservation");
  const defaultAccounts = useCompanyAccount({
    params: {
      includeCompanyId: true,
      isLinkedWithRecord: false
    }
  });

  useEffect(() => {
    if (defaultAccounts && !initialState.areAccountsSelected) {
      const {
        customerReceivable,
        bounceChequePenalty,
        customerAdvance,
        securityDeposit,
        contractBreakPenalty,
        customerRefund
      } = defaultAccounts;

      setInitialState((prevState) => ({
        ...prevState,
        areAccountsSelected: true,
        receivableAccount: customerReceivable,
        bouncedChequePenaltyAccount: bounceChequePenalty,
        securityDepositAccount: securityDeposit,
        customerAdvanceAccount: customerAdvance,
        contractTerminationAccount: contractBreakPenalty,
        customerRefundAccount: customerRefund,
      }));
    }
  }, [defaultAccounts]);

  const { data: reservationData, isFetched: reservationFetched } = useQuery(
    [state?.reservation?.objectName, state?.reservation?.id],
    () => getDynamicObjectRecordById(state?.reservation?.objectName, state?.reservation?.id),
    {
      enabled: Boolean(state?.reservation?.id),
    }
  );

  const { data: itemArabicName, isFetched: isFetchedArabicNameApi } = useQuery(
    [`arabic-${reservationData?.lead?.name || state?.reservation?.lead?.name}`],
    () => getFieldDynamicObjectArabicRecordName({
      fullName: (reservationData?.lead?.name || state?.reservation?.lead?.name),
      outputLanguage: "Arabic"
    }),
    {
      enabled: Boolean((reservationFetched && !!reservationData?.lead?.name) ||
      state?.reservation?.lead?.name)
    }
  );

  useEffect(() => {
    if (reservationId) {
      setState({
        reservation: {
          objectName: "Reservation",
          id: reservationId,
        },
      });
    }
  }, [reservationId]);

  useEffect(() => {
    if (reservationData || state?.reservation?.id) {
      ref.current.setFormState(formatReservation((reservationData || state?.reservation),
        itemArabicName));
      setInitialState((prevState) => ({
        ...prevState,
        ...formatReservation((reservationData || state?.reservation), itemArabicName)
      }));
    } else {
      ref.current.setFormState(tenantInitialState);
    }
  }, [reservationData, state.reservation, isFetchedArabicNameApi]);

  const { data: leadData } = useQuery(
    [state?.lead?.objectName, state?.lead?.id],
    () => getDynamicObjectRecordById(state?.lead?.objectName, state?.lead?.id),
    {
      enabled: Boolean(state?.lead?.id),
    }
  );

  useEffect(() => {
    if (leadId) {
      setState({
        lead: {
          objectName: "Lead",
          id: leadId,
        },
      });
    }
  }, [leadId]);

  useEffect(() => {
    if (leadData || state?.lead?.id) {
      ref.current.setFormState(formatLead(leadData || state.lead));
      setInitialState((prevState) => ({
        ...prevState,
        ...formatLead(leadData || state.lead)
      }));
    } else {
      ref.current.setFormState(tenantInitialState);
    }
  }, [leadData, state.lead]);

  const onStateChange = (key, value) => {
    if (key === "reservation") {
      setState((prevState) => ({
        ...prevState,
        reservation: value,
        lead: value?.lead,
      }));
    }
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        initialData={initialState}
        objectName={dynamicObjectMap.get("TenantObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        readOnlyFields={reservationId ? ["reservation"] : []}
      />
    </BoxedContent>
  );
}

export default TenantForm;
