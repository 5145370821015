import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { ResponsiveBar } from "@nivo/bar";

const lightTheme = ["#9E77ED", "#D6BBFB"];

function BarChart({
  data, bottomAxisLegend, keys, groupMode, colors, legends
}) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const animation = setTimeout(() => setChartData(data), 1);

    return () => {
      clearTimeout(animation);
    };
  }, [data]);

  return (
    <div className="bar-chart">
      <ResponsiveBar
        data={chartData}
        keys={keys}
        indexBy="label"
        margin={{
          top: 15,
          right: 0,
          bottom: bottomAxisLegend ? 45 : 24,
          left: 0,
        }}
        padding={0.3}
        groupMode={groupMode}
        layout="vertical"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={colors}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 9,
          tickRotation: 0,
          legend: bottomAxisLegend,
          legendPosition: "middle",
          legendOffset: 35,
          textColor: "blue",
        }}
        axisLeft={null}
        theme={{
          grid: {
            line: {
              stroke: "#F2F4F7",
            }
          },
          axis: {
            ticks: {
              text: {
                fontSize: 12,
                lineHeight: "18px",
                fontWeight: 400,
                fontFamily: "Inter",
                fill: "#475467",
                outlineWidth: 0,
                outlineColor: "transparent"
              }
            },
          },
        }}
        legends={legends ? [
          {
            dataFrom: "keys",
            anchor: "top-right",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: -15,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 8,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 7,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ] : []}
        animate
        motionConfig="slow"
        enableLabel={false}
        enableGridX={false}
        gridYValues={6}
      />
    </div>
  );
}

BarChart.propTypes = {
  data: PropTypes.array.isRequired,
  bottomAxisLegend: PropTypes.array,
  keys: PropTypes.array.isRequired,
  groupMode: PropTypes.string,
  colors: PropTypes.array,
};

BarChart.defaultProps = {
  groupMode: "stacked",
  bottomAxisLegend: null,
  colors: lightTheme
};

export default BarChart;
