import PropTypes from "prop-types";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { DynamicFormContainer } from "@/components/dynamic";
import { TASK_TYPES } from "@/components/workflow/constants";

function PettyCashWorkflowFormContainer({ task, recordData, setIsLoading }) {

  const renderForm = () => {
    if (
      task?.objectName === dynamicObjectMap.get("PettyCashRequestObjectName") &&
      task?.currentStep === "Petty Cash Request" &&
      task?.taskType === TASK_TYPES.APPROVAL
    ) {
      return (
        <DynamicFormContainer
          initialData={{
            amountApproved: recordData.amount
          }}
          objectName={task.objectName}
          selectedId={recordData?.id}
          setIsLoading={setIsLoading}
          navigate={false}
          showButtons={false}
          layout="Approve Petty Cash Request"
          readOnlyFields={["amount"]}
        />
      );
    }

    if (
      task?.objectName === dynamicObjectMap.get("PettyCashRequestObjectName") &&
      task?.currentStep === "Petty Cash Request" &&
      task?.taskType === TASK_TYPES.UPDATE
    ) {
      return (
        <DynamicFormContainer
          initialData={{
            amount: recordData.amount
          }}
          objectName={task.objectName}
          selectedId={recordData?.id}
          setIsLoading={setIsLoading}
          navigate={false}
          showButtons={false}
          layout="Update Petty Cash Request"
        />
      );
    }

    return null;
  };

  return <div>{renderForm()}</div>;
}

PettyCashWorkflowFormContainer.propTypes = {
  task: PropTypes.object.isRequired,
  recordData: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default PettyCashWorkflowFormContainer;
