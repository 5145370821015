import request from "@/utils/api/helpers";

const budgetBaseUrl = "/finance/budgets";
const budgetCategoryBaseUrl = "/finance/budget-categories";
const periodicBudgetBaseUrl = "/finance/periodic-budgets";

export const getBudgets = () => request({
  url: budgetBaseUrl
});

export const getBudgetById = (id) => request({
  url: `${budgetBaseUrl}/${id}`
});

export const createBudget = (data) => request({
  url: budgetBaseUrl,
  method: "POST",
  data
});

export const updateBudget = (id, data) => request({
  url: `${budgetBaseUrl}/${id}`,
  method: "PATCH",
  data
});

export const deleteBudget = (id) => request({
  url: `${budgetBaseUrl}/${id}`,
  method: "DELETE",
});

export const importBudgetData = async (data) => {
  const { file, accountPeriodId, interval } = data;

  const formData = new FormData();
  formData.append("File", file, file.name);
  formData.append("AccountPeriodId", accountPeriodId);
  formData.append("Interval", interval);

  return request({
    url: `${budgetBaseUrl}/import`,
    method: "POST",
    headers: {
      "Content-type": "multipart/form-data",
    },
    data: formData,
  });
};

export const getBudgetCategories = () => request({
  url: budgetCategoryBaseUrl
});

export const getBudgetCategoryById = (id) => request({
  url: `${budgetCategoryBaseUrl}/${id}`
});

export const createBudgetCategory = (data) => request({
  url: budgetCategoryBaseUrl,
  method: "POST",
  data
});

export const updateBudgetCategory = (id, data) => request({
  url: `${budgetCategoryBaseUrl}/${id}`,
  method: "PATCH",
  data
});

export const deleteBudgetCategory = (id) => request({
  url: `${budgetCategoryBaseUrl}/${id}`,
  method: "DELETE",
});

export const getBudgetPeriods = (data) => request({
  url: `${budgetBaseUrl}/project`,
  method: "POST",
  data
});

export const getPeriodicBudgets = () => request({
  url: budgetCategoryBaseUrl
});

export const getPeriodicBudgetById = (id) => request({
  url: `${periodicBudgetBaseUrl}/${id}`
});

export const createPeriodicBudget = (data) => request({
  url: periodicBudgetBaseUrl,
  method: "POST",
  data
});

export const updatePeriodicBudget = (id, data) => request({
  url: `${periodicBudgetBaseUrl}/${id}`,
  method: "PATCH",
  data
});

export const deletePeriodicBudget = (id) => request({
  url: `${periodicBudgetBaseUrl}/${id}`,
  method: "DELETE",
});
