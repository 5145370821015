import { useState, useRef, useMemo } from "react";
import { ReactGrid } from "@silevis/reactgrid";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { Input, FormControl } from "@hydra/atom/components";
import { format } from "date-fns";
import {
  CustomDropdown, CustomizedDateRangePicker, SvgIcon, IconButton
} from "@/components/common";
import getGeneralJournal from "@/api/finance/reportApi";
import { getGeneralJournalRows, getGeneralJournalColumns } from "./general-journal-statement";
import { selectActiveCompany } from "@/store/appSlice";
import TableSkeleton from "@/components/common/TableSkeleton";
import { useDebounce } from "@/hooks";
import appSettings from "@/settings";
import { formatApiPayloadDate } from "@/utils/helpers";

function ReportGeneralJournal() {
  const ref = useRef();
  const [filterText, setFilterText] = useState("");
  const debounceSearch = useDebounce(filterText, 800);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const activeCompany = useSelector(selectActiveCompany);
  const [columnsData, setColumnsData] = useState(getGeneralJournalColumns(activeCompany));

  const columns = useMemo(
    () => columnsData.filter((c) => c.value),
    [columnsData, activeCompany?.id]
  );

  const { data: reportData, isLoading } = useQuery(
    ["general-journal", "leasing", dateRange, activeCompany?.id],
    () =>
      getGeneralJournal({
        scope: "leasing",
        startDate: formatApiPayloadDate(dateRange[0]),
        endDate: formatApiPayloadDate(dateRange[1]),
        companyId: activeCompany?.id,
      })
  );

  const formatReport = (transaction) => {
    if (debounceSearch) {
      return transaction
        ?.filter(
          (obj) =>
            obj?.name?.toLowerCase().includes(debounceSearch.toLowerCase()) ||
            obj?.accountName?.toLowerCase().includes(debounceSearch.toLowerCase()) ||
            obj?.buildingName?.toLowerCase().includes(debounceSearch.toLowerCase())
        )
        ?.map((obj, index) => ({ ...obj, sn: index + 1 }));
    }

    return transaction?.map((obj, index) => ({ ...obj, sn: index + 1 }));
  };

  const rows = useMemo(
    () =>
      getGeneralJournalRows({
        data: {
          data: formatReport(reportData?.data) || [],
        },
        columns,
      }),
    [reportData, columns, debounceSearch]
  );

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const exportCsv = () => window.open(`${appSettings.baseUrl}/finance/reports/general-journal/csv?scope=leasing&startDate=${format(dateRange[0], "yyyy-MM-dd")}&endDate=${format(dateRange[1], "yyyy-MM-dd")}&CompanyId=${activeCompany?.id}`, "_blank", "noopener,noreferrer");
  const exportExcel = () => window.open(`${appSettings.baseUrl}/finance/reports/general-journal/xls?scope=leasing&startDate=${format(dateRange[0], "yyyy-MM-dd")}&endDate=${format(dateRange[1], "yyyy-MM-dd")}&CompanyId=${activeCompany?.id}`, "_blank", "noopener,noreferrer");

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-5">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name, cost center, account"
          />
        </div>
        <div className="col-md-3 general-date-range">
          <FormControl>
            <CustomizedDateRangePicker
              name="dateRange"
              value={dateRange}
              onChange={(value) => setDateRange(value)}
              required
            />
          </FormControl>
        </div>
        <div className="col-md-4 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportCsv()}
          >
            <SvgIcon icon="upload-cloud-stroke-icon" />
            <span>Download CSV</span>
          </IconButton>
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
        </div>
      </div>
      <div className="react-grid-container">
        {!isLoading ? (
          <ReactGrid
            key={debounceSearch}
            rows={rows}
            columns={columns}
            onColumnResized={handleColumnResize}
          />
        ) : (
          <TableSkeleton />
        )}
      </div>
    </div>
  );
}

export default ReportGeneralJournal;
