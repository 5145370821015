import { useQuery } from "@tanstack/react-query";

import {
  BoxedContent,
  Header,
  NoDataFound,
} from "@/components/common";

import { DynamicObjectTable } from "@/components/dynamic/object-manager";
import { getAllDynamicObjects } from "@/api/dynamic/dynamicObjectSchemaApi";
import {
  getDynamicObjectColumns,
  getDynamicObjectData,
} from "@/components/dynamic/object-manager/dynamicObjectTableData";
import { skeletonLoadingRows } from "@/utils/helpers";

function DynamicObjectManager() {
  const { isLoading, data } = useQuery(["dynamic-objects"], getAllDynamicObjects);

  const renderTable = () => {
    if (!isLoading && data && !data?.data) {
      return (
        <NoDataFound
          title="No objects have been created"
        />
      );
    }

    return (
      <DynamicObjectTable
        data={getDynamicObjectData(data?.data || skeletonLoadingRows())}
        tableColumns={getDynamicObjectColumns(data?.data || [])}
        isLoading={isLoading}
      />
    );
  };

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<h1>Object Manager</h1>}
      />
      {renderTable()}
    </BoxedContent>
  );
}

export default DynamicObjectManager;
