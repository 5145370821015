import PropTypes from "prop-types";

function IconButton({
  children, onClick, className, baseClassName
}) {
  const getClassName = () => {
    if (className) {
      return `${baseClassName} ${className}`;
    }

    return baseClassName;
  };

  return (
    <button className={getClassName()} type="button" onClick={onClick}>
      {children}
    </button>
  );
}

IconButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.node
  ]).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  baseClassName: PropTypes.string,
};

IconButton.defaultProps = {
  className: "",
  baseClassName: "icon-button",
  onClick: () => {}
};

export default IconButton;
