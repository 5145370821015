import PropTypes from "prop-types";
import DateRangeTimePicker from "@wojtekmaj/react-datetimerange-picker";
import { getTestId } from "@/utils/helpers";

export default function CustomizedDateTimeRangePicker({ value, onChange, testId }) {
  return (
    <DateRangeTimePicker
      className="custom-date-range-time-picker"
      clockIcon={null}
      clearIcon={null}
      onChange={onChange}
      value={value}
      data-testid={getTestId(testId)}
    />
  );
}

CustomizedDateTimeRangePicker.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

CustomizedDateTimeRangePicker.defaultProps = {
  testId: ""
};
