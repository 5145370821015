import toast from "react-hot-toast";
import { SvgIcon, IconButton } from "@/components/common";

const getContainerClassName = (type) => {
  let className = "custom-toast";

  switch (type) {
    case "success":
    case "info":
    case "error":
      className += `-${type}`;
      break;
    default:
      className += "-error";
      break;
  }

  return className;
};

const getMessageClassName = (type) => {
  let className = getContainerClassName(type);
  className += "-message";
  return className;
};

const getIconClassName = (type) => {
  switch (type) {
    case "success":
      return "check-circle";
    default:
      return "alert-circle";
  }
};

export default function CustomToast({ type, message, toastId }) {
  return (
    <div className="custom-toast-container">
      <div className={getContainerClassName(type)}>
        <IconButton>
          <SvgIcon icon={getIconClassName(type)} />
        </IconButton>
        <IconButton onClick={() => { toast.dismiss(toastId); }}>
          <SvgIcon icon="icon-close" />
        </IconButton>
      </div>
      <p className={getMessageClassName(type)}>
        {message}
      </p>
    </div>
  );
}
