import { useRef } from "react";
import PropTypes from "prop-types";

import { SvgIcon } from "@/components/common";
import { checkIfAttachmentIsImage, getTestId } from "@/utils/helpers";

function prepareFile(file) {
  return {
    url: URL.createObjectURL(file),
    isImage: checkIfAttachmentIsImage(file.name),
    file,
  };
}

function FileUploadInput({
  buttonText,
  onFileUploaded,
  buttonClass,
  acceptedFormats,
  allowMultiple,
  disabled,
  isUploading,
  uploadProgress,
  testId,
  required
}) {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    if (!allowMultiple) {
      onFileUploaded([prepareFile(event.target.file || event.target.files[0])]);
    } else {
      const uploadedFiles = [];

      for (let i = 0; i < event.target.files.length; i += 1) {
        uploadedFiles.push(prepareFile(event.target.files[i]));
      }
      onFileUploaded(uploadedFiles);
    }
    event.target.value = null;
  };

  return (
    <>
      <div className="file-upload-input">
        {isUploading ? (
          <button type="button" className={buttonClass}>
            <SvgIcon icon="upload-cloud-stroke-icon" />
            <span className="button-text">Uploading...</span>
            <span className="button-subtitle">{`${uploadProgress}%`}</span>
          </button>
        ) : (
          <button
            type="button"
            onClick={handleClick}
            className={buttonClass}
            data-testid={getTestId(testId)}
          >
            <SvgIcon icon="upload-cloud-stroke-icon" />
            <span className="button-text">{buttonText}</span>
            <span className="button-subtitle">SVG, PNG, JPG or GIF (max. 800x400px)</span>
          </button>
        )}
      </div>

      <input
        type="file"
        accept={acceptedFormats}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ position: "absolute", opacity: "0", cursor: "pointer", zIndex: "-1" }}
        multiple={allowMultiple}
        disabled={disabled}
        required={required}
      />
    </>
  );
}

FileUploadInput.propTypes = {
  buttonText: PropTypes.string,
  onFileUploaded: PropTypes.func.isRequired,
  buttonClass: PropTypes.string,
  acceptedFormats: PropTypes.string,
  allowMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  isUploading: PropTypes.bool,
  uploadProgress: PropTypes.number,
  testId: PropTypes.string,
};

FileUploadInput.defaultProps = {
  buttonClass: "btn-link",
  buttonText: "Upload File",
  acceptedFormats: ".doc,.docx,.pdf,application/msword,image/png,image/gif,image/jpeg,image/jpg",
  allowMultiple: false,
  disabled: false,
  isUploading: false,
  uploadProgress: 0,
  testId: "",
};

export default FileUploadInput;
