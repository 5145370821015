import { getSupplierAgingRows, getSupplierAgingColumns } from "./supplier-aging-statement";
import { getConsolidatedSupplierAging } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportSupplierAging({ activeCompany }) {
  return (
    <DynamicReport
      reportName="supplier-aging"
      activeCompany={activeCompany}
      columnsDataProvider={getSupplierAgingColumns}
      rowsDataProvider={getSupplierAgingRows}
      fetchData={getConsolidatedSupplierAging}
      exportUrl="reports/export-query-report/consolidated-supplier-aging-report"
      filtersInitialState={{}}
      orderBy="SupplierName"
    />
  );
}
