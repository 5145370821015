import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  unreadNotificationCount: 0,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setUnreadNotificationCount: (state, action) => {
      state.unreadNotificationCount = action.payload;
    },
    resetNotifications: (state) => {
      state.unreadNotificationCount = 0;
    },
  },
});

export const { setUnreadNotificationCount, resetNotifications } =
  notificationSlice.actions;

export const selectUnreadNotificationCount = (state) =>
  state[notificationSlice.name].unreadNotificationCount;
