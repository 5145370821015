import { createSlice } from "@reduxjs/toolkit";
import { adminApp } from "../components/dashboard/data";
import { setCompany, setPrecision } from "@/api/user/authApi";

const initialState = {
  apps: [],
  companies: [],
  activeApp: null,
  activeCompany: "",
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setActiveApp: (state, action) => {
      state.activeApp = action.payload;
    },
    addActiveAppMenuItems: (state, action) => {
      state.activeApp.menuItems[0].items = [
        ...state.activeApp.menuItems[0].items,
        ...action.payload,
      ];
    },
    setActiveCompany: (state, action) => {
      setCompany(action.payload.id);
      setPrecision(action?.payload?.defaultCurrency?.precision || 0);
      const currency = action.payload.defaultCurrency;
      let defaultCurrencyCode = "";
      let defaultCurrency = null;

      if (currency) {
        defaultCurrencyCode = currency.code;
        defaultCurrency = {
          label: currency.code,
          value: currency.id,
        };
      }

      state.activeCompany = {
        ...action.payload,
        precision: action?.payload?.defaultCurrency?.precision,
        defaultCurrency,
        defaultCurrencyCode,
      };
    },
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
    setApps: (state, action) => {
      state.apps = action.payload;
    },
    resetApp: (state) => {
      state.apps = [];
      state.activeApp = adminApp;
      state.activeCompany = "";
    },
  },
});

export const {
  setActiveApp,
  addActiveAppMenuItems,
  setActiveCompany,
  setCompanies,
  setApps,
  resetApp,
} = appSlice.actions;

export const selectActiveApp = (state) => state[appSlice.name].activeApp;
export const selectActiveCompany = (state) => state[appSlice.name].activeCompany;
export const selectApps = (state) => state[appSlice.name].apps.filter((a) => a.label !== "Reports");
export const selectCompanies = (state) => state[appSlice.name].companies;
