import { useState, useRef, useMemo } from "react";
import { ReactGrid } from "@silevis/reactgrid";
import { Input } from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import { getSupplierPaidInvoicesRows, getSupplierPaidInvoicesColumns } from "./supplier-statement";
import DynamicObjectDetails from "@/pages/dynamic/detail-views/DynamicObjectDetails";
import { getPaidInvoices } from "@/api/finance/reportApi";

function ReportSupplierPaidInvoices({ id }) {
  const ref = useRef();
  const [filterPaymentText, setFilterPaymentText] = useState("");

  const [paymentColumnsData, setPaymentColumnsData] = useState(
    getSupplierPaidInvoicesColumns(ref?.current?.clientWidth)
  );

  const { data: paymentData } = useQuery(["paid-invoices", id], () => getPaidInvoices({ SupplierId: id }));
  const paymentColumns = useMemo(() => paymentColumnsData.filter(
    (c) => c.value), [paymentColumnsData]);

  const formatReport = (transaction) => {
    if (filterPaymentText) {
      return transaction.filter((obj) => obj.invoiceNumber.toLowerCase()
        .includes(filterPaymentText.toLowerCase()));
    }
    return transaction;
  };

  const advancePaymentRows = useMemo(
    () =>
      getSupplierPaidInvoicesRows({
        data: {
          data: formatReport(paymentData?.data || []),
        },
        columns: paymentColumns,
      }),
    [paymentData?.data, paymentColumns, filterPaymentText]
  );

  return (
    <>
      <DynamicObjectDetails objectName="Supplier" id={id} showHeader={false} showTransfers={false} />
      <div className="table-wrapper report-sheet" ref={ref}>
        <div className="row filter-row">
          <div className="col-md-3">
            <Input
              className="input-height-fix"
              value={filterPaymentText}
              onChange={setFilterPaymentText}
              placeholder="Search by Invoice Number"
            />
          </div>
        </div>
        <div className="react-grid-container">
          <h6><b>Paid Invoices</b></h6>
          <ReactGrid
            key={filterPaymentText}
            rows={advancePaymentRows}
            columns={paymentColumns}
            stickyTopRows={1}
            enableFillHandle
            enableRangeSelection
            disableVirtualScrolling
          />
        </div>
      </div>
    </>
  );
}

export default ReportSupplierPaidInvoices;
