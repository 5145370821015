import { getForthcomingVacancyRows, getForthcomingVacancyColumns } from "./forthcoming-vacancy";
import { getForthcomingVacancy } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportForthcomingVacancy({ activeCompany }) {
  return (
    <DynamicReport
      reportName="forthcoming-vacancy"
      activeCompany={activeCompany}
      columnsDataProvider={getForthcomingVacancyColumns}
      rowsDataProvider={getForthcomingVacancyRows}
      fetchData={getForthcomingVacancy}
      exportUrl="reports/export-query-report/forthcoming-vacancy"
      filtersInitialState={{}}
      orderBy={null}
      isPaginated={false}
      showDateRangeFilter={false}
    />
  );
}
