import PropTypes from "prop-types";
import Drawer from "react-modern-drawer";
import { useIsMobile } from "@/hooks";

function CoreDrawer({
  isOpen, onClose, children, size = 500, direction = "right", overlay = true, className
}) {
  const isMobileView = useIsMobile();

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      direction={direction}
      duration={250}
      size={isMobileView ? "100%" : size}
      enableOverlay
      /* note: we use the overlay for close on background click even if overlay = false */
      overlayOpacity={overlay ? 0 : 0.3}
      className={className}
    >
      {children}
    </Drawer>
  );
}

CoreDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CoreDrawer.defaultProps = {
  isOpen: false,
  onClose: () => {},
  className: "drawer-border",
};

export default CoreDrawer;
