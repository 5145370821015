// eslint-disable-next-line import/prefer-default-export
export const toastStyles = {
  success: {
    border: "1px solid #4CAF50",
    backgroundColor: "#F6FEF9",
    color: "#027A48",
    padding: "16px",
    width: "100%",
  },
  error: {
    border: "1px solid #F44336",
    backgroundColor: "#FFFBFA",
    color: "#B42318",
    padding: "16px",
    width: "100%",
  },
  info: {
    border: "1px solid #1570ef",
    backgroundColor: "#eff8ff",
    color: "#175cd3",
    padding: "16px",
    width: "100%",
  },
};
