import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";
import { AlertModal } from "@/components/modals";
import { useModal } from "@/hooks";

export default function InfoTable({
  data,
  columns,
  className,
  title,
  onDelete: deleteItem,
  onEdit: editItem,
  isQuotationDrawer = false,
  totalCount = null
}) {
  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(() => columns, [columns]);

  const [selectedId, setSelectedId] = useState(null);
  const { isOpen, closeModal, openModal } = useModal(false);

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } =
    useTable({
      columns: tableColumns,
      data: tableData,
      onDelete: (id) => {
        openModal();
        setSelectedId(id);
      },
      onEdit: (id) => {
        editItem(id);
      },
    });

  const tableTitle = (
    <>
      <p className="headline">{title}</p>
      <hr className="full-hr" />
    </>
  );

  const handleDelete = () => {
    deleteItem(selectedId);
    setSelectedId(null);
    closeModal();
  };

  return (
    <div className={`table-wrapper scrollable ${className}`}>
      {title ? tableTitle : null}
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="table-header-row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className={`table-header-cell ${
                    column.collapse ? "collapse" : ""
                  }`}
                  {...column.getHeaderProps()}
                >
                  <div className="table-header-cell-content">
                    <span>{column.render("Header")}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr className="table-row" {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    className={`table-body-cell ${
                      cell.column.collapse ? "collapse" : ""
                    }`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
          {isQuotationDrawer ? (
            totalCount()
          ) : ""}
        </tbody>
      </table>

      <AlertModal
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={handleDelete}
      />
    </div>
  );
}

InfoTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

InfoTable.defaultProps = {
  className: "",
  title: null,
  onEdit: () => {},
  onDelete: () => {},
};
