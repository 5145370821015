import PropTypes from "prop-types";
import { capitalize } from "lodash";
import { useNavigate } from "react-router-dom";
import { Button } from "@hydra/atom/components";
import { formatCurrency } from "@/utils/helpers";
import { CustomActionDropdown } from "@/components/dynamic";

import { StatusCircle, Status } from "@/components/common";

function ActionCell() {
  const navigate = useNavigate();

  return (
    <div className="action-cell">
      <Button bordered small onClick={() => navigate("/admin/material-request/new")}>
        Request
      </Button>
    </div>
  );
}

ActionCell.propTypes = {
};

function StatusCell({ value }) {
  return (
    <Status baseClassName="status-cell" status={value.value}>
      <StatusCircle color={value.color} />
    </Status>
  );
}

StatusCell.propTypes = {
  value: PropTypes.object.isRequired,
};

function AvatarCell({ value }) {
  return (
    <div className="avatar-cell">
      <div
        className="avatar"
        style={{ backgroundColor: value.color }}
      >
        {value.initials}
      </div>
      <span>{value.value}</span>
    </div>
  );
}

AvatarCell.propTypes = {
  value: PropTypes.object.isRequired,
};

export const getTechnicianTableColumns = (isWorkOrderCompleted = false) => {
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: AvatarCell
    },
    {
      Header: "Rate",
      accessor: "hourlyRate",
    },
  ];

  if (isWorkOrderCompleted) {
    columns.push({
      Header: "Hours Worked",
      accessor: "hours",
      collapse: true
    });
  }

  return columns;
};

export const getTechnicianTableData = (data) => data.map((worker) => ({
  key: worker.key,
  name: {
    value: capitalize(worker.label),
    color: worker.color,
    initials: worker.initials
  },
  designation: worker.designation?.name,
  hourlyRate: `${formatCurrency(worker.rate)}${worker.rateText}`,
  hours: worker.hours
}));

export const getMaterialTableColumns = (isWorkOrderCompleted = false) => {
  const columns = [
    {
      Header: "Name",
      accessor: "name"
    },
    {
      Header: "Material used",
      accessor: "materialUsed",
    },
    {
      Header: "Rate",
      accessor: "rate"
    }
  ];

  if (!isWorkOrderCompleted) {
    columns.push({
      Header: "Action",
      accessor: "action",
      Cell: ActionCell,
      collapse: true
    });
  }

  return columns;
};

export const getMaterialTableData = (data) => data.map((material) => ({
  key: material.key,
  name: material.label,
  materialUsed: `${material.quantity} ${material.unit}`,
  rate: `${formatCurrency(material.rate)}${material.rateText}`,
  action: ""
}));

export const getEquipmentTableColumns = (isWorkOrderCompleted = false) => {
  const columns = [
    {
      Header: "Name",
      accessor: "name"
    },
    {
      Header: "Equipment Id",
      accessor: "equipmentId",
    },
    {
      Header: "Rate",
      accessor: "hourlyRate",
    }
  ];

  if (isWorkOrderCompleted) {
    columns.push({
      Header: "Hours",
      accessor: "hours",
      collapse: true
    });
  }

  return columns;
};

export const getEquipmentTableData = (data) => data.map((equipment) => ({
  key: equipment.key,
  name: equipment.label,
  equipmentId: equipment.assetId,
  hourlyRate: `${formatCurrency(equipment.rate)}${equipment.rateText}`,
  hours: equipment.hours
}));

function ProposeTimeTableActionCell({ onDelete, row }) {
  const actions = [
    {
      title: "Delete",
      onClick: () => { onDelete(row.original.id); },
      icon: "trash-icon"
    },
  ];
  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={actions}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId="Task-Item-Action-Button"
      />
    </div>
  );
}

export const getProposeTimeColumns = () => [
  {
    Header: "Work Order",
    accessor: "workorderName",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Time",
    accessor: "time",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ProposeTimeTableActionCell,
    collapse: true
  }
];
