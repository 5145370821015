import PropTypes from "prop-types";
import { Radio } from "@hydra/atom/components";
import { isEmpty } from "lodash";
import { Pill } from "@/components/dashboard";
import {
  getDynamicObjectName
} from "@/utils/dynamic/helpers";
import { getFullName, getTestId } from "@/utils/helpers";

const iconOptions = (item) => [
  { condition: item?.phone, icon: "phone-icon" },
  { condition: item?.building?.name, icon: "building-icon" },
  { condition: item?.buildingManagedBy, icon: "building-icon" },
  { condition: true, icon: "" }, // Default option
];

function CardPill({ text, icon, variant }) {
  return (
    <div className="tech-title-container">
      <div className="sub-title">
        <Pill text={text} icon={icon} variant={variant} />
      </div>
    </div>
  );
}

function ListWithCards({ list, onChange, objectName }) {
  list.sort((a, b) => {

    const nameA = getDynamicObjectName(objectName, a);
    const nameB = getDynamicObjectName(objectName, b);

    return nameA.localeCompare(nameB);
  });

  const pillList = (item) => {
    const pillData = [];

    switch (objectName) {
      case "Unit":
        if (!isEmpty(item.building)) {
          pillData.push({
            text: item.building.name,
            variant: "success",
          });
        }
        break;

      case "ServiceRequest":
        if (!isEmpty(item.unit)) {
          pillData.push({
            text: item.unit.name,
            variant: "success",
          });
        }

        if (!isEmpty(item.tenant)) {
          pillData.push({
            text: getFullName(item.tenant),
            variant: "pink",
          });
        }

        if (!isEmpty(item.tenant)) {
          pillData.push({
            text: item.category.name,
            variant: "blue",
          });
        }
        break;

      default:
        pillData.push({
          text: item?.phone || item?.buildingManagedBy || item?.building?.name,
          variant: "primary",
          icon: iconOptions(item).find((option) => option.condition)?.icon
        },
        {
          text: item?.lead?.name,
          variant: "primary",
        },
        {
          text: item?.type,
          variant: "primary",
        },
        {
          text: item?.reservationAmountType,
          variant: "primary",
        },
        {
          text: item?.salesLocation?.name,
          variant: "primary",
        }
        );
        break;
    }

    if (item.status) {
      pillData.push({
        text: item.status,
        variant: "blue",
      });
    }

    return pillData.filter((i) => i.text !== undefined);
  };

  return list.map((item, i) => (
    <div
      key={`technician-card-${i}`}
      className="tech-card-container"
      onClick={() => onChange(i, item)}
      role="presentation"
      data-testid={getTestId(`List-Card-${i}`)}
    >
      <div className="header">
        <div className="d-flex">
          <div className="tech-title-container">
            <div className="title">
              {getFullName(item)}
            </div>
            <div className="sub-title">{item.number}</div>
            <div className="sub-title">{item.address}</div>
          </div>
        </div>
        <div className="">
          <div className="schedule-radio">
            <Radio
              itemValue
              label=""
              type="radio"
              value={item.isSelected}
              onChange={() => onChange(i, item)}
            >
              <Radio.CheckIcon>
                <span className="material-icons">circle</span>
              </Radio.CheckIcon>
            </Radio>
          </div>
        </div>
      </div>
      {" "}
      {(item.phone || item?.buildingManagedBy || item?.building?.name) && (
        <div className="d-flex">
          {pillList(item)?.map((pillItem, index) => (
            <CardPill
              key={index}
              text={pillItem?.text}
              icon={pillItem?.icon}
              variant={pillItem.variant}
            />
          ))}
        </div>
      )}
    </div>
  ));
}

ListWithCards.propTypes = {
  list: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  objectName: PropTypes.func.isRequired
};

export default ListWithCards;
