import { useMemo, useState } from "react";
import {
  Loader, Input, ReactSelect
} from "@hydra/atom/components";
import PropTypes from "prop-types";
import { useParams, useSearchParams } from "react-router-dom";
import { useQueries, useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import {
  ReportTable, ReportCard, TabWithBadge
} from "@/components/property";
import { DynamicObjectActivityDrawer } from "@/components/dynamic";
import {
  BoxedContent,
  Header,
  SvgIcon,
  Accordion,
  TwoColumnList,
  IconButton,
  NoDataFound,
  Gallery,
} from "@/components/common";
import TenantImg from "@/assets/images/icons/avatar.svg";
import { PieChart, PieChartLegendItem } from "@/components/charts";
import { useIsMobile } from "@/hooks";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecordById, getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { getAttachmentUrl, getFullName } from "@/utils/helpers";
import DynamicObjectOverview from "@/pages/dynamic/list-views/DynamicObjectOverview";
import DynamicObjectDetails from "@/pages/dynamic/detail-views/DynamicObjectDetails";

function formatTenantUnitData(data) {
  const namesArray = data?.landlord?.map((item) => item.name);
  const landlords = namesArray?.join(", ");
  return data?.map((key, index) => ({
    index: key.id,
    key: key.id,
    "sr#": index + 1,
    name: key.name,
    unitId: key.number,
    status: key.status,
    landlord: landlords,
    rent: `${key.totalRentAmount} AED`,
    unitProperty: key.unitProperty,
  }));
}

const renderList = (items, className = "col-md-3") => (
  <div className="card">
    <div className="list-container">
      {items &&
        items.map((item, index) => (
          <div key={`item-${index}`} className={className} style={{ marginRight: "15px" }}>
            {item}
          </div>
        ))}
    </div>
  </div>
);

function DetailItem({ heading, item, children }) {
  return (
    <div className="row">
      <div className="col-12">
        <h6 className="headline">{heading}</h6>
        {item}
        {children}
      </div>
    </div>
  );
}

DetailItem.propTypes = {
  heading: PropTypes.string.isRequired,
};

const perPageOptions = [
  { label: "12", value: 12 },
  { label: "24", value: 24 },
  { label: "36", value: 36 },
];

function TenantDetails() {
  const [currentPage, setCurrentPage] = useState(1);
  const [, setSearchParams] = useSearchParams();
  const [filterText, setFilterText] = useState("");
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [openFiltersDrawer, setOpenActivityDrawer] = useState(false);
  const [activeTab, setStatusTab] = useState("Overview");
  const isMobileView = useIsMobile(1400);
  const { tenantId } = useParams();

  const pieColor = ["#F04438", "#12B76A"];
  const pieProps = {
    width: 300,
    height: 250,
    margin: {
      top: 7,
      right: 0,
      bottom: 7,
      left: 0,
    },
    innerRadius: 0.5,
    padAngle: 0,
    cornerRadius: 0,
    activeOuterRadiusOffset: 4,
    colors: pieColor,
    colorBy: "index",
    borderWidth: 1,
    borderColor: {
      from: "color",
      modifiers: [["darker", 0.2]],
    },
    enableArcLabels: false,
    enableArcLinkLabels: false,
    legends: false,
    animate: true,
    motionConfig: "slow",
  };

  const { data: tenantData, isLoading } = useQuery(
    [kebabCase(dynamicObjectMap.get("TenantObjectName")), tenantId],
    () => getDynamicObjectRecordById(kebabCase(dynamicObjectMap.get("TenantObjectName")), tenantId),
    {
      enabled: Boolean(tenantId),
    }
  );

  const { data: moveInRequestData } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("MoveInRequestObjectName")),
      tenantId,
    ],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("MoveInRequestObjectName"), {
        tenant: tenantId,
      }),
    {
      enabled: Boolean(tenantId),
    }
  );

  const moveInChecklistIds = useMemo(
    () =>
      moveInRequestData?.data?.map((item) => ({
        queryKey: [kebabCase(dynamicObjectMap.get("MoveInCheckListObjectName")), item.id],
        queryFn: () =>
          getDynamicObjectRecords(kebabCase(dynamicObjectMap.get("MoveInCheckListObjectName")), {
            moveInRequest: item.id,
            queryMode: "Deep"
          }),
        enabled: Boolean(moveInRequestData.data),
      })),
    [moveInRequestData?.data]
  );

  const tabs = [
    { value: "Overview", label: "Overview" },
    { value: "Units", label: "Units", badgeContent: `${tenantData?.unit?.length}` },
    { value: "Assigned Keys", label: "Assigned Keys" },
    { value: "Documents", label: "Documents" },
    { label: "Service Requests" },
  ];

  const moveInChecklistData = useQueries({ queries: moveInChecklistIds || [] });

  const assignedKeys = useMemo(
    () =>
      moveInChecklistData.flatMap((item) =>
        item?.data?.data.map((i) => ({
          moveInRequestNumber: i.moveInRequest.number,
          checkList: i.checkList
        }))
      ),
    [moveInChecklistData]
  );

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
    setSearchParams(`?page=${pageNumber}&pageSize=${pageSize.label}`);
  };

  const handleStatusSelectChange = (item) => {
    setStatusTab(item.label);
  };

  const handleStatusTabChange = (index) => {
    setStatusTab(tabs[index].label);
  };

  const headerLeftContent = (content) => (
    <div className="left-content">
      <TabWithBadge className={content.className} tabs={content.tabs} onClick={() => {}} />
    </div>
  );

  const rightContent = () => (
    <div className="container-right-content">
      <IconButton className="icon-button-with-text" onClick={() => {}}>
        View Finances
      </IconButton>
    </div>
  );

  const leftContent = () => (
    <div className="container-left-content">
      <div className="title">
        {/* {activeTab === "Time Reports" ? "Resolution Time" : "Work Orders Breakdown"} */}
        Rent payment chart
      </div>
      <div className="subtitle">Manage your team members and their</div>
    </div>
  );

  const getFirstTabData = () => {
    switch (activeTab) {
      default:
        return [
          {
            titleValue: `${tenantData?.unit?.length || 0}`,
            titleColor: "primary",
            subtitle: "Total Units",
            subtitleColor: "gray",
          },
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Service Requests",
            subtitleColor: "gray",
          },
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Total Payment (AED)",
            subtitleColor: "gray",
          },
        ];
    }
  };

  const getSecondTabData = () => {
    switch (activeTab) {
      case "Time Reports":
        return [
          {
            titleValue: "02",
            titleColor: "primary",
            subtitle: "Escalated Tasks",
            subtitleColor: "gray",
          },
          {
            titleValue: "129",
            titleColor: "primary",
            subtitle: "Total Hours worked",
            subtitleColor: "gray",
          },
          {
            titleValue: "16",
            titleColor: "primary",
            subtitle: "Average Task Time (hours)",
            subtitleColor: "gray",
          },
        ];
      default:
        return [
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Overall Rating",
            subtitleColor: "gray",
            positive: true,
          },
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Positive Rating",
            subtitleColor: "gray",
            positive: true,
          },
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Negative Rating",
            subtitleColor: "gray",
            positive: false,
          },
        ];
    }
  };

  const headerRightContent = () => (
    <div className="right-content">
      <Input
        className="input-height-fix"
        value={filterText}
        onChange={setFilterText}
        placeholder="Search by name"
      />
    </div>
  );

  const renderView = () => {
    switch (activeTab) {
      case "Overview":
        return (
          <div className="accordion-container">
            <Accordion title="Building Details">
              <DynamicObjectDetails objectName="Tenant" id={tenantId} showHeader={false} />
            </Accordion>
          </div>
        );
      case "Units":
        if (tenantData.unit.length > 0) {
          return (
            <ReportTable
              data={formatTenantUnitData(tenantData?.unit)}
              headerRight={headerRightContent()}
              headerLeft={headerLeftContent({
                className: "secondary-tabs",
                tabs: [{ label: "All Units", badgeContent: `${tenantData?.unit?.length}` }],
              })}
              objectName={dynamicObjectMap.get("UnitObjectName")}
              perPage={perPage}
              perPageOptions={perPageOptions}
              currentPage={currentPage}
              handlePagination={handlePagination}
              totalCount={1}
              isLoading={isLoading}
              activeStatusTab={activeTab}
            />
          );
        }
        return;
      case "Assigned Keys":
        return assignedKeys?.length > 0 ? (
          <div className="accordion-container">
            {assignedKeys?.length > 0 &&
              assignedKeys.map((item) => (
                <Accordion title={`${item.moveInRequestNumber}`}>
                  <DetailItem>
                    {renderList(
                      [
                        <div className="d-flex align-items-center justify-content-between">
                          {item?.checkList.map((key) => (
                            <TwoColumnList
                              className="gap-20"
                              data={[
                                {
                                  label: key.item,
                                  value: key.quantity,
                                },
                              ]}
                            />
                          ))}
                          ,
                        </div>,
                      ],
                      "col-md-12"
                    )}
                  </DetailItem>
                </Accordion>
              ))}
          </div>
        ) : (
          <NoDataFound title="No Keys Available" objectName="UnitKey" />
        );
      case "Documents":
        return (
          <>
            {tenantData?.document?.map((document) => {
              const pictureURLs = [];
              if (document.frontPicture && document.frontPicture.length > 0) {
                pictureURLs.push(...document.frontPicture);
              }
              if (document.backPicture && document.backPicture.length > 0) {
                pictureURLs.push(...document.backPicture);
              }
              if (pictureURLs && pictureURLs.length > 0) {
                return (
                  <Accordion key={document.id} title={document.docType} open={false}>
                    <DetailItem
                      heading={document.docType}
                      item={(
                        <div className="images-container">
                          <Gallery
                            videos={[]}
                            images={pictureURLs}
                          />
                        </div>
                      )}
                    />
                  </Accordion>
                );
              }
              return null;
            })}
            {!(tenantData?.document && tenantData?.document?.length > 0) && <NoDataFound title="No Documents Found" />}
          </>
        );
      case "Service Requests":
        return (
          <DynamicObjectOverview
            objectName={dynamicObjectMap.get("ServiceRequestObjectName")}
            showActionCell={false}
            showBreadcrumb={false}
            showActionButton={false}
            filters={{
              tenant: tenantId,
            }}
          />
        );

      default:
        return <div>404 Page Not Found !</div>;
    }
  };

  const renderMobileView = () => (
    <>
      <Input
        className="search-input input-height-fix mb-3"
        value={filterText}
        onChange={setFilterText}
        placeholder="Search by name"
      >
        <Input.PrependIcon>
          <SvgIcon icon="search" />
        </Input.PrependIcon>
      </Input>
      <div className="tenant-report-container">
        <div className="tenant-report-img-container d-flex card-gap">
          <div className="card-img">
            <div className="tenant-profile-container">
              {tenantData?.profilePicture?.length > 0 ? (
                <img
                  loading="lazy"
                  src={getAttachmentUrl(tenantData.profilePicture[0])}
                  alt="profile-pic"
                  className="prof-img"
                />
              ) : (
                <img loading="lazy" src={TenantImg} alt="profile-pic" className="prof-img" />
              )}
            </div>
          </div>
          <div className="cards-container">
            <div className="card-container">
              <ReportCard data={getFirstTabData()} />
            </div>
            <div className="card-container">
              <ReportCard data={getSecondTabData()} />
            </div>
          </div>
        </div>
      </div>

      <div className="header-chart card mt-3 mb-3">
        <Header leftContent={leftContent()} rightContent={rightContent()} />
        <div className="pie-chart-container">
          <PieChart
            data={[
              {
                id: "Late Payments",
                label: "Late Payments",
                value: 0,
                color: pieColor[1],
              },
              {
                id: "On Time",
                label: "On Time",
                value: 0,
                color: pieColor[0],
              },
            ]}
            config={pieProps}
          />
          <div className="pie-chart-legend-container">
            <PieChartLegendItem title="Late Payments" subtitle="0" color={pieColor[0]} />
            <PieChartLegendItem title="On Time" subtitle="0" color={pieColor[1]} />
          </div>
        </div>
      </div>
      <div>
        <ReactSelect
          id="status"
          onChange={(value) => handleStatusSelectChange(value)}
          value={{ label: activeTab, value: activeTab }}
          options={tabs}
        />
      </div>
    </>
  );

  return (
    <BoxedContent className="dashboard">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!isMobileView ? (
            <>
              <Header
                className="report-dashboard-header"
                showBreadcrumb
                leftContent={(
                  <div>
                    <h1>{`${getFullName(tenantData)} (${tenantData.number})`}</h1>
                  </div>
                )}
              />
              <div className="report-container">
                <div className="report-img-container d-flex card-gap">
                  <div className="card-img justify-content-start tenant-img">
                    {tenantData?.profilePicture?.length > 0 ? (
                      <img
                        loading="lazy"
                        src={getAttachmentUrl(tenantData.profilePicture[0])}
                        alt="profile-pic"
                        className="prof-img"
                      />
                    ) : (
                      <img loading="lazy" src={TenantImg} alt="profile-pic" className="prof-img" />
                    )}
                  </div>
                  <div className="header-chart card">
                    <Header leftContent={leftContent()} rightContent={rightContent()} />
                    <div className="pie-chart-container">
                      <PieChart
                        data={[
                          {
                            id: "Late Payments",
                            label: "Late Payments",
                            value: 0,
                            color: pieColor[1],
                          },
                          {
                            id: "On Time",
                            label: "On Time",
                            value: 0,
                            color: pieColor[0],
                          },
                        ]}
                        config={pieProps}
                      />
                      <div className="pie-chart-legend-container">
                        <PieChartLegendItem title="Late Payments" subtitle="0" color={pieColor[0]} />
                        <PieChartLegendItem title="On Time" subtitle="0" color={pieColor[1]} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cards-container">
                  <div className="card-container">
                    <ReportCard data={getFirstTabData()} />
                  </div>
                </div>
              </div>
              <TabWithBadge
                tabs={tabs}
                className="primary-tabs custom-primary-tabs"
                onClick={(value) => handleStatusTabChange(value)}
              />
            </>
          ) : (
            renderMobileView()
          )}
          <div className="tenant-table-container">{renderView()}</div>
        </>
      )}
      <DynamicObjectActivityDrawer isOpen={openFiltersDrawer} onClose={setOpenActivityDrawer} />
    </BoxedContent>
  );
}

TenantDetails.propTypes = {};

export default TenantDetails;
