import { addDays } from "date-fns";

import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import DynamicObjectOverview from "@/pages/dynamic/list-views/DynamicObjectOverview";
import { formatApiPayloadDate } from "@/utils/helpers";

const tabs = [
  {
    label: "All",
    value: undefined,
    fieldName: "status"
  },
  {
    label: "Active Contracts",
    value: "Active",
    fieldName: "status"
  },
  {
    label: "Pending Notice",
    value: "PendingNotice",
    multiple: true,
    filters: [
      {
        fieldName: "agreementEndDate[gte]",
        value: formatApiPayloadDate(new Date(), "yyyy-MM-dd")
      },
      {
        fieldName: "agreementEndDate[lte]",
        value: formatApiPayloadDate(addDays(new Date(), 100), "yyyy-MM-dd")
      },
      {
        fieldName: "status",
        value: "Open"
      }
    ]
  },
  {
    label: "Pending Renewal",
    value: "RenewalPending",
    fieldName: "status"
  },
  {
    label: "Terminated Contracts",
    value: "Expired",
    fieldName: "status"
  }
];

function BlanketAgreementOverview() {

  return (
    <DynamicObjectOverview
      objectName={dynamicObjectMap.get("BlanketAgreementObjectName")}
      tabs={tabs}
      showFilters
    />
  );
}

export default BlanketAgreementOverview;
