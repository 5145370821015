import PropTypes from "prop-types";
import PdfFileIcon from "@/assets/images/file-pdf-icon.png";
import { getAttachmentUrl, getTestId } from "@/utils/helpers";

function DeleteAttachmentButton({ file, onDelete, index, testId }) {
  return (
    <button
      type="button"
      className="btn-remove"
      onClick={() => onDelete(file)}
      data-testid={getTestId(`${testId}-Delete-Button-${index}`)}
    >
      <span className="material-icons-outlined">delete</span>
    </button>
  );
}

DeleteAttachmentButton.propTypes = {
  file: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  testId: PropTypes.string.isRequired,
};

function FileViewer({ attachments, onFileRemoved, isFileRemovable, isEditing, testId }) {
  const renderFile = (file, index) => {
    if (file.isImage) {
      return (
        <div className="attachment-container">
          <a target="_blank" href={isEditing ? getAttachmentUrl(file.url) : file.url}>
            <img
              loading="lazy"
              className="attachment-image"
              src={isEditing ? getAttachmentUrl(file.url) : file.url}
              alt={file.url}
              data-testid={getTestId(`${testId}-Attachment-${index}`)}
            />
          </a>
          {isFileRemovable && <DeleteAttachmentButton file={file} onDelete={onFileRemoved} />}
        </div>
      );
    }

    return (
      <div className="attachment-container">
        <a target="_blank" href={isEditing ? getAttachmentUrl(file.url) : file.url}>
          <img
            loading="lazy"
            className="attachment-icon"
            src={PdfFileIcon}
            alt={file.url}
            data-testid={getTestId(`${testId}-Attachment-${index}`)}
          />
        </a>
        {isFileRemovable && (
          <DeleteAttachmentButton
            index={index}
            file={file}
            onDelete={onFileRemoved}
            testId={getTestId(testId)}
          />
        )}
      </div>
    );
  };

  const renderFileItem = (file, index) => <li key={index}>{renderFile(file, index)}</li>;

  const renderFilesList = (files) => {
    if (files.length === 0) {
      return null;
    }

    return (
      <ul className="attachment-viewer">
        {files.map((file, index) => renderFileItem(file, index))}
      </ul>
    );
  };

  if (attachments.length === 0) {
    return null;
  }

  return <>{renderFilesList(attachments)}</>;
}

FileViewer.propTypes = {
  attachments: PropTypes.array.isRequired,
  onFileRemoved: PropTypes.func,
  isFileRemovable: PropTypes.bool,
  isEditing: PropTypes.bool,
  testId: PropTypes.string,
};

FileViewer.defaultProps = {
  onFileRemoved: () => {},
  isFileRemovable: true,
  isEditing: false,
  testId: "",
};

export default FileViewer;
