import { estimatedColumnWidth } from "../helpers";
import { formatDate } from "@/utils/helpers";

export default function getUnitReadyReportColumns() {

  const colArr = [
    {
      label: "Work Order No.",
      value: true,
      columnId: "workOrderNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Building Name",
      value: true,
      columnId: "buildingName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Description", value: true, columnId: "description", width: estimatedColumnWidth("number"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Start Date",
      value: true,
      columnId: "startDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))
    },
    {
      label: "Planned End Date", value: true, columnId: "plannedEndDate", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => (String(value || ""))
    },
    {
      label: "Unit Type", value: true, columnId: "unitType", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => (String(value || ""))
    },
    {
      label: "Planned Move-In", value: true, columnId: "plannedMoveIn", width: estimatedColumnWidth("date"), resizable: true, formatter: (value) => String(value !== "0001-01-01" ? formatDate(new Date(value)) : "")
    },
    {
      label: "Maint Class", value: true, columnId: "maintClass", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Handled By", value: true, columnId: "handledBy", width: estimatedColumnWidth("name"), resizable: true, formatter: (value) => String(value || "")
    }
  ];

  return colArr;
}
