import qs from "qs";
import request from "@/utils/api/helpers";

const baseUrl = "/finance/transfers";

export const getTransfers = (params = {}) => {
  const queryParams = qs.stringify(params, {
    addQueryPrefix: true
  });

  return request({
    url: `${baseUrl}${queryParams}`
  });
};

export const getTransferById = (id) => request({
  url: `${baseUrl}/${id}`
});

export const createTransfer = (data) => request({
  url: baseUrl,
  method: "POST",
  data
});

export const simulateTransfer = (data) => request({
  url: `${baseUrl}?simulate=true`,
  method: "POST",
  data
});

export const updateTransfer = (id, data) => request({
  url: `${baseUrl}/${id}`,
  method: "PATCH",
  data
});

export const deleteTransfer = (id) => request({
  url: `${baseUrl}/${id}`,
  method: "DELETE",
});
