import { createSlice } from "@reduxjs/toolkit";
import { pascalize } from "humps";

const initialState = {
};

export const autoNumberSlice = createSlice({
  name: "autoNumber",
  initialState,
  reducers: {
    setAutoNumber: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const { setAutoNumber } =
  autoNumberSlice.actions;

export const selectAutoNumber = (objectName) => (state) => {
  const pascalCaseObjectName = pascalize(objectName);

  return state[autoNumberSlice.name][pascalCaseObjectName] ?? "";
};
