import PropTypes from "prop-types";
import { SvgIcon } from "@/components/common";

function FormLeftHeader({ title, subtitle, icon }) {
  return (
    <header className="form-left-header">
      {icon && <SvgIcon icon={icon} />}
      <div className="title-container">
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </div>
    </header>
  );
}

FormLeftHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

FormLeftHeader.defaultProps = {
  subtitle: null
};

export default FormLeftHeader;
