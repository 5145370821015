import { useState, useMemo } from "react";
import { Loader } from "@hydra/atom/components";
import PropTypes from "prop-types";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ReportTable, ReportCard, TabWithBadge } from "@/components/property";
import { DynamicObjectActivityDrawer } from "@/components/dynamic";
import { getDashboardData } from "@/api/dynamic/dynamicDashboardApi";
import { barChartData, barChartData5 } from "@/components/charts/chartData";
import {
  BoxedContent, Header, Accordion, TwoColumnList,
} from "@/components/common";
import ProfilePictureImg from "@/assets/images/user-profile.png";
import { PieChart, BarChart, PieChartLegendItem } from "@/components/charts";
import {
  formatCurrency, formatDate, getAttachmentUrl, formatDecimalValues
} from "@/utils/helpers";

const renderList = (items) => (
  <div className="card">
    <div className="list-container">
      {items &&
        items.map((item, index) => (
          <div key={`item-${index}`} className="col-md-3">
            {item}
          </div>
        ))}
    </div>
  </div>
);

function DetailItem({ heading, children }) {
  return (
    <div className="row">
      <div className="col-12">
        <h6 className="headline">{heading}</h6>
        {children}
      </div>
    </div>
  );
}

DetailItem.propTypes = {
  heading: PropTypes.string.isRequired,
};

const perPageOptions = [
  { label: "12", value: 12 },
  { label: "24", value: 24 },
  { label: "36", value: 36 },
];

const formatWorkOrder = (workOrder, i) => ({
  index: i + 1,
  key: workOrder?.id,
  workOrderId: workOrder?.workOrder?.workOrderId,
  jobId: workOrder?.id,
  title: workOrder?.title,
  tenant: {
    label: workOrder?.tenant?.name,
    value: workOrder?.tenant?.id,
  },
  supervisor: {
    label: workOrder?.supervisor?.name,
    value: workOrder?.supervisor?.id,
  },
  unit: {
    label: `${workOrder?.unit?.name}`,
    value: workOrder?.unit?.id,
  },
  category: {
    label: `${workOrder?.category?.name}`,
    value: workOrder?.category?.id,
  },
  status: {
    value: workOrder.status,
    color: workOrder.status === "Completed" ? "success" : "error",
  },
});

const formatPettyCash = (pettyCash, i) => ({
  index: i + 1,
  key: pettyCash?.id,
  receiptNo: pettyCash?.receiptNo,
  type: {
    value: pettyCash.type,
    color: pettyCash.type === "Debit" ? "error" : "success",
  },
  paymentDate: formatDate(new Date(pettyCash?.paymentDate)),
  totalAmount: formatCurrency(pettyCash?.totalAmount),
});
// TODO: refactor when api is available.

function TechnicianDetails() {
  const [currentPage, setCurrentPage] = useState(1);
  const [, setSearchParams] = useSearchParams();
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [openFiltersDrawer, setOpenActivityDrawer] = useState(false);
  const [activeTab, setStatusTab] = useState("Overview");
  const [reportView, setReportView] = useState(false);
  const tabs = [{ label: "Overview" }, { label: "Work orders" }, { label: "Finance" }];

  const pieColor = ["#9E77ED", "#12B76A", "#EE46BC"];
  const pieProps = {
    width: 300,
    height: 250,
    margin: {
      top: 7,
      right: 0,
      bottom: 7,
      left: 0,
    },
    innerRadius: 0.5,
    padAngle: 0,
    cornerRadius: 0,
    activeOuterRadiusOffset: 4,
    colors: pieColor,
    colorBy: "index",
    borderWidth: 1,
    borderColor: {
      from: "color",
      modifiers: [["darker", 0.2]],
    },
    enableArcLabels: false,
    enableArcLinkLabels: false,
    legends: false,
    animate: true,
    motionConfig: "slow",
  };

  const { technicianId } = useParams();

  const { data, isLoading } = useQuery(["techinician-detail"], () =>
    getDashboardData({
      DashboardName: "technician-dashboard",
      Params: [
        {
          Name: "Technician",
          Label: "@Technician",
          Value: technicianId,
        },
      ],
    })
  );

  const totalExpense = useMemo(() => {
    if (!data || isLoading) {
      return [];
    }
    let totalExpenseTemp;
    data?.data.forEach((item) => {
      if (item.name === "TotalExpense") {
        totalExpenseTemp = item.data[0].total;
      }
    });
    return totalExpenseTemp;
  }, [data]);

  const totalCash = useMemo(() => {
    if (!data || isLoading) {
      return [];
    }
    let totalCashTemp;
    data?.data.forEach((item) => {
      if (item.name === "TotalCash") {
        totalCashTemp = item.data[0].total;
      }
    });
    return totalCashTemp;
  }, [data]);

  const technicianData = useMemo(() => {
    if (!data || isLoading) {
      return [];
    }
    let technicianDetail = {};
    // eslint-disable-next-line array-callback-return
    data?.data.map((item) => {
      if (item.name === "TechnicianDetail") {
        // eslint-disable-next-line prefer-destructuring
        technicianDetail = item.data[0];
      }
    });
    return technicianDetail;
  }, [data]);

  const workOrderData = useMemo(() => {
    if (!data || isLoading) {
      return [];
    }
    let workOrderDetail;
    // eslint-disable-next-line array-callback-return
    data?.data.map((item) => {
      if (item.name === "WorkOrders") {
        workOrderDetail = item.data.map((itemData, i) => formatWorkOrder(itemData, i));
      }
    });
    return workOrderDetail;
  }, [data]);

  const pettyCashData = useMemo(() => {
    if (!data || isLoading) {
      return [];
    }
    let pettyCashDataDetail;
    // eslint-disable-next-line array-callback-return
    data?.data.map((item) => {
      if (item.name === "PettyCash") {
        pettyCashDataDetail = item.data.map((itemData, i) => formatPettyCash(itemData, i));
      }
    });
    return pettyCashDataDetail;
  }, [data]);

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
    setSearchParams(`?page=${pageNumber}&pageSize=${pageSize.label}`);
  };

  const handleStatusTabChange = (index) => {
    setReportView(false);
    setStatusTab(tabs[index].label);
  };

  const leftContent = () => {
    const getHeading = () => {
      switch (activeTab) {
        case "Time Reports":
          return "Resolution Time";
        case "Finance":
          return "Cash Flow Trend";
        default:
          return "Work Orders Breakdown";
      }
    };

    const getSubHeading = () => {
      switch (activeTab) {
        case "Finance":
          return "Track the cash flow of your system.";
        default:
          return "Manage your team members and their.";
      }
    };
    return (
      <div className="container-left-content">
        <div className="title">{getHeading()}</div>
        <div className="subtitle">{getSubHeading()}</div>
      </div>
    );
  };

  const getFirstTabData = () => {
    switch (activeTab) {
      case "Time Reports":
        return [
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Total Tasks",
            subtitleColor: "gray",
          },
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Completed Task On-Time",
            subtitleColor: "gray",
          },
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Completed Task Late",
            subtitleColor: "gray",
          },
        ];
      default:
        return [
          {
            titleValue: totalCash ? formatDecimalValues(parseFloat(totalCash)) : 0,
            titleColor: "primary",
            subtitle: "Total Cash (AED)",
            subtitleColor: "gray",
          },
          {
            titleValue: totalExpense ? formatDecimalValues(parseFloat(totalExpense)) : 0,
            titleColor: "primary",
            subtitle: "Total Expense (AED)",
            subtitleColor: "gray",
          },
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Total Hours Worked",
            subtitleColor: "gray",
          },
        ];
    }
  };

  const getSecondTabData = () => {
    switch (activeTab) {
      case "Time Reports":
        return [
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Escalated Tasks",
            subtitleColor: "gray",
          },
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Total Hours worked",
            subtitleColor: "gray",
          },
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Average Task Time (hours)",
            subtitleColor: "gray",
          },
        ];
      default:
        return [
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Overall Rating",
            subtitleColor: "gray",
            positive: true,
            percentage: "0",
          },
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Positive Rating",
            subtitleColor: "gray",
            positive: true,
            percentage: "0",
          },
          {
            titleValue: "0",
            titleColor: "primary",
            subtitle: "Negative Rating",
            subtitleColor: "gray",
            positive: false,
            percentage: "0",
          },
        ];
    }
  };

  const getMiddleSectionChart = () => {
    switch (activeTab) {
      case "Time Reports":
        return (
          <div className="pie-chart-container">
            <PieChart
              data={[
                {
                  id: "Average On time",
                  label: "Average On time",
                  value: 54,
                  color: "hsl(9, 70%, 50%)",
                },
                {
                  id: "Late Arrival",
                  label: "Late Arrival",
                  value: 26,
                  color: "hsl(346, 70%, 50%)",
                },
              ]}
              config={pieProps}
            />
            <div className="pie-chart-legend-container">
              <PieChartLegendItem title="Average On time" subtitle="54" color={pieColor[0]} />
              <PieChartLegendItem title="Late Arrival" subtitle="26" color={pieColor[1]} />
            </div>
          </div>
        );
      case "Finance":
        return (
          <div className="pie-chart-container">
            <BarChart data={barChartData5} keys={["Debit", "Credit"]} legends />
          </div>
        );
      default:
        return (
          <div className="pie-chart-container">
            <PieChart
              data={[
                {
                  id: "Negative Rating",
                  label: "Negative Rating",
                  value: 54,
                  color: "hsl(9, 70%, 50%)",
                },
                {
                  id: "Late Completion",
                  label: "Late Completion",
                  value: 26,
                  color: "hsl(346, 70%, 50%)",
                },
                {
                  id: "Completed Tasks",
                  label: "Completed Tasks",
                  value: 254,
                  color: "hsl(88, 70%, 50%)",
                },
              ]}
              config={pieProps}
            />
            <div className="pie-chart-legend-container">
              <PieChartLegendItem title="Negative Rating" subtitle="0" color={pieColor[0]} />
              <PieChartLegendItem title="Late Completion" subtitle="0" color={pieColor[1]} />
              <PieChartLegendItem title="Completed Tasks" subtitle="0" color={pieColor[2]} />
            </div>
          </div>
        );
    }
  };

  const renderView = () => {
    switch (activeTab) {
      case "Overview":
        return (
          <div className="accordion-container">
            <Accordion title="Technician Details">
              <DetailItem>
                {renderList([
                  <TwoColumnList
                    data={[
                      {
                        label: "Name",
                        value: technicianData?.name,
                      },
                      {
                        label: "Phone Number",
                        value: technicianData?.phone,
                      },
                      {
                        label: "Gender",
                        value: technicianData?.gender,
                      },
                      {
                        label: "Blood Group",
                        value: technicianData?.bloodGroup,
                      },
                    ]}
                  />,
                  <TwoColumnList
                    data={[
                      {
                        label: "DOB",
                        value: formatDate(new Date(technicianData?.dOB)),
                      },
                      {
                        label: "Religion",
                        value: "",
                      },
                      {
                        label: "Marital Status",
                        value: technicianData?.maritalStatus,
                      },
                    ]}
                  />,
                  <TwoColumnList
                    data={[
                      {
                        label: "Designation",
                        value: technicianData?.designation?.name,
                      },
                      {
                        label: "Nationality",
                        value: "",
                      },
                      {
                        label: "Disability",
                        value: "",
                      },
                      {
                        label: "Under Dispute",
                        value: "",
                      },
                    ]}
                  />,
                ])}
              </DetailItem>
            </Accordion>
          </div>
        );
      case "Work orders":
        return (
          <ReportTable
            objectName="work-order-job"
            data={workOrderData}
            perPage={perPage}
            perPageOptions={perPageOptions}
            currentPage={currentPage}
            handlePagination={handlePagination}
            totalCount={1}
            isLoading={isLoading}
            activeStatusTab={activeTab}
          />
        );
      case "Finance":
        return (
          <ReportTable
            data={pettyCashData}
            perPage={perPage}
            perPageOptions={perPageOptions}
            currentPage={currentPage}
            handlePagination={handlePagination}
            totalCount={1}
            isLoading={isLoading}
            activeStatusTab={`Technician ${activeTab}`}
            objectName="PettyCashTransaction"
          />
        );
      default:
        return <div>404 Page Not Found !</div>;
    }
  };
  return (
    <BoxedContent className="dashboard">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header
            className="report-dashboard-header"
            showBreadcrumb
            leftContent={(
              <div>
                <h1>{technicianData?.name}</h1>
              </div>
            )}
          />
          {!reportView && technicianData ? (
            <div className="report-container">
              <div className="report-img-container d-flex card-gap">
                <div className="card-img">
                  <img loading="lazy" src={technicianData?.profilePicture.length > 0 ? getAttachmentUrl(technicianData?.profilePicture[0]) : ProfilePictureImg} alt="profile-pic" className="prof-img" />
                  <div className="title-card-primary">
                    <div className="d-flex justify-content-center">
                      <div>
                        <div>{technicianData?.name}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="header-chart card">
                  <Header leftContent={leftContent()} />
                  {getMiddleSectionChart()}
                </div>
              </div>
              <div className="cards-container">
                <div className="card-container">
                  <ReportCard data={getFirstTabData()} />
                </div>
                <div className="card-container">
                  <ReportCard data={getSecondTabData()} />
                </div>
              </div>
            </div>
          ) : (
            <div className="report-container">
              <div className="report-img-container">
                <div>
                  <BarChart data={barChartData} keys={["value", "value1"]} />
                </div>
              </div>
              <div className="cards-container">
                <div className="card-container">
                  <ReportCard
                    data={[
                      {
                        titleValue: "1965",
                        titleColor: "primary",
                        subtitle: "Total Cash (AED)",
                        subtitleColor: "gray",
                      },
                      {
                        titleValue: "462",
                        titleColor: "primary",
                        subtitle: "Total Expense (AED)",
                        subtitleColor: "gray",
                      },
                      {
                        titleValue: "42",
                        titleColor: "primary",
                        subtitle: "Waiting for Approval",
                        subtitleColor: "gray",
                      },
                    ]}
                  />
                </div>
                <div className="card-container">
                  <ReportCard
                    data={[
                      {
                        titleValue: "98%",
                        titleColor: "primary",
                        subtitle: "Credited (June 2023)",
                        subtitleColor: "gray",
                        positive: true,
                        percentage: "12",
                      },
                      {
                        titleValue: "129",
                        titleColor: "primary",
                        subtitle: "Debited (June 2023)",
                        subtitleColor: "gray",
                        positive: true,
                        percentage: "12",
                      },
                      {
                        titleValue: "16",
                        titleColor: "primary",
                        subtitle: "Negative Ratings",
                        subtitleColor: "gray",
                        positive: false,
                        percentage: "4",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          )}
          <TabWithBadge tabs={tabs} className="primary-tabs" onClick={handleStatusTabChange} />
          {renderView()}
        </>
      )}
      <DynamicObjectActivityDrawer isOpen={openFiltersDrawer} onClose={setOpenActivityDrawer} />
    </BoxedContent>
  );
}

TechnicianDetails.propTypes = {};

export default TechnicianDetails;
