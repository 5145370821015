import JobBoardTask from "./JobBoardTask";
import { StatusCircle, Status, SvgIcon } from "@/components/common";

function JobBoardColumn({ status, tasks }) {
  const filteredTasks = tasks?.filter(
    (task) => task.jobStatus === status.value
  );

  return (
    <div className="work-order-job-column">
      <div className="work-order-job-status">
        <Status
          baseClassName="status-cell"
          status={status.label}
          bgColor={status.color ? status.color : "#7c4dff"}
        >
          <StatusCircle color={status.color ? status.color : "#7c4dff"} />
        </Status>
        <div className="d-flex">
          <span className="board-icon">
            <SvgIcon icon="plus-icon" />
          </span>
          <span className="board-icon">
            <SvgIcon icon="list-2-stroke-icon" />
          </span>
        </div>
      </div>
      <div className="tasks-list">
        <div>
          {filteredTasks?.map((task, index) => (
            <JobBoardTask key={task.id} index={index} task={task} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default JobBoardColumn;
