import { estimatedColumnWidth } from "../helpers";
import { formatDate, formatCurrency } from "@/utils/helpers";

export default function getVATExceptionColumns() {
  const colArr = [
    {
      label: "Transaction no",
      value: true,
      columnId: "transactionNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Type",
      value: true,
      columnId: "transactionType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Date",
      value: true,
      columnId: "date",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))
    },
    {
      label: "TRN of tenant",
      value: true,
      columnId: "customerTrn",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "TRN of Supplier",
      value: true,
      columnId: "supplierTrn",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Name",
      value: true,
      columnId: "customerName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Location",
      value: true,
      columnId: "location",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Building",
      value: true,
      columnId: "building",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit",
      value: true,
      columnId: "unit",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Unit Type",
      value: true,
      columnId: "unitType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Department",
      value: true,
      columnId: "department",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Class",
      value: true,
      columnId: "class",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Memo/Description",
      value: true,
      columnId: "memo",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tax Rate",
      value: true,
      columnId: "taxrate",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(`${value}%` || "0%")
    },
    {
      label: "Taxable Amount",
      value: true,
      columnId: "taxableAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || ""))
    },
    {
      label: "Amount",
      value: true,
      columnId: "amount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || ""))
    },
    {
      label: "Create Date",
      value: true,
      columnId: "createdDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))
    },
  ];
  return colArr;
}
