/* eslint-disable no-underscore-dangle */
const baseUrl = window.__ENV__.BASE_URL;
const sentryUrl = window.__ENV__.SENTRY_URL;
const minioUrl = window.__ENV__.MINIO_URL;
const googleMapsApiKey = window.__ENV__.GOOGLE_MAPS_API_KEY;
const publicVapidKey = window.__ENV__.PUBLIC_VAPID_KEY;
const generateTestId = window.__ENV__.GENERATE_TEST_ID;
const postHogKey = window.__ENV__.POSTHOG_KEY;
const postHogHost = window.__ENV__.POSTHOG_HOST;
const enablePostHog = window.__ENV__.ENABLE_POSTHOG;

const appSettings = {
  baseUrl,
  sentryUrl,
  apiBaseUrl: baseUrl,
  minioBaseUrl: minioUrl,
  googleMapsApiKey,
  publicVapidKey,
  authCookieName: ".tuli-uiLoggedIn",
  languageCookieName: ".tuli-uiLanguage",
  generateTestId,
  postHogKey,
  postHogHost,
  enablePostHog
};

export default appSettings;
