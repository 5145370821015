/* eslint-disable no-console */
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { pascalize } from "humps";
import { selectPermissions } from "@/store/userSlice";
import { isSnakeCase } from "@/utils/helpers";

function RequirePermission({
  parent, scope, action, isFromRoutes, children
}) {
  let objectName = scope;

  if (isSnakeCase(scope)) {
    objectName = pascalize(scope);
  }
  const location = useLocation();
  const permissions = useSelector(selectPermissions);
  if (permissions && permissions[parent] && !permissions[parent][objectName]?.includes(action)) {
    const isPermitted =
      permissions[parent][objectName]?.includes("Any") || permissions[parent][objectName]?.includes(action);

    if (!isPermitted) {
      console.warn(`Action ${action} was denied`);
      return isFromRoutes ? (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
      ) : null;
    }
  }

  return isFromRoutes ? <Outlet /> : children;
}

RequirePermission.propTypes = {
  parent: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  isFromRoutes: PropTypes.bool,
  children: PropTypes.node,
  scope: PropTypes.string,
};

RequirePermission.defaultProps = {
  isFromRoutes: false,
  children: null,
  scope: null,
};

export default RequirePermission;
