import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { BoxedContent, Header, NoDataFound } from "@/components/common";
import { FinanceMasterTable } from "@/components/finance";
import { skeletonLoadingRows } from "@/utils/helpers";
import { getCompanies } from "@/api/finance/companyApi";
import { getCompanySettingTableColumns, getCompanySettingTableData } from "@/components/admin/company-settings/companySettingTableData";

function CompanySettingsListView() {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(["company"], getCompanies);

  const renderTable = () => {
    if (!isLoading && data && !data?.data) {
      return (
        <NoDataFound
          title="No company has been added"
          buttonText="Add company"
          onClick={() => navigate("new")}
        />
      );
    }
    return (
      <FinanceMasterTable
        isLoading={isLoading}
        tableColumns={getCompanySettingTableColumns()}
        data={getCompanySettingTableData(data?.data || skeletonLoadingRows(5))}
      />
    );
  };

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<h1>Company</h1>}
      />
      {renderTable()}
    </BoxedContent>
  );
}

export default CompanySettingsListView;
