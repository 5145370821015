import { Button, Checkbox, Radio } from "@hydra/atom/components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Header, FormLeftHeader } from "@/components/common";
import {
  setFormValue, setCommentsValue, setRemindersValue, setActivityValue
} from "@/reducers/admin/companySettingsReducer.js";
import { updateCompanySettingAlert } from "@/api/admin/companySettingApi";
import showToast from "@/utils/toast/helpers";

function Alerts({ state, dispatch }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const leftContent = (title, subtitle) => (
    <div className="container-header-left-content">
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );

  const prepareData = (item) => {
    let comments = 0;
    let reminders = 0;
    let moreActivites = 0;

    if ((item.allComments === true)) {
      comments = 2;
    }

    if (item.commentsNotNotify === true) {
      comments = 1;
    }

    if (item.commentsMentionsOnly === true) {
      comments = 0;
    }

    if ((item.allreminders === true)) {
      reminders = 2;
    }

    if (item.remindersNotNotify === true) {
      reminders = 1;
    }

    if (item.remindersImportantOnly === true) {
      reminders = 0;
    }

    if ((item.allActivity === true)) {
      moreActivites = 1;
    }

    if (item.activityNotNotify === true) {
      moreActivites = 0;
    }

    return {
      hasNewsAndUpdates: item.newsAndUpdates,
      hasTipsAndTutorials: item.tipsAndTutorials,
      hasUserResearch: item.userResearch,
      comments,
      reminders,
      moreActivites
    };
  };

  const updateMutation = useMutation(
    ({ id: recordId, data: recordData }) =>
      updateCompanySettingAlert(recordId, recordData),
    {
      onError: () => {
        showToast("Could not update. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Updated successfully", "success");
        queryClient.invalidateQueries({
          queryKey: ["company-settings"],
        });
        dispatch(setFormValue("completedStep", 4));
        navigate(-1);
      },
    }
  );

  const handleSubmit = () => {
    const formattedAlertData = prepareData(state);
    updateMutation.mutate({
      id: state.companyId,
      data: {
        ...formattedAlertData,
      },
    });
  };

  return (
    <section className="alert-setting-body">
      <div className="setting-header">
        <Header
          leftContent={leftContent(
            "Email notifications",
            "Get emails to find out what’s going on when you’re not online. You can turn them off anytime."
          )}
        />
        <hr className="full-hr" />
      </div>
      <div className="row">
        <div className="col-md-3">
          <FormLeftHeader
            title="Notifications from us"
            subtitle="Receive the latest news, updates and industry tutorials from us."
          />
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-8 checkbox-items-container">
              <Checkbox
                value={state.newsAndUpdates}
                onChange={() =>
                  dispatch(
                    setFormValue("newsAndUpdates", !state.newsAndUpdates)
                  )}
              />
              <FormLeftHeader
                title="News and updates"
                subtitle="News about product and feature updates."
              />
            </div>
            <div className="col-md-8 checkbox-items-container">
              <Checkbox
                value={state.tipsAndTutorials}
                onChange={() =>
                  dispatch(
                    setFormValue("tipsAndTutorials", !state.tipsAndTutorials)
                  )}
              />
              <FormLeftHeader
                title="Tips and tutorials"
                subtitle="Tips on getting more out of Untitled."
              />
            </div>
            <div className="col-md-8 checkbox-items-container">
              <Checkbox
                value={state.userResearch}
                onChange={() =>
                  dispatch(setFormValue("userResearch", !state.userResearch))}
              />
              <FormLeftHeader
                title="User research"
                subtitle="Get involved in our beta testing program or participate in paid product user research."
              />
            </div>
          </div>
        </div>
      </div>
      <hr className="full-hr" />
      <div className="row">
        <div className="col-md-3">
          <FormLeftHeader
            title="Comments"
            subtitle="These are notifications for comments on your posts and replies to your comments."
          />
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-8 radio-items-container">
              <Radio
                className="radio-text"
                itemValue
                type="radio"
                value={state.commentsNotNotify}
                onChange={() => {
                  dispatch(setCommentsValue([true, false, false]));
                }}
              >
                <Radio.CheckIcon>
                  <span className="material-icons">circle</span>
                </Radio.CheckIcon>
              </Radio>
              <FormLeftHeader title="Do not notifiy me" />
            </div>
            <div className="col-md-8 radio-items-container">
              <Radio
                className="radio-text"
                itemValue
                type="radio"
                value={state.commentsMentionsOnly}
                onChange={() => {
                  dispatch(setCommentsValue([false, true, false]));
                }}
              >
                <Radio.CheckIcon>
                  <span className="material-icons">circle</span>
                </Radio.CheckIcon>
              </Radio>
              <FormLeftHeader
                title="Mentions only"
                subtitle="Only notify me if I’m mentioned in a comment."
              />
            </div>
            <div className="col-md-8 radio-items-container">
              <Radio
                className="radio-text"
                itemValue
                type="radio"
                value={state.allComments}
                onChange={() => {
                  dispatch(setCommentsValue([false, false, true]));
                }}
              >
                <Radio.CheckIcon>
                  <span className="material-icons">circle</span>
                </Radio.CheckIcon>
              </Radio>
              <FormLeftHeader
                title="All comments"
                subtitle="Notify me for all comments on my posts."
              />
            </div>
          </div>
        </div>
      </div>
      <hr className="full-hr" />
      <div className="row">
        <div className="col-md-3">
          <FormLeftHeader
            title="Reminders"
            subtitle="These are notifications to remind you of updates you might have missed."
          />
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-8 radio-items-container">
              <Radio
                className="radio-text"
                itemValue
                type="radio"
                value={state.remindersNotNotify}
                onChange={() => {
                  dispatch(setRemindersValue([true, false, false]));
                }}
              >
                <Radio.CheckIcon>
                  <span className="material-icons">circle</span>
                </Radio.CheckIcon>
              </Radio>
              <FormLeftHeader title="Do not notifiy me" />
            </div>
            <div className="col-md-12 radio-items-container">
              <Radio
                className="radio-text"
                itemValue
                type="radio"
                value={state.remindersImportantOnly}
                onChange={() => {
                  dispatch(setRemindersValue([false, true, false]));
                }}
              >
                <Radio.CheckIcon>
                  <span className="material-icons">circle</span>
                </Radio.CheckIcon>
              </Radio>
              <FormLeftHeader
                title="Important reminders only"
                subtitle="Only notify me if the reminder is tagged as important."
              />
            </div>
            <div className="col-md-8 radio-items-container">
              <Radio
                className="radio-text"
                itemValue
                type="radio"
                value={state.allreminders}
                onChange={() => {
                  dispatch(setRemindersValue([false, false, true]));
                }}
              >
                <Radio.CheckIcon>
                  <span className="material-icons">circle</span>
                </Radio.CheckIcon>
              </Radio>
              <FormLeftHeader
                title="All reminders"
                subtitle="Notify me for all reminders."
              />
            </div>
          </div>
        </div>
      </div>
      <hr className="full-hr" />
      <div className="row">
        <div className="col-md-3">
          <FormLeftHeader
            title="More activity about you"
            subtitle="These are notifications for posts on your profile, likes and other reactions to your posts, and more."
          />
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-8 radio-items-container">
              <Radio
                className="radio-text"
                itemValue
                type="radio"
                value={state.activityNotNotify}
                onChange={() => {
                  dispatch(setActivityValue([true, false]));
                }}
              >
                <Radio.CheckIcon>
                  <span className="material-icons">circle</span>
                </Radio.CheckIcon>
              </Radio>
              <FormLeftHeader title="Do not notifiy me" />
            </div>
            <div className="col-md-8 radio-items-container">
              <Radio
                className="radio-text"
                itemValue
                type="radio"
                value={state.allActivity}
                onChange={() => {
                  dispatch(setActivityValue([false, true]));
                }}
              >
                <Radio.CheckIcon>
                  <span className="material-icons">circle</span>
                </Radio.CheckIcon>
              </Radio>
              <FormLeftHeader
                title="All reminders"
                subtitle="Notify me for all other activity."
              />
            </div>
          </div>
        </div>
      </div>
      <hr className="full-hr" />
      <footer className="setting-footer">
        <Button
          small
          loading={updateMutation.isLoading}
          className="save-btn"
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </footer>
    </section>
  );
}

export default Alerts;
