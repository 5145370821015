import request from "@/utils/api/helpers";

const baseUrl = "/dynamic-dashboard/view";

export const getDynamicDashboardView = (schemaId) => request({
  url: `${baseUrl}/objects/${schemaId}`
});

export const getDynamicDashboardObjects = () => request({
  url: `${baseUrl}/object-names`
});

export const getDynamicDashboardDataset = (data) => request({
  url: `${baseUrl}/dataset`,
  method: "POST",
  headers: {
    "Content-Type": "application/json"
  },
  data
});
