import request from "@/utils/api/helpers";
import { VENDOR_USER_TYPE, SUPPLIER_USER_TYPE } from "@/utils/userTypes";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

const baseUrl = "/auth";

export const loginUser = (data) => request({
  url: baseUrl,
  method: "POST",
  headers: {
    "Content-type": "application/json"
  },
  data
});

export const logoutUser = () => request({
  url: `${baseUrl}/logout`,
});

export const setToken = (token) => {
  window.localStorage.setItem("tuli_auth", token);
};

export const setPrecision = (precision) => {
  window.localStorage.setItem("tuli_precision", precision);
};

export const setUserType = (userType) => {
  window.localStorage.setItem("user_type", userType);
};

export const setUserID = (userID) => {
  window.localStorage.setItem("user_id", userID);
};

export const setUserLoginId = (userLoginId) => {
  window.localStorage.setItem("user_login_id", userLoginId);
};

export const getUserLoginId = () => window.localStorage.getItem("user_login_id");

export const removeUserLoginId = () => window.localStorage.removeItem("user_login_id");

export const getUserType = () => window.localStorage.getItem("user_type");

export const removeUserType = () => window.localStorage.removeItem("user_type");

export const getToken = () => window.localStorage.getItem("tuli_auth");

export const getPrecision = () => window.localStorage.getItem("tuli_precision");

export const removeToken = () => window.localStorage.removeItem("tuli_auth");

export const setCompany = (companyId) => window.localStorage.setItem("company", companyId);

export const getCompany = () => window.localStorage.getItem("company");

export const removeCompany = () => window.localStorage.removeItem("company");

export const setRoles = (roles) => window.localStorage.setItem("roles", JSON.stringify(roles));

export const getRoles = () => {
  const roles = window.localStorage.getItem("roles");
  let userRoles = [];
  try {
    userRoles = JSON.parse(roles);
  } catch (error) {
    console.log(error);
  }

  return userRoles;
};

export const removeRoles = () => window.localStorage.removeItem("roles");

export const resetLocalStorage = () => {
  removeToken();
  removeUserType();
  removeCompany();
  removeUserLoginId();
  removeRoles();
};

export const getUserData = async (userType, userId) => {
  let objectName;

  switch (userType) {
    case VENDOR_USER_TYPE:
    case SUPPLIER_USER_TYPE:
      objectName = dynamicObjectMap.get("SupplierStaffObjectName");
      break;

    default:
      objectName = dynamicObjectMap.get("EmployeeObjectName");
      break;
  }

  return getDynamicObjectRecords(objectName, {
    userId,
  });
};
