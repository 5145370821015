import { useEffect, useRef } from "react";

export default function useScrollToTop(activeStep = 1) {
  const scrollToTopRef = useRef(null);

  useEffect(() => {
    if (scrollToTopRef.current) {
      scrollToTopRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [activeStep]);

  return { scrollToTopRef };
}
