import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { CustomActionDropdown } from "@/components/dynamic";
import { formatDate } from "@/utils/helpers";
import { selectPermission } from "@/store/userSlice";

export default function ActionCell({
  onDelete, onEdit, row, onView
}) {
  const deletePermission = useSelector(selectPermission({ parent: "Model", scope: "Currency", action: "Delete" }));

  const getActions = () => {
    const actions = [
      {
        title: "View",
        onClick: () => { onView(row.original.id); },
        icon: "eye"
      },
      {
        title: "Edit",
        onClick: () => { onEdit(row.original.id); },
        icon: "edit-icon"
      }
    ];

    // if (deletePermission) {
    //   actions.push(
    //     {
    //       title: "Delete",
    //       onClick: () => { onDelete(row.original.id); },
    //       icon: "trash-icon"
    //     });
    // }
    return actions;
  };

  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={getActions()}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId="Currency-Action-Button"
      />
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

function DateCell({ value }) {
  return (
    <div className="date-cell">
      {formatDate(new Date(value))}
    </div>
  );
}

DateCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const getCurrencyTableColumns = () => [
  {
    Header: "Name",
    accessor: "name",
    defaultCanSort: true,
    filter: "text"
  },
  {
    Header: "Code",
    accessor: "code",
    defaultCanSort: true,
  },
  {
    Header: "Symbol",
    accessor: "symbol",
    defaultCanSort: true,
  },
  {
    Header: "Precision",
    accessor: "precision",
    defaultCanSort: true,
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    Cell: DateCell,
    defaultCanSort: true,
    collapse: true
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionCell,
    collapse: true,
  },
];

export const getCurrencyTableData = (data) => data;
