import PropTypes from "prop-types";

import { getAttachmentUrl } from "@/utils/helpers";
import PdfFileIcon from "@/assets/images/file-pdf-icon.png";

export default function Gallery({ images, videos }) {
  if (!(videos?.length > 0) && !images?.length > 0) {
    return null;
  }

  return (
    <>
      {videos?.map((src, i) => (
        <video width="150" height="112" controls key={`work-order-video-${i}`}>
          <source src={getAttachmentUrl(src)} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ))}
      {images?.map((src, i) => (
        <a href={getAttachmentUrl(src)} target="_blank" key={`work-order-image-${i}`}>
          <img
            loading="lazy"
            src={src.toLowerCase().endsWith(".pdf") ? PdfFileIcon : getAttachmentUrl(src)}
            key={i}
            alt=""
          />
        </a>
      ))}
    </>
  );
}

Gallery.propTypes = {
  images: PropTypes.array,
  videos: PropTypes.array,
};

Gallery.defaultProps = {
  images: [],
  videos: [],
};
