import { estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getInputVATColumns() {

  const colArr = [
    {
      label: "Company Name",
      value: true,
      columnId: "companyName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "VAT Code",
      value: true,
      columnId: "vATCode",
      width: estimatedColumnWidth("code"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "VAT Description",
      value: true,
      columnId: "vATDescription",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Expense Value",
      value: true,
      columnId: "expenseValue",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "VAT Value",
      value: true,
      columnId: "vATValue",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "VAT Value (Commercial)",
      value: true,
      columnId: "vATValueCommercial",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "VAT Value (Residential)",
      value: true,
      columnId: "vATValueResidental",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "VAT Value (Admin)",
      value: true,
      columnId: "vATValueAdmin",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Record Type",
      value: true,
      columnId: "recordType",
      width: estimatedColumnWidth("type"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Component Location",
      value: true,
      columnId: "componentLocation",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Component Number",
      value: true,
      columnId: "componentNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Component Name",
      value: true,
      columnId: "componentName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Reference Number",
      value: true,
      columnId: "referenceNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Invoice Date",
      value: true,
      columnId: "invoiceDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Supplier Name",
      value: true,
      columnId: "supplierName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "TRN",
      value: true,
      columnId: "trn",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Description",
      value: true,
      columnId: "description",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Remarks",
      value: true,
      columnId: "remarks",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Invoice Number",
      value: true,
      columnId: "invoiceNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Received Date (GL)",
      value: true,
      columnId: "receivedDateGL",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Cancelled Date (GL)",
      value: true,
      columnId: "cancelledDateGL",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => String(value || "")
    }
  ];

  return colArr;
}
