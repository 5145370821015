import { useMemo, forwardRef } from "react";
import { Form, TextArea } from "@hydra/atom/components";
import { FormLeftHeader } from "@/components/common";

const TaskRemarksForm = forwardRef(({ remarks, setRemarks, onSubmit, required, disabled }, ref) => {
  const additionalProps = useMemo(() => {
    const props = {
      rules: "max:500",
      messages: {
        max: "Remarks must be less than 500 characters",
      },
    };

    if (required) {
      props.rules = `required|${props.rules}`;
      props.messages.required = "Remarks are required if a task is rejected";
    }

    return props;
  }, [required]);

  return (
    <Form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-3">
          <FormLeftHeader title={`Remarks${required ? "*" : ""}`} subtitle="Your remarks" />
        </div>
        <div className="col-md-9">
          <TextArea
            id="remarks"
            name="remarks"
            value={remarks}
            onChange={setRemarks}
            disabled={disabled}
            {...additionalProps}
          />
        </div>
      </div>
      <button ref={ref} disabled={disabled} type="submit" style={{ display: "none" }}>
        Submit
      </button>
    </Form>
  );
});

export default TaskRemarksForm;
