import { camelize } from "humps";
import {
  companyAccountTypes, currencies, dateFormat, areaUnitType
} from "./constants";
import { formatApiPayloadDate } from "@/utils/helpers";

export const getAccountsData = (state) => {
  const accountsData = [];
  companyAccountTypes.forEach((accountType) => {
    const key = camelize(accountType);

    if (state[key]) {
      const accountData = {
        companyId: state.companyId,
        accountType,
        accountId: state[key]?.value
      };
      if (state.accountsKeys[key]) {
        accountData.id = state.accountsKeys[key];
      }

      accountsData.push(accountData);
    }
  });

  return accountsData;
};

export const prepareData = (item) => {
  const customerActivityAlertOptions = [];

  if (item.creditLimit === true) {
    customerActivityAlertOptions.push("CreditLimit");
  }

  if (item.commitmentLimit === true) {
    customerActivityAlertOptions.push("CommitmentLimit");
  }

  if (item.reservation === true) {
    customerActivityAlertOptions.push("Reservation");
  }

  if (item.arInvoice === true) {
    customerActivityAlertOptions.push("ArInvoice");
  }
  return {
    taxRegistrationNumber: item.taxRegistrationNumber,
    systemCurrencyCode: item.systemCurrency.value,
    localCurrencyCode: item?.localCurrency?.value,
    standardChartOfAccounts: item.chartAccountSystem,
    fiscalCalendarYearStart: item.startFiscalCalendarYear ? formatApiPayloadDate(new Date(item.startFiscalCalendarYear)) : null,
    fiscalCalendarYearEnd: item.endFiscalCalendarYear ? formatApiPayloadDate(new Date(item.endFiscalCalendarYear)) : null,
    customerActivityAlertOptions,
  };
};

export const prepareGeneralData = (item) => ({
  name: item?.companyName,
  website: item?.companyWebsite,
  address: item?.companyAddress,
  phoneNumber01: item?.phoneNumbers[0],
  phoneNumber02: item?.phoneNumbers[1],
  email: item?.email,
  multiLanguageSupport: item?.multiLanguageSupport,
  logo: "",
  postalAddress: item.companyPostalAddress,
  areaMeasurementUnitType: item.areaMeasurementUnitType.value,
  dateFormat: item.dateFormat.value
});

export const formatCompanySetting = (item) => {
  const comments = {
    allComments: false,
    commentsNotNotify: false,
    commentsMentionsOnly: false
  };
  const reminders = {
    allreminders: false,
    remindersNotNotify: false,
    remindersImportantOnly: false
  };
  const moreActivites = {
    allActivity: false,
    activityNotNotify: false
  };

  if ((item.comments === 2)) {
    comments.allComments = true;
  }

  if (item.comments === 1) {
    comments.commentsNotNotify = true;
  }

  if (item.comments === 0) {
    comments.commentsMentionsOnly = true;
  }

  if ((item.reminders === 2)) {
    // reminders = 2;
    reminders.allreminders = true;
  }

  if (item.reminders === 1) {
    reminders.remindersNotNotify = true;
  }

  if (item.reminders === 0) {
    reminders.remindersImportantOnly = true;
  }

  if ((item.moreActivites === 1)) {
    moreActivites.allActivity = true;
  }

  if (item.moreActivites === 0) {
    moreActivites.activityNotNotify = true;

  }
  return {
    companyName: item?.name,
    companyWebsite: item?.website,
    companyAddress: item?.address,
    phoneNumbers: [item?.phoneNumber01, item?.phoneNumber02],
    email: item?.email,
    multiLanguageSupport: item?.multiLanguageSupport,
    companyPostalAddress: item?.postalAddress,
    taxRegistrationNumber: item.taxRegistrationNumber,
    systemCurrency: currencies.find((currency) => currency.value === item.systemCurrencyCode),
    localCurrency: currencies.find((currency) => currency?.value === item?.localCurrencyCode),
    chartAccountSystem: item.standardChartOfAccounts,
    startFiscalCalendarYear: item.fiscalCalendarYearStart || null,
    endFiscalCalendarYear: item.fiscalCalendarYearEnd || null,
    creditLimit: item.customerActivityAlertOptions.includes("CreditLimit"),
    commitmentLimit: item.customerActivityAlertOptions.includes("CommitmentLimit"),
    reservation: item.customerActivityAlertOptions.includes("Reservation"),
    arInvoice: item.customerActivityAlertOptions.includes("ArInvoice"),
    newsAndUpdates: item.hasNewsAndUpdates,
    tipsAndTutorials: item.hasTipsAndTutorials,
    userResearch: item.hasUserResearch,
    areaMeasurementUnitType: areaUnitType.find((type) =>
      type.value === item.areaMeasurementUnitType),
    dateFormat: dateFormat.find((type) => type.value === item.dateFormat),
    ...comments,
    ...reminders,
    ...moreActivites
  };
};
