import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import * as Progress from "@radix-ui/react-progress";

import { CardLayout } from "@/components/dashboard";
import { TASK_STATUSES } from "@/components/workflow/constants";

export default function StatusWithProgressbar({ title, progressCount, isPassed }) {
  const [progress, setProgress] = useState(13);
  useEffect(() => {
    if (progressCount === TASK_STATUSES.COMPLETED) {
      setProgress(100);
    } else if (progressCount === TASK_STATUSES.INPROGRESS) {
      setProgress(13);
    } else {
      setProgress(0);
    }
  }, [progressCount]);

  const progressbarInfo = () => {
    // TODO: Change progress count status after receiving the progress count from backend
    switch (true) {
      case progressCount === 100 || progressCount === TASK_STATUSES.COMPLETED:
        return "Completed";
      case progressCount > 0 || progressCount === TASK_STATUSES.INPROGRESS:
        return "In progress";
      case progressCount === TASK_STATUSES.SKIPPED:
        return "Skipped";
      case progressCount === TASK_STATUSES.STOPPED:
        return "Stopped";
      case !progressCount && isPassed:
        return "Skipped";
      default:
        return "Not Started";
    }
  };

  return (
    <CardLayout
      title={title}
      className={`status-with-progressbar ${kebabCase(progressbarInfo())}`}
    >
      <Progress.Root className="progress-root" value={progress}>
        <Progress.Indicator
          className="progress-indicator"
          style={{ transform: `translateX(-${100 - progress}%)` }}
        />
      </Progress.Root>
      <p className="progress-text">{progressbarInfo()}</p>
    </CardLayout>
  );
}

StatusWithProgressbar.propTypes = {
  title: PropTypes.string.isRequired,
  progressCount: PropTypes.string.isRequired,
  isPassed: PropTypes.bool,
};

StatusWithProgressbar.defaultProps = {
  isPassed: false
};
