import {
  useState, useRef, useMemo, useEffect
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input, Radio, Tab
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import { camelize, pascalize } from "humps";
import {
  SvgIcon, FormLeftHeader, IconButton
} from "@/components/common";
import { getOpexRows, getOpexColumns } from "./opex-statement";
import { getOpex } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";
import appSettings from "@/settings";

const tabs = [
  { label: "Year To Date", value: "year" },
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" }
];

function ReportOpex({ activeCompany }) {
  const ref = useRef();

  const [filterText, setFilterText] = useState("");
  const [columnsData, setColumnsData] = useState(
    []
  );
  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);
  const [formattedRowsData, setFormattedRowsData] = useState([]);
  const [totalRows, setTotalRowsData] = useState(null);
  const [activeTab, setStatusTab] = useState("Total");
  const [tabValue, setTabValue] = useState({ label: "Total", value: "year" });
  const [isTabLoading, setIsTabLoading] = useState(false);
  const [amcFilter, setAmcFilter] = useState("Both");
  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };
  const exportExcel = () => window.open(`${appSettings.baseUrl}/finance/reports/opex/csv?CompanyId=${activeCompany.name}&YearToDate=true&MonthWise=${tabValue?.value !== "year"}&AmcFilter=${amcFilter}`, "_blank", "noopener,noreferrer");

  const handleStatusTabChange = (index) => {
    setStatusTab(tabs[index].label);
    setTabValue(tabs[index]);
    setIsTabLoading(true);
  };

  const { data: opexData } = useQuery(["opex", tabValue?.value, amcFilter], () => getOpex({
    yearToDate: true,
    monthWise: tabValue?.value !== "year",
    amcFilter
  }));

  function calculateSums(data) {
    const result = {};

    data.forEach((item) => {
      for (const key in item) {
        if (key.endsWith("BudgetAmount") || key.endsWith("ActualAmount")) {
          const baseKey = key.replace("BudgetAmount", "").replace("ActualAmount", "");
          const budgetKey = `${baseKey}BudgetAmount`;
          const actualKey = `${baseKey}ActualAmount`;

          if (!result[budgetKey]) {
            result[budgetKey] = 0;
          }
          if (!result[actualKey]) {
            result[actualKey] = 0;
          }

          if (key.endsWith("BudgetAmount")) {
            result[budgetKey] += item[key];
          } else if (key.endsWith("ActualAmount")) {
            result[actualKey] += item[key];
          }
        }
      }
    });

    return result;
  }

  function formatStructuredData(input, month = null) {
    const structuredData = {};

    input.forEach((group) => {
      const { groupName } = group;

      group.buckets.forEach((bucket) => {
        const { componentId } = bucket;

        if (month === null || (bucket.month && new Date(bucket.month).getMonth() + 1 === month)) {
          if (!structuredData[componentId]) {
            structuredData[componentId] = {
              componentId,
              number: bucket.number,
              externalNumber: bucket.externalNumber,
              name: bucket.name,
              month: bucket?.month
            };
          }

          structuredData[componentId][`${camelize(pascalize(groupName))}BudgetAmount`] = bucket.budgetAmount;
          structuredData[componentId][`${camelize(pascalize(groupName))}ActualAmount`] = bucket.actualAmount;
        }
      });
    });

    const result = Object.values(structuredData);
    const sums = calculateSums(result);
    return { sums, result };
  }

  useEffect(() => {
    if (opexData?.data?.length > 0) {
      const input = opexData?.data;
      setColumnsData(getOpexColumns(input));
      let data;
      if (tabValue.value !== "year") {
        data = formatStructuredData(input, Number(tabValue.value));

      } else {
        data = formatStructuredData(input);
      }
      setFormattedRowsData(data?.result);
      setTotalRowsData(data?.sums);
      setIsTabLoading(false);
    }
  }, [opexData, tabValue.label]);

  const rows = useMemo(
    () =>
      getOpexRows({
        data: {
          data: formattedRowsData || [],
          sums: totalRows
        },
        columns,
      }),
    [columns, formattedRowsData]
  );

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-6">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-1 radio-items-container">
          <Radio
            className="radio-text"
            itemValue
            type="radio"
            value={amcFilter === "Both"}
            onChange={() => {
              setIsTabLoading(true);
              setAmcFilter("Both");
            }}
          >
            <Radio.CheckIcon>
              <span className="material-icons">circle</span>
            </Radio.CheckIcon>
          </Radio>
          <FormLeftHeader title="Both" />
        </div>
        <div className="col-md-1 radio-items-container">
          <Radio
            className="radio-text"
            itemValue
            type="radio"
            value={amcFilter === "AMC"}
            onChange={() => {
              setIsTabLoading(true);
              setAmcFilter("AMC");
            }}
          >
            <Radio.CheckIcon>
              <span className="material-icons">circle</span>
            </Radio.CheckIcon>
          </Radio>
          <FormLeftHeader
            title="AMC"
          />
        </div>
        <div className="col-md-3 radio-items-container">
          <Radio
            className="radio-text"
            itemValue
            type="radio"
            value={amcFilter === "RepairAndMaintenance"}
            onChange={() => {
              setIsTabLoading(true);
              setAmcFilter("RepairAndMaintenance");
            }}
          >
            <Radio.CheckIcon>
              <span className="material-icons">circle</span>
            </Radio.CheckIcon>
          </Radio>
          <FormLeftHeader
            title="Repair And Maintenance"
          />
        </div>

        <div className="col-md-1 btn-icon-container">
          <IconButton
            className="icon-button-with-text opex-icon"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
        </div>
      </div>
      <Tab
        className="activity-tabs-container"
        tabs={tabs.map((t) => t.label)}
        onClick={handleStatusTabChange}
      />
      <div className="react-grid-container">
        {columns.length > 0 && !isTabLoading ? (
          <ReactGrid
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        ) : <TableSkeleton />}
      </div>
    </div>
  );
}

export default ReportOpex;
