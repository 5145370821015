import PropTypes from "prop-types";

function Separator({ className }) {
  return (
    <div className={`separator ${className}`} />
  );
}

Separator.propTypes = {
  className: PropTypes.string
};

Separator.defaultProps = {
  className: ""
};

export default Separator;
