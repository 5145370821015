import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Loader } from "@hydra/atom/components";
import { camelize } from "humps";

import { DashboardTable } from "@/components/dynamic";
import { getDynamicDashboardDataset } from "@/api/dynamic/dynamicDashboardViewApi";
import { CardAnalytics } from "@/components/dashboard";
import { BarChart, PieChart } from "@/components/charts";
import {
  getDashboardTableData,
  getDashboardTableColumns,
} from "./dashboardTableData";
import { parseSelectFieldValue } from "@/utils/dynamic/helpers";

function DashboardComponentLoader({ config }) {
  const { schemaId } = useParams();

  const { data, isLoading } = useQuery(
    ["dynamic-dashboard", config.name],
    () => getDynamicDashboardDataset({
      take: 1,
      limit: 100,
      sortBy: "Id",
      sortOrder: "ASC",
      model: {
        schemaId,
        objectName: config.name,
      },
    })
  );

  const formatPieChartData = (chartData) => {
    const { labelKey, valueKey } = config;

    return chartData.map((value) => ({
      id: parseSelectFieldValue(value[camelize(labelKey)]).label,
      label: parseSelectFieldValue(value[camelize(labelKey)]).label,
      value: value[camelize(valueKey)],
    }));
  };

  const getLabel = (labelKey, value) => {
    if (labelKey.includes(".")) {
      const keys = labelKey.split(".").map((key) => camelize(key));

      try {
        if (value[keys[0]][keys[1]]) {
          return value[keys[0]][keys[1]];
        }

        if (value[keys[0]][0][keys[1]]) {
          return value[keys[0]][0][keys[1]];
        }
      } catch (error) {
        return "";
      }
    }

    return value[0][labelKey];
  };

  const getValue = (valueKey, value) => {
    const chartValue = {};

    if (Array.isArray(valueKey)) {
      valueKey.forEach((key) => {
        chartValue[camelize(key)] = value[camelize(key)];
      });
    } else {
      chartValue[camelize(valueKey)] = value[camelize(valueKey)];
    }

    return chartValue;
  };

  const formatBarChartData = (chartData) => {
    const { xAxisKey, yAxisKey } = config;

    const barData = chartData.map((value) => ({
      label: getLabel(xAxisKey, value),
      ...getValue(yAxisKey, value),
    })).filter((o) => o.label);

    return barData;
  };

  const getBarChartKeys = (yAxisKey) => {
    if (Array.isArray(yAxisKey)) {
      return yAxisKey.map((key) => camelize(key));
    }

    return [camelize(yAxisKey)];
  };

  const renderDashboardComponent = () => {
    if (!data?.data.length) {
      return <div>No data found</div>;
    }

    switch (config.dashboardType.toLowerCase()) {
      case "tabular":
        return (
          <DashboardTable
            data={getDashboardTableData(data?.data || [])}
            columns={getDashboardTableColumns(data?.data || [])}
          />
        );

      case "scorecard":
        return <CardAnalytics title={data?.data[0][camelize(config.valueKey)]} />;

      case "pie":
        return <PieChart data={formatPieChartData(data?.data)} />;

      case "bar":
        return (
          <BarChart
            groupMode="grouped"
            data={formatBarChartData(data?.data)}
            keys={getBarChartKeys(config.yAxisKey)}
            bottomAxisLegend={config.xAxisLabel}
            leftAxisLegend={config.yAxisLabel}
          />
        );

      default:
        return <div>Dashboard Component</div>;
    }
  };

  return (
    <div className="dashboard-component-loader">
      {isLoading ? <Loader /> : renderDashboardComponent()}
    </div>
  );
}

DashboardComponentLoader.propTypes = {
  config: PropTypes.object.isRequired,
};

export default DashboardComponentLoader;
