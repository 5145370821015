import PropTypes from "prop-types";
import {
  Form, Label, Input, FormControl
} from "@hydra/atom/components";

import { camelCase } from "lodash";
import { pascalize } from "humps";
import AddressAutocomplete from "./AddressAutocomplete";
import { CountrySelector } from "@/components/common";
import { getTestId } from "@/utils/helpers";

function GoogleAddressAutoComplete({
  state, dispatch, groupName, testId
}) {
  const updateAddressFetchedFromGooglePlacesAPI = (data) => {
    for (const key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) {
        dispatch({
          type: "SET_FORM_VALUE",
          payload: {
            key: `${camelCase(groupName)}${pascalize(key)}`,
            value: data[key],
          },
        });
      }
    }
  };

  const handleDispatch = (key, value) => {
    if (key === "country" || key === "street") {
      dispatch({
        type: "SET_FORM_VALUE",
        payload: {
          key: `${camelCase(groupName)}${pascalize(key)}`,
          value: JSON.stringify(value),
        },
      });
    } else {
      dispatch({
        type: "SET_FORM_VALUE",
        payload: {
          key: `${camelCase(groupName)}${pascalize(key)}`,
          value,
        },
      });
    }
  };

  return (
    <Form >
      <div className="row">
        <div className="col-md-4">
          <FormControl>
            <Label className="labels-wrapper" labelFor="select-country" label="Country" />
            <CountrySelector
              value={
                JSON.parse(state[`${camelCase(groupName)}Country`] || null) || {
                  label: "United Arab Emirates",
                  value: "AE",
                  icon: "https://purecatamphetamine.github.io/country-flag-icons/3x2/AE.svg",
                }
              }
              onChange={(value) => {
                handleDispatch("country", value);
                handleDispatch("street", null);
                handleDispatch("zipCode", "");
                handleDispatch("city", "");
                handleDispatch("state", "");
              }}
              testId={getTestId(`${testId}CountrySelect`)}
            />
          </FormControl>
        </div>
        <div className="col-md-2">
          <FormControl className="zip-code input-wrapper">
            <Label
              className="labels-wrapper"
              labelFor="houseNumber"
              label="House No."
            />
            <Input
              name="house number"
              id="houseNumber"
              placeholder="House No."
              value={state[`${camelCase(groupName)}HouseNumber`]}
              onChange={(value) => handleDispatch("houseNumber", value)}
            />
          </FormControl>
        </div>

        <div className="col-md-6">
          <FormControl>
            <Label className="labels-wrapper" labelFor="address" label="Street number" />
            <AddressAutocomplete
              value={JSON.parse(state[`${camelCase(groupName)}Street`] || null) || "ae"}
              onChange={(value) => {
                handleDispatch("street", value);
              }}
              onAddressDetailsChange={updateAddressFetchedFromGooglePlacesAPI}
              countryInitials={
                JSON.parse(state[`${camelCase(groupName)}Country`] || null)?.value ?
                  JSON.parse(state[`${camelCase(groupName)}Country`] || null)?.value.toLowerCase() :
                  "ae"
              }
              testId={getTestId(`${testId}AutocompleteSelect`)}
            />
          </FormControl>
        </div>

        <div className="col-md-2">
          <FormControl className="zip-code input-wrapper">
            <Label className="labels-wrapper" labelFor="zipCode" label="ZIP code" />
            <Input
              name="zip code"
              id="zipCode"
              placeholder="ZIP code"
              value={state[`${camelCase(groupName)}ZipCode`]}
              onChange={(value) => handleDispatch("zipCode", value)}
              testId={getTestId(`${testId}ZipCodeInput`)}
            />
          </FormControl>
        </div>

        <div className="col-md-5">
          <FormControl className="city input-wrapper">
            <Label className="labels-wrapper" labelFor="city" label="City" />
            <Input
              name="city"
              id="city"
              placeholder="City"
              value={state[`${camelCase(groupName)}City`]}
              onChange={(value) => handleDispatch("city", value)}
              testId={getTestId(`${testId}CityInput`)}
            />
          </FormControl>
        </div>

        <div className="col-md-5">
          <FormControl>
            <Label className="labels-wrapper" labelFor="province" label="State" />
            <Input
              name="province"
              id="province"
              placeholder="State"
              value={state[`${camelCase(groupName)}State`]}
              onChange={(value) => handleDispatch("state", value)}
              testId={getTestId(`${testId}ProvinceInput`)}
            />
          </FormControl>
        </div>
      </div>
      <button style={{ display: "none" }} type="submit" data-testid={getTestId(`${testId}SubmitButton`)}>
        Submit
      </button>
    </Form>
  );
}

GoogleAddressAutoComplete.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

GoogleAddressAutoComplete.defaultProps = {
  testId: "",
};

export default GoogleAddressAutoComplete;
