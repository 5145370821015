import PropTypes from "prop-types";
import { ResponsiveLine } from "@nivo/line";

const lightTheme = ["#9E77ED", "#D6BBFB"];

function LineChart({ data }) {
  return (
    <div className="line-chart">
      <ResponsiveLine
        data={data}
        margin={{
          top: 10, right: 10, bottom: 24, left: 10
        }}
        curve="catmullRom"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 9,
          tickRotation: 0,
        }}
        axisLeft={null}
        colors={lightTheme}
        enableArea
        areaOpacity={0.03}
        animate
        motionConfig="slow"
        enableGridX={false}
        theme={{
          grid: {
            line: {
              stroke: "#F2F4F7",
            }
          },
          axis: {
            ticks: {
              text: {
                fontSize: 12,
                lineHeight: "18px",
                fontWeight: 400,
                fontFamily: "Inter",
                fill: "#475467",
                outlineWidth: 0,
                outlineColor: "transparent"
              }
            },
          },
        }}
      />
    </div>
  );
}

LineChart.propTypes = {
  data: PropTypes.array.isRequired
};

export default LineChart;
