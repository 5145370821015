import PropTypes from "prop-types";
import { Breadcrumb } from "@/components/common";

function Header({ className, leftContent, rightContent, showBreadcrumb }) {
  return (
    <>
      {showBreadcrumb ? <Breadcrumb /> : null}
      <header className={`${className} page-header`}>
        {leftContent}
        {rightContent}
      </header>
    </>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  showBreadcrumb: PropTypes.bool
};

Header.defaultProps = {
  className: "",
  leftContent: null,
  rightContent: null,
  showBreadcrumb: false
};

export default Header;
