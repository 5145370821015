import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Button } from "@hydra/atom/components";

import { BoxedContent, Header, NoDataFound } from "@/components/common";
import { FinanceMasterTable } from "@/components/finance";
import {
  getBudgetCategoryTableColumns,
  getBudgetCategoryTableData,
} from "@/components/finance/budget/budgetTableData";
import { getBudgetCategories, deleteBudgetCategory } from "@/api/finance/budgetApi";
import { skeletonLoadingRows } from "@/utils/helpers";
import showToast from "@/utils/toast/helpers";

function BudgetCategory() {
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(["budget-category"], getBudgetCategories);

  const queryClient = useQueryClient();
  const deleteMutation = useMutation(
    deleteBudgetCategory,
    {
      onError: () => {
        showToast("Could not delete. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Deleted successfully", "success");
        queryClient.invalidateQueries({
          queryKey: ["ledger-category"]
        });
      },
    }
  );

  const renderTable = () => {
    if (!isLoading && !data?.data) {
      return (
        <NoDataFound
          title="No budget category has been added"
          buttonText="Add budget category"
          onClick={() => navigate("/finance/budget-category/new")}
        />
      );
    }

    return (
      <FinanceMasterTable
        objectName="BudgetCategory"
        isLoading={isLoading}
        tableColumns={getBudgetCategoryTableColumns(data?.data || [])}
        data={isLoading ? skeletonLoadingRows(5) : getBudgetCategoryTableData(data?.data)}
        handleDelete={(id) => deleteMutation.mutate(id)}
      />
    );
  };

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<h1>Budget Category</h1>}
        rightContent={(
          <Button small onClick={() => navigate("/finance/budget-category/new")}>
            Add budget category
          </Button>
        )}
      />

      {renderTable()}
    </BoxedContent>
  );
}

export default BudgetCategory;
