import { actionCreator } from "@/utils/helpers";
import {
  dateFormatOptions,
  geoLocationFieldRadioOptions,
  allowedFileTypeOptions,
} from "@/utils/dynamic/constants";

export const SET_FORM_VALUE = "SET_FORM_VALUE";
export const SET_INITIAL_FORM_STATE = "SET_INITIAL_FORM_STATE";
export const SET_SELECT_FIELD_OPTION_VALUE = "SET_SELECT_FIELD_OPTION_VALUE";
export const ADD_SELECT_FIELD_OPTION = "ADD_SELECT_FIELD_OPTION";
export const REMOVE_SELECT_FIELD_OPTION = "REMOVE_SELECT_FIELD_OPTION";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_OBJECT_FIELDS = "SET_OBJECT_FIELDS";
export const ADD_OBJECT_FIELD = "ADD_OBJECT_FIELD";
export const UPDATE_OBJECT_FIELD = "UPDATE_OBJECT_FIELD";
export const REMOVE_OBJECT_FIELD = "REMOVE_OBJECT_FIELD";
export const SHOW_FIELD_FORM = "SHOW_FIELD_FORM";
export const HIDE_FIELD_FORM = "HIDE_FIELD_FORM";
export const RESET_FORM_STATE = "RESET_FORM_STATE";
export const ADD_RELATIONAL_FIELD_INITIAL_STATE =
  "ADD_RELATIONAL_FIELD_INITIAL_STATE";
export const ADD_RELATIONAL_FIELD = "ADD_RELATIONAL_FIELD";
export const UPDATE_RELATIONAL_FIELD = "UPDATE_RELATIONAL_FIELD";
export const UPDATE_RELATIONAL_FIELD_INITIAL_STATE =
  "UPDATE_RELATIONAL_FIELD_INITIAL_STATE";
export const RESET_TO_RELATIONAL_FIELD_STATE =
  "RESET_TO_RELATIONAL_FIELD_STATE";

const initialFieldState = {
  fieldType: "text",
  label: "",
  fieldName: "",
  description: "",
  helperText: "",
  isRequired: false,
  isIndexed: false,
  regexValidation: "",
  geoLocationDisplayFormat: geoLocationFieldRadioOptions[1].value,
  dateDisplayFormat: dateFormatOptions[0],
  minimumDate: null,
  maximumDate: null,
  minimumTime: null,
  maximumTime: null,
  selectFieldOptions: [
    {
      default: false,
      label: "",
      value: "",
    },
  ],
  relationalFieldId: null,
  displayFieldNames: null,
  allowedFileTypes: [allowedFileTypeOptions[0]],
};

export const initialState = {
  activeObjectName: null,
  applicationName: null,
  showFieldForm: false,
  isEditing: false,
  isEditingRelationalField: false,
  activeStep: 1,
  isRelationalField: false,
  fields: [],
  fieldState: {
    ...initialFieldState,
  },
  relationalFieldState: {
    ...initialFieldState,
  },
};

export const dynamicObjectFieldFormReducer = (state, action) => {
  switch (action.type) {
    case SHOW_FIELD_FORM:
      return {
        ...state,
        activeStep: 1,
        showFieldForm: true,
      };

    case HIDE_FIELD_FORM:
      return {
        ...state,
        activeStep: 1,
        showFieldForm: false,
        isEditing: false,
      };

    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };

    case SET_FORM_VALUE: {
      const { key, value } = action.payload;

      if (key === "allowedFileTypes") {
        const allowAllFileTypesOption = value.find(
          (option) => option.value === allowedFileTypeOptions[0].value
        );

        if (allowAllFileTypesOption) {
          return {
            ...state,
            fieldState: {
              ...state.fieldState,
              [key]: [allowedFileTypeOptions[0]],
            },
          };
        }
      }

      return {
        ...state,
        fieldState: {
          ...state.fieldState,
          [key]: value,
        },
      };
    }

    case SET_INITIAL_FORM_STATE:
      return {
        ...state,
        activeStep: 2,
        showFieldForm: true,
        isEditing: true,
        fieldState: {
          ...state.fieldState,
          ...action.payload,
        },
      };

    case SET_SELECT_FIELD_OPTION_VALUE: {
      const selectFieldOptions = [...state.fieldState.selectFieldOptions];

      const labels = [];
      const values = [];

      state.fieldState.selectFieldOptions
        .filter((_, i) => i !== action.payload.index)
        .forEach((o) => {
          if (o.label) labels.push(o.label.toLowerCase());
          if (o.value) values.push(o.value.toLowerCase());
        });

      if (
        labels.includes(
          state.fieldState.selectFieldOptions[
            action.payload.index
          ].label.toLowerCase()
        ) ||
        values.includes(
          state.fieldState.selectFieldOptions[
            action.payload.index
          ].value.toLowerCase()
        )
      ) {
        selectFieldOptions[action.payload.index].isSame = true;
      } else {
        selectFieldOptions[action.payload.index].isSame = false;
      }

      selectFieldOptions[action.payload.index][action.payload.key] =
        action.payload.value;

      return {
        ...state,
        fieldState: {
          ...state.fieldState,
          selectFieldOptions,
        },
      };
    }

    case ADD_SELECT_FIELD_OPTION:
      return {
        ...state,
        fieldState: {
          ...state.fieldState,
          selectFieldOptions: [
            ...state.fieldState.selectFieldOptions,
            {
              default: false,
              label: "",
              value: "",
            },
          ],
        },
      };

    case REMOVE_SELECT_FIELD_OPTION: {
      let selectFieldOption = [...state.fieldState.selectFieldOptions];

      selectFieldOption = selectFieldOption.filter(
        (_, index) => index !== action.payload
      );

      return {
        ...state,
        fieldState: {
          ...state.fieldState,
          selectFieldOptions: selectFieldOption,
        },
      };
    }

    case SET_OBJECT_FIELDS:
      return {
        ...state,
        fields: [...action.payload],
      };

    case ADD_OBJECT_FIELD: {
      return {
        ...state,
        fields: [...state.fields, action.payload],
      };
    }

    case UPDATE_OBJECT_FIELD: {
      const updatedFields = [...state.fields];

      const updatedFieldIndex = updatedFields.findIndex(
        (f) => f.id === action.payload.id
      );

      updatedFields[updatedFieldIndex] = {
        ...action.payload,
      };

      return {
        ...state,
        fields: updatedFields,
      };
    }

    case REMOVE_OBJECT_FIELD:
      return {
        ...state,
        fields: state.fields.filter((f) => f.id !== action.payload),
      };

    case RESET_FORM_STATE:
      return {
        ...initialState,
        fieldState: {
          ...initialState.fieldState,
          selectFieldOptions: [
            {
              default: false,
              label: "",
              value: "",
            },
          ],
        },
        fields: state.fields,
      };

    case ADD_RELATIONAL_FIELD_INITIAL_STATE: {
      // eslint-disable-next-line no-use-before-define
      const objectFields = selectObjectFields(state);

      const relationalFieldIndex = objectFields.findIndex(
        (f) => f.id === state.fieldState.id
      );

      return {
        ...state,
        activeStep: 1,
        isRelationalField: true,
        relationalFieldState: {
          ...state.fieldState,
          id: `${state.fieldState.fieldType}-${relationalFieldIndex}`,
        },
        fieldState: {
          ...initialFieldState,
          id: `${state.fieldState.fieldType}-${objectFields.length}`,
          relationalFieldId: `${state.fieldState.fieldType}-${relationalFieldIndex}`,
        },
      };
    }

    case ADD_RELATIONAL_FIELD:
      return {
        ...state,
        activeStep: 2,
        isRelationalField: false,
        isEditingRelationalField: false,
        fields: [...state.fields, action.payload],
        fieldState: {
          ...state.relationalFieldState,
        },
        relationalFieldState: {
          ...initialFieldState,
        },
      };

    case UPDATE_RELATIONAL_FIELD_INITIAL_STATE:
      return {
        ...state,
        activeStep: 1,
        isRelationalField: true,
        isEditingRelationalField: true,
        relationalFieldState: {
          ...state.fieldState,
        },
        fieldState: {
          ...action.payload,
        },
      };

    case UPDATE_RELATIONAL_FIELD: {
      const newFields = [...state.fields];

      const fieldIndex = newFields.findIndex((f) => f.id === action.payload.id);

      newFields[fieldIndex] = {
        ...action.payload,
      };

      return {
        ...state,
        fields: newFields,
        activeStep: 2,
        isRelationalField: false,
        isEditingRelationalField: false,
        fieldState: {
          ...state.relationalFieldState,
        },
        relationalFieldState: {
          ...initialFieldState,
        },
      };
    }

    case RESET_TO_RELATIONAL_FIELD_STATE:
      return {
        ...state,
        activeStep: 2,
        isRelationalField: false,
        isEditingRelationalField: false,
        fieldState: {
          ...state.relationalFieldState,
        },
        relationalFieldState: {
          ...initialFieldState,
        },
      };

    default:
      throw new Error(`${action.type} not found`);
  }
};

export const setFormValue = (key, value) => actionCreator(SET_FORM_VALUE, { key, value });

export const setInitialFormState = (value) => actionCreator(SET_INITIAL_FORM_STATE, value);

export const setSelectFieldOptionValue =
  (payload) => actionCreator(SET_SELECT_FIELD_OPTION_VALUE, payload);

export const removeSelectFieldOption = (index) => actionCreator(REMOVE_SELECT_FIELD_OPTION, index);

export const addSelectFieldOption = () => actionCreator(ADD_SELECT_FIELD_OPTION);

export const setActiveStep = (step) => actionCreator(SET_ACTIVE_STEP, step);

export const setObjectFields = (fields) => actionCreator(SET_OBJECT_FIELDS, fields);

export const addObjectField = (field) => actionCreator(ADD_OBJECT_FIELD, field);

export const updateObjectField = (field) => actionCreator(UPDATE_OBJECT_FIELD, field);

export const removeObjectField = (fieldId) => actionCreator(REMOVE_OBJECT_FIELD, fieldId);

export const showFieldForm = () => actionCreator(SHOW_FIELD_FORM);

export const hideFieldForm = () => actionCreator(HIDE_FIELD_FORM);

export const resetFormState = () => actionCreator(RESET_FORM_STATE);

// eslint-disable-next-line arrow-body-style
export const addRelationalFieldInitialState = () => {
  return actionCreator(ADD_RELATIONAL_FIELD_INITIAL_STATE);
};

export const addRelationalField = (field) => actionCreator(ADD_RELATIONAL_FIELD, field);

export const updateRelationalField = (field) => actionCreator(UPDATE_RELATIONAL_FIELD, field);

// eslint-disable-next-line arrow-body-style
export const updateRelationalFieldInitialState = (field) => {
  return actionCreator(UPDATE_RELATIONAL_FIELD_INITIAL_STATE, field);
};

export const resetRelationalFieldState = () => actionCreator(RESET_TO_RELATIONAL_FIELD_STATE);

// Selector function

export const selectObjectFields = (state) => state.fields.filter((f) => !f.relationalFieldId);

export const selectRelationalFields = (state, id) => {
  if (!id) return [];

  return state.fields.filter((f) => f.relationalFieldId === id);
};
