import { formatDistanceToNowStrict } from "date-fns";
import { useNavigate } from "react-router";
import { Avatar } from "@/components/common";

export function NotificationItem({
  userName,
  img,
  title,
  time,
  subtitleTextBefore,
  key,
  subtitleTextAfter,
  isLastItem,
  subtitleLinkText,
  url,
  isRead,
}) {
  const navigate = useNavigate();
  return (
    <div
      role="presentation"
      onClick={() => {
        navigate(url, { replace: true });
      }}
      className="d-flex notification-container-view"
      key={key}
    >
      <div className="avatar-wrap">
        <Avatar name={userName} imageUrl={img} size="medium" status="online" />
        {isLastItem ? null : <div className="separator" />}
      </div>
      <div className="notification-body">
        <div className="title-text-container">
          <div className="title-text">{title}</div>
          <div className="title-sub-text">{time}</div>
          {!isRead && <div className="notification-read-icon" />}
        </div>
        <div>
          <div className="title-subtitle">
            {subtitleTextBefore}
            <a
              role="banner"
              onClick={() => {
                navigate(url, { replace: true });
              }}
            >
              {subtitleLinkText}
            </a>
            {subtitleTextAfter}
          </div>
        </div>
      </div>
    </div>
  );
}

export const formatNotification = (notification) => {
  const { notificationType, additionalProperties } = notification;
  const event = { id: notification.id };

  // eslint-disable-next-line max-len
  const kebabarizedApplicationName =
    additionalProperties?.kebabarizedApplicationName ||
    additionalProperties?.KebabarizedApplicationName;
  // eslint-disable-next-line max-len
  const kebabarizedObjectName =
    additionalProperties?.kebabarizedObjectName || additionalProperties?.KebabarizedObjectName;
  const objectLabel = additionalProperties?.objectLabel || additionalProperties?.ObjectLabel;

  switch (notificationType) {
    case "Record": {
      const { recordId, action, userDetails } = notification;
      const userName = userDetails?.name;
      const eventTime = `${notification.eventTime}Z`;
      event.title = userName;
      event.userName = userName;
      event.img = userDetails?.profilePicture;
      event.time = formatDistanceToNowStrict(new Date(eventTime), { addSuffix: true });
      event.url = `/${kebabarizedApplicationName}`;
      event.tag = action;

      if (action === "created") {
        event.url += `/${kebabarizedObjectName}/details/${recordId}}`;
        event.subtitle = `${userName} created a `;
        event.subtitleTextBefore = `${userName} created a `;
        event.subtitleTextAfter = "";
        event.subtitleLinkText = objectLabel;
      }

      if (action === "modified") {
        event.url += `/${kebabarizedObjectName}/details/${recordId}}`;
        event.subtitle = `${userName} updated a `;
        event.subtitleLinkText = objectLabel;
      }

      if (action === "deleted") {
        event.url += `/${kebabarizedObjectName}`;
        event.subtitle = `${userName} deleted a `;
        event.subtitleLinkText = objectLabel;
      }

      event.data = { url: event.url };
      event.actions = [
        {
          action: "view-action",
          title: "View",
          type: "button",
        },
      ];
      break;
    }

    case "Workflow": {
      const { recordId, action, userDetails } = notification;

      const userName = userDetails?.name;
      const eventTime = `${notification.eventTime}Z`;

      event.title = `${action} task`;
      event.time = formatDistanceToNowStrict(new Date(eventTime), { addSuffix: true });
      event.body = `A new ${objectLabel} task has been assigned to you by ${userName}`;
      event.subtitleTextBefore = "A new ";
      event.subtitleTextAfter = ` has been assigned to you by ${userName}`;
      event.subtitleLinkText = objectLabel;
      event.tag = action;
      event.url = `/${kebabarizedApplicationName}/tasks/${recordId}`;
      event.userName = userName;
      event.img = userDetails?.profilePicture;
      event.data = { url: event.url };
      event.actions = [
        {
          action: "view-action",
          title: "View Task",
          type: "button",
        },
      ];
      break;
    }

    default:
      event.title = notification.subject;
      event.body = notification.message;
      event.url = window.location.origin;
      event.tag = "random";
      event.actions = [
        {
          action: "view-action",
          title: "Open app",
          type: "button",
        },
      ];
      event.data = { url: event.url };
      break;
  }
  event.isRead = notification.isRead;
  return event;
};
