import { Header, CrossButton, Drawer } from "@/components/common";
import { Task } from "@/components/facility/board";

export default function CalendarDrawer({ isOpen, onClose, tasks }) {

  const leftContent = (
    <div className="left-content">
      <h3>
        Tasks (
        {tasks.length}
        )
      </h3>
    </div>
  );

  return (
    <Drawer
      isOpen={isOpen}
      overlay
      size={400}
      onClose={() => onClose(false)}
    >
      <div className="filters-drawer calender-filter-drawer mobile-responsive-drawer">
        <Header
          leftContent={leftContent}
          rightContent={<CrossButton onClick={() => onClose(false)} />}
        />
      </div>

      <div className="upcoming-view-task-container">
        {tasks.length > 0 &&
          tasks.map((task, index) => <Task type="calender" key={index} task={task.job} />)}
      </div>
    </Drawer>
  );
}
