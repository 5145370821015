import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { Button } from "@hydra/atom/components";
import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { useModal, useCompanyAccount } from "@/hooks";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getGeneralInvoiceTableColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";
import { getFullName, calculateTax, formatDate } from "@/utils/helpers";

const formatGeneralInvoice = (invoice) => {
  const {
    id, number, lineItem, tenant, date
  } =
    invoice;
  const data = {
    key: id,
    generalInvoice: {
      label: number,
      value: id,
    },
    tenant: {
      label: getFullName(tenant),
      value: tenant?.id,
    },
    lineItem: [],
    invoiceDate: formatDate(new Date(date)),
    isSelected: false,
  };

  if (lineItem && lineItem.length) {
    lineItem.forEach((invoiceItem) => {
      const { account, tax } = invoiceItem;
      const lineItemData = {
        ...invoiceItem,
      };

      if (account) {
        lineItemData.account = {
          ...account,
          label: account.name,
          value: account.id
        };
      }

      if (tax) {
        lineItemData.tax = {
          ...tax,
          label: tax.name,
          value: tax.id
        };
      }

      data.lineItem.push(lineItemData);
    });
  }

  const totalAmount = data.lineItem.reduce(
    (total, currentValue) => total + Number(currentValue.totalAmount),
    0
  );

  data.subtotal = totalAmount;
  data.total = totalAmount;

  return data;
};

function HeaderRightContent({ openModal, showButton, ...rest }) {
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {showButton && (
        <Button small bordered onClick={openModal}>
          Select general invoices
        </Button>
      )}
    </defaultComponents.HeaderRightContent>
  );
}

function BulkInvoiceForm() {
  const ref = useRef(null);
  const [initialState, setInitialState] = useState({});
  const [state, setState] = useState({});
  const [generalInvoicesTableData, setGeneralInvoicesTableData] = useState([]);
  const { isOpen, closeModal, openModal } = useModal(false);
  const defaultAccounts = useCompanyAccount({
    params: {
      includeCompanyId: true,
      isLinkedWithRecord: false
    }
  });

  useEffect(() => {
    if (defaultAccounts && !initialState.areAccountsSelected) {
      const { rentalRevenue } = defaultAccounts;

      setInitialState((prevState) => ({
        ...prevState,
        areAccountsSelected: true,
        revenueAccount: rentalRevenue,
      }));
    }
  }, [defaultAccounts]);

  const { data: generalInvoicesData } = useQuery(
    [kebabCase(dynamicObjectMap.get("GeneralInvoiceObjectName")), state?.tenant?.value],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("GeneralInvoiceObjectName"), {
        tenant: state?.tenant?.value,
        takePage: 1,
        limitPage: 10,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
      }),
    {
      enabled:
        Boolean(state?.tenant?.value),
    }
  );

  useEffect(() => {
    if (generalInvoicesData && generalInvoicesData?.data && state?.tenant) {
      const formattedInvoices = generalInvoicesData.data.map((invoice) =>
        formatGeneralInvoice(invoice)
      );
      if (generalInvoicesData?.data?.length) {
        setGeneralInvoicesTableData(formattedInvoices);
      } else {
        setGeneralInvoicesTableData([]);
      }
      openModal();
    }
  }, [generalInvoicesData, state?.tenant?.value]);

  const setLineItem = (generalInvoices) => {
    const data = {
      generalInvoice: [],
      lineItem: [],
      subtotal: "",
      amountBeforeTax: "",
      total: ""
    };

    generalInvoices.forEach((generalInvoice) => {
      const { generalInvoice: invoice, lineItem } = generalInvoice;

      data.generalInvoice.push(invoice);
      data.lineItem = data.lineItem.concat(lineItem);
    });

    const totalAmount = data.lineItem.reduce(
      (total, currentValue) => total + Number(currentValue.amount),
      0
    );

    const taxAmount = data.lineItem.reduce(
      (total, currentValue) => total + Number(currentValue.taxAmount),
      0
    );

    const amountBeforeTax = data.lineItem.filter((i) => i.taxAmount).reduce(
      (total, currentValue) => total + Number(currentValue.amount),
      0
    );

    data.subtotal = totalAmount;
    data.taxAmount = taxAmount;
    data.amountBeforeTax = amountBeforeTax;
    data.total = totalAmount + taxAmount;

    ref.current.setFormState(data);
  };

  const updateLineItem = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { amountOfTax, tax: parentTax, lineItem } = formState;

    const updatedLineItem = lineItem.map((rent) => {
      let { amount } = rent;
      const { tax, taxAmount: prevTaxAmount } = rent;
      let taxAmount = 0;

      const selectedTax = tax ?? parentTax;

      if (selectedTax && amountOfTax) {
        const parameters = {
          amount,
          amountOfTax,
          tax: selectedTax,
          taxAmount: ""
        };

        if (key === "amountOfTax") {
          parameters.taxAmount = prevTaxAmount || 0;
        }

        const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax(parameters);

        amount = principalAmount;
        taxAmount = calculatedTaxAmount;
      }

      return {
        ...rent,
        amount,
        tax: selectedTax,
        taxAmount,
        totalAmount: Number(amount) + Number(taxAmount),
      };
    });

    ref.current.setFormValue("lineItem", updatedLineItem);
  };

  const setTaxAndTotal = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { lineItem, tax, amountOfTax } = formState;

    const subtotal = lineItem.reduce(
      (total, currentValue) => total + Number(currentValue.amount),
      0
    );

    const data = {
      subtotal,
      taxAmount: "",
      total: "",
    };

    if (amountOfTax && tax) {
      const parameters = {
        amount: subtotal,
        amountOfTax,
        tax,
        taxAmount: ""
      };

      const { taxAmount, principalAmount } = calculateTax(parameters);

      data.taxAmount = taxAmount;
      data.amountBeforeTax = principalAmount;
    }

    data.total = Number(data.amountBeforeTax) + Number(data.taxAmount);
    ref.current.setFormState(data);
    updateLineItem(key, value);
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "tenant":
        setState({
          ...state,
          [key]: value,
        });
        ref.current.setFormValue("tRN", value?.tRN);
        break;

      case "tax": {
        setTaxAndTotal(key, value);
        break;
      }

      case "amountOfTax": {
        if (state[key]) {
          if (state[key]?.value === value?.value) {
            break;
          }
        }

        setState((prevState) => ({
          ...prevState,
          [key]: value,
        }));

        setTaxAndTotal(key, value);
        break;
      }

      default:
        break;
    }
  };

  const handleConfirm = () => {
    if (generalInvoicesTableData.length) {
      const selectedGeneralInvoices = generalInvoicesTableData.filter((r) => r.isSelected);
      setLineItem(selectedGeneralInvoices);
    }

    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Tenant General Invoice"
        subtitle="Selected tenant has following general invoices"
        isOpen={isOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
        size="large"
      >
        {generalInvoicesTableData.length ? (
          <TableWithCheckbox
            data={generalInvoicesTableData}
            setData={setGeneralInvoicesTableData}
            columns={getGeneralInvoiceTableColumns()}
            allowMultiple
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        initialData={initialState}
        objectName={dynamicObjectMap.get("BulkInvoiceObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        components={{
          HeaderRightContent: (props) =>
            HeaderRightContent({
              openModal,
              showButton: Boolean(generalInvoicesTableData.length),
              ...props,
            }),
        }}
      />
    </BoxedContent>
  );
}

export default BulkInvoiceForm;
