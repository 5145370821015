import { getForthComingRows, getForthComingColumns } from "./forth-coming-renewal-statement";
import { getForthComingRenewals } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

export default function ReportForthComingRenewal({ activeCompany }) {
  return (
    <DynamicReport
      reportName="forthcoming-renewals"
      activeCompany={activeCompany}
      columnsDataProvider={getForthComingColumns}
      rowsDataProvider={getForthComingRows}
      fetchData={getForthComingRenewals}
      exportUrl="reports/export-query-report/forthcoming-renewals"
      filtersInitialState={{}}
      orderBy="Company"
      showDateRangeFilter={false}
    />
  );
}
