import { useReducer, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import {
  BoxedContent,
  Header,
  NoDataFound,
} from "@/components/common";

import { DynamicObjectFieldsTable } from "@/components/dynamic/object-manager";

import {
  initialState,
  dynamicObjectFieldFormReducer,
  setObjectFields,
  selectObjectFields,
} from "@/reducers/dynamic/dynamicObjectFieldFormReducer";
import {
  getDynamicObjectByKey,
} from "@/api/dynamic/dynamicObjectSchemaApi";
import { dateFormatOptions, allowedFileTypeOptions } from "@/utils/dynamic/constants";
import { skeletonLoadingRows } from "@/utils/helpers";

function DynamicObjectFieldsManager() {
  const [state, dispatch] = useReducer(
    dynamicObjectFieldFormReducer,
    initialState
  );
  const { objectKey } = useParams();
  const isLoaded = useRef(false);
  const { isInitialLoading, data } = useQuery(
    ["dynamic-object-schema", objectKey],
    () => getDynamicObjectByKey(objectKey),
    {
      enabled: !isLoaded.current,
    }
  );

  const prepareStateData = (fields, relationalFieldId = null) => {
    const fieldsState = [];

    fields.forEach((field, index) => {
      if (!relationalFieldId) {
        const fieldIndex = fields
          .filter((f) => !f.relationalFieldId)
          .findIndex((objectField) => objectField.name === field.name);
        field.id = `${field.objectFieldType.toLowerCase()}-${fieldIndex}`;
      }

      field.fieldType = field.objectFieldType.toLowerCase();
      field.fieldName = field.name;
      field.isIndexed = field.indexed;
      field.isRequired = field.required;
      // Email field case is handled
      field.isUnique = field.unique;

      if (relationalFieldId) {
        field.relationalFieldId = relationalFieldId;
        field.id = `${field.relationalFieldId}-${index}`;
      }

      switch (field.fieldType) {
        case "text":
          field.minimum = field.minimumLength || "";
          field.maximum = field.maximumLength || "";
          break;

        case "select":
          field.selectFieldOptions = field.options;
          break;

        case "lookup":
          field.objectName = {
            label: field.lookupObjectName,
            value: field.lookupObjectName,
            key: field.lookupObjectKey,
          };

          field.lookupField = field.lookupDisplayFieldName.map((fieldName) => ({
            label: fieldName,
            value: fieldName,
          }));
          break;

        case "table": {
          const additionalFields = prepareStateData(field.fields, field.id);
          if (additionalFields.length) {
            additionalFields.forEach((f) => fieldsState.push(f));
          }
          break;
        }

        case "geolocation":
          field.geoLocationDisplayFormat = field.displayFormat;
          break;

        case "date": {
          const dateFormat =
            dateFormatOptions.find((d) => d.value === field.displayFormat) ||
            dateFormatOptions[0];
          field.dateDisplayFormat = {
            label: dateFormat.label,
            value: dateFormat.value,
          };
          field.minimumDate = field.minimumDate ?
            new Date(field.minimumDate) :
            null;
          field.maximumDate = field.maximumDate ?
            new Date(field.maximumDate) :
            null;
          break;
        }

        case "attachment": {
          field.allowedFileTypes =
            field.allowedFileTypes.map((fileType) => allowedFileTypeOptions.find(
              ({ value: fileTypeValue }) => fileTypeValue === fileType
            ));
          break;
        }

        default:
          break;
      }

      if (!field.regexValidation) {
        field.regexValidation = "";
      }

      fieldsState.push(field);
    });

    return fieldsState;
  };

  useEffect(() => {
    if (data) {
      const stateData = prepareStateData(data.document);
      stateData.applicationName = data.applicationName;
      dispatch(setObjectFields(stateData));
      isLoaded.current = true;
    }

    return () => { };
  }, [data]);

  const renderTable = () => {
    if (!isInitialLoading && data && !data.document) {
      return (
        <NoDataFound
          title="No fields have been added"
        />
      );
    }

    return (
      <DynamicObjectFieldsTable
        fields={selectObjectFields(state) || skeletonLoadingRows(3)}
        dispatch={dispatch}
        isLoading={isInitialLoading}
        objectName={data?.objectName || ""}
      />
    );
  };

  return (
    <BoxedContent className="fields-manager">
      <Header
        showBreadcrumb
        leftContent={(<h1>{data?.label}</h1>)}
      />
      {renderTable()}
    </BoxedContent>
  );
}

export default DynamicObjectFieldsManager;
