import { estimatedColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getStandardSalesColumns() {

  const colArr = [
    {
      label: "Tax Payer TRN",
      value: true,
      columnId: "taxPayerTRN",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Company Name / Member Company Name (If applicable)",
      value: true,
      columnId: "companyName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tax Invoice/Tax Credit Note No.",
      value: true,
      columnId: "invoiceNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Tax Invoice/Tax Credit Note Date ",
      value: true,
      columnId: "invoiceDate",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))
    },
    {
      label: "Reporting Period From",
      value: true,
      columnId: "periodFrom",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))
    },
    {
      label: "Reporting Period To",
      value: true,
      columnId: "periodTo",
      width: estimatedColumnWidth("date"),
      resizable: true,
      formatter: (value) => (value ? formatDate(new Date(value)) : String(value || ""))
    },
    {
      label: "Tax Invoice/Tax Credit Note Amount AED (before VAT)",
      value: true,
      columnId: "amount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "VAT Amount",
      value: true,
      columnId: "vATAmount",
      width: estimatedColumnWidth("amount"),
      resizable: true,
      formatter: (value) => formatCurrency(String(value || 0))
    },
    {
      label: "Customer Name",
      value: true,
      columnId: "customerName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Customer TRN",
      value: true,
      columnId: "customerTRN",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Clear Description of the Supply",
      value: true,
      columnId: "description",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    }
  ];

  return colArr;
}
