import { useRef, useReducer, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { isEmpty } from "lodash";

import {
  Button,
  Form,
  FormControl,
  Input,
  Label,
  Loader,
  ReactSelect,
  TextArea
} from "@hydra/atom/components";

import { selectPermission } from "@/store/userSlice";
import showToast from "@/utils/toast/helpers";

import {
  BoxedContent, Header, FormLeftHeader, Select
} from "@/components/common";
import {
  budgetCategoryFormReducer,
  initialState,
  setFormValue,
  setInitialState,
} from "@/reducers/finance/budgetCategoryFormReducer";
import {
  createBudgetCategory,
  updateBudgetCategory,
  getBudgetCategoryById,
  deleteBudgetCategory
} from "@/api/finance/budgetApi";
import { nestedOptionStyles } from "@/utils/select/constants";
import { getLedgerAccounts } from "@/api/finance/ledgerAccountApi";
import { buildTree, flattenTree } from "@/utils/finance/helpers";
import { CustomActionDropdown } from "@/components/dynamic";

const validationMessages = {
  required: {
    required: "This field is required",
  },
};

const categoryTypeOptions = [
  {
    label: "Capex",
    value: "Capex",
  },
  {
    label: "Opex",
    value: "Opex",
  }
];

const prepareData = (state) => {
  const data = { ...state };
  const { account, categoryType } = state;

  if (!isEmpty(account)) {
    data.accountId = account.value;
  }

  if (!isEmpty(categoryType)) {
    data.categoryType = categoryType.value;
  }

  return data;
};

const prepareState = (data) => {
  const state = { ...data };
  const { account, categoryType } = data;

  if (!isEmpty(account)) {
    state.account = {
      ...account,
      label: account.name,
      value: account.id
    };
  }

  if (categoryType) {
    const selectedType = categoryTypeOptions.find((c) => c.value === categoryType);
    if (selectedType) {
      state.categoryType = selectedType;
    }
  }

  return state;
};

function ActionCell({
  deleteMutation, id
}) {
  const actions = [
    {
      title: "Delete",
      onClick: () => { deleteMutation.mutate(id); },
      icon: "trash-icon"
    }
  ];
  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={actions}
        trigger={(
          <div className="action-dropdown-trigger">
            <span className="text">Actions</span>
            <span className="material-icons-outlined">expand_more</span>
          </div>
        )}
        testId="Budget-Category-Action-Button"
      />
    </div>
  );
}

export default function BudgetCategoryForm() {
  const [state, dispatch] = useReducer(budgetCategoryFormReducer, initialState);
  const queryClient = useQueryClient();
  const submitButtonRef = useRef(null);
  const deletePermission = useSelector(selectPermission({ parent: "Model", scope: "BudgetCategory", action: "Delete" }));
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isInitialLoading } = useQuery(
    ["ledger-category", id],
    () => getBudgetCategoryById(id),
    {
      enabled: id !== undefined,
    }
  );

  const deleteMutation = useMutation(
    deleteBudgetCategory,
    {
      onError: () => {
        showToast("Could not delete. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Deleted successfully", "success");
        queryClient.invalidateQueries({
          queryKey: ["ledger-category"]
        });
      },
    }
  );

  useEffect(() => {
    if (id && data) {
      const stateData = prepareState(data?.data);
      dispatch(setInitialState(stateData));
    }
  }, [data]);

  const saveMutation = useMutation(createBudgetCategory, {
    onError: () => {
      showToast("Could not create. Try again!", "error");
    },
    onSuccess: () => {
      showToast("Created successfully", "success");
      navigate(-1);
    },
  });

  const updateMutation = useMutation(
    ({ id: recordId, data: recordData }) => updateBudgetCategory(recordId, recordData),
    {
      onError: () => {
        showToast("Could not update. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Updated successfully", "success");
        navigate(-1);
      },
    }
  );

  const handleCancel = () => {
    dispatch(setInitialState(initialState));
    navigate(-1);
  };

  const handleSave = () => {
    submitButtonRef.current.click();
  };

  const handleSubmit = async () => {
    const recordData = prepareData(state);

    if (id) {
      updateMutation.mutate({
        id,
        data: recordData,
      });
      return;
    }

    saveMutation.mutate(recordData);
  };

  if (id && isInitialLoading) {
    return <Loader />;
  }

  return (
    <BoxedContent className="gl-account-form">
      <Header
        showBreadcrumb
        leftContent={(
          <h1>{`${id ? "Edit" : "Create"} Budget Category`}</h1>
        )}
        // rightContent={id && deletePermission ? <ActionCell deleteMutation={deleteMutation} id={id} /> : null}
      />
      <Form
        onSubmit={handleSubmit}
        key="budget-category-form"
        className="dynamic-object-form"
        shouldScrollOnError
      >
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Name & Code"
              subtitle="Provide Name & Code of budget category"
              icon="scan-icon"
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="name" label="Name*" />
                  <Input
                    name="name"
                    placeholder="Name"
                    value={state.name}
                    onChange={(value) => dispatch(setFormValue("name", value))}
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="code" label="Code*" />
                  <Input
                    name="code"
                    placeholder="Code"
                    value={state.code}
                    onChange={(value) => dispatch(setFormValue("code", value))}
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="type" label="Category Type*" />
                  <ReactSelect
                    name="type"
                    placeholder="Select type"
                    value={state.categoryType}
                    onChange={(value) => dispatch(setFormValue("categoryType", value))}
                    options={categoryTypeOptions}
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="account" label="Category Account" />
                  <Select
                    id="account"
                    name="account"
                    queryKey="ledger-account"
                    placeholder="Select category account"
                    value={state.account}
                    onChange={(value) => dispatch(setFormValue("account", value))}
                    optionsLoader={() =>
                      getLedgerAccounts().then((res) => {
                        res.data = flattenTree(buildTree(res.data, 1));
                        return res;
                      })}
                    dataAccessKey="data"
                    optionLabelKey="name"
                    optionValueKey="id"
                    hasNestedOptions
                    styles={nestedOptionStyles}
                    isClearable
                  />
                </FormControl>
              </div>
              <div className="col-md-12">
                <FormControl>
                  <Label htmlFor="description" label="Description*" />
                  <TextArea
                    name="description"
                    placeholder="Description"
                    value={state.description}
                    onChange={(value) => dispatch(setFormValue("description", value))}
                    rules="required"
                    messages={validationMessages.required}
                  />
                </FormControl>
              </div>
            </div>
          </div>

          <button
            type="submit"
            style={{ display: "none" }}
            ref={submitButtonRef}
          >
            Save
          </button>
        </div>
      </Form>
      <div className="col-md-12">
        <div className="hr" />
      </div>

      <div className="col">
        <div className="buttons-at-end">
          <Button bordered small onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            small
            className="save-btn"
            loading={saveMutation.isLoading || updateMutation.isLoading}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </BoxedContent>
  );
}
