import { getColumnWidth } from "../helpers";

const COL_WIDTH = 135;

function filterColumns(colArr, data) {
  return colArr.filter((col) => {
    const dataItem = data?.find((item) => item.label === col.columnId);
    return !dataItem || dataItem.value;
  });
}

export default function getReturnedChequeColumns(windowWidth, columnData) {
  const colArr = [
    { columnId: "No", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) },
    { columnId: "Tenant Name", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) },
    {
      columnId: "Cheque",
      width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth)
    },
    { columnId: "Cheque Date", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) },
    { columnId: "Return Reason", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) },
    { columnId: "Date Notified", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) },
    { columnId: "Report Date of", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) },
    { columnId: "Unit No.", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) },
    { columnId: "Area", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) },
    { columnId: "Area", width: windowWidth < 1363 ? COL_WIDTH : getColumnWidth(0.75 / 4, windowWidth) }
  ];

  const filteredColArr = filterColumns(colArr, columnData);
  return filteredColArr;
}
