import PropTypes from "prop-types";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { getDateFormat, getTestId } from "@/utils/helpers";

function CustomizedDateRangePicker({
  value, onChange, required, disabled, testId, ...rest
}) {

  const dateFormat = getDateFormat();
  return (
    <DateRangePicker
      className="custom-daterange-picker"
      dayPlaceholder="dd"
      monthPlaceholder="MM"
      yearPlaceholder="yyyy"
      format={dateFormat}
      onChange={onChange}
      value={value}
      rangeDivider="-"
      disabled={disabled}
      calendarIcon={null}
      clearIcon={null}
      required={required}
      data-testid={getTestId(testId)}
      {...rest}
    />
  );
}

CustomizedDateRangePicker.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

CustomizedDateRangePicker.defaultProps = {
  required: false,
  disabled: false,
  testId: ""
};

CustomizedDateRangePicker.Type = "HydraValidatableComponent";

export default CustomizedDateRangePicker;
