import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ReportSupplierStatement
} from "@/components/finance/reporting";
import {
  BoxedContent, Header, SvgIcon, IconButton, HeaderLeftContent
} from "@/components/common";
import { selectActiveCompany } from "@/store/appSlice";
import appSettings from "@/settings";

function ReportSupplierStatementWrapper() {
  const [searchParams] = useSearchParams();
  const activeCompany = useSelector(selectActiveCompany);
  const id = searchParams.get("id").substring(1);
  const exportExcel = () => window.open(`${appSettings.baseUrl}/procurement/reports/supplier/${id}/xls?companyId=${activeCompany.id}`, "_blank", "noopener,noreferrer");

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<HeaderLeftContent title="Supplier Statement" subtitle="" icon="file-check-stroke-icon" />}
        rightContent={(
          <div>
            <IconButton
              className="icon-button-with-text"
              onClick={() => exportExcel(id)}
            >
              <SvgIcon icon="excel" />
              <span>Download Excel</span>
            </IconButton>
          </div>
        )}
      />
      <ReportSupplierStatement id={id} />
    </BoxedContent>
  );
}

export default ReportSupplierStatementWrapper;
