import PropTypes from "prop-types";
import { Button } from "@hydra/atom/components";

import { Header } from "@/components/common";

export default function CardLayout({
  className,
  children,
  title,
  buttonText,
  onClick,
}) {
  const leftContent = (
    <div className="card-layout-header-left-content">
      <h2>{title}</h2>
    </div>
  );

  const rightContent = buttonText ? <Button bordered small onClick={onClick}>{buttonText}</Button> : null;

  return (
    <div className={`card-layout ${className}`}>
      {
        title && (
        <Header
          className="card-layout-header"
          leftContent={leftContent}
          rightContent={rightContent}
        />
        )
      }
      <div className="card-layout-body">{children}</div>
    </div>
  );
}

CardLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
};

CardLayout.defaultProps = {
  title: "",
  className: "",
  buttonText: "",
  onClick: () => {},
};
