import { PostHogProvider } from "posthog-js/react";
import appSettings from "@/settings";

const options = { api_host: appSettings.postHogHost };

function PostHogProviderWrapper({ children }) {
  if (!appSettings.enablePostHog) {
    return children;
  }

  return (
    <PostHogProvider
      apiKey={appSettings.postHogKey}
      options={options}
    >
      {children}
    </PostHogProvider>
  );
}

export default PostHogProviderWrapper;
