import { Switch } from "@hydra/atom/components";
import {
  FormLeftHeader
} from "@/components/common";

function CustomSwitch({
  value, title, subtitle, onChange
}) {

  return (
    <div className="custom-switch">
      <Switch value={value} onChange={() => onChange(!value)} round />
      <FormLeftHeader
        title={title}
        subtitle={subtitle}
      />
    </div>
  );
}

export default CustomSwitch;
