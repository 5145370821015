import PropTypes from "prop-types";
import { TabWithBadge } from "@/components/property";

function CalendarToolbar({ onView }) {
  const calendarTabs = [
    { label: "Monthly", view: "month" },
    { label: "Weekly", view: "week" },
    { label: "Daily", view: "day" },
  ];

  const handleTabChange = (index) => {
    onView(calendarTabs[index].view);
  };

  return (
    <div className="calendar-view-tabs-container">
      <TabWithBadge
        tabs={calendarTabs}
        className="calendar-view-tabs"
        onClick={handleTabChange}
      />
    </div>
  );
}

CalendarToolbar.propTypes = {
  onView: PropTypes.func.isRequired,
};

export default CalendarToolbar;
