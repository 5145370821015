import { SvgIcon } from "@/components/common";

export default function ReportCard({ data }) {
  return (
    <div className="building-container-card">
      { data.map((item, i) => (
        <div className="card-title-container" key={`card-${i}`}>
          <div className={`card-title ${item.titleColor}`}>
            <div>
              {item.titleValue}
            </div>
            {item?.percentage ? (
              <div className="card-icon">
                <div>
                  <SvgIcon icon={item.positive ? "up" : "down"} />
                </div>
                <div className={`card-percentage ${item.positive ? "positive-color" : "negative-color"}`}>
                  {`${item.percentage}%`}
                </div>
              </div>
            ) : null}
          </div>
          <div className={`card-subtitle ${item.subtitleColor}`}>
            {item.subtitle}
          </div>
        </div>
      ))}
    </div>
  );
}
