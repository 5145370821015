import { createContext, useReducer, useContext } from "react";
import PropTypes from "prop-types";
import { I18n } from "@hydra/atom/lib";
import enTranslations from "./translations";

const i18n = new I18n();
i18n.addLanguage("EN", enTranslations);
i18n.setLanguage("EN");

const initialState = {
  i18n
};

function i18nReducer(state, action) {
  switch (action.type) {
    case "setLanguage": {
      state.i18n.setLanguage(action.payload);
      return state;
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}

export const i18nContext = createContext(null);
export const i18nDispatchContext = createContext(null);

export function I18nProvider({ children }) {
  const [state, dispatch] = useReducer(i18nReducer, initialState);

  return (
    <i18nContext.Provider value={state.i18n}>
      <i18nDispatchContext.Provider value={dispatch}>
        {children}
      </i18nDispatchContext.Provider>
    </i18nContext.Provider>
  );
}

I18nProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export function useI18n() {
  return useContext(i18nContext);
}

export function useI18nDispatch() {
  return useContext(i18nDispatchContext);
}
