import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import { I18nProvider } from "@/context/i18nContext";
import PostHogProviderWrapper from "@/providers/PostHogProviderWrapper";
import store from "./store/configureStore";
import App from "./App";
import { registerServiceWorker } from "./utils/service-workers/helpers";

Sentry.init({
  dsn: "https://fbf262d56a8a74117abe3e132fcb95a2@sentry.erip.me/3",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /tuli.com\/api\/v1/, /^alfahim.ae\/api\/v1/],
  replaysSessionSampleRate: 0.1,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime: 1000, // milliseconds
      staleTime: 1000, // milliseconds
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <PostHogProviderWrapper>
    <I18nProvider>
      <Provider store={store}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <Toaster position="bottom-right" reverseOrder />
            <App />
          </QueryClientProvider>
        </BrowserRouter>
      </Provider>
    </I18nProvider>
  </PostHogProviderWrapper>
);

registerServiceWorker();
