/* eslint-disable no-plusplus */
import {
  emptyTextCell,
  nonEditable,
  textCell,
  monthHeaderCell,
  noSideBorders,
  chevronCell
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT, HEADING_ROW_HEIGHT } from "../constants";
import { formatNumber } from "@/utils/helpers";

export const CASHINCHANGE_ROW_ID = "cashInChange";
let SR_NO = 1;

function getHeaderRow() {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: [
      nonEditable(monthHeaderCell("Type"), "trial-balance-header trial-balance-row justify-content-start"),
      nonEditable(monthHeaderCell("Date"), "trial-balance-header trial-balance-row justify-content-start"),
      nonEditable(monthHeaderCell("Budget"), "trial-balance-header trial-balance-row justify-content-start"),
      nonEditable(monthHeaderCell("AED over Budget"), "trial-balance-header trial-balance-row justify-content-start"),
      nonEditable(monthHeaderCell("% of Budget"), "trial-balance-header trial-balance-row justify-content-start"),
    ]
  };
}

function getTotalCashInChangeRow(title) {
  return {
    rowId: CASHINCHANGE_ROW_ID,
    height: HEADING_ROW_HEIGHT,
    cells: [
      nonEditable(noSideBorders(textCell({ text: title })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: "" })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: "" })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: "" })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(emptyTextCell(), ""),
    ]
  };
}

function getReimbursementHeader(title) {
  return {
    rowId: 1,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: title, className: "font-bold trial-balance-row justify-content-start", hasChildren: true, isExpanded: true
      }),
      nonEditable(noSideBorders(textCell({ text: "" })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: "" })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(noSideBorders(textCell({ text: "" })), "font-bold trial-balance-row justify-content-start"),
      nonEditable(emptyTextCell(), ""),
    ]
  };
}

function getReimbursementIncome(title) {
  return [{
    rowId: 2,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: "Invoice", parentId: 1, isExpanded: true, className: "trial-balance-row justify-content-start"
      }),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(emptyTextCell(), ""),
    ]
  },
  {
    rowId: 3,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: "Invoice", parentId: 1, isExpanded: true, className: "trial-balance-row justify-content-start"
      }),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(10904))}` }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(10904))}` }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(10904))}` }), "trial-balance-row justify-content-start"),
    ]
  },
  {
    rowId: `${title}${SR_NO++}`,
    height: HEADING_ROW_HEIGHT,
    cells: [
      nonEditable(textCell({ text: "Total Cabinets" }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start total-row"),
    ]
  },
  {
    rowId: `${title}${SR_NO++}`,
    height: HEADING_ROW_HEIGHT,
    cells: [
      nonEditable(textCell({ text: "" }), "font-bold justify-content-center"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
    ]
  }
  ];

}

function getCostOfGoods(title) {
  return [{
    rowId: 4,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: title, className: "font-bold trial-balance-row justify-content-start", hasChildren: true, isExpanded: true
      }),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(emptyTextCell(), ""),
    ]
  },
  {
    rowId: 5,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: "Brass Hinges", parentId: 4, isExpanded: true, className: "trial-balance-row justify-content-start"
      }),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(7488896))}` }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(7488896))}` }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(7488896))}` }), "trial-balance-row justify-content-start"),
    ]
  },
  {
    rowId: 6,
    height: HEADING_ROW_HEIGHT,
    cells: [
      chevronCell({
        text: "Doorknobs", parentId: 4, isExpanded: true, className: "trial-balance-row justify-content-start"
      }),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(7488896))}` }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: "" }), "trial-balance-row justify-content-start"),
    ]
  }
  ];

}

function getTotals(title, subtitle) {
  return [{
    rowId: CASHINCHANGE_ROW_ID,
    height: HEADING_ROW_HEIGHT,
    cells: [
      nonEditable(textCell({ text: title }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start total-row"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start total-row"),
    ]
  },
  {
    rowId: `${title}${SR_NO++}`,
    height: HEADING_ROW_HEIGHT,
    cells: [
      nonEditable(textCell({ text: subtitle }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
      nonEditable(textCell({ text: `${formatNumber(Math.abs(26666))}` }), "font-bold trial-balance-row justify-content-start"),
    ]
  }
  ];

}

export function getProfitAndLossVsActualRows({
  cashboxBank,
}) {
  return [
    getHeaderRow(),
    getTotalCashInChangeRow("Income", cashboxBank),
    getReimbursementHeader("Reimbursement Income", cashboxBank),
    ...getReimbursementIncome("Income", cashboxBank),
    ...getCostOfGoods("Door Frame"),
    ...getTotals(
      "Grand Total",
      "Grand Profit"
    ),
  ];
}
