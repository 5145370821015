import { useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { LoginForm } from "@hydra/saas/components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { kebabCase } from "lodash";

import { usePostHog } from "posthog-js/react";

import { useUser } from "@/hooks";
import { useI18n } from "@/context/i18nContext";
import { setUserConnect } from "@/store/userSlice";
import { selectActiveCompany, setActiveCompany } from "@/store/appSlice";
import { getUser } from "@/api/user/profileApi";
import {
  loginUser, setToken, setUserType, setRoles, resetLocalStorage
} from "@/api/user/authApi";
import { getAllPermissions } from "@/api/user/permissionApi";
import passwordVisibleIcon from "@/assets/images/icons/active.svg";
import passwordHiddenIcon from "@/assets/images/icons/in-active.svg";
import Logo from "@/assets/images/logos/tuli-login.svg";
import showToast from "@/utils/toast/helpers";

function Login() {
  const i18n = useI18n();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useUser();
  const activeCompany = useSelector(selectActiveCompany);
  const isLoggedIn = Boolean(user?.userId) && Boolean(activeCompany?.id);
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirect");
  const {
    data: permissionsRes,
    isFetched: isFetchedPermissions,
  } = useQuery(
    ["permissions", user?.userId, activeCompany?.id],
    getAllPermissions,
    {
      enabled: isLoggedIn,
    });

  useEffect(() => {
    if (isFetchedPermissions && isLoggedIn) {
      const userApps = permissionsRes.data.filter(({ objectType }) => objectType === "Module");

      if (userApps.length) {
        const firstAppWithAccess = userApps[0];
        const { objectName } = firstAppWithAccess;
        showToast("Logged in successfully", "success");
        navigate(`/${kebabCase(objectName)}/tasks`);
      }
    }
  }, [isLoggedIn, isFetchedPermissions]);

  const verifyToken = useMutation(getUser, {
    onSuccess: (data) => {
      const {
        defaultModelOrView, defaultModule, defaultBookOfBusiness, companies
      } = data;

      let navigationRoute = "";

      if (redirectTo) {
        navigationRoute = redirectTo;
      } else if (defaultModelOrView) {
        navigationRoute = `/${kebabCase(defaultModule)}/${kebabCase(defaultModelOrView)}`;
      } else if (defaultModule) {
        navigationRoute = `/${defaultModule}/tasks`;
      }

      posthog?.identify(data.id, {
        email: data.email,
        username: data.username
      });

      data.roles.forEach((role) => posthog?.group(role, role));

      dispatch(
        setUserConnect({
          ...data,
          userId: data.id,
        })
      );

      if (navigationRoute) {
        showToast("Logged in successfully", "success");
        navigate(navigationRoute);
      }

      if (defaultBookOfBusiness) {
        const defaultCompany = companies.find((c) => c.name === defaultBookOfBusiness);
        dispatch(setActiveCompany(defaultCompany));
      }
    },
    onError: () => {
      resetLocalStorage();
    },
  });

  const loginMutation = useMutation(loginUser, {
    onSuccess: (data) => {
      setToken(data.token);
      setUserType(data.userType);
      setRoles(data.roles);
      verifyToken.mutate();
    },
    onError: () => {
      showToast("Could not sign in. Please enter a valid email address and password.", "error");
    },
  });

  const onSubmit = (data) => {
    loginMutation.mutate({
      emailOrPhone: data.email,
      password: data.password,
      clientName: "tuli-web-client"
    });
  };

  return (
    <div className="form-container login-form">
      <div className="logo-container">
        <img src={Logo} alt="Tuli" className="tuli-logo" />
      </div>
      <h3>Welcome back!</h3>
      <span className="subheading">Start managing your tenants and other people</span>
      <LoginForm
        i18n={i18n}
        onSubmit={onSubmit}
        isRememberPassword
        isLoading={loginMutation.isLoading}
        forgotPasswordNavigateTo="/forgot-password"
        passwordVisibleIcon={passwordVisibleIcon}
        passwordHiddenIcon={passwordHiddenIcon}
      />
      {/* <p className="text-sm sign-up-message">
        No account?
        <Link to="/register">Sign up</Link>
      </p> */}
      <p className="footer-text">
        All Rights Reserved
        {" "}
        {format(new Date(), "yyyy")}
      </p>
    </div>
  );
}

export default Login;
