import { useCallback, useMemo } from "react";
import { kebabCase } from "lodash";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "@hydra/atom/components";
import PropTypes from "prop-types";

import { DynamicFormContainer } from "@/components/dynamic";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { TASK_STATUSES, TASK_TYPES } from "@/components/workflow/constants";
import prepareInitialState from "@/utils/workflow/helpers";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import ApproveQuotationForm from "./ApproveQuotationForm";

function P2PWorkflowFormContainer({
  task, recordData, setIsLoading, onSuccess
}) {
  const checkFormRender = useCallback(() => {
    const { taskType, status: taskStatus, objectName } = task;
    switch (taskType) {
      case TASK_TYPES.CREATE:
        return taskStatus !== TASK_STATUSES.CREATED;

      case TASK_TYPES.SUBMIT:
        if (objectName === "StockTransfer") return false;
        return taskStatus !== TASK_STATUSES.SUBMITTED;
      default:
        return false;
    }
  }, [task]);

  const initialState = useMemo(
    () => prepareInitialState(recordData, task?.objectName),
    [recordData, task?.objectName]
  );

  const { data: quotationData, isLoading: isLoadingQuotation } = useQuery(
    [kebabCase(dynamicObjectMap.get("QuotationObjectName"))],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("QuotationObjectName"), {
        queryMode: "Deep",
        requestForQuotation: task?.recordId,
      })
  );

  const renderForm = () => {
    if (
      task?.preObjectName === "RequestForQuotation" &&
      task?.currentStep === "Request for Quote"
    ) {
      if (isLoadingQuotation) {
        return <Loader />;
      }

      return (
        <ApproveQuotationForm
          initialData={prepareInitialState(
            quotationData?.data,
            dynamicObjectMap.get("QuotationObjectName")
          )}
          setIsLoading={setIsLoading}
          onSuccess={onSuccess}
          onError={() => setIsLoading(false)}
        />
      );
    }

    switch (task?.objectName) {
      case dynamicObjectMap.get("GoodsReceiptNoteObjectName"):
      case dynamicObjectMap.get("PurchaseOrderObjectName"):
      case dynamicObjectMap.get("PurchaseInvoiceObjectName"):
      case dynamicObjectMap.get("PaymentObjectName"):
        return null;

      default:
        return (
          <DynamicFormContainer
            objectName={task?.objectName}
            initialData={initialState}
            setIsLoading={setIsLoading}
            onSuccess={onSuccess}
            onError={() => setIsLoading(false)}
            showButtons={false}
          />
        );
    }
  };

  if (!checkFormRender()) {
    return null;
  }

  return <div>{renderForm()}</div>;
}

P2PWorkflowFormContainer.propTypes = {
  task: PropTypes.object.isRequired,
  recordData: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default P2PWorkflowFormContainer;
