import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import pluralize from "pluralize";
import { ReactSelect, FormControl } from "@hydra/atom/components";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import FieldLabel from "./FieldLabel";
import request from "@/utils/api/helpers";
import { objectWithFullNameFields } from "@/utils/dynamic/constants";
import { getFullName, getTestId } from "@/utils/helpers";
import { AdvanceSearchModal } from "@/components/modals";
import { Control, DropdownIndicator } from "@/components/common/form/Select";
import { useDebounce } from "@/hooks";

const getStaticObjectRecords = (applicationName, objectName) =>
  request({
    url: `/${kebabCase(applicationName.toLowerCase())}/${kebabCase(pluralize(objectName))}`,
  });

const getOptions = async (config) => {
  const {
    objectName,
    optionLabelKey,
    optionValueKey,
    fieldFilters,
    isDynamicObject,
    debouncedSearch,
  } = config;

  let options = [];
  let response;

  if (isDynamicObject) {
    const dynamicObjectValuesConfig = {
      sort_by: "CreatedAt",
      sort_type: "DESC",
      take_page: 1,
      limit_page: ["Location", "Organization"].includes(objectName) ? undefined : 5,
      [`${optionLabelKey}[like]`]: `%${debouncedSearch}%`,
      ...fieldFilters,
    };

    response = await getDynamicObjectRecords(objectName, dynamicObjectValuesConfig);
  } else {
    response = await getStaticObjectRecords("finance", objectName);
  }

  options = response.data.map((option) => {
    let label = "";
    if (objectWithFullNameFields.includes(objectName)) {
      label = getFullName(option);
    } else {
      label = option[optionLabelKey];
    }

    return {
      label,
      value: option[optionValueKey],
      objectName,
    };
  });

  return options;
};
function DynamicObjectSelectField({
  name,
  objectName,
  value,
  onChange,
  optionLabelKey,
  optionValueKey,
  fieldFilters,
  error,
  label,
  showLabel,
  isDynamicObject,
  testId,
  disabled,
  ...rest
}) {
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  const handleInputChange = useCallback((inputValue) => {
    if (isDynamicObject) {
      setSearch(inputValue);
    }
  }, []);

  const { data: options, isInitialLoading } = useQuery(
    [kebabCase(objectName), fieldFilters, debouncedSearch],
    () =>
      getOptions({
        objectName,
        optionLabelKey,
        optionValueKey,
        fieldFilters,
        isDynamicObject,
        debouncedSearch,
      })
  );
  const handleSubmit = () => {
    setOpenModal(true);
  };

  return (
    <>
      <FormControl>
        {showLabel && <FieldLabel label={label} />}
        <ReactSelect
          value={value}
          onChange={onChange}
          options={options}
          isLoading={isInitialLoading}
          isClearable
          backSpaceRemovesValue
          noOptionsMessage={() => "No options found!"}
          testId={getTestId(testId)}
          isDisabled={disabled}
          handleSubmit={handleSubmit}
          components={{
            Control,
            ...{ DropdownIndicator },
          }}
          onInputChange={handleInputChange}
          {...rest}
        />
        <AdvanceSearchModal
          isOpen={openModal}
          filterOption={[]}
          multiSelect={rest.isMulti}
          objectLabel={objectName}
          objectName={objectName}
          setOpenModal={setOpenModal}
          onSubmit={(selectedItems) => {
            let finalValue;
            if (rest.isMulti) {
              finalValue = selectedItems.map((item) => ({
                ...item,
                label: item[optionLabelKey],
                value: item.id,
              }));
            } else {
              const item = selectedItems[0];
              finalValue = {
                ...item,
                label: item[optionLabelKey],
                value: item.id,
              };
            }
            onChange(finalValue);
          }}
        />
      </FormControl>
      {typeof error === "string" && <span className="error-text">{error}</span>}
    </>
  );
}

DynamicObjectSelectField.propTypes = {
  objectName: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  onChange: PropTypes.func.isRequired,
  optionLabelKey: PropTypes.string.isRequired,
  optionValueKey: PropTypes.string.isRequired,
  fieldFilters: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showLabel: PropTypes.bool,
  isDynamicObject: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
};

DynamicObjectSelectField.defaultProps = {
  name: "dynamic-object-select-field",
  label: "Dynamic Object Select",
  showLabel: true,
  isDynamicObject: true,
  value: null,
  fieldFilters: {},
  error: false,
  testId: "",
  disabled: false,
};

export default DynamicObjectSelectField;
