import PropTypes from "prop-types";
import { useState } from "react";
import { Pill } from "@/components/dashboard";
import { BankReconciliationModal } from "@/components/modals";
import { BankReconciliationActionDropdown } from "./BankReconciliationActionDropdown";

function StatusCell({ value }) {
  return (
    <Pill
      text={value.value}
      variant={value.color}
    />
  );
}

StatusCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export function ReportActionViewCell({}) {
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState({});
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSave = () => {
    setIsOpen(false);
  };

  const handleStateChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const actions = [
    {
      title: "Update Row",
      onClick: () => {},
    },
    {
      title: "Update Invoice",
      onClick: () => {},
    },
    {
      title: "Allocate Amount",
      onClick: () => {
        handleStateChange("type", "allocated-amount");
        setIsOpen(true);
      },
    },
    {
      title: "Update Transaction",
      onClick: () => {
        handleStateChange("type", "update-transaction");
        setIsOpen(true);
      },
    },
  ];

  return (
    <div className="action-cell">
      <BankReconciliationActionDropdown
        actions={actions}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId="Bank-Reconciliation-Action-Button"
      />
      <BankReconciliationModal
        isOpen={isOpen}
        onClose={handleClose}
        onSave={handleSave}
        state={state}
        onChange={handleStateChange}
      />
    </div>
  );
}

function NumberCell({ value }) {
  return (
    <div
      className={`table-img-container ${value.color}`}
    >
      {value.value}
    </div>
  );
}

export const getBankReconciliationTableColumns = () => [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "CHK",
    accessor: "category1",
  },
  {
    Header: "Reference No.",
    accessor: "category2",
  },
  {
    Header: "Payee",
    accessor: "category3",
  },
  {
    Header: "Description",
    accessor: "category4",
  },
  {
    Header: "CHK Deposit",
    accessor: "category5",
    Cell: NumberCell
  },
  {
    Header: "Withdrawn",
    accessor: "category6",
    Cell: NumberCell
  },
  {
    Header: "Un allocated",
    accessor: "category7",
    Cell: NumberCell
  },
  {
    Header: "Status",
    accessor: "category8",
    Cell: StatusCell
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ReportActionViewCell,
    collapse: true,
  },
];
