/* eslint-disable no-plusplus */
import {
  nonEditable,
  textCell,
  headerCell
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT } from "../constants";
import { formatCurrency } from "@/utils/helpers";

export const CASH_IN_CHANGE_ROW_ID = "cashInChange";

function getHeaderRow(columns) {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: columns.map((column) => headerCell(column.label)),
  };
}

function getGroupRows(title, groups, totalDebit, totalCredit, columns, groupIndex) {
  if (groups?.length) {
    return [
      ...groups.map((item, index) => ({
        rowId: item.id,
        height: ROW_HEIGHT,
        cells: columns.map((column) => {
          let className = "";
          const textCellArgs = {};

          if (column.formatter) {
            textCellArgs.text = column.formatter(item[column.columnId], index);
          } else {
            textCellArgs.text = item[column.columnId] ?? "";
          }

          if (column.classname) {
            className = column.classname(item);
          }

          return nonEditable(textCell(textCellArgs), className);
        }),
      })),
      {
        rowId: `${title}-${groupIndex}-Footer`,
        height: ROW_HEIGHT,
        cells: columns.map((column, i) => {
          const hasDebits = columns.some((col) => col.columnId === "debits");
          const hasCredits = columns.some((col) => col.columnId === "credits");
          if (column.columnId === "debits") {
            return headerCell(totalDebit, "font-bold total-row-debit");
          } if (column.columnId === "credits") {
            return headerCell(totalCredit, "font-bold total-row-credit");
          } if ((hasDebits || hasCredits) && i === 0) {
            return headerCell(title, "font-bold total-row");
          }
          if (!hasDebits && !hasCredits) {
            return headerCell("", "");
          }
          return headerCell("", "total-row");
        })
      },
    ];
  }
  return [];
}

export function getTrialBalanceRows({
  data,
  columns
}) {

  if (!data) {
    return [];
  }
  return [getHeaderRow(columns), ...getGroupRows("Total", data.data, formatCurrency(data?.totalDebit, "AED"), formatCurrency(data?.totalCredit, "AED"), columns)];
}
