import { useNavigate } from "react-router-dom";

import { ForgotPasswordForm } from "@hydra/saas/components";
import { useI18n } from "@/context/i18nContext";

function ForgotPassword() {
  const i18n = useI18n();
  const navigate = useNavigate();

  const onSubmit = () => {};

  const onCancelClick = () => navigate("/login");

  return (
    <ForgotPasswordForm
      i18n={i18n}
      handleSubmit={onSubmit}
      isSubheading
      isHeadingLeftAlign
      isLoading={false}
      onCancelClick={onCancelClick}
      isDidntReceiveAtBottom={false}
    />
  );
}

export default ForgotPassword;
